define('cdata/pods/people/merge-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modal: Ember.inject.service(),
    flashMessage: Ember.inject.service(),
    mergeList: Ember.inject.service(),

    personMerging: function personMerging(id, ids) {
      this.get('mergeList').save(this.get('store'), 'person', id, ids);
    },


    actions: {

      /**
       * Merge data of current person TO other person or FROM other people to current
       * @param {String} type to|from
       * @param {Array} personSubjects
       */
      mergePeople: function mergePeople(type, personSubjects) {
        var _this = this;

        // many people into current
        var personId = this.get('currentModel.id');
        var subjectIds = personSubjects.mapBy('id');

        // current into other
        if (type === 'to') {
          personId = personSubjects.get('firstObject').get('id');
          subjectIds = [this.get('currentModel.id')];
        }

        var message = type === 'to' ? 'Current person has been merged to other person' : 'Selected people has been merged to current person';

        this._subscribeOnPersonMerge(message, function () {
          _this.transitionTo('people.show', personId);
          _this.send('cancel');
        });

        this.personMerging(personId, subjectIds);
      },
      confirmPersonConversion: function confirmPersonConversion(person) {
        var _this2 = this;

        var conversion = this.get('store').createRecord('person-conversion', {
          personId: person.get('id')
        });

        conversion.save().then(function (conversion) {
          _this2.send('closeConversionPersonForm');
          _this2.transitionTo('organizations.show', conversion.get('organizationId'));
          _this2.get('flashMessage').showSuccess('Conversion completed');
        }, function (error) {
          _this2.get('flashMessage').showError(error);
        });
      }
    },

    _subscribeOnPersonMerge: function _subscribeOnPersonMerge() {
      var successMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var successCallback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      this._personMergeSuccessMessage = successMessage;
      this._personMergeSuccessFunction = successCallback;

      this.get('modal').on('person.merge.success', this, this._onPersonMergeSuccess).on('person.merge.error', this, this._onPersonMergeError);
    },
    _unsubscribePersonMerge: function _unsubscribePersonMerge() {
      this.get('modal').off('person.merge.success', this, this._onPersonMergeSuccess).off('person.merge.error', this, this._onPersonMergeError);
    },
    _onPersonMergeSuccess: function _onPersonMergeSuccess() {
      this._unsubscribePersonMerge();

      if (null !== this._personMergeSuccessMessage) {
        this.get('flashMessage').showSuccess(this._personMergeSuccessMessage);
      }

      if (null !== this._personMergeSuccessFunction) {
        this._personMergeSuccessFunction();
      }
    },
    _onPersonMergeError: function _onPersonMergeError(error) {
      this._unsubscribePersonMerge();
      this.get('flashMessage').showError(error);
    }
  });
});