define('cdata/pods/components/subjects/search-result-list/component', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',

    classNames: ['subject-search-results'],
    classNameBindings: ['active', 'short', 'showType:type', 'process'],

    modal: Ember.inject.service(),
    showType: Ember.computed.equal('mode', 'type'),

    createNewLabel: 'Create new with title',

    process: Ember.computed.alias('subjectSearch.process'),

    _results: [],

    text: Ember.computed.alias('subjectSearch.query'),
    active: Ember.computed.alias('subjectSearch.active'),
    short: Ember.computed.equal('mode', 'short'),

    openVocabularyNewItemForm: 'openVocabularyNewItemForm',

    /**
     * If exist, need show option for add new item
     */
    vocabNameForNewItem: null,

    results: Ember.computed('subjectSearch.results', 'process', 'text', function () {
      var results = this.get('subjectSearch.results');

      // in search process, return old results
      if (this.get('process') && this.get('text') && !results.get('length')) {
        return this.get('_results');
      }

      // save and return current results
      this.set('_results', results);

      return results;
    }),

    size: Ember.computed('active', 'results.length', function () {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        Ember.run.debounce(_this, _this.scroll, 100);
      });
    }),

    selectedIndex: Ember.computed('results.@each.active', function () {
      if (!this.get('active') || !this.get('results.length')) {
        return;
      }

      var elem = this.$();
      var item = elem.find('li.active');
      if (!item || item.length === 0) {
        return;
      }

      var container = elem.find('ul');
      var position = item.position().top;
      var scroll = container.scrollTop();
      var height = container.height();

      if (height + scroll < position + 30) {
        container.scrollTop(scroll + 100);
      } else if (position < 0) {
        container.scrollTop(scroll - 100);
      }
    }),

    scroll: function scroll() {
      if (!this.get('active') || !this.get('results.length')) {
        return;
      }

      var modalSid = this.get('modal.sid');
      var modal = Ember.$('.' + modalSid);
      var scrollTarget = modalSid ? modal : Ember.$(window);
      var $elem = Ember.$('#' + this.elementId);
      var scroll = scrollTarget.scrollTop();
      var height = scrollTarget.height();
      var position = $elem.offset().top + $elem.height();
      if (modalSid) {
        position -= scrollTarget.offset().top;
      } else {
        position -= scroll;
      }

      if (height < position) {
        var animateTarget = modalSid ? modal : Ember.$('html, body');
        animateTarget.animate({ scrollTop: scroll + position - height + 50 }, 500);
      }
    },


    /**
     * Is enable to add option for create new item?
     */
    possibleCreate: Ember.computed('text', function () {
      return !Ember.isEmpty(this.get('text')) && ( // through handleCreate action
      typeof this.get('handleCreate') === 'function' || this.get('vocabNameForNewItem'));
    } // through vocabulary item modal
    ),

    actions: {
      selectItem: function selectItem(item) {
        this.get('handleSelect')(item);
      },
      createItem: function createItem() {
        if (this.get('vocabNameForNewItem')) {
          // start creating new item trough modal window
          // after created, will be to call 'selectItem' action
          this.sendAction('openVocabularyNewItemForm', this.get('vocabNameForNewItem'), this.get('text'));
          this._subscribeOnNewItem();
        } else {
          // directly to call 'creating' action from the parent component
          this.get('handleCreate')(this.get('text'));
        }
      },
      cancel: function cancel() {
        this.get('subjectSearch').reset();
      }
    },

    /**
     * Listen modal events
     * @private
     */
    _subscribeOnNewItem: function _subscribeOnNewItem() {
      this.get('modal').on(_constants.MODALS.VOCAB_ITEM_ADD + '.saving.success', this, this._onNewAddSuccess).on(_constants.MODALS.VOCAB_ITEM_ADD + '.close', this, this._unsubscribeNewItem);
    },


    /**
     * New vocabulary item has been added
     * @param {Object} result
     * @private
     */
    _onNewAddSuccess: function _onNewAddSuccess(result) {
      this.send('selectItem', Ember.Object.create({
        id: result.item.get('id'),
        name: result.item.get('label')
      }));
    },


    /**
     * Close modal listeners
     * @private
     */
    _unsubscribeNewItem: function _unsubscribeNewItem() {
      this.get('modal').off(_constants.MODALS.VOCAB_ITEM_ADD + '.saving.success', this, this._onNewAddSuccess).off(_constants.MODALS.VOCAB_ITEM_ADD + '.close', this, this._unsubscribeNewItem);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      this._unsubscribeNewItem();
    }
  });
});