define('cdata/pods/components/citations/web-sources/component', ['exports', 'handlebars', 'ember-cli-jss', 'clsx', 'cdata/helpers/short-url', 'cdata/pods/components/citations/web-sources/stylesheet', 'cdata/pods/components/citations/web-sources/utils'], function (exports, _handlebars, _emberCliJss, _clsx, _shortUrl, _stylesheet, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var escapeExpression = _handlebars.default.Utils.escapeExpression;
  exports.default = Ember.Component.extend(_emberCliJss.TaglessJSS, {
    tagName: '',
    stylesheet: _stylesheet.default,

    withCitation: Ember.computed.filterBy('websites', 'citationName'),

    othersWithCitation: Ember.computed('withCitation.@each.{preferred,citationName,userName}', function () {
      var _this = this;

      var websites = this.get('withCitation');

      return (0, _utils.mapByGroups)(function (items) {
        return _this.renderWithCitation(items);
      }, websites);
    }),

    withoutCitation: Ember.computed.setDiff('websites', 'withCitation'),

    othersWithoutCitation: Ember.computed('withoutCitation.@each.{userName,url}', function () {
      var _this2 = this;

      var websites = this.get('withoutCitation');

      return websites.map(function (item) {
        return Ember.String.htmlSafe(_this2.renderItem(item));
      });
    }),

    others: Ember.computed.union('othersWithCitation', 'othersWithoutCitation'),

    renderWithCitation: function renderWithCitation(items) {
      var _this3 = this;

      var citationName = items[0].get('citationName');
      var preferred = items.find(function (item) {
        return item.get('preferred');
      });
      var links = items.map(function (item) {
        return _this3.renderItem(item);
      }).join('');

      return Ember.String.htmlSafe('<div class=' + (0, _clsx.default)(preferred && this.classes.preferred) + '>' + citationName + ' ' + links + '</div>');
    },
    renderItem: function renderItem(website) {
      var userName = website.get('userName');
      var url = website.get('url');
      var link = '<a href="' + escapeExpression(url) + '" target="_blank">' + _shortUrl.default.compute([url, 40]) + '</a>';
      var lineItem = userName ? link + ' &larr; <i>' + userName + '</i>' : link;

      return '<div>' + lineItem + '</div>';
    }
  });
});