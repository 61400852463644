define('cdata/helpers/format-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatUrl = formatUrl;
  function formatUrl(params) {
    var url = params[0] + '';
    url = url.replace(/https?:\/\//gi, '');
    url = url.replace(/^(.*)\/$/gi, '$1');

    return url;
  }

  exports.default = Ember.Helper.helper(formatUrl);
});