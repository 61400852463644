define('cdata/pods/components/comparables/item-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'entities-entity-header comparables',

    bgrStyle: Ember.computed('image', function () {
      var image = this.get('image');

      if (image) {
        return Ember.String.htmlSafe('background-image: ' + image);
      } else {
        return Ember.String.htmlSafe('');
      }
    }),

    image: Ember.computed.alias('item.imageLarge'),

    makerYears: Ember.computed.alias('item.makerYears')
  });
});