define('cdata/pods/components/d3-zoomable-sunburst/declaration', ['exports', 'ember-prop-types'], function (exports, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var dataType = _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.shape({
    name: _emberPropTypes.PropTypes.string.isRequired,
    children: _emberPropTypes.PropTypes.arrayOf(_emberPropTypes.PropTypes.object).isRequired
  }), _emberPropTypes.PropTypes.shape({
    name: _emberPropTypes.PropTypes.string.isRequired,
    size: _emberPropTypes.PropTypes.number.isRequired
  })]).isRequired;

  exports.default = {
    data: dataType,
    onNodeHover: _emberPropTypes.PropTypes.func,
    onNodeSelect: _emberPropTypes.PropTypes.func,
    onLeave: _emberPropTypes.PropTypes.func
  };
});