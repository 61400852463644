define('cdata/mixins/serializers/embedded-on-save', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    serialize: function serialize(snapshot) {
      var _this = this;

      if (snapshot && !snapshot.id && this.saveNewAsEmbedded) {
        this.attrs = this.attrs || {};
        this.saveNewAsEmbedded.forEach(function (relationship) {
          _this.attrs[relationship] = _this.attrs[relationship] || {};
          _this.attrs[relationship].serialize = 'records';
        });
      }

      return this._super.apply(this, arguments);
    }
  });
});