define('cdata/pods/components/entities/view/entity-item/thumb-image/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['thumb-image'],
    classNameBindings: ['pref'],

    pref: Ember.computed.alias('model.preferred'),

    openForms: 'openForms',

    actions: {
      openForms: function openForms() {
        this.sendAction.apply(this, ['openForms'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});