define('cdata/pods/vocabularies/route', ['exports', 'cdata/mixins/route-init', 'cdata/mixins/route-create-modals'], function (exports, _routeInit, _routeCreateModals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeInit.default, _routeCreateModals.default, {
    sidConstants: Ember.inject.service(),

    actions: {
      loadSidFor: function loadSidFor(context, entityId) {
        var sid = void 0;

        if (entityId) {
          sid = this.get('sidConstants').getByEntityId(entityId, 'vocabulary');
        }

        if (!sid) {
          sid = this.get('store').createRecord('sid', {
            path: 'vocabulary',
            entityId: entityId,
            sid: ''
          });
        }

        context.set('sid', sid);
      },
      saveSid: function saveSid(sid) {
        var _this = this;

        sid.save().then(function () {
          _this.get('flashMessage').showSuccess('Sid saved');
        }, function (error) {
          _this.get('flashMessage').showError(error);
        });
      }
    }
  });
});