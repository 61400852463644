define('cdata/pods/components/organizations/edit/edit-forms-nationalities/component', ['exports', 'cdata/pods/components/organizations/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Nationality'), {
    nationalitiesIds: Ember.computed.mapBy('nationalities', 'nationalityVocabularyItemId'),

    nationalities: Ember.computed.filterBy('model.organizationNationalities', 'isDeleted', false),

    nationality: null,
    citationId: null,

    autoCreating: false,

    actions: {
      setNationality: function setNationality(_, item) {
        this.set('nationality', item);
      },
      setCitationId: function setCitationId(citationId) {
        this.set('citationId', citationId);
      },
      add: function add() {
        this.sendAction('addOrganizationNationality', this.get('nationality.id'), this.get('citationId'));
        this.set('nationality', null);
        this.set('citationId', null);
      }
    }
  });
});