define('cdata/pods/components/entities/edit/edit-forms-diamond/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), {
    item: Ember.computed.alias('model'),

    checkGemProperties: 'checkGemProperties',
    clearEntityColorSection: 'clearEntityColorSection',
    addEntityColor: 'addEntityColor',

    colorId: Ember.computed('model.colorModel', 'model.colorModel.colorVocabularyItemId', function () {
      return this.get('model.colorModel') ? this.get('model.colorModel.colorVocabularyItemId') : null;
    }),

    colorLabel: Ember.computed('colorId', function () {
      return this.get('colorId') ? this.get('vocab.colorsLabels')[this.get('colorId')] : null;
    }),

    excludeIds: Ember.computed('colorId', 'vocab.diamondColors.length', function () {
      var ids = this.get('vocab.diamondColors').mapBy('id');
      if (this.get('colorId')) {
        ids.push(this.get('colorId'));
      }

      return ids;
    }),

    actions: {
      changeColourless: function changeColourless() {
        this.set('model.gemColourless', !this.get('model.gemColourless'));
        this.set('colorId', null);
        this.sendAction('clearEntityColorSection');
        this.sendAction('checkGemProperties');
      },
      changeColor: function changeColor(color1, color2) {
        var color = color1 || color2;
        this.sendAction('clearEntityColorSection');
        if (color && color.get('id')) {
          this.set('colorId', color.get('id'));
          this.sendAction('addEntityColor', color);
        }
      },
      deleteColor: function deleteColor() {
        this.sendAction('clearEntityColorSection');
      }
    }
  });
});