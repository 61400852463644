define('cdata/pods/reports/components/sales-report/utils', ['exports', 'cdata/pods/reports/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mapLotsCount = exports.mapSalesCount = undefined;
  var mapSalesCount = exports.mapSalesCount = (0, _utils.mapNotZeroBy)('sales_count');

  var mapLotsCount = exports.mapLotsCount = (0, _utils.mapNotZeroBy)('lots_count');
});