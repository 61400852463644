define('cdata/pods/components/entities/edit/edit-forms-measurements/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Measurement'), {
    measurements: Ember.computed.filterBy('model.entityMeasurements', 'isDeleted', false),

    addEntityMeasurementValue: 'addEntityMeasurementValue',
    parseMeasurement: 'parseMeasurement',

    actions: {
      addNewValue: function addNewValue() {
        this.sendAction.apply(this, ['addEntityMeasurementValue'].concat(Array.prototype.slice.call(arguments)));
      },
      parseMeasurement: function parseMeasurement() {
        this.sendAction.apply(this, ['parseMeasurement'].concat(Array.prototype.slice.call(arguments)));
      },
      removeMeasurementValue: function removeMeasurementValue(measurementValue) {
        measurementValue.deleteRecord();
      }
    }
  });
});