define('cdata/pods/components/vocabularies/list/set-results/component', ['exports', 'cdata/mixins/lists/set-results'], function (exports, _setResults) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_setResults.default, {
    openForm: 'openForm',
    createNewVocabularyItem: 'createNewVocabularyItem',
    deleteItem: 'deleteItem',
    sortingType: 'tree',

    actions: {
      openForm: function openForm(item) {
        this.sendAction('openForm', item);
      },
      createNewVocabularyItem: function createNewVocabularyItem() {
        this.sendAction.apply(this, ['createNewVocabularyItem'].concat(Array.prototype.slice.call(arguments)));
      },
      deleteItem: function deleteItem() {
        this.sendAction.apply(this, ['deleteItem'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});