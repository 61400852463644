define('cdata/pods/marks/mark-mixin', ['exports', 'cdata/mixins/editor/saving-cases'], function (exports, _savingCases) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_savingCases.default, {
    actions: {
      /*
       * <<<<<< titles >>>>>>>
       */

      addMarkTitle: function addMarkTitle() {
        var model = this.get('currentModel');
        var store = this.get('store');

        var markTitle = store.createRecord('mark-title', {
          markId: model.get('id'),
          mark: model,
          preferred: !model.get('markTitles').filterBy('preferred').get('length')
        });

        model.get('markTitles').pushObject(markTitle);
      },
      removeMarkTitle: function removeMarkTitle(markTitle) {
        this._removeItemWithPreferred(markTitle, 'markTitles');
      },
      setPreferredToMarkTitle: function setPreferredToMarkTitle(markTitle) {
        this._changePreferred('markTitles', markTitle);
      },
      clearMarkTitleSection: function clearMarkTitleSection() {
        this._removeAllItems('markTitles', 'removeMarkTitle');
      },


      /*
       * <<<<<< images >>>>>
       */

      addMarkImage: function addMarkImage() {
        var model = this.get('currentModel');

        var image = this.get('store').createRecord('image', {});

        var markImage = this.get('store').createRecord('mark-image', {
          mark: model,
          markId: model.get('id'),
          preferred: !model.get('markImages').filterBy('preferred').get('length'),
          image: image
        });

        model.get('markImages').pushObject(markImage);
      },
      removeMarkImage: function removeMarkImage(markImage) {
        this._removeItemWithPreferred(markImage, 'markImages');
      },
      setPreferredToMarkImage: function setPreferredToMarkImage(markImage) {
        this._changePreferred('markImages', markImage);
      },
      clearMarkImageSection: function clearMarkImageSection() {
        this._removeAllItems('markImages', 'removeMarkImage');
      },


      /*
       * <<<<<< makers >>>>>>>
       */

      setMakerToMarkMaker: function setMakerToMarkMaker(markMaker, makerSubject) {
        this.get('relations').removeBelongsTo(markMaker, 'person');
        this.get('relations').removeBelongsTo(markMaker, 'organization');

        if ('organization' === makerSubject.get('type')) {
          this.get('store').findRecord('organization', makerSubject.get('id')).then(function (organization) {
            markMaker.set('organization', organization);
            markMaker.set('organizationId', makerSubject.get('id'));

            markMaker.set('_personName', null);
            markMaker.set('_organizationTitle', makerSubject.get('name'));
          });

          return;
        }

        this.get('store').findRecord('person', makerSubject.get('id')).then(function (person) {
          markMaker.set('person', person);
          markMaker.set('personId', makerSubject.get('id'));

          markMaker.set('_personName', makerSubject.get('name'));
          markMaker.set('_organizationTitle', null);
        });
      },
      addMarkMaker: function addMarkMaker() {
        var model = this.get('currentModel');
        var store = this.get('store');

        var markMaker = store.createRecord('mark-maker', {
          markId: model.get('id'),
          mark: model,
          preferred: !model.get('markMakers').filterBy('preferred').get('length')
        });

        model.get('markMakers').pushObject(markMaker);
      },
      removeMarkMaker: function removeMarkMaker(markMaker) {
        this._removeItemWithPreferred(markMaker, 'markMakers', true);
      },
      setPreferredToMarkMaker: function setPreferredToMarkMaker(markMaker) {
        this._changePreferred('markMakers', markMaker, true);
      },
      clearMarkMakerSection: function clearMarkMakerSection() {
        this._removeAllItems('markMakers', 'removeMarkMaker');
      },


      /*
       * <<<<<< origin places >>>>>>>
       */

      addMarkPlace: function addMarkPlace(subjectPlace) {
        var model = this.get('currentModel');
        var store = this.get('store');

        this.get('store').findRecord('place', subjectPlace.get('id')).then(function (place) {
          var markMakerPlace = store.createRecord('mark-place', {
            markId: model.get('id'),
            mark: model,
            placeId: subjectPlace.get('id'),
            place: place
          });

          markMakerPlace.buildName();

          model.get('markPlaces').pushObject(markMakerPlace);
        });
      },
      removeMarkPlace: function removeMarkPlace(markMakerPlace) {
        this._removeItemWithPreferred(markMakerPlace, 'markPlaces');
      },
      clearMarkPlaceSection: function clearMarkPlaceSection() {
        this._removeAllItems('markPlaces', 'removeMarkPlace');
      },


      /*
       * <<<<<< symbols >>>>>>>
       */

      addMarkSymbol: function addMarkSymbol(symbol) {
        var model = this.get('currentModel');
        var store = this.get('store');

        var markSymbol = store.createRecord('mark-symbol', {
          markId: model.get('id'),
          mark: model,
          symbolVocabularyItemId: symbol.get('id')
        });

        model.get('markSymbols').pushObject(markSymbol);
      },
      removeMarkSymbol: function removeMarkSymbol(markSymbol) {
        this._removeItemWithPreferred(markSymbol, 'markSymbols');
      },
      clearMarkSymbolSection: function clearMarkSymbolSection() {
        this._removeAllItems('markSymbols', 'removeMarkSymbol');
      },


      /*
       * <<<<<< notes >>>>>>>
       */

      addMarkNote: function addMarkNote(text) {
        var model = this.get('currentModel');
        var store = this.get('store');

        var markNote = store.createRecord('mark-note', {
          markId: model.get('id'),
          mark: model,
          text: text
        });

        model.get('markNotes').pushObject(markNote);
      },
      removeMarkNote: function removeMarkNote(markNote) {
        this._removeItemWithPreferred(markNote, 'markNotes');
      },
      clearMarkNoteSection: function clearMarkNoteSection() {
        this._removeAllItems('markNotes', 'removeMarkNote');
      },


      /*
       * <<<<<< letters >>>>>>>
       */

      addMarkLetter: function addMarkLetter(letter) {
        var model = this.get('currentModel');
        var store = this.get('store');

        var markMakerLetter = store.createRecord('mark-letter', {
          markId: model.get('id'),
          mark: model,
          letter: letter.toUpperCase()
        });

        model.get('markLetters').pushObject(markMakerLetter);
      },
      removeMarkLetter: function removeMarkLetter(markMakerLetter) {
        this._removeItemWithPreferred(markMakerLetter, 'markLetters');
      },
      clearMarkLetterSection: function clearMarkLetterSection() {
        this._removeAllItems('markLetters', 'removeMarkLetter');
      },


      /*
       * <<<<<< materials >>>>>>>
       */

      addMarkMaterial: function addMarkMaterial(subjectMaterial) {
        var model = this.get('currentModel');
        var store = this.get('store');

        var markMakerMaterial = store.createRecord('mark-material', {
          markId: model.get('id'),
          mark: model,
          materialVocabularyItemId: subjectMaterial.get('id')
        });

        model.get('markMaterials').pushObject(markMakerMaterial);
      },
      removeMarkMaterial: function removeMarkMaterial(markMakerMaterial) {
        this._removeItemWithPreferred(markMakerMaterial, 'markMaterials');
      },
      clearMarkMaterialSection: function clearMarkMaterialSection() {
        this._removeAllItems('markMaterials', 'removeMarkMaterial');
      },


      /*
       * <<<<<< shapes >>>>>>>
       */

      addMarkShape: function addMarkShape(subjectShape) {
        var model = this.get('currentModel');
        var store = this.get('store');

        var markMakerShape = store.createRecord('mark-shape', {
          markId: model.get('id'),
          mark: model,
          shapeVocabularyItemId: subjectShape.get('id')
        });

        model.get('markShapes').pushObject(markMakerShape);
      },
      removeMarkShape: function removeMarkShape(markMakerShape) {
        this._removeItemWithPreferred(markMakerShape, 'markShapes');
      },
      clearMarkShapeSection: function clearMarkShapeSection() {
        this._removeAllItems('markShapes', 'removeMarkShape');
      },


      /*
       * <<<<<< categories >>>>>>>
       */

      addMarkCategory: function addMarkCategory(id) {
        var model = this.get('currentModel');
        var store = this.get('store');

        var markMakerCategory = store.createRecord('mark-category', {
          markId: model.get('id'),
          mark: model,
          cdataCategoryVocabularyItemId: id
        });

        model.get('markCategories').pushObject(markMakerCategory);
      },
      removeMarkCategory: function removeMarkCategory(markMakerCategory) {
        this._removeItemWithPreferred(markMakerCategory, 'markCategories');
      },
      clearMarkCategorySection: function clearMarkCategorySection() {
        this._removeAllItems('markCategories', 'removeMarkCategory');
      },


      /*
       * <<<<<< globals >>>>>>>
       */

      cancel: function cancel() {
        var _this = this;

        var model = this.get('currentModel');
        model.rollbackAttributes();

        this.get('relations').rollbackHasMany(model);

        model.get('markMakers').forEach(function (maker) {
          _this.get('relations').resetBelongsTo(maker, 'person');
          _this.get('relations').resetBelongsTo(maker, 'organization');
        });

        this._clearEditor();
      },
      save: function save() {
        var _this2 = this;

        this._clean();

        var model = this.get('currentModel');

        model.get('markImages').forEach(function (item) {
          _this2._saveRelationWithImage(item);
        });

        var rels = ['markTitles', 'markShapes', 'markLetters', 'markSymbols', 'markNotes', 'markMaterials', 'markPlaces', 'markCategories', 'markMakers'];

        // saving simple has many relations
        this._handleSaving(this.get('relations').deleteSimpleHasMany(model, rels)).then(function () {
          _this2._handleSaving(_this2.get('relations').saveSimpleHasMany(model, rels)).then(function (items) {
            if (!items) {
              return;
            }

            // clear saved 'belongs to' from relations
            items.forEach(function (item) {
              if (item.constructor.modelName === 'mark-maker') {
                _this2.get('relations').clearBelongsTo(item, 'person');
                _this2.get('relations').clearBelongsTo(item, 'organization');
              }
            });
          });

          if (model.get('hasDirtyAttributes')) {
            _this2._handleSaving(model.save());
          } else {
            _this2._handleSaving();
          }
        });
      }
    },

    _clean: function _clean() {
      this._removeItemsByEmptyField('markTitles', 'text', 'removeMarkTitle');
      this._removeItemsByEmptyField('markMakers', ['personId', 'organizationId'], 'removeMarkMaker');
      this._removeItemsByEmptyField('markImages', 'image.url', 'removeMarkImage');
      this._removeItemsByEmptyField('markCategories', 'cdataCategoryVocabularyItemId', 'removeMarkCategory');
      this._removeItemsByEmptyField('markShapes', 'shapeVocabularyItemId', 'removeMarkShape');
      this._removeItemsByEmptyField('markMaterials', 'materialVocabularyItemId', 'removeMarkMaterial');
      this._removeItemsByEmptyField('markLetters', 'letter', 'removeMarkLetter');
      this._removeItemsByEmptyField('markSymbols', 'symbolVocabularyItemId', 'removeMarkSymbol');
      this._removeItemsByEmptyField('markNotes', 'text', 'removeMarkNote');
      this._removeItemsByEmptyField('markPlaces', 'placeId', 'removeMarkPlace');
    }
  });
});