define('cdata/models/export', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    name: attr('string'),
    filter: attr('none'),
    module: attr('string'),
    options: attr('none'),
    link: attr('string'),
    requestedById: attr('string')
  });
});