define('cdata/pods/stats/controller', ['exports', 'moment', 'cdata/mixins/components/cdata-category', 'cdata/utils/auction-houses-options', 'cdata/utils/computed'], function (exports, _moment, _cdataCategory, _auctionHousesOptions, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend(_cdataCategory.default, {
    ajax: Ember.inject.service(),

    item: Ember.Object.create({}),

    creator: null,
    creatorName: Ember.computed.alias('creator.name'),

    auctionHouses: [],
    auctionHouseIds: Ember.computed.mapBy('auctionHouses', 'id'),

    auctionHousesOptions: (0, _auctionHousesOptions.getAuctionHousesOptions)(),
    auctionHousesVisible: (0, _computed.computedWithoutByIds)('auctionHousesOptions', 'auctionHouseIds'),

    saleDateFrom: null,
    saleDateTo: null,

    priceFrom: null,
    priceTo: null,

    stats: null,

    loading: false,

    actions: {
      submit: function submit() {
        var _this = this;

        this.set('loading', true);

        var modifier_uuids = this.get('creator.uuid') ? this.get('creator.modifier_uuid') ? [this.get('creator.modifier_uuid')] : ['null'] : [];

        var params = {
          auction_house_uuids: this.get('auctionHouseIds'),
          session_start_date_from: this.get('saleDateFrom'),
          session_start_date_to: this.get('saleDateTo'),
          cdata_category_uuids: this.get('item.uuid') ? [this.get('item.uuid')] : [],
          maker_uuids: this.get('creator.uuid') ? [this.get('creator.uuid')] : [],
          maker_qualifier_uuids: modifier_uuids,
          estimate_usd_from: this.get('priceFrom'),
          estimate_usd_to: this.get('priceTo')
        };

        var req_params = {};
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Object.entries(params)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _ref = _step.value;

            var _ref2 = _slicedToArray(_ref, 2);

            var key = _ref2[0];
            var value = _ref2[1];

            if (!Ember.isEmpty(value)) {
              req_params[key] = value;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        this.get('ajax').$get('lots/stats', req_params, function (result) {
          console.log(result);
          console.log(JSON.stringify(result));
          _this.set('stats', result.stats);
          _this.set('loading', false);
        }, function () {
          _this.set('loading', false);
        }, function () {
          _this.set('loading', false);
        });
      },
      setCdataCategory: function setCdataCategory(category) {
        this.set('item.cdataCategoryVocabularyItemId', category && category.get('id'));
        this.send('loadCdataCategory', this.get('item'));
      },
      setCreator: function setCreator(_, item) {
        this.set('creator', Ember.Object.create({
          creator_qualifier_vocabulary_item_id: item.creator_qualifier_vocabulary_item_id,
          name: item.name,
          id: item.id
        }));

        this.send('loadCreator', this.get('creator'));
      },
      addAuctionHouse: function addAuctionHouse(_ref3) {
        var id = _ref3.id,
            label = _ref3.label;

        var auctionHouses = this.get('auctionHouses');
        var isExist = Boolean(auctionHouses.findBy('id', id));

        if (!isExist) {
          var item = Ember.Object.create({ id: id, label: label });

          auctionHouses.pushObject(item);
        }
      },
      removeAuctionHouse: function removeAuctionHouse(item) {
        this.get('auctionHouses').removeObject(item);
      },
      setDateFrom: function setDateFrom(date) {
        this.set('saleDateFrom', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
      },
      setDateTo: function setDateTo(date) {
        this.set('saleDateTo', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
      }
    }
  });
});