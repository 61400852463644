define('cdata/pods/components/people/edit/edit-forms-memberships/item/component', ['exports', 'cdata/pods/components/people/edit/mixins/edit-forms-item', 'cdata/helpers/format-education', 'cdata/pods/components/organizations/mixins/handle_new_organization'], function (exports, _editFormsItem, _formatEducation, _handle_new_organization) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsItem.default, _handle_new_organization.default, {
    modal: Ember.inject.service(),

    roleLabels: Ember.computed.alias('vocab.rolesLabels'),

    openVocabularyNewItemForm: 'openVocabularyNewItemForm',
    openNewOrganizationForm: 'openNewOrganizationForm',
    loadOrganizationFor: 'loadOrganizationFor',

    membershipTitle: Ember.computed('model.{_title,_place,dateText,personRoleVocabularyItemId}', function () {
      return _formatEducation.default.compute([this.get('model'), false], {
        rolesLabels: this.get('roleLabels')
      });
    }),

    actions: {
      setOrganization: function setOrganization(_, organizationSubject) {
        this.sendAction('loadOrganizationFor', this.get('model'), organizationSubject.get('id'));
      },
      openVocabularyNewItemForm: function openVocabularyNewItemForm() {
        this.sendAction.apply(this, ['openVocabularyNewItemForm'].concat(Array.prototype.slice.call(arguments)));
      },
      openNewOrganizationForm: function openNewOrganizationForm() {
        this.sendAction.apply(this, ['openNewOrganizationForm'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});