define('cdata/pods/components/organizations/view/organization-item/summary-info/component', ['exports', 'ember-data', 'cdata/mixins/components/summary-info-comments', 'cdata/mixins/components/render-with-citation', 'cdata/utils/clustering'], function (exports, _emberData, _summaryInfoComments, _renderWithCitation, _clustering) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var PromiseArray = _emberData.default.PromiseArray;


  var escape = Ember.Handlebars.Utils.escapeExpression;
  exports.default = Ember.Component.extend(_summaryInfoComments.default, _renderWithCitation.default, {
    classNames: 'organization-item--info',
    tagName: 'article',

    openOrganizationForms: 'openOrganizationForms',
    save: 'save',

    actions: {
      openForms: function openForms() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.sendAction.apply(this, ['openOrganizationForms'].concat(_toConsumableArray(args)));
      },
      setApproved: function setApproved() {
        this.set('model.approved', true);
        this.sendAction('save');
      },
      setNew: function setNew() {
        this.set('model.approved', false);
        this.sendAction('save');
      }
    },

    preferredTitle: Ember.computed('model.organizationTitles.@each.{preferred,text}', function () {
      var organizationTitles = this.get('model.organizationTitles').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, organizationTitles);
    }),

    otherTitles: Ember.computed('model.organizationTitles.@each.{preferred,text}', function () {
      var organizationTitles = this.get('model.organizationTitles').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, organizationTitles);
    }),

    preferredRole: Ember.computed('model.organizationRoles.@each.{preferred,roleId}', function () {
      var organizationRoles = this.get('model.organizationRoles').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('role.name');
      }, organizationRoles);
    }),

    otherRoles: Ember.computed('model.organizationRoles.@each.{preferred,roleId}', function () {
      var organizationRoles = this.get('model.organizationRoles').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('role.name');
      }, organizationRoles);
    }),

    preferredNationality: Ember.computed('model.organizationNationalities.@each.{preferred,nationalityVocabularyItemId}', 'vocab.nationalitiesLabels', function () {
      var _this = this;

      var nationalities = this.get('model.organizationNationalities').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return _this.get('vocab.nationalitiesLabels.' + item.get('nationalityVocabularyItemId'));
      }, nationalities);
    }),

    otherNationalities: Ember.computed('model.organizationNationalities.@each.{preferred,nationalityVocabularyItemId}', 'vocab.nationalitiesLabels', function () {
      var _this2 = this;

      var nationalities = this.get('model.organizationNationalities').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return _this2.get('vocab.nationalitiesLabels.' + item.get('nationalityVocabularyItemId'));
      }, nationalities);
    }),

    preferredDate: Ember.computed('model.organizationExistenceDates.@each.{preferred,text}', function () {
      var dates = this.get('model.organizationExistenceDates').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, dates);
    }),

    otherDates: Ember.computed('model.organizationExistenceDates.@each.{preferred,text}', function () {
      var dates = this.get('model.organizationExistenceDates').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, dates);
    }),

    preferredDescription: Ember.computed('model.organizationDescriptions.@each.{preferred,text}', function () {
      var descriptions = this.get('model.organizationDescriptions').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, descriptions);
    }),

    otherDescriptions: Ember.computed('model.organizationDescriptions.@each.{preferred,text}', function () {
      var descriptions = this.get('model.organizationDescriptions').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, descriptions);
    }),

    preferredChristiesSpecialistDepartments: Ember.computed('model.organizationChristiesSpecialistDepartments.@each.{preferred,christiesSpecialistDepartmentVocabularyItemId}', function () {
      var dep = this.get('model.organizationChristiesSpecialistDepartments').filterBy('preferred').get('firstObject');

      return dep && this.get('vocab.christiesSpecialistsLabels')[dep.get('christiesSpecialistDepartmentVocabularyItemId')];
    }),

    othersChristiesSpecialistDepartments: Ember.computed('model.organizationChristiesSpecialistDepartments.@each.{preferred,christiesSpecialistDepartmentVocabularyItemId}', function () {
      var _this3 = this;

      var deps = this.get('model.organizationChristiesSpecialistDepartments').rejectBy('preferred');

      return deps.map(function (item) {
        return _this3.get('vocab.christiesSpecialistsLabels.' + item.get('christiesSpecialistDepartmentVocabularyItemId'));
      });
    }),

    jdeDepartments: Ember.computed('model.organizationJdeDepartments.@each.jdeDepartmentVocabularyItemId', function () {
      var _this4 = this;

      var deps = this.get('model.organizationJdeDepartments');

      return deps.map(function (item) {
        return _this4.get('vocab.jdeDepartmentsLabels.' + item.get('jdeDepartmentVocabularyItemId'));
      });
    }),

    threeDigitCodes: Ember.computed('model._threeDigitCodes.@each', function () {
      var _this5 = this;

      var codes = this.get('model._threeDigitCodes') || [];

      return codes.map(function (code) {
        return _this5.get('vocab.threeDigitCodesLabels')[code];
      }).join(', ');
    }),

    addresses: Ember.computed('model.organizationAddresses.@each.{location,locationId,contactTypeVocabularyItemId,citationLabelVocabularyItemId,userId}', 'displaySettings', function () {
      var addresses = this.get('model.organizationAddresses').rejectBy('deleted').reject(this._isChartOfArt.bind(this));

      return this._prepareLocations(addresses);
    }),

    addresses2: Ember.computed('model.organizationAddresses.@each.{location,locationId,contactTypeVocabularyItemId,citationLabelVocabularyItemId,userId}', 'displaySettings', function () {
      var addresses = this.get('model.organizationAddresses').rejectBy('deleted').reject(this._isNotChartOfArt.bind(this));

      return this._prepareLocations(addresses);
    }),

    _prepareLocations: function _prepareLocations(addresses) {
      var _this6 = this;

      var locationPromises = addresses.getEach('location');
      var promise = Ember.RSVP.all(locationPromises).then(function (locations) {
        return locations.map(function (loc) {
          var addr_name = loc.get('_name') + ' / ' + loc.get('address');
          var address = addresses.findBy('locationId', loc.get('id'));
          var suffix = _this6._getSourceUserString(address);

          return suffix ? Ember.String.htmlSafe(escape(addr_name) + suffix) : addr_name;
        });
      });

      return PromiseArray.create({
        promise: promise
      });
    }
  });
});