define('cdata/pods/components/controls/batch-model-errors/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['batch-model-errors'],
    classNameBindings: ['enabled'],

    enabled: Ember.computed.notEmpty('errors'),

    modelErrors: Ember.computed('model.errors.[]', function () {
      var errors = this.get('model.errors');
      if (!errors || errors.length === 0) {
        return [];
      }

      return errors.toArray();
    }),

    modelsErrors: Ember.computed('errorModels.length', function () {
      var models = this.get('errorModels');
      if (!models || models.length === 0) {
        return [];
      }

      var errors = [];
      models.forEach(function (model) {
        var modelErrors = model.get('errors');
        if (!modelErrors || modelErrors.length === 0) {
          return;
        }
        errors = errors.concat(modelErrors.toArray());
      });

      return errors;
    }),

    errorModels: Ember.computed('models.@each.{isError,isDeleted,dirtyType}', function () {
      var models = this.get('models');
      if (!Ember.isArray(models)) {
        return [];
      }

      return models;
    }),

    errors: Ember.computed('modelErrors.[]', 'modelsErrors.[]', function () {
      return this.get('modelErrors').concat(this.get('modelsErrors'));
    })
  });
});