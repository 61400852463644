define('cdata/pods/components/marks/edit/edit-forms-categories/component', ['exports', 'cdata/pods/components/marks/edit/mixins/edit-forms', 'cdata/mixins/components/cdata-category'], function (exports, _editForms, _cdataCategory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Category'), _cdataCategory.default, {
    autoCreating: false,
    cdataCategoryVocabularyItemId: null,

    item: Ember.computed.alias('model'),

    categories: Ember.computed.filterBy('model.markCategories', 'isDeleted', false),

    actions: {
      setCategory: function setCategory() {
        if (this.get('cdataCategoryId') > 0) {
          this.send('add', this.get('cdataCategoryId'));
        }
        this.set('model.cdataCategoryVocabularyItemId', null);
      }
    }
  });
});