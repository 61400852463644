define('cdata/pods/components/people/edit/edit-forms-birth-places/component', ['exports', 'cdata/pods/components/people/edit/mixins/edit-forms', 'cdata/pods/components/places/mixins/handle_new_place'], function (exports, _editForms, _handle_new_place) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('BirthPlace'), _handle_new_place.default, {
    placesIds: Ember.computed.mapBy('items', 'placeId'),

    autoCreating: false,
    place: null,
    citationId: null,

    subjectFilter: { clear: true },

    placeLabel: Ember.computed('place.id', function () {
      var name = this.get('place.name');
      if (!name) {
        return null;
      }

      if (this.get('place.description')) {
        name = name + ', ' + this.get('place.description');
      }

      return name;
    }),

    actions: {
      setPlace: function setPlace(_, item) {
        this.set('place', item);
      },
      setCitationId: function setCitationId(citationId) {
        this.set('citationId', citationId);
      },
      add: function add() {
        this.sendAction('addPersonBirthPlace', this.get('place.id'), this.get('citationId'));
        this.set('place', null);
        this.set('citationId', null);
      }
    }
  });
});