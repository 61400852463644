define('cdata/mixins/lists/set-results', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['list-set-results'],

    bulkCheckItem: 'bulkCheckItem',
    saveItem: 'saveItem',

    actions: {
      bulkCheckItem: function bulkCheckItem() {
        this.sendAction.apply(this, ['bulkCheckItem'].concat(Array.prototype.slice.call(arguments)));
      },
      saveItem: function saveItem(item) {
        this.sendAction('saveItem', item);
      }
    }
  });
});