define('cdata/pods/lots/show/route', ['exports', 'cdata/pods/lots/lot-mixin-show', 'cdata/mixins/audit-logs/audit-log-route', 'cdata/pods/lots/review-mixin'], function (exports, _lotMixinShow, _auditLogRoute, _reviewMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_lotMixinShow.default, _auditLogRoute.default, _reviewMixin.default, {
    _needReloadAfterSave: false,

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.initLotReview(controller, model);
    },


    actions: {
      reloadAfterSave: function reloadAfterSave() {
        this._needReloadAfterSave = true;
      }
    },

    _onAfterSaveAll: function _onAfterSaveAll() {
      var _this = this;

      if (this._needReloadAfterSave) {
        this._needReloadAfterSave = false;

        Ember.run.later(this, function () {
          _this.get('store').findRecord('lot', _this.get('currentModel.id'));
        }, 1000);
      }
    }
  });
});