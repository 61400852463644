define('cdata/pods/components/controls/input-text/component', ['exports', 'cdata/pods/components/controls/input-mixin'], function (exports, _inputMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputMixin.default, {
    tagName: 'input',
    type: 'text',
    classNames: [],
    required: false,
    disabled: false,
    attributeBindings: ['type', 'placeholder', 'name', 'required', 'disabled', 'autocomplete'],
    classNameBindings: ['isFilled:filled'],
    filled: false,
    isFilled: Ember.computed.bool('filled')
  });
});