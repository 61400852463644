define('cdata/pods/components/people/list/set-results/item/item-mixin', ['exports', 'cdata/mixins/lists/set-results-item'], function (exports, _setResultsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var escape = Ember.Handlebars.Utils.escapeExpression;

  exports.default = Ember.Mixin.create(_setResultsItem.default, {
    about: Ember.computed('item.{nationality,vitalDates,role}', function () {
      var nationality = escape(this.get('item.nationality'));
      var dates = escape(this.get('item.vitalDates'));
      var role = escape(this.get('item.role'));

      var result = [];

      if (role) {
        result.push('<span><strong>' + role + '</strong></span>');
      }

      if (nationality) {
        result.push('<span>' + nationality + '</span>');
      }

      if (dates) {
        result.push('<span>' + dates + '</span>');
      }

      return result.join('<br>').htmlSafe();
    })
  });
});