define('cdata/pods/components/lots/edit/menu-context/component', ['exports', 'cdata/mixins/components/menu-context', 'ember-local-storage'], function (exports, _menuContext, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_menuContext.default, {
    reviewMode: (0, _emberLocalStorage.storageFor)('review-mode'),
    comparableSets: (0, _emberLocalStorage.storageFor)('comparable-sets'),

    sets: false,
    export: false,
    sources: false,

    openExportLotsForm: 'openExportLotsForm',
    openSelectSetForm: 'openSelectSetForm',
    userActions: Ember.inject.service(),

    pricesEnabled: Ember.computed.alias('userActions.predictedPrices.settings.enabled'),
    setsEnabled: Ember.computed.alias('comparableSets.settings.enabled'),
    reviewEnabled: Ember.computed.alias('reviewMode.settings.enabled'),

    sourceTagsEnabled: Ember.computed.alias('userActions.sourceTags.settings.enabledTags'),
    sourceUsersEnabled: Ember.computed.alias('userActions.sourceTags.settings.enabledUsers'),

    actions: {
      openExportLotsForm: function openExportLotsForm() {
        this.sendAction('openExportLotsForm');
        this.closeContextMenuAction();
      },
      togglePredictedPrices: function togglePredictedPrices() {
        this.get('userActions').toggleShowPredictedPrices();
      },
      toggleSourceTags: function toggleSourceTags() {
        this.get('userActions').toggleSourceTags();
      },
      toggleSourceUsers: function toggleSourceUsers() {
        this.get('userActions').toggleSourceUsers();
      },
      enableReviewMode: function enableReviewMode() {
        this.get('reviewMode').set('settings.enabled', true);
        this.closeContextMenuAction();
      },
      disableReviewMode: function disableReviewMode() {
        this.get('reviewMode').set('settings.enabled', false);
        this.closeContextMenuAction();
      },
      enableSetsMode: function enableSetsMode() {
        this.sendAction('openSelectSetForm');
        this.closeContextMenuAction();
      },
      disableSetsMode: function disableSetsMode() {
        this.get('comparableSets').set('settings.enabled', false);
        this.get('comparableSets').set('settings.setId', null);
        this.closeContextMenuAction();
      }
    }
  });
});