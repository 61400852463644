define('cdata/pods/comparables/list/route', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var url = _constants.API_URL_SERVICES + '/value_explorer/value_item';
  var headers = _constants.API_SERVICES_HEADERS;

  exports.default = Ember.Route.extend({
    comparable: Ember.inject.service(),

    queryParams: {
      query: {
        refreshModel: false
      },
      page: {
        refreshModel: false
      }
    },

    model: function model() {
      return [];
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.initParameters();
    },


    actions: {
      gotoPage: function gotoPage(link) {
        var _this = this;

        this._progress(true);
        this.set('controller.page', this.get('meta.page'));

        Ember.$.ajax({
          url: link + '&ids_only=False',
          method: 'GET',
          crossDomain: true,
          dataType: 'json',
          headers: headers,
          success: this._results.bind(this),
          complete: this._complete.bind(this)
        }).fail(function (error) {
          _this.get('flashMessage').showError(error);
        });
      },
      show: function show() {
        var _this2 = this;

        if (this._progress()) {
          return;
        }

        var hasChanged = this._query();
        this._progress(true);

        var params = this.getCleanParams();
        var page = this.get('controller.page');

        if (page > 1) {
          params.page = page;
        }

        if (!hasChanged && !Ember.isEmpty(this.get('controller.comparables'))) {
          this._progress(false);

          return;
        }

        params.christies_only = false;
        params.only_upcoming_lots = false;

        Ember.$.ajax({
          url: url,
          method: 'POST',
          crossDomain: true,
          data: JSON.stringify(params || {}),
          dataType: 'json',
          headers: headers,
          success: this._results.bind(this),
          complete: this._complete.bind(this)
        }).fail(function (error) {
          _this2.get('flashMessage').showError(error);
        });
      },
      gotoItem: function gotoItem(item) {
        this.get('comparable').set('item', this.get('comparable').parseItemForValueExplorer(item));
        this.transitionTo('comparables.item');
      }
    },

    _results: function _results(data) {
      var objects = data && data.message && data.message.COMPARABLES && data.message.COMPARABLES.results;
      var message = null;
      var meta = null;
      var valuation = {};

      if (objects) {
        meta = data.message.COMPARABLES.meta;
        valuation = {
          FMV: data.message && data.message.FMV,
          RRV: data.message && data.message.RRV,
          MTM: data.message && data.message.MTM
        };
      } else if (!objects && data.results) {
        objects = data.results;
        valuation = this.get('controller.valuation');
        meta = data.meta;
      } else {
        objects = [];
        if (typeof data.message === 'string') {
          message = data.message;
          this.get('flashMessage').showError(message);
        }
      }

      this.get('controller').setProperties({
        meta: meta,
        message: message,
        comparables: objects,
        valuation: valuation,
        page: meta && meta.page > 1 ? meta.page : undefined
      });
    },
    _query: function _query() {
      var params = this.get('controller.params');
      var lastQuery = JSON.stringify(params);
      var hasChanged = lastQuery !== this.get('controller.query');
      this.get('controller').set('query', JSON.stringify(params));

      return hasChanged;
    },
    _progress: function _progress() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if (null === value) {
        return this.get('controller.inProgress');
      }
      this.get('controller').set('inProgress', value);
    },
    _complete: function _complete() {
      this._progress(false);
    },
    getCleanParams: function getCleanParams() {
      var params = this.get('controller.params');
      var result = {};

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(params)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];

          if (value) {
            result[key] = value;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return result;
    }
  });
});