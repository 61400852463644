define('cdata/pods/stats/route', ['exports', 'cdata/mixins/route-init'], function (exports, _routeInit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeInit.default, {
    model: function model() {
      return {};
    },


    actions: {
      loadCreator: function loadCreator(creator) {
        var _this = this;

        this.get('store').findRecord('person', creator.get('id')).then(function (person) {
          creator.set('uuid', person.get('uuid'));

          if (creator.get('creator_qualifier_vocabulary_item_id')) {
            _this.get('vocab').findItemById(creator.get('creator_qualifier_vocabulary_item_id')).then(function (item) {
              creator.set('modifier_uuid', item.get('uuid'));
            });
          }
        });
      },
      loadCdataCategory: function loadCdataCategory(category) {
        this.get('vocab').findItemById(category.get('cdataCategoryVocabularyItemId')).then(function (item) {
          category.set('uuid', item.get('uuid'));
        });
      }
    }
  });
});