define('cdata/pods/components/organizations/edit/edit-forms-addresses/accept-location/declaration', ['exports', 'ember-prop-types'], function (exports, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    onApprove: _emberPropTypes.PropTypes.func.isRequired,
    onDeny: _emberPropTypes.PropTypes.func.isRequired,
    onDefault: _emberPropTypes.PropTypes.func.isRequired
  };
});