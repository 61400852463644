define('cdata/pods/components/entities/edit/edit-forms-lists/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms', 'cdata/services/natural-sorter'], function (exports, _editForms, _naturalSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), {
    addEntityColor: 'addEntityColor',
    removeEntityColor: 'removeEntityColor',

    addEntityShape: 'addEntityShape',
    removeEntityShape: 'removeEntityShape',

    addEntityStyle: 'addEntityStyle',
    removeEntityStyle: 'removeEntityStyle',

    addEntitySubjectMatter: 'addEntitySubjectMatter',
    removeEntitySubjectMatter: 'removeEntitySubjectMatter',

    colors: Ember.computed.filterBy('model.entityColors', 'isDeleted', false),
    colorsSorted: Ember.computed('colors.@each', function () {
      var _this = this;

      this.get('colors').forEach(function (item) {
        item.set('label', _this.get('vocab.colorsLabels')[item.get('colorVocabularyItemId')]);
      });

      return this.get('colors').sort((0, _naturalSorter.default)('label'));
    }),

    shapes: Ember.computed.filterBy('model.entityShapes', 'isDeleted', false),
    shapesSorted: Ember.computed('shapes.@each', function () {
      var _this2 = this;

      this.get('shapes').forEach(function (item) {
        item.set('label', _this2.get('vocab.shapesLabels')[item.get('shapeVocabularyItemId')]);
      });

      return this.get('shapes').sort((0, _naturalSorter.default)('label'));
    }),

    styles: Ember.computed.filterBy('model.entityStyles', 'isDeleted', false),
    stylesSorted: Ember.computed('styles.@each', function () {
      var _this3 = this;

      this.get('styles').forEach(function (item) {
        item.set('label', _this3.get('vocab.stylesLabels')[item.get('styleVocabularyItemId')]);
      });

      return this.get('styles').sort((0, _naturalSorter.default)('label'));
    }),

    subjectMatters: Ember.computed.filterBy('model.entitySubjectMatters', 'isDeleted', false),
    subjectMattersSorted: Ember.computed('subjectMatters.@each', function () {
      var _this4 = this;

      this.get('subjectMatters').forEach(function (item) {
        item.set('label', _this4.get('vocab.subjectMattersLabels')[item.get('subjectMatterVocabularyItemId')]);
      });

      return this.get('subjectMatters').sort((0, _naturalSorter.default)('label'));
    }),

    actions: {
      addColor: function addColor(value) {
        this.sendAction('addEntityColor', value);
      },
      removeColor: function removeColor(value) {
        this.sendAction('removeEntityColor', value);
      },
      addShape: function addShape(value) {
        this.sendAction('addEntityShape', value);
      },
      removeShape: function removeShape(value) {
        this.sendAction('removeEntityShape', value);
      },
      addStyle: function addStyle(value) {
        this.sendAction('addEntityStyle', value);
      },
      removeStyle: function removeStyle(value) {
        this.sendAction('removeEntityStyle', value);
      },
      addSubjectMatter: function addSubjectMatter(value) {
        this.sendAction('addEntitySubjectMatter', value);
      },
      removeSubjectMatter: function removeSubjectMatter(value) {
        this.sendAction('removeEntitySubjectMatter', value);
      }
    }
  });
});