define('cdata/helpers/value-converter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hexForColor = hexForColor;
  exports.symbolForISO = symbolForISO;
  var ColorMappings = {
    'red': '#EA1B25',
    'orange': '#FFA650',
    'yellow': '#FAE857',
    'green': '#00D255',
    'blue': '#291AED',
    'purple': '#9738AA'
  };

  var CurrencyMappings = {
    gbp: '£',
    usd: '$',
    eur: '€',
    frf: 'Fr',
    dem: 'DM',
    chf: 'CHF',
    sek: 'kr',
    itl: '₤',
    nlg: 'ƒ',
    aud: 'A$',
    dkk: 'kr.',
    cad: 'C$',
    bef: 'fr.',
    ats: 'öS',
    hkd: 'HK$',
    esp: 'Pta',
    nzd: '$',
    iep: '£',
    cny: 'CNY',
    nok: 'kr',
    zar: 'R',
    huf: 'Ft',
    fim: 'mk',
    sgd: 'S$',
    pln: 'zł',
    mxn: '$',
    inr: '₹',
    twd: 'NT$',
    brl: 'Ɽ',
    czk: 'Kč',
    jpy: 'JPY',
    ils: '₪',
    grd: 'GRD',
    idr: 'Rp',
    ars: '$',
    skk: 'Sk',
    mad: 'MAD',
    vef: 'Bs. F',
    veb: 'Bs.',
    ngn: '₦',
    rur: '₽',
    aed: '.إ',
    krw: '₩',
    myr: 'RM',
    sar: 'ريال',
    php: '₱'
  };

  function hexForColor(color) {
    return ColorMappings[color] || color;
  }

  function symbolForISO(isoCurrency) {
    var currency = isoCurrency && isoCurrency.toLowerCase();
    if (CurrencyMappings.hasOwnProperty(currency)) {
      return CurrencyMappings[currency];
    } else {
      return isoCurrency;
    }
  }
});