define('cdata/pods/components/controls/input-limit/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'input-limit-indicator',
    classNameBindings: ['active'],
    text: '',
    limit: 140,
    timeout: false,
    always: false,

    active: Ember.computed('timeout', 'always', function () {
      return this.get('timeout') || this.get('always');
    }),

    left: Ember.computed('text.length', function () {
      var _this = this;

      var length = this.get('text.length');

      if (!length) {
        length = 0;

        return this.get('limit');
      }

      Ember.run.schedule('afterRender', this, function () {
        _this.set('timeout', true);
      });

      length = parseInt(length);
      var size = parseInt(this.get('limit'));
      var limit = size - length;

      Ember.run.schedule('afterRender', this, function () {
        _this.set('always', limit === 0);
      });

      Ember.run.cancel(this.get('later'));
      this.set('later', Ember.run.later(this, function () {
        _this.set('timeout', false);
      }, 3000));

      if (limit < 0) {
        return 0;
      }

      return limit;
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this.get('later'));
    }
  });
});