define('cdata/mixins/route-type-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _pageType: 'main',

    pageType: Ember.inject.service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('pageType').setType(this.get('_pageType'));
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.get('pageType').clear();
    }
  });
});