define('cdata/pods/components/controls/select-item/component', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modal: Ember.inject.service(),
    tagName: 'select',
    classNames: [],
    attributeBindings: ['disabled'],
    openVocabularyNewItemForm: 'openVocabularyNewItemForm',

    /**
     * First empty option label
     */
    prompt: '',

    /**
     * Show this prompt option if empty current selection
     * After select one of items, default empty value will be hidden
     */
    promptForEmpty: '',

    /**
     * Set to true if you want reset selected value after handle
     */
    resetAfterSelect: false,

    /**
     * Path to label of item
     * @var {String}
     */
    labelPath: 'label',

    /**
     * Items for dropdown list
     * @var {Array}
     */
    items: [],

    /**
     * Current selected item
     * @var {String}
     */
    itemId: null,

    /**
     * Show as tree mode
     * @var {Boolean}
     */
    tree: false,

    /**
     * Need add option with 'add new' action?
     */
    isAddNewOption: Ember.computed(function () {
      return this.get('handleCreate') && typeof this.get('handleCreate') === 'function' || this.get('vocabNameForNewItem');
    }),

    /**
     * If exist, need show option for add new item
     */
    vocabNameForNewItem: null,

    /**
     * Current selected Item
     */
    selectedItem: Ember.computed('itemId', 'items.length', function () {
      return this.get('items').findBy('id', this.get('itemId'));
    }),

    /**
     * Filtered by excludeIds
     */
    filteredItems: Ember.computed('items.[]', 'excludeIds.@each', function () {
      var excludeIds = this.get('excludeIds');
      var items = this.get('items');

      if (!items || !Ember.isArray(items)) {
        return [];
      }

      if (!excludeIds) {
        return this.get('items');
      }

      if (!Ember.isArray(excludeIds)) {
        excludeIds = [excludeIds];
      }

      return this.get('items').filter(function (item) {
        return excludeIds.indexOf(item.get('id')) === -1;
      });
    }),

    /**
     * Show first empty option when no current selection
     */
    emptyPrompt: Ember.computed('itemId', function () {
      return Ember.isEmpty(this.get('itemId')) && !Ember.isEmpty(this.get('promptForEmpty'));
    }),

    /**
     * Show first option if it defined
     */
    alwaysPrompt: Ember.computed('emptyPrompt', function () {
      return !this.get('emptyPrompt') && !Ember.isEmpty(this.get('prompt'));
    }),

    change: function change(event) {
      var select = event.originalEvent.target;
      this.set('itemId', select.value);

      // action 'add new item'
      if ('__add_new__' === select.value && this.get('isAddNewOption')) {
        this._doCreateNewItem();
        this.set('itemId', null);
        select.value = '';

        return;
      }

      this._sendSelected(this.get('selectedItem'));

      if (this.get('resetAfterSelect')) {
        this.set('itemId', null);
        select.value = '';
      }
    },


    /**
     * To open modal form for creating new vocabulary item
     * Or to call parent action
     * @private
     */
    _doCreateNewItem: function _doCreateNewItem() {
      if (this.get('vocabNameForNewItem')) {
        this.sendAction('openVocabularyNewItemForm', this.get('vocabNameForNewItem'), '');
      } else {
        this.get('handleCrate')();
      }

      // listen when new item added
      this._subscribeOnNewItem();
    },


    /**
     * Listen modal events
     * @private
     */
    _subscribeOnNewItem: function _subscribeOnNewItem() {
      this.get('modal').on(_constants.MODALS.VOCAB_ITEM_ADD + '.saving.success', this, this._onNewAddSuccess).on(_constants.MODALS.VOCAB_ITEM_ADD + '.close', this, this._unsubscribeNewItem);
    },


    /**
     * Send selected item to up
     * @param {Object} item
     * @private
     */
    _sendSelected: function _sendSelected(item) {
      if (this.get('handleSelect')) {
        this.get('handleSelect')(item);
      }

      if (this.get('handleSelectId')) {
        this.get('handleSelectId')(item && item.get('id') || null);
      }
    },


    /**
     * New vocabulary item has been added
     * @param {Object} data
     * @private
     */
    _onNewAddSuccess: function _onNewAddSuccess(data) {
      this._sendSelected(data.item);
    },


    /**
     * Close modal listeners
     * @private
     */
    _unsubscribeNewItem: function _unsubscribeNewItem() {
      this.get('modal').off(_constants.MODALS.VOCAB_ITEM_ADD + '.saving.success', this, this._onNewAddSuccess).off(_constants.MODALS.VOCAB_ITEM_ADD + '.close', this, this._unsubscribeNewItem);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      this._unsubscribeNewItem();
    }
  });
});