define('cdata/app', ['exports', 'cdata/resolver', 'ember-load-initializers', 'cdata/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Logger = Ember.Logger;


  var App = void 0;

  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  Ember.onerror = function (error) {
    if (Ember.testing) {
      throw error;
    }

    Logger.error(error.message, error);
  };

  exports.default = App;
});