define('cdata/pods/components/d3-zoomable-sunburst/utils/setup-colors', ['exports', 'd3', 'ramda'], function (exports, _d, _ramda) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setupColors = undefined;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var rootColor = '#FFF';
  var minOpacity = 0.4;
  var maxOpacity = 0.7;

  var toRgba = function toRgba(rgb) {
    var opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    return rgb.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba');
  };

  var getMediumColor = function getMediumColor(arr) {
    return arr.length > 1 ? getMediumColor((0, _ramda.aperture)(2, arr).map(function (items) {
      return _d.default.interpolate(items[0], items[1])(0.5);
    })) : arr[0];
  };

  var setupColors = exports.setupColors = function setupColors(root, palette) {
    var list = root.descendants();
    var maxDepth = Math.max.apply(Math, _toConsumableArray(list.map(function (d) {
      return d.depth;
    })));
    var lastNodes = list.filter(function (i) {
      return i.depth === maxDepth;
    });
    var getColor = _d.default.scaleSequential().domain([0, lastNodes.length]).interpolator(palette);

    lastNodes.forEach(function (item, i) {
      return item.color = getColor(i);
    });

    // setup color rgb
    root.eachAfter(function (node) {
      var children = node.children;


      if (children) {
        node.color = getMediumColor(children.map(function (_ref) {
          var color = _ref.color;
          return color;
        }));
      }
    });

    root.color = rootColor;

    // setup color rgba
    root.eachAfter(function (node) {
      var children = node.children,
          depth = node.depth;


      if (children && depth !== 0) {
        var length = children.length;

        var step = length > 1 ? (maxOpacity - minOpacity) / length : 0;
        children.forEach(function (item, idx) {
          return item.color = toRgba(item.color, maxOpacity - (idx + 1) * step);
        });
      } else if (depth === 0) {
        children.forEach(function (item) {
          return item.color = toRgba(item.color, maxOpacity);
        });
      }
    });
  };
});