define('cdata/pods/components/lots/list/set-results/item/component', ['exports', 'cdata/mixins/lists/set-results-item'], function (exports, _setResultsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_setResultsItem.default, {
    hideControls: false,

    classNameBindings: ['comparable:comparable'],

    addToComparableSet: 'addToComparableSet',
    removeFromComparableSet: 'removeFromComparableSet',

    comparable: Ember.computed('comparableSetsActive', 'item.id', 'comparablesLotsIds.@each', function () {
      if (!this.get('comparableSetsActive')) {
        return false;
      }

      return this.get('comparablesLotsIds').indexOf(this.get('item.id')) !== -1;
    }),

    entity: Ember.computed.alias('item.entityListItems.firstObject'),

    title: Ember.computed('entity.title', 'item.title', function () {
      return this.get('item.title') || this.get('entity.title');
    }),

    creationDate: Ember.computed('entity.{creation_date_start,creation_date_finish}', function () {
      if (this.get('entity.creation_date_start') || this.get('entity.creation_date_finish')) {
        if (this.get('entity.creation_date_start') === this.get('entity.creation_date_finish')) {
          return this.get('entity.creation_date_start');
        } else {
          return this.get('entity.creation_date_start') + ' - ' + this.get('entity.creation_date_finish');
        }
      }

      return '';
    }),

    normalizedMeasurements: Ember.computed('entity.normalized_width', 'entity.normalized_height', function () {
      if (this.get('entity.normalized_width') && this.get('entity.normalized_height')) {
        return this.get('entity.normalized_width') + ' x ' + this.get('entity.normalized_height') + ' cm';
      }

      return '';
    }),

    materials: Ember.computed('entity.material_vocabulary_item_ids', function () {
      var _this = this;

      if (!this.get('entity.material_vocabulary_item_ids')) {
        return '';
      }

      return this.get('entity.material_vocabulary_item_ids').map(function (id) {
        return _this.get('vocab.materialsLabels')[id];
      }).sort().join(', ');
    }),

    othersDepartmentsCount: Ember.computed('item.christiesSpecialistDepartmentIds.length', function () {
      var idsCount = this.get('item.christiesSpecialistDepartmentIds.length');

      return idsCount > 1 ? idsCount - 1 : null;
    }),

    actions: {
      addToComparableSet: function addToComparableSet() {
        this.sendAction('addToComparableSet', this.get('item'), this.get('entity'));
      },
      removeFromComparableSet: function removeFromComparableSet() {
        this.sendAction('removeFromComparableSet', this.get('item'), this.get('entity'));
      }
    }
  });
});