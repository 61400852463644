define('cdata/mirage/models/entity-lot', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    lot: (0, _emberCliMirage.belongsTo)('lot'),
    entity: (0, _emberCliMirage.belongsTo)('entity')
  });
});