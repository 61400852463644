define('cdata/pods/components/lots/modals/create-form-entity/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.LOT,
    classNames: ['context-modal', _constants.MODALS.LOT, 'edit-form'],

    inProcess: Ember.computed.alias('model.inProcess'),

    isEnabled: Ember.computed.and('session.id', 'sale.id', 'model.source', 'model.lotStatusVocabularyItemId'),
    isDisabled: Ember.computed.not('isEnabled'),

    loadSaleFor: 'loadSaleFor',
    loadEntityFor: 'loadEntityFor',

    sale: null,
    session: null,

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('model.entity') && this.get('model.entityId')) {
        this.sendAction('loadEntityFor', this.get('model'), this.get('model.entityId'));
      }
    },


    entityTitle: Ember.computed('model.entity.entityTitles.@each.{preferred,text}', function () {
      var titles = this.get('model.entity.entityTitles');
      if (!titles || titles.get('length') === 0) {
        return '';
      }

      var title = titles.findBy('preferred', true);

      return title && title.get('text');
    }),

    saleTitle: Ember.computed.alias('sale.title'),

    organizationTitle: Ember.computed('sale.organization.organizationTitles.@each.{preferred,text}', function () {
      var titles = this.get('sale.organization.organizationTitles');
      if (!titles || titles.get('length') === 0) {
        return '';
      }

      var title = titles.findBy('preferred', true);

      return title && title.get('text');
    }),

    sessionsItems: Ember.computed('sale.sessions', function () {
      var list = this.get('sale.sessions');

      if (Ember.isEmpty(list)) {
        return [];
      }

      var items = list.map(function (item) {
        return Ember.Object.create({
          id: item.get('id'),
          label: item.get('title'),
          session: item
        });
      });

      if (items.get('length') === 1) {
        this.set('session', items.get('firstObject.session'));
      }

      return items;
    }),

    actions: {
      setSale: function setSale(_, saleSubject) {
        this.sendAction('loadSaleFor', this, saleSubject.get('id'));
      },
      setSession: function setSession(_, sessionItem) {
        if (!sessionItem) {
          this.set('session', null);

          return;
        }
        this.set('session', sessionItem.get('session'));
      },
      save: function save() {
        var model = this.get('model');
        model.set('session', this.get('session'));
        this.sendAction('saveNewLotForm', model);
      },
      follow: function follow() {
        var model = this.get('model');
        model.set('session', this.get('session'));
        this.sendAction('saveNewLotForm', model, true);
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeNewLotForm');
      }
    }
  });
});