define('cdata/mixins/create-modals/entity', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    title: null,
    sidConstants: Ember.inject.service(),

    actions: {
      openNewEntityForm: function openNewEntityForm() {
        this.get('modal').register(this, _constants.MODALS.ENTITY, 'entities.modal.new').open(Ember.Object.create());
      },
      closeNewEntityForm: function closeNewEntityForm() {
        this.get('modal').close(_constants.MODALS.ENTITY);
      },


      /**
       * @param {Object} form model from lots/create-modal-form component
       * @param isFollow
       */
      saveNewEntityForm: function saveNewEntityForm(form, isFollow) {
        var _this = this;

        form.set('inProcess', true);
        this._setTitle(form.get('title') || null);

        var entity = this.get('store').createRecord('entity', {
          entityTitles: []
        });

        // saving
        this._handleSavingModal(entity.save(), false).then(function (entity) {
          _this._saveTitle(entity);

          _this.send('closeNewEntityForm');

          if (isFollow) {
            _this.transitionTo('entities.show', entity.get('id'));
          }
        }).finally(function () {
          form.set('inProcess', false);
        });
      },
      createEntity: function createEntity() {
        var _this2 = this;

        var entity = this.get('store').createRecord('entity', {
          entityTitles: []
        });

        this._handleSavingModal(entity.save(), false).then(function (entity) {
          _this2.transitionTo('entities.show', entity.get('id'));
        });
      },
      createEntityForLot: function createEntityForLot(lot) {
        var _this3 = this;

        this._moveEntityTextsToLot(lot);

        var entity = this.get('store').createRecord('entity', {
          entityTitles: []
        });

        this._handleSavingModal(entity.save(), false).then(function (entity) {
          var entityLot = _this3.get('store').createRecord('entity-lot', {
            lot: lot,
            lotId: lot.get('id'),
            entity: entity,
            entityId: entity.get('id')
          });

          var goToLot = function () {
            this.transitionTo('entities.subject', lot.get('id'), entity.get('id'));
          }.bind(_this3);

          _this3._handleSavingModal(entityLot.save()).then(function (entityLot) {
            lot.get('entityLots').pushObject(entityLot);
            lot.set('multiObjectCount', lot.get('entityLots.length'));

            if (lot.get('entityLots.length') > 1 && !lot.get('multiObject')) {
              lot.set('multiObject', true);
              lot.save();
              goToLot();
            } else if (lot.get('entityLots.length') <= 1 && lot.get('multiObject')) {
              lot.set('multiObject', false);
              lot.save();
              goToLot();
            } else {
              goToLot();
            }
          });
        });
      },
      createGemForJewelry: function createGemForJewelry(entity) {
        var _this4 = this;

        var gem = this.get('store').createRecord('entity', {
          entityTitles: [],
          cdataCategoryVocabularyItemId: this.get('sidConstants').get('gemCategoryId')
        });

        this._handleSavingModal(gem.save(), false).then(function (gem) {
          var entityJewelryGem = _this4.get('store').createRecord('entity-jewelry-gem', {
            entityJewelry: entity,
            entityJewelryId: entity.get('id'),
            entityGem: gem,
            entityGemId: gem.get('id')
          });

          _this4._handleSavingModal(entityJewelryGem.save()).then(function (entityJewelryGem) {
            entity.get('entityJewelryGems').pushObject(entityJewelryGem);
            _this4.transitionTo('entities.show', gem.get('id'));
          });
        });
      }
    },

    _setTitle: function _setTitle(title) {
      if (title) {
        title = this.get('store').createRecord('entity-title', {
          text: title,
          preferred: true
        });
        this.set('title', title);
      }
    },
    _saveTitle: function _saveTitle(entity) {
      var title = this.get('title');

      if (!title) {
        return;
      }

      title.setProperties({
        entity: entity,
        entityId: entity.get('id')
      });

      entity.get('entityTitles').pushObject(title);

      this._handleSavingModal(title.save(), false);
    },
    _moveEntityTextsToLot: function _moveEntityTextsToLot(lot) {
      // must be only one entity in the lot
      var onceEntity = lot.get('entityLots.length') === 1 && lot.get('entityLots.firstObject.entity');

      if (!onceEntity) {
        return;
      }

      // move if lot hasn't description
      if (lot.get('lotDescriptions.length') === 0) {
        this._createLotDescription(lot, this._extractEntityTextForLot(onceEntity.get('entityDescriptions')));
      }

      // move if lot hasn't title
      if (lot.get('lotTitles.length') === 0) {
        this._createLotTitle(lot, this._extractEntityTextForLot(onceEntity.get('entityTitles')));
      }
    },
    _extractEntityTextForLot: function _extractEntityTextForLot(items) {
      if (Ember.isEmpty(items)) {
        return;
      }

      var preferred = items.findBy('preferred');

      if (!preferred) {
        return;
      }

      var text = preferred.get('text');

      var second = items.findBy('preferred', false);

      if (preferred) {
        preferred.deleteRecord();
        items.removeObject(preferred);
        preferred.save();
      }

      if (second) {
        second.set('preferred', true);
        second.save();
      }

      return text;
    },
    _createLotDescription: function _createLotDescription(lot, text) {
      if (!text) {
        return;
      }

      var description = this.get('store').createRecord('lot-description', {
        text: text,
        preferred: lot.get('lotDescriptions.length') === 0,
        lot: lot,
        lotId: lot.get('id')
      });

      lot.get('lotDescriptions').pushObject(description);

      description.save();
    },
    _createLotTitle: function _createLotTitle(lot, text) {
      if (!text) {
        return;
      }

      var title = this.get('store').createRecord('lot-title', {
        text: text,
        preferred: lot.get('lotTitles.length') === 0,
        lot: lot,
        lotId: lot.get('id')
      });

      lot.get('lotTitles').pushObject(title);

      title.save();
    }
  });
});