define('cdata/pods/components/site/log-in-auth0/component', ['exports', 'ember-cli-jss', 'cdata/pods/components/site/log-in-auth0/stylesheet'], function (exports, _emberCliJss, _stylesheet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCliJss.default, {
    webAuth: Ember.inject.service(),

    stylesheet: _stylesheet.default,
    jssNames: ['root'],

    onClick: function onClick() {
      this.get('webAuth.auth0').authorize();
    }
  });
});