define('cdata/pods/components/comparables/collectrium-estimate-chart/component', ['exports', 'cdata/pods/components/comparables/collectrium-estimate-chart/collectrium-estimate'], function (exports, _collectriumEstimate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['collectrium-estimate'],

    init: function init() {
      this._super.apply(this, arguments);
      this.waitData();
    },
    waitData: function waitData() {
      var _this = this;

      if (this.get('data')) {
        Ember.run.schedule('afterRender', this, function () {
          (0, _collectriumEstimate.default)(_this.get('data'));
        });

        return;
      }

      Ember.run.later(this, this.waitData, 100);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.waitData();
    }
  });
});