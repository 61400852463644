define('cdata/pods/components/citations/web-sources/utils', ['exports', 'ramda'], function (exports, _ramda) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mapByGroups = undefined;


  var orBlank = (0, _ramda.defaultTo)('');

  var groupByCitationName = (0, _ramda.compose)((0, _ramda.map)((0, _ramda.sort)(function (item) {
    return item.get('preferred') ? -1 : 1;
  })), (0, _ramda.groupBy)(function (item) {
    return orBlank(item.get('citationName'));
  }), (0, _ramda.sortBy)(function (item) {
    return orBlank(item.get('citationName')).toLowerCase();
  }));

  var mapByGroups = exports.mapByGroups = function mapByGroups(fn, websites) {
    return (0, _ramda.values)((0, _ramda.map)(fn, groupByCitationName(websites)));
  };
});