define('cdata/pods/components/entities/edit/edit-forms-materials/item/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms-item', 'cdata/services/natural-sorter'], function (exports, _editFormsItem, _naturalSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsItem.default, {
    addMaterialTerm: 'addMaterialTerm',
    parseEntityMaterial: 'parseEntityMaterial',
    inProcess: false,

    materialTerms: Ember.computed.filterBy('model.entityMaterialTerms', 'isDeleted', false),

    labels: Ember.computed.alias('vocab.materialsLabels'),

    materialTermsSorted: Ember.computed.sort('materialTerms', (0, _naturalSorter.default)('label')),

    actions: {
      addMaterialTerm: function addMaterialTerm(_, subject) {
        this.sendAction('addMaterialTerm', this.get('model'), subject);
      },
      textHasChanged: function textHasChanged(text) {
        this.set('model.text', text);
        Ember.run.debounce(this, this.runParse, 500);
      },
      doParse: function doParse() {
        this.runParse();
      }
    },

    runParse: function runParse() {
      this.sendAction('parseEntityMaterial', this.get('model'));
    }
  });
});