define('cdata/pods/components/entities/edit/edit-forms-editions/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Edition'), {
    editions: Ember.computed.filterBy('model.entityEditions', 'isDeleted', false),

    openNewEditionForm: 'openNewEditionForm',

    actions: {
      openNewEditionForm: function openNewEditionForm() {
        this.sendAction.apply(this, ['openNewEditionForm'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});