define('cdata/pods/components/people/list/filter-relation-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['list-filter-relation-form'],

    isAddingEnabled: Ember.computed.and('relation', 'subject'),
    isAddingDisabled: Ember.computed.not('isAddingEnabled'),

    relation: null,
    role: null,
    subject: null,

    showRole: Ember.computed('relation', function () {
      if (!this.get('relation')) {
        return false;
      }

      return this.get('relations').findBy('id', this.get('relation')).showRole;
    }),

    relations: [{
      id: 'education',
      label: 'Education institution',
      showRole: false
    }, {
      id: 'membership',
      label: 'Membership institution',
      showRole: false
    }, {
      id: 'organization',
      label: 'Organization',
      showRole: true
    }, {
      id: 'gallery',
      label: 'Gallery or museum',
      showRole: false
    }, {
      id: 'person',
      label: 'Person',
      showRole: true
    }],

    actions: {
      setRelation: function setRelation(id) {
        this.cleanup();
        this.set('relation', id);
      },
      setSubject: function setSubject(_, subject) {
        this.set('subject', subject);
      },
      setRole: function setRole(_, subject) {
        this.set('role', subject);
      },
      add: function add() {
        this.sendAction('addRelation', Ember.Object.create({
          relation: this.get('relation'),
          role: this.get('role'),
          subject: this.get('subject')
        }));
        this.send('cancel');
      },
      cancel: function cancel() {
        this.cleanup();
      }
    },

    cleanup: function cleanup() {
      this.set('relation', null);
      this.set('role', null);
      this.set('subject', null);
    }
  });
});