define('cdata/pods/components/organizations/edit/edit-forms-addresses/item/component', ['exports', 'cdata/pods/components/organizations/edit/mixins/edit-forms-item', 'cdata/mixins/editor/forms/address-item'], function (exports, _editFormsItem, _addressItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_addressItem.default, _editFormsItem.default, {
    actions: {
      changePlace: function changePlace(place) {
        for (var _len = arguments.length, rest = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          rest[_key - 1] = arguments[_key];
        }

        this._super.apply(this, [place].concat(_toConsumableArray(rest)));

        var model = this.get('model');
        var location = model.get('location');
        var organizationTitle = model.get('organization.title');

        location.set('_name', organizationTitle + ' - ' + place.city);
      }
    }
  });
});