define('cdata/pods/components/places/modals/create-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin', 'cdata/pods/components/places/mixins/handle_new_place'], function (exports, _constants, _componentMixin, _handle_new_place) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var eventKey = _constants.MODALS.PLACE + '.saving';

  exports.default = Ember.Component.extend(_componentMixin.default, _handle_new_place.default, {
    modalClassId: _constants.MODALS.PLACE,
    classNames: ['context-modal', _constants.MODALS.PLACE, 'edit-form'],
    inProcess: false,

    parent: null,
    current: null,

    subjectFilter: { clear: true },

    actions: {
      setParent: function setParent(_, item) {
        this.set('parent', item);
      },
      save: function save() {
        var parent = this.get('parent');

        var path = [];

        if (parent.get('name')) {
          path.push(parent.get('name'));
        }

        if (parent.get('description')) {
          path.push(parent.get('description'));
        }

        var form = Ember.Object.create({
          name: this.get('model.name'),
          parent: parent,
          description: path.join(', ')
        });

        this.sendAction('saveNewPlaceForm', form);
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeNewPlaceForm');
      },
      setPlaceItem: function setPlaceItem(_, place) {
        var subject = Ember.Object.create({
          id: place.get('id'),
          name: place.get('name'),
          type: 'place',
          description: null
        });

        this.set('current', subject);
      }
    },

    _onProcess: function _onProcess() {
      this.set('inProcess', true);
    },
    _onFinally: function _onFinally() {
      this.set('inProcess', false);
    },
    _onSuccess: function _onSuccess() {
      this.send('close');
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this.get('modal').on(eventKey + '.process', this, this._onProcess).on(eventKey + '.success', this, this._onSuccess).on(eventKey + '.finally', this, this._onFinally);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      this.get('modal').off(eventKey + '.process', this, this._onProcess).off(eventKey + '.success', this, this._onSuccess).off(eventKey + '.finally', this, this._onFinally);
    }
  });
});