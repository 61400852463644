define('cdata/helpers/format-source', ['exports', 'cdata/helpers/short-url', 'cdata/helpers/format-url'], function (exports, _shortUrl, _formatUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatSource = formatSource;

  var escape = Ember.Handlebars.Utils.escapeExpression;

  function formatSource(params) {
    var source = params[0];
    var page = params[1] || '';
    var needHtml = typeof params[2] === 'boolean' ? params[2] : true;

    var url = source.get('url');
    var title = source.get('title');

    if (!url) {
      return formatOfflineSource(source, page, needHtml);
    }

    if (!title) {
      if (needHtml) {
        var link = '<a href="' + escape(url) + '" target="_blank">' + _shortUrl.default.compute([url, 100]) + '</a>';

        return link.htmlSafe();
      }

      return _shortUrl.default.compute([url, 100]);
    }

    var formattedUrl = _formatUrl.default.compute([_shortUrl.default.compute([url, 50])]);

    if (needHtml) {
      var _link = title + ' (<a href="' + escape(url) + '" target="_blank">' + formattedUrl + '</a>)';

      return _link.htmlSafe();
    }

    return title + ' (' + formattedUrl + ')';
  }

  function formatOfflineSource(source, page, needHtml) {
    var parts = [];

    var title = source.get('title');
    var headline = source.get('headline');
    var url = source.get('url');

    if (title) {
      title = escape(title.trim());
    }

    if (title && url) {
      if (needHtml) {
        title = '<a href="' + escape(url) + '" target="_blank">' + title + '</a>';
      }
    } else if (url) {
      if (needHtml) {
        title = '<a href="' + escape(url) + '" target="_blank">' + _formatUrl.default.compute([_shortUrl.default.compute([url])]) + '</a>';
      } else {
        title = _formatUrl.default.compute([_shortUrl.default.compute([url])]);
      }
    }

    if (title) {
      if (needHtml) {
        parts.push('<span class="strong">' + title + '</span>');
      } else {
        parts.push(title);
      }
    }

    if (headline) {
      if (needHtml) {
        parts.push('<span class="strong">' + escape(headline.trim()) + '</span>');
      } else {
        parts.push(headline.trim());
      }
    }

    var authorsList = '';
    var authors = source.get('sourcePersonAuthors');
    if (!authors || authors.get('length') === 0) {
      authors = source.get('sourcePersonEditors');
    }
    if (authors && authors.get('length') > 0) {
      authorsList = authors.mapBy('_name');
      if (authorsList) {
        authorsList = authorsList.join(', ');
        parts.push(escape(authorsList));
      }
    }

    var publishers = source.get('sourcePersonPublishers');
    if (!publishers || publishers.get('length') === 0) {
      publishers = source.get('sourceOrganizationPublishers');
    }
    if (publishers && publishers.get('length') > 0) {
      parts.push(escape(publishers.get('firstObject').get('_name')));
    }

    var place = source.get('_place');
    if (place) {
      parts.push(escape(place));
    }

    var year = source.get('publicationYear');
    if (year) {
      parts.push(escape(year));
    }

    if (page) {
      if (/^\d+$/gi.test(page)) {
        if (needHtml) {
          page = 'p.&nbsp;' + page;
        } else {
          page = 'p. ' + page;
        }
      } else if (/^\d+-\d+$/gi.test(page)) {
        if (needHtml) {
          page = 'pp.&nbsp;' + page;
        } else {
          page = 'pp. ' + page;
        }
      } else {
        page = escape(page);
      }

      parts.push(page);
    }

    if (needHtml) {
      return parts.join('; ').htmlSafe();
    } else {
      return parts.join('; ');
    }
  }

  exports.default = Ember.Helper.helper(formatSource);
});