define('cdata/adapters/application', ['exports', 'active-model-adapter', 'cdata/constants'], function (exports, _activeModelAdapter, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.default.extend({
    host: _constants.API_URL,

    session: Ember.inject.service(),

    /**
     * Prefix for api path
     */
    // namespace: 'api',

    /**
     * Enable 'ids' queries
     */
    // coalesceFindRequests: true,

    /**
     * Set api key to header
     */
    headers: Ember.computed.readOnly('session.authHeader'),

    pathForType: function pathForType(type) {
      if (type === 'person-conversion') {
        return 'people/conversion';
      }
      if (type === 'organization-conversion') {
        return 'organizations/conversion';
      }

      return this._super.apply(this, arguments);
    },
    parseErrorResponse: function parseErrorResponse() {
      var response = this._super.apply(this, arguments);

      if (response && response.error && typeof response.error === 'string') {
        return response.error;
      }

      return response;
    }
  });
});