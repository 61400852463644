define('cdata/pods/components/comparables/custom-estimate/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',

    estimateBoth: Ember.computed.and('item.estimate_high', 'item.estimate_low'),

    estimateOne: Ember.computed('estimateBoth', 'item.{estimate_low,estimate_high}', function () {
      return !this.get('estimateBoth') ? this.get('item.estimate_low') || this.get('item.estimate_high') : '';
    }),

    exist: Ember.computed.or('item.estimate_high', 'item.estimate_low'),
    none: Ember.computed.not('exist')
  });
});