define('cdata/serializers/entity-merge', ['exports', 'ember-data', 'cdata/serializers/application', 'cdata/mixins/serializers/embedded-on-save'], function (exports, _emberData, _application, _embeddedOnSave) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
  exports.default = _application.default.extend(EmbeddedRecordsMixin, _embeddedOnSave.default, {
    saveNewAsEmbedded: ['entityMergeSubjects'],
    attrs: {
      entityMergeSubjects: {
        serialize: 'ids',
        deserialize: 'ids'
      }
    }
  });
});