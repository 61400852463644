define('cdata/mixins/components/assign-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    assignSubject: Ember.Object.create({
      id: null
    }),

    multiple: Ember.computed.equal('model.option', 'creator'),
    multiruledept: Ember.computed.equal('model.option', 'christiesSpecialist'),

    eventKey: null,

    enabled: false,
    disabled: Ember.computed.not('enabled'),

    inProcess: false,

    subjectName: null,

    isCreator: false,

    rulesList: [],

    isRemoveCreator: Ember.computed.equal('model.option', 'remove-creator'),

    isComparableSet: Ember.computed.equal('model.option', 'comparable-set'),
    comparableSetName: 'New set',

    rulesListOne: [Ember.Object.create({
      id: 'add-as-preferred',
      label: 'add as preferred'
    }), Ember.Object.create({
      id: 'add-as-alternate',
      label: 'add as alternate'
    }), Ember.Object.create({
      id: 'replace-as-preferred',
      label: 'replace'
    })],

    rulesListMulti: [Ember.Object.create({
      id: 'add-as-preferred',
      label: 'add and replace the preferred'
    }), Ember.Object.create({
      id: 'add-as-preferred-multiple',
      label: 'add as preferred'
    }), Ember.Object.create({
      id: 'add-as-alternate',
      label: 'add as alternate'
    }), Ember.Object.create({
      id: 'replace-as-preferred',
      label: 'replace'
    })],

    rulesListMultiDepartments: [Ember.Object.create({
      id: 'replace-as-preferred',
      label: 'replace'
    }), Ember.Object.create({
      id: 'add-as-preferred',
      label: 'add and replace the preferred'
    }), Ember.Object.create({
      id: 'add-as-alternate',
      label: 'add as alternate'
    })],

    actionLabel: Ember.computed('isRemoveCreator', function () {
      if (this.get('isRemoveCreator')) {
        return 'removed from';
      } else {
        return 'assigned to';
      }
    }),

    label: Ember.computed('assignSubject.{name,title,id}', function () {
      var subject = this.get('assignSubject');

      return subject && (subject.get('label') || subject.get('name') || subject.get('id'));
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.initRule();
    },
    initRule: function initRule() {
      if (this.get('multiruledept')) {
        this.set('rulesList', this.get('rulesListMultiDepartments'));

        this.set('rule', Ember.Object.create({
          id: 'replace-as-preferred'
        }));
      } else {
        this.set('rulesList', this.get('multiple') ? this.get('rulesListMulti') : this.get('rulesListOne'));

        this.set('rule', Ember.Object.create({
          id: 'add-as-preferred'
        }));
      }
    },


    actions: {
      close: function close() {
        this.send('cancel');
      },
      cancel: function cancel() {
        this.reset();
        this.set('model.items', []);
        this.sendAction('closeAssignForm');
      },
      assign: function assign() {
        var value = this.get('assignSubject.id');

        if (this.get('isCreator')) {
          var qualifier_id = parseInt(this.get('model.creatorQualifierVocabularyItemId'));

          value = {
            id: value,
            type: this.get('assignSubject.type'),
            creator_qualifier_vocabulary_item_id: qualifier_id || null
          };
        }

        if (this.get('isRemoveCreator')) {
          value = {
            id: value,
            type: this.get('assignSubject.type')
          };
        }

        if (this.get('isComparableSet')) {
          value = this.get('comparableSetName');
        }

        this.sendAction('saveAssignForm', this.get('model.items'), this.get('model.option'), value, this.get('rule.id'), this.get('model.isFilter'));
      },
      setAssign: function setAssign(_, subject) {
        this.set('assignSubject', subject);
      },
      setRule: function setRule(rule) {
        this.set('rule', rule);
      },
      setCreator: function setCreator(_, creator) {
        this.set('assignSubject', creator);
      }
    },

    reset: function reset() {
      this.set('assignSubject', Ember.Object.create({
        id: null
      }));

      this.initRule();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var eventKey = this.get('eventKey');
      this.reset();

      this.get('modal').on(eventKey + '.process', this, this._onProcess).on(eventKey + '.success', this, this._onSuccess).on(eventKey + '.finally', this, this._onFinally);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var eventKey = this.get('eventKey');

      this.get('modal').off(eventKey + '.process', this, this._onProcess).off(eventKey + '.success', this, this._onSuccess).off(eventKey + '.finally', this, this._onFinally);
    },
    _onProcess: function _onProcess() {
      this.set('inProcess', true);
    },
    _onFinally: function _onFinally() {
      this.set('inProcess', false);
    },
    _onSuccess: function _onSuccess() {
      this.send('close');
    }
  });
});