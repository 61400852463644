define('cdata/pods/components/buttons/edit-page-buttons/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['context-right-menu'],

    initSaveShortcuts: Ember.on('didInsertElement', function () {
      key.filter = function (event) {
        var tagName = (event.target || event.srcElement).tagName;
        key.setScope(['INPUT', 'SELECT', 'TEXTAREA'].indexOf(tagName) >= 0 ? 'input' : 'other');

        return true;
      };

      key('ctrl+s, command+s', 'input', function () {
        this.send('save');

        return false;
      }.bind(this));

      key('ctrl+s, command+s', 'other', function () {
        this.send('save');

        return false;
      }.bind(this));
    }),

    removeSaveShortcuts: Ember.on('willDestroyElement', function () {
      key.unbind('ctrl+s, command+s');

      key.filter = function (event) {
        var tagName = (event.target || event.srcElement).tagName;

        return ['INPUT', 'SELECT', 'TEXTAREA'].indexOf(tagName) < 0;
      };
    }),

    actions: {
      save: function save() {
        this.sendAction('save');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      reset: function reset() {
        this.sendAction('reset');
      },
      create: function create() {
        this.sendAction('create');
      }
    }
  });
});