define('cdata/pods/lots/show/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      navigateToLotInSale: function navigateToLotInSale() {
        this.send.apply(this, ['doNavigateToLotInSale'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});