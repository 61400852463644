define('cdata/pods/components/entities/list/set-filters/component', ['exports', 'moment', 'cdata/mixins/lists/component-filter', 'cdata/mixins/components/cdata-categories-filter', 'cdata/utils/auction-houses-options', 'cdata/utils/computed'], function (exports, _moment, _componentFilter, _cdataCategoriesFilter, _auctionHousesOptions, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentFilter.default, _cdataCategoriesFilter.default, {
    fields: ['query', 'creationDateStart', 'creationDateFinish', 'creators', 'cdataCategoryIds', 'christiesBusinessGroupIds', 'miCategoryIds', 'organizationIds', 'auctionHouseIds', 'saleIds', 'priceMin', 'priceMax', 'estimateMin', 'estimateMax', 'saleDateFrom', 'saleDateTo', 'saleFinishDateFrom', 'saleFinishDateTo', 'lotsCountMin', 'lotsCountMax', 'materialIds', 'materialOr'],

    materials: [],
    creators: [],
    people: [],
    organizations: [],
    auctionHouses: [],
    sales: [],
    miCategories: [],
    christiesBusinessGroups: [],

    materialIds: Ember.computed.mapBy('materials', 'id'),
    miCategoryIds: Ember.computed.mapBy('miCategories', 'id'),
    auctionHouseIds: Ember.computed.mapBy('auctionHouses', 'id'),
    saleIds: Ember.computed.mapBy('sales', 'id'),
    christiesBusinessGroupIds: Ember.computed.mapBy('christiesBusinessGroups', 'id'),

    // `Id` it's a number when we parse query params, in other cases `id` it's a string
    auctionHouseStringIds: Ember.computed.map('auctionHouseIds', function (item) {
      return '' + item;
    }),

    auctionHousesOptions: (0, _auctionHousesOptions.getAuctionHousesOptions)(),
    auctionHousesVisible: (0, _computed.computedWithoutByIds)('auctionHousesOptions', 'auctionHouseStringIds'),

    initFilter: function initFilter() {
      this._super();

      this.syncDefaultQueryParams();
      this.initCreators();

      this.initFilterSubjects('miCategoryIds', 'miCategory', 'miCategories');
      this.initFilterSubjects('christiesBusinessGroupIds', 'christiesBusinessGroup', 'christiesBusinessGroups');
      this.initFilterSubjects('auctionHouseIds', 'organization', 'auctionHouses');
      this.initFilterSubjects('saleIds', 'sale', 'sales');

      this.initFilterVocabs('materialIds', 'materials');

      if (this.get('model.materialOr') === undefined) {
        this.set('model.materialOr', null);
      }

      this.initCdataCategoriesTree();
    },


    actions: {
      clear: function clear() {
        this.setProperties({
          materials: [],
          creators: [],
          people: [],
          organizations: [],
          auctionHouses: [],
          sales: [],
          miCategories: [],
          christiesBusinessGroup: []
        });
        this._super();

        this.initCdataCategoriesTree();
      },
      setMaterialOr: function setMaterialOr() {
        var value = this.get('model.materialOr') ? null : true;
        this.set('model.materialOr', value);
        this.applyFilter();
      },
      setCreationDates: function setCreationDates(start, finish) {
        this.set('model.creationDateStart', start);
        this.set('model.creationDateFinish', finish);
        this.applyFilter();
      },
      setPrices: function setPrices(start, finish) {
        this.set('model.priceMin', start);
        this.set('model.priceMax', finish);
        this.applyFilter();
      },
      setLotsCount: function setLotsCount(start, finish) {
        this.set('model.lotsCountMin', start);
        this.set('model.lotsCountMax', finish);
        this.applyFilter();
      },
      setEstimates: function setEstimates(start, finish) {
        this.set('model.estimateMin', start);
        this.set('model.estimateMax', finish);
        this.applyFilter();
      },
      addCreator: function addCreator(subject) {
        this.addCreatorToCollection(subject);
      },
      removeCreator: function removeCreator(subject) {
        this.removeCreatorFromCollection(subject);
      },
      setDateFrom: function setDateFrom(date) {
        this.set('model.saleDateFrom', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      },
      setDateTo: function setDateTo(date) {
        this.set('model.saleDateTo', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      },
      setFinishDateFrom: function setFinishDateFrom(date) {
        this.set('model.saleFinishDateFrom', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      },
      setFinishDateTo: function setFinishDateTo(date) {
        this.set('model.saleFinishDateTo', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      }
    }
  });
});