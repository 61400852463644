define('cdata/pods/components/site/editor/audit-log/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'main',
    classNames: 'main sale-item',

    auditLogsOn: false,
    auditLogs: [],
    auditLogsLoading: false,

    doLoadAuditLogs: 'doLoadAuditLogs',

    params: Ember.computed('entityId', 'lotId', 'personId', function () {
      if (this.get('lotId') && this.get('entityId')) {
        return { lot_id: this.get('lotId'), entity_id: this.get('entityId') };
      }

      if (this.get('lotId')) {
        return { lot_id: this.get('lotId') };
      }

      if (this.get('entityId')) {
        return { entity_id: this.get('entityId') };
      }

      if (this.get('personId')) {
        return { person_id: this.get('personId') };
      }

      if (this.get('organizationId')) {
        return { organization_id: this.get('organizationId') };
      }
    }),

    actions: {
      loadAuditLogs: function loadAuditLogs() {
        var _this = this;

        this.set('auditLogsLoading', true);
        this.sendAction('doLoadAuditLogs', this.get('params'), function (logs) {
          _this.set('auditLogsLoading', false);
          _this.set('auditLogs', logs);
          _this.set('auditLogsOn', true);
        });
      }
    }
  });
});