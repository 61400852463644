define('cdata/pods/components/organizations/edit/edit-forms-addresses/component', ['exports', 'ramda', 'cdata/pods/components/organizations/edit/mixins/edit-forms'], function (exports, _ramda, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var nullIdFirstSort = (0, _ramda.sortBy)(function (item) {
    return item.get('id');
  });

  exports.default = Ember.Component.extend((0, _editForms.default)('Address'), {
    store: Ember.inject.service(),

    notDeleedtAddresses: Ember.computed.filterBy('model.organizationAddresses', 'isDeleted', false),

    addresses: Ember.computed('notDeleedtAddresses.@each.id', function () {
      var addresses = this.get('notDeleedtAddresses');

      return nullIdFirstSort(addresses);
    }),

    classNameBindings: ['editMode:edit-mode'],

    parseLocationForm: 'parseLocationForm',
    addressesChanged: 'doOrganizationAddressesChanged',

    editMode: Ember.computed('addresses.@each._isEditMode', function () {
      return this.get('addresses').filterBy('_isEditMode').length > 0;
    }),

    listMode: Ember.computed.not('editMode'),

    setLocation: function setLocation(address, location) {
      address.set('location', location);
      address.set('locationId', location.get('id'));
    },
    checkForTitle: function checkForTitle(address) {
      var location = address.get('location');

      if (address.get('isNew')) {
        var title = this.get('store').createRecord('location-title', {
          text: location.get('_name'),
          preferred: true,
          language: 'en',
          location: location
        });

        location.get('locationTitles').pushObject(title);
      }
    },


    actions: {
      parseLocationForm: function parseLocationForm() {
        this.sendAction.apply(this, ['parseLocationForm'].concat(Array.prototype.slice.call(arguments)));
      },
      addressesChanged: function addressesChanged() {
        this.sendAction('doOrganizationAddressesChanged');
      }
    }
  });
});