define('cdata/models/session', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    _locationName: attr('string'),
    _title: attr('string'),
    saleId: attr('string'),
    sale: belongsTo('sale', { async: true }),
    startDate: attr('string'),
    startTime: attr('string'),
    finishDate: attr('string'),
    location: belongsTo('location'),
    locationId: attr('string'),
    lotsCount: attr('number'),
    firstLotNumber: attr('string'),
    lastLotNumber: attr('string'),
    channelVocabularyItemId: attr('string'),
    sessionStatusVocabularyItemId: attr('string'),
    sessionTitles: hasMany('session-title'),
    uuid: attr('string'),

    title: Ember.computed('id', 'sessionTitles.@each.{preferred,text}', function () {
      var title = '#' + this.get('id');
      var sessionTitle = this.get('sessionTitles').findBy('preferred');

      sessionTitle = sessionTitle && sessionTitle.get('text');

      if (sessionTitle) {
        title += ' ' + sessionTitle;
      }

      if (this.get('startDate')) {
        title += ' ' + (0, _moment.default)(this.get('startDate')).format('ll');
      }

      return title;
    })
  });
});