define('cdata/mixins/components/load-creator-names', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    loadCreatorNames: function loadCreatorNames(entityModel) {
      if (!entityModel) {
        entityModel = this.get('model');
      }

      var creators = entityModel.get('entityCreators') ? entityModel.get('entityCreators') : entityModel.get('markMakers');

      creators.forEach(function (creator) {
        if (creator.get('personId')) {
          creator.get('person').then(function (person) {
            if (person) {
              creator.set('_personName', person.get('longName'));
            }
          });
        }
        if (creator.get('organizationId')) {
          creator.get('organization').then(function (organization) {
            if (organization) {
              creator.set('_organizationTitle', organization.get('longTitle'));
            }
          });
        }
      });
    }
  });
});