define('cdata/pods/entities/parser-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ajax: Ember.inject.service(),

    actions: {
      parseEntityText: function parseEntityText(entityDescription) {
        var _this = this;

        this._setInProcess(true);

        this.get('ajax').post('entities/parse', {
          description: entityDescription.get('text'),
          entity_id: entityDescription.get('entityId')
        }, function (response) {
          response.description = entityDescription.get('text');
          response.entityDescription = entityDescription;
          var model = _this.get('currentModel');
          model.set('_parsingDescription', _this._prepareParsingObject(response));
          _this.set('editor.formName', 'entities/edit/edit-forms-parse');
        }, function (error) {
          _this.get('flashMessage').showError(error);
        }, function () {
          _this._setInProcess(false);
        });
      },
      parseCreationDate: function parseCreationDate(entityCreationDate) {
        var _this2 = this;

        var text = entityCreationDate.get('text');
        if (!text) {
          return;
        }

        this._setInProcess(true);

        this.get('ajax').post('entities/parse', { creation_date_text: text }, function (response) {
          if (response.creation_date_values) {
            entityCreationDate.set('startYear', response.creation_date_values.start_year);
            entityCreationDate.set('finishYear', response.creation_date_values.finish_year);
          }
        }, function (error) {
          _this2.get('flashMessage').showError(error);
        }, function () {
          _this2._setInProcess(false);
        });
      },
      parseEntityMaterial: function parseEntityMaterial(entityMaterial) {
        var _this3 = this;

        var text = entityMaterial.get('text');
        if (!text) {
          entityMaterial.get('entityMaterialTerms').forEach(function (term) {
            if (term) {
              term.deleteRecord();
            }
          });

          return;
        }

        this._setInProcess(true);

        this.get('ajax').post('entities/parse', { material_text: text }, function (response) {
          entityMaterial.get('entityMaterialTerms').forEach(function (term) {
            if (term) {
              term.deleteRecord();
            }
          });
          if (response.material_values) {
            response.material_values.forEach(function (value) {
              _this3.send('addEntityMaterialTerm', entityMaterial, Ember.Object.create({ id: value.material_vocabulary_item_id }));
            });
          }
        }, function (error) {
          _this3.get('flashMessage').showError(error);
        }, function () {
          _this3._setInProcess(false);
        });
      },
      parseMeasurement: function parseMeasurement(entityMeasurement) {
        var _this4 = this;

        this._setInProcess(true);

        this.get('ajax').post('entities/parse', { measurement_text: entityMeasurement.get('text') }, function (response) {
          response.measurement = entityMeasurement.get('text');
          var parse = _this4._prepareParsingObject(response);

          if (parse.measurementValues.length === 0) {
            _this4.get('flashMessage').showError('Measurement values for text' + '\n' + response.measurement + '\n' + 'not found');
          }

          parse.measurementValues.forEach(function (parsedMeasurementValue) {
            _this4.send('addEntityMeasurementValue', entityMeasurement, {
              typeVocabularyItemId: parsedMeasurementValue.typeVocabularyItemId,
              unitVocabularyItemId: parsedMeasurementValue.unitVocabularyItemId,
              value: parsedMeasurementValue.value
            });
          });
        }, function (error) {
          _this4.get('flashMessage').showError(error);
        }, function () {
          _this4._setInProcess(false);
        });
      },
      saveParsing: function saveParsing(parse) {
        var _this5 = this;

        var model = this.get('currentModel');

        var params = {
          entity_id: model.get('id')
        };

        if (parse.date && parse.date.text) {
          params.creation_date_text = parse.date.text;
          params.creation_date_values = {
            start_year: parse.date.startYear,
            finish_year: parse.date.finishYear
          };
        }

        if (parse.measurement) {
          params.measurement_text = parse.measurement;
          params.measurement_values = [];
        }

        if (parse.measurementValues) {
          parse.measurementValues.forEach(function (value) {
            params.measurement_values.push({
              value: value.value,
              type_vocabulary_item_id: value.typeVocabularyItemId,
              unit_vocabulary_item_id: value.unitVocabularyItemId
            });
          });
        }

        if (parse.inscription) {
          params.inscription_text = parse.inscription;
        }

        if (parse.description) {
          params.description = parse.description;
        }

        if (parse.material) {
          params.material_text = parse.material;
          params.material_values = [];
        }

        if (parse.materialValues) {
          parse.materialValues.forEach(function (value) {
            params.material_values.push({
              material_vocabulary_item_id: parseInt(value.materialVocabularyItemId)
            });
          });
        }

        this._setInProcess(true);
        this.get('ajax').post('entities/parse/save', params, function () {
          _this5.get('flashMessage').showSuccess('Successfully saved');
          _this5._clearEditor();
          _this5.refresh();
        }, function (error) {
          _this5.get('flashMessage').showError(error);
        }, function () {
          _this5._setInProcess(false);
        });
      }
    },

    _prepareParsingObject: function _prepareParsingObject(data) {
      var _this6 = this;

      var dates = data.creation_date_values ? {
        startYear: data.creation_date_values.start_year ? parseInt(data.creation_date_values.start_year) : null,
        finishYear: data.creation_date_values.finish_year ? parseInt(data.creation_date_values.finish_year) : null
      } : {
        startYear: null,
        finishYear: null
      };

      return Ember.Object.create({
        description: data.description,
        inscription: data.inscription_text,
        entityDescription: data.entityDescription,
        date: data.creation_date_text ? Ember.Object.create({
          text: data.creation_date_text,
          startYear: dates.startYear,
          finishYear: dates.finishYear
        }) : null,
        measurement: data.measurement_text,
        measurementValues: data.measurement_values ? data.measurement_values.map(function (item) {
          return Ember.Object.create({
            value: parseFloat(item.value),
            unitVocabularyItemId: parseInt(item.unit_vocabulary_item_id) > 0 ? parseInt(item.unit_vocabulary_item_id) : null,
            typeVocabularyItemId: parseInt(item.type_vocabulary_item_id) > 0 ? parseInt(item.type_vocabulary_item_id) : null
          });
        }) : null,
        material: data.material_text,
        materialValues: data.material_values ? data.material_values.map(function (value) {
          value = value.material_vocabulary_item_id;
          var itemId = parseInt(value) > 0 ? parseInt(value) : null;

          return Ember.Object.create({
            materialVocabularyItemId: itemId,
            label: _this6.get('vocab.materialsLabels')[itemId]
          });
        }) : []
      });
    }
  });
});