define('cdata/models/person-death-place', ['exports', 'ember-data', 'cdata/mixins/models/build-place-name', 'cdata/mixins/models/user-name'], function (exports, _emberData, _buildPlaceName, _userName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_userName.default, _buildPlaceName.default, {
    placeId: attr('string'),
    place: belongsTo('place'),
    personId: attr('string'),
    userId: attr('string'),
    person: belongsTo('person'),
    citationLabelVocabularyItemId: attr('string'),
    preferred: attr('boolean')
  });
});