define('cdata/pods/components/organizations/list/set-results/item/item-mixin', ['exports', 'cdata/mixins/lists/set-results-item'], function (exports, _setResultsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var escape = Ember.Handlebars.Utils.escapeExpression;

  exports.default = Ember.Mixin.create(_setResultsItem.default, {
    about: Ember.computed('item.{existenceDate,nationality,role}', function () {
      var result = [];
      var role = this.get('item.role');
      var date = this.get('item.existenceDate');
      var nationality = this.get('item.nationality');

      if (role) {
        role = escape(role);
        result.push('<span><strong>' + role + '</strong></span>');
      }

      if (nationality) {
        result.push('<span>' + escape(nationality) + '</span>');
      }

      if (date) {
        result.push('<span>' + escape(date) + '</span>');
      }

      return result.join('').htmlSafe();
    })
  });
});