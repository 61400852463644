define('cdata/pods/components/places/list/search-header/component', ['exports', 'cdata/mixins/lists/component-list-controls'], function (exports, _componentListControls) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentListControls.default, {
    classNames: ['places'],

    createNewPlace: 'createNewRootPlace',

    newPlace: false,
    newPlaceName: null,

    actions: {
      saveNewPlace: function saveNewPlace() {
        this.sendAction('createNewPlace', this.get('newPlaceName'));
        this.send('cancelNewPlace');
      },
      cancelNewPlace: function cancelNewPlace() {
        this.set('newPlaceName', null);
        this.set('newPlace', false);
      },
      addNewPlace: function addNewPlace() {
        this.set('newPlace', true);
      }
    }
  });
});