define('cdata/pods/components/sales/list/set-filter/component', ['exports', 'moment', 'cdata/mixins/lists/component-filter', 'cdata/utils/auction-houses-options', 'cdata/utils/computed'], function (exports, _moment, _componentFilter, _auctionHousesOptions, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentFilter.default, {
    fields: ['shown', 'source', 'auctionHouseIds', 'priceTypeIds', 'sessionStatusIds', 'lotStatusIds', 'calculatedTotalNotEqual', 'publishedTotalMissing', 'calculatedTotalUsdFrom', 'calculatedTotalUsdTo', 'saleDateFrom', 'saleDateTo', 'saleFinishDateFrom', 'saleFinishDateTo'],

    auctionHouses: [],
    sessionStatuses: [],
    priceTypes: [],
    lotStatuses: [],

    auctionHouseIds: Ember.computed.mapBy('auctionHouses', 'id'),
    sessionStatusIds: Ember.computed.mapBy('sessionStatuses', 'id'),
    priceTypeIds: Ember.computed.mapBy('priceTypes', 'id'),
    lotStatusIds: Ember.computed.mapBy('lotStatuses', 'id'),

    // `Id` it's a number when we parse query params, in other cases `id` it's a string
    auctionHouseStringIds: Ember.computed.map('auctionHouseIds', function (item) {
      return '' + item;
    }),

    auctionHousesOptions: (0, _auctionHousesOptions.getAuctionHousesOptions)(),
    auctionHousesVisible: (0, _computed.computedWithoutByIds)('auctionHousesOptions', 'auctionHouseStringIds'),

    initFilter: function initFilter() {
      this._super();
      this.syncDefaultQueryParams();
      this.initFilterSubjects('auctionHouseIds', 'organization', 'auctionHouses');

      this.initFilterVocabs('sessionStatusIds', 'sessionStatuses');
      this.initFilterVocabs('priceTypeIds', 'priceTypes');
      this.initFilterVocabs('lotStatusIds', 'lotStatuses');
    },


    actions: {
      clear: function clear() {
        this.setProperties({
          auctionHouses: [],
          sessionStatuses: [],
          priceTypes: [],
          lotStatuses: []
        });

        this.get('model').setProperties({
          saleMonths: []
        });

        this._super();
      },
      setCalculatedTotalNotEqualToTrue: function setCalculatedTotalNotEqualToTrue() {
        var value = this.get('model.calculatedTotalNotEqual') === true ? null : true;
        this.set('model.calculatedTotalNotEqual', value);
        this.set('model.publishedTotalMissing', null);
        this.applyFilter();
      },
      setCalculatedTotalNotEqualToFalse: function setCalculatedTotalNotEqualToFalse() {
        var value = this.get('model.calculatedTotalNotEqual') === false ? null : false;
        this.set('model.calculatedTotalNotEqual', value);
        this.set('model.publishedTotalMissing', null);
        this.applyFilter();
      },
      setPublishedTotalMissing: function setPublishedTotalMissing() {
        var value = this.get('model.publishedTotalMissing') === true ? null : true;
        this.set('model.publishedTotalMissing', value);
        this.set('model.calculatedTotalNotEqual', null);
        this.applyFilter();
      },


      left: Ember.computed('model.calculatedTotalUsdFrom', function () {
        if (this.get('model.calculatedTotalUsdFrom')) {
          return parseInt(parseInt(this.get('model.calculatedTotalUsdFrom')) / 10);
        }

        return 0;
      }),
      right: Ember.computed('model.calculatedTotalUsdTo', function () {
        if (this.get('model.calculatedTotalUsdTo')) {
          return parseInt(parseInt(this.get('model.calculatedTotalUsdTo')) / 10);
        }

        return 170000000;
      }),
      setCalculatedTotalUsd: function setCalculatedTotalUsd(start, finish) {
        this.set('model.calculatedTotalUsdFrom', parseInt(parseInt(start) * 10));
        this.set('model.calculatedTotalUsdTo', parseInt(parseInt(finish) * 10));
        this.applyFilter();
      },
      setSource: function setSource(value) {
        this.set('model.source', value);
        this.applyFilter();
      },
      setNotShown: function setNotShown() {
        var value = false === this.get('model.shown') ? null : false;
        this.set('model.shown', value);
        this.applyFilter();
      },
      setDateFrom: function setDateFrom(date) {
        this.set('model.saleDateFrom', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      },
      setDateTo: function setDateTo(date) {
        this.set('model.saleDateTo', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      },
      setFinishDateFrom: function setFinishDateFrom(date) {
        this.set('model.saleFinishDateFrom', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      },
      setFinishDateTo: function setFinishDateTo(date) {
        this.set('model.saleFinishDateTo', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      }
    }
  });
});