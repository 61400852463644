define('cdata/pods/components/sales/modals/create-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.SALE,
    classNames: ['context-modal', _constants.MODALS.SALE, 'edit-form'],
    inProcess: Ember.computed.alias('model.inProcess'),

    isEnabled: Ember.computed.and('model.organization.id', 'model.currencyVocabularyItemId', 'model.priceTypeVocabularyItemId', 'model.priceTypeVocabularyItemId', 'model.number'),
    isDisabled: Ember.computed.not('isEnabled'),

    auctionHouseTitle: Ember.computed('model.auctionHouse.id', 'model.auctionHouse.name', function () {
      return this.get('model.auctionHouse.name');
    }),

    actions: {
      setAuctionHouse: function setAuctionHouse(_, auctionHouseSubject) {
        this.set('model.auctionHouse', auctionHouseSubject);
        this.sendAction('loadOrganizationFor', this.get('model'), auctionHouseSubject.get('id'));
      },
      save: function save() {
        this.sendAction('saveNewSaleForm', this.get('model'));
      },
      follow: function follow() {
        this.sendAction('saveNewSaleForm', this.get('model'), true);
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeNewSaleForm');
      }
    }
  });
});