define('cdata/models/entity-pre-lot', ['exports', 'ember-data', 'cdata/mixins/models/user-name'], function (exports, _emberData, _userName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_userName.default, {
    entityId: attr('string'),
    entity: belongsTo('entity'),
    text: attr('string'),
    preferred: attr('boolean'),
    citationLabelVocabularyItemId: attr('string'),
    userId: attr('string')
  });
});