define('cdata/mixins/models/user-name', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    userActions: Ember.inject.service(),
    vocab: Ember.inject.service(),
    users: Ember.computed.alias('userActions.users'),

    userName: Ember.computed('userId', function () {
      return this.get('userId') && this.get('users')[this.get('userId')] || null;
    }),

    isImportUser: Ember.computed('userName', function () {
      return this.get('userName') && _constants.IMPORT_USER_NAMES.indexOf(this.get('userName')) !== -1;
    }),

    citationName: Ember.computed('citationLabelVocabularyItemId', function () {
      return this.get('citationLabelVocabularyItemId') && this.get('vocab.citationLabelsLabels.' + this.get('citationLabelVocabularyItemId')) || null;
    })
  });
});