define('cdata/components/flash-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['flash-message', 'ui', 'message'],
    classNameBindings: ['success:positive', 'error:negative', 'disabled'],

    message: Ember.computed.alias('flashMessage.message'),
    success: Ember.computed.equal('flashMessage.type', 'success'),
    error: Ember.computed.equal('flashMessage.type', 'error'),
    disabled: Ember.computed.not('flashMessage.isShown'),

    actions: {
      close: function close() {
        this.get('flashMessage').hide();
      }
    }
  });
});