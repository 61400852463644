define('cdata/pods/components/people/edit/menu-context/component', ['exports', 'cdata/mixins/components/menu-context'], function (exports, _menuContext) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_menuContext.default, {
    ajax: Ember.inject.service(),
    userActions: Ember.inject.service(),

    sourceTagsEnabled: Ember.computed.alias('userActions.sourceTags.settings.enabledTags'),
    sourceUsersEnabled: Ember.computed.alias('userActions.sourceTags.settings.enabledUsers'),

    openNewPersonForm: 'openNewPersonForm',
    openPersonForms: 'openPersonForms',
    openConversionPersonForm: 'openConversionPersonForm',
    openMergePersonToOrganizationForm: 'openMergePersonToOrganizationForm',

    add: true,
    merge: true,
    conversion: false,

    reload: 'reload',

    actions: {
      merge: function merge() {
        this.sendAction('openPersonForms', 'edit-forms-merge');
        this.closeContextMenuAction();
      },
      add: function add() {
        this.sendAction('openNewPersonForm');
        this.closeContextMenuAction();
      },
      conversion: function conversion() {
        this.sendAction('openConversionPersonForm', this.get('model'));
        this.closeContextMenuAction();
      },
      toggleSourceTags: function toggleSourceTags() {
        this.get('userActions').toggleSourceTags();
      },
      toggleSourceUsers: function toggleSourceUsers() {
        this.get('userActions').toggleSourceUsers();
      },
      miner: function miner() {
        var _this = this;

        this.set('mineInProgress', true);
        this.get('ajax').post('information_miner', { person_id: this.get('model.id') }, function () {
          setTimeout(function () {
            _this.sendAction('reload');
          }, 1000);
          _this.get('flashMessage').showSuccess('Mining is complete!');
        }, function (error) {
          _this.get('flashMessage').showSuccess(error);
        }, function () {
          _this.set('mineInProgress', false);
          _this.closeContextMenuAction();
        });
      }
    }
  });
});