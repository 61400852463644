define('cdata/pods/components/d3-zoomable-sunburst/stylesheet', ['exports', 'ember-cli-jss'], function (exports, _emberCliJss) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = new _emberCliJss.StyleSheet({
    root: {
      display: 'flex',
      justifyContent: 'center'
    },

    svg: {
      fontSize: 8
    },

    container: {
      transform: function transform(_ref) {
        var width = _ref.width;
        return 'translate(' + width / 2 + 'px, ' + width / 2 + 'px)';
      }
    },

    labelsContainer: {
      textAnchor: 'middle',
      dominantBaseline: 'middle',
      userSelect: 'none'
    },

    label: {
      pointerEvents: 'none'
    },

    clickable: {
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.8
      }
    },

    circleTitleWrapper: {
      fontSize: 10,
      pointerEvents: 'none',
      textAnchor: 'middle'
    },

    circleSubtitle: {
      transform: 'translate(0, 1em)'
    }
  });
});