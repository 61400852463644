define('cdata/pods/components/two-options-switcher/stylesheet', ['exports', 'ember-cli-jss'], function (exports, _emberCliJss) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var button = {
    color: '#000',
    backgroundColor: '#CACBCD',
    borderColor: '#000'
  };

  var buttonActive = {
    color: '#fff',
    backgroundColor: '#D65D5D',
    borderBottomColor: '#ab4a4a'
  };

  var buttonHovered = {
    color: '#fff',
    backgroundColor: '#900',
    borderBottomColor: '#000'
  };

  exports.default = new _emberCliJss.StyleSheet({
    button: {
      '.button&': _extends({
        height: 40
      }, buttonActive),

      '.ui.button&:focus': _extends({}, buttonActive),

      '.ui.button&:hover': _extends({}, buttonHovered)
    },

    unchecked: {
      '$button&': _extends({}, button)
    }
  });
});