define('cdata/pods/lots/lot-comparables', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    comparableSets: (0, _emberLocalStorage.storageFor)('comparable-sets'),

    activate: function activate() {
      this._super.apply(this, arguments);
      this.off('comparable-sets.reload').on('comparable-sets.reload', this, this.setsChanged);
    },
    setsChanged: function setsChanged(data) {
      var _this = this;

      this.get('store').query('lot-list-item', { set_id: data.set.get('id'), page_size: 1000 }).then(function (lots) {
        _this.get('controller').set('comparablesLots', lots.toArray());
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('comparablesLots', []);

      var setId = this.get('comparableSets.settings.setId');

      if (setId && this.get('comparableSets.settings.enabled')) {
        this.get('store').query('lot-list-item', { set_id: setId, page_size: 1000 }).then(function (lots) {
          controller.set('comparablesLots', lots.toArray());
        });
      }
    },


    actions: {
      addToComparableSet: function addToComparableSet(lotItem, entity) {
        var _this2 = this;

        var setId = this.get('comparableSets.settings.setId');

        var setEntity = this.get('store').createRecord('set-entity', {
          setId: setId,
          entityId: entity.id
        });

        setEntity.save().then(function () {
          _this2.get('controller').get('comparablesLots').pushObject(lotItem);
        });
      },
      removeFromComparableSet: function removeFromComparableSet(lotItem, entity) {
        var _this3 = this;

        var setId = this.get('comparableSets.settings.setId');

        this.get('store').query('set-entity', { set_id: setId }).then(function (setEntities) {
          var setEntity = setEntities.toArray().find(function (item) {
            return item.get('entityId') === entity.id;
          });
          setEntity.deleteRecord();
          setEntity.save().then(function () {
            _this3.get('controller').get('comparablesLots').removeObject(lotItem);
          });
        });
      }
    }
  });
});