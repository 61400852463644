define('cdata/pods/comparables/item/mixins/route', ['exports', 'cdata/services/natural-sorter', 'moment', 'cdata/pods/comparables/item/mixins/default-item', 'cdata/constants'], function (exports, _naturalSorter, _moment, _defaultItem, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var path = '/';
  var url_comparables = _constants.API_URL_SERVICES + '/value_explorer/value_item';

  exports.default = Ember.Mixin.create({
    comparable: Ember.inject.service(),

    model: function model() {
      var item = this.get('comparable.item');
      if (!item) {
        item = this.get('comparable').parseItemForValueExplorer(_defaultItem.default);
      }

      return item;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('estimatesParams', Ember.Object.create({
        artistName: model.get('name'),
        artworkTitle: model.get('title'),
        medium: model.get('medium'),
        creation: model.get('creation'),
        category: null,
        model: null,
        reference: null,
        weighted: null,
        height: null,
        width: null,
        boughtValue: model.get('oldValue') || '',
        boughtYear: model.get('date') ? (0, _moment.default)(model.get('date')).format('YYYY') : '',
        imageUrl: null,
        perPage: 100
      }));
    },


    actions: {
      loadCustomEstimateBar: function loadCustomEstimateBar() {
        var _this = this;

        Ember.$.getJSON(path + 'custom-estimate-bar.json').then(function (data) {
          _this.get('controller').set('customEstimateBar', data);
        });
      },
      loadCustomEstimate: function loadCustomEstimate(params) {
        var _this2 = this;

        this.get('controller').setInProgress(true);

        var queryParams = {
          maker: params.get('artistName') || undefined,
          title: params.get('artworkTitle') || undefined,
          medium: params.get('medium') || undefined,
          creation: params.get('creation') || undefined,
          category: params.get('category') || undefined,
          height: params.get('height') || undefined,
          width: params.get('width') || undefined,
          image_url: params.get('imageUrl') || undefined,
          model: params.get('model') || undefined,
          reference: params.get('reference') || undefined,
          weighted: params.get('weighted') || undefined,
          boughtvalue: params.get('boughtValue') || undefined,
          boughtyear: params.get('boughtYear') || undefined,
          per_page: params.get('perPage') || 100,
          christies_only: false,
          only_upcoming_lots: false
        };

        Ember.$.ajax({
          url: url_comparables,
          method: 'POST',
          crossDomain: true,
          data: JSON.stringify(queryParams || {}),
          dataType: 'json',
          headers: _constants.API_SERVICES_HEADERS,
          success: this._results_comparables.bind(this, params),
          complete: this._complete_comparables.bind(this)
        }).fail(function (error) {
          _this2.get('flashMessage').showError(error);
        });
      },
      loadObjectsLikeYours: function loadObjectsLikeYours() {
        var _this3 = this;

        Ember.$.getJSON(path + 'objects-like-yours.json').then(function (data) {
          _this3.get('controller').set('objectsLikeYours', data);
        });
      }
    },

    _results_indices: function _results_indices(data, params) {
      var result = data && data.message && data.message.INDEX && data.message.YEAR ? {
        currency: '$',
        old_value: params.get('boughtValue'),
        bought_year: params.get('boughtYear'),
        time: data.message.YEAR,
        index: data.message.INDEX
      } : null;

      if (!result) {
        this.get('controller').set('collectriumEstimate', {
          empty: true
        });

        return;
      }

      result.time = result.time.map(function (year) {
        return year.toString();
      });

      this.get('controller').set('collectriumEstimate', result);
    },
    _complete_comparables: function _complete_comparables() {
      this.get('controller').setInProgress(false);
    },
    _results_comparables: function _results_comparables(params, data) {
      this._results_indices(data, params);

      var items = data && data.message && data.message.COMPARABLES && data.message.COMPARABLES.results;

      if (!items || items.length === 0) {
        this.get('controller').set('customEstimate', {
          list: []
        });

        if (data.message && typeof data.message === 'string') {
          this.get('flashMessage').showError('Custom Estimate: ' + '\n' + data.message + '\n' + 'Try to use less parameters');
        }

        return;
      }

      items = items.mapBy('object');

      items.sort((0, _naturalSorter.default)('sale_date'));
      items = items.reverse();

      var groups = [];
      var qnt = 0;

      items.forEach(function (item) {
        var year = (0, _moment.default)(item.sale_date).format('YYYY');

        if (qnt >= 5) {
          return null;
        }

        if (!groups[year]) {
          groups[year] = [];
          qnt++;
        }

        if (groups[year].length >= 3) {
          return null;
        }

        groups[year].push(item);
      });

      var result = [];

      var _loop = function _loop(key, value) {
        var images = [];
        var minEstimate = null;
        var maxEstimate = null;

        value.forEach(function (item) {
          if (item && item.artwork_assets && item.artwork_assets[0] && item.artwork_assets[0].thumbnail) {
            images.push(item.artwork_assets[0].thumbnail);
          }

          if (minEstimate === null || minEstimate < item.estimate_low_usd) {
            minEstimate = item.estimate_low_usd;
          }

          if (maxEstimate === null || maxEstimate < item.estimate_high_usd) {
            maxEstimate = item.estimate_high_usd;
          }
        });

        result.push({
          year: key,
          images: images,
          currency: 'USD',
          estimate_low: minEstimate,
          estimate_high: maxEstimate
        });
      };

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(groups)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];

          _loop(key, value);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      result = result.reverse();

      var model = this.get('currentModel');

      this.get('controller').set('customEstimate', {
        list: result,
        currency: 'USD',
        estimate_low: model.get('estimateLow'),
        estimate_high: model.get('estimateHigh')
      });
    }
  });
});