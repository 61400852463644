define('cdata/pods/components/marks/edit/edit-forms-makers/item/component', ['exports', 'cdata/pods/components/marks/edit/mixins/edit-forms-item', 'cdata/pods/components/people/mixins/handle_new_person', 'cdata/pods/components/organizations/mixins/handle_new_organization'], function (exports, _editFormsItem, _handle_new_person, _handle_new_organization) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsItem.default, _handle_new_person.default, _handle_new_organization.default, {
    setMakerToMarkMaker: 'setMakerToMarkMaker',
    openNewPersonOrOrganizationForm: 'openNewPersonOrOrganizationForm',

    makerTitle: Ember.computed.alias('model.label'),

    actions: {
      setMaker: function setMaker(_, makerSubject) {
        this.sendAction('setMakerToMarkMaker', this.get('model'), makerSubject);
      },
      createPersonOrOrganization: function createPersonOrOrganization(query) {
        this.sendAction('openNewPersonOrOrganizationForm', query, true);
        this._subscribeNewPerson();
        this._subscribeNewOrganization();
      },
      setOrganization: function setOrganization(_, makerSubject) {
        this.send('setMaker', _, makerSubject);
      },
      setPerson: function setPerson(_, makerSubject) {
        this.send('setMaker', _, makerSubject);
      }
    },

    /**
     * unsubscribe person if organization completed
     * @param {Boolean} skip
     */
    _unSubscribeNewOrganization: function _unSubscribeNewOrganization() {
      var skip = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      this._super();
      if (!skip) {
        this._unSubscribeNewPerson(true);
      }
    },


    /**
     * unsubscribe organization if person completed
     * @param {Boolean} skip
     */
    _unSubscribeNewPerson: function _unSubscribeNewPerson() {
      var skip = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      this._super();
      if (!skip) {
        this._unSubscribeNewPerson(true);
      }
    }
  });
});