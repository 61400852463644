define('cdata/pods/components/buttons/inline-edit-row-cell-actions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['sixteen wide column', 'inline-edit-row-cell-actions'],
    save: 'save',
    cancel: 'cancel',
    enableSaving: true,
    enableLabel: true,

    actions: {
      save: function save() {
        this.sendAction('save');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });
});