define('cdata/pods/components/entities/edit/note-common/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms-item'], function (exports, _editFormsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsItem.default, {
    lang: true,
    citation: false
  });
});