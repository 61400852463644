define('cdata/pods/reports/components/chart-header/component', ['exports', 'ember-cli-jss', 'cdata/pods/reports/components/chart-header/stylesheet', 'cdata/pods/reports/components/chart-header/declaration'], function (exports, _emberCliJss, _stylesheet, _declaration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCliJss.TaglessJSS, {
    tagName: '',
    stylesheet: _stylesheet.default,
    propTypes: _declaration.default,

    getDefaultProps: function getDefaultProps() {
      return {
        breadcrumbs: null,
        onCloseClick: function onCloseClick() {
          return null;
        }
      };
    }
  });
});