define('cdata/pods/components/site/editor/image-relation/upload/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['six wide column'],

    setUrl: 'setUrl',
    upload: 'upload',

    actions: {
      setUrl: function setUrl() {
        this.sendAction.apply(this, ['setUrl'].concat(Array.prototype.slice.call(arguments)));
      },
      upload: function upload() {
        this.sendAction.apply(this, ['upload'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});