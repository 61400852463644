define('cdata/pods/sales/sale-mixin', ['exports', 'cdata/mixins/editor/saving-cases'], function (exports, _savingCases) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_savingCases.default, {

    actions: {
      /*
       * <<<<<< titles >>>>>>>
       */

      addSaleTitle: function addSaleTitle() {
        var model = this.get('currentModel');
        var store = this.get('store');

        var saleTitle = store.createRecord('sale-title', {
          saleId: model.get('id'),
          sale: model,
          preferred: !model.get('saleTitles').filterBy('preferred').get('length'),
          source: null,
          sourceId: null,
          sourcePage: null
        });

        model.get('saleTitles').pushObject(saleTitle);
      },
      removeSaleTitle: function removeSaleTitle(saleTitle) {
        this._removeItemWithPreferred(saleTitle, 'saleTitles');
      },
      setPreferredToSaleTitle: function setPreferredToSaleTitle(saleTitle) {
        this._changePreferred('saleTitles', saleTitle);
      },
      clearSaleTitleSection: function clearSaleTitleSection() {
        this._removeAllItems('saleTitles', 'removeSaleTitle');
      },


      /*
       * <<<<<< images >>>>>
       */

      addSaleImage: function addSaleImage() {
        var model = this.get('currentModel');
        var saleImage = this.get('store').createRecord('sale-image', {
          person: model,
          personId: model.get('id'),
          preferred: !model.get('saleImages').filterBy('preferred').get('length')
        });
        model.get('saleImages').pushObject(saleImage);
      },
      removeSaleImage: function removeSaleImage(saleImage) {
        this._removeItemWithPreferred(saleImage, 'saleImages');
      },
      setPreferredToSaleImage: function setPreferredToSaleImage(saleImage) {
        this._changePreferred('saleImages', saleImage);
      },
      clearSaleImageSection: function clearSaleImageSection() {
        this._removeAllItems('saleImages', 'removeSaleImage');
      },


      /*
       * <<<<< descriptions >>>>
       */

      setPreferredToSaleDescription: function setPreferredToSaleDescription(saleDescription) {
        this._changePreferred('saleDescriptions', saleDescription);
      },
      removeSaleDescription: function removeSaleDescription(saleDescription) {
        this._removeItemWithPreferred(saleDescription, 'saleDescriptions');
      },
      clearSaleDescriptionSection: function clearSaleDescriptionSection() {
        this._removeAllItems('saleDescriptions', 'removeSaleDescription');
      },
      addSaleDescription: function addSaleDescription() {
        var model = this.get('currentModel');
        var store = this.get('store');

        var saleDescription = store.createRecord('sale-description', {
          saleId: model.get('id'),
          sale: model,
          preferred: !model.get('saleDescriptions').filterBy('preferred').get('length')
        });

        model.get('saleDescriptions').pushObject(saleDescription);
      },


      /*
       * <<<<<< globals >>>>>>>
       */

      cancel: function cancel() {
        this.cancel();
      },
      save: function save() {
        this.save();
      },
      loadOrganizationFor: function loadOrganizationFor(model, id) {
        model.set('organization', null);
        this.get('store').findRecord('organization', id).then(function (organization) {
          model.set('organization', organization);
          model.set('organizationId', organization.get('id'));
        });
      }
    },

    _clean: function _clean() {
      this._removeItemsByEmptyField('saleTitles', 'text', 'removeSaleTitle');
      this._removeItemsByEmptyField('saleDescriptions', 'text', 'removeSaleDescription');
      this._removeItemsByEmptyField('saleImages', 'image.url', 'removeSaleImage');
    },
    cancel: function cancel() {
      var model = this.get('currentModel');
      model.rollbackAttributes();

      this.get('relations').rollbackHasMany(model, ['sessions']);

      this._clearEditor();
    },
    save: function save() {
      var _this = this;

      this._clean();

      var model = this.get('currentModel');

      model.get('saleImages').forEach(function (item) {
        _this._saveRelationWithImage(item);
      });

      // saving simple has many relations
      this._handleSaving(this.get('relations').saveSimpleHasMany(model, ['saleTitles', 'saleDescriptions']));

      if (model.get('hasDirtyAttributes')) {
        this._handleSaving(model.save());
      } else {
        this._handleSaving();
      }
    }
  });
});