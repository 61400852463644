define('cdata/pods/components/entities/edit/edit-forms-creation-places/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms', 'cdata/pods/components/places/mixins/handle_new_place'], function (exports, _editForms, _handle_new_place) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('CreationPlace'), _handle_new_place.default, {
    autoCreating: false,

    placesIds: Ember.computed.mapBy('places', 'placeId'),

    places: Ember.computed.filterBy('model.entityCreationPlaces', 'isDeleted', false),

    subjectFilter: { clear: true },

    placeText: null,
    placeItem: null,
    citationLabelVocabularyItemId: null,

    enabled: Ember.computed.or('placeText', 'placeItem'),
    disabled: Ember.computed.not('enabled'),

    creationPlaceTitle: Ember.computed('model.isGem', function () {
      return this.get('model.isGem') ? 'Origin places' : 'Creation places';
    }),

    actions: {
      setPlace: function setPlace(_, subjectPlace) {
        this.set('placeItem', subjectPlace);
      },
      add: function add() {
        this.get('placeItem').set('citationLabelVocabularyItemId', this.get('citationLabelVocabularyItemId'));
        this.sendAction('addEntityCreationPlace', this.get('placeItem'), this.get('placeText'));
        this.send('reset');
      },
      reset: function reset() {
        this.set('placeText', null);
        this.set('placeItem', null);
        this.set('citationLabelVocabularyItemId', null);
      }
    }
  });
});