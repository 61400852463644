define('cdata/helpers/short-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shortUrl = shortUrl;
  function shortUrl(params) {
    var url = params[0] + '';
    var length = params[1] || 30;
    if (!url || url.length < length) {
      return url;
    }
    url = url.substr(0, length) + '...' + url.substr(-5);

    return url;
  }

  exports.default = Ember.Helper.helper(shortUrl);
});