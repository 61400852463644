define('cdata/mixins/editor/add-comment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      this.setupComments(model);
    },
    setupComments: function setupComments(model) {
      model = model || this.get('currentModel');

      model.set('comments', this.get('store').peekAll('comment').filter(function (item) {
        return item.get('entity') === model.constructor.modelName && item.get('entityId') === model.get('id');
      }).sortBy('insertedAt').reverse());
    },


    actions: {
      updateComment: function updateComment(model, comment, callback) {
        var _this = this;

        this._handleSaving(comment.save()).then(function () {
          _this.setupComments(model);
          callback();
        });
      },
      addNewComment: function addNewComment(model, text, asPublic, callback) {
        var _this2 = this;

        var comment = this.get('store').createRecord('comment', {
          entity: model.constructor.modelName,
          entityId: model.get('id'),
          text: text,
          public: !!asPublic
        });

        this._handleSaving(comment.save()).then(function () {
          _this2.setupComments(model);
          callback();
        });
      },
      deleteComment: function deleteComment(comment, callback) {
        comment.set('deleted', true);
        comment.deleteRecord();

        this._handleSaving(comment.save()).then(function () {
          callback();
        });
      }
    }
  });
});