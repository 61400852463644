define('cdata/models/lot', ['exports', 'ember-data', 'cdata/helpers/short-url'], function (exports, _emberData, _shortUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var escape = Ember.Handlebars.Utils.escapeExpression;

  exports.default = Model.extend({
    _title: attr('string'),
    sessionId: attr('string'),
    session: belongsTo('session'),
    number: attr('string'),
    lotStatusVocabularyItemId: attr('string'),
    estimateTypeVocabularyItemId: attr('string'),
    url: attr('string'),
    source: attr('string'),
    sourceId: attr('string'),
    guaranteed: attr('boolean'),
    resaleRightArtist: attr('boolean'),
    auctionHouseOwnership: attr('boolean'),
    reserveOff: attr('boolean'),
    thirdParty: attr('boolean'),
    shown: attr('boolean'),
    lotPrices: hasMany('lot-price'),
    lotTitles: hasMany('lot-title'),
    lotDescriptions: hasMany('lot-description'),
    entityLots: hasMany('entity-lot'),
    previousLotId: attr('number'),
    nextLotId: attr('number'),
    impliedBidsReserve: attr('number'),
    impliedBidsLowEstimate: attr('number'),
    multiObject: attr('boolean'),
    multiObjectCount: attr('number'),
    uuid: attr('string'),
    christiesSpecialistDepartmentsText: attr('string'),

    lotChristiesSpecialistDepartments: hasMany('lot-christies-specialist-department'),

    lotSymbols: hasMany('lot-symbol'),

    sources: Ember.computed('url', 'source', 'sourceId', function () {
      var sources = [];
      var source = this.get('source');
      var sourceId = this.get('sourceId');

      if (source) {
        sources.push(escape(source));

        if (sourceId) {
          sources.push(' &rarr; ' + sourceId);
        }
      }

      var url = this.get('url');
      if (source === 'christies' && sourceId) {
        url = 'https://www.christies.com/lotfinder/lot_details2.aspx?intObjectID=' + sourceId;
      }

      if (url) {
        sources.push('<a href="' + escape(url) + '" target="_blank">' + _shortUrl.default.compute([url, 50]) + '</a>');
      }

      return sources.join(', ').htmlSafe();
    })
  });
});