define('cdata/pods/components/sales/modals/spectrum-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.SALE_SPECTRUM,
    classNames: ['context-modal', _constants.MODALS.SALE_SPECTRUM, 'edit-form'],
    inProcess: Ember.computed.alias('model.inProcess'),

    isEnabled: Ember.computed.alias('model.title'),
    isDisabled: Ember.computed.not('isEnabled'),

    closeSpectrumSaleForm: 'closeSpectrumSaleForm',
    saveSpectrumSaleForm: 'saveSpectrumSaleForm',

    actions: {
      save: function save() {
        this.sendAction('saveSpectrumSaleForm', this.get('model'));
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeSpectrumSaleForm');
      }
    }
  });
});