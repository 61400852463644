define('cdata/services/ajax', ['exports', 'cdata/constants', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _constants, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_fileSaver.default, {
    session: Ember.inject.service(),

    request: function request(options) {
      return new Promise(function (resolve, reject) {
        return Ember.$.ajax(options).then(resolve, reject);
      });
    },


    postRequest: function postRequest(endpoint, params) {
      return this.request({
        url: _constants.API_URL + '/' + endpoint,
        method: 'POST',
        crossDomain: true,
        data: JSON.stringify(params),
        dataType: 'json',
        headers: this.get('session.authHeader')
      });
    },

    post: function post(endpoint, params, _success, fail, _complete) {
      return Ember.$.ajax({
        url: _constants.API_URL + '/' + endpoint,
        method: 'POST',
        crossDomain: true,
        data: JSON.stringify(params),
        dataType: 'json',
        headers: this.get('session.authHeader'),
        success: function success(response) {
          Ember.run(function () {
            if (typeof _success === 'function') {
              _success(response);
            }
          });
        },
        complete: function complete() {
          Ember.run(function () {
            if (typeof _complete === 'function') {
              _complete();
            }
          });
        }
      }).fail(function (error) {
        return typeof fail === 'function' ? fail(error) : null;
      });
    },

    put: function put(endpoint, params, _success2, fail, _complete2) {
      return Ember.$.ajax({
        url: _constants.API_URL + '/' + endpoint,
        method: 'PUT',
        crossDomain: true,
        data: JSON.stringify(params),
        dataType: 'json',
        headers: this.get('session.authHeader'),
        success: function success(response) {
          Ember.run(function () {
            if (typeof _success2 === 'function') {
              _success2(response);
            }
          });
        },
        complete: function complete() {
          Ember.run(function () {
            if (typeof _complete2 === 'function') {
              _complete2();
            }
          });
        }
      }).fail(function (error) {
        return typeof fail === 'function' ? fail(error) : null;
      });
    },

    $get: function $get(endpoint, params, _success3, fail, _complete3) {
      return Ember.$.ajax({
        url: _constants.API_URL + '/' + endpoint,
        method: 'GET',
        crossDomain: true,
        data: params,
        dataType: 'json',
        headers: this.get('session.authHeader'),
        success: function success(response) {
          Ember.run(function () {
            if (typeof _success3 === 'function') {
              _success3(response);
            }
          });
        },
        complete: function complete() {
          Ember.run(function () {
            if (typeof _complete3 === 'function') {
              _complete3();
            }
          });
        }
      }).fail(function (error) {
        return typeof fail === 'function' ? fail(error) : null;
      });
    },

    download: function download(url, name) {
      var _this = this;

      var contentType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'plain/text';

      Ember.$.ajax({
        url: _constants.API_URL + '/' + url,
        method: 'GET',
        crossDomain: true,
        dataType: 'arraybuffer', // or 'blob'
        processData: false,
        headers: this.get('session.authHeader')
      }).then(function (content) {
        return _this.saveFileAs(name, content, contentType);
      });
    }
  });
});