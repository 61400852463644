define('cdata/pods/components/organizations/edit/edit-forms-roles/component', ['exports', 'cdata/pods/components/organizations/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Role'), {
    autoCreating: false,

    rolesIds: Ember.computed.mapBy('roles', 'roleId'),

    roles: Ember.computed.filterBy('model.organizationRoles', 'isDeleted', false),

    role: null,
    citationId: null,

    actions: {
      setRole: function setRole(_, item) {
        this.set('role', item);
      },
      setCitationId: function setCitationId(citationId) {
        this.set('citationId', citationId);
      },
      add: function add() {
        this.sendAction('addOrganizationRole', this.get('role.id'), this.get('citationId'));
        this.set('role', null);
        this.set('citationId', null);
      }
    }
  });
});