define('cdata/pods/components/entities/list/sort-box/component', ['exports', 'cdata/mixins/lists/component-sort-box'], function (exports, _componentSortBox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentSortBox.default, {
    values: [Ember.Object.create({
      title: 'Title',
      key: 'title'
    }), Ember.Object.create({
      title: 'Lots count',
      key: 'lots_count'
    }), Ember.Object.create({
      title: 'Creation date',
      key: 'creation_date'
    }), Ember.Object.create({
      title: 'Creator name',
      key: 'creator_name'
    }), Ember.Object.create({
      title: 'Modified',
      key: 'updated_at'
    })]
  });
});