define('cdata/pods/components/entities/edit/edit-forms-parse/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms', 'cdata/mixins/components/manage-source-actions', 'cdata/services/natural-sorter'], function (exports, _editForms, _manageSourceActions, _naturalSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Description'), _manageSourceActions.default, {
    descriptions: Ember.computed.filterBy('model.entityDescriptions', 'isDeleted', false),

    parse: Ember.computed.alias('model._parsingDescription'),

    saveParsing: 'saveParsing',

    materialTermsSorted: Ember.computed.sort('parse.materialValues', (0, _naturalSorter.default)('label')),

    actions: {
      save: function save() {
        this.sendAction('saveParsing', this.get('parse'));
      },
      addMaterialTerm: function addMaterialTerm(_, subject) {
        var exist = this.get('parse.materialValues').findBy('materialVocabularyItemId', subject.get('id'));
        if (!exist) {
          this.get('parse.materialValues').pushObject(Ember.Object.create({
            materialVocabularyItemId: subject.get('id'),
            label: subject.get('name')
          }));
        }
      },
      removeMaterialTerm: function removeMaterialTerm(entityMaterialTerm) {
        this.get('parse.materialValues').removeObject(entityMaterialTerm);
      },
      addMeasurementValue: function addMeasurementValue() {
        this.get('parse.measurementValues').pushObject(Ember.Object.create({
          typeVocabularyItemId: null,
          unitVocabularyItemId: null,
          value: null
        }));
      },
      removeMeasurementValue: function removeMeasurementValue(measurementValue) {
        this.get('parse.measurementValues').removeObject(measurementValue);
      },
      clearCreationDates: function clearCreationDates() {
        this.set('parse.date', null);
      },
      addCreationDates: function addCreationDates() {
        this.set('parse.date', Ember.Object.create({
          text: '',
          startYear: null,
          finishYear: null
        }));
      }
    }
  });
});