define("cdata/mirage/datasets/person_list_items", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "person_list_items": [{
      "vital_dates": null,
      "updated_at": "2017-05-09T04:04:18.707304Z",
      "role_ids": [],
      "role": null,
      "person_names": ["Shame 125", "S. Averly", "14 Taisha 3"],
      "person_id": 371105,
      "nationality_ids": [],
      "nationality": null,
      "name": "14 Taisha 3",
      "image_url": null,
      "id": 371105,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": null,
      "updated_at": "2017-05-08T16:48:13.679201Z",
      "role_ids": [],
      "role": null,
      "person_names": ["2nd Bt Hoare Richard Colt"],
      "person_id": 146753,
      "nationality_ids": [],
      "nationality": null,
      "name": "2nd Bt Hoare Richard Colt",
      "image_url": null,
      "id": 146753,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1806 - 1891",
      "updated_at": "2017-05-09T05:34:02.905599Z",
      "role_ids": [],
      "role": null,
      "person_names": ["3rd Baron De Saumarez John St. Vincent"],
      "person_id": 408890,
      "nationality_ids": [],
      "nationality": null,
      "name": "3rd Baron De Saumarez John St. Vincent",
      "image_url": null,
      "id": 408890,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1800 - 1849",
      "updated_at": "2017-05-09T04:28:03.967454Z",
      "role_ids": [],
      "role": null,
      "person_names": ["3rd Earl Of Carnarvon Henry John George Herbert"],
      "person_id": 379757,
      "nationality_ids": [],
      "nationality": null,
      "name": "3rd Earl Of Carnarvon Henry John George Herbert",
      "image_url": null,
      "id": 379757,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": null,
      "updated_at": "2017-05-08T15:53:37.399463Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A  Famechon E", "A  Lamont Oca Laura", "A  Browning Chas"],
      "person_id": 89860,
      "nationality_ids": [],
      "nationality": null,
      "name": "A  Browning Chas",
      "image_url": null,
      "id": 89860,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1853 - 2003",
      "updated_at": "2017-05-08T14:53:45.127175Z",
      "role_ids": [1627, 1630],
      "role": "artist",
      "person_names": ["William A. Elleby", "Elleby, William A.", "William Alfred Elleby", "Elleby, William Alfred", "A  Elleby William"],
      "person_id": 60857,
      "nationality_ids": [901],
      "nationality": "British",
      "name": "A  Elleby William",
      "image_url": null,
      "id": 60857,
      "gender": "male",
      "disambiguating_comment": null,
      "biography": "British painter and landscapist, active 1883-1903",
      "approved": false
    }, {
      "vital_dates": "1906",
      "updated_at": "2017-05-08T15:53:37.657437Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A  Larter E", "A  Mercurio D", "A  Lgotak Lyubov", "A  Wentworth George"],
      "person_id": 89867,
      "nationality_ids": [],
      "nationality": null,
      "name": "A  Wentworth George",
      "image_url": null,
      "id": 89867,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1900",
      "updated_at": "2017-05-08T15:53:37.653058Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A  Wills Leslie"],
      "person_id": 89866,
      "nationality_ids": [],
      "nationality": null,
      "name": "A  Wills Leslie",
      "image_url": null,
      "id": 89866,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1948",
      "updated_at": "2017-05-08T22:56:38.324602Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A Bakumofu•mihayier•geaoerjiyeweiqi", "A Amiaux"],
      "person_id": 242581,
      "nationality_ids": [],
      "nationality": null,
      "name": "A Amiaux",
      "image_url": null,
      "id": 242581,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1953",
      "updated_at": "2017-05-08T22:56:38.653199Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A Battachi", "A Belkin", "A Barron"],
      "person_id": 242585,
      "nationality_ids": [],
      "nationality": null,
      "name": "A Barron",
      "image_url": null,
      "id": 242585,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": null,
      "updated_at": "2017-05-09T06:43:21.528600Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A Bergnier", "A Bellogiorno"],
      "person_id": 436290,
      "nationality_ids": [],
      "nationality": null,
      "name": "A Bellogiorno",
      "image_url": null,
      "id": 436290,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1952",
      "updated_at": "2017-05-09T02:01:07.166778Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A Bu", "A Boluozhou"],
      "person_id": 310214,
      "nationality_ids": [],
      "nationality": null,
      "name": "A Boluozhou",
      "image_url": null,
      "id": 310214,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1939",
      "updated_at": "2017-05-09T02:16:19.761650Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A Budelai·aosimannuofu"],
      "person_id": 319275,
      "nationality_ids": [],
      "nationality": null,
      "name": "A Budelai·aosimannuofu",
      "image_url": null,
      "id": 319275,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1952",
      "updated_at": "2017-05-09T02:26:09.938034Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A Budumijiti·yiertuhan"],
      "person_id": 325404,
      "nationality_ids": [],
      "nationality": null,
      "name": "A Budumijiti·yiertuhan",
      "image_url": null,
      "id": 325404,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1947",
      "updated_at": "2017-05-09T02:11:02.138348Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A Budu·kelimu·nasierding"],
      "person_id": 316622,
      "nationality_ids": [],
      "nationality": null,
      "name": "A Budu·kelimu·nasierding",
      "image_url": null,
      "id": 316622,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1952",
      "updated_at": "2017-05-09T02:15:11.277100Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A Budu·mijiti"],
      "person_id": 318560,
      "nationality_ids": [],
      "nationality": null,
      "name": "A Budu·mijiti",
      "image_url": null,
      "id": 318560,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1909",
      "updated_at": "2017-05-08T22:56:38.652446Z",
      "role_ids": [1630],
      "role": "artist",
      "person_names": ["A D Bell", "Bell, A. D.", "A. D. Bell", "A Buyi", "A Claus"],
      "person_id": 242584,
      "nationality_ids": [901],
      "nationality": "British",
      "name": "A Claus",
      "image_url": null,
      "id": 242584,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1815",
      "updated_at": "2017-05-09T02:25:39.034362Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A Daofu·mencaier"],
      "person_id": 325078,
      "nationality_ids": [],
      "nationality": null,
      "name": "A Daofu·mencaier",
      "image_url": null,
      "id": 325078,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1964",
      "updated_at": "2017-05-08T14:26:55.627910Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A Ding（youhua）", "A Delun", "A Ding"],
      "person_id": 31277,
      "nationality_ids": [],
      "nationality": null,
      "name": "A Ding",
      "image_url": null,
      "id": 31277,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }, {
      "vital_dates": "1973",
      "updated_at": "2017-05-09T02:02:32.417189Z",
      "role_ids": [],
      "role": null,
      "person_names": ["A Dou"],
      "person_id": 311139,
      "nationality_ids": [],
      "nationality": null,
      "name": "A Dou",
      "image_url": null,
      "id": 311139,
      "gender": null,
      "disambiguating_comment": null,
      "biography": null,
      "approved": false
    }], "meta": { "total_pages": 22127, "page_size": 20, "page_number": 1, "count_total": 442530, "count_filtered": 442530 }
  };
});