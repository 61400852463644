define('cdata/pods/components/lots/edit/edit-forms-symbols/component', ['exports', 'cdata/pods/components/lots/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Symbols'), {
    allSpec: Ember.computed.reads('vocab.lotSymbolsLabels'),

    autoCreating: false,
    spec: null,

    actions: {
      setSpec: function setSpec(_, item) {
        this.set('spec', item);
      },
      addLine: function addLine() {
        var _getProperties = this.getProperties('spec'),
            spec = _getProperties.spec;

        this.sendAction('addLotSymbols', spec);
        this.setProperties({ spec: null });
      }
    }
  });
});