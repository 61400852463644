define('cdata/pods/components/dates/edit-fields/component', ['exports', 'cdata/helpers/creation-dates'], function (exports, _creationDates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['nDash:n-dash'],

    disabled: false,
    citation: false,

    init: function init() {
      this._super.apply(this, arguments);
      var path = 'model.' + this.get('path');
      this.set('text', Ember.computed.alias(path));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var model = this.get('model');

      if (!model) {
        return;
      }

      this._doReceiveModel(model);
    },


    classNames: ['dates-edit-fields'],
    isBirthDates: false,
    isCreations: false,
    isExistence: false,

    required: false,

    bcStart: false,
    bcFinish: false,

    start: null,
    finish: null,

    nDash: false,

    autoText: Ember.computed('start', 'finish', 'bcStart', 'bcFinish', function () {
      if (this.get('isCreations')) {
        var _start = this.get('bcStart') ? '-' + this.get('start') : this.get('start');
        var _finish = this.get('bcFinish') ? '-' + this.get('finish') : this.get('finish');

        return _creationDates.default.compute([_start, _finish]);
      }

      var start = this._bringDate(this.get('start'));
      var finish = this._bringDate(this.get('finish'));

      var result = '';

      if (start && this.get('bcStart')) {
        start += ' BC';
      }

      if (finish && this.get('bcFinish')) {
        finish += ' BC';
      }

      if (start && finish) {
        result = start + ' ‒ ' + finish;
      } else if (start || finish) {
        if (!finish) {
          if (this.get('isBirthDates')) {
            result = 'b. ' + start;
          } else {
            result = 'started at ' + start;
          }
        }
        if (!start) {
          result = 'unknown ‒ ' + finish;
        }
      }

      return result;
    }),

    path: 'text',

    /**
     * Text field is auto (empty or equal current text)
     */
    isAutoText: Ember.computed('autoText', 'text', function () {
      return Ember.isEmpty(this.get('text')) || this.get('autoText') === this.get('text') || this.get('autoText') === this.get('text').replace('-', '‒');
    }),

    isValid: Ember.computed('model.{startYear,finishYear}', function () {
      var start = this._bringDate(this.get('model.startYear'));
      var finish = this._bringDate(this.get('model.finishYear'));

      if (!start.length || !finish.length) {
        return true;
      }

      return parseInt(start) <= parseInt(finish);
    }),

    errorMessage: Ember.computed('isValid', function () {
      if (this.get('isValid')) {
        return '';
      }

      if (this.get('isBirthDates') || this.get('isExistence')) {
        return this.get('startLabel') + ' year must be precede ' + this.get('finishLabel') + ' year';
      }

      return this.get('startLabel') + ' year must be earlier then ' + this.get('finishLabel') + ' year';
    }),

    actions: {
      startYearChanged: function startYearChanged(value, bc) {
        var auto = this.get('isAutoText');
        var autoFinish = this.get('bcStart') === this.get('bcFinish');

        if (typeof bc === 'boolean') {
          this.set('bcStart', bc);
        }

        this.set('start', value);

        var bcValue = value;
        if (this.get('bcStart')) {
          bcValue = '-' + value;
        }

        autoFinish = !this.get('isCreations') && this.get('model.startYear') === this.get('model.finishYear') && autoFinish;

        this.set('model.startYear', bcValue);

        if (autoFinish) {
          this.set('model.finishYear', bcValue);
          this.set('finish', value);
          this.set('bcFinish', this.get('bcStart'));
        }

        if (auto && !this.get('isCreations')) {
          this.send('textChanged', this.get('autoText'));
        }
      },
      finishYearChanged: function finishYearChanged(value, bc) {
        var auto = this.get('isAutoText');
        if (typeof bc === 'boolean') {
          this.set('bcFinish', bc);
        }

        this.set('finish', value);

        var bcValue = value;
        if (this.get('bcFinish')) {
          bcValue = '-' + value;
        }
        this.set('model.finishYear', bcValue);

        if (auto && !this.get('isCreations')) {
          this.send('textChanged', this.get('autoText'));
        }
      },
      textChanged: function textChanged(value) {
        var key = 'model.' + this.get('path');
        this.set(key, value);

        if (Ember.isEmpty(value) && !this.get('isCreations')) {
          this.set(key, this.get('autoText'));
        } else {
          this.sendAction('textHasChanged', value);
        }
      },
      textClicked: function textClicked() {
        if (this.get('isAutoText') && !this.get('isCreations')) {
          this.send('textChanged', this.get('autoText'));
        }
      },
      changeBcStart: function changeBcStart() {
        this.send('startYearChanged', this.get('start'), !this.get('bcStart'));
      },
      changeBcFinish: function changeBcFinish() {
        this.send('finishYearChanged', this.get('finish'), !this.get('bcFinish'));
      }
    },

    _hasDash: function _hasDash(value) {
      return value ? /^-.*$/.test(value) : false;
    },
    _bringDate: function _bringDate(value) {
      if (value === 0 || value === '0') {
        value = 0;
      } else if (value === '-') {
        value = null;
      } else {
        value = parseInt(value) || null;
      }

      return null === value ? '' : '' + value;
    },
    _doReceiveModel: function _doReceiveModel(model) {
      this.set('bcStart', false);
      if (this._hasDash(model.get('startYear'))) {
        this.set('bcStart', true);
      }

      this.set('bcFinish', false);
      if (this._hasDash(model.get('finishYear'))) {
        this.set('bcFinish', true);
      }

      var start = Math.abs(parseInt(this._bringDate(model.get('startYear'))));
      var finish = Math.abs(parseInt(this._bringDate(model.get('finishYear'))));

      this.set('start', isNaN(start) ? null : start);
      this.set('finish', isNaN(finish) ? null : finish);
    }
  });
});