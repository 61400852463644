define('cdata/mirage/datasets/vocabularies', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var vocabularies = exports.vocabularies = {
    vocabularies: [{ name: 'Image sizes', id: 1 }, {
      name: 'MI Categories',
      id: 2
    }, { name: "Christie's Business Groups", id: 3 }, { name: 'Cities', id: 4 }, {
      name: 'Countries',
      id: 5
    }, { name: 'Languages', id: 6 }, { name: 'Object types', id: 7 }, {
      name: 'Regions',
      id: 8
    }, { name: 'Measurement units', id: 9 }, { name: 'CDATA Categories', id: 10 }, {
      name: 'Currencies',
      id: 11
    }, { name: 'Measurement types', id: 12 }, { name: 'Lot statuses', id: 13 }, {
      name: 'Session statuses',
      id: 14
    }, { name: 'Location types', id: 15 }, { name: 'Convention systems', id: 16 }, {
      name: 'National affiliations',
      id: 17
    }, { name: 'Person qualifiers', id: 18 }, { name: 'Name labels', id: 19 }, {
      name: 'Roles',
      id: 20
    }, { name: 'Source types', id: 21 }, { name: 'Techniques', id: 22 }, {
      name: 'Materials',
      id: 23
    }, { name: 'Colors', id: 24 }, { name: 'Contact types', id: 25 }, {
      name: 'Inscription types',
      id: 26
    }, { name: 'Estimate types', id: 27 }, { name: 'Price types', id: 28 }, {
      name: 'Academic specializations',
      id: 29
    }, { name: 'Academic degrees', id: 30 }, { name: 'Creation terms', id: 31 }, {
      name: 'Date types',
      id: 32
    }, { name: 'Note relation types', id: 33 }, { name: 'Place relation types', id: 34 }, {
      name: 'Place types',
      id: 35
    }, { name: 'Source relation types', id: 36 }, { name: 'Sale channels', id: 37 }, {
      name: 'Shapes / Watch cases',
      id: 38
    }, { name: 'Shapes / Gemstones ', id: 39 }, { name: 'Shapes / Gemstones cuts', id: 40 }, {
      name: 'Shapes',
      id: 41
    }, { name: 'Mark types', id: 42 }, { name: 'Clarity', id: 43 }, { name: 'Gem settings', id: 44 }, { name: 'Genders', id: 45 }, { name: 'Jewelry types', id: 46 }, { name: 'Examination types', id: 47 }, { name: 'Colors', id: 48 }, { name: 'Styles', id: 49 }, { name: 'Gemstone cuts', id: 50 }, { name: 'Treatment types', id: 60 }, { name: 'Treatment degrees', id: 61 }, { name: 'Color Intensity', id: 62 }, { name: 'Gemstone cut grades', id: 63 }, { name: 'Mark symbols', id: 64 }, { name: 'JDE Departments', id: 65 }, { name: 'Christies Specialist', id: 66 }, { name: 'JDE Codes', id: 67 }, { name: 'Citation labels', id: 68 }, { name: 'Departments / Clusters', id: 498 }]
  };

  var sids = exports.sids = {
    sids: [{
      sid: 'vocabulary_citation_labels',
      path: 'vocabulary',
      id: 96,
      entity_id: 68
    }, {
      sid: 'vocabulary_three_digit_codes',
      path: 'vocabulary',
      id: 95,
      entity_id: 67
    }, {
      sid: 'vocabulary_christies_specialists',
      path: 'vocabulary',
      id: 94,
      entity_id: 66
    }, {
      sid: 'vocabulary_jde_departments',
      path: 'vocabulary',
      id: 93,
      entity_id: 65
    }, {
      sid: 'vocabulary_mark_symbols',
      path: 'vocabulary',
      id: 92,
      entity_id: 64
    }, {
      sid: 'material_mineral',
      path: 'vocabulary',
      id: 91,
      entity_id: 1025
    }, {
      sid: 'material_diamond',
      path: 'vocabulary',
      id: 87,
      entity_id: 1021
    }, {
      sid: 'material_ruby',
      path: 'vocabulary',
      id: 88,
      entity_id: 1022
    }, {
      sid: 'material_emerald',
      path: 'vocabulary',
      id: 89,
      entity_id: 1023
    }, {
      sid: 'material_sapphire',
      path: 'vocabulary',
      id: 90,
      entity_id: 1024
    }, {
      sid: 'vocabulary_gemstone_cuts',
      path: 'vocabulary',
      id: 86,
      entity_id: 50
    }, {
      sid: 'vocabulary_treatment_types',
      path: 'vocabulary',
      id: 85,
      entity_id: 60
    }, {
      sid: 'vocabulary_treatment_degrees',
      path: 'vocabulary',
      id: 84,
      entity_id: 61
    }, {
      sid: 'vocabulary_color_intensity',
      path: 'vocabulary',
      id: 83,
      entity_id: 62
    }, {
      sid: 'vocabulary_gemstone_cut_grades',
      path: 'vocabulary',
      id: 82,
      entity_id: 63
    }, {
      sid: 'vocabulary_styles',
      path: 'vocabulary',
      id: 81,
      entity_id: 49
    }, {
      sid: 'vocabulary_colors',
      path: 'vocabulary',
      id: 80,
      entity_id: 48
    }, {
      sid: 'vocabulary_examination_types',
      path: 'vocabulary',
      id: 79,
      entity_id: 47
    }, {
      sid: 'vocabulary_jewelry_types',
      path: 'vocabulary',
      id: 78,
      entity_id: 46
    }, {
      sid: 'vocabulary_genders',
      path: 'vocabulary',
      id: 77,
      entity_id: 45
    }, {
      sid: 'vocabulary_gem_settings',
      path: 'vocabulary',
      id: 76,
      entity_id: 44
    }, {
      sid: 'vocabulary_clarity',
      path: 'vocabulary',
      id: 75,
      entity_id: 43
    }, {
      sid: 'vocabulary_mark_types',
      path: 'vocabulary',
      id: 73,
      entity_id: 42
    }, {
      sid: 'vocabulary_shapes',
      path: 'vocabulary',
      id: 74,
      entity_id: 41
    }, {
      sid: 'vocabulary_note_relation_types',
      path: 'vocabulary',
      id: 1,
      entity_id: 33
    }, {
      sid: 'vocabulary_entity_types',
      path: 'vocabulary',
      id: 2,
      entity_id: 7
    }, {
      sid: 'vocabulary_nationalities',
      path: 'vocabulary',
      id: 3,
      entity_id: 17
    }, {
      sid: 'vocabulary_techniques',
      path: 'vocabulary',
      id: 4,
      entity_id: 22
    }, {
      sid: 'vocabulary_convention_types',
      path: 'vocabulary',
      id: 5,
      entity_id: 16
    }, {
      sid: 'vocabulary_christies_business_groups',
      path: 'vocabulary',
      id: 6,
      entity_id: 3
    }, {
      sid: 'vocabulary_inscription_types',
      path: 'vocabulary',
      id: 7,
      entity_id: 26
    }, {
      sid: 'vocabulary_cdata_categories',
      path: 'vocabulary',
      id: 8,
      entity_id: 10
    }, {
      sid: 'vocabulary_session_statuses',
      path: 'vocabulary',
      id: 9,
      entity_id: 14
    }, {
      sid: 'vocabulary_source_types',
      path: 'vocabulary',
      id: 10,
      entity_id: 21
    }, {
      sid: 'vocabulary_mi_categories',
      path: 'vocabulary',
      id: 11,
      entity_id: 2
    }, {
      sid: 'vocabulary_creator_qualifiers',
      path: 'vocabulary',
      id: 12,
      entity_id: 18
    }, {
      sid: 'vocabulary_materials',
      path: 'vocabulary',
      id: 13,
      entity_id: 23
    }, {
      sid: 'vocabulary_measurement_units',
      path: 'vocabulary',
      id: 14,
      entity_id: 9
    }, {
      sid: 'vocabulary_creation_terms',
      path: 'vocabulary',
      id: 15,
      entity_id: 31
    }, {
      sid: 'vocabulary_roles',
      path: 'vocabulary',
      id: 16,
      entity_id: 20
    }, {
      sid: 'vocabulary_source_relation_types',
      path: 'vocabulary',
      id: 17,
      entity_id: 36
    }, {
      sid: 'vocabulary_languages',
      path: 'vocabulary',
      id: 18,
      entity_id: 6
    }, {
      sid: 'vocabulary_image_sizes',
      path: 'vocabulary',
      id: 19,
      entity_id: 1
    }, {
      sid: 'vocabulary_contact_types',
      path: 'vocabulary',
      id: 20,
      entity_id: 25
    }, {
      sid: 'vocabulary_countries',
      path: 'vocabulary',
      id: 21,
      entity_id: 5
    }, {
      sid: 'vocabulary_date_types',
      path: 'vocabulary',
      id: 22,
      entity_id: 32
    }, {
      sid: 'vocabulary_measurement_types',
      path: 'vocabulary',
      id: 23,
      entity_id: 12
    }, {
      sid: 'vocabulary_place_types',
      path: 'vocabulary',
      id: 24,
      entity_id: 35
    }, {
      sid: 'vocabulary_academic_specializations',
      path: 'vocabulary',
      id: 25,
      entity_id: 29
    }, {
      sid: 'vocabulary_location_types',
      path: 'vocabulary',
      id: 26,
      entity_id: 15
    }, {
      sid: 'vocabulary_sale_channels',
      path: 'vocabulary',
      id: 27,
      entity_id: 37
    }, {
      sid: 'vocabulary_lot_statuses',
      path: 'vocabulary',
      id: 28,
      entity_id: 13
    }, {
      sid: 'vocabulary_academic_degrees',
      path: 'vocabulary',
      id: 29,
      entity_id: 30
    }, {
      sid: 'vocabulary_currencies',
      path: 'vocabulary',
      id: 30,
      entity_id: 11
    }, {
      sid: 'vocabulary_cities',
      path: 'vocabulary',
      id: 31,
      entity_id: 4
    }, {
      sid: 'vocabulary_name_labels',
      path: 'vocabulary',
      id: 32,
      entity_id: 19
    }, {
      sid: 'vocabulary_place_relation_types',
      path: 'vocabulary',
      id: 33,
      entity_id: 34
    }, {
      sid: 'vocabulary_colors',
      path: 'vocabulary',
      id: 34,
      entity_id: 24
    }, {
      sid: 'vocabulary_price_types',
      path: 'vocabulary',
      id: 35,
      entity_id: 28
    }, {
      sid: 'vocabulary_estimate_types',
      path: 'vocabulary',
      id: 36,
      entity_id: 27
    }, {
      sid: 'vocabulary_regions',
      path: 'vocabulary',
      id: 37,
      entity_id: 8
    }, {
      sid: 'vital_date',
      path: 'vocabulary:item',
      id: 38,
      entity_id: 2460
    }, {
      sid: 'activity_date',
      path: 'vocabulary:item',
      id: 39,
      entity_id: 2459
    }, {
      sid: 'creation_date',
      path: 'vocabulary:item',
      id: 40,
      entity_id: 2457
    }, {
      sid: 'existence_date',
      path: 'vocabulary:item',
      id: 41,
      entity_id: 2458
    }, {
      sid: 'name_name',
      path: 'vocabulary:item',
      id: 42,
      entity_id: 1442
    }, {
      sid: 'name_pseudonym',
      path: 'vocabulary:item',
      id: 43,
      entity_id: 1441
    }, {
      sid: 'name_official',
      path: 'vocabulary:item',
      id: 44,
      entity_id: 1440
    }, {
      sid: 'name_birth',
      path: 'vocabulary:item',
      id: 45,
      entity_id: 1439
    }, {
      sid: 'note_biography',
      path: 'vocabulary:item',
      id: 46,
      entity_id: 2470
    }, {
      sid: 'note_description',
      path: 'vocabulary:item',
      id: 47,
      entity_id: 2469
    }, {
      sid: 'note_inscription_text',
      path: 'vocabulary:item',
      id: 48,
      entity_id: 2468
    }, {
      sid: 'note_condition_description',
      path: 'vocabulary:item',
      id: 49,
      entity_id: 2462
    }, {
      sid: 'measurements',
      path: 'vocabulary:item',
      id: 50,
      entity_id: 2467
    }, {
      sid: 'editions',
      path: 'vocabulary:item',
      id: 51,
      entity_id: 2466
    }, {
      sid: 'exhibitions',
      path: 'vocabulary:item',
      id: 52,
      entity_id: 2465
    }, {
      sid: 'literatures',
      path: 'vocabulary:item',
      id: 53,
      entity_id: 2464
    }, {
      sid: 'provenances',
      path: 'vocabulary:item',
      id: 54,
      entity_id: 2463
    }, {
      sid: 'mediums',
      path: 'vocabulary:item',
      id: 55,
      entity_id: 2461
    }, {
      sid: 'person_birth_place',
      path: 'vocabulary:item',
      id: 56,
      entity_id: 2472
    }, {
      sid: 'person_death_place',
      path: 'vocabulary:item',
      id: 57,
      entity_id: 2471
    }, {
      sid: 'place_country',
      path: 'vocabulary:item',
      id: 58,
      entity_id: 2474
    }, {
      sid: 'place_region',
      path: 'vocabulary:item',
      id: 59,
      entity_id: 2475
    }, {
      sid: 'place_city',
      path: 'vocabulary:item',
      id: 60,
      entity_id: 2476
    }, {
      sid: 'place_continent',
      path: 'vocabulary:item',
      id: 61,
      entity_id: 2473
    }, {
      sid: 'price_type_hammer',
      path: 'vocabulary:item',
      id: 62,
      entity_id: 2291
    }, {
      sid: 'price_type_premium',
      path: 'vocabulary:item',
      id: 63,
      entity_id: 2290
    }, {
      sid: 'role_member',
      path: 'vocabulary:item',
      id: 64,
      entity_id: 1445
    }, {
      sid: 'role_author',
      path: 'vocabulary:item',
      id: 65,
      entity_id: 1455
    }, {
      sid: 'role_editor',
      path: 'vocabulary:item',
      id: 66,
      entity_id: 1454
    }, {
      sid: 'role_publisher',
      path: 'vocabulary:item',
      id: 67,
      entity_id: 1453
    }, {
      sid: 'role_auction_house',
      path: 'vocabulary:item',
      id: 68,
      entity_id: 1443
    }, {
      sid: 'sale_channel_online',
      path: 'vocabulary:item',
      id: 69,
      entity_id: 2480
    }, {
      sid: 'sale_channel_live',
      path: 'vocabulary:item',
      id: 70,
      entity_id: 2479
    }, {
      sid: 'sale_channel_online_live',
      path: 'vocabulary:item',
      id: 71,
      entity_id: 2478
    }, {
      sid: 'source_related_publications',
      path: 'vocabulary:item',
      id: 72,
      entity_id: 2477
    }, {
      sid: 'vocabulary_clusters',
      path: 'vocabulary',
      id: 684,
      entity_id: 498
    }]
  };

  var vocabulary_items = exports.vocabulary_items = {
    vocabulary_items: [{
      vocabulary_item_term_ids: [5],
      vocabulary_id: 2,
      parent_id: null,
      id: 5,
      _second_name: null,
      _name: 'Watches'
    }, {
      vocabulary_item_term_ids: [6],
      vocabulary_id: 2,
      parent_id: null,
      id: 6,
      _second_name: null,
      _name: 'War Memorabilia'
    }, {
      vocabulary_item_term_ids: [7],
      vocabulary_id: 2,
      parent_id: null,
      id: 7,
      _second_name: null,
      _name: 'Popular Culture'
    }, {
      vocabulary_item_term_ids: [8],
      vocabulary_id: 2,
      parent_id: null,
      id: 8,
      _second_name: null,
      _name: 'Tribal Art'
    }, {
      vocabulary_item_term_ids: [9],
      vocabulary_id: 2,
      parent_id: null,
      id: 9,
      _second_name: null,
      _name: 'Native American'
    }, {
      vocabulary_item_term_ids: [10],
      vocabulary_id: 2,
      parent_id: null,
      id: 10,
      _second_name: null,
      _name: 'Topographical Art'
    }, {
      vocabulary_item_term_ids: [11],
      vocabulary_id: 2,
      parent_id: null,
      id: 11,
      _second_name: null,
      _name: 'Swiss Art'
    }, {
      vocabulary_item_term_ids: [12],
      vocabulary_id: 2,
      parent_id: null,
      id: 12,
      _second_name: null,
      _name: 'Stamps'
    }, {
      vocabulary_item_term_ids: [13],
      vocabulary_id: 2,
      parent_id: null,
      id: 13,
      _second_name: null,
      _name: 'Maritime Pictures'
    }, {
      vocabulary_item_term_ids: [14],
      vocabulary_id: 2,
      parent_id: null,
      id: 14,
      _second_name: null,
      _name: 'European Objects'
    }, {
      vocabulary_item_term_ids: [15],
      vocabulary_id: 2,
      parent_id: null,
      id: 15,
      _second_name: null,
      _name: 'Travel, Science & Natural Hist'
    }, {
      vocabulary_item_term_ids: [16],
      vocabulary_id: 2,
      parent_id: null,
      id: 16,
      _second_name: null,
      _name: 'Sculpture'
    }, {
      vocabulary_item_term_ids: [17],
      vocabulary_id: 2,
      parent_id: null,
      id: 17,
      _second_name: null,
      _name: 'Rugs & Carpets'
    }, {
      vocabulary_item_term_ids: [18],
      vocabulary_id: 2,
      parent_id: null,
      id: 18,
      _second_name: null,
      _name: 'Collectibles'
    }, {
      vocabulary_item_term_ids: [19],
      vocabulary_id: 2,
      parent_id: null,
      id: 19,
      _second_name: null,
      _name: 'Asian Contemporary Art'
    }, {
      vocabulary_item_term_ids: [20],
      vocabulary_id: 2,
      parent_id: null,
      id: 20,
      _second_name: null,
      _name: 'Arms & Armour'
    }, {
      vocabulary_item_term_ids: [21],
      vocabulary_id: 2,
      parent_id: null,
      id: 21,
      _second_name: null,
      _name: 'Cars - Automobilia'
    }, {
      vocabulary_item_term_ids: [22],
      vocabulary_id: 2,
      parent_id: null,
      id: 22,
      _second_name: null,
      _name: '19th C. Paintings'
    }, {
      vocabulary_item_term_ids: [23],
      vocabulary_id: 2,
      parent_id: null,
      id: 23,
      _second_name: null,
      _name: 'Indian Contemporary Art'
    }, {
      vocabulary_item_term_ids: [24],
      vocabulary_id: 2,
      parent_id: null,
      id: 24,
      _second_name: null,
      _name: 'American Furniture & Dec Arts'
    }, {
      vocabulary_item_term_ids: [25],
      vocabulary_id: 2,
      parent_id: null,
      id: 25,
      _second_name: null,
      _name: '20th Century Decorative Arts'
    }, {
      vocabulary_item_term_ids: [26],
      vocabulary_id: 2,
      parent_id: null,
      id: 26,
      _second_name: null,
      _name: '19th C. Furniture'
    }, {
      vocabulary_item_term_ids: [27],
      vocabulary_id: 2,
      parent_id: null,
      id: 27,
      _second_name: null,
      _name: 'Musical Instruments'
    }, {
      vocabulary_item_term_ids: [28],
      vocabulary_id: 2,
      parent_id: null,
      id: 28,
      _second_name: null,
      _name: 'Impressionist & Modern Art'
    }, {
      vocabulary_item_term_ids: [29],
      vocabulary_id: 2,
      parent_id: null,
      id: 29,
      _second_name: null,
      _name: 'American Paintings'
    }, {
      vocabulary_item_term_ids: [30],
      vocabulary_id: 2,
      parent_id: null,
      id: 30,
      _second_name: null,
      _name: 'Posters'
    }, {
      vocabulary_item_term_ids: [31],
      vocabulary_id: 2,
      parent_id: null,
      id: 31,
      _second_name: null,
      _name: 'Old Master & British Pictures'
    }, {
      vocabulary_item_term_ids: [32],
      vocabulary_id: 2,
      parent_id: null,
      id: 32,
      _second_name: null,
      _name: 'Motorcycles'
    }, {
      vocabulary_item_term_ids: [33],
      vocabulary_id: 2,
      parent_id: null,
      id: 33,
      _second_name: null,
      _name: 'Textiles'
    }, {
      vocabulary_item_term_ids: [34],
      vocabulary_id: 2,
      parent_id: null,
      id: 34,
      _second_name: null,
      _name: 'Dolls Toys Trains'
    }, {
      vocabulary_item_term_ids: [35],
      vocabulary_id: 2,
      parent_id: null,
      id: 35,
      _second_name: null,
      _name: 'Contemporary Middle Eastern'
    }, {
      vocabulary_item_term_ids: [36],
      vocabulary_id: 2,
      parent_id: null,
      id: 36,
      _second_name: null,
      _name: 'Cars'
    }, {
      vocabulary_item_term_ids: [37],
      vocabulary_id: 2,
      parent_id: null,
      id: 37,
      _second_name: null,
      _name: 'Scientific Instruments'
    }, {
      vocabulary_item_term_ids: [38],
      vocabulary_id: 2,
      parent_id: null,
      id: 38,
      _second_name: null,
      _name: 'Russian Paintings'
    }, {
      vocabulary_item_term_ids: [39],
      vocabulary_id: 2,
      parent_id: null,
      id: 39,
      _second_name: null,
      _name: 'Antiquities'
    }, {
      vocabulary_item_term_ids: [40],
      vocabulary_id: 2,
      parent_id: null,
      id: 40,
      _second_name: null,
      _name: 'Old Master Drawings'
    }, {
      vocabulary_item_term_ids: [41],
      vocabulary_id: 2,
      parent_id: null,
      id: 41,
      _second_name: null,
      _name: 'Japanese & Korean Art'
    }, {
      vocabulary_item_term_ids: [42],
      vocabulary_id: 2,
      parent_id: null,
      id: 42,
      _second_name: null,
      _name: 'English Furniture'
    }, {
      vocabulary_item_term_ids: [43],
      vocabulary_id: 2,
      parent_id: null,
      id: 43,
      _second_name: null,
      _name: 'Victorian Pictures'
    }, {
      vocabulary_item_term_ids: [44],
      vocabulary_id: 2,
      parent_id: null,
      id: 44,
      _second_name: null,
      _name: 'Sporting Art'
    }, {
      vocabulary_item_term_ids: [45],
      vocabulary_id: 2,
      parent_id: null,
      id: 45,
      _second_name: null,
      _name: 'Southeast Asian Art'
    }, {
      vocabulary_item_term_ids: [46],
      vocabulary_id: 2,
      parent_id: null,
      id: 46,
      _second_name: null,
      _name: 'Modern Sporting Guns'
    }, {
      vocabulary_item_term_ids: [47],
      vocabulary_id: 2,
      parent_id: null,
      id: 47,
      _second_name: null,
      _name: 'Furniture & Objects'
    }, {
      vocabulary_item_term_ids: [48],
      vocabulary_id: 2,
      parent_id: null,
      id: 48,
      _second_name: null,
      _name: 'Coins'
    }, {
      vocabulary_item_term_ids: [49],
      vocabulary_id: 2,
      parent_id: null,
      id: 49,
      _second_name: null,
      _name: 'Prints'
    }, {
      vocabulary_item_term_ids: [50],
      vocabulary_id: 2,
      parent_id: null,
      id: 50,
      _second_name: null,
      _name: 'Jewellery'
    }, {
      vocabulary_item_term_ids: [51],
      vocabulary_id: 2,
      parent_id: null,
      id: 51,
      _second_name: null,
      _name: 'Interiors'
    }, {
      vocabulary_item_term_ids: [52],
      vocabulary_id: 2,
      parent_id: null,
      id: 52,
      _second_name: null,
      _name: 'Russian Art'
    }, {
      vocabulary_item_term_ids: [53],
      vocabulary_id: 2,
      parent_id: null,
      id: 53,
      _second_name: null,
      _name: 'British Drawings & Watercolors'
    }, {
      vocabulary_item_term_ids: [54],
      vocabulary_id: 2,
      parent_id: null,
      id: 54,
      _second_name: null,
      _name: 'Silver'
    }, {
      vocabulary_item_term_ids: [55],
      vocabulary_id: 2,
      parent_id: null,
      id: 55,
      _second_name: null,
      _name: 'Porcelain & Glass'
    }, {
      vocabulary_item_term_ids: [56],
      vocabulary_id: 2,
      parent_id: null,
      id: 56,
      _second_name: null,
      _name: 'Photographs'
    }, {
      vocabulary_item_term_ids: [57],
      vocabulary_id: 2,
      parent_id: null,
      id: 57,
      _second_name: null,
      _name: 'Pre-Columbian Art'
    }, {
      vocabulary_item_term_ids: [58],
      vocabulary_id: 2,
      parent_id: null,
      id: 58,
      _second_name: null,
      _name: 'Clocks'
    }, {
      vocabulary_item_term_ids: [59],
      vocabulary_id: 2,
      parent_id: null,
      id: 59,
      _second_name: null,
      _name: 'Latin American Paintings'
    }, {
      vocabulary_item_term_ids: [60],
      vocabulary_id: 2,
      parent_id: null,
      id: 60,
      _second_name: null,
      _name: 'Chinese Works of Art'
    }, {
      vocabulary_item_term_ids: [61],
      vocabulary_id: 2,
      parent_id: null,
      id: 61,
      _second_name: null,
      _name: 'Islamic Art'
    }, {
      vocabulary_item_term_ids: [62],
      vocabulary_id: 2,
      parent_id: null,
      id: 62,
      _second_name: null,
      _name: 'Chinese Paintings'
    }, {
      vocabulary_item_term_ids: [63],
      vocabulary_id: 2,
      parent_id: null,
      id: 63,
      _second_name: null,
      _name: 'Italian PW'
    }, {
      vocabulary_item_term_ids: [64],
      vocabulary_id: 2,
      parent_id: null,
      id: 64,
      _second_name: null,
      _name: 'Handbags'
    }, {
      vocabulary_item_term_ids: [65],
      vocabulary_id: 2,
      parent_id: null,
      id: 65,
      _second_name: null,
      _name: 'House Sales'
    }, {
      vocabulary_item_term_ids: [66],
      vocabulary_id: 2,
      parent_id: null,
      id: 66,
      _second_name: null,
      _name: 'PWCA'
    }, {
      vocabulary_item_term_ids: [67],
      vocabulary_id: 2,
      parent_id: null,
      id: 67,
      _second_name: null,
      _name: 'Gold Boxes'
    }, {
      vocabulary_item_term_ids: [68],
      vocabulary_id: 2,
      parent_id: null,
      id: 68,
      _second_name: null,
      _name: 'Australian Paintings'
    }, {
      vocabulary_item_term_ids: [69],
      vocabulary_id: 2,
      parent_id: null,
      id: 69,
      _second_name: null,
      _name: '20th Century British Art'
    }, {
      vocabulary_item_term_ids: [70],
      vocabulary_id: 2,
      parent_id: null,
      id: 70,
      _second_name: null,
      _name: 'Portrait Miniatures'
    }, {
      vocabulary_item_term_ids: [71],
      vocabulary_id: 2,
      parent_id: null,
      id: 71,
      _second_name: null,
      _name: "Non Christie's Categories"
    }, {
      vocabulary_item_term_ids: [72],
      vocabulary_id: 2,
      parent_id: null,
      id: 72,
      _second_name: null,
      _name: 'Mechanical Music'
    }, {
      vocabulary_item_term_ids: [73],
      vocabulary_id: 2,
      parent_id: null,
      id: 73,
      _second_name: null,
      _name: 'European Furniture'
    }, {
      vocabulary_item_term_ids: [74],
      vocabulary_id: 2,
      parent_id: null,
      id: 74,
      _second_name: null,
      _name: 'Couture'
    }, {
      vocabulary_item_term_ids: [75],
      vocabulary_id: 2,
      parent_id: null,
      id: 75,
      _second_name: null,
      _name: 'Contemporary SEA'
    }, {
      vocabulary_item_term_ids: [76],
      vocabulary_id: 2,
      parent_id: null,
      id: 76,
      _second_name: null,
      _name: 'Books & Manuscripts - Comics'
    }, {
      vocabulary_item_term_ids: [77],
      vocabulary_id: 2,
      parent_id: null,
      id: 77,
      _second_name: null,
      _name: 'Wine'
    }, {
      vocabulary_item_term_ids: [78],
      vocabulary_id: 2,
      parent_id: null,
      id: 78,
      _second_name: null,
      _name: 'Cameras'
    }, {
      vocabulary_item_term_ids: [79],
      vocabulary_id: 2,
      parent_id: null,
      id: 79,
      _second_name: null,
      _name: 'British & Irish Art'
    }, {
      vocabulary_item_term_ids: [80],
      vocabulary_id: 2,
      parent_id: null,
      id: 80,
      _second_name: null,
      _name: 'Books & Manuscripts'
    }, {
      vocabulary_item_term_ids: [81],
      vocabulary_id: 3,
      parent_id: null,
      id: 81,
      _second_name: null,
      _name: 'PWCA'
    }, {
      vocabulary_item_term_ids: [82],
      vocabulary_id: 3,
      parent_id: null,
      id: 82,
      _second_name: null,
      _name: "Non Christie's Categories"
    }, {
      vocabulary_item_term_ids: [83],
      vocabulary_id: 3,
      parent_id: null,
      id: 83,
      _second_name: null,
      _name: 'World Art'
    }, {
      vocabulary_item_term_ids: [84],
      vocabulary_id: 3,
      parent_id: null,
      id: 84,
      _second_name: null,
      _name: 'Science & Books'
    }, {
      vocabulary_item_term_ids: [85],
      vocabulary_id: 3,
      parent_id: null,
      id: 85,
      _second_name: null,
      _name: 'OMNC & Russian'
    }, {
      vocabulary_item_term_ids: [86],
      vocabulary_id: 3,
      parent_id: null,
      id: 86,
      _second_name: null,
      _name: 'Luxury'
    }, {
      vocabulary_item_term_ids: [87],
      vocabulary_id: 3,
      parent_id: null,
      id: 87,
      _second_name: null,
      _name: 'Asian Art'
    }, {
      vocabulary_item_term_ids: [88],
      vocabulary_id: 3,
      parent_id: null,
      id: 88,
      _second_name: null,
      _name: 'Imp Mod'
    }, {
      vocabulary_item_term_ids: [89],
      vocabulary_id: 3,
      parent_id: null,
      id: 89,
      _second_name: null,
      _name: 'Decorative Arts & American Furniture'
    }, {
      vocabulary_item_term_ids: [90],
      vocabulary_id: 3,
      parent_id: null,
      id: 90,
      _second_name: null,
      _name: '20/21'
    }, {
      vocabulary_item_term_ids: [876, 875],
      vocabulary_id: 11,
      parent_id: null,
      id: 568,
      _second_name: 'Zaire',
      _name: 'ZRZ'
    }, {
      vocabulary_item_term_ids: [916, 915],
      vocabulary_id: 11,
      parent_id: null,
      id: 588,
      _second_name: 'NIC',
      _name: 'Cordoba'
    }, {
      vocabulary_item_term_ids: [956, 955],
      vocabulary_id: 11,
      parent_id: null,
      id: 608,
      _second_name: 'Kwanza',
      _name: 'AOK'
    }, {
      vocabulary_item_term_ids: [996, 995],
      vocabulary_id: 11,
      parent_id: null,
      id: 628,
      _second_name: 'Moroccan Dirham',
      _name: 'MAD'
    }, {
      vocabulary_item_term_ids: [1036, 1035],
      vocabulary_id: 11,
      parent_id: null,
      id: 648,
      _second_name: 'Ethiopian Birr',
      _name: 'ETB'
    }, {
      vocabulary_item_term_ids: [1076, 1075],
      vocabulary_id: 11,
      parent_id: null,
      id: 668,
      _second_name: 'Colombian Peso',
      _name: 'COP'
    }, {
      vocabulary_item_term_ids: [1116, 1115],
      vocabulary_id: 11,
      parent_id: null,
      id: 688,
      _second_name: 'Argentine Peso',
      _name: 'ARS'
    }, {
      vocabulary_item_term_ids: [1156, 1155],
      vocabulary_id: 11,
      parent_id: null,
      id: 708,
      _second_name: 'Kenyan Shilling',
      _name: 'KES'
    }, {
      vocabulary_item_term_ids: [1196, 1195],
      vocabulary_id: 11,
      parent_id: null,
      id: 728,
      _second_name: 'Uruguayan Peso',
      _name: 'UYP'
    }, {
      vocabulary_item_term_ids: [1236, 1235],
      vocabulary_id: 11,
      parent_id: null,
      id: 748,
      _second_name: 'Uzbekistan Sum',
      _name: 'UZS'
    }, {
      vocabulary_item_term_ids: [1287, 1286],
      vocabulary_id: 11,
      parent_id: null,
      id: 773,
      _second_name: 'Gibraltar Pound',
      _name: 'GIP'
    }, {
      vocabulary_item_term_ids: [1327, 1326],
      vocabulary_id: 11,
      parent_id: null,
      id: 793,
      _second_name: 'Luxembourg Franc',
      _name: 'LUF'
    }, {
      vocabulary_item_term_ids: [1367, 1366],
      vocabulary_id: 11,
      parent_id: null,
      id: 813,
      _second_name: 'Kuwaiti Dinar',
      _name: 'KWD'
    }, {
      vocabulary_item_term_ids: [1406, 1407],
      vocabulary_id: 11,
      parent_id: null,
      id: 833,
      _second_name: 'WIR Euro',
      _name: 'CHE'
    }, {
      vocabulary_item_term_ids: [1433],
      vocabulary_id: 12,
      parent_id: null,
      id: 848,
      _second_name: null,
      _name: 'time'
    }, {
      vocabulary_item_term_ids: [1437],
      vocabulary_id: 12,
      parent_id: null,
      id: 852,
      _second_name: null,
      _name: 'refractive index'
    }, {
      vocabulary_item_term_ids: [1456],
      vocabulary_id: 14,
      parent_id: null,
      id: 871,
      _second_name: null,
      _name: 'Prices approved'
    }, {
      vocabulary_item_term_ids: [1460],
      vocabulary_id: 14,
      parent_id: null,
      id: 875,
      _second_name: null,
      _name: 'Catalog info needs review'
    }, {
      vocabulary_item_term_ids: [1510],
      vocabulary_id: 17,
      parent_id: null,
      id: 914,
      _second_name: null,
      _name: 'Timorese'
    }, {
      vocabulary_item_term_ids: [1514],
      vocabulary_id: 17,
      parent_id: null,
      id: 918,
      _second_name: null,
      _name: 'Syrian'
    }, {
      vocabulary_item_term_ids: [1518],
      vocabulary_id: 17,
      parent_id: null,
      id: 922,
      _second_name: null,
      _name: 'Svalbard'
    }, {
      vocabulary_item_term_ids: [1522],
      vocabulary_id: 17,
      parent_id: null,
      id: 926,
      _second_name: null,
      _name: 'Spanish'
    }, {
      vocabulary_item_term_ids: [1572],
      vocabulary_id: 17,
      parent_id: null,
      id: 966,
      _second_name: null,
      _name: 'Paraguayan'
    }, {
      vocabulary_item_term_ids: [1595],
      vocabulary_id: 17,
      parent_id: null,
      id: 984,
      _second_name: null,
      _name: 'Nauruan'
    }, {
      vocabulary_item_term_ids: [1599],
      vocabulary_id: 17,
      parent_id: null,
      id: 988,
      _second_name: null,
      _name: 'Moroccan'
    }, {
      vocabulary_item_term_ids: [1603, 1604],
      vocabulary_id: 17,
      parent_id: null,
      id: 992,
      _second_name: 'Monacan',
      _name: 'Monégasque'
    }, {
      vocabulary_item_term_ids: [1661],
      vocabulary_id: 17,
      parent_id: null,
      id: 1040,
      _second_name: null,
      _name: 'Icelandic'
    }, {
      vocabulary_item_term_ids: [1878],
      vocabulary_id: 17,
      parent_id: null,
      id: 1236,
      _second_name: null,
      _name: 'Euboean'
    }, {
      vocabulary_item_term_ids: [1882],
      vocabulary_id: 17,
      parent_id: null,
      id: 1240,
      _second_name: null,
      _name: 'Epidaurian'
    }, {
      vocabulary_item_term_ids: [1886, 1887],
      vocabulary_id: 17,
      parent_id: null,
      id: 1244,
      _second_name: 'Eleusinian',
      _name: 'Eleusian'
    }, {
      vocabulary_item_term_ids: [1925],
      vocabulary_id: 17,
      parent_id: null,
      id: 1277,
      _second_name: null,
      _name: 'Athenian'
    }, {
      vocabulary_item_term_ids: [1929],
      vocabulary_id: 17,
      parent_id: null,
      id: 1281,
      _second_name: null,
      _name: 'Aquitanian'
    }, {
      vocabulary_item_term_ids: [1933],
      vocabulary_id: 17,
      parent_id: null,
      id: 1285,
      _second_name: null,
      _name: 'Aethaean'
    }, {
      vocabulary_item_term_ids: [2954],
      vocabulary_id: 25,
      parent_id: null,
      id: 2266,
      _second_name: null,
      _name: 'official website'
    }, {
      vocabulary_item_term_ids: [2958],
      vocabulary_id: 25,
      parent_id: null,
      id: 2270,
      _second_name: null,
      _name: 'private'
    }, {
      vocabulary_item_term_ids: [3076],
      vocabulary_id: 29,
      parent_id: null,
      id: 2388,
      _second_name: null,
      _name: 'Accountancy'
    }, {
      vocabulary_item_term_ids: [3080],
      vocabulary_id: 29,
      parent_id: null,
      id: 2392,
      _second_name: null,
      _name: 'Tourism Studies'
    }, {
      vocabulary_item_term_ids: [3084],
      vocabulary_id: 29,
      parent_id: null,
      id: 2396,
      _second_name: null,
      _name: 'Science in Law'
    }, {
      vocabulary_item_term_ids: [3088],
      vocabulary_id: 29,
      parent_id: null,
      id: 2400,
      _second_name: null,
      _name: 'Mortuary Science'
    }, {
      vocabulary_item_term_ids: [3092],
      vocabulary_id: 29,
      parent_id: null,
      id: 2404,
      _second_name: null,
      _name: 'Library Science'
    }, {
      vocabulary_item_term_ids: [3096],
      vocabulary_id: 29,
      parent_id: null,
      id: 2408,
      _second_name: null,
      _name: 'General Studies '
    }, {
      vocabulary_item_term_ids: [3100],
      vocabulary_id: 29,
      parent_id: null,
      id: 2412,
      _second_name: null,
      _name: 'Religious Studies'
    }, {
      vocabulary_item_term_ids: [3104],
      vocabulary_id: 29,
      parent_id: null,
      id: 2416,
      _second_name: null,
      _name: 'Medical Biology'
    }, {
      vocabulary_item_term_ids: [3108],
      vocabulary_id: 29,
      parent_id: null,
      id: 2420,
      _second_name: null,
      _name: 'Computer Science'
    }, {
      vocabulary_item_term_ids: [3112],
      vocabulary_id: 29,
      parent_id: null,
      id: 2424,
      _second_name: null,
      _name: 'Management and Organizational Studies '
    }, {
      vocabulary_item_term_ids: [3139],
      vocabulary_id: 31,
      parent_id: null,
      id: 2451,
      _second_name: null,
      _name: 'director'
    }, {
      vocabulary_item_term_ids: [3143],
      vocabulary_id: 31,
      parent_id: null,
      id: 2455,
      _second_name: null,
      _name: 'manufacturer'
    }, {
      vocabulary_item_term_ids: [2065],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1393,
      _second_name: null,
      _name: 'Yukon'
    }, {
      vocabulary_item_term_ids: [2057, 2058],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1386,
      _second_name: 'North Rhine',
      _name: 'Westphalian'
    }, {
      vocabulary_item_term_ids: [2053],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1382,
      _second_name: null,
      _name: 'Saxon'
    }, {
      vocabulary_item_term_ids: [2026],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1362,
      _second_name: null,
      _name: 'Keralite'
    }, {
      vocabulary_item_term_ids: [2022],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1358,
      _second_name: null,
      _name: 'Manipuri'
    }, {
      vocabulary_item_term_ids: [1996],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1342,
      _second_name: null,
      _name: 'Kedahan'
    }, {
      vocabulary_item_term_ids: [1992],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1338,
      _second_name: null,
      _name: 'Malaccan'
    }, {
      vocabulary_item_term_ids: [1988],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1334,
      _second_name: null,
      _name: 'Perakian'
    }, {
      vocabulary_item_term_ids: [1984],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1330,
      _second_name: null,
      _name: 'Sarawakian'
    }, {
      vocabulary_item_term_ids: [1937],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1289,
      _second_name: null,
      _name: 'Chatham'
    }, {
      vocabulary_item_term_ids: [727, 726],
      vocabulary_id: 9,
      parent_id: null,
      id: 437,
      _second_name: 'tonne',
      _name: 't'
    }, {
      vocabulary_item_term_ids: [878, 877],
      vocabulary_id: 11,
      parent_id: null,
      id: 569,
      _second_name: 'Zambian Kwacha',
      _name: 'ZMK'
    }, {
      vocabulary_item_term_ids: [918, 917],
      vocabulary_id: 11,
      parent_id: null,
      id: 589,
      _second_name: 'Belarussian Ruble',
      _name: 'BYB'
    }, {
      vocabulary_item_term_ids: [958, 957],
      vocabulary_id: 11,
      parent_id: null,
      id: 609,
      _second_name: 'Luxembourg Convertible Franc',
      _name: 'LUC'
    }, {
      vocabulary_item_term_ids: [998, 997],
      vocabulary_id: 11,
      parent_id: null,
      id: 629,
      _second_name: 'Kwanza Reajustado',
      _name: 'AOR'
    }, {
      vocabulary_item_term_ids: [1038, 1037],
      vocabulary_id: 11,
      parent_id: null,
      id: 649,
      _second_name: 'Rhodesian Dollar',
      _name: 'RHD'
    }, {
      vocabulary_item_term_ids: [1078, 1077],
      vocabulary_id: 11,
      parent_id: null,
      id: 669,
      _second_name: 'Czech Koruna',
      _name: 'CZK'
    }, {
      vocabulary_item_term_ids: [1118, 1117],
      vocabulary_id: 11,
      parent_id: null,
      id: 689,
      _second_name: 'ROK',
      _name: 'Leu A/52'
    }, {
      vocabulary_item_term_ids: [1158, 1157],
      vocabulary_id: 11,
      parent_id: null,
      id: 709,
      _second_name: 'Maloti',
      _name: 'LSM'
    }, {
      vocabulary_item_term_ids: [1198, 1197],
      vocabulary_id: 11,
      parent_id: null,
      id: 729,
      _second_name: 'UGW',
      _name: 'Old Shilling'
    }, {
      vocabulary_item_term_ids: [1238, 1237],
      vocabulary_id: 11,
      parent_id: null,
      id: 749,
      _second_name: 'Vatu',
      _name: 'VUV'
    }, {
      vocabulary_item_term_ids: [1289, 1288],
      vocabulary_id: 11,
      parent_id: null,
      id: 774,
      _second_name: 'Belize Dollar',
      _name: 'BZD'
    }, {
      vocabulary_item_term_ids: [1329, 1328],
      vocabulary_id: 11,
      parent_id: null,
      id: 794,
      _second_name: 'GHC',
      _name: 'Cedi'
    }, {
      vocabulary_item_term_ids: [1369, 1368],
      vocabulary_id: 11,
      parent_id: null,
      id: 814,
      _second_name: 'Tugrik',
      _name: 'MNT'
    }, {
      vocabulary_item_term_ids: [1409, 1408],
      vocabulary_id: 11,
      parent_id: null,
      id: 834,
      _second_name: 'Georgian Coupon',
      _name: 'GEK'
    }, {
      vocabulary_item_term_ids: [1482],
      vocabulary_id: 17,
      parent_id: null,
      id: 895,
      _second_name: null,
      _name: 'Venezuelan'
    }, {
      vocabulary_item_term_ids: [1528],
      vocabulary_id: 17,
      parent_id: null,
      id: 931,
      _second_name: null,
      _name: 'Somali'
    }, {
      vocabulary_item_term_ids: [1547],
      vocabulary_id: 17,
      parent_id: null,
      id: 945,
      _second_name: null,
      _name: 'Sammarinese'
    }, {
      vocabulary_item_term_ids: [1578],
      vocabulary_id: 17,
      parent_id: null,
      id: 971,
      _second_name: null,
      _name: 'Pakistani'
    }, {
      vocabulary_item_term_ids: [1586],
      vocabulary_id: 17,
      parent_id: null,
      id: 979,
      _second_name: null,
      _name: 'Nigerien'
    }, {
      vocabulary_item_term_ids: [1605],
      vocabulary_id: 17,
      parent_id: null,
      id: 993,
      _second_name: null,
      _name: 'Moldovan'
    }, {
      vocabulary_item_term_ids: [1609],
      vocabulary_id: 17,
      parent_id: null,
      id: 997,
      _second_name: null,
      _name: 'Mauritian'
    }, {
      vocabulary_item_term_ids: [1750],
      vocabulary_id: 17,
      parent_id: null,
      id: 1119,
      _second_name: null,
      _name: 'Barbadian'
    }, {
      vocabulary_item_term_ids: [1755, 1754],
      vocabulary_id: 17,
      parent_id: null,
      id: 1123,
      _second_name: 'Azeri',
      _name: 'Azerbaijani'
    }, {
      vocabulary_item_term_ids: [1810, 1809],
      vocabulary_id: 17,
      parent_id: null,
      id: 1172,
      _second_name: 'Sardian',
      _name: 'Sardianus'
    }, {
      vocabulary_item_term_ids: [1888],
      vocabulary_id: 17,
      parent_id: null,
      id: 1245,
      _second_name: null,
      _name: 'Egestan'
    }, {
      vocabulary_item_term_ids: [1892],
      vocabulary_id: 17,
      parent_id: null,
      id: 1249,
      _second_name: null,
      _name: 'Dalmatian'
    }, {
      vocabulary_item_term_ids: [1900],
      vocabulary_id: 17,
      parent_id: null,
      id: 1256,
      _second_name: null,
      _name: 'Corsican'
    }, {
      vocabulary_item_term_ids: [1904],
      vocabulary_id: 17,
      parent_id: null,
      id: 1260,
      _second_name: null,
      _name: 'Colchian'
    }, {
      vocabulary_item_term_ids: [1909, 1908],
      vocabulary_id: 17,
      parent_id: null,
      id: 1264,
      _second_name: 'Catalonian',
      _name: 'Catalan'
    }, {
      vocabulary_item_term_ids: [2095],
      vocabulary_id: 17,
      parent_id: null,
      id: 1420,
      _second_name: null,
      _name: 'Middle Eastern'
    }, {
      vocabulary_item_term_ids: [2309],
      vocabulary_id: 21,
      parent_id: null,
      id: 1634,
      _second_name: null,
      _name: 'dissertation'
    }, {
      vocabulary_item_term_ids: [2313],
      vocabulary_id: 21,
      parent_id: null,
      id: 1638,
      _second_name: null,
      _name: 'condition report'
    }, {
      vocabulary_item_term_ids: [2317],
      vocabulary_id: 21,
      parent_id: null,
      id: 1642,
      _second_name: null,
      _name: 'birth certificate'
    }, {
      vocabulary_item_term_ids: [2321],
      vocabulary_id: 21,
      parent_id: null,
      id: 1646,
      _second_name: null,
      _name: 'dictionary'
    }, {
      vocabulary_item_term_ids: [2975],
      vocabulary_id: 27,
      parent_id: null,
      id: 2287,
      _second_name: null,
      _name: 'Estimate on request'
    }, {
      vocabulary_item_term_ids: [3026],
      vocabulary_id: 29,
      parent_id: null,
      id: 2338,
      _second_name: null,
      _name: 'Medical Science'
    }, {
      vocabulary_item_term_ids: [3030],
      vocabulary_id: 29,
      parent_id: null,
      id: 2342,
      _second_name: null,
      _name: 'Library and Information Science'
    }, {
      vocabulary_item_term_ids: [3034],
      vocabulary_id: 29,
      parent_id: null,
      id: 2346,
      _second_name: null,
      _name: 'Studies in Law '
    }, {
      vocabulary_item_term_ids: [3038],
      vocabulary_id: 29,
      parent_id: null,
      id: 2350,
      _second_name: null,
      _name: 'Information System Management '
    }, {
      vocabulary_item_term_ids: [3042],
      vocabulary_id: 29,
      parent_id: null,
      id: 2354,
      _second_name: null,
      _name: 'International Affairs'
    }, {
      vocabulary_item_term_ids: [3046],
      vocabulary_id: 29,
      parent_id: null,
      id: 2358,
      _second_name: null,
      _name: 'Health Administration '
    }, {
      vocabulary_item_term_ids: [3050],
      vocabulary_id: 29,
      parent_id: null,
      id: 2362,
      _second_name: null,
      _name: 'Financial Mathematics'
    }, {
      vocabulary_item_term_ids: [3054],
      vocabulary_id: 29,
      parent_id: null,
      id: 2366,
      _second_name: null,
      _name: 'Engineering'
    }, {
      vocabulary_item_term_ids: [3058],
      vocabulary_id: 29,
      parent_id: null,
      id: 2370,
      _second_name: null,
      _name: 'Divinity'
    }, {
      vocabulary_item_term_ids: [3062],
      vocabulary_id: 29,
      parent_id: null,
      id: 2374,
      _second_name: null,
      _name: 'Computer Applications'
    }, {
      vocabulary_item_term_ids: [3066],
      vocabulary_id: 29,
      parent_id: null,
      id: 2378,
      _second_name: null,
      _name: 'City Planning'
    }, {
      vocabulary_item_term_ids: [3070],
      vocabulary_id: 29,
      parent_id: null,
      id: 2382,
      _second_name: null,
      _name: 'Arts in Liberal Studies '
    }, {
      vocabulary_item_term_ids: [3074],
      vocabulary_id: 29,
      parent_id: null,
      id: 2386,
      _second_name: null,
      _name: 'Architecture'
    }, {
      vocabulary_item_term_ids: [2114],
      vocabulary_id: 19,
      parent_id: 1442,
      id: 1439,
      _second_name: null,
      _name: 'birth name'
    }, {
      vocabulary_item_term_ids: [2071],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1398,
      _second_name: null,
      _name: 'Nova Scotian'
    }, {
      vocabulary_item_term_ids: [2063],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1391,
      _second_name: null,
      _name: 'Franconian'
    }, {
      vocabulary_item_term_ids: [2059],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1387,
      _second_name: null,
      _name: 'Lower Saxon'
    }, {
      vocabulary_item_term_ids: [2051],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1380,
      _second_name: null,
      _name: 'Thuringian'
    }, {
      vocabulary_item_term_ids: [2047],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1376,
      _second_name: null,
      _name: 'Arunachal Pradesh'
    }, {
      vocabulary_item_term_ids: [2010, 2009],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1349,
      _second_name: 'Tamilian',
      _name: 'Tamil'
    }, {
      vocabulary_item_term_ids: [1944, 1943],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1294,
      _second_name: 'Wellingtonian',
      _name: 'Wellington'
    }, {
      vocabulary_item_term_ids: [1582],
      vocabulary_id: 17,
      parent_id: 901,
      id: 975,
      _second_name: null,
      _name: 'Northern Irish'
    }, {
      vocabulary_item_term_ids: [1478],
      vocabulary_id: 17,
      parent_id: 901,
      id: 891,
      _second_name: null,
      _name: 'Welsh'
    }, {
      vocabulary_item_term_ids: [655],
      vocabulary_id: 7,
      parent_id: null,
      id: 374,
      _second_name: null,
      _name: 'textiles'
    }, {
      vocabulary_item_term_ids: [659],
      vocabulary_id: 7,
      parent_id: null,
      id: 378,
      _second_name: null,
      _name: 'silver'
    }, {
      vocabulary_item_term_ids: [663],
      vocabulary_id: 7,
      parent_id: null,
      id: 382,
      _second_name: null,
      _name: 'etching'
    }, {
      vocabulary_item_term_ids: [667],
      vocabulary_id: 7,
      parent_id: null,
      id: 386,
      _second_name: null,
      _name: 'rugs and carpets'
    }, {
      vocabulary_item_term_ids: [671],
      vocabulary_id: 7,
      parent_id: null,
      id: 390,
      _second_name: null,
      _name: 'decorative art'
    }, {
      vocabulary_item_term_ids: [675],
      vocabulary_id: 7,
      parent_id: null,
      id: 394,
      _second_name: null,
      _name: 'work on paper'
    }, {
      vocabulary_item_term_ids: [679],
      vocabulary_id: 7,
      parent_id: null,
      id: 398,
      _second_name: null,
      _name: 'video art'
    }, {
      vocabulary_item_term_ids: [683],
      vocabulary_id: 7,
      parent_id: null,
      id: 402,
      _second_name: null,
      _name: 'screen printing'
    }, {
      vocabulary_item_term_ids: [687],
      vocabulary_id: 7,
      parent_id: null,
      id: 406,
      _second_name: null,
      _name: 'book'
    }, {
      vocabulary_item_term_ids: [691],
      vocabulary_id: 7,
      parent_id: null,
      id: 410,
      _second_name: null,
      _name: 'video and multimedia'
    }, {
      vocabulary_item_term_ids: [695],
      vocabulary_id: 7,
      parent_id: null,
      id: 414,
      _second_name: null,
      _name: 'manuscript'
    }, {
      vocabulary_item_term_ids: [729, 728],
      vocabulary_id: 9,
      parent_id: null,
      id: 438,
      _second_name: 'kilogram',
      _name: 'kg'
    }, {
      vocabulary_item_term_ids: [879, 880],
      vocabulary_id: 11,
      parent_id: null,
      id: 570,
      _second_name: 'UIC-Franc',
      _name: 'XFU'
    }, {
      vocabulary_item_term_ids: [920, 919],
      vocabulary_id: 11,
      parent_id: null,
      id: 590,
      _second_name: 'XPF',
      _name: 'CFP Franc'
    }, {
      vocabulary_item_term_ids: [960, 959],
      vocabulary_id: 11,
      parent_id: null,
      id: 610,
      _second_name: 'Syli',
      _name: 'GNS'
    }, {
      vocabulary_item_term_ids: [1000, 999],
      vocabulary_id: 11,
      parent_id: null,
      id: 630,
      _second_name: 'New Kwanza',
      _name: 'AON'
    }, {
      vocabulary_item_term_ids: [1040, 1039],
      vocabulary_id: 11,
      parent_id: null,
      id: 650,
      _second_name: 'Guinea Escudo',
      _name: 'GWE'
    }, {
      vocabulary_item_term_ids: [1080, 1079],
      vocabulary_id: 11,
      parent_id: null,
      id: 670,
      _second_name: 'Hong Kong Dollar',
      _name: 'HKD'
    }, {
      vocabulary_item_term_ids: [1120, 1119],
      vocabulary_id: 11,
      parent_id: null,
      id: 690,
      _second_name: 'Jamaican Dollar',
      _name: 'JMD'
    }, {
      vocabulary_item_term_ids: [1160, 1159],
      vocabulary_id: 11,
      parent_id: null,
      id: 710,
      _second_name: 'Pound Sterling',
      _name: 'GBP'
    }, {
      vocabulary_item_term_ids: [1200, 1199],
      vocabulary_id: 11,
      parent_id: null,
      id: 730,
      _second_name: 'Yen',
      _name: 'JPY'
    }, {
      vocabulary_item_term_ids: [1240, 1239],
      vocabulary_id: 11,
      parent_id: null,
      id: 750,
      _second_name: 'UAH',
      _name: 'Hryvnia'
    }, {
      vocabulary_item_term_ids: [1291, 1290],
      vocabulary_id: 11,
      parent_id: null,
      id: 775,
      _second_name: 'Ngultrum',
      _name: 'BTN'
    }, {
      vocabulary_item_term_ids: [1331, 1330],
      vocabulary_id: 11,
      parent_id: null,
      id: 795,
      _second_name: 'French Franc',
      _name: 'FRF'
    }, {
      vocabulary_item_term_ids: [1371, 1370],
      vocabulary_id: 11,
      parent_id: null,
      id: 815,
      _second_name: 'KYD',
      _name: 'Cayman Islands Dollar'
    }, {
      vocabulary_item_term_ids: [1411, 1410],
      vocabulary_id: 11,
      parent_id: null,
      id: 835,
      _second_name: 'Brunei Dollar',
      _name: 'BND'
    }, {
      vocabulary_item_term_ids: [1451],
      vocabulary_id: 13,
      parent_id: null,
      id: 866,
      _second_name: null,
      _name: 'Not Yet Available'
    }, {
      vocabulary_item_term_ids: [1496, 1497, 1495],
      vocabulary_id: 17,
      parent_id: null,
      id: 902,
      _second_name: 'Emirian',
      _name: 'Emiri'
    }, {
      vocabulary_item_term_ids: [1535, 1534],
      vocabulary_id: 17,
      parent_id: null,
      id: 936,
      _second_name: 'Sint Eustatius',
      _name: 'Statian'
    }, {
      vocabulary_item_term_ids: [1615],
      vocabulary_id: 17,
      parent_id: null,
      id: 1002,
      _second_name: null,
      _name: 'Malian, Malinese'
    }, {
      vocabulary_item_term_ids: [1619],
      vocabulary_id: 17,
      parent_id: null,
      id: 1006,
      _second_name: null,
      _name: 'Malagasy'
    }, {
      vocabulary_item_term_ids: [1639, 1638],
      vocabulary_id: 17,
      parent_id: null,
      id: 1020,
      _second_name: 'Kosovan',
      _name: 'Kosovar'
    }, {
      vocabulary_item_term_ids: [1673],
      vocabulary_id: 17,
      parent_id: null,
      id: 1049,
      _second_name: null,
      _name: 'Guatemalan'
    }, {
      vocabulary_item_term_ids: [1756],
      vocabulary_id: 17,
      parent_id: null,
      id: 1124,
      _second_name: null,
      _name: 'Austrian'
    }, {
      vocabulary_item_term_ids: [1760],
      vocabulary_id: 17,
      parent_id: null,
      id: 1128,
      _second_name: null,
      _name: 'Argentine'
    }, {
      vocabulary_item_term_ids: [1764],
      vocabulary_id: 17,
      parent_id: null,
      id: 1132,
      _second_name: null,
      _name: 'Anguillan'
    }, {
      vocabulary_item_term_ids: [1768],
      vocabulary_id: 17,
      parent_id: null,
      id: 1136,
      _second_name: null,
      _name: 'Algerian'
    }, {
      vocabulary_item_term_ids: [1773, 1772],
      vocabulary_id: 17,
      parent_id: null,
      id: 1140,
      _second_name: 'Abkhazian',
      _name: 'Abkhaz'
    }, {
      vocabulary_item_term_ids: [1812, 1811],
      vocabulary_id: 17,
      parent_id: null,
      id: 1173,
      _second_name: 'Sardi',
      _name: 'Sardinian'
    }, {
      vocabulary_item_term_ids: [1911, 1910],
      vocabulary_id: 17,
      parent_id: null,
      id: 1265,
      _second_name: 'Carystian',
      _name: 'Carthaginian'
    }, {
      vocabulary_item_term_ids: [2093],
      vocabulary_id: 17,
      parent_id: null,
      id: 1418,
      _second_name: null,
      _name: 'Eurasian'
    }, {
      vocabulary_item_term_ids: [2980],
      vocabulary_id: 29,
      parent_id: null,
      id: 2292,
      _second_name: null,
      _name: 'Juris Doctor'
    }, {
      vocabulary_item_term_ids: [2984],
      vocabulary_id: 29,
      parent_id: null,
      id: 2296,
      _second_name: null,
      _name: 'Veterinary Science'
    }, {
      vocabulary_item_term_ids: [2988],
      vocabulary_id: 29,
      parent_id: null,
      id: 2300,
      _second_name: null,
      _name: 'Studies'
    }, {
      vocabulary_item_term_ids: [2992],
      vocabulary_id: 29,
      parent_id: null,
      id: 2304,
      _second_name: null,
      _name: 'Science in Teaching'
    }, {
      vocabulary_item_term_ids: [2996],
      vocabulary_id: 29,
      parent_id: null,
      id: 2308,
      _second_name: null,
      _name: 'Science in Management'
    }, {
      vocabulary_item_term_ids: [3000],
      vocabulary_id: 29,
      parent_id: null,
      id: 2312,
      _second_name: null,
      _name: 'Science in Information Systems'
    }, {
      vocabulary_item_term_ids: [3004],
      vocabulary_id: 29,
      parent_id: null,
      id: 2316,
      _second_name: null,
      _name: 'Science in Engineering '
    }, {
      vocabulary_item_term_ids: [3008],
      vocabulary_id: 29,
      parent_id: null,
      id: 2320,
      _second_name: null,
      _name: 'Sacred Theology'
    }, {
      vocabulary_item_term_ids: [3012],
      vocabulary_id: 29,
      parent_id: null,
      id: 2324,
      _second_name: null,
      _name: 'Rabbinic Studies'
    }, {
      vocabulary_item_term_ids: [3016],
      vocabulary_id: 29,
      parent_id: null,
      id: 2328,
      _second_name: null,
      _name: 'Public Health'
    }, {
      vocabulary_item_term_ids: [3020],
      vocabulary_id: 29,
      parent_id: null,
      id: 2332,
      _second_name: null,
      _name: 'Political Science'
    }, {
      vocabulary_item_term_ids: [3134],
      vocabulary_id: 30,
      parent_id: null,
      id: 2446,
      _second_name: null,
      _name: 'Professional'
    }, {
      vocabulary_item_term_ids: [3138],
      vocabulary_id: 30,
      parent_id: null,
      id: 2450,
      _second_name: null,
      _name: 'Associate'
    }, {
      vocabulary_item_term_ids: [3161],
      vocabulary_id: 35,
      parent_id: null,
      id: 2473,
      _second_name: null,
      _name: 'continent'
    }, {
      vocabulary_item_term_ids: [2077],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1403,
      _second_name: null,
      _name: 'Manitoban'
    }, {
      vocabulary_item_term_ids: [2045],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1374,
      _second_name: null,
      _name: 'Chandigarhi'
    }, {
      vocabulary_item_term_ids: [2034],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1368,
      _second_name: null,
      _name: 'Gujarati'
    }, {
      vocabulary_item_term_ids: [2017, 2015, 2016],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1354,
      _second_name: 'Odia',
      _name: 'Odishan'
    }, {
      vocabulary_item_term_ids: [2011],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1350,
      _second_name: null,
      _name: 'Sikkimese'
    }, {
      vocabulary_item_term_ids: [1953, 1954],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1303,
      _second_name: 'Wanganui',
      _name: 'Manawatu'
    }, {
      vocabulary_item_term_ids: [1949],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1299,
      _second_name: null,
      _name: 'Otago'
    }, {
      vocabulary_item_term_ids: [1945],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1295,
      _second_name: null,
      _name: 'Waikato'
    }, {
      vocabulary_item_term_ids: [731, 730],
      vocabulary_id: 9,
      parent_id: null,
      id: 439,
      _second_name: 'gram',
      _name: 'gr'
    }, {
      vocabulary_item_term_ids: [746],
      vocabulary_id: 10,
      parent_id: null,
      id: 448,
      _second_name: null,
      _name: 'Science and Technology'
    }, {
      vocabulary_item_term_ids: [762],
      vocabulary_id: 10,
      parent_id: null,
      id: 464,
      _second_name: null,
      _name: 'Indigenous Art and Culture'
    }, {
      vocabulary_item_term_ids: [810],
      vocabulary_id: 10,
      parent_id: null,
      id: 512,
      _second_name: null,
      _name: 'Books and Historical Documents'
    }, {
      vocabulary_item_term_ids: [822],
      vocabulary_id: 10,
      parent_id: null,
      id: 524,
      _second_name: null,
      _name: 'Furniture and Decorative Arts'
    }, {
      vocabulary_item_term_ids: [854],
      vocabulary_id: 10,
      parent_id: null,
      id: 556,
      _second_name: null,
      _name: 'Clothes, Costumes, and Accessories'
    }, {
      vocabulary_item_term_ids: [882, 881],
      vocabulary_id: 11,
      parent_id: null,
      id: 571,
      _second_name: 'XEU',
      _name: 'European Currency Unit (E.C.U)'
    }, {
      vocabulary_item_term_ids: [922, 921],
      vocabulary_id: 11,
      parent_id: null,
      id: 591,
      _second_name: 'New Cruzado',
      _name: 'BRN'
    }, {
      vocabulary_item_term_ids: [962, 961],
      vocabulary_id: 11,
      parent_id: null,
      id: 611,
      _second_name: 'Talonas',
      _name: 'LTT'
    }, {
      vocabulary_item_term_ids: [1002, 1001],
      vocabulary_id: 11,
      parent_id: null,
      id: 631,
      _second_name: 'Iranian Rial',
      _name: 'IRR'
    }, {
      vocabulary_item_term_ids: [1042, 1041],
      vocabulary_id: 11,
      parent_id: null,
      id: 651,
      _second_name: 'GRD',
      _name: 'Drachma'
    }, {
      vocabulary_item_term_ids: [1082, 1081],
      vocabulary_id: 11,
      parent_id: null,
      id: 671,
      _second_name: 'YUM',
      _name: 'New Dinar'
    }, {
      vocabulary_item_term_ids: [1122, 1121],
      vocabulary_id: 11,
      parent_id: null,
      id: 691,
      _second_name: 'Lempira',
      _name: 'HNL'
    }, {
      vocabulary_item_term_ids: [1162, 1161],
      vocabulary_id: 11,
      parent_id: null,
      id: 711,
      _second_name: 'Cuban Peso',
      _name: 'CUP'
    }, {
      vocabulary_item_term_ids: [1202, 1201],
      vocabulary_id: 11,
      parent_id: null,
      id: 731,
      _second_name: 'New Israeli Sheqel',
      _name: 'ILS'
    }, {
      vocabulary_item_term_ids: [1242, 1241],
      vocabulary_id: 11,
      parent_id: null,
      id: 751,
      _second_name: 'TOP',
      _name: 'Pa’anga'
    }, {
      vocabulary_item_term_ids: [1293, 1292],
      vocabulary_id: 11,
      parent_id: null,
      id: 776,
      _second_name: 'Mvdol',
      _name: 'BOV'
    }, {
      vocabulary_item_term_ids: [1333, 1332],
      vocabulary_id: 11,
      parent_id: null,
      id: 796,
      _second_name: 'Markka',
      _name: 'FIM'
    }, {
      vocabulary_item_term_ids: [1373, 1372],
      vocabulary_id: 11,
      parent_id: null,
      id: 816,
      _second_name: 'HTG',
      _name: 'Gourde'
    }, {
      vocabulary_item_term_ids: [1413, 1412],
      vocabulary_id: 11,
      parent_id: null,
      id: 836,
      _second_name: 'Cruzado',
      _name: 'BRC'
    }, {
      vocabulary_item_term_ids: [1537, 1536],
      vocabulary_id: 17,
      parent_id: null,
      id: 937,
      _second_name: 'Singaporean',
      _name: 'Singapore'
    }, {
      vocabulary_item_term_ids: [1555],
      vocabulary_id: 17,
      parent_id: null,
      id: 951,
      _second_name: null,
      _name: 'Kittitian or Nevisian'
    }, {
      vocabulary_item_term_ids: [1559],
      vocabulary_id: 17,
      parent_id: null,
      id: 955,
      _second_name: null,
      _name: 'Rwandan'
    }, {
      vocabulary_item_term_ids: [1625],
      vocabulary_id: 17,
      parent_id: null,
      id: 1011,
      _second_name: null,
      _name: 'Liechtenstein'
    }, {
      vocabulary_item_term_ids: [1629],
      vocabulary_id: 17,
      parent_id: null,
      id: 1015,
      _second_name: null,
      _name: 'Lebanese'
    }, {
      vocabulary_item_term_ids: [1640],
      vocabulary_id: 17,
      parent_id: null,
      id: 1021,
      _second_name: null,
      _name: 'South Korean'
    }, {
      vocabulary_item_term_ids: [1645, 1644],
      vocabulary_id: 17,
      parent_id: null,
      id: 1025,
      _second_name: 'Kazakhstani',
      _name: 'Kazakh'
    }, {
      vocabulary_item_term_ids: [1671],
      vocabulary_id: 17,
      parent_id: null,
      id: 1047,
      _second_name: null,
      _name: 'Bissau-Guinean'
    }, {
      vocabulary_item_term_ids: [1680, 1679],
      vocabulary_id: 17,
      parent_id: null,
      id: 1054,
      _second_name: 'Hellenic',
      _name: 'Greek'
    }, {
      vocabulary_item_term_ids: [1716, 1715],
      vocabulary_id: 17,
      parent_id: null,
      id: 1088,
      _second_name: 'Comorian',
      _name: 'Comoran'
    }, {
      vocabulary_item_term_ids: [1731],
      vocabulary_id: 17,
      parent_id: null,
      id: 1103,
      _second_name: null,
      _name: 'Bulgarian'
    }, {
      vocabulary_item_term_ids: [1735],
      vocabulary_id: 17,
      parent_id: null,
      id: 1107,
      _second_name: null,
      _name: 'Bouvet Island'
    }, {
      vocabulary_item_term_ids: [1774],
      vocabulary_id: 17,
      parent_id: null,
      id: 1141,
      _second_name: null,
      _name: 'Zakynthian'
    }, {
      vocabulary_item_term_ids: [1778],
      vocabulary_id: 17,
      parent_id: null,
      id: 1145,
      _second_name: null,
      _name: 'Troezenian'
    }, {
      vocabulary_item_term_ids: [1782],
      vocabulary_id: 17,
      parent_id: null,
      id: 1149,
      _second_name: null,
      _name: 'Thuriat'
    }, {
      vocabulary_item_term_ids: [1786],
      vocabulary_id: 17,
      parent_id: null,
      id: 1153,
      _second_name: null,
      _name: 'Thespian'
    }, {
      vocabulary_item_term_ids: [1794],
      vocabulary_id: 17,
      parent_id: null,
      id: 1160,
      _second_name: null,
      _name: 'Symian'
    }, {
      vocabulary_item_term_ids: [1802],
      vocabulary_id: 17,
      parent_id: null,
      id: 1168,
      _second_name: null,
      _name: 'Sere'
    }, {
      vocabulary_item_term_ids: [1813],
      vocabulary_id: 17,
      parent_id: null,
      id: 1174,
      _second_name: null,
      _name: 'Samian'
    }, {
      vocabulary_item_term_ids: [1817],
      vocabulary_id: 17,
      parent_id: null,
      id: 1178,
      _second_name: null,
      _name: 'Roman'
    }, {
      vocabulary_item_term_ids: [1861],
      vocabulary_id: 17,
      parent_id: null,
      id: 1221,
      _second_name: null,
      _name: 'Lesbian'
    }, {
      vocabulary_item_term_ids: [1866, 1865],
      vocabulary_id: 17,
      parent_id: null,
      id: 1225,
      _second_name: 'Lakedaemonian',
      _name: 'Lakedaimonian '
    }, {
      vocabulary_item_term_ids: [1912],
      vocabulary_id: 17,
      parent_id: null,
      id: 1266,
      _second_name: null,
      _name: 'Carian'
    }, {
      vocabulary_item_term_ids: [1916],
      vocabulary_id: 17,
      parent_id: null,
      id: 1270,
      _second_name: null,
      _name: 'Byzantine'
    }, {
      vocabulary_item_term_ids: [2091],
      vocabulary_id: 17,
      parent_id: null,
      id: 1416,
      _second_name: null,
      _name: 'Central American'
    }, {
      vocabulary_item_term_ids: [2101],
      vocabulary_id: 18,
      parent_id: null,
      id: 1426,
      _second_name: null,
      _name: 'copyist of'
    }, {
      vocabulary_item_term_ids: [2105],
      vocabulary_id: 18,
      parent_id: null,
      id: 1430,
      _second_name: null,
      _name: 'associate of'
    }, {
      vocabulary_item_term_ids: [2109],
      vocabulary_id: 18,
      parent_id: null,
      id: 1434,
      _second_name: null,
      _name: 'studio of'
    }, {
      vocabulary_item_term_ids: [2113],
      vocabulary_id: 18,
      parent_id: null,
      id: 1438,
      _second_name: null,
      _name: 'after'
    }, {
      vocabulary_item_term_ids: [2962],
      vocabulary_id: 26,
      parent_id: null,
      id: 2274,
      _second_name: null,
      _name: 'Countersign'
    }, {
      vocabulary_item_term_ids: [2966],
      vocabulary_id: 26,
      parent_id: null,
      id: 2278,
      _second_name: null,
      _name: 'Mark'
    }, {
      vocabulary_item_term_ids: [2970],
      vocabulary_id: 26,
      parent_id: null,
      id: 2282,
      _second_name: null,
      _name: 'Signature'
    }, {
      vocabulary_item_term_ids: [3166],
      vocabulary_id: 37,
      parent_id: null,
      id: 2478,
      _second_name: null,
      _name: 'online + live'
    }, {
      vocabulary_item_term_ids: [2087],
      vocabulary_id: 17,
      parent_id: 1125,
      id: 1412,
      _second_name: null,
      _name: 'New South Welsh'
    }, {
      vocabulary_item_term_ids: [2083],
      vocabulary_id: 17,
      parent_id: 1125,
      id: 1408,
      _second_name: null,
      _name: 'Tasmanian'
    }, {
      vocabulary_item_term_ids: [1798],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1164,
      _second_name: null,
      _name: 'Silesian'
    }, {
      vocabulary_item_term_ids: [1955],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1304,
      _second_name: null,
      _name: "Hawke's Bay"
    }, {
      vocabulary_item_term_ids: [850],
      vocabulary_id: 10,
      parent_id: 509,
      id: 552,
      _second_name: null,
      _name: 'Projections'
    }, {
      vocabulary_item_term_ids: [846],
      vocabulary_id: 10,
      parent_id: 505,
      id: 548,
      _second_name: null,
      _name: 'Periodicals'
    }, {
      vocabulary_item_term_ids: [842],
      vocabulary_id: 10,
      parent_id: 524,
      id: 544,
      _second_name: null,
      _name: 'Clocks'
    }, {
      vocabulary_item_term_ids: [838],
      vocabulary_id: 10,
      parent_id: 549,
      id: 540,
      _second_name: null,
      _name: 'Coins'
    }, {
      vocabulary_item_term_ids: [834],
      vocabulary_id: 10,
      parent_id: 505,
      id: 536,
      _second_name: null,
      _name: 'Memorabilia'
    }, {
      vocabulary_item_term_ids: [830],
      vocabulary_id: 10,
      parent_id: 505,
      id: 532,
      _second_name: null,
      _name: 'Advertising'
    }, {
      vocabulary_item_term_ids: [826],
      vocabulary_id: 10,
      parent_id: 512,
      id: 528,
      _second_name: null,
      _name: 'Autographs'
    }, {
      vocabulary_item_term_ids: [818],
      vocabulary_id: 10,
      parent_id: 509,
      id: 520,
      _second_name: null,
      _name: 'Performance Art'
    }, {
      vocabulary_item_term_ids: [814],
      vocabulary_id: 10,
      parent_id: 505,
      id: 516,
      _second_name: null,
      _name: 'Trading Cards'
    }, {
      vocabulary_item_term_ids: [806],
      vocabulary_id: 10,
      parent_id: 509,
      id: 508,
      _second_name: null,
      _name: 'Motion Pictures'
    }, {
      vocabulary_item_term_ids: [802],
      vocabulary_id: 10,
      parent_id: 509,
      id: 504,
      _second_name: null,
      _name: 'New Media Art'
    }, {
      vocabulary_item_term_ids: [798],
      vocabulary_id: 10,
      parent_id: 505,
      id: 500,
      _second_name: null,
      _name: 'Music and Sound Recordings'
    }, {
      vocabulary_item_term_ids: [794],
      vocabulary_id: 10,
      parent_id: 512,
      id: 496,
      _second_name: null,
      _name: 'Documents'
    }, {
      vocabulary_item_term_ids: [790],
      vocabulary_id: 10,
      parent_id: 509,
      id: 492,
      _second_name: null,
      _name: 'Video Art'
    }, {
      vocabulary_item_term_ids: [786],
      vocabulary_id: 10,
      parent_id: 524,
      id: 488,
      _second_name: null,
      _name: 'Design'
    }, {
      vocabulary_item_term_ids: [782],
      vocabulary_id: 10,
      parent_id: 448,
      id: 484,
      _second_name: null,
      _name: 'Computers and Electronics'
    }, {
      vocabulary_item_term_ids: [778],
      vocabulary_id: 10,
      parent_id: 474,
      id: 480,
      _second_name: null,
      _name: 'Cognac'
    }, {
      vocabulary_item_term_ids: [774],
      vocabulary_id: 10,
      parent_id: 524,
      id: 476,
      _second_name: null,
      _name: 'Lighting'
    }, {
      vocabulary_item_term_ids: [770],
      vocabulary_id: 10,
      parent_id: 448,
      id: 472,
      _second_name: null,
      _name: 'Cameras'
    }, {
      vocabulary_item_term_ids: [766],
      vocabulary_id: 10,
      parent_id: 464,
      id: 468,
      _second_name: null,
      _name: 'African, Oceanic, and Pre-Columbian'
    }, {
      vocabulary_item_term_ids: [758],
      vocabulary_id: 10,
      parent_id: 524,
      id: 460,
      _second_name: null,
      _name: 'Decorative Objects'
    }, {
      vocabulary_item_term_ids: [754],
      vocabulary_id: 10,
      parent_id: 466,
      id: 456,
      _second_name: null,
      _name: 'Percussion and Drums'
    }, {
      vocabulary_item_term_ids: [750],
      vocabulary_id: 10,
      parent_id: 524,
      id: 452,
      _second_name: null,
      _name: 'Fireplace Accessories'
    }, {
      vocabulary_item_term_ids: [733, 732],
      vocabulary_id: 9,
      parent_id: null,
      id: 440,
      _second_name: 'milligram',
      _name: 'mg'
    }, {
      vocabulary_item_term_ids: [884, 883],
      vocabulary_id: 11,
      parent_id: null,
      id: 572,
      _second_name: 'ROL',
      _name: 'Old Leu'
    }, {
      vocabulary_item_term_ids: [924, 923],
      vocabulary_id: 11,
      parent_id: null,
      id: 592,
      _second_name: 'Zloty',
      _name: 'PLZ'
    }, {
      vocabulary_item_term_ids: [964, 963],
      vocabulary_id: 11,
      parent_id: null,
      id: 612,
      _second_name: 'Ekwele',
      _name: 'EQE'
    }, {
      vocabulary_item_term_ids: [1004, 1003],
      vocabulary_id: 11,
      parent_id: null,
      id: 632,
      _second_name: 'Guinea-Bissau Peso',
      _name: 'GWP'
    }, {
      vocabulary_item_term_ids: [1044, 1043],
      vocabulary_id: 11,
      parent_id: null,
      id: 652,
      _second_name: 'Spanish Peseta',
      _name: 'ESP'
    }, {
      vocabulary_item_term_ids: [1084, 1083],
      vocabulary_id: 11,
      parent_id: null,
      id: 672,
      _second_name: 'Sri Lanka Rupee',
      _name: 'LKR'
    }, {
      vocabulary_item_term_ids: [1124, 1123],
      vocabulary_id: 11,
      parent_id: null,
      id: 692,
      _second_name: 'Bulgarian Lev',
      _name: 'BGN'
    }, {
      vocabulary_item_term_ids: [1164, 1163],
      vocabulary_id: 11,
      parent_id: null,
      id: 712,
      _second_name: 'Unidad de Fomento',
      _name: 'CLF'
    }, {
      vocabulary_item_term_ids: [1204, 1203],
      vocabulary_id: 11,
      parent_id: null,
      id: 732,
      _second_name: 'Quetzal',
      _name: 'GTQ'
    }, {
      vocabulary_item_term_ids: [1244, 1243],
      vocabulary_id: 11,
      parent_id: null,
      id: 752,
      _second_name: 'Saint Helena Pound',
      _name: 'SHP'
    }, {
      vocabulary_item_term_ids: [1295, 1294],
      vocabulary_id: 11,
      parent_id: null,
      id: 777,
      _second_name: 'XRE',
      _name: 'RINET Funds Code'
    }, {
      vocabulary_item_term_ids: [1335, 1334],
      vocabulary_id: 11,
      parent_id: null,
      id: 797,
      _second_name: 'Deutsche Mark',
      _name: 'DEM'
    }, {
      vocabulary_item_term_ids: [1375, 1374],
      vocabulary_id: 11,
      parent_id: null,
      id: 817,
      _second_name: 'Danish Krone',
      _name: 'DKK'
    }, {
      vocabulary_item_term_ids: [1415, 1414],
      vocabulary_id: 11,
      parent_id: null,
      id: 837,
      _second_name: 'Afghani',
      _name: 'AFA'
    }, {
      vocabulary_item_term_ids: [1438],
      vocabulary_id: 12,
      parent_id: null,
      id: 853,
      _second_name: null,
      _name: 'surface area'
    }, {
      vocabulary_item_term_ids: [1442],
      vocabulary_id: 12,
      parent_id: null,
      id: 857,
      _second_name: null,
      _name: 'depth'
    }, {
      vocabulary_item_term_ids: [1446],
      vocabulary_id: 12,
      parent_id: null,
      id: 861,
      _second_name: null,
      _name: 'height'
    }, {
      vocabulary_item_term_ids: [1469],
      vocabulary_id: 17,
      parent_id: null,
      id: 884,
      _second_name: null,
      _name: 'Zimbabwean'
    }, {
      vocabulary_item_term_ids: [1499],
      vocabulary_id: 17,
      parent_id: null,
      id: 904,
      _second_name: null,
      _name: 'Ugandan'
    }, {
      vocabulary_item_term_ids: [1503],
      vocabulary_id: 17,
      parent_id: null,
      id: 908,
      _second_name: null,
      _name: 'Turkish'
    }, {
      vocabulary_item_term_ids: [1538],
      vocabulary_id: 17,
      parent_id: null,
      id: 938,
      _second_name: null,
      _name: 'Sierra Leonean'
    }, {
      vocabulary_item_term_ids: [1565],
      vocabulary_id: 17,
      parent_id: null,
      id: 960,
      _second_name: null,
      _name: 'Puerto Rican'
    }, {
      vocabulary_item_term_ids: [1570, 1569],
      vocabulary_id: 17,
      parent_id: null,
      id: 964,
      _second_name: 'Filipino',
      _name: 'Philippine'
    }, {
      vocabulary_item_term_ids: [1646],
      vocabulary_id: 17,
      parent_id: null,
      id: 1026,
      _second_name: null,
      _name: 'Jordanian'
    }, {
      vocabulary_item_term_ids: [1650],
      vocabulary_id: 17,
      parent_id: null,
      id: 1030,
      _second_name: null,
      _name: 'Jamaican'
    }, {
      vocabulary_item_term_ids: [1654],
      vocabulary_id: 17,
      parent_id: null,
      id: 1034,
      _second_name: null,
      _name: 'Manx'
    }, {
      vocabulary_item_term_ids: [1681],
      vocabulary_id: 17,
      parent_id: null,
      id: 1055,
      _second_name: null,
      _name: 'Gibraltar'
    }, {
      vocabulary_item_term_ids: [1685],
      vocabulary_id: 17,
      parent_id: null,
      id: 1059,
      _second_name: null,
      _name: 'Gambian'
    }, {
      vocabulary_item_term_ids: [1689],
      vocabulary_id: 17,
      parent_id: null,
      id: 1063,
      _second_name: null,
      _name: 'French Guianese'
    }, {
      vocabulary_item_term_ids: [1693],
      vocabulary_id: 17,
      parent_id: null,
      id: 1067,
      _second_name: null,
      _name: 'Faroese'
    }, {
      vocabulary_item_term_ids: [1697],
      vocabulary_id: 17,
      parent_id: null,
      id: 1071,
      _second_name: null,
      _name: 'Estonian'
    }, {
      vocabulary_item_term_ids: [1705],
      vocabulary_id: 17,
      parent_id: null,
      id: 1078,
      _second_name: null,
      _name: 'Djiboutian'
    }, {
      vocabulary_item_term_ids: [1709],
      vocabulary_id: 17,
      parent_id: null,
      id: 1082,
      _second_name: null,
      _name: 'Curaçaoan'
    }, {
      vocabulary_item_term_ids: [1717],
      vocabulary_id: 17,
      parent_id: null,
      id: 1089,
      _second_name: null,
      _name: 'Colombian'
    }, {
      vocabulary_item_term_ids: [1721],
      vocabulary_id: 17,
      parent_id: null,
      id: 1093,
      _second_name: null,
      _name: 'Chilean'
    }, {
      vocabulary_item_term_ids: [1725],
      vocabulary_id: 17,
      parent_id: null,
      id: 1097,
      _second_name: null,
      _name: 'Canadian'
    }, {
      vocabulary_item_term_ids: [1729],
      vocabulary_id: 17,
      parent_id: null,
      id: 1101,
      _second_name: null,
      _name: 'Burundian'
    }, {
      vocabulary_item_term_ids: [1741],
      vocabulary_id: 17,
      parent_id: null,
      id: 1112,
      _second_name: null,
      _name: 'Bolivian'
    }, {
      vocabulary_item_term_ids: [1823],
      vocabulary_id: 17,
      parent_id: null,
      id: 1183,
      _second_name: null,
      _name: 'Pontian'
    }, {
      vocabulary_item_term_ids: [1827],
      vocabulary_id: 17,
      parent_id: null,
      id: 1187,
      _second_name: null,
      _name: 'Phocian'
    }, {
      vocabulary_item_term_ids: [1831],
      vocabulary_id: 17,
      parent_id: null,
      id: 1191,
      _second_name: null,
      _name: 'Pergamian'
    }, {
      vocabulary_item_term_ids: [1835],
      vocabulary_id: 17,
      parent_id: null,
      id: 1195,
      _second_name: null,
      _name: 'Pamphylian'
    }, {
      vocabulary_item_term_ids: [1839],
      vocabulary_id: 17,
      parent_id: null,
      id: 1199,
      _second_name: null,
      _name: 'Olympian'
    }, {
      vocabulary_item_term_ids: [1843],
      vocabulary_id: 17,
      parent_id: null,
      id: 1203,
      _second_name: null,
      _name: 'Naupactian'
    }, {
      vocabulary_item_term_ids: [1847],
      vocabulary_id: 17,
      parent_id: null,
      id: 1207,
      _second_name: null,
      _name: 'Methymnian'
    }, {
      vocabulary_item_term_ids: [1851],
      vocabulary_id: 17,
      parent_id: null,
      id: 1211,
      _second_name: null,
      _name: 'Melian'
    }, {
      vocabulary_item_term_ids: [1855],
      vocabulary_id: 17,
      parent_id: null,
      id: 1215,
      _second_name: null,
      _name: 'Marathonian'
    }, {
      vocabulary_item_term_ids: [1867],
      vocabulary_id: 17,
      parent_id: null,
      id: 1226,
      _second_name: null,
      _name: 'Knossian'
    }, {
      vocabulary_item_term_ids: [1871],
      vocabulary_id: 17,
      parent_id: null,
      id: 1230,
      _second_name: null,
      _name: 'Illyrian'
    }, {
      vocabulary_item_term_ids: [2979],
      vocabulary_id: 28,
      parent_id: null,
      id: 2291,
      _second_name: null,
      _name: 'Hammer'
    }, {
      vocabulary_item_term_ids: [3117],
      vocabulary_id: 29,
      parent_id: null,
      id: 2429,
      _second_name: null,
      _name: 'Science in Business'
    }, {
      vocabulary_item_term_ids: [3121],
      vocabulary_id: 29,
      parent_id: null,
      id: 2433,
      _second_name: null,
      _name: 'Political Science or Associate of Public Service'
    }, {
      vocabulary_item_term_ids: [3125],
      vocabulary_id: 29,
      parent_id: null,
      id: 2437,
      _second_name: null,
      _name: 'Pre-Engineering'
    }, {
      vocabulary_item_term_ids: [3129],
      vocabulary_id: 29,
      parent_id: null,
      id: 2441,
      _second_name: null,
      _name: 'Arts and Sciences'
    }, {
      vocabulary_item_term_ids: [2039, 2038],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1370,
      _second_name: 'Delhiite',
      _name: 'Delhian'
    }, {
      vocabulary_item_term_ids: [1977],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1324,
      _second_name: null,
      _name: 'South Lower Californian'
    }, {
      vocabulary_item_term_ids: [1973],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1320,
      _second_name: null,
      _name: 'Coahuilan'
    }, {
      vocabulary_item_term_ids: [1965],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1313,
      _second_name: null,
      _name: 'Queretan'
    }, {
      vocabulary_item_term_ids: [1961],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1309,
      _second_name: null,
      _name: 'Zacatecan'
    }, {
      vocabulary_item_term_ids: [1543, 1542],
      vocabulary_id: 17,
      parent_id: 901,
      id: 942,
      _second_name: 'Scots',
      _name: 'Scottish'
    }, {
      vocabulary_item_term_ids: [734, 735],
      vocabulary_id: 9,
      parent_id: null,
      id: 441,
      _second_name: 'kilometres',
      _name: 'km'
    }, {
      vocabulary_item_term_ids: [885, 886],
      vocabulary_id: 11,
      parent_id: null,
      id: 573,
      _second_name: 'Italian Lira',
      _name: 'ITL'
    }, {
      vocabulary_item_term_ids: [925, 926],
      vocabulary_id: 11,
      parent_id: null,
      id: 593,
      _second_name: 'UYN',
      _name: 'Old Uruguay Peso'
    }, {
      vocabulary_item_term_ids: [965, 966],
      vocabulary_id: 11,
      parent_id: null,
      id: 613,
      _second_name: 'Sudanese Pound',
      _name: 'SDG'
    }, {
      vocabulary_item_term_ids: [1005, 1006],
      vocabulary_id: 11,
      parent_id: null,
      id: 633,
      _second_name: 'Indian Rupee',
      _name: 'INR'
    }, {
      vocabulary_item_term_ids: [1045, 1046],
      vocabulary_id: 11,
      parent_id: null,
      id: 653,
      _second_name: 'Cruzeiro',
      _name: 'BRB'
    }, {
      vocabulary_item_term_ids: [1085, 1086],
      vocabulary_id: 11,
      parent_id: null,
      id: 673,
      _second_name: 'Iceland Krona',
      _name: 'ISK'
    }, {
      vocabulary_item_term_ids: [1125, 1126],
      vocabulary_id: 11,
      parent_id: null,
      id: 693,
      _second_name: 'Azerbaijanian Manat',
      _name: 'AZN'
    }, {
      vocabulary_item_term_ids: [1165, 1166],
      vocabulary_id: 11,
      parent_id: null,
      id: 713,
      _second_name: 'Brazilian Real',
      _name: 'BRL'
    }, {
      vocabulary_item_term_ids: [1205, 1206],
      vocabulary_id: 11,
      parent_id: null,
      id: 733,
      _second_name: 'Slovak Koruna',
      _name: 'SKK'
    }, {
      vocabulary_item_term_ids: [1245, 1246],
      vocabulary_id: 11,
      parent_id: null,
      id: 753,
      _second_name: 'Pakistan Rupee',
      _name: 'PKR'
    }, {
      vocabulary_item_term_ids: [1296, 1297],
      vocabulary_id: 11,
      parent_id: null,
      id: 778,
      _second_name: 'Netherlands Guilder',
      _name: 'NLG'
    }, {
      vocabulary_item_term_ids: [1336, 1337],
      vocabulary_id: 11,
      parent_id: null,
      id: 798,
      _second_name: 'Mark der DDR',
      _name: 'DDM'
    }, {
      vocabulary_item_term_ids: [1376, 1377],
      vocabulary_id: 11,
      parent_id: null,
      id: 818,
      _second_name: 'WIR Franc',
      _name: 'CHW'
    }, {
      vocabulary_item_term_ids: [1416, 1417],
      vocabulary_id: 11,
      parent_id: null,
      id: 838,
      _second_name: 'Solomon Islands Dollar',
      _name: 'SBD'
    }, {
      vocabulary_item_term_ids: [1432],
      vocabulary_id: 12,
      parent_id: null,
      id: 847,
      _second_name: null,
      _name: 'pressure'
    }, {
      vocabulary_item_term_ids: [1436],
      vocabulary_id: 12,
      parent_id: null,
      id: 851,
      _second_name: null,
      _name: 'density'
    }, {
      vocabulary_item_term_ids: [1455],
      vocabulary_id: 14,
      parent_id: null,
      id: 870,
      _second_name: null,
      _name: 'Prices unavailable'
    }, {
      vocabulary_item_term_ids: [1459],
      vocabulary_id: 14,
      parent_id: null,
      id: 874,
      _second_name: null,
      _name: 'Catalog info approved'
    }, {
      vocabulary_item_term_ids: [1509],
      vocabulary_id: 17,
      parent_id: null,
      id: 913,
      _second_name: null,
      _name: 'Togolese'
    }, {
      vocabulary_item_term_ids: [1513],
      vocabulary_id: 17,
      parent_id: null,
      id: 917,
      _second_name: null,
      _name: 'Tajikistani'
    }, {
      vocabulary_item_term_ids: [1517],
      vocabulary_id: 17,
      parent_id: null,
      id: 921,
      _second_name: null,
      _name: 'Swazi'
    }, {
      vocabulary_item_term_ids: [1521],
      vocabulary_id: 17,
      parent_id: null,
      id: 925,
      _second_name: null,
      _name: 'Sri Lankan'
    }, {
      vocabulary_item_term_ids: [1525, 1526],
      vocabulary_id: 17,
      parent_id: null,
      id: 929,
      _second_name: 'South Sandwich Islands',
      _name: 'South Georgia'
    }, {
      vocabulary_item_term_ids: [1544, 1545],
      vocabulary_id: 17,
      parent_id: null,
      id: 943,
      _second_name: 'Saudi Arabian',
      _name: 'Saudi'
    }, {
      vocabulary_item_term_ids: [1571],
      vocabulary_id: 17,
      parent_id: null,
      id: 965,
      _second_name: null,
      _name: 'Peruvian'
    }, {
      vocabulary_item_term_ids: [1598],
      vocabulary_id: 17,
      parent_id: null,
      id: 987,
      _second_name: null,
      _name: 'Mozambican'
    }, {
      vocabulary_item_term_ids: [1602],
      vocabulary_id: 17,
      parent_id: null,
      id: 991,
      _second_name: null,
      _name: 'Mongolian'
    }, {
      vocabulary_item_term_ids: [1660],
      vocabulary_id: 17,
      parent_id: null,
      id: 1039,
      _second_name: null,
      _name: 'Indian'
    }, {
      vocabulary_item_term_ids: [1703],
      vocabulary_id: 17,
      parent_id: null,
      id: 1076,
      _second_name: null,
      _name: 'Ecuadoran'
    }, {
      vocabulary_item_term_ids: [1877],
      vocabulary_id: 17,
      parent_id: null,
      id: 1235,
      _second_name: null,
      _name: 'Galatian'
    }, {
      vocabulary_item_term_ids: [1881],
      vocabulary_id: 17,
      parent_id: null,
      id: 1239,
      _second_name: null,
      _name: 'Epirote'
    }, {
      vocabulary_item_term_ids: [1885],
      vocabulary_id: 17,
      parent_id: null,
      id: 1243,
      _second_name: null,
      _name: 'Elian'
    }, {
      vocabulary_item_term_ids: [1924],
      vocabulary_id: 17,
      parent_id: null,
      id: 1276,
      _second_name: null,
      _name: 'Bactrian'
    }, {
      vocabulary_item_term_ids: [1928],
      vocabulary_id: 17,
      parent_id: null,
      id: 1280,
      _second_name: null,
      _name: 'Arcadian'
    }, {
      vocabulary_item_term_ids: [1932],
      vocabulary_id: 17,
      parent_id: null,
      id: 1284,
      _second_name: null,
      _name: 'Aetolian'
    }, {
      vocabulary_item_term_ids: [1936],
      vocabulary_id: 17,
      parent_id: null,
      id: 1288,
      _second_name: null,
      _name: 'Acarnanian'
    }, {
      vocabulary_item_term_ids: [2957],
      vocabulary_id: 25,
      parent_id: null,
      id: 2269,
      _second_name: null,
      _name: 'work'
    }, {
      vocabulary_item_term_ids: [2961],
      vocabulary_id: 25,
      parent_id: null,
      id: 2273,
      _second_name: null,
      _name: 'home'
    }, {
      vocabulary_item_term_ids: [3075],
      vocabulary_id: 29,
      parent_id: null,
      id: 2387,
      _second_name: null,
      _name: 'Advanced Study '
    }, {
      vocabulary_item_term_ids: [3079],
      vocabulary_id: 29,
      parent_id: null,
      id: 2391,
      _second_name: null,
      _name: 'Mathematical Sciences'
    }, {
      vocabulary_item_term_ids: [3083],
      vocabulary_id: 29,
      parent_id: null,
      id: 2395,
      _second_name: null,
      _name: 'Arts in Social Work'
    }, {
      vocabulary_item_term_ids: [3087],
      vocabulary_id: 29,
      parent_id: null,
      id: 2399,
      _second_name: null,
      _name: 'Science in Psychology'
    }, {
      vocabulary_item_term_ids: [3091],
      vocabulary_id: 29,
      parent_id: null,
      id: 2403,
      _second_name: null,
      _name: 'Art in Music'
    }, {
      vocabulary_item_term_ids: [3095],
      vocabulary_id: 29,
      parent_id: null,
      id: 2407,
      _second_name: null,
      _name: 'Science in Human Biology'
    }, {
      vocabulary_item_term_ids: [3099],
      vocabulary_id: 29,
      parent_id: null,
      id: 2411,
      _second_name: null,
      _name: 'Film and Television '
    }, {
      vocabulary_item_term_ids: [3103],
      vocabulary_id: 29,
      parent_id: null,
      id: 2415,
      _second_name: null,
      _name: 'Science in Public Health'
    }, {
      vocabulary_item_term_ids: [3107],
      vocabulary_id: 29,
      parent_id: null,
      id: 2419,
      _second_name: null,
      _name: 'Computing '
    }, {
      vocabulary_item_term_ids: [3111],
      vocabulary_id: 29,
      parent_id: null,
      id: 2423,
      _second_name: null,
      _name: 'Business Science'
    }, {
      vocabulary_item_term_ids: [3115],
      vocabulary_id: 29,
      parent_id: null,
      id: 2427,
      _second_name: null,
      _name: 'Management Studies'
    }, {
      vocabulary_item_term_ids: [3142],
      vocabulary_id: 31,
      parent_id: null,
      id: 2454,
      _second_name: null,
      _name: 'producer'
    }, {
      vocabulary_item_term_ids: [2068, 2069],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1396,
      _second_name: 'P.E.I.',
      _name: 'Prince Edward Island'
    }, {
      vocabulary_item_term_ids: [2056],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1385,
      _second_name: null,
      _name: 'Pomeranian'
    }, {
      vocabulary_item_term_ids: [2040, 2041],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1371,
      _second_name: 'Diuese',
      _name: 'Damanese'
    }, {
      vocabulary_item_term_ids: [2025],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1361,
      _second_name: null,
      _name: 'Laccadivian'
    }, {
      vocabulary_item_term_ids: [2021],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1357,
      _second_name: null,
      _name: 'Meghalayan'
    }, {
      vocabulary_item_term_ids: [2006, 2007],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1347,
      _second_name: 'Tripuran',
      _name: 'Tripuri'
    }, {
      vocabulary_item_term_ids: [1995],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1341,
      _second_name: null,
      _name: 'Kelantanese'
    }, {
      vocabulary_item_term_ids: [1991],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1337,
      _second_name: null,
      _name: 'Negeri Sembilanese'
    }, {
      vocabulary_item_term_ids: [1987],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1333,
      _second_name: null,
      _name: 'Perlisian'
    }, {
      vocabulary_item_term_ids: [1983],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1329,
      _second_name: null,
      _name: 'Selangorean'
    }, {
      vocabulary_item_term_ids: [725],
      vocabulary_id: 9,
      parent_id: null,
      id: 436,
      _second_name: null,
      _name: 'm/s'
    }, {
      vocabulary_item_term_ids: [736, 737],
      vocabulary_id: 9,
      parent_id: null,
      id: 442,
      _second_name: 'metres',
      _name: 'm'
    }, {
      vocabulary_item_term_ids: [887, 888],
      vocabulary_id: 11,
      parent_id: null,
      id: 574,
      _second_name: 'Uganda Shilling',
      _name: 'UGS'
    }, {
      vocabulary_item_term_ids: [927, 928],
      vocabulary_id: 11,
      parent_id: null,
      id: 594,
      _second_name: 'Malagasy Franc',
      _name: 'MGF'
    }, {
      vocabulary_item_term_ids: [967, 968],
      vocabulary_id: 11,
      parent_id: null,
      id: 614,
      _second_name: 'Serbian Dinar',
      _name: 'RSD'
    }, {
      vocabulary_item_term_ids: [1007, 1008],
      vocabulary_id: 11,
      parent_id: null,
      id: 634,
      _second_name: 'Jordanian Dinar',
      _name: 'JOD'
    }, {
      vocabulary_item_term_ids: [1047, 1048],
      vocabulary_id: 11,
      parent_id: null,
      id: 654,
      _second_name: 'Dinar',
      _name: 'BAD'
    }, {
      vocabulary_item_term_ids: [1087, 1088],
      vocabulary_id: 11,
      parent_id: null,
      id: 674,
      _second_name: 'Rupiah',
      _name: 'IDR'
    }, {
      vocabulary_item_term_ids: [1127, 1128],
      vocabulary_id: 11,
      parent_id: null,
      id: 694,
      _second_name: 'Sol',
      _name: 'PES'
    }, {
      vocabulary_item_term_ids: [1167, 1168],
      vocabulary_id: 11,
      parent_id: null,
      id: 714,
      _second_name: 'Unidad de Valor Constante (UVC)',
      _name: 'ECV'
    }, {
      vocabulary_item_term_ids: [1207, 1208],
      vocabulary_id: 11,
      parent_id: null,
      id: 734,
      _second_name: 'Sol',
      _name: 'PEH'
    }, {
      vocabulary_item_term_ids: [1247, 1248],
      vocabulary_id: 11,
      parent_id: null,
      id: 754,
      _second_name: 'Philippine Peso',
      _name: 'PHP'
    }, {
      vocabulary_item_term_ids: [1298, 1299],
      vocabulary_id: 11,
      parent_id: null,
      id: 779,
      _second_name: 'Yemeni Dinar',
      _name: 'YDD'
    }, {
      vocabulary_item_term_ids: [1338, 1339],
      vocabulary_id: 11,
      parent_id: null,
      id: 799,
      _second_name: 'N.A.',
      _name: 'BUK'
    }, {
      vocabulary_item_term_ids: [1378, 1379],
      vocabulary_id: 11,
      parent_id: null,
      id: 819,
      _second_name: 'Fiji Dollar',
      _name: 'FJD'
    }, {
      vocabulary_item_term_ids: [1419, 1418],
      vocabulary_id: 11,
      parent_id: null,
      id: 839,
      _second_name: 'Saudi Riyal',
      _name: 'SAR'
    }, {
      vocabulary_item_term_ids: [1468],
      vocabulary_id: 16,
      parent_id: null,
      id: 883,
      _second_name: null,
      _name: 'Metric'
    }, {
      vocabulary_item_term_ids: [1477],
      vocabulary_id: 17,
      parent_id: null,
      id: 890,
      _second_name: null,
      _name: 'Wallis and Futuna'
    }, {
      vocabulary_item_term_ids: [1481],
      vocabulary_id: 17,
      parent_id: null,
      id: 894,
      _second_name: null,
      _name: 'Vietnamese'
    }, {
      vocabulary_item_term_ids: [1527],
      vocabulary_id: 17,
      parent_id: null,
      id: 930,
      _second_name: null,
      _name: 'South African'
    }, {
      vocabulary_item_term_ids: [1546],
      vocabulary_id: 17,
      parent_id: null,
      id: 944,
      _second_name: null,
      _name: 'São Toméan'
    }, {
      vocabulary_item_term_ids: [1577],
      vocabulary_id: 17,
      parent_id: null,
      id: 970,
      _second_name: null,
      _name: 'Palauan'
    }, {
      vocabulary_item_term_ids: [1581],
      vocabulary_id: 17,
      parent_id: null,
      id: 974,
      _second_name: null,
      _name: 'Northern Marianan'
    }, {
      vocabulary_item_term_ids: [1585],
      vocabulary_id: 17,
      parent_id: null,
      id: 978,
      _second_name: null,
      _name: 'Nigerian'
    }, {
      vocabulary_item_term_ids: [1608],
      vocabulary_id: 17,
      parent_id: null,
      id: 996,
      _second_name: null,
      _name: 'Mahoran'
    }, {
      vocabulary_item_term_ids: [1749],
      vocabulary_id: 17,
      parent_id: null,
      id: 1118,
      _second_name: null,
      _name: 'Belarusian'
    }, {
      vocabulary_item_term_ids: [1753],
      vocabulary_id: 17,
      parent_id: null,
      id: 1122,
      _second_name: null,
      _name: 'Bahamian'
    }, {
      vocabulary_item_term_ids: [1891],
      vocabulary_id: 17,
      parent_id: null,
      id: 1248,
      _second_name: null,
      _name: 'Delian'
    }, {
      vocabulary_item_term_ids: [1895],
      vocabulary_id: 17,
      parent_id: null,
      id: 1252,
      _second_name: null,
      _name: 'Cyrenaic'
    }, {
      vocabulary_item_term_ids: [1899],
      vocabulary_id: 17,
      parent_id: null,
      id: 1255,
      _second_name: null,
      _name: 'Cretan'
    }, {
      vocabulary_item_term_ids: [1903],
      vocabulary_id: 17,
      parent_id: null,
      id: 1259,
      _second_name: null,
      _name: 'Colossian'
    }, {
      vocabulary_item_term_ids: [1907],
      vocabulary_id: 17,
      parent_id: null,
      id: 1263,
      _second_name: null,
      _name: 'Cephalonian'
    }, {
      vocabulary_item_term_ids: [2094],
      vocabulary_id: 17,
      parent_id: null,
      id: 1419,
      _second_name: null,
      _name: 'Australasian'
    }, {
      vocabulary_item_term_ids: [2117],
      vocabulary_id: 19,
      parent_id: null,
      id: 1442,
      _second_name: null,
      _name: 'name'
    }, {
      vocabulary_item_term_ids: [2308],
      vocabulary_id: 21,
      parent_id: null,
      id: 1633,
      _second_name: null,
      _name: 'newspaper article'
    }, {
      vocabulary_item_term_ids: [2312],
      vocabulary_id: 21,
      parent_id: null,
      id: 1637,
      _second_name: null,
      _name: 'exhibition review'
    }, {
      vocabulary_item_term_ids: [2316],
      vocabulary_id: 21,
      parent_id: null,
      id: 1641,
      _second_name: null,
      _name: 'inventory'
    }, {
      vocabulary_item_term_ids: [2320],
      vocabulary_id: 21,
      parent_id: null,
      id: 1645,
      _second_name: null,
      _name: 'encyclopedia'
    }, {
      vocabulary_item_term_ids: [2974],
      vocabulary_id: 27,
      parent_id: null,
      id: 2286,
      _second_name: null,
      _name: 'Range'
    }, {
      vocabulary_item_term_ids: [3025],
      vocabulary_id: 29,
      parent_id: null,
      id: 2337,
      _second_name: null,
      _name: 'Music '
    }, {
      vocabulary_item_term_ids: [3029],
      vocabulary_id: 29,
      parent_id: null,
      id: 2341,
      _second_name: null,
      _name: 'Management '
    }, {
      vocabulary_item_term_ids: [3033],
      vocabulary_id: 29,
      parent_id: null,
      id: 2345,
      _second_name: null,
      _name: 'Landscape Architecture '
    }, {
      vocabulary_item_term_ids: [3037],
      vocabulary_id: 29,
      parent_id: null,
      id: 2349,
      _second_name: null,
      _name: 'IT '
    }, {
      vocabulary_item_term_ids: [3041],
      vocabulary_id: 29,
      parent_id: null,
      id: 2353,
      _second_name: null,
      _name: 'International Business'
    }, {
      vocabulary_item_term_ids: [3045],
      vocabulary_id: 29,
      parent_id: null,
      id: 2357,
      _second_name: null,
      _name: 'Health Science '
    }, {
      vocabulary_item_term_ids: [3049],
      vocabulary_id: 29,
      parent_id: null,
      id: 2361,
      _second_name: null,
      _name: 'Financial Engineering '
    }, {
      vocabulary_item_term_ids: [3053],
      vocabulary_id: 29,
      parent_id: null,
      id: 2365,
      _second_name: null,
      _name: 'Engineering Management'
    }, {
      vocabulary_item_term_ids: [3057],
      vocabulary_id: 29,
      parent_id: null,
      id: 2369,
      _second_name: null,
      _name: 'Economics '
    }, {
      vocabulary_item_term_ids: [3061],
      vocabulary_id: 29,
      parent_id: null,
      id: 2373,
      _second_name: null,
      _name: 'Creative Technologies'
    }, {
      vocabulary_item_term_ids: [3065],
      vocabulary_id: 29,
      parent_id: null,
      id: 2377,
      _second_name: null,
      _name: 'Chemistry '
    }, {
      vocabulary_item_term_ids: [3069],
      vocabulary_id: 29,
      parent_id: null,
      id: 2381,
      _second_name: null,
      _name: 'Business '
    }, {
      vocabulary_item_term_ids: [3073],
      vocabulary_id: 29,
      parent_id: null,
      id: 2385,
      _second_name: null,
      _name: 'Applied Science '
    }, {
      vocabulary_item_term_ids: [2070],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1397,
      _second_name: null,
      _name: 'Ontario'
    }, {
      vocabulary_item_term_ids: [2062],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1390,
      _second_name: null,
      _name: 'Hamburgish'
    }, {
      vocabulary_item_term_ids: [2050],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1379,
      _second_name: null,
      _name: 'Andamanese '
    }, {
      vocabulary_item_term_ids: [2046],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1375,
      _second_name: null,
      _name: 'Assamese'
    }, {
      vocabulary_item_term_ids: [2043, 2042],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1372,
      _second_name: 'Nagar Havelian',
      _name: 'Dadran'
    }, {
      vocabulary_item_term_ids: [2008],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1348,
      _second_name: null,
      _name: 'Telanganite'
    }, {
      vocabulary_item_term_ids: [1942],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1293,
      _second_name: null,
      _name: 'West Coast'
    }, {
      vocabulary_item_term_ids: [658],
      vocabulary_id: 7,
      parent_id: null,
      id: 377,
      _second_name: null,
      _name: 'stamps'
    }, {
      vocabulary_item_term_ids: [662],
      vocabulary_id: 7,
      parent_id: null,
      id: 381,
      _second_name: null,
      _name: 'jewelry'
    }, {
      vocabulary_item_term_ids: [666],
      vocabulary_id: 7,
      parent_id: null,
      id: 385,
      _second_name: null,
      _name: 'drawing'
    }, {
      vocabulary_item_term_ids: [670],
      vocabulary_id: 7,
      parent_id: null,
      id: 389,
      _second_name: null,
      _name: 'vase'
    }, {
      vocabulary_item_term_ids: [674],
      vocabulary_id: 7,
      parent_id: null,
      id: 393,
      _second_name: null,
      _name: 'bust'
    }, {
      vocabulary_item_term_ids: [678],
      vocabulary_id: 7,
      parent_id: null,
      id: 397,
      _second_name: null,
      _name: 'ceramics'
    }, {
      vocabulary_item_term_ids: [682],
      vocabulary_id: 7,
      parent_id: null,
      id: 401,
      _second_name: null,
      _name: 'tapestry'
    }, {
      vocabulary_item_term_ids: [686],
      vocabulary_id: 7,
      parent_id: null,
      id: 405,
      _second_name: null,
      _name: 'fresco'
    }, {
      vocabulary_item_term_ids: [690],
      vocabulary_id: 7,
      parent_id: null,
      id: 409,
      _second_name: null,
      _name: 'digital art'
    }, {
      vocabulary_item_term_ids: [694],
      vocabulary_id: 7,
      parent_id: null,
      id: 413,
      _second_name: null,
      _name: 'mixed media'
    }, {
      vocabulary_item_term_ids: [716, 717],
      vocabulary_id: 9,
      parent_id: null,
      id: 431,
      _second_name: 'hours',
      _name: 'h'
    }, {
      vocabulary_item_term_ids: [738, 739],
      vocabulary_id: 9,
      parent_id: null,
      id: 443,
      _second_name: 'centimetres',
      _name: 'cm'
    }, {
      vocabulary_item_term_ids: [889, 890],
      vocabulary_id: 11,
      parent_id: null,
      id: 575,
      _second_name: 'Maltese Pound',
      _name: 'MTP'
    }, {
      vocabulary_item_term_ids: [929, 930],
      vocabulary_id: 11,
      parent_id: null,
      id: 595,
      _second_name: 'XSU',
      _name: 'Sucre'
    }, {
      vocabulary_item_term_ids: [969, 970],
      vocabulary_id: 11,
      parent_id: null,
      id: 615,
      _second_name: 'Cruzeiro',
      _name: 'BRE'
    }, {
      vocabulary_item_term_ids: [1009, 1010],
      vocabulary_id: 11,
      parent_id: null,
      id: 635,
      _second_name: 'Peso boliviano',
      _name: 'BOP'
    }, {
      vocabulary_item_term_ids: [1049, 1050],
      vocabulary_id: 11,
      parent_id: null,
      id: 655,
      _second_name: 'Azerbaijanian Manat',
      _name: 'AZM'
    }, {
      vocabulary_item_term_ids: [1089, 1090],
      vocabulary_id: 11,
      parent_id: null,
      id: 675,
      _second_name: 'HUF',
      _name: 'Forint'
    }, {
      vocabulary_item_term_ids: [1129, 1130],
      vocabulary_id: 11,
      parent_id: null,
      id: 695,
      _second_name: 'Lek',
      _name: 'ALL'
    }, {
      vocabulary_item_term_ids: [1169, 1170],
      vocabulary_id: 11,
      parent_id: null,
      id: 715,
      _second_name: 'HRD',
      _name: 'Croatian Dinar'
    }, {
      vocabulary_item_term_ids: [1209, 1210],
      vocabulary_id: 11,
      parent_id: null,
      id: 735,
      _second_name: 'Peso Convertible',
      _name: 'CUC'
    }, {
      vocabulary_item_term_ids: [1249, 1250],
      vocabulary_id: 11,
      parent_id: null,
      id: 755,
      _second_name: 'Nepalese Rupee',
      _name: 'NPR'
    }, {
      vocabulary_item_term_ids: [1300, 1301],
      vocabulary_id: 11,
      parent_id: null,
      id: 780,
      _second_name: 'XFO',
      _name: 'Gold-Franc'
    }, {
      vocabulary_item_term_ids: [1340, 1341],
      vocabulary_id: 11,
      parent_id: null,
      id: 800,
      _second_name: 'Lev A/52',
      _name: 'BGJ'
    }, {
      vocabulary_item_term_ids: [1380, 1381],
      vocabulary_id: 11,
      parent_id: null,
      id: 820,
      _second_name: 'Costa Rican Colon',
      _name: 'CRC'
    }, {
      vocabulary_item_term_ids: [1420, 1421],
      vocabulary_id: 11,
      parent_id: null,
      id: 840,
      _second_name: 'Malaysian Ringgit',
      _name: 'MYR'
    }, {
      vocabulary_item_term_ids: [1450],
      vocabulary_id: 13,
      parent_id: null,
      id: 865,
      _second_name: null,
      _name: 'Withdrawn'
    }, {
      vocabulary_item_term_ids: [1533],
      vocabulary_id: 17,
      parent_id: null,
      id: 935,
      _second_name: null,
      _name: 'Sint Maarten'
    }, {
      vocabulary_item_term_ids: [1614],
      vocabulary_id: 17,
      parent_id: null,
      id: 1001,
      _second_name: null,
      _name: 'Maltese'
    }, {
      vocabulary_item_term_ids: [1618],
      vocabulary_id: 17,
      parent_id: null,
      id: 1005,
      _second_name: null,
      _name: 'Malawian'
    }, {
      vocabulary_item_term_ids: [1622, 1623],
      vocabulary_id: 17,
      parent_id: null,
      id: 1009,
      _second_name: 'Luxembourgish',
      _name: 'Luxembourg'
    }, {
      vocabulary_item_term_ids: [1637],
      vocabulary_id: 17,
      parent_id: null,
      id: 1019,
      _second_name: null,
      _name: 'Kuwaiti'
    }, {
      vocabulary_item_term_ids: [1759],
      vocabulary_id: 17,
      parent_id: null,
      id: 1127,
      _second_name: null,
      _name: 'Armenian'
    }, {
      vocabulary_item_term_ids: [1763],
      vocabulary_id: 17,
      parent_id: null,
      id: 1131,
      _second_name: null,
      _name: 'Antarctic'
    }, {
      vocabulary_item_term_ids: [1767],
      vocabulary_id: 17,
      parent_id: null,
      id: 1135,
      _second_name: null,
      _name: 'American Samoan'
    }, {
      vocabulary_item_term_ids: [1771],
      vocabulary_id: 17,
      parent_id: null,
      id: 1139,
      _second_name: null,
      _name: 'Afghan'
    }, {
      vocabulary_item_term_ids: [1791, 1792],
      vocabulary_id: 17,
      parent_id: null,
      id: 1158,
      _second_name: 'Tarentumian',
      _name: 'Tarentine'
    }, {
      vocabulary_item_term_ids: [2983],
      vocabulary_id: 29,
      parent_id: null,
      id: 2295,
      _second_name: null,
      _name: 'Medicine'
    }, {
      vocabulary_item_term_ids: [2987],
      vocabulary_id: 29,
      parent_id: null,
      id: 2299,
      _second_name: null,
      _name: 'Theology'
    }, {
      vocabulary_item_term_ids: [2991],
      vocabulary_id: 29,
      parent_id: null,
      id: 2303,
      _second_name: null,
      _name: 'Social Work'
    }, {
      vocabulary_item_term_ids: [2995],
      vocabulary_id: 29,
      parent_id: null,
      id: 2307,
      _second_name: null,
      _name: 'Science in Leadership '
    }, {
      vocabulary_item_term_ids: [2999],
      vocabulary_id: 29,
      parent_id: null,
      id: 2311,
      _second_name: null,
      _name: 'Science in Information Technology'
    }, {
      vocabulary_item_term_ids: [3003],
      vocabulary_id: 29,
      parent_id: null,
      id: 2315,
      _second_name: null,
      _name: 'Science in Finance'
    }, {
      vocabulary_item_term_ids: [3007],
      vocabulary_id: 29,
      parent_id: null,
      id: 2319,
      _second_name: null,
      _name: 'Sacred Music '
    }, {
      vocabulary_item_term_ids: [3011],
      vocabulary_id: 29,
      parent_id: null,
      id: 2323,
      _second_name: null,
      _name: 'Real Estate Development'
    }, {
      vocabulary_item_term_ids: [3015],
      vocabulary_id: 29,
      parent_id: null,
      id: 2327,
      _second_name: null,
      _name: 'Public Management'
    }, {
      vocabulary_item_term_ids: [3019],
      vocabulary_id: 29,
      parent_id: null,
      id: 2331,
      _second_name: null,
      _name: 'Professional Studies '
    }, {
      vocabulary_item_term_ids: [3023],
      vocabulary_id: 29,
      parent_id: null,
      id: 2335,
      _second_name: null,
      _name: 'Philosophy '
    }, {
      vocabulary_item_term_ids: [3133],
      vocabulary_id: 30,
      parent_id: null,
      id: 2445,
      _second_name: null,
      _name: 'Specialist'
    }, {
      vocabulary_item_term_ids: [3137],
      vocabulary_id: 30,
      parent_id: null,
      id: 2449,
      _second_name: null,
      _name: 'Bachelor'
    }, {
      vocabulary_item_term_ids: [3164],
      vocabulary_id: 35,
      parent_id: null,
      id: 2476,
      _second_name: null,
      _name: 'city'
    }, {
      vocabulary_item_term_ids: [2076],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1402,
      _second_name: null,
      _name: 'New Brunswick'
    }, {
      vocabulary_item_term_ids: [2044],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1373,
      _second_name: null,
      _name: 'Chhattisgarhi'
    }, {
      vocabulary_item_term_ids: [2033],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1367,
      _second_name: null,
      _name: 'Haryanvi'
    }, {
      vocabulary_item_term_ids: [2014],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1353,
      _second_name: null,
      _name: 'Pondicherrian'
    }, {
      vocabulary_item_term_ids: [1952],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1302,
      _second_name: null,
      _name: 'Marlborough'
    }, {
      vocabulary_item_term_ids: [1948],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1298,
      _second_name: null,
      _name: 'Southland'
    }, {
      vocabulary_item_term_ids: [718, 719],
      vocabulary_id: 9,
      parent_id: null,
      id: 432,
      _second_name: 'minutes',
      _name: 'min'
    }, {
      vocabulary_item_term_ids: [740, 741],
      vocabulary_id: 9,
      parent_id: null,
      id: 444,
      _second_name: 'millimetres',
      _name: 'mm'
    }, {
      vocabulary_item_term_ids: [765],
      vocabulary_id: 10,
      parent_id: null,
      id: 467,
      _second_name: null,
      _name: 'Jewelry and Gems'
    }, {
      vocabulary_item_term_ids: [789],
      vocabulary_id: 10,
      parent_id: null,
      id: 491,
      _second_name: null,
      _name: 'Natural History and Fine Minerals'
    }, {
      vocabulary_item_term_ids: [891, 892],
      vocabulary_id: 11,
      parent_id: null,
      id: 576,
      _second_name: 'Mali Franc',
      _name: 'MLF'
    }, {
      vocabulary_item_term_ids: [931, 932],
      vocabulary_id: 11,
      parent_id: null,
      id: 596,
      _second_name: 'XOF',
      _name: 'CFA Franc BCEAO'
    }, {
      vocabulary_item_term_ids: [971, 972],
      vocabulary_id: 11,
      parent_id: null,
      id: 616,
      _second_name: 'Azerbaijan Manat',
      _name: 'AYM'
    }, {
      vocabulary_item_term_ids: [1011, 1012],
      vocabulary_id: 11,
      parent_id: null,
      id: 636,
      _second_name: 'PGK',
      _name: 'Kina'
    }, {
      vocabulary_item_term_ids: [1051, 1052],
      vocabulary_id: 11,
      parent_id: null,
      id: 656,
      _second_name: 'Turkish Lira',
      _name: 'TRY'
    }, {
      vocabulary_item_term_ids: [1091, 1092],
      vocabulary_id: 11,
      parent_id: null,
      id: 676,
      _second_name: 'Burundi Franc',
      _name: 'BIF'
    }, {
      vocabulary_item_term_ids: [1131, 1132],
      vocabulary_id: 11,
      parent_id: null,
      id: 696,
      _second_name: 'Mozambique Escudo',
      _name: 'MZE'
    }, {
      vocabulary_item_term_ids: [1171, 1172],
      vocabulary_id: 11,
      parent_id: null,
      id: 716,
      _second_name: 'Ghana Cedi',
      _name: 'GHP'
    }, {
      vocabulary_item_term_ids: [1211, 1212],
      vocabulary_id: 11,
      parent_id: null,
      id: 736,
      _second_name: 'Mozambique Metical',
      _name: 'MZM'
    }, {
      vocabulary_item_term_ids: [1251, 1252],
      vocabulary_id: 11,
      parent_id: null,
      id: 756,
      _second_name: 'Rial Omani',
      _name: 'OMR'
    }, {
      vocabulary_item_term_ids: [1262, 1263],
      vocabulary_id: 11,
      parent_id: null,
      id: 761,
      _second_name: 'South Sudanese Pound',
      _name: 'SSP'
    }, {
      vocabulary_item_term_ids: [1302, 1303],
      vocabulary_id: 11,
      parent_id: null,
      id: 781,
      _second_name: 'VNC',
      _name: 'Old Dong'
    }, {
      vocabulary_item_term_ids: [1342, 1343],
      vocabulary_id: 11,
      parent_id: null,
      id: 801,
      _second_name: 'Peso',
      _name: 'ARY'
    }, {
      vocabulary_item_term_ids: [1382, 1383],
      vocabulary_id: 11,
      parent_id: null,
      id: 821,
      _second_name: 'Belarussian Ruble',
      _name: 'BYR'
    }, {
      vocabulary_item_term_ids: [1422, 1423],
      vocabulary_id: 11,
      parent_id: null,
      id: 841,
      _second_name: 'North Korean Won',
      _name: 'KPW'
    }, {
      vocabulary_item_term_ids: [1489, 1490, 1491],
      vocabulary_id: 17,
      parent_id: null,
      id: 900,
      _second_name: 'United States',
      _name: 'American'
    }, {
      vocabulary_item_term_ids: [1554],
      vocabulary_id: 17,
      parent_id: null,
      id: 950,
      _second_name: null,
      _name: 'Saint Lucian'
    }, {
      vocabulary_item_term_ids: [1558],
      vocabulary_id: 17,
      parent_id: null,
      id: 954,
      _second_name: null,
      _name: 'Saba'
    }, {
      vocabulary_item_term_ids: [1562, 1563],
      vocabulary_id: 17,
      parent_id: null,
      id: 958,
      _second_name: 'Réunionnais',
      _name: 'Réunionese'
    }, {
      vocabulary_item_term_ids: [1624],
      vocabulary_id: 17,
      parent_id: null,
      id: 1010,
      _second_name: null,
      _name: 'Lithuanian'
    }, {
      vocabulary_item_term_ids: [1628],
      vocabulary_id: 17,
      parent_id: null,
      id: 1014,
      _second_name: null,
      _name: 'Basotho'
    }, {
      vocabulary_item_term_ids: [1643],
      vocabulary_id: 17,
      parent_id: null,
      id: 1024,
      _second_name: null,
      _name: 'Kenyan'
    }, {
      vocabulary_item_term_ids: [1670],
      vocabulary_id: 17,
      parent_id: null,
      id: 1046,
      _second_name: null,
      _name: 'Guyanese'
    }, {
      vocabulary_item_term_ids: [1678],
      vocabulary_id: 17,
      parent_id: null,
      id: 1053,
      _second_name: null,
      _name: 'Greenlandic'
    }, {
      vocabulary_item_term_ids: [1714],
      vocabulary_id: 17,
      parent_id: null,
      id: 1087,
      _second_name: null,
      _name: 'Congolese'
    }, {
      vocabulary_item_term_ids: [1730],
      vocabulary_id: 17,
      parent_id: null,
      id: 1102,
      _second_name: null,
      _name: 'Burkinabé'
    }, {
      vocabulary_item_term_ids: [1734],
      vocabulary_id: 17,
      parent_id: null,
      id: 1106,
      _second_name: null,
      _name: 'Brazilian'
    }, {
      vocabulary_item_term_ids: [1777],
      vocabulary_id: 17,
      parent_id: null,
      id: 1144,
      _second_name: null,
      _name: 'Trojan'
    }, {
      vocabulary_item_term_ids: [1781],
      vocabulary_id: 17,
      parent_id: null,
      id: 1148,
      _second_name: null,
      _name: 'Thurian'
    }, {
      vocabulary_item_term_ids: [1785],
      vocabulary_id: 17,
      parent_id: null,
      id: 1152,
      _second_name: null,
      _name: 'Thessalian'
    }, {
      vocabulary_item_term_ids: [1789],
      vocabulary_id: 17,
      parent_id: null,
      id: 1156,
      _second_name: null,
      _name: 'Tenedian'
    }, {
      vocabulary_item_term_ids: [1793],
      vocabulary_id: 17,
      parent_id: null,
      id: 1159,
      _second_name: null,
      _name: 'Syracusan'
    }, {
      vocabulary_item_term_ids: [1797],
      vocabulary_id: 17,
      parent_id: null,
      id: 1163,
      _second_name: null,
      _name: 'Skopelitan'
    }, {
      vocabulary_item_term_ids: [1801],
      vocabulary_id: 17,
      parent_id: null,
      id: 1167,
      _second_name: null,
      _name: 'Sicilian'
    }, {
      vocabulary_item_term_ids: [1816],
      vocabulary_id: 17,
      parent_id: null,
      id: 1177,
      _second_name: null,
      _name: 'Sabine'
    }, {
      vocabulary_item_term_ids: [1820, 1821],
      vocabulary_id: 17,
      parent_id: null,
      id: 1181,
      _second_name: 'Rhegine',
      _name: 'Rhegian'
    }, {
      vocabulary_item_term_ids: [1860],
      vocabulary_id: 17,
      parent_id: null,
      id: 1220,
      _second_name: null,
      _name: 'Locrian'
    }, {
      vocabulary_item_term_ids: [1864],
      vocabulary_id: 17,
      parent_id: null,
      id: 1224,
      _second_name: null,
      _name: 'Larissan'
    }, {
      vocabulary_item_term_ids: [1915],
      vocabulary_id: 17,
      parent_id: null,
      id: 1269,
      _second_name: null,
      _name: 'Calabrian'
    }, {
      vocabulary_item_term_ids: [2090],
      vocabulary_id: 17,
      parent_id: null,
      id: 1415,
      _second_name: null,
      _name: 'Oceanian'
    }, {
      vocabulary_item_term_ids: [2100],
      vocabulary_id: 18,
      parent_id: null,
      id: 1425,
      _second_name: null,
      _name: 'style of'
    }, {
      vocabulary_item_term_ids: [2104],
      vocabulary_id: 18,
      parent_id: null,
      id: 1429,
      _second_name: null,
      _name: 'follower of'
    }, {
      vocabulary_item_term_ids: [2108],
      vocabulary_id: 18,
      parent_id: null,
      id: 1433,
      _second_name: null,
      _name: 'atelier of'
    }, {
      vocabulary_item_term_ids: [2112],
      vocabulary_id: 18,
      parent_id: null,
      id: 1437,
      _second_name: null,
      _name: 'attributed to'
    }, {
      vocabulary_item_term_ids: [2965],
      vocabulary_id: 26,
      parent_id: null,
      id: 2277,
      _second_name: null,
      _name: 'Assay hallmark'
    }, {
      vocabulary_item_term_ids: [2969],
      vocabulary_id: 26,
      parent_id: null,
      id: 2281,
      _second_name: null,
      _name: 'Title'
    }, {
      vocabulary_item_term_ids: [2086],
      vocabulary_id: 17,
      parent_id: 1125,
      id: 1411,
      _second_name: null,
      _name: 'Northern Territory'
    }, {
      vocabulary_item_term_ids: [2082],
      vocabulary_id: 17,
      parent_id: 1125,
      id: 1407,
      _second_name: null,
      _name: 'Victorian'
    }, {
      vocabulary_item_term_ids: [853],
      vocabulary_id: 10,
      parent_id: 556,
      id: 555,
      _second_name: null,
      _name: 'Couture and Vintage'
    }, {
      vocabulary_item_term_ids: [849],
      vocabulary_id: 10,
      parent_id: 509,
      id: 551,
      _second_name: null,
      _name: 'Sculptures'
    }, {
      vocabulary_item_term_ids: [845],
      vocabulary_id: 10,
      parent_id: 550,
      id: 547,
      _second_name: null,
      _name: 'Military Firearms'
    }, {
      vocabulary_item_term_ids: [841],
      vocabulary_id: 10,
      parent_id: 505,
      id: 543,
      _second_name: null,
      _name: 'Transportation Memorabilia'
    }, {
      vocabulary_item_term_ids: [837],
      vocabulary_id: 10,
      parent_id: 509,
      id: 539,
      _second_name: null,
      _name: 'Collages'
    }, {
      vocabulary_item_term_ids: [833],
      vocabulary_id: 10,
      parent_id: 505,
      id: 535,
      _second_name: null,
      _name: 'Pens'
    }, {
      vocabulary_item_term_ids: [829],
      vocabulary_id: 10,
      parent_id: 509,
      id: 531,
      _second_name: null,
      _name: 'Digital Images'
    }, {
      vocabulary_item_term_ids: [825],
      vocabulary_id: 10,
      parent_id: 549,
      id: 527,
      _second_name: null,
      _name: 'Stamps'
    }, {
      vocabulary_item_term_ids: [821],
      vocabulary_id: 10,
      parent_id: 509,
      id: 523,
      _second_name: null,
      _name: 'Calligraphy'
    }, {
      vocabulary_item_term_ids: [817],
      vocabulary_id: 10,
      parent_id: 524,
      id: 519,
      _second_name: null,
      _name: 'Architectural Elements'
    }, {
      vocabulary_item_term_ids: [813],
      vocabulary_id: 10,
      parent_id: 509,
      id: 515,
      _second_name: null,
      _name: 'Multimedia'
    }, {
      vocabulary_item_term_ids: [809],
      vocabulary_id: 10,
      parent_id: 556,
      id: 511,
      _second_name: null,
      _name: 'Costumes and Historical Uniforms'
    }, {
      vocabulary_item_term_ids: [805],
      vocabulary_id: 10,
      parent_id: 509,
      id: 507,
      _second_name: null,
      _name: 'Murals'
    }, {
      vocabulary_item_term_ids: [801],
      vocabulary_id: 10,
      parent_id: 509,
      id: 503,
      _second_name: null,
      _name: 'Prints'
    }, {
      vocabulary_item_term_ids: [797],
      vocabulary_id: 10,
      parent_id: 505,
      id: 499,
      _second_name: null,
      _name: 'Sports Memorabilia'
    }, {
      vocabulary_item_term_ids: [793],
      vocabulary_id: 10,
      parent_id: 505,
      id: 495,
      _second_name: null,
      _name: 'Cinema, Theater, and Television'
    }, {
      vocabulary_item_term_ids: [785],
      vocabulary_id: 10,
      parent_id: 466,
      id: 487,
      _second_name: null,
      _name: 'Brass and Woodwinds'
    }, {
      vocabulary_item_term_ids: [781],
      vocabulary_id: 10,
      parent_id: 466,
      id: 483,
      _second_name: null,
      _name: 'Vintage Electric Instruments'
    }, {
      vocabulary_item_term_ids: [777],
      vocabulary_id: 10,
      parent_id: 491,
      id: 479,
      _second_name: null,
      _name: 'Fossils'
    }, {
      vocabulary_item_term_ids: [773],
      vocabulary_id: 10,
      parent_id: 448,
      id: 475,
      _second_name: null,
      _name: 'Equipment and Machines'
    }, {
      vocabulary_item_term_ids: [769],
      vocabulary_id: 10,
      parent_id: 448,
      id: 471,
      _second_name: null,
      _name: 'Scientific Instruments'
    }, {
      vocabulary_item_term_ids: [761],
      vocabulary_id: 10,
      parent_id: 448,
      id: 463,
      _second_name: null,
      _name: 'Space History'
    }, {
      vocabulary_item_term_ids: [757],
      vocabulary_id: 10,
      parent_id: 467,
      id: 459,
      _second_name: null,
      _name: 'Jewelry'
    }, {
      vocabulary_item_term_ids: [753],
      vocabulary_id: 10,
      parent_id: 481,
      id: 455,
      _second_name: null,
      _name: 'Motorcyles'
    }, {
      vocabulary_item_term_ids: [749],
      vocabulary_id: 10,
      parent_id: 524,
      id: 451,
      _second_name: null,
      _name: 'Furniture'
    }, {
      vocabulary_item_term_ids: [745],
      vocabulary_id: 10,
      parent_id: 481,
      id: 447,
      _second_name: null,
      _name: 'Boats'
    }, {
      vocabulary_item_term_ids: [709, 710],
      vocabulary_id: 9,
      parent_id: null,
      id: 427,
      _second_name: 'carats',
      _name: 'ct'
    }, {
      vocabulary_item_term_ids: [720, 721],
      vocabulary_id: 9,
      parent_id: null,
      id: 433,
      _second_name: 'seconds',
      _name: 's'
    }, {
      vocabulary_item_term_ids: [742, 743],
      vocabulary_id: 9,
      parent_id: null,
      id: 445,
      _second_name: 'inches',
      _name: 'in'
    }, {
      vocabulary_item_term_ids: [893, 894],
      vocabulary_id: 11,
      parent_id: null,
      id: 577,
      _second_name: 'Latvian Lats',
      _name: 'LVL'
    }, {
      vocabulary_item_term_ids: [934, 933],
      vocabulary_id: 11,
      parent_id: null,
      id: 597,
      _second_name: 'US Dollar',
      _name: 'USD'
    }, {
      vocabulary_item_term_ids: [973, 974],
      vocabulary_id: 11,
      parent_id: null,
      id: 617,
      _second_name: 'Seychelles Rupee',
      _name: 'SCR'
    }, {
      vocabulary_item_term_ids: [1013, 1014],
      vocabulary_id: 11,
      parent_id: null,
      id: 637,
      _second_name: 'Financial Franc',
      _name: 'BEL'
    }, {
      vocabulary_item_term_ids: [1053, 1054],
      vocabulary_id: 11,
      parent_id: null,
      id: 657,
      _second_name: 'New Zealand Dollar',
      _name: 'NZD'
    }, {
      vocabulary_item_term_ids: [1093, 1094],
      vocabulary_id: 11,
      parent_id: null,
      id: 677,
      _second_name: 'Netherlands Antillean Guilder',
      _name: 'ANG'
    }, {
      vocabulary_item_term_ids: [1133, 1134],
      vocabulary_id: 11,
      parent_id: null,
      id: 697,
      _second_name: 'Australian Dollar',
      _name: 'AUD'
    }, {
      vocabulary_item_term_ids: [1173, 1174],
      vocabulary_id: 11,
      parent_id: null,
      id: 717,
      _second_name: 'Belgian Franc',
      _name: 'BEF'
    }, {
      vocabulary_item_term_ids: [1213, 1214],
      vocabulary_id: 11,
      parent_id: null,
      id: 737,
      _second_name: 'Bahraini Dinar',
      _name: 'BHD'
    }, {
      vocabulary_item_term_ids: [1253, 1254],
      vocabulary_id: 11,
      parent_id: null,
      id: 757,
      _second_name: 'Mexican Peso',
      _name: 'MXN'
    }, {
      vocabulary_item_term_ids: [1264, 1265],
      vocabulary_id: 11,
      parent_id: null,
      id: 762,
      _second_name: 'Russian Ruble',
      _name: 'RUB'
    }, {
      vocabulary_item_term_ids: [1304, 1305],
      vocabulary_id: 11,
      parent_id: null,
      id: 782,
      _second_name: 'Romanian Leu',
      _name: 'RON'
    }, {
      vocabulary_item_term_ids: [1344, 1345],
      vocabulary_id: 11,
      parent_id: null,
      id: 802,
      _second_name: 'Cyprus Pound',
      _name: 'CYP'
    }, {
      vocabulary_item_term_ids: [1384, 1385],
      vocabulary_id: 11,
      parent_id: null,
      id: 822,
      _second_name: 'Aruban Florin',
      _name: 'AWG'
    }, {
      vocabulary_item_term_ids: [1424, 1425],
      vocabulary_id: 11,
      parent_id: null,
      id: 842,
      _second_name: 'Riel',
      _name: 'KHR'
    }, {
      vocabulary_item_term_ids: [1441],
      vocabulary_id: 12,
      parent_id: null,
      id: 856,
      _second_name: null,
      _name: 'length'
    }, {
      vocabulary_item_term_ids: [1445],
      vocabulary_id: 12,
      parent_id: null,
      id: 860,
      _second_name: null,
      _name: 'width'
    }, {
      vocabulary_item_term_ids: [1472, 1473, 1474],
      vocabulary_id: 17,
      parent_id: null,
      id: 887,
      _second_name: 'Sahraouian',
      _name: 'Sahrawi'
    }, {
      vocabulary_item_term_ids: [1498],
      vocabulary_id: 17,
      parent_id: null,
      id: 903,
      _second_name: null,
      _name: 'Ukrainian'
    }, {
      vocabulary_item_term_ids: [1502],
      vocabulary_id: 17,
      parent_id: null,
      id: 907,
      _second_name: null,
      _name: 'Turkmen'
    }, {
      vocabulary_item_term_ids: [1541],
      vocabulary_id: 17,
      parent_id: null,
      id: 941,
      _second_name: null,
      _name: 'Senegalese'
    }, {
      vocabulary_item_term_ids: [1564],
      vocabulary_id: 17,
      parent_id: null,
      id: 959,
      _second_name: null,
      _name: 'Qatari'
    }, {
      vocabulary_item_term_ids: [1568],
      vocabulary_id: 17,
      parent_id: null,
      id: 963,
      _second_name: null,
      _name: 'Pitcairn Island'
    }, {
      vocabulary_item_term_ids: [1649],
      vocabulary_id: 17,
      parent_id: null,
      id: 1029,
      _second_name: null,
      _name: 'Jan Mayen'
    }, {
      vocabulary_item_term_ids: [1653],
      vocabulary_id: 17,
      parent_id: null,
      id: 1033,
      _second_name: null,
      _name: 'Israeli'
    }, {
      vocabulary_item_term_ids: [1657, 1658],
      vocabulary_id: 17,
      parent_id: null,
      id: 1037,
      _second_name: 'Persian',
      _name: 'Iranian'
    }, {
      vocabulary_item_term_ids: [1684],
      vocabulary_id: 17,
      parent_id: null,
      id: 1058,
      _second_name: null,
      _name: 'Georgian'
    }, {
      vocabulary_item_term_ids: [1688],
      vocabulary_id: 17,
      parent_id: null,
      id: 1062,
      _second_name: null,
      _name: 'French Polynesian'
    }, {
      vocabulary_item_term_ids: [1692],
      vocabulary_id: 17,
      parent_id: null,
      id: 1066,
      _second_name: null,
      _name: 'Fijian'
    }, {
      vocabulary_item_term_ids: [1696],
      vocabulary_id: 17,
      parent_id: null,
      id: 1070,
      _second_name: null,
      _name: 'Ethiopian'
    }, {
      vocabulary_item_term_ids: [1704],
      vocabulary_id: 17,
      parent_id: null,
      id: 1077,
      _second_name: null,
      _name: 'Dominican'
    }, {
      vocabulary_item_term_ids: [1708, 1896],
      vocabulary_id: 17,
      parent_id: null,
      id: 1081,
      _second_name: 'Cypriote',
      _name: 'Cypriot'
    }, {
      vocabulary_item_term_ids: [1720],
      vocabulary_id: 17,
      parent_id: null,
      id: 1092,
      _second_name: null,
      _name: 'Chinese'
    }, {
      vocabulary_item_term_ids: [1724],
      vocabulary_id: 17,
      parent_id: null,
      id: 1096,
      _second_name: null,
      _name: 'Caymanian'
    }, {
      vocabulary_item_term_ids: [1728],
      vocabulary_id: 17,
      parent_id: null,
      id: 1100,
      _second_name: null,
      _name: 'Cabo Verdean'
    }, {
      vocabulary_item_term_ids: [1740],
      vocabulary_id: 17,
      parent_id: null,
      id: 1111,
      _second_name: null,
      _name: 'Bonaire'
    }, {
      vocabulary_item_term_ids: [1822],
      vocabulary_id: 17,
      parent_id: null,
      id: 1182,
      _second_name: null,
      _name: 'Pylosian'
    }, {
      vocabulary_item_term_ids: [1826],
      vocabulary_id: 17,
      parent_id: null,
      id: 1186,
      _second_name: null,
      _name: 'Phoenician'
    }, {
      vocabulary_item_term_ids: [1830],
      vocabulary_id: 17,
      parent_id: null,
      id: 1190,
      _second_name: null,
      _name: 'Persian'
    }, {
      vocabulary_item_term_ids: [1834],
      vocabulary_id: 17,
      parent_id: null,
      id: 1194,
      _second_name: null,
      _name: 'Parian'
    }, {
      vocabulary_item_term_ids: [1838],
      vocabulary_id: 17,
      parent_id: null,
      id: 1198,
      _second_name: null,
      _name: 'Oiniadan'
    }, {
      vocabulary_item_term_ids: [1842],
      vocabulary_id: 17,
      parent_id: null,
      id: 1202,
      _second_name: null,
      _name: 'Naxian'
    }, {
      vocabulary_item_term_ids: [1846],
      vocabulary_id: 17,
      parent_id: null,
      id: 1206,
      _second_name: null,
      _name: 'Moravian'
    }, {
      vocabulary_item_term_ids: [1850],
      vocabulary_id: 17,
      parent_id: null,
      id: 1210,
      _second_name: null,
      _name: 'Mesopotamian'
    }, {
      vocabulary_item_term_ids: [1854],
      vocabulary_id: 17,
      parent_id: null,
      id: 1214,
      _second_name: null,
      _name: 'Median'
    }, {
      vocabulary_item_term_ids: [1870],
      vocabulary_id: 17,
      parent_id: null,
      id: 1229,
      _second_name: null,
      _name: 'Ionian'
    }, {
      vocabulary_item_term_ids: [1921, 1922],
      vocabulary_id: 17,
      parent_id: null,
      id: 1274,
      _second_name: 'Beotian',
      _name: 'Boeotian'
    }, {
      vocabulary_item_term_ids: [2978],
      vocabulary_id: 28,
      parent_id: null,
      id: 2290,
      _second_name: null,
      _name: 'Premium'
    }, {
      vocabulary_item_term_ids: [3116],
      vocabulary_id: 29,
      parent_id: null,
      id: 2428,
      _second_name: null,
      _name: 'Engineering Technology'
    }, {
      vocabulary_item_term_ids: [3120],
      vocabulary_id: 29,
      parent_id: null,
      id: 2432,
      _second_name: null,
      _name: 'Applied Arts'
    }, {
      vocabulary_item_term_ids: [3124],
      vocabulary_id: 29,
      parent_id: null,
      id: 2436,
      _second_name: null,
      _name: 'Industrial Technology'
    }, {
      vocabulary_item_term_ids: [3128],
      vocabulary_id: 29,
      parent_id: null,
      id: 2440,
      _second_name: null,
      _name: 'Baccalaureate Studies'
    }, {
      vocabulary_item_term_ids: [3132],
      vocabulary_id: 29,
      parent_id: null,
      id: 2444,
      _second_name: null,
      _name: 'Electronics Engineering Technology'
    }, {
      vocabulary_item_term_ids: [2018, 2019],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1355,
      _second_name: 'Naga',
      _name: 'Nagalandese'
    }, {
      vocabulary_item_term_ids: [1980, 1981],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1327,
      _second_name: 'Peninsular',
      _name: 'West Malaysian'
    }, {
      vocabulary_item_term_ids: [1976],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1323,
      _second_name: null,
      _name: 'Campechan'
    }, {
      vocabulary_item_term_ids: [1972],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1319,
      _second_name: null,
      _name: 'Durangoan'
    }, {
      vocabulary_item_term_ids: [1964],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1312,
      _second_name: null,
      _name: 'Sinaloan'
    }, {
      vocabulary_item_term_ids: [1960],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1308,
      _second_name: null,
      _name: 'Auckland'
    }, {
      vocabulary_item_term_ids: [711, 712],
      vocabulary_id: 9,
      parent_id: null,
      id: 428,
      _second_name: 'karat',
      _name: 'kt'
    }, {
      vocabulary_item_term_ids: [722, 723],
      vocabulary_id: 9,
      parent_id: null,
      id: 434,
      _second_name: 'milliseconds',
      _name: 'ms'
    }, {
      vocabulary_item_term_ids: [895, 896],
      vocabulary_id: 11,
      parent_id: null,
      id: 578,
      _second_name: 'Russian Ruble',
      _name: 'RUR'
    }, {
      vocabulary_item_term_ids: [935, 936],
      vocabulary_id: 11,
      parent_id: null,
      id: 598,
      _second_name: 'SVC',
      _name: 'El Salvador Colon'
    }, {
      vocabulary_item_term_ids: [975, 976],
      vocabulary_id: 11,
      parent_id: null,
      id: 618,
      _second_name: 'Austral',
      _name: 'ARA'
    }, {
      vocabulary_item_term_ids: [1015, 1016],
      vocabulary_id: 11,
      parent_id: null,
      id: 638,
      _second_name: 'Zambian Kwacha',
      _name: 'ZMW'
    }, {
      vocabulary_item_term_ids: [1055, 1056],
      vocabulary_id: 11,
      parent_id: null,
      id: 658,
      _second_name: 'NIO',
      _name: 'Cordoba Oro'
    }, {
      vocabulary_item_term_ids: [1095, 1096],
      vocabulary_id: 11,
      parent_id: null,
      id: 678,
      _second_name: 'Luxembourg Financial Franc',
      _name: 'LUL'
    }, {
      vocabulary_item_term_ids: [1135, 1136],
      vocabulary_id: 11,
      parent_id: null,
      id: 698,
      _second_name: 'UAE Dirham',
      _name: 'AED'
    }, {
      vocabulary_item_term_ids: [1175, 1176],
      vocabulary_id: 11,
      parent_id: null,
      id: 718,
      _second_name: 'Convertible Franc',
      _name: 'BEC'
    }, {
      vocabulary_item_term_ids: [1215, 1216],
      vocabulary_id: 11,
      parent_id: null,
      id: 738,
      _second_name: 'Old Krona',
      _name: 'ISJ'
    }, {
      vocabulary_item_term_ids: [1255, 1256],
      vocabulary_id: 11,
      parent_id: null,
      id: 758,
      _second_name: 'Tenge',
      _name: 'KZT'
    }, {
      vocabulary_item_term_ids: [1266, 1267],
      vocabulary_id: 11,
      parent_id: null,
      id: 763,
      _second_name: 'Cabo Verde Escudo',
      _name: 'CVE'
    }, {
      vocabulary_item_term_ids: [1306, 1307],
      vocabulary_id: 11,
      parent_id: null,
      id: 783,
      _second_name: 'Mexican Unidad de Inversion (UDI)',
      _name: 'MXV'
    }, {
      vocabulary_item_term_ids: [1346, 1347],
      vocabulary_id: 11,
      parent_id: null,
      id: 803,
      _second_name: 'XUA',
      _name: 'ADB Unit of Account'
    }, {
      vocabulary_item_term_ids: [1386, 1387],
      vocabulary_id: 11,
      parent_id: null,
      id: 823,
      _second_name: 'Armenian Dram',
      _name: 'AMD'
    }, {
      vocabulary_item_term_ids: [1426, 1427],
      vocabulary_id: 11,
      parent_id: null,
      id: 843,
      _second_name: 'Guinea Franc',
      _name: 'GNF'
    }, {
      vocabulary_item_term_ids: [1431],
      vocabulary_id: 12,
      parent_id: null,
      id: 846,
      _second_name: null,
      _name: 'fineness'
    }, {
      vocabulary_item_term_ids: [1435],
      vocabulary_id: 12,
      parent_id: null,
      id: 850,
      _second_name: null,
      _name: 'odometer'
    }, {
      vocabulary_item_term_ids: [1454],
      vocabulary_id: 14,
      parent_id: null,
      id: 869,
      _second_name: null,
      _name: 'Available on redshift'
    }, {
      vocabulary_item_term_ids: [1458],
      vocabulary_id: 14,
      parent_id: null,
      id: 873,
      _second_name: null,
      _name: 'Catalog unavailable'
    }, {
      vocabulary_item_term_ids: [1508],
      vocabulary_id: 17,
      parent_id: null,
      id: 912,
      _second_name: null,
      _name: 'Tokelauan'
    }, {
      vocabulary_item_term_ids: [1512],
      vocabulary_id: 17,
      parent_id: null,
      id: 916,
      _second_name: null,
      _name: 'Tanzanian'
    }, {
      vocabulary_item_term_ids: [1516],
      vocabulary_id: 17,
      parent_id: null,
      id: 920,
      _second_name: null,
      _name: 'Swedish'
    }, {
      vocabulary_item_term_ids: [1520],
      vocabulary_id: 17,
      parent_id: null,
      id: 924,
      _second_name: null,
      _name: 'Sudanese'
    }, {
      vocabulary_item_term_ids: [1524],
      vocabulary_id: 17,
      parent_id: null,
      id: 928,
      _second_name: null,
      _name: 'South Ossetian'
    }, {
      vocabulary_item_term_ids: [1597],
      vocabulary_id: 17,
      parent_id: null,
      id: 986,
      _second_name: null,
      _name: 'Burmese'
    }, {
      vocabulary_item_term_ids: [1601],
      vocabulary_id: 17,
      parent_id: null,
      id: 990,
      _second_name: null,
      _name: 'Montenegrin'
    }, {
      vocabulary_item_term_ids: [1659],
      vocabulary_id: 17,
      parent_id: null,
      id: 1038,
      _second_name: null,
      _name: 'Indonesian'
    }, {
      vocabulary_item_term_ids: [1702],
      vocabulary_id: 17,
      parent_id: null,
      id: 1075,
      _second_name: null,
      _name: 'Egyptian'
    }, {
      vocabulary_item_term_ids: [1876],
      vocabulary_id: 17,
      parent_id: null,
      id: 1234,
      _second_name: null,
      _name: 'Gallaecus'
    }, {
      vocabulary_item_term_ids: [1880],
      vocabulary_id: 17,
      parent_id: null,
      id: 1238,
      _second_name: null,
      _name: 'Eretrian'
    }, {
      vocabulary_item_term_ids: [1884],
      vocabulary_id: 17,
      parent_id: null,
      id: 1242,
      _second_name: null,
      _name: 'Ephesian'
    }, {
      vocabulary_item_term_ids: [1927],
      vocabulary_id: 17,
      parent_id: null,
      id: 1279,
      _second_name: null,
      _name: 'Argive'
    }, {
      vocabulary_item_term_ids: [1931],
      vocabulary_id: 17,
      parent_id: null,
      id: 1283,
      _second_name: null,
      _name: 'Andalusian'
    }, {
      vocabulary_item_term_ids: [1935],
      vocabulary_id: 17,
      parent_id: null,
      id: 1287,
      _second_name: null,
      _name: 'Achaean'
    }, {
      vocabulary_item_term_ids: [2099],
      vocabulary_id: 17,
      parent_id: null,
      id: 1424,
      _second_name: null,
      _name: 'African'
    }, {
      vocabulary_item_term_ids: [2956],
      vocabulary_id: 25,
      parent_id: null,
      id: 2268,
      _second_name: null,
      _name: 'linkedin'
    }, {
      vocabulary_item_term_ids: [2960],
      vocabulary_id: 25,
      parent_id: null,
      id: 2272,
      _second_name: null,
      _name: 'mobile'
    }, {
      vocabulary_item_term_ids: [3078],
      vocabulary_id: 29,
      parent_id: null,
      id: 2390,
      _second_name: null,
      _name: 'Public Affairs and Policy Management'
    }, {
      vocabulary_item_term_ids: [3082],
      vocabulary_id: 29,
      parent_id: null,
      id: 2394,
      _second_name: null,
      _name: 'Talmudic Law '
    }, {
      vocabulary_item_term_ids: [3086],
      vocabulary_id: 29,
      parent_id: null,
      id: 2398,
      _second_name: null,
      _name: 'Science and/with education degree '
    }, {
      vocabulary_item_term_ids: [3090],
      vocabulary_id: 29,
      parent_id: null,
      id: 2402,
      _second_name: null,
      _name: 'Music Education'
    }, {
      vocabulary_item_term_ids: [3094],
      vocabulary_id: 29,
      parent_id: null,
      id: 2406,
      _second_name: null,
      _name: 'Applied Studies '
    }, {
      vocabulary_item_term_ids: [3098],
      vocabulary_id: 29,
      parent_id: null,
      id: 2410,
      _second_name: null,
      _name: 'Integrated studies '
    }, {
      vocabulary_item_term_ids: [3102],
      vocabulary_id: 29,
      parent_id: null,
      id: 2414,
      _second_name: null,
      _name: 'Kinesiology '
    }, {
      vocabulary_item_term_ids: [3106],
      vocabulary_id: 29,
      parent_id: null,
      id: 2418,
      _second_name: null,
      _name: 'Business Information Systems'
    }, {
      vocabulary_item_term_ids: [3110],
      vocabulary_id: 29,
      parent_id: null,
      id: 2422,
      _second_name: null,
      _name: 'Comptrolling '
    }, {
      vocabulary_item_term_ids: [3114],
      vocabulary_id: 29,
      parent_id: null,
      id: 2426,
      _second_name: null,
      _name: 'Administrative Studies'
    }, {
      vocabulary_item_term_ids: [3141],
      vocabulary_id: 31,
      parent_id: null,
      id: 2453,
      _second_name: null,
      _name: 'customizer'
    }, {
      vocabulary_item_term_ids: [2067],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1395,
      _second_name: null,
      _name: 'Quebec'
    }, {
      vocabulary_item_term_ids: [1923],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1275,
      _second_name: null,
      _name: 'Bavarian'
    }, {
      vocabulary_item_term_ids: [2055],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1384,
      _second_name: null,
      _name: 'Prussian'
    }, {
      vocabulary_item_term_ids: [2024],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1360,
      _second_name: null,
      _name: 'Madhya Pradeshi'
    }, {
      vocabulary_item_term_ids: [2020],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1356,
      _second_name: null,
      _name: 'Mizo'
    }, {
      vocabulary_item_term_ids: [2005],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1346,
      _second_name: null,
      _name: 'Uttar Pradeshi'
    }, {
      vocabulary_item_term_ids: [1998, 2002, 1999, 2003, 2000, 2001],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1344,
      _second_name: 'West Bengali ',
      _name: 'Bengali '
    }, {
      vocabulary_item_term_ids: [1994],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1340,
      _second_name: null,
      _name: 'Kuala Lumpurian'
    }, {
      vocabulary_item_term_ids: [1990],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1336,
      _second_name: null,
      _name: 'Pahangite'
    }, {
      vocabulary_item_term_ids: [1986],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1332,
      _second_name: null,
      _name: 'Putrajayan'
    }, {
      vocabulary_item_term_ids: [1982],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1328,
      _second_name: null,
      _name: 'Terengganuan'
    }, {
      vocabulary_item_term_ids: [1939, 1940],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1291,
      _second_name: 'Mainland',
      _name: 'South Island'
    }, {
      vocabulary_item_term_ids: [713, 714],
      vocabulary_id: 9,
      parent_id: null,
      id: 429,
      _second_name: 'atmospheres',
      _name: 'atm'
    }, {
      vocabulary_item_term_ids: [724],
      vocabulary_id: 9,
      parent_id: null,
      id: 435,
      _second_name: null,
      _name: 'km/h'
    }, {
      vocabulary_item_term_ids: [857, 858],
      vocabulary_id: 11,
      parent_id: null,
      id: 559,
      _second_name: 'ZRN',
      _name: 'New Zaire'
    }, {
      vocabulary_item_term_ids: [897, 898],
      vocabulary_id: 11,
      parent_id: null,
      id: 579,
      _second_name: 'Pound',
      _name: 'ILP'
    }, {
      vocabulary_item_term_ids: [937, 938],
      vocabulary_id: 11,
      parent_id: null,
      id: 599,
      _second_name: 'Surinam Dollar',
      _name: 'SRD'
    }, {
      vocabulary_item_term_ids: [977, 978],
      vocabulary_id: 11,
      parent_id: null,
      id: 619,
      _second_name: 'PYG',
      _name: 'Guarani'
    }, {
      vocabulary_item_term_ids: [1017, 1018],
      vocabulary_id: 11,
      parent_id: null,
      id: 639,
      _second_name: 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
      _name: 'UYI'
    }, {
      vocabulary_item_term_ids: [1057, 1058],
      vocabulary_id: 11,
      parent_id: null,
      id: 659,
      _second_name: 'MMK',
      _name: 'Kyat'
    }, {
      vocabulary_item_term_ids: [1097, 1098],
      vocabulary_id: 11,
      parent_id: null,
      id: 679,
      _second_name: 'Koruna',
      _name: 'CSK'
    }, {
      vocabulary_item_term_ids: [1137, 1138],
      vocabulary_id: 11,
      parent_id: null,
      id: 699,
      _second_name: 'Zimbabwe Dollar',
      _name: 'ZWR'
    }, {
      vocabulary_item_term_ids: [1177, 1178],
      vocabulary_id: 11,
      parent_id: null,
      id: 719,
      _second_name: 'Schilling',
      _name: 'ATS'
    }, {
      vocabulary_item_term_ids: [1217, 1218],
      vocabulary_id: 11,
      parent_id: null,
      id: 739,
      _second_name: 'Krona A/53',
      _name: 'CSJ'
    }, {
      vocabulary_item_term_ids: [1257, 1258],
      vocabulary_id: 11,
      parent_id: null,
      id: 759,
      _second_name: 'VND',
      _name: 'Dong'
    }, {
      vocabulary_item_term_ids: [1268, 1269],
      vocabulary_id: 11,
      parent_id: null,
      id: 764,
      _second_name: 'PEN',
      _name: 'Nuevo Sol'
    }, {
      vocabulary_item_term_ids: [1308, 1309],
      vocabulary_id: 11,
      parent_id: null,
      id: 784,
      _second_name: 'MWK',
      _name: 'Kwacha'
    }, {
      vocabulary_item_term_ids: [1348, 1349],
      vocabulary_id: 11,
      parent_id: null,
      id: 804,
      _second_name: 'Peoples Bank Dollar',
      _name: 'CNX'
    }, {
      vocabulary_item_term_ids: [1388, 1389],
      vocabulary_id: 11,
      parent_id: null,
      id: 824,
      _second_name: 'Tajik Ruble',
      _name: 'TJR'
    }, {
      vocabulary_item_term_ids: [1428, 1429],
      vocabulary_id: 11,
      parent_id: null,
      id: 844,
      _second_name: 'Yuan Renminbi',
      _name: 'CNY'
    }, {
      vocabulary_item_term_ids: [1467],
      vocabulary_id: 16,
      parent_id: null,
      id: 882,
      _second_name: null,
      _name: 'Imperial'
    }, {
      vocabulary_item_term_ids: [1480],
      vocabulary_id: 17,
      parent_id: null,
      id: 893,
      _second_name: null,
      _name: 'British Virgin Island'
    }, {
      vocabulary_item_term_ids: [1484, 1485],
      vocabulary_id: 17,
      parent_id: null,
      id: 897,
      _second_name: 'Ni-Vanuatu',
      _name: 'Vanuatuan'
    }, {
      vocabulary_item_term_ids: [1530, 1531],
      vocabulary_id: 17,
      parent_id: null,
      id: 933,
      _second_name: 'Slovene',
      _name: 'Slovenian'
    }, {
      vocabulary_item_term_ids: [1549, 1550],
      vocabulary_id: 17,
      parent_id: null,
      id: 947,
      _second_name: 'Vincentian',
      _name: 'Saint Vincentian'
    }, {
      vocabulary_item_term_ids: [1576],
      vocabulary_id: 17,
      parent_id: null,
      id: 969,
      _second_name: null,
      _name: 'Palestinian'
    }, {
      vocabulary_item_term_ids: [1580],
      vocabulary_id: 17,
      parent_id: null,
      id: 973,
      _second_name: null,
      _name: 'Norwegian'
    }, {
      vocabulary_item_term_ids: [1584],
      vocabulary_id: 17,
      parent_id: null,
      id: 977,
      _second_name: null,
      _name: 'Niuean'
    }, {
      vocabulary_item_term_ids: [1588, 1589],
      vocabulary_id: 17,
      parent_id: null,
      id: 981,
      _second_name: 'NZ',
      _name: 'New Zealand'
    }, {
      vocabulary_item_term_ids: [1611, 1612],
      vocabulary_id: 17,
      parent_id: null,
      id: 999,
      _second_name: 'Martinican',
      _name: 'Martiniquais'
    }, {
      vocabulary_item_term_ids: [1748],
      vocabulary_id: 17,
      parent_id: null,
      id: 1117,
      _second_name: null,
      _name: 'Belgian'
    }, {
      vocabulary_item_term_ids: [1752],
      vocabulary_id: 17,
      parent_id: null,
      id: 1121,
      _second_name: null,
      _name: 'Bahraini'
    }, {
      vocabulary_item_term_ids: [1890],
      vocabulary_id: 17,
      parent_id: null,
      id: 1247,
      _second_name: null,
      _name: 'Dodecanesian'
    }, {
      vocabulary_item_term_ids: [1894],
      vocabulary_id: 17,
      parent_id: null,
      id: 1251,
      _second_name: null,
      _name: 'Cyrenian'
    }, {
      vocabulary_item_term_ids: [1898],
      vocabulary_id: 17,
      parent_id: null,
      id: 1254,
      _second_name: null,
      _name: 'Crotonian'
    }, {
      vocabulary_item_term_ids: [1902],
      vocabulary_id: 17,
      parent_id: null,
      id: 1258,
      _second_name: null,
      _name: 'Consentian'
    }, {
      vocabulary_item_term_ids: [1906],
      vocabulary_id: 17,
      parent_id: null,
      id: 1262,
      _second_name: null,
      _name: 'Chalcidian'
    }, {
      vocabulary_item_term_ids: [2097],
      vocabulary_id: 17,
      parent_id: null,
      id: 1422,
      _second_name: null,
      _name: 'Americana'
    }, {
      vocabulary_item_term_ids: [2116],
      vocabulary_id: 19,
      parent_id: null,
      id: 1441,
      _second_name: null,
      _name: 'pseudonym'
    }, {
      vocabulary_item_term_ids: [2307],
      vocabulary_id: 21,
      parent_id: null,
      id: 1632,
      _second_name: null,
      _name: 'journal article'
    }, {
      vocabulary_item_term_ids: [2311],
      vocabulary_id: 21,
      parent_id: null,
      id: 1636,
      _second_name: null,
      _name: 'exhibition checklist'
    }, {
      vocabulary_item_term_ids: [2315],
      vocabulary_id: 21,
      parent_id: null,
      id: 1640,
      _second_name: null,
      _name: 'diary'
    }, {
      vocabulary_item_term_ids: [2319],
      vocabulary_id: 21,
      parent_id: null,
      id: 1644,
      _second_name: null,
      _name: 'guide book'
    }, {
      vocabulary_item_term_ids: [2323],
      vocabulary_id: 21,
      parent_id: null,
      id: 1648,
      _second_name: null,
      _name: 'monograph'
    }, {
      vocabulary_item_term_ids: [2973],
      vocabulary_id: 27,
      parent_id: null,
      id: 2285,
      _second_name: null,
      _name: 'Single'
    }, {
      vocabulary_item_term_ids: [3024],
      vocabulary_id: 29,
      parent_id: null,
      id: 2336,
      _second_name: null,
      _name: 'Occupational Therapy'
    }, {
      vocabulary_item_term_ids: [3028],
      vocabulary_id: 29,
      parent_id: null,
      id: 2340,
      _second_name: null,
      _name: 'Mathematics '
    }, {
      vocabulary_item_term_ids: [3032],
      vocabulary_id: 29,
      parent_id: null,
      id: 2344,
      _second_name: null,
      _name: 'Letters '
    }, {
      vocabulary_item_term_ids: [3036],
      vocabulary_id: 29,
      parent_id: null,
      id: 2348,
      _second_name: null,
      _name: 'Jurisprudence '
    }, {
      vocabulary_item_term_ids: [3040],
      vocabulary_id: 29,
      parent_id: null,
      id: 2352,
      _second_name: null,
      _name: 'International Studies'
    }, {
      vocabulary_item_term_ids: [3044],
      vocabulary_id: 29,
      parent_id: null,
      id: 2356,
      _second_name: null,
      _name: 'Humanities '
    }, {
      vocabulary_item_term_ids: [3048],
      vocabulary_id: 29,
      parent_id: null,
      id: 2360,
      _second_name: null,
      _name: 'Financial Economics'
    }, {
      vocabulary_item_term_ids: [3052],
      vocabulary_id: 29,
      parent_id: null,
      id: 2364,
      _second_name: null,
      _name: 'European Law '
    }, {
      vocabulary_item_term_ids: [3056],
      vocabulary_id: 29,
      parent_id: null,
      id: 2368,
      _second_name: null,
      _name: 'Education '
    }, {
      vocabulary_item_term_ids: [3060],
      vocabulary_id: 29,
      parent_id: null,
      id: 2372,
      _second_name: null,
      _name: 'Criminal Justice'
    }, {
      vocabulary_item_term_ids: [3064],
      vocabulary_id: 29,
      parent_id: null,
      id: 2376,
      _second_name: null,
      _name: 'Commerce '
    }, {
      vocabulary_item_term_ids: [3068],
      vocabulary_id: 29,
      parent_id: null,
      id: 2380,
      _second_name: null,
      _name: 'Business Administration'
    }, {
      vocabulary_item_term_ids: [3072],
      vocabulary_id: 29,
      parent_id: null,
      id: 2384,
      _second_name: null,
      _name: 'Arts'
    }, {
      vocabulary_item_term_ids: [2073, 2074],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1400,
      _second_name: 'N.W.T.',
      _name: 'Northwest Territorian '
    }, {
      vocabulary_item_term_ids: [2061],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1389,
      _second_name: null,
      _name: 'Hanoverian'
    }, {
      vocabulary_item_term_ids: [2049],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1378,
      _second_name: null,
      _name: 'Nicobarese'
    }, {
      vocabulary_item_term_ids: [2030, 2031],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1365,
      _second_name: 'Jammu',
      _name: 'Kashmiri'
    }, {
      vocabulary_item_term_ids: [1607],
      vocabulary_id: 17,
      parent_id: 995,
      id: 995,
      _second_name: null,
      _name: 'Mexican'
    }, {
      vocabulary_item_term_ids: [1941],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1292,
      _second_name: null,
      _name: 'North Island'
    }, {
      vocabulary_item_term_ids: [1476],
      vocabulary_id: 17,
      parent_id: 890,
      id: 889,
      _second_name: null,
      _name: 'Wallisian'
    }, {
      vocabulary_item_term_ids: [657],
      vocabulary_id: 7,
      parent_id: null,
      id: 376,
      _second_name: null,
      _name: 'installation'
    }, {
      vocabulary_item_term_ids: [661],
      vocabulary_id: 7,
      parent_id: null,
      id: 380,
      _second_name: null,
      _name: 'other'
    }, {
      vocabulary_item_term_ids: [665],
      vocabulary_id: 7,
      parent_id: null,
      id: 384,
      _second_name: null,
      _name: 'painting'
    }, {
      vocabulary_item_term_ids: [669],
      vocabulary_id: 7,
      parent_id: null,
      id: 388,
      _second_name: null,
      _name: 'watches'
    }, {
      vocabulary_item_term_ids: [673],
      vocabulary_id: 7,
      parent_id: null,
      id: 392,
      _second_name: null,
      _name: 'design'
    }, {
      vocabulary_item_term_ids: [677],
      vocabulary_id: 7,
      parent_id: null,
      id: 396,
      _second_name: null,
      _name: 'hanging scroll'
    }, {
      vocabulary_item_term_ids: [681],
      vocabulary_id: 7,
      parent_id: null,
      id: 400,
      _second_name: null,
      _name: 'print'
    }, {
      vocabulary_item_term_ids: [685],
      vocabulary_id: 7,
      parent_id: null,
      id: 404,
      _second_name: null,
      _name: 'poster'
    }, {
      vocabulary_item_term_ids: [689],
      vocabulary_id: 7,
      parent_id: null,
      id: 408,
      _second_name: null,
      _name: 'antiquities'
    }, {
      vocabulary_item_term_ids: [693],
      vocabulary_id: 7,
      parent_id: null,
      id: 412,
      _second_name: null,
      _name: 'mosaic'
    }, {
      vocabulary_item_term_ids: [697],
      vocabulary_id: 7,
      parent_id: null,
      id: 416,
      _second_name: null,
      _name: 'bas-relief'
    }, {
      vocabulary_item_term_ids: [715],
      vocabulary_id: 9,
      parent_id: null,
      id: 430,
      _second_name: null,
      _name: 'Bars'
    }, {
      vocabulary_item_term_ids: [859, 860],
      vocabulary_id: 11,
      parent_id: null,
      id: 560,
      _second_name: 'Yugoslavian Dinar',
      _name: 'YUN'
    }, {
      vocabulary_item_term_ids: [899, 900],
      vocabulary_id: 11,
      parent_id: null,
      id: 580,
      _second_name: 'GQE',
      _name: 'Ekwele'
    }, {
      vocabulary_item_term_ids: [939, 940],
      vocabulary_id: 11,
      parent_id: null,
      id: 600,
      _second_name: 'SLL',
      _name: 'Leone'
    }, {
      vocabulary_item_term_ids: [979, 980],
      vocabulary_id: 11,
      parent_id: null,
      id: 620,
      _second_name: 'Nakfa',
      _name: 'ERN'
    }, {
      vocabulary_item_term_ids: [1019, 1020],
      vocabulary_id: 11,
      parent_id: null,
      id: 640,
      _second_name: 'ZAR',
      _name: 'Rand'
    }, {
      vocabulary_item_term_ids: [1059, 1060],
      vocabulary_id: 11,
      parent_id: null,
      id: 660,
      _second_name: 'Malagasy Ariary',
      _name: 'MGA'
    }, {
      vocabulary_item_term_ids: [1099, 1100],
      vocabulary_id: 11,
      parent_id: null,
      id: 680,
      _second_name: 'Barbados Dollar',
      _name: 'BBD'
    }, {
      vocabulary_item_term_ids: [1139, 1140],
      vocabulary_id: 11,
      parent_id: null,
      id: 700,
      _second_name: 'Latvian Ruble',
      _name: 'LVR'
    }, {
      vocabulary_item_term_ids: [1179, 1180],
      vocabulary_id: 11,
      parent_id: null,
      id: 720,
      _second_name: 'DZD',
      _name: 'Algerian Dinar'
    }, {
      vocabulary_item_term_ids: [1219, 1220],
      vocabulary_id: 11,
      parent_id: null,
      id: 740,
      _second_name: 'WIR Franc (for electronic)',
      _name: 'CHC'
    }, {
      vocabulary_item_term_ids: [1259, 1260],
      vocabulary_id: 11,
      parent_id: null,
      id: 760,
      _second_name: 'Loti',
      _name: 'LSL'
    }, {
      vocabulary_item_term_ids: [1270, 1271],
      vocabulary_id: 11,
      parent_id: null,
      id: 765,
      _second_name: 'Norwegian Krone',
      _name: 'NOK'
    }, {
      vocabulary_item_term_ids: [1310, 1311],
      vocabulary_id: 11,
      parent_id: null,
      id: 785,
      _second_name: 'Pataca',
      _name: 'MOP'
    }, {
      vocabulary_item_term_ids: [1350, 1351],
      vocabulary_id: 11,
      parent_id: null,
      id: 805,
      _second_name: 'Lev A/62',
      _name: 'BGK'
    }, {
      vocabulary_item_term_ids: [1391, 1390],
      vocabulary_id: 11,
      parent_id: null,
      id: 825,
      _second_name: 'Som',
      _name: 'KGS'
    }, {
      vocabulary_item_term_ids: [1449],
      vocabulary_id: 13,
      parent_id: null,
      id: 864,
      _second_name: null,
      _name: 'Not Published'
    }, {
      vocabulary_item_term_ids: [1453],
      vocabulary_id: 13,
      parent_id: null,
      id: 868,
      _second_name: null,
      _name: 'Amended'
    }, {
      vocabulary_item_term_ids: [1487, 1486],
      vocabulary_id: 17,
      parent_id: null,
      id: 898,
      _second_name: 'Uzbekistani',
      _name: 'Uzbek'
    }, {
      vocabulary_item_term_ids: [1532],
      vocabulary_id: 17,
      parent_id: null,
      id: 934,
      _second_name: null,
      _name: 'Slovak'
    }, {
      vocabulary_item_term_ids: [1552, 1551],
      vocabulary_id: 17,
      parent_id: null,
      id: 948,
      _second_name: 'Miquelonnais',
      _name: 'Saint-Pierrais '
    }, {
      vocabulary_item_term_ids: [1591, 1592, 1590],
      vocabulary_id: 17,
      parent_id: null,
      id: 982,
      _second_name: 'New Caledonian',
      _name: 'Dutch'
    }, {
      vocabulary_item_term_ids: [1613],
      vocabulary_id: 17,
      parent_id: null,
      id: 1000,
      _second_name: null,
      _name: 'Marshallese'
    }, {
      vocabulary_item_term_ids: [1617],
      vocabulary_id: 17,
      parent_id: null,
      id: 1004,
      _second_name: null,
      _name: 'Malaysian'
    }, {
      vocabulary_item_term_ids: [1668, 1667],
      vocabulary_id: 17,
      parent_id: null,
      id: 1044,
      _second_name: 'McDonald Islands',
      _name: 'Heard Island '
    }, {
      vocabulary_item_term_ids: [1758],
      vocabulary_id: 17,
      parent_id: null,
      id: 1126,
      _second_name: null,
      _name: 'Aruban'
    }, {
      vocabulary_item_term_ids: [1762],
      vocabulary_id: 17,
      parent_id: null,
      id: 1130,
      _second_name: null,
      _name: 'Antiguan'
    }, {
      vocabulary_item_term_ids: [1766],
      vocabulary_id: 17,
      parent_id: null,
      id: 1134,
      _second_name: null,
      _name: 'Andorran'
    }, {
      vocabulary_item_term_ids: [1770],
      vocabulary_id: 17,
      parent_id: null,
      id: 1138,
      _second_name: null,
      _name: 'Åland Island'
    }, {
      vocabulary_item_term_ids: [1790],
      vocabulary_id: 17,
      parent_id: null,
      id: 1157,
      _second_name: null,
      _name: 'Tegean'
    }, {
      vocabulary_item_term_ids: [2982],
      vocabulary_id: 29,
      parent_id: null,
      id: 2294,
      _second_name: null,
      _name: 'Dental Surgery'
    }, {
      vocabulary_item_term_ids: [2986],
      vocabulary_id: 29,
      parent_id: null,
      id: 2298,
      _second_name: null,
      _name: 'Theological Studies'
    }, {
      vocabulary_item_term_ids: [2990],
      vocabulary_id: 29,
      parent_id: null,
      id: 2302,
      _second_name: null,
      _name: 'Social Science'
    }, {
      vocabulary_item_term_ids: [2994],
      vocabulary_id: 29,
      parent_id: null,
      id: 2306,
      _second_name: null,
      _name: 'Science in Supply Chain Management'
    }, {
      vocabulary_item_term_ids: [2998],
      vocabulary_id: 29,
      parent_id: null,
      id: 2310,
      _second_name: null,
      _name: 'Science in Nursing '
    }, {
      vocabulary_item_term_ids: [3002],
      vocabulary_id: 29,
      parent_id: null,
      id: 2314,
      _second_name: null,
      _name: 'Science in Human Resource Development'
    }, {
      vocabulary_item_term_ids: [3006],
      vocabulary_id: 29,
      parent_id: null,
      id: 2318,
      _second_name: null,
      _name: 'Science'
    }, {
      vocabulary_item_term_ids: [3010],
      vocabulary_id: 29,
      parent_id: null,
      id: 2322,
      _second_name: null,
      _name: 'Religious Education'
    }, {
      vocabulary_item_term_ids: [3014],
      vocabulary_id: 29,
      parent_id: null,
      id: 2326,
      _second_name: null,
      _name: 'Public Policy'
    }, {
      vocabulary_item_term_ids: [3018],
      vocabulary_id: 29,
      parent_id: null,
      id: 2330,
      _second_name: null,
      _name: 'Public Administration '
    }, {
      vocabulary_item_term_ids: [3022],
      vocabulary_id: 29,
      parent_id: null,
      id: 2334,
      _second_name: null,
      _name: 'Physics'
    }, {
      vocabulary_item_term_ids: [3136],
      vocabulary_id: 30,
      parent_id: null,
      id: 2448,
      _second_name: null,
      _name: 'Master'
    }, {
      vocabulary_item_term_ids: [3163],
      vocabulary_id: 35,
      parent_id: null,
      id: 2475,
      _second_name: null,
      _name: 'region'
    }, {
      vocabulary_item_term_ids: [2075],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1401,
      _second_name: null,
      _name: 'Newfoundland and Labrador'
    }, {
      vocabulary_item_term_ids: [2032],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1366,
      _second_name: null,
      _name: 'Himachali'
    }, {
      vocabulary_item_term_ids: [2013],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1352,
      _second_name: null,
      _name: 'Punjabi'
    }, {
      vocabulary_item_term_ids: [1951],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1301,
      _second_name: null,
      _name: 'Nelsonian'
    }, {
      vocabulary_item_term_ids: [1947],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1297,
      _second_name: null,
      _name: 'Taranaki'
    }, {
      vocabulary_item_term_ids: [1621],
      vocabulary_id: 17,
      parent_id: 1092,
      id: 1008,
      _second_name: null,
      _name: 'Macanese'
    }, {
      vocabulary_item_term_ids: [643, 644],
      vocabulary_id: 6,
      parent_id: null,
      id: 368,
      _second_name: 'Japanese',
      _name: 'ja'
    }, {
      vocabulary_item_term_ids: [752],
      vocabulary_id: 10,
      parent_id: null,
      id: 454,
      _second_name: null,
      _name: 'Watches'
    }, {
      vocabulary_item_term_ids: [764],
      vocabulary_id: 10,
      parent_id: null,
      id: 466,
      _second_name: null,
      _name: 'Musical Instruments'
    }, {
      vocabulary_item_term_ids: [772],
      vocabulary_id: 10,
      parent_id: null,
      id: 474,
      _second_name: null,
      _name: 'Wine and Spirits'
    }, {
      vocabulary_item_term_ids: [848],
      vocabulary_id: 10,
      parent_id: null,
      id: 550,
      _second_name: null,
      _name: 'Arms, Uniforms, and Militaria'
    }, {
      vocabulary_item_term_ids: [861, 862],
      vocabulary_id: 11,
      parent_id: null,
      id: 561,
      _second_name: 'Surinam Guilder',
      _name: 'SRG'
    }, {
      vocabulary_item_term_ids: [901, 902],
      vocabulary_id: 11,
      parent_id: null,
      id: 581,
      _second_name: 'ESB',
      _name: '"A" Account (convertible Peseta Account)'
    }, {
      vocabulary_item_term_ids: [941, 942],
      vocabulary_id: 11,
      parent_id: null,
      id: 601,
      _second_name: 'Irish Pound',
      _name: 'IEP'
    }, {
      vocabulary_item_term_ids: [981, 982],
      vocabulary_id: 11,
      parent_id: null,
      id: 621,
      _second_name: 'TWD',
      _name: 'New Taiwan Dollar'
    }, {
      vocabulary_item_term_ids: [1021, 1022],
      vocabulary_id: 11,
      parent_id: null,
      id: 641,
      _second_name: 'Turkmenistan New Manat',
      _name: 'TMT'
    }, {
      vocabulary_item_term_ids: [1061, 1062],
      vocabulary_id: 11,
      parent_id: null,
      id: 661,
      _second_name: 'Guyana Dollar',
      _name: 'GYD'
    }, {
      vocabulary_item_term_ids: [1101, 1102],
      vocabulary_id: 11,
      parent_id: null,
      id: 681,
      _second_name: 'Cruzeiro Real',
      _name: 'BRR'
    }, {
      vocabulary_item_term_ids: [1141, 1142],
      vocabulary_id: 11,
      parent_id: null,
      id: 701,
      _second_name: 'ZAL',
      _name: 'Financial Rand'
    }, {
      vocabulary_item_term_ids: [1181, 1182],
      vocabulary_id: 11,
      parent_id: null,
      id: 721,
      _second_name: 'XAF',
      _name: 'CFA Franc BEAC'
    }, {
      vocabulary_item_term_ids: [1221, 1222],
      vocabulary_id: 11,
      parent_id: null,
      id: 741,
      _second_name: 'Zimbabwe Dollar',
      _name: 'ZWL'
    }, {
      vocabulary_item_term_ids: [1272, 1273],
      vocabulary_id: 11,
      parent_id: null,
      id: 766,
      _second_name: 'Bahamian Dollar',
      _name: 'BSD'
    }, {
      vocabulary_item_term_ids: [1312, 1313],
      vocabulary_id: 11,
      parent_id: null,
      id: 786,
      _second_name: 'KMF',
      _name: 'Comoro Franc'
    }, {
      vocabulary_item_term_ids: [1352, 1353],
      vocabulary_id: 11,
      parent_id: null,
      id: 806,
      _second_name: 'UYU',
      _name: 'Peso Uruguayo'
    }, {
      vocabulary_item_term_ids: [1392, 1393],
      vocabulary_id: 11,
      parent_id: null,
      id: 826,
      _second_name: 'Kuna',
      _name: 'HRK'
    }, {
      vocabulary_item_term_ids: [1488],
      vocabulary_id: 17,
      parent_id: null,
      id: 899,
      _second_name: null,
      _name: 'Uruguayan'
    }, {
      vocabulary_item_term_ids: [1553],
      vocabulary_id: 17,
      parent_id: null,
      id: 949,
      _second_name: null,
      _name: 'Saint-Martinoise'
    }, {
      vocabulary_item_term_ids: [1557],
      vocabulary_id: 17,
      parent_id: null,
      id: 953,
      _second_name: null,
      _name: 'Barthélemois'
    }, {
      vocabulary_item_term_ids: [1561],
      vocabulary_id: 17,
      parent_id: null,
      id: 957,
      _second_name: null,
      _name: 'Romanian'
    }, {
      vocabulary_item_term_ids: [1627],
      vocabulary_id: 17,
      parent_id: null,
      id: 1013,
      _second_name: null,
      _name: 'Liberian'
    }, {
      vocabulary_item_term_ids: [1631, 1632],
      vocabulary_id: 17,
      parent_id: null,
      id: 1017,
      _second_name: 'Laotian',
      _name: 'Lao'
    }, {
      vocabulary_item_term_ids: [1642],
      vocabulary_id: 17,
      parent_id: null,
      id: 1023,
      _second_name: null,
      _name: 'I-Kiribati'
    }, {
      vocabulary_item_term_ids: [1669],
      vocabulary_id: 17,
      parent_id: null,
      id: 1045,
      _second_name: null,
      _name: 'Haitian'
    }, {
      vocabulary_item_term_ids: [1677],
      vocabulary_id: 17,
      parent_id: null,
      id: 1052,
      _second_name: null,
      _name: 'Grenadian'
    }, {
      vocabulary_item_term_ids: [1713],
      vocabulary_id: 17,
      parent_id: null,
      id: 1086,
      _second_name: null,
      _name: 'Cook Island'
    }, {
      vocabulary_item_term_ids: [1733],
      vocabulary_id: 17,
      parent_id: null,
      id: 1105,
      _second_name: null,
      _name: 'BIOT'
    }, {
      vocabulary_item_term_ids: [1776],
      vocabulary_id: 17,
      parent_id: null,
      id: 1143,
      _second_name: null,
      _name: 'Umbrian'
    }, {
      vocabulary_item_term_ids: [1780],
      vocabulary_id: 17,
      parent_id: null,
      id: 1147,
      _second_name: null,
      _name: 'Thynian'
    }, {
      vocabulary_item_term_ids: [1784],
      vocabulary_id: 17,
      parent_id: null,
      id: 1151,
      _second_name: null,
      _name: 'Thracian'
    }, {
      vocabulary_item_term_ids: [1788],
      vocabulary_id: 17,
      parent_id: null,
      id: 1155,
      _second_name: null,
      _name: 'Thasian'
    }, {
      vocabulary_item_term_ids: [1796],
      vocabulary_id: 17,
      parent_id: null,
      id: 1162,
      _second_name: null,
      _name: 'Spartan'
    }, {
      vocabulary_item_term_ids: [1800],
      vocabulary_id: 17,
      parent_id: null,
      id: 1166,
      _second_name: null,
      _name: 'Sicyonian'
    }, {
      vocabulary_item_term_ids: [1804, 1805],
      vocabulary_id: 17,
      parent_id: null,
      id: 1170,
      _second_name: 'Scytha',
      _name: 'Scythian'
    }, {
      vocabulary_item_term_ids: [1815],
      vocabulary_id: 17,
      parent_id: null,
      id: 1176,
      _second_name: null,
      _name: 'Salaminian'
    }, {
      vocabulary_item_term_ids: [1819],
      vocabulary_id: 17,
      parent_id: null,
      id: 1180,
      _second_name: null,
      _name: 'Rhodian'
    }, {
      vocabulary_item_term_ids: [1859],
      vocabulary_id: 17,
      parent_id: null,
      id: 1219,
      _second_name: null,
      _name: 'Lucanian'
    }, {
      vocabulary_item_term_ids: [1863],
      vocabulary_id: 17,
      parent_id: null,
      id: 1223,
      _second_name: null,
      _name: 'Latin'
    }, {
      vocabulary_item_term_ids: [1914],
      vocabulary_id: 17,
      parent_id: null,
      id: 1268,
      _second_name: null,
      _name: 'Campanian'
    }, {
      vocabulary_item_term_ids: [1918, 1919],
      vocabulary_id: 17,
      parent_id: null,
      id: 1272,
      _second_name: 'Bosphoran',
      _name: 'Bosporan'
    }, {
      vocabulary_item_term_ids: [2089],
      vocabulary_id: 17,
      parent_id: null,
      id: 1414,
      _second_name: null,
      _name: 'South American'
    }, {
      vocabulary_item_term_ids: [2103],
      vocabulary_id: 18,
      parent_id: null,
      id: 1428,
      _second_name: null,
      _name: 'school of'
    }, {
      vocabulary_item_term_ids: [2107],
      vocabulary_id: 18,
      parent_id: null,
      id: 1432,
      _second_name: null,
      _name: 'office of'
    }, {
      vocabulary_item_term_ids: [2111],
      vocabulary_id: 18,
      parent_id: null,
      id: 1436,
      _second_name: null,
      _name: 'assistant to'
    }, {
      vocabulary_item_term_ids: [2964],
      vocabulary_id: 26,
      parent_id: null,
      id: 2276,
      _second_name: null,
      _name: 'European mark'
    }, {
      vocabulary_item_term_ids: [2968],
      vocabulary_id: 26,
      parent_id: null,
      id: 2280,
      _second_name: null,
      _name: 'Hallmark'
    }, {
      vocabulary_item_term_ids: [3168],
      vocabulary_id: 37,
      parent_id: null,
      id: 2480,
      _second_name: null,
      _name: 'online'
    }, {
      vocabulary_item_term_ids: [2085],
      vocabulary_id: 17,
      parent_id: 1125,
      id: 1410,
      _second_name: null,
      _name: 'Queensland'
    }, {
      vocabulary_item_term_ids: [2081],
      vocabulary_id: 17,
      parent_id: 1125,
      id: 1406,
      _second_name: null,
      _name: 'Western Australian'
    }, {
      vocabulary_item_term_ids: [856],
      vocabulary_id: 10,
      parent_id: 550,
      id: 558,
      _second_name: null,
      _name: 'Armor'
    }, {
      vocabulary_item_term_ids: [852],
      vocabulary_id: 10,
      parent_id: 549,
      id: 554,
      _second_name: null,
      _name: 'Medals'
    }, {
      vocabulary_item_term_ids: [844],
      vocabulary_id: 10,
      parent_id: 509,
      id: 546,
      _second_name: null,
      _name: 'Transparencies and Negatives'
    }, {
      vocabulary_item_term_ids: [840],
      vocabulary_id: 10,
      parent_id: 509,
      id: 542,
      _second_name: null,
      _name: 'Mixed Media'
    }, {
      vocabulary_item_term_ids: [836],
      vocabulary_id: 10,
      parent_id: 509,
      id: 538,
      _second_name: null,
      _name: 'Paintings'
    }, {
      vocabulary_item_term_ids: [832],
      vocabulary_id: 10,
      parent_id: 512,
      id: 534,
      _second_name: null,
      _name: 'Scrolls'
    }, {
      vocabulary_item_term_ids: [828],
      vocabulary_id: 10,
      parent_id: 509,
      id: 530,
      _second_name: null,
      _name: 'Sound Art'
    }, {
      vocabulary_item_term_ids: [824],
      vocabulary_id: 10,
      parent_id: 509,
      id: 526,
      _second_name: null,
      _name: 'Reliefs'
    }, {
      vocabulary_item_term_ids: [820],
      vocabulary_id: 10,
      parent_id: 556,
      id: 522,
      _second_name: null,
      _name: 'Luxury Accessories'
    }, {
      vocabulary_item_term_ids: [816],
      vocabulary_id: 10,
      parent_id: 550,
      id: 518,
      _second_name: null,
      _name: 'Historical Weapons'
    }, {
      vocabulary_item_term_ids: [812],
      vocabulary_id: 10,
      parent_id: 524,
      id: 514,
      _second_name: null,
      _name: 'Carpets and Rugs'
    }, {
      vocabulary_item_term_ids: [808],
      vocabulary_id: 10,
      parent_id: 549,
      id: 510,
      _second_name: null,
      _name: 'Banknotes'
    }, {
      vocabulary_item_term_ids: [804],
      vocabulary_id: 10,
      parent_id: 524,
      id: 506,
      _second_name: null,
      _name: 'Ceramics'
    }, {
      vocabulary_item_term_ids: [800],
      vocabulary_id: 10,
      parent_id: 509,
      id: 502,
      _second_name: null,
      _name: 'Textile Art'
    }, {
      vocabulary_item_term_ids: [796],
      vocabulary_id: 10,
      parent_id: 550,
      id: 498,
      _second_name: null,
      _name: 'Sporting and Historical Firearms'
    }, {
      vocabulary_item_term_ids: [792],
      vocabulary_id: 10,
      parent_id: 509,
      id: 494,
      _second_name: null,
      _name: 'Mosaics'
    }, {
      vocabulary_item_term_ids: [788],
      vocabulary_id: 10,
      parent_id: 524,
      id: 490,
      _second_name: null,
      _name: 'Glass'
    }, {
      vocabulary_item_term_ids: [784],
      vocabulary_id: 10,
      parent_id: 524,
      id: 486,
      _second_name: null,
      _name: 'Garden Furniture and Statuary'
    }, {
      vocabulary_item_term_ids: [780],
      vocabulary_id: 10,
      parent_id: 481,
      id: 482,
      _second_name: null,
      _name: 'Parts and Accessories'
    }, {
      vocabulary_item_term_ids: [776],
      vocabulary_id: 10,
      parent_id: 474,
      id: 478,
      _second_name: null,
      _name: 'Wine'
    }, {
      vocabulary_item_term_ids: [768],
      vocabulary_id: 10,
      parent_id: 481,
      id: 470,
      _second_name: null,
      _name: 'Automobiles'
    }, {
      vocabulary_item_term_ids: [760],
      vocabulary_id: 10,
      parent_id: 491,
      id: 462,
      _second_name: null,
      _name: 'Taxidermy'
    }, {
      vocabulary_item_term_ids: [756],
      vocabulary_id: 10,
      parent_id: 467,
      id: 458,
      _second_name: null,
      _name: 'Gems'
    }, {
      vocabulary_item_term_ids: [748],
      vocabulary_id: 10,
      parent_id: 524,
      id: 450,
      _second_name: null,
      _name: 'Textiles'
    }, {
      vocabulary_item_term_ids: [744],
      vocabulary_id: 10,
      parent_id: 474,
      id: 446,
      _second_name: null,
      _name: 'Whisky'
    }, {
      vocabulary_item_term_ids: [645, 646],
      vocabulary_id: 6,
      parent_id: null,
      id: 369,
      _second_name: 'Chinese',
      _name: 'zh'
    }, {
      vocabulary_item_term_ids: [708],
      vocabulary_id: 9,
      parent_id: null,
      id: 426,
      _second_name: null,
      _name: 'Mohs'
    }, {
      vocabulary_item_term_ids: [863, 864],
      vocabulary_id: 11,
      parent_id: null,
      id: 562,
      _second_name: 'Tolar',
      _name: 'SIT'
    }, {
      vocabulary_item_term_ids: [903, 904],
      vocabulary_id: 11,
      parent_id: null,
      id: 582,
      _second_name: 'Syli',
      _name: 'GNE'
    }, {
      vocabulary_item_term_ids: [943, 944],
      vocabulary_id: 11,
      parent_id: null,
      id: 602,
      _second_name: 'TRL',
      _name: 'Old Turkish Lira'
    }, {
      vocabulary_item_term_ids: [983, 984],
      vocabulary_id: 11,
      parent_id: null,
      id: 622,
      _second_name: 'Syrian Pound',
      _name: 'SYP'
    }, {
      vocabulary_item_term_ids: [1023, 1024],
      vocabulary_id: 11,
      parent_id: null,
      id: 642,
      _second_name: 'Falkland Islands Pound',
      _name: 'FKP'
    }, {
      vocabulary_item_term_ids: [1063, 1064],
      vocabulary_id: 11,
      parent_id: null,
      id: 662,
      _second_name: 'Swiss Franc',
      _name: 'CHF'
    }, {
      vocabulary_item_term_ids: [1103, 1104],
      vocabulary_id: 11,
      parent_id: null,
      id: 682,
      _second_name: 'Pula',
      _name: 'BWP'
    }, {
      vocabulary_item_term_ids: [1143, 1144],
      vocabulary_id: 11,
      parent_id: null,
      id: 702,
      _second_name: 'Lev',
      _name: 'BGL'
    }, {
      vocabulary_item_term_ids: [1183, 1184],
      vocabulary_id: 11,
      parent_id: null,
      id: 722,
      _second_name: 'Sucre',
      _name: 'ECS'
    }, {
      vocabulary_item_term_ids: [1223, 1224],
      vocabulary_id: 11,
      parent_id: null,
      id: 742,
      _second_name: 'Yemeni Rial',
      _name: 'YER'
    }, {
      vocabulary_item_term_ids: [1274, 1275],
      vocabulary_id: 11,
      parent_id: null,
      id: 767,
      _second_name: 'Boliviano',
      _name: 'BOB'
    }, {
      vocabulary_item_term_ids: [1314, 1315],
      vocabulary_id: 11,
      parent_id: null,
      id: 787,
      _second_name: 'Dominican Peso',
      _name: 'DOP'
    }, {
      vocabulary_item_term_ids: [1354, 1355],
      vocabulary_id: 11,
      parent_id: null,
      id: 807,
      _second_name: 'SZL',
      _name: 'Lilangeni'
    }, {
      vocabulary_item_term_ids: [1394, 1395],
      vocabulary_id: 11,
      parent_id: null,
      id: 827,
      _second_name: 'PEI',
      _name: 'Inti'
    }, {
      vocabulary_item_term_ids: [1440],
      vocabulary_id: 12,
      parent_id: null,
      id: 855,
      _second_name: null,
      _name: 'volume'
    }, {
      vocabulary_item_term_ids: [1444],
      vocabulary_id: 12,
      parent_id: null,
      id: 859,
      _second_name: null,
      _name: 'diameter'
    }, {
      vocabulary_item_term_ids: [1471],
      vocabulary_id: 17,
      parent_id: null,
      id: 886,
      _second_name: null,
      _name: 'Yemeni'
    }, {
      vocabulary_item_term_ids: [1501],
      vocabulary_id: 17,
      parent_id: null,
      id: 906,
      _second_name: null,
      _name: 'Turks and Caicos Island'
    }, {
      vocabulary_item_term_ids: [1505, 1506],
      vocabulary_id: 17,
      parent_id: null,
      id: 910,
      _second_name: 'Tobagonian',
      _name: 'Trinidadian'
    }, {
      vocabulary_item_term_ids: [1540],
      vocabulary_id: 17,
      parent_id: null,
      id: 940,
      _second_name: null,
      _name: 'Serbian'
    }, {
      vocabulary_item_term_ids: [1567],
      vocabulary_id: 17,
      parent_id: null,
      id: 962,
      _second_name: null,
      _name: 'Polish'
    }, {
      vocabulary_item_term_ids: [1633, 1634, 1636, 1635],
      vocabulary_id: 17,
      parent_id: null,
      id: 1018,
      _second_name: 'Kirgiz',
      _name: 'Kyrgyz'
    }, {
      vocabulary_item_term_ids: [1648],
      vocabulary_id: 17,
      parent_id: null,
      id: 1028,
      _second_name: null,
      _name: 'Japanese'
    }, {
      vocabulary_item_term_ids: [1652],
      vocabulary_id: 17,
      parent_id: null,
      id: 1032,
      _second_name: null,
      _name: 'Italian'
    }, {
      vocabulary_item_term_ids: [1656],
      vocabulary_id: 17,
      parent_id: null,
      id: 1036,
      _second_name: null,
      _name: 'Iraqi'
    }, {
      vocabulary_item_term_ids: [1683],
      vocabulary_id: 17,
      parent_id: null,
      id: 1057,
      _second_name: null,
      _name: 'German'
    }, {
      vocabulary_item_term_ids: [1687],
      vocabulary_id: 17,
      parent_id: null,
      id: 1061,
      _second_name: null,
      _name: 'French Southern Territories'
    }, {
      vocabulary_item_term_ids: [1691],
      vocabulary_id: 17,
      parent_id: null,
      id: 1065,
      _second_name: null,
      _name: 'Finnish'
    }, {
      vocabulary_item_term_ids: [1695],
      vocabulary_id: 17,
      parent_id: null,
      id: 1069,
      _second_name: null,
      _name: 'European'
    }, {
      vocabulary_item_term_ids: [1699, 1700],
      vocabulary_id: 17,
      parent_id: null,
      id: 1073,
      _second_name: 'Equatoguinean',
      _name: 'Equatorial Guinean'
    }, {
      vocabulary_item_term_ids: [1707],
      vocabulary_id: 17,
      parent_id: null,
      id: 1080,
      _second_name: null,
      _name: 'Czech'
    }, {
      vocabulary_item_term_ids: [1711],
      vocabulary_id: 17,
      parent_id: null,
      id: 1084,
      _second_name: null,
      _name: 'Croatian'
    }, {
      vocabulary_item_term_ids: [1719],
      vocabulary_id: 17,
      parent_id: null,
      id: 1091,
      _second_name: null,
      _name: 'Christmas Island'
    }, {
      vocabulary_item_term_ids: [1723],
      vocabulary_id: 17,
      parent_id: null,
      id: 1095,
      _second_name: null,
      _name: 'Central African'
    }, {
      vocabulary_item_term_ids: [1727],
      vocabulary_id: 17,
      parent_id: null,
      id: 1099,
      _second_name: null,
      _name: 'Cambodian'
    }, {
      vocabulary_item_term_ids: [1739],
      vocabulary_id: 17,
      parent_id: null,
      id: 1110,
      _second_name: null,
      _name: 'Bosnian'
    }, {
      vocabulary_item_term_ids: [1743, 1744],
      vocabulary_id: 17,
      parent_id: null,
      id: 1114,
      _second_name: 'Bermudan',
      _name: 'Bermudian'
    }, {
      vocabulary_item_term_ids: [1806, 1807, 1808],
      vocabulary_id: 17,
      parent_id: null,
      id: 1171,
      _second_name: 'Sauromata',
      _name: 'Sarmatian'
    }, {
      vocabulary_item_term_ids: [1825],
      vocabulary_id: 17,
      parent_id: null,
      id: 1185,
      _second_name: null,
      _name: 'Phrygian'
    }, {
      vocabulary_item_term_ids: [1829],
      vocabulary_id: 17,
      parent_id: null,
      id: 1189,
      _second_name: null,
      _name: 'Philippian'
    }, {
      vocabulary_item_term_ids: [1833],
      vocabulary_id: 17,
      parent_id: null,
      id: 1193,
      _second_name: null,
      _name: 'Patmian'
    }, {
      vocabulary_item_term_ids: [1837],
      vocabulary_id: 17,
      parent_id: null,
      id: 1197,
      _second_name: null,
      _name: 'Orcadian'
    }, {
      vocabulary_item_term_ids: [1841],
      vocabulary_id: 17,
      parent_id: null,
      id: 1201,
      _second_name: null,
      _name: 'Nisyrian'
    }, {
      vocabulary_item_term_ids: [1845],
      vocabulary_id: 17,
      parent_id: null,
      id: 1205,
      _second_name: null,
      _name: 'Mycenaean'
    }, {
      vocabulary_item_term_ids: [1849],
      vocabulary_id: 17,
      parent_id: null,
      id: 1209,
      _second_name: null,
      _name: 'Messenian'
    }, {
      vocabulary_item_term_ids: [1853],
      vocabulary_id: 17,
      parent_id: null,
      id: 1213,
      _second_name: null,
      _name: 'Megarian'
    }, {
      vocabulary_item_term_ids: [1857],
      vocabulary_id: 17,
      parent_id: null,
      id: 1217,
      _second_name: null,
      _name: 'Maeonian'
    }, {
      vocabulary_item_term_ids: [1869],
      vocabulary_id: 17,
      parent_id: null,
      id: 1228,
      _second_name: null,
      _name: 'Kalymnian'
    }, {
      vocabulary_item_term_ids: [1873, 1874],
      vocabulary_id: 17,
      parent_id: null,
      id: 1232,
      _second_name: 'Germanus',
      _name: 'Germanic'
    }, {
      vocabulary_item_term_ids: [1920],
      vocabulary_id: 17,
      parent_id: null,
      id: 1273,
      _second_name: null,
      _name: 'Boius'
    }, {
      vocabulary_item_term_ids: [2977],
      vocabulary_id: 28,
      parent_id: null,
      id: 2289,
      _second_name: null,
      _name: 'Sale price'
    }, {
      vocabulary_item_term_ids: [3119],
      vocabulary_id: 29,
      parent_id: null,
      id: 2431,
      _second_name: null,
      _name: 'Applied Arts and Science'
    }, {
      vocabulary_item_term_ids: [3123],
      vocabulary_id: 29,
      parent_id: null,
      id: 2435,
      _second_name: null,
      _name: 'Occupational Studies'
    }, {
      vocabulary_item_term_ids: [3127],
      vocabulary_id: 29,
      parent_id: null,
      id: 2439,
      _second_name: null,
      _name: 'Engineering Science'
    }, {
      vocabulary_item_term_ids: [3131],
      vocabulary_id: 29,
      parent_id: null,
      id: 2443,
      _second_name: null,
      _name: 'Forestry'
    }, {
      vocabulary_item_term_ids: [1979],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1326,
      _second_name: null,
      _name: 'East Malaysian'
    }, {
      vocabulary_item_term_ids: [1975],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1322,
      _second_name: null,
      _name: 'Chiapan'
    }, {
      vocabulary_item_term_ids: [1971],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1318,
      _second_name: null,
      _name: 'Guerreran'
    }, {
      vocabulary_item_term_ids: [1967, 1968],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1315,
      _second_name: 'Poblano',
      _name: 'Pueblan'
    }, {
      vocabulary_item_term_ids: [1963],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1311,
      _second_name: null,
      _name: 'Sonoran'
    }, {
      vocabulary_item_term_ids: [1959],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1307,
      _second_name: null,
      _name: 'Bay of Plenty'
    }, {
      vocabulary_item_term_ids: [647, 648],
      vocabulary_id: 6,
      parent_id: null,
      id: 370,
      _second_name: 'Italian',
      _name: 'it'
    }, {
      vocabulary_item_term_ids: [865, 866],
      vocabulary_id: 11,
      parent_id: null,
      id: 563,
      _second_name: 'Zimbabwe Dollar (new)',
      _name: 'ZWN'
    }, {
      vocabulary_item_term_ids: [905, 906],
      vocabulary_id: 11,
      parent_id: null,
      id: 583,
      _second_name: 'Spanish Peseta',
      _name: 'ESA'
    }, {
      vocabulary_item_term_ids: [945, 946],
      vocabulary_id: 11,
      parent_id: null,
      id: 603,
      _second_name: 'Old Lek',
      _name: 'ALK'
    }, {
      vocabulary_item_term_ids: [985, 986],
      vocabulary_id: 11,
      parent_id: null,
      id: 623,
      _second_name: 'Namibia Dollar',
      _name: 'NAD'
    }, {
      vocabulary_item_term_ids: [1025, 1026],
      vocabulary_id: 11,
      parent_id: null,
      id: 643,
      _second_name: 'SDR (Special Drawing Right)',
      _name: 'XDR'
    }, {
      vocabulary_item_term_ids: [1065, 1066],
      vocabulary_id: 11,
      parent_id: null,
      id: 663,
      _second_name: 'Congolese Franc',
      _name: 'CDF'
    }, {
      vocabulary_item_term_ids: [1105, 1106],
      vocabulary_id: 11,
      parent_id: null,
      id: 683,
      _second_name: 'Taka',
      _name: 'BDT'
    }, {
      vocabulary_item_term_ids: [1145, 1146],
      vocabulary_id: 11,
      parent_id: null,
      id: 703,
      _second_name: 'Rwanda Franc',
      _name: 'RWF'
    }, {
      vocabulary_item_term_ids: [1185, 1186],
      vocabulary_id: 11,
      parent_id: null,
      id: 723,
      _second_name: 'TJS',
      _name: 'Somoni'
    }, {
      vocabulary_item_term_ids: [1225, 1226],
      vocabulary_id: 11,
      parent_id: null,
      id: 743,
      _second_name: 'Lari',
      _name: 'GEL'
    }, {
      vocabulary_item_term_ids: [1276, 1277],
      vocabulary_id: 11,
      parent_id: null,
      id: 768,
      _second_name: 'Bermudian Dollar',
      _name: 'BMD'
    }, {
      vocabulary_item_term_ids: [1316, 1317],
      vocabulary_id: 11,
      parent_id: null,
      id: 788,
      _second_name: 'Kwanza',
      _name: 'AOA'
    }, {
      vocabulary_item_term_ids: [1356, 1357],
      vocabulary_id: 11,
      parent_id: null,
      id: 808,
      _second_name: 'Somali Shilling',
      _name: 'SOS'
    }, {
      vocabulary_item_term_ids: [1396, 1397],
      vocabulary_id: 11,
      parent_id: null,
      id: 828,
      _second_name: 'Ghana Cedi',
      _name: 'GHS'
    }, {
      vocabulary_item_term_ids: [1430],
      vocabulary_id: 12,
      parent_id: null,
      id: 845,
      _second_name: null,
      _name: 'hardness'
    }, {
      vocabulary_item_term_ids: [1434],
      vocabulary_id: 12,
      parent_id: null,
      id: 849,
      _second_name: null,
      _name: 'speed'
    }, {
      vocabulary_item_term_ids: [1457],
      vocabulary_id: 14,
      parent_id: null,
      id: 872,
      _second_name: null,
      _name: 'Prices need review'
    }, {
      vocabulary_item_term_ids: [1461],
      vocabulary_id: 14,
      parent_id: null,
      id: 876,
      _second_name: null,
      _name: 'Created'
    }, {
      vocabulary_item_term_ids: [1494, 1492, 1493],
      vocabulary_id: 17,
      parent_id: null,
      id: 901,
      _second_name: 'UK',
      _name: 'British'
    }, {
      vocabulary_item_term_ids: [1507],
      vocabulary_id: 17,
      parent_id: null,
      id: 911,
      _second_name: null,
      _name: 'Tongan'
    }, {
      vocabulary_item_term_ids: [1511],
      vocabulary_id: 17,
      parent_id: null,
      id: 915,
      _second_name: null,
      _name: 'Thai'
    }, {
      vocabulary_item_term_ids: [1515],
      vocabulary_id: 17,
      parent_id: null,
      id: 919,
      _second_name: null,
      _name: 'Swiss'
    }, {
      vocabulary_item_term_ids: [1519],
      vocabulary_id: 17,
      parent_id: null,
      id: 923,
      _second_name: null,
      _name: 'Surinamese'
    }, {
      vocabulary_item_term_ids: [1523],
      vocabulary_id: 17,
      parent_id: null,
      id: 927,
      _second_name: null,
      _name: 'South Sudanese'
    }, {
      vocabulary_item_term_ids: [1573, 1574],
      vocabulary_id: 17,
      parent_id: null,
      id: 967,
      _second_name: 'Papua New Guinean',
      _name: 'Papuan'
    }, {
      vocabulary_item_term_ids: [1596],
      vocabulary_id: 17,
      parent_id: null,
      id: 985,
      _second_name: null,
      _name: 'Namibian'
    }, {
      vocabulary_item_term_ids: [1600],
      vocabulary_id: 17,
      parent_id: null,
      id: 989,
      _second_name: null,
      _name: 'Montserratian'
    }, {
      vocabulary_item_term_ids: [1662, 1663],
      vocabulary_id: 17,
      parent_id: null,
      id: 1041,
      _second_name: 'Magyar',
      _name: 'Hungarian'
    }, {
      vocabulary_item_term_ids: [1701],
      vocabulary_id: 17,
      parent_id: null,
      id: 1074,
      _second_name: null,
      _name: 'Salvadoran'
    }, {
      vocabulary_item_term_ids: [1745, 1746],
      vocabulary_id: 17,
      parent_id: null,
      id: 1115,
      _second_name: 'Beninois',
      _name: 'Beninese'
    }, {
      vocabulary_item_term_ids: [1875],
      vocabulary_id: 17,
      parent_id: null,
      id: 1233,
      _second_name: null,
      _name: 'Gaulish'
    }, {
      vocabulary_item_term_ids: [1879],
      vocabulary_id: 17,
      parent_id: null,
      id: 1237,
      _second_name: null,
      _name: 'Etruscan'
    }, {
      vocabulary_item_term_ids: [1883],
      vocabulary_id: 17,
      parent_id: null,
      id: 1241,
      _second_name: null,
      _name: 'Epidamnian'
    }, {
      vocabulary_item_term_ids: [1926],
      vocabulary_id: 17,
      parent_id: null,
      id: 1278,
      _second_name: null,
      _name: 'Arretine'
    }, {
      vocabulary_item_term_ids: [1930],
      vocabulary_id: 17,
      parent_id: null,
      id: 1282,
      _second_name: null,
      _name: 'Apulian'
    }, {
      vocabulary_item_term_ids: [1934],
      vocabulary_id: 17,
      parent_id: null,
      id: 1286,
      _second_name: null,
      _name: 'Aeginetan'
    }, {
      vocabulary_item_term_ids: [2098],
      vocabulary_id: 17,
      parent_id: null,
      id: 1423,
      _second_name: null,
      _name: 'Afro-Eurasian'
    }, {
      vocabulary_item_term_ids: [2955],
      vocabulary_id: 25,
      parent_id: null,
      id: 2267,
      _second_name: null,
      _name: 'personal website'
    }, {
      vocabulary_item_term_ids: [2959],
      vocabulary_id: 25,
      parent_id: null,
      id: 2271,
      _second_name: null,
      _name: 'office'
    }, {
      vocabulary_item_term_ids: [3077],
      vocabulary_id: 29,
      parent_id: null,
      id: 2389,
      _second_name: null,
      _name: 'Urban and Regional Planning'
    }, {
      vocabulary_item_term_ids: [3081],
      vocabulary_id: 29,
      parent_id: null,
      id: 2393,
      _second_name: null,
      _name: 'Technology '
    }, {
      vocabulary_item_term_ids: [3085],
      vocabulary_id: 29,
      parent_id: null,
      id: 2397,
      _second_name: null,
      _name: 'Science in Forestry '
    }, {
      vocabulary_item_term_ids: [3089],
      vocabulary_id: 29,
      parent_id: null,
      id: 2401,
      _second_name: null,
      _name: 'Arts in Psychology'
    }, {
      vocabulary_item_term_ids: [3093],
      vocabulary_id: 29,
      parent_id: null,
      id: 2405,
      _second_name: null,
      _name: 'Liberal Studies'
    }, {
      vocabulary_item_term_ids: [3097],
      vocabulary_id: 29,
      parent_id: null,
      id: 2409,
      _second_name: null,
      _name: 'Journalism'
    }, {
      vocabulary_item_term_ids: [3101],
      vocabulary_id: 29,
      parent_id: null,
      id: 2413,
      _second_name: null,
      _name: 'Aviation'
    }, {
      vocabulary_item_term_ids: [3105],
      vocabulary_id: 29,
      parent_id: null,
      id: 2417,
      _second_name: null,
      _name: 'Intercalated Bachelor of Science'
    }, {
      vocabulary_item_term_ids: [3109],
      vocabulary_id: 29,
      parent_id: null,
      id: 2421,
      _second_name: null,
      _name: 'Arts in Organizational Management'
    }, {
      vocabulary_item_term_ids: [3113],
      vocabulary_id: 29,
      parent_id: null,
      id: 2425,
      _second_name: null,
      _name: 'International Business Economics'
    }, {
      vocabulary_item_term_ids: [3140],
      vocabulary_id: 31,
      parent_id: null,
      id: 2452,
      _second_name: null,
      _name: 'operator'
    }, {
      vocabulary_item_term_ids: [3144],
      vocabulary_id: 31,
      parent_id: null,
      id: 2456,
      _second_name: null,
      _name: 'designer'
    }, {
      vocabulary_item_term_ids: [2066],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1394,
      _second_name: null,
      _name: 'Saskatchewan'
    }, {
      vocabulary_item_term_ids: [2054],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1383,
      _second_name: null,
      _name: 'Rhenish'
    }, {
      vocabulary_item_term_ids: [2027, 2028],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1363,
      _second_name: 'Canarese',
      _name: 'Karnatakan'
    }, {
      vocabulary_item_term_ids: [2023],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1359,
      _second_name: null,
      _name: 'Maharashtrian'
    }, {
      vocabulary_item_term_ids: [2004],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1345,
      _second_name: null,
      _name: 'Uttarakhandi'
    }, {
      vocabulary_item_term_ids: [1997],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1343,
      _second_name: null,
      _name: 'Johorean'
    }, {
      vocabulary_item_term_ids: [1993],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1339,
      _second_name: null,
      _name: 'Labuanese'
    }, {
      vocabulary_item_term_ids: [1989],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1335,
      _second_name: null,
      _name: 'Penangite'
    }, {
      vocabulary_item_term_ids: [1985],
      vocabulary_id: 17,
      parent_id: 1004,
      id: 1331,
      _second_name: null,
      _name: 'Sabahan'
    }, {
      vocabulary_item_term_ids: [1969],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1316,
      _second_name: null,
      _name: 'Oaxacan'
    }, {
      vocabulary_item_term_ids: [1938],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1290,
      _second_name: null,
      _name: 'Stewart Island'
    }, {
      vocabulary_item_term_ids: [649, 650],
      vocabulary_id: 6,
      parent_id: null,
      id: 371,
      _second_name: 'German',
      _name: 'de'
    }, {
      vocabulary_item_term_ids: [867, 868],
      vocabulary_id: 11,
      parent_id: null,
      id: 564,
      _second_name: 'Mali Franc',
      _name: 'MAF'
    }, {
      vocabulary_item_term_ids: [907, 908],
      vocabulary_id: 11,
      parent_id: null,
      id: 584,
      _second_name: 'Serbian Dinar',
      _name: 'CSD'
    }, {
      vocabulary_item_term_ids: [947, 948],
      vocabulary_id: 11,
      parent_id: null,
      id: 604,
      _second_name: 'Andorran Peseta',
      _name: 'ADP'
    }, {
      vocabulary_item_term_ids: [987, 988],
      vocabulary_id: 11,
      parent_id: null,
      id: 624,
      _second_name: 'Singapore Dollar',
      _name: 'SGD'
    }, {
      vocabulary_item_term_ids: [1027, 1028],
      vocabulary_id: 11,
      parent_id: null,
      id: 644,
      _second_name: 'Uganda Shilling',
      _name: 'UGX'
    }, {
      vocabulary_item_term_ids: [1067, 1068],
      vocabulary_id: 11,
      parent_id: null,
      id: 664,
      _second_name: 'Canadian Dollar',
      _name: 'CAD'
    }, {
      vocabulary_item_term_ids: [1107, 1108],
      vocabulary_id: 11,
      parent_id: null,
      id: 684,
      _second_name: 'THB',
      _name: 'Baht'
    }, {
      vocabulary_item_term_ids: [1147, 1148],
      vocabulary_id: 11,
      parent_id: null,
      id: 704,
      _second_name: 'Zloty',
      _name: 'PLN'
    }, {
      vocabulary_item_term_ids: [1187, 1188],
      vocabulary_id: 11,
      parent_id: null,
      id: 724,
      _second_name: 'STD',
      _name: 'Dobra'
    }, {
      vocabulary_item_term_ids: [1227, 1228],
      vocabulary_id: 11,
      parent_id: null,
      id: 744,
      _second_name: 'Peso Argentino',
      _name: 'ARP'
    }, {
      vocabulary_item_term_ids: [1278, 1279],
      vocabulary_id: 11,
      parent_id: null,
      id: 769,
      _second_name: 'Convertible Mark',
      _name: 'BAM'
    }, {
      vocabulary_item_term_ids: [1318, 1319],
      vocabulary_id: 11,
      parent_id: null,
      id: 789,
      _second_name: 'Mexican Peso',
      _name: 'MXP'
    }, {
      vocabulary_item_term_ids: [1358, 1359],
      vocabulary_id: 11,
      parent_id: null,
      id: 809,
      _second_name: 'PAB',
      _name: 'Balboa'
    }, {
      vocabulary_item_term_ids: [1398, 1399],
      vocabulary_id: 11,
      parent_id: null,
      id: 829,
      _second_name: 'Egyptian Pound',
      _name: 'EGP'
    }, {
      vocabulary_item_term_ids: [1479],
      vocabulary_id: 17,
      parent_id: null,
      id: 892,
      _second_name: null,
      _name: 'U.S. Virgin Island'
    }, {
      vocabulary_item_term_ids: [1483],
      vocabulary_id: 17,
      parent_id: null,
      id: 896,
      _second_name: null,
      _name: 'Vatican'
    }, {
      vocabulary_item_term_ids: [1529],
      vocabulary_id: 17,
      parent_id: null,
      id: 932,
      _second_name: null,
      _name: 'Solomon Island'
    }, {
      vocabulary_item_term_ids: [1548],
      vocabulary_id: 17,
      parent_id: null,
      id: 946,
      _second_name: null,
      _name: 'Samoan'
    }, {
      vocabulary_item_term_ids: [1575],
      vocabulary_id: 17,
      parent_id: null,
      id: 968,
      _second_name: null,
      _name: 'Panamanian'
    }, {
      vocabulary_item_term_ids: [1579],
      vocabulary_id: 17,
      parent_id: null,
      id: 972,
      _second_name: null,
      _name: 'Omani'
    }, {
      vocabulary_item_term_ids: [1583],
      vocabulary_id: 17,
      parent_id: null,
      id: 976,
      _second_name: null,
      _name: 'Norfolk Island'
    }, {
      vocabulary_item_term_ids: [1587],
      vocabulary_id: 17,
      parent_id: null,
      id: 980,
      _second_name: null,
      _name: 'Nicaraguan'
    }, {
      vocabulary_item_term_ids: [1606],
      vocabulary_id: 17,
      parent_id: null,
      id: 994,
      _second_name: null,
      _name: 'Micronesian'
    }, {
      vocabulary_item_term_ids: [1610],
      vocabulary_id: 17,
      parent_id: null,
      id: 998,
      _second_name: null,
      _name: 'Mauritanian'
    }, {
      vocabulary_item_term_ids: [1664, 1665],
      vocabulary_id: 17,
      parent_id: null,
      id: 1042,
      _second_name: 'Hong Kong',
      _name: 'Hong Kongese'
    }, {
      vocabulary_item_term_ids: [1747],
      vocabulary_id: 17,
      parent_id: null,
      id: 1116,
      _second_name: null,
      _name: 'Belizean'
    }, {
      vocabulary_item_term_ids: [1751],
      vocabulary_id: 17,
      parent_id: null,
      id: 1120,
      _second_name: null,
      _name: 'Bangladeshi'
    }, {
      vocabulary_item_term_ids: [1889],
      vocabulary_id: 17,
      parent_id: null,
      id: 1246,
      _second_name: null,
      _name: 'Edonian'
    }, {
      vocabulary_item_term_ids: [1893],
      vocabulary_id: 17,
      parent_id: null,
      id: 1250,
      _second_name: null,
      _name: 'Dacian'
    }, {
      vocabulary_item_term_ids: [1897],
      vocabulary_id: 17,
      parent_id: null,
      id: 1253,
      _second_name: null,
      _name: 'Cycladian'
    }, {
      vocabulary_item_term_ids: [1901],
      vocabulary_id: 17,
      parent_id: null,
      id: 1257,
      _second_name: null,
      _name: 'Corcyrean'
    }, {
      vocabulary_item_term_ids: [1905],
      vocabulary_id: 17,
      parent_id: null,
      id: 1261,
      _second_name: null,
      _name: 'Chiot'
    }, {
      vocabulary_item_term_ids: [2096],
      vocabulary_id: 17,
      parent_id: null,
      id: 1421,
      _second_name: null,
      _name: 'Asian'
    }, {
      vocabulary_item_term_ids: [2306],
      vocabulary_id: 21,
      parent_id: null,
      id: 1631,
      _second_name: null,
      _name: 'exhibition catalog'
    }, {
      vocabulary_item_term_ids: [2310],
      vocabulary_id: 21,
      parent_id: null,
      id: 1635,
      _second_name: null,
      _name: 'brochure'
    }, {
      vocabulary_item_term_ids: [2314],
      vocabulary_id: 21,
      parent_id: null,
      id: 1639,
      _second_name: null,
      _name: 'letter'
    }, {
      vocabulary_item_term_ids: [2318],
      vocabulary_id: 21,
      parent_id: null,
      id: 1643,
      _second_name: null,
      _name: 'biography'
    }, {
      vocabulary_item_term_ids: [2322],
      vocabulary_id: 21,
      parent_id: null,
      id: 1647,
      _second_name: null,
      _name: 'catalog raisonné'
    }, {
      vocabulary_item_term_ids: [2972],
      vocabulary_id: 27,
      parent_id: null,
      id: 2284,
      _second_name: null,
      _name: 'No estimate'
    }, {
      vocabulary_item_term_ids: [3027],
      vocabulary_id: 29,
      parent_id: null,
      id: 2339,
      _second_name: null,
      _name: 'Mathematical Finance'
    }, {
      vocabulary_item_term_ids: [3031],
      vocabulary_id: 29,
      parent_id: null,
      id: 2343,
      _second_name: null,
      _name: 'Liberal Arts '
    }, {
      vocabulary_item_term_ids: [3035],
      vocabulary_id: 29,
      parent_id: null,
      id: 2347,
      _second_name: null,
      _name: 'Laws'
    }, {
      vocabulary_item_term_ids: [3039],
      vocabulary_id: 29,
      parent_id: null,
      id: 2351,
      _second_name: null,
      _name: 'International Economics'
    }, {
      vocabulary_item_term_ids: [3043],
      vocabulary_id: 29,
      parent_id: null,
      id: 2355,
      _second_name: null,
      _name: 'Industrial and Labor Relations'
    }, {
      vocabulary_item_term_ids: [3047],
      vocabulary_id: 29,
      parent_id: null,
      id: 2359,
      _second_name: null,
      _name: 'Fine Arts'
    }, {
      vocabulary_item_term_ids: [3051],
      vocabulary_id: 29,
      parent_id: null,
      id: 2363,
      _second_name: null,
      _name: 'Finance '
    }, {
      vocabulary_item_term_ids: [3055],
      vocabulary_id: 29,
      parent_id: null,
      id: 2367,
      _second_name: null,
      _name: 'Enterprise '
    }, {
      vocabulary_item_term_ids: [3059],
      vocabulary_id: 29,
      parent_id: null,
      id: 2371,
      _second_name: null,
      _name: 'Design'
    }, {
      vocabulary_item_term_ids: [3063],
      vocabulary_id: 29,
      parent_id: null,
      id: 2375,
      _second_name: null,
      _name: 'Computational Finance'
    }, {
      vocabulary_item_term_ids: [3067],
      vocabulary_id: 29,
      parent_id: null,
      id: 2379,
      _second_name: null,
      _name: 'Business Informatics '
    }, {
      vocabulary_item_term_ids: [3071],
      vocabulary_id: 29,
      parent_id: null,
      id: 2383,
      _second_name: null,
      _name: 'Arts in Teaching '
    }, {
      vocabulary_item_term_ids: [2115],
      vocabulary_id: 19,
      parent_id: 1442,
      id: 1440,
      _second_name: null,
      _name: 'official name'
    }, {
      vocabulary_item_term_ids: [2072],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1399,
      _second_name: null,
      _name: 'Nunavut'
    }, {
      vocabulary_item_term_ids: [2064],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1392,
      _second_name: null,
      _name: 'Brandenburgish'
    }, {
      vocabulary_item_term_ids: [2060],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1388,
      _second_name: null,
      _name: 'Hessian'
    }, {
      vocabulary_item_term_ids: [2052],
      vocabulary_id: 17,
      parent_id: 1057,
      id: 1381,
      _second_name: null,
      _name: 'Swabian'
    }, {
      vocabulary_item_term_ids: [2048],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1377,
      _second_name: null,
      _name: 'Andhraite'
    }, {
      vocabulary_item_term_ids: [2029],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1364,
      _second_name: null,
      _name: 'Bihari'
    }, {
      vocabulary_item_term_ids: [1475],
      vocabulary_id: 17,
      parent_id: 890,
      id: 888,
      _second_name: null,
      _name: 'Futunan'
    }, {
      vocabulary_item_term_ids: [651, 652],
      vocabulary_id: 6,
      parent_id: null,
      id: 372,
      _second_name: 'French',
      _name: 'fr'
    }, {
      vocabulary_item_term_ids: [656],
      vocabulary_id: 7,
      parent_id: null,
      id: 375,
      _second_name: null,
      _name: 'statue'
    }, {
      vocabulary_item_term_ids: [660],
      vocabulary_id: 7,
      parent_id: null,
      id: 379,
      _second_name: null,
      _name: 'sculpture'
    }, {
      vocabulary_item_term_ids: [664],
      vocabulary_id: 7,
      parent_id: null,
      id: 383,
      _second_name: null,
      _name: 'collage'
    }, {
      vocabulary_item_term_ids: [668],
      vocabulary_id: 7,
      parent_id: null,
      id: 387,
      _second_name: null,
      _name: 'glass'
    }, {
      vocabulary_item_term_ids: [672],
      vocabulary_id: 7,
      parent_id: null,
      id: 391,
      _second_name: null,
      _name: 'ethnographic'
    }, {
      vocabulary_item_term_ids: [676],
      vocabulary_id: 7,
      parent_id: null,
      id: 395,
      _second_name: null,
      _name: 'performance'
    }, {
      vocabulary_item_term_ids: [680],
      vocabulary_id: 7,
      parent_id: null,
      id: 399,
      _second_name: null,
      _name: 'wine'
    }, {
      vocabulary_item_term_ids: [684],
      vocabulary_id: 7,
      parent_id: null,
      id: 403,
      _second_name: null,
      _name: 'furniture'
    }, {
      vocabulary_item_term_ids: [688],
      vocabulary_id: 7,
      parent_id: null,
      id: 407,
      _second_name: null,
      _name: 'mural'
    }, {
      vocabulary_item_term_ids: [692],
      vocabulary_id: 7,
      parent_id: null,
      id: 411,
      _second_name: null,
      _name: 'photography'
    }, {
      vocabulary_item_term_ids: [696],
      vocabulary_id: 7,
      parent_id: null,
      id: 415,
      _second_name: null,
      _name: 'coins and currency'
    }, {
      vocabulary_item_term_ids: [869, 870],
      vocabulary_id: 11,
      parent_id: null,
      id: 565,
      _second_name: 'YUD',
      _name: 'New Yugoslavian Dinar'
    }, {
      vocabulary_item_term_ids: [909, 910],
      vocabulary_id: 11,
      parent_id: null,
      id: 585,
      _second_name: 'Kroon',
      _name: 'EEK'
    }, {
      vocabulary_item_term_ids: [949, 950],
      vocabulary_id: 11,
      parent_id: null,
      id: 605,
      _second_name: 'Trinidad and Tobago Dollar',
      _name: 'TTD'
    }, {
      vocabulary_item_term_ids: [989, 990],
      vocabulary_id: 11,
      parent_id: null,
      id: 625,
      _second_name: 'Qatari Rial',
      _name: 'QAR'
    }, {
      vocabulary_item_term_ids: [1029, 1030],
      vocabulary_id: 11,
      parent_id: null,
      id: 645,
      _second_name: 'Tanzanian Shilling',
      _name: 'TZS'
    }, {
      vocabulary_item_term_ids: [1069, 1070],
      vocabulary_id: 11,
      parent_id: null,
      id: 665,
      _second_name: 'Lebanese Pound',
      _name: 'LBP'
    }, {
      vocabulary_item_term_ids: [1261, 1109, 1110],
      vocabulary_id: 11,
      parent_id: null,
      id: 685,
      _second_name: 'VEB',
      _name: 'Bolivar'
    }, {
      vocabulary_item_term_ids: [1149, 1150],
      vocabulary_id: 11,
      parent_id: null,
      id: 705,
      _second_name: 'Naira',
      _name: 'NGN'
    }, {
      vocabulary_item_term_ids: [1189, 1190],
      vocabulary_id: 11,
      parent_id: null,
      id: 725,
      _second_name: 'Swedish Krona',
      _name: 'SEK'
    }, {
      vocabulary_item_term_ids: [1229, 1230],
      vocabulary_id: 11,
      parent_id: null,
      id: 745,
      _second_name: 'XCD',
      _name: 'East Caribbean Dollar'
    }, {
      vocabulary_item_term_ids: [1280, 1281],
      vocabulary_id: 11,
      parent_id: null,
      id: 770,
      _second_name: 'Afghani',
      _name: 'AFN'
    }, {
      vocabulary_item_term_ids: [1320, 1321],
      vocabulary_id: 11,
      parent_id: null,
      id: 790,
      _second_name: 'Maldive Rupee',
      _name: 'MVQ'
    }, {
      vocabulary_item_term_ids: [1360, 1361],
      vocabulary_id: 11,
      parent_id: null,
      id: 810,
      _second_name: 'Moldovan Leu',
      _name: 'MDL'
    }, {
      vocabulary_item_term_ids: [1400, 1401],
      vocabulary_id: 11,
      parent_id: null,
      id: 830,
      _second_name: 'Djibouti Franc',
      _name: 'DJF'
    }, {
      vocabulary_item_term_ids: [1448],
      vocabulary_id: 13,
      parent_id: null,
      id: 863,
      _second_name: null,
      _name: 'Sold'
    }, {
      vocabulary_item_term_ids: [1452],
      vocabulary_id: 13,
      parent_id: null,
      id: 867,
      _second_name: null,
      _name: 'Unsold'
    }, {
      vocabulary_item_term_ids: [1616],
      vocabulary_id: 17,
      parent_id: null,
      id: 1003,
      _second_name: null,
      _name: 'Maldivian'
    }, {
      vocabulary_item_term_ids: [1620],
      vocabulary_id: 17,
      parent_id: null,
      id: 1007,
      _second_name: null,
      _name: 'Macedonian'
    }, {
      vocabulary_item_term_ids: [1666],
      vocabulary_id: 17,
      parent_id: null,
      id: 1043,
      _second_name: null,
      _name: 'Honduran'
    }, {
      vocabulary_item_term_ids: [1674, 1675],
      vocabulary_id: 17,
      parent_id: null,
      id: 1050,
      _second_name: 'Guambat',
      _name: 'Guamanian'
    }, {
      vocabulary_item_term_ids: [1757],
      vocabulary_id: 17,
      parent_id: null,
      id: 1125,
      _second_name: null,
      _name: 'Australian'
    }, {
      vocabulary_item_term_ids: [1761],
      vocabulary_id: 17,
      parent_id: null,
      id: 1129,
      _second_name: null,
      _name: 'Barbudan'
    }, {
      vocabulary_item_term_ids: [1765],
      vocabulary_id: 17,
      parent_id: null,
      id: 1133,
      _second_name: null,
      _name: 'Angolan'
    }, {
      vocabulary_item_term_ids: [1769],
      vocabulary_id: 17,
      parent_id: null,
      id: 1137,
      _second_name: null,
      _name: 'Albanian'
    }, {
      vocabulary_item_term_ids: [2981],
      vocabulary_id: 29,
      parent_id: null,
      id: 2293,
      _second_name: null,
      _name: 'Pharmacy'
    }, {
      vocabulary_item_term_ids: [2985],
      vocabulary_id: 29,
      parent_id: null,
      id: 2297,
      _second_name: null,
      _name: 'Urban Planning'
    }, {
      vocabulary_item_term_ids: [2989],
      vocabulary_id: 29,
      parent_id: null,
      id: 2301,
      _second_name: null,
      _name: 'Surgery '
    }, {
      vocabulary_item_term_ids: [2993],
      vocabulary_id: 29,
      parent_id: null,
      id: 2305,
      _second_name: null,
      _name: 'Science in Taxation'
    }, {
      vocabulary_item_term_ids: [2997],
      vocabulary_id: 29,
      parent_id: null,
      id: 2309,
      _second_name: null,
      _name: 'Science in Project Management'
    }, {
      vocabulary_item_term_ids: [3001],
      vocabulary_id: 29,
      parent_id: null,
      id: 2313,
      _second_name: null,
      _name: 'Science in Information Systems Management'
    }, {
      vocabulary_item_term_ids: [3005],
      vocabulary_id: 29,
      parent_id: null,
      id: 2317,
      _second_name: null,
      _name: 'Science in Education'
    }, {
      vocabulary_item_term_ids: [3009],
      vocabulary_id: 29,
      parent_id: null,
      id: 2321,
      _second_name: null,
      _name: 'Research'
    }, {
      vocabulary_item_term_ids: [3013],
      vocabulary_id: 29,
      parent_id: null,
      id: 2325,
      _second_name: null,
      _name: 'Quantitative Finance'
    }, {
      vocabulary_item_term_ids: [3017],
      vocabulary_id: 29,
      parent_id: null,
      id: 2329,
      _second_name: null,
      _name: 'Public Affairs'
    }, {
      vocabulary_item_term_ids: [3021],
      vocabulary_id: 29,
      parent_id: null,
      id: 2333,
      _second_name: null,
      _name: 'Physician Assistant Studies'
    }, {
      vocabulary_item_term_ids: [3135],
      vocabulary_id: 30,
      parent_id: null,
      id: 2447,
      _second_name: null,
      _name: 'Doctor'
    }, {
      vocabulary_item_term_ids: [3162],
      vocabulary_id: 35,
      parent_id: null,
      id: 2474,
      _second_name: null,
      _name: 'country'
    }, {
      vocabulary_item_term_ids: [2078, 2079],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1404,
      _second_name: 'B.C.',
      _name: 'British Columbian '
    }, {
      vocabulary_item_term_ids: [2037, 2035, 2036],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1369,
      _second_name: 'Goenkar',
      _name: 'Goan '
    }, {
      vocabulary_item_term_ids: [2012],
      vocabulary_id: 17,
      parent_id: 1039,
      id: 1351,
      _second_name: null,
      _name: 'Rajasthani'
    }, {
      vocabulary_item_term_ids: [1950],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1300,
      _second_name: null,
      _name: 'Northland'
    }, {
      vocabulary_item_term_ids: [1946],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1296,
      _second_name: null,
      _name: 'Tasman'
    }, {
      vocabulary_item_term_ids: [653, 654],
      vocabulary_id: 6,
      parent_id: null,
      id: 373,
      _second_name: 'English',
      _name: 'en'
    }, {
      vocabulary_item_term_ids: [705, 706],
      vocabulary_id: 9,
      parent_id: null,
      id: 424,
      _second_name: 'pennyweight ',
      _name: 'dwt'
    }, {
      vocabulary_item_term_ids: [779],
      vocabulary_id: 10,
      parent_id: null,
      id: 481,
      _second_name: null,
      _name: 'Transportation'
    }, {
      vocabulary_item_term_ids: [803],
      vocabulary_id: 10,
      parent_id: null,
      id: 505,
      _second_name: null,
      _name: 'Collectibles and Memorabilia'
    }, {
      vocabulary_item_term_ids: [807],
      vocabulary_id: 10,
      parent_id: null,
      id: 509,
      _second_name: null,
      _name: 'Fine Art'
    }, {
      vocabulary_item_term_ids: [811],
      vocabulary_id: 10,
      parent_id: null,
      id: 513,
      _second_name: null,
      _name: 'Antiquities'
    }, {
      vocabulary_item_term_ids: [847],
      vocabulary_id: 10,
      parent_id: null,
      id: 549,
      _second_name: null,
      _name: 'Coins, Currency, and Stamps'
    }, {
      vocabulary_item_term_ids: [871, 872],
      vocabulary_id: 11,
      parent_id: null,
      id: 566,
      _second_name: 'LAJ',
      _name: 'Kip Pot Pol'
    }, {
      vocabulary_item_term_ids: [911, 912],
      vocabulary_id: 11,
      parent_id: null,
      id: 586,
      _second_name: 'Turkmenistan Manat',
      _name: 'TMM'
    }, {
      vocabulary_item_term_ids: [951, 952],
      vocabulary_id: 11,
      parent_id: null,
      id: 606,
      _second_name: 'Portuguese Escudo',
      _name: 'PTE'
    }, {
      vocabulary_item_term_ids: [991, 992],
      vocabulary_id: 11,
      parent_id: null,
      id: 626,
      _second_name: 'MKD',
      _name: 'Denar'
    }, {
      vocabulary_item_term_ids: [1031, 1032],
      vocabulary_id: 11,
      parent_id: null,
      id: 646,
      _second_name: 'ZWC',
      _name: 'Rhodesian Dollar'
    }, {
      vocabulary_item_term_ids: [1071, 1072],
      vocabulary_id: 11,
      parent_id: null,
      id: 666,
      _second_name: 'Tunisian Dinar',
      _name: 'TND'
    }, {
      vocabulary_item_term_ids: [1111, 1112],
      vocabulary_id: 11,
      parent_id: null,
      id: 686,
      _second_name: 'UAK',
      _name: 'Karbovanet'
    }, {
      vocabulary_item_term_ids: [1151, 1152],
      vocabulary_id: 11,
      parent_id: null,
      id: 706,
      _second_name: 'Mauritius Rupee',
      _name: 'MUR'
    }, {
      vocabulary_item_term_ids: [1191, 1192],
      vocabulary_id: 11,
      parent_id: null,
      id: 726,
      _second_name: 'Ouguiya',
      _name: 'MRO'
    }, {
      vocabulary_item_term_ids: [1231, 1232],
      vocabulary_id: 11,
      parent_id: null,
      id: 746,
      _second_name: 'WST',
      _name: 'Tala'
    }, {
      vocabulary_item_term_ids: [1282, 1283],
      vocabulary_id: 11,
      parent_id: null,
      id: 771,
      _second_name: 'Iraqi Dinar',
      _name: 'IQD'
    }, {
      vocabulary_item_term_ids: [1322, 1323],
      vocabulary_id: 11,
      parent_id: null,
      id: 791,
      _second_name: 'Maltese Lira',
      _name: 'MTL'
    }, {
      vocabulary_item_term_ids: [1362, 1363],
      vocabulary_id: 11,
      parent_id: null,
      id: 811,
      _second_name: 'Libyan Dinar',
      _name: 'LYD'
    }, {
      vocabulary_item_term_ids: [1402, 1403],
      vocabulary_id: 11,
      parent_id: null,
      id: 831,
      _second_name: 'Unidad de Valor Real',
      _name: 'COU'
    }, {
      vocabulary_item_term_ids: [1556],
      vocabulary_id: 17,
      parent_id: null,
      id: 952,
      _second_name: null,
      _name: 'Saint Helenian'
    }, {
      vocabulary_item_term_ids: [1560],
      vocabulary_id: 17,
      parent_id: null,
      id: 956,
      _second_name: null,
      _name: 'Russian'
    }, {
      vocabulary_item_term_ids: [1626],
      vocabulary_id: 17,
      parent_id: null,
      id: 1012,
      _second_name: null,
      _name: 'Libyan'
    }, {
      vocabulary_item_term_ids: [1630],
      vocabulary_id: 17,
      parent_id: null,
      id: 1016,
      _second_name: null,
      _name: 'Latvian'
    }, {
      vocabulary_item_term_ids: [1641],
      vocabulary_id: 17,
      parent_id: null,
      id: 1022,
      _second_name: null,
      _name: 'North Korean'
    }, {
      vocabulary_item_term_ids: [1672],
      vocabulary_id: 17,
      parent_id: null,
      id: 1048,
      _second_name: null,
      _name: 'Guinean'
    }, {
      vocabulary_item_term_ids: [1676],
      vocabulary_id: 17,
      parent_id: null,
      id: 1051,
      _second_name: null,
      _name: 'Guadeloupe'
    }, {
      vocabulary_item_term_ids: [1712],
      vocabulary_id: 17,
      parent_id: null,
      id: 1085,
      _second_name: null,
      _name: 'Costa Rican'
    }, {
      vocabulary_item_term_ids: [1732],
      vocabulary_id: 17,
      parent_id: null,
      id: 1104,
      _second_name: null,
      _name: 'Bruneian'
    }, {
      vocabulary_item_term_ids: [1736, 1737],
      vocabulary_id: 17,
      parent_id: null,
      id: 1108,
      _second_name: 'Botswanan',
      _name: 'Motswana'
    }, {
      vocabulary_item_term_ids: [1775],
      vocabulary_id: 17,
      parent_id: null,
      id: 1142,
      _second_name: null,
      _name: 'Xanthian'
    }, {
      vocabulary_item_term_ids: [1779],
      vocabulary_id: 17,
      parent_id: null,
      id: 1146,
      _second_name: null,
      _name: 'Trichonian'
    }, {
      vocabulary_item_term_ids: [1783],
      vocabulary_id: 17,
      parent_id: null,
      id: 1150,
      _second_name: null,
      _name: 'Thriasian'
    }, {
      vocabulary_item_term_ids: [1787],
      vocabulary_id: 17,
      parent_id: null,
      id: 1154,
      _second_name: null,
      _name: 'Theban'
    }, {
      vocabulary_item_term_ids: [1795],
      vocabulary_id: 17,
      parent_id: null,
      id: 1161,
      _second_name: null,
      _name: 'Suebius'
    }, {
      vocabulary_item_term_ids: [1799],
      vocabulary_id: 17,
      parent_id: null,
      id: 1165,
      _second_name: null,
      _name: 'Sidonian'
    }, {
      vocabulary_item_term_ids: [1803],
      vocabulary_id: 17,
      parent_id: null,
      id: 1169,
      _second_name: null,
      _name: 'Serrean'
    }, {
      vocabulary_item_term_ids: [1814],
      vocabulary_id: 17,
      parent_id: null,
      id: 1175,
      _second_name: null,
      _name: 'Samnite'
    }, {
      vocabulary_item_term_ids: [1818],
      vocabulary_id: 17,
      parent_id: null,
      id: 1179,
      _second_name: null,
      _name: 'Rhithymnian'
    }, {
      vocabulary_item_term_ids: [1858],
      vocabulary_id: 17,
      parent_id: null,
      id: 1218,
      _second_name: null,
      _name: 'Lydian'
    }, {
      vocabulary_item_term_ids: [1862],
      vocabulary_id: 17,
      parent_id: null,
      id: 1222,
      _second_name: null,
      _name: 'Leontinian'
    }, {
      vocabulary_item_term_ids: [1913],
      vocabulary_id: 17,
      parent_id: null,
      id: 1267,
      _second_name: null,
      _name: 'Cantabrian'
    }, {
      vocabulary_item_term_ids: [1917],
      vocabulary_id: 17,
      parent_id: null,
      id: 1271,
      _second_name: null,
      _name: 'Bruttian'
    }, {
      vocabulary_item_term_ids: [2092],
      vocabulary_id: 17,
      parent_id: null,
      id: 1417,
      _second_name: null,
      _name: 'North American'
    }, {
      vocabulary_item_term_ids: [2102],
      vocabulary_id: 18,
      parent_id: null,
      id: 1427,
      _second_name: null,
      _name: 'circle of'
    }, {
      vocabulary_item_term_ids: [2106],
      vocabulary_id: 18,
      parent_id: null,
      id: 1431,
      _second_name: null,
      _name: 'pupil of'
    }, {
      vocabulary_item_term_ids: [2110],
      vocabulary_id: 18,
      parent_id: null,
      id: 1435,
      _second_name: null,
      _name: 'workshop of'
    }, {
      vocabulary_item_term_ids: [2963],
      vocabulary_id: 26,
      parent_id: null,
      id: 2275,
      _second_name: null,
      _name: 'Signet'
    }, {
      vocabulary_item_term_ids: [2967],
      vocabulary_id: 26,
      parent_id: null,
      id: 2279,
      _second_name: null,
      _name: 'Monogram'
    }, {
      vocabulary_item_term_ids: [2971],
      vocabulary_id: 26,
      parent_id: null,
      id: 2283,
      _second_name: null,
      _name: 'Date'
    }, {
      vocabulary_item_term_ids: [3167],
      vocabulary_id: 37,
      parent_id: null,
      id: 2479,
      _second_name: null,
      _name: 'live'
    }, {
      vocabulary_item_term_ids: [2088],
      vocabulary_id: 17,
      parent_id: 1125,
      id: 1413,
      _second_name: null,
      _name: 'Canberran'
    }, {
      vocabulary_item_term_ids: [2084],
      vocabulary_id: 17,
      parent_id: 1125,
      id: 1409,
      _second_name: null,
      _name: 'South Australian'
    }, {
      vocabulary_item_term_ids: [2080],
      vocabulary_id: 17,
      parent_id: 1097,
      id: 1405,
      _second_name: null,
      _name: 'Albertan'
    }, {
      vocabulary_item_term_ids: [1956, 1957],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1305,
      _second_name: 'Gisborne',
      _name: 'East Coast'
    }, {
      vocabulary_item_term_ids: [855],
      vocabulary_id: 10,
      parent_id: 512,
      id: 557,
      _second_name: null,
      _name: 'Correspondence'
    }, {
      vocabulary_item_term_ids: [851],
      vocabulary_id: 10,
      parent_id: 509,
      id: 553,
      _second_name: null,
      _name: 'Miniatures'
    }, {
      vocabulary_item_term_ids: [843],
      vocabulary_id: 10,
      parent_id: 509,
      id: 545,
      _second_name: null,
      _name: 'Installations'
    }, {
      vocabulary_item_term_ids: [839],
      vocabulary_id: 10,
      parent_id: 550,
      id: 541,
      _second_name: null,
      _name: 'Medals and Decorations'
    }, {
      vocabulary_item_term_ids: [835],
      vocabulary_id: 10,
      parent_id: 509,
      id: 537,
      _second_name: null,
      _name: 'Tiles'
    }, {
      vocabulary_item_term_ids: [831],
      vocabulary_id: 10,
      parent_id: 509,
      id: 533,
      _second_name: null,
      _name: 'Hanging Scrolls'
    }, {
      vocabulary_item_term_ids: [827],
      vocabulary_id: 10,
      parent_id: 550,
      id: 529,
      _second_name: null,
      _name: 'Uniforms'
    }, {
      vocabulary_item_term_ids: [823],
      vocabulary_id: 10,
      parent_id: 509,
      id: 525,
      _second_name: null,
      _name: 'Works on Paper'
    }, {
      vocabulary_item_term_ids: [819],
      vocabulary_id: 10,
      parent_id: 505,
      id: 521,
      _second_name: null,
      _name: 'Toys and Dolls'
    }, {
      vocabulary_item_term_ids: [815],
      vocabulary_id: 10,
      parent_id: 549,
      id: 517,
      _second_name: null,
      _name: 'Certificates'
    }, {
      vocabulary_item_term_ids: [799],
      vocabulary_id: 10,
      parent_id: 505,
      id: 501,
      _second_name: null,
      _name: 'Comic Books'
    }, {
      vocabulary_item_term_ids: [795],
      vocabulary_id: 10,
      parent_id: 512,
      id: 497,
      _second_name: null,
      _name: 'Books and Manuscripts'
    }, {
      vocabulary_item_term_ids: [791],
      vocabulary_id: 10,
      parent_id: 509,
      id: 493,
      _second_name: null,
      _name: 'Photographs'
    }, {
      vocabulary_item_term_ids: [787],
      vocabulary_id: 10,
      parent_id: 466,
      id: 489,
      _second_name: null,
      _name: 'Pianos'
    }, {
      vocabulary_item_term_ids: [783],
      vocabulary_id: 10,
      parent_id: 491,
      id: 485,
      _second_name: null,
      _name: 'Natural History'
    }, {
      vocabulary_item_term_ids: [775],
      vocabulary_id: 10,
      parent_id: 524,
      id: 477,
      _second_name: null,
      _name: 'Silver'
    }, {
      vocabulary_item_term_ids: [771],
      vocabulary_id: 10,
      parent_id: 464,
      id: 473,
      _second_name: null,
      _name: 'Aboriginal'
    }, {
      vocabulary_item_term_ids: [767],
      vocabulary_id: 10,
      parent_id: 481,
      id: 469,
      _second_name: null,
      _name: 'Aeronautical'
    }, {
      vocabulary_item_term_ids: [763],
      vocabulary_id: 10,
      parent_id: 491,
      id: 465,
      _second_name: null,
      _name: 'Fine Minerals'
    }, {
      vocabulary_item_term_ids: [759],
      vocabulary_id: 10,
      parent_id: 448,
      id: 461,
      _second_name: null,
      _name: 'History of Science'
    }, {
      vocabulary_item_term_ids: [755],
      vocabulary_id: 10,
      parent_id: 466,
      id: 457,
      _second_name: null,
      _name: 'String Instruments'
    }, {
      vocabulary_item_term_ids: [751],
      vocabulary_id: 10,
      parent_id: 474,
      id: 453,
      _second_name: null,
      _name: 'Sake'
    }, {
      vocabulary_item_term_ids: [747],
      vocabulary_id: 10,
      parent_id: 464,
      id: 449,
      _second_name: null,
      _name: 'North American'
    }, {
      vocabulary_item_term_ids: [707],
      vocabulary_id: 9,
      parent_id: null,
      id: 425,
      _second_name: null,
      _name: 'grain'
    }, {
      vocabulary_item_term_ids: [874, 873],
      vocabulary_id: 11,
      parent_id: null,
      id: 567,
      _second_name: 'Old Shekel',
      _name: 'ILR'
    }, {
      vocabulary_item_term_ids: [914, 913],
      vocabulary_id: 11,
      parent_id: null,
      id: 587,
      _second_name: 'SUR',
      _name: 'Rouble'
    }, {
      vocabulary_item_term_ids: [954, 953],
      vocabulary_id: 11,
      parent_id: null,
      id: 607,
      _second_name: 'Lithuanian Litas',
      _name: 'LTL'
    }, {
      vocabulary_item_term_ids: [994, 993],
      vocabulary_id: 11,
      parent_id: null,
      id: 627,
      _second_name: 'Won',
      _name: 'KRW'
    }, {
      vocabulary_item_term_ids: [1034, 1033],
      vocabulary_id: 11,
      parent_id: null,
      id: 647,
      _second_name: 'Rufiyaa',
      _name: 'MVR'
    }, {
      vocabulary_item_term_ids: [1074, 1073],
      vocabulary_id: 11,
      parent_id: null,
      id: 667,
      _second_name: 'LAK',
      _name: 'Kip'
    }, {
      vocabulary_item_term_ids: [1114, 1113],
      vocabulary_id: 11,
      parent_id: null,
      id: 687,
      _second_name: 'Timor Escudo',
      _name: 'TPE'
    }, {
      vocabulary_item_term_ids: [1154, 1153],
      vocabulary_id: 11,
      parent_id: null,
      id: 707,
      _second_name: 'Sudanese Pound',
      _name: 'SDP'
    }, {
      vocabulary_item_term_ids: [1194, 1193],
      vocabulary_id: 11,
      parent_id: null,
      id: 727,
      _second_name: 'Liberian Dollar',
      _name: 'LRD'
    }, {
      vocabulary_item_term_ids: [1234, 1233],
      vocabulary_id: 11,
      parent_id: null,
      id: 747,
      _second_name: 'Euro',
      _name: 'EUR'
    }, {
      vocabulary_item_term_ids: [1285, 1284],
      vocabulary_id: 11,
      parent_id: null,
      id: 772,
      _second_name: 'GMD',
      _name: 'Dalasi'
    }, {
      vocabulary_item_term_ids: [1325, 1324],
      vocabulary_id: 11,
      parent_id: null,
      id: 792,
      _second_name: 'Sudanese Dinar',
      _name: 'SDD'
    }, {
      vocabulary_item_term_ids: [1365, 1364],
      vocabulary_id: 11,
      parent_id: null,
      id: 812,
      _second_name: 'Mozambique Metical',
      _name: 'MZN'
    }, {
      vocabulary_item_term_ids: [1405, 1404],
      vocabulary_id: 11,
      parent_id: null,
      id: 832,
      _second_name: 'Chilean Peso',
      _name: 'CLP'
    }, {
      vocabulary_item_term_ids: [1439],
      vocabulary_id: 12,
      parent_id: null,
      id: 854,
      _second_name: null,
      _name: 'weight'
    }, {
      vocabulary_item_term_ids: [1443],
      vocabulary_id: 12,
      parent_id: null,
      id: 858,
      _second_name: null,
      _name: 'circumference'
    }, {
      vocabulary_item_term_ids: [1447],
      vocabulary_id: 12,
      parent_id: null,
      id: 862,
      _second_name: null,
      _name: 'thickness'
    }, {
      vocabulary_item_term_ids: [1470],
      vocabulary_id: 17,
      parent_id: null,
      id: 885,
      _second_name: null,
      _name: 'Zambian'
    }, {
      vocabulary_item_term_ids: [1500],
      vocabulary_id: 17,
      parent_id: null,
      id: 905,
      _second_name: null,
      _name: 'Tuvaluan'
    }, {
      vocabulary_item_term_ids: [1504],
      vocabulary_id: 17,
      parent_id: null,
      id: 909,
      _second_name: null,
      _name: 'Tunisian'
    }, {
      vocabulary_item_term_ids: [1539],
      vocabulary_id: 17,
      parent_id: null,
      id: 939,
      _second_name: null,
      _name: 'Seychellois'
    }, {
      vocabulary_item_term_ids: [1566],
      vocabulary_id: 17,
      parent_id: null,
      id: 961,
      _second_name: null,
      _name: 'Portuguese'
    }, {
      vocabulary_item_term_ids: [1594, 1593],
      vocabulary_id: 17,
      parent_id: null,
      id: 983,
      _second_name: 'Nepali',
      _name: 'Nepalese'
    }, {
      vocabulary_item_term_ids: [1647],
      vocabulary_id: 17,
      parent_id: null,
      id: 1027,
      _second_name: null,
      _name: 'Channel Island'
    }, {
      vocabulary_item_term_ids: [1651],
      vocabulary_id: 17,
      parent_id: null,
      id: 1031,
      _second_name: null,
      _name: 'Ivorian'
    }, {
      vocabulary_item_term_ids: [1655],
      vocabulary_id: 17,
      parent_id: null,
      id: 1035,
      _second_name: null,
      _name: 'Irish'
    }, {
      vocabulary_item_term_ids: [1682],
      vocabulary_id: 17,
      parent_id: null,
      id: 1056,
      _second_name: null,
      _name: 'Ghanaian'
    }, {
      vocabulary_item_term_ids: [1686],
      vocabulary_id: 17,
      parent_id: null,
      id: 1060,
      _second_name: null,
      _name: 'Gabonese'
    }, {
      vocabulary_item_term_ids: [1690],
      vocabulary_id: 17,
      parent_id: null,
      id: 1064,
      _second_name: null,
      _name: 'French'
    }, {
      vocabulary_item_term_ids: [1694],
      vocabulary_id: 17,
      parent_id: null,
      id: 1068,
      _second_name: null,
      _name: 'Falkland Island'
    }, {
      vocabulary_item_term_ids: [1698],
      vocabulary_id: 17,
      parent_id: null,
      id: 1072,
      _second_name: null,
      _name: 'Eritrean'
    }, {
      vocabulary_item_term_ids: [1706],
      vocabulary_id: 17,
      parent_id: null,
      id: 1079,
      _second_name: null,
      _name: 'Danish'
    }, {
      vocabulary_item_term_ids: [1710],
      vocabulary_id: 17,
      parent_id: null,
      id: 1083,
      _second_name: null,
      _name: 'Cuban'
    }, {
      vocabulary_item_term_ids: [1718],
      vocabulary_id: 17,
      parent_id: null,
      id: 1090,
      _second_name: null,
      _name: 'Cocos Island'
    }, {
      vocabulary_item_term_ids: [1722],
      vocabulary_id: 17,
      parent_id: null,
      id: 1094,
      _second_name: null,
      _name: 'Chadian'
    }, {
      vocabulary_item_term_ids: [1726],
      vocabulary_id: 17,
      parent_id: null,
      id: 1098,
      _second_name: null,
      _name: 'Cameroonian'
    }, {
      vocabulary_item_term_ids: [1738],
      vocabulary_id: 17,
      parent_id: null,
      id: 1109,
      _second_name: null,
      _name: 'Herzegovinian'
    }, {
      vocabulary_item_term_ids: [1742],
      vocabulary_id: 17,
      parent_id: null,
      id: 1113,
      _second_name: null,
      _name: 'Bhutanese'
    }, {
      vocabulary_item_term_ids: [1824],
      vocabulary_id: 17,
      parent_id: null,
      id: 1184,
      _second_name: null,
      _name: 'Pisidian'
    }, {
      vocabulary_item_term_ids: [1828],
      vocabulary_id: 17,
      parent_id: null,
      id: 1188,
      _second_name: null,
      _name: 'Phliasian'
    }, {
      vocabulary_item_term_ids: [1832],
      vocabulary_id: 17,
      parent_id: null,
      id: 1192,
      _second_name: null,
      _name: 'Peloponnesian'
    }, {
      vocabulary_item_term_ids: [1836],
      vocabulary_id: 17,
      parent_id: null,
      id: 1196,
      _second_name: null,
      _name: 'Paeonian'
    }, {
      vocabulary_item_term_ids: [1840],
      vocabulary_id: 17,
      parent_id: null,
      id: 1200,
      _second_name: null,
      _name: 'Oean'
    }, {
      vocabulary_item_term_ids: [1844],
      vocabulary_id: 17,
      parent_id: null,
      id: 1204,
      _second_name: null,
      _name: 'Mytilenean'
    }, {
      vocabulary_item_term_ids: [1848],
      vocabulary_id: 17,
      parent_id: null,
      id: 1208,
      _second_name: null,
      _name: 'Milesian'
    }, {
      vocabulary_item_term_ids: [1852],
      vocabulary_id: 17,
      parent_id: null,
      id: 1212,
      _second_name: null,
      _name: 'Melitian'
    }, {
      vocabulary_item_term_ids: [1856],
      vocabulary_id: 17,
      parent_id: null,
      id: 1216,
      _second_name: null,
      _name: 'Mantinean'
    }, {
      vocabulary_item_term_ids: [1868],
      vocabulary_id: 17,
      parent_id: null,
      id: 1227,
      _second_name: null,
      _name: 'Kaulonian'
    }, {
      vocabulary_item_term_ids: [1872],
      vocabulary_id: 17,
      parent_id: null,
      id: 1231,
      _second_name: null,
      _name: 'Iberian'
    }, {
      vocabulary_item_term_ids: [2976],
      vocabulary_id: 28,
      parent_id: null,
      id: 2288,
      _second_name: null,
      _name: 'Fixed price'
    }, {
      vocabulary_item_term_ids: [3118],
      vocabulary_id: 29,
      parent_id: null,
      id: 2430,
      _second_name: null,
      _name: 'Applied Science in Information Technology'
    }, {
      vocabulary_item_term_ids: [3122],
      vocabulary_id: 29,
      parent_id: null,
      id: 2434,
      _second_name: null,
      _name: 'Physical Therapy'
    }, {
      vocabulary_item_term_ids: [3126],
      vocabulary_id: 29,
      parent_id: null,
      id: 2438,
      _second_name: null,
      _name: 'Degree in Nursing'
    }, {
      vocabulary_item_term_ids: [3130],
      vocabulary_id: 29,
      parent_id: null,
      id: 2442,
      _second_name: null,
      _name: 'Applied Business'
    }, {
      vocabulary_item_term_ids: [1978],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1325,
      _second_name: null,
      _name: 'Lower Californian'
    }, {
      vocabulary_item_term_ids: [1974],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1321,
      _second_name: null,
      _name: 'Chihuahuan'
    }, {
      vocabulary_item_term_ids: [1970],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1317,
      _second_name: null,
      _name: 'Jaliscan'
    }, {
      vocabulary_item_term_ids: [1966],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1314,
      _second_name: null,
      _name: 'Quintanaroan'
    }, {
      vocabulary_item_term_ids: [1962],
      vocabulary_id: 17,
      parent_id: 995,
      id: 1310,
      _second_name: null,
      _name: 'Tamaulipan'
    }, {
      vocabulary_item_term_ids: [1958],
      vocabulary_id: 17,
      parent_id: 981,
      id: 1306,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20000],
      vocabulary_id: 42,
      parent_id: null,
      id: 20000,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20001],
      vocabulary_id: 41,
      parent_id: null,
      id: 20001,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20100],
      vocabulary_id: 43,
      parent_id: null,
      id: 20100,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20200],
      vocabulary_id: 44,
      parent_id: null,
      id: 20200,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20300],
      vocabulary_id: 45,
      parent_id: null,
      id: 20300,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20400],
      vocabulary_id: 46,
      parent_id: null,
      id: 20400,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20500],
      vocabulary_id: 47,
      parent_id: null,
      id: 20500,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20600],
      vocabulary_id: 48,
      parent_id: null,
      id: 20600,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20700],
      vocabulary_id: 49,
      parent_id: null,
      id: 20700,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20800],
      vocabulary_id: 50,
      parent_id: null,
      id: 20800,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20900],
      vocabulary_id: 60,
      parent_id: null,
      id: 20900,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [21000],
      vocabulary_id: 61,
      parent_id: null,
      id: 20700,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [21100],
      vocabulary_id: 62,
      parent_id: null,
      id: 21100,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [21200],
      vocabulary_id: 63,
      parent_id: null,
      id: 21200,
      _second_name: null,
      _name: 'Canterbury'
    }, {
      vocabulary_item_term_ids: [20011],
      vocabulary_id: 64,
      parent_id: null,
      id: 20011,
      _second_name: null,
      _name: 'Apple'
    }, {
      vocabulary_item_term_ids: [20022],
      vocabulary_id: 66,
      parent_id: null,
      id: 20022,
      _second_name: null,
      _name: 'Spec'
    }, {
      vocabulary_item_term_ids: [20021],
      vocabulary_id: 65,
      parent_id: null,
      id: 20021,
      _second_name: null,
      _name: 'Dep'
    }, {
      vocabulary_item_term_ids: [20032],
      vocabulary_id: 67,
      parent_id: null,
      id: 20032,
      _second_name: null,
      _name: 'JDE'
    }, {
      vocabulary_item_term_ids: [20040],
      vocabulary_id: 68,
      parent_id: null,
      id: 20040,
      _second_name: null,
      _name: 'Citation'
    }],
    vocabulary_item_terms: [{
      vocabulary_item_id: 20040,
      text: 'Citation',
      preferred: true,
      language: 'en',
      id: 20040
    }, {
      vocabulary_item_id: 20032,
      text: 'JDE',
      preferred: true,
      language: 'en',
      id: 20032
    }, {
      vocabulary_item_id: 20022,
      text: 'Spec',
      preferred: true,
      language: 'en',
      id: 20022
    }, {
      vocabulary_item_id: 20021,
      text: 'Dep',
      preferred: true,
      language: 'en',
      id: 20021
    }, {
      vocabulary_item_id: 20011,
      text: 'Apple',
      preferred: true,
      language: 'en',
      id: 20011
    }, {
      vocabulary_item_id: 20700,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 20700
    }, {
      vocabulary_item_id: 20800,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 20800
    }, {
      vocabulary_item_id: 20900,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 20900
    }, {
      vocabulary_item_id: 21000,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 21000
    }, {
      vocabulary_item_id: 21100,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 21100
    }, {
      vocabulary_item_id: 21200,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 21200
    }, {
      vocabulary_item_id: 20600,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 20600
    }, {
      vocabulary_item_id: 20500,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 20500
    }, {
      vocabulary_item_id: 20400,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 20400
    }, {
      vocabulary_item_id: 20300,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 20300
    }, {
      vocabulary_item_id: 20200,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 20200
    }, {
      vocabulary_item_id: 20100,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 20100
    }, {
      vocabulary_item_id: 5,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 5
    }, {
      vocabulary_item_id: 6,
      text: 'War Memorabilia',
      preferred: true,
      language: 'en',
      id: 6
    }, {
      vocabulary_item_id: 20000,
      text: 'War Memorabilia',
      preferred: true,
      language: 'en',
      id: 20000
    }, {
      vocabulary_item_id: 20001,
      text: 'War Memorabilia',
      preferred: true,
      language: 'en',
      id: 20001
    }, {
      vocabulary_item_id: 7,
      text: 'Popular Culture',
      preferred: true,
      language: 'en',
      id: 7
    }, {
      vocabulary_item_id: 8,
      text: 'Tribal Art',
      preferred: true,
      language: 'en',
      id: 8
    }, {
      vocabulary_item_id: 9,
      text: 'Native American',
      preferred: true,
      language: 'en',
      id: 9
    }, {
      vocabulary_item_id: 10,
      text: 'Topographical Art',
      preferred: true,
      language: 'en',
      id: 10
    }, {
      vocabulary_item_id: 11,
      text: 'Swiss Art',
      preferred: true,
      language: 'en',
      id: 11
    }, {
      vocabulary_item_id: 12,
      text: 'Stamps',
      preferred: true,
      language: 'en',
      id: 12
    }, {
      vocabulary_item_id: 13,
      text: 'Maritime Pictures',
      preferred: true,
      language: 'en',
      id: 13
    }, {
      vocabulary_item_id: 14,
      text: 'European Objects',
      preferred: true,
      language: 'en',
      id: 14
    }, {
      vocabulary_item_id: 15,
      text: 'Travel, Science & Natural Hist',
      preferred: true,
      language: 'en',
      id: 15
    }, {
      vocabulary_item_id: 16,
      text: 'Sculpture',
      preferred: true,
      language: 'en',
      id: 16
    }, {
      vocabulary_item_id: 17,
      text: 'Rugs & Carpets',
      preferred: true,
      language: 'en',
      id: 17
    }, {
      vocabulary_item_id: 18,
      text: 'Collectibles',
      preferred: true,
      language: 'en',
      id: 18
    }, {
      vocabulary_item_id: 19,
      text: 'Asian Contemporary Art',
      preferred: true,
      language: 'en',
      id: 19
    }, {
      vocabulary_item_id: 20,
      text: 'Arms & Armour',
      preferred: true,
      language: 'en',
      id: 20
    }, {
      vocabulary_item_id: 21,
      text: 'Cars - Automobilia',
      preferred: true,
      language: 'en',
      id: 21
    }, {
      vocabulary_item_id: 22,
      text: '19th C. Paintings',
      preferred: true,
      language: 'en',
      id: 22
    }, {
      vocabulary_item_id: 23,
      text: 'Indian Contemporary Art',
      preferred: true,
      language: 'en',
      id: 23
    }, {
      vocabulary_item_id: 24,
      text: 'American Furniture & Dec Arts',
      preferred: true,
      language: 'en',
      id: 24
    }, {
      vocabulary_item_id: 25,
      text: '20th Century Decorative Arts',
      preferred: true,
      language: 'en',
      id: 25
    }, {
      vocabulary_item_id: 26,
      text: '19th C. Furniture',
      preferred: true,
      language: 'en',
      id: 26
    }, {
      vocabulary_item_id: 27,
      text: 'Musical Instruments',
      preferred: true,
      language: 'en',
      id: 27
    }, {
      vocabulary_item_id: 28,
      text: 'Impressionist & Modern Art',
      preferred: true,
      language: 'en',
      id: 28
    }, {
      vocabulary_item_id: 29,
      text: 'American Paintings',
      preferred: true,
      language: 'en',
      id: 29
    }, {
      vocabulary_item_id: 30,
      text: 'Posters',
      preferred: true,
      language: 'en',
      id: 30
    }, {
      vocabulary_item_id: 31,
      text: 'Old Master & British Pictures',
      preferred: true,
      language: 'en',
      id: 31
    }, {
      vocabulary_item_id: 32,
      text: 'Motorcycles',
      preferred: true,
      language: 'en',
      id: 32
    }, {
      vocabulary_item_id: 33,
      text: 'Textiles',
      preferred: true,
      language: 'en',
      id: 33
    }, {
      vocabulary_item_id: 34,
      text: 'Dolls Toys Trains',
      preferred: true,
      language: 'en',
      id: 34
    }, {
      vocabulary_item_id: 35,
      text: 'Contemporary Middle Eastern',
      preferred: true,
      language: 'en',
      id: 35
    }, {
      vocabulary_item_id: 36,
      text: 'Cars',
      preferred: true,
      language: 'en',
      id: 36
    }, {
      vocabulary_item_id: 37,
      text: 'Scientific Instruments',
      preferred: true,
      language: 'en',
      id: 37
    }, {
      vocabulary_item_id: 38,
      text: 'Russian Paintings',
      preferred: true,
      language: 'en',
      id: 38
    }, {
      vocabulary_item_id: 39,
      text: 'Antiquities',
      preferred: true,
      language: 'en',
      id: 39
    }, {
      vocabulary_item_id: 40,
      text: 'Old Master Drawings',
      preferred: true,
      language: 'en',
      id: 40
    }, {
      vocabulary_item_id: 41,
      text: 'Japanese & Korean Art',
      preferred: true,
      language: 'en',
      id: 41
    }, {
      vocabulary_item_id: 42,
      text: 'English Furniture',
      preferred: true,
      language: 'en',
      id: 42
    }, {
      vocabulary_item_id: 43,
      text: 'Victorian Pictures',
      preferred: true,
      language: 'en',
      id: 43
    }, {
      vocabulary_item_id: 44,
      text: 'Sporting Art',
      preferred: true,
      language: 'en',
      id: 44
    }, {
      vocabulary_item_id: 45,
      text: 'Southeast Asian Art',
      preferred: true,
      language: 'en',
      id: 45
    }, {
      vocabulary_item_id: 46,
      text: 'Modern Sporting Guns',
      preferred: true,
      language: 'en',
      id: 46
    }, {
      vocabulary_item_id: 47,
      text: 'Furniture & Objects',
      preferred: true,
      language: 'en',
      id: 47
    }, {
      vocabulary_item_id: 48,
      text: 'Coins',
      preferred: true,
      language: 'en',
      id: 48
    }, {
      vocabulary_item_id: 49,
      text: 'Prints',
      preferred: true,
      language: 'en',
      id: 49
    }, {
      vocabulary_item_id: 50,
      text: 'Jewellery',
      preferred: true,
      language: 'en',
      id: 50
    }, {
      vocabulary_item_id: 51,
      text: 'Interiors',
      preferred: true,
      language: 'en',
      id: 51
    }, {
      vocabulary_item_id: 52,
      text: 'Russian Art',
      preferred: true,
      language: 'en',
      id: 52
    }, {
      vocabulary_item_id: 53,
      text: 'British Drawings & Watercolors',
      preferred: true,
      language: 'en',
      id: 53
    }, {
      vocabulary_item_id: 54,
      text: 'Silver',
      preferred: true,
      language: 'en',
      id: 54
    }, {
      vocabulary_item_id: 55,
      text: 'Porcelain & Glass',
      preferred: true,
      language: 'en',
      id: 55
    }, {
      vocabulary_item_id: 56,
      text: 'Photographs',
      preferred: true,
      language: 'en',
      id: 56
    }, {
      vocabulary_item_id: 57,
      text: 'Pre-Columbian Art',
      preferred: true,
      language: 'en',
      id: 57
    }, {
      vocabulary_item_id: 58,
      text: 'Clocks',
      preferred: true,
      language: 'en',
      id: 58
    }, {
      vocabulary_item_id: 59,
      text: 'Latin American Paintings',
      preferred: true,
      language: 'en',
      id: 59
    }, {
      vocabulary_item_id: 60,
      text: 'Chinese Works of Art',
      preferred: true,
      language: 'en',
      id: 60
    }, {
      vocabulary_item_id: 61,
      text: 'Islamic Art',
      preferred: true,
      language: 'en',
      id: 61
    }, {
      vocabulary_item_id: 62,
      text: 'Chinese Paintings',
      preferred: true,
      language: 'en',
      id: 62
    }, {
      vocabulary_item_id: 63,
      text: 'Italian PW',
      preferred: true,
      language: 'en',
      id: 63
    }, {
      vocabulary_item_id: 64,
      text: 'Handbags',
      preferred: true,
      language: 'en',
      id: 64
    }, {
      vocabulary_item_id: 65,
      text: 'House Sales',
      preferred: true,
      language: 'en',
      id: 65
    }, {
      vocabulary_item_id: 66,
      text: 'PWCA',
      preferred: true,
      language: 'en',
      id: 66
    }, {
      vocabulary_item_id: 67,
      text: 'Gold Boxes',
      preferred: true,
      language: 'en',
      id: 67
    }, {
      vocabulary_item_id: 68,
      text: 'Australian Paintings',
      preferred: true,
      language: 'en',
      id: 68
    }, {
      vocabulary_item_id: 69,
      text: '20th Century British Art',
      preferred: true,
      language: 'en',
      id: 69
    }, {
      vocabulary_item_id: 70,
      text: 'Portrait Miniatures',
      preferred: true,
      language: 'en',
      id: 70
    }, {
      vocabulary_item_id: 71,
      text: "Non Christie's Categories",
      preferred: true,
      language: 'en',
      id: 71
    }, {
      vocabulary_item_id: 72,
      text: 'Mechanical Music',
      preferred: true,
      language: 'en',
      id: 72
    }, {
      vocabulary_item_id: 73,
      text: 'European Furniture',
      preferred: true,
      language: 'en',
      id: 73
    }, {
      vocabulary_item_id: 74,
      text: 'Couture',
      preferred: true,
      language: 'en',
      id: 74
    }, {
      vocabulary_item_id: 75,
      text: 'Contemporary SEA',
      preferred: true,
      language: 'en',
      id: 75
    }, {
      vocabulary_item_id: 76,
      text: 'Books & Manuscripts - Comics',
      preferred: true,
      language: 'en',
      id: 76
    }, {
      vocabulary_item_id: 77,
      text: 'Wine',
      preferred: true,
      language: 'en',
      id: 77
    }, {
      vocabulary_item_id: 78,
      text: 'Cameras',
      preferred: true,
      language: 'en',
      id: 78
    }, {
      vocabulary_item_id: 79,
      text: 'British & Irish Art',
      preferred: true,
      language: 'en',
      id: 79
    }, {
      vocabulary_item_id: 80,
      text: 'Books & Manuscripts',
      preferred: true,
      language: 'en',
      id: 80
    }, {
      vocabulary_item_id: 81,
      text: 'PWCA',
      preferred: true,
      language: 'en',
      id: 81
    }, {
      vocabulary_item_id: 82,
      text: "Non Christie's Categories",
      preferred: true,
      language: 'en',
      id: 82
    }, {
      vocabulary_item_id: 83,
      text: 'World Art',
      preferred: true,
      language: 'en',
      id: 83
    }, {
      vocabulary_item_id: 84,
      text: 'Science & Books',
      preferred: true,
      language: 'en',
      id: 84
    }, {
      vocabulary_item_id: 85,
      text: 'OMNC & Russian',
      preferred: true,
      language: 'en',
      id: 85
    }, {
      vocabulary_item_id: 86,
      text: 'Luxury',
      preferred: true,
      language: 'en',
      id: 86
    }, {
      vocabulary_item_id: 87,
      text: 'Asian Art',
      preferred: true,
      language: 'en',
      id: 87
    }, {
      vocabulary_item_id: 88,
      text: 'Imp Mod',
      preferred: true,
      language: 'en',
      id: 88
    }, {
      vocabulary_item_id: 89,
      text: 'Decorative Arts & American Furniture',
      preferred: true,
      language: 'en',
      id: 89
    }, {
      vocabulary_item_id: 90,
      text: '20/21',
      preferred: true,
      language: 'en',
      id: 90
    }, {
      vocabulary_item_id: 428,
      text: 'kt',
      preferred: true,
      language: 'en',
      id: 711
    }, {
      vocabulary_item_id: 434,
      text: 'ms',
      preferred: true,
      language: 'en',
      id: 722
    }, {
      vocabulary_item_id: 440,
      text: 'milligram',
      preferred: false,
      language: 'en',
      id: 733
    }, {
      vocabulary_item_id: 572,
      text: 'ROL',
      preferred: false,
      language: 'en',
      id: 884
    }, {
      vocabulary_item_id: 578,
      text: 'RUR',
      preferred: true,
      language: 'en',
      id: 895
    }, {
      vocabulary_item_id: 592,
      text: 'Zloty',
      preferred: false,
      language: 'en',
      id: 924
    }, {
      vocabulary_item_id: 598,
      text: 'El Salvador Colon',
      preferred: true,
      language: 'en',
      id: 935
    }, {
      vocabulary_item_id: 612,
      text: 'Ekwele',
      preferred: false,
      language: 'en',
      id: 964
    }, {
      vocabulary_item_id: 618,
      text: 'ARA',
      preferred: true,
      language: 'en',
      id: 975
    }, {
      vocabulary_item_id: 632,
      text: 'Guinea-Bissau Peso',
      preferred: false,
      language: 'en',
      id: 1004
    }, {
      vocabulary_item_id: 638,
      text: 'ZMW',
      preferred: true,
      language: 'en',
      id: 1015
    }, {
      vocabulary_item_id: 652,
      text: 'Spanish Peseta',
      preferred: false,
      language: 'en',
      id: 1044
    }, {
      vocabulary_item_id: 658,
      text: 'Cordoba Oro',
      preferred: true,
      language: 'en',
      id: 1055
    }, {
      vocabulary_item_id: 672,
      text: 'Sri Lanka Rupee',
      preferred: false,
      language: 'en',
      id: 1084
    }, {
      vocabulary_item_id: 678,
      text: 'LUL',
      preferred: true,
      language: 'en',
      id: 1095
    }, {
      vocabulary_item_id: 692,
      text: 'Bulgarian Lev',
      preferred: false,
      language: 'en',
      id: 1124
    }, {
      vocabulary_item_id: 698,
      text: 'AED',
      preferred: true,
      language: 'en',
      id: 1135
    }, {
      vocabulary_item_id: 712,
      text: 'Unidad de Fomento',
      preferred: false,
      language: 'en',
      id: 1164
    }, {
      vocabulary_item_id: 718,
      text: 'BEC',
      preferred: true,
      language: 'en',
      id: 1175
    }, {
      vocabulary_item_id: 732,
      text: 'Quetzal',
      preferred: false,
      language: 'en',
      id: 1204
    }, {
      vocabulary_item_id: 738,
      text: 'ISJ',
      preferred: true,
      language: 'en',
      id: 1215
    }, {
      vocabulary_item_id: 752,
      text: 'Saint Helena Pound',
      preferred: false,
      language: 'en',
      id: 1244
    }, {
      vocabulary_item_id: 758,
      text: 'KZT',
      preferred: true,
      language: 'en',
      id: 1255
    }, {
      vocabulary_item_id: 763,
      text: 'CVE',
      preferred: true,
      language: 'en',
      id: 1266
    }, {
      vocabulary_item_id: 777,
      text: 'XRE',
      preferred: false,
      language: 'en',
      id: 1295
    }, {
      vocabulary_item_id: 783,
      text: 'MXV',
      preferred: true,
      language: 'en',
      id: 1306
    }, {
      vocabulary_item_id: 797,
      text: 'Deutsche Mark',
      preferred: false,
      language: 'en',
      id: 1335
    }, {
      vocabulary_item_id: 803,
      text: 'ADB Unit of Account',
      preferred: true,
      language: 'en',
      id: 1346
    }, {
      vocabulary_item_id: 817,
      text: 'Danish Krone',
      preferred: false,
      language: 'en',
      id: 1375
    }, {
      vocabulary_item_id: 823,
      text: 'AMD',
      preferred: true,
      language: 'en',
      id: 1386
    }, {
      vocabulary_item_id: 837,
      text: 'Afghani',
      preferred: false,
      language: 'en',
      id: 1415
    }, {
      vocabulary_item_id: 843,
      text: 'GNF',
      preferred: true,
      language: 'en',
      id: 1426
    }, {
      vocabulary_item_id: 846,
      text: 'fineness',
      preferred: true,
      language: 'en',
      id: 1431
    }, {
      vocabulary_item_id: 850,
      text: 'odometer',
      preferred: true,
      language: 'en',
      id: 1435
    }, {
      vocabulary_item_id: 869,
      text: 'Available on redshift',
      preferred: true,
      language: 'en',
      id: 1454
    }, {
      vocabulary_item_id: 873,
      text: 'Catalog unavailable',
      preferred: true,
      language: 'en',
      id: 1458
    }, {
      vocabulary_item_id: 912,
      text: 'Tokelauan',
      preferred: true,
      language: 'en',
      id: 1508
    }, {
      vocabulary_item_id: 916,
      text: 'Tanzanian',
      preferred: true,
      language: 'en',
      id: 1512
    }, {
      vocabulary_item_id: 920,
      text: 'Swedish',
      preferred: true,
      language: 'en',
      id: 1516
    }, {
      vocabulary_item_id: 924,
      text: 'Sudanese',
      preferred: true,
      language: 'en',
      id: 1520
    }, {
      vocabulary_item_id: 928,
      text: 'South Ossetian',
      preferred: true,
      language: 'en',
      id: 1524
    }, {
      vocabulary_item_id: 942,
      text: 'Scots',
      preferred: false,
      language: 'en',
      id: 1543
    }, {
      vocabulary_item_id: 964,
      text: 'Filipino',
      preferred: false,
      language: 'en',
      id: 1570
    }, {
      vocabulary_item_id: 986,
      text: 'Burmese',
      preferred: true,
      language: 'en',
      id: 1597
    }, {
      vocabulary_item_id: 990,
      text: 'Montenegrin',
      preferred: true,
      language: 'en',
      id: 1601
    }, {
      vocabulary_item_id: 1038,
      text: 'Indonesian',
      preferred: true,
      language: 'en',
      id: 1659
    }, {
      vocabulary_item_id: 1075,
      text: 'Egyptian',
      preferred: true,
      language: 'en',
      id: 1702
    }, {
      vocabulary_item_id: 1234,
      text: 'Gallaecus',
      preferred: true,
      language: 'en',
      id: 1876
    }, {
      vocabulary_item_id: 1238,
      text: 'Eretrian',
      preferred: true,
      language: 'en',
      id: 1880
    }, {
      vocabulary_item_id: 1242,
      text: 'Ephesian',
      preferred: true,
      language: 'en',
      id: 1884
    }, {
      vocabulary_item_id: 1275,
      text: 'Bavarian',
      preferred: true,
      language: 'en',
      id: 1923
    }, {
      vocabulary_item_id: 1279,
      text: 'Argive',
      preferred: true,
      language: 'en',
      id: 1927
    }, {
      vocabulary_item_id: 1283,
      text: 'Andalusian',
      preferred: true,
      language: 'en',
      id: 1931
    }, {
      vocabulary_item_id: 1287,
      text: 'Achaean',
      preferred: true,
      language: 'en',
      id: 1935
    }, {
      vocabulary_item_id: 1291,
      text: 'South Island',
      preferred: true,
      language: 'en',
      id: 1939
    }, {
      vocabulary_item_id: 1328,
      text: 'Terengganuan',
      preferred: true,
      language: 'en',
      id: 1982
    }, {
      vocabulary_item_id: 1332,
      text: 'Putrajayan',
      preferred: true,
      language: 'en',
      id: 1986
    }, {
      vocabulary_item_id: 1336,
      text: 'Pahangite',
      preferred: true,
      language: 'en',
      id: 1990
    }, {
      vocabulary_item_id: 1340,
      text: 'Kuala Lumpurian',
      preferred: true,
      language: 'en',
      id: 1994
    }, {
      vocabulary_item_id: 1344,
      text: 'Bengali ',
      preferred: true,
      language: 'en',
      id: 1998
    }, {
      vocabulary_item_id: 1346,
      text: 'Uttar Pradeshi',
      preferred: true,
      language: 'en',
      id: 2005
    }, {
      vocabulary_item_id: 1356,
      text: 'Mizo',
      preferred: true,
      language: 'en',
      id: 2020
    }, {
      vocabulary_item_id: 1360,
      text: 'Madhya Pradeshi',
      preferred: true,
      language: 'en',
      id: 2024
    }, {
      vocabulary_item_id: 1370,
      text: 'Delhiite',
      preferred: false,
      language: 'en',
      id: 2039
    }, {
      vocabulary_item_id: 1384,
      text: 'Prussian',
      preferred: true,
      language: 'en',
      id: 2055
    }, {
      vocabulary_item_id: 1395,
      text: 'Quebec',
      preferred: true,
      language: 'en',
      id: 2067
    }, {
      vocabulary_item_id: 1424,
      text: 'African',
      preferred: true,
      language: 'en',
      id: 2099
    }, {
      vocabulary_item_id: 441,
      text: 'km',
      preferred: true,
      language: 'en',
      id: 734
    }, {
      vocabulary_item_id: 567,
      text: 'Old Shekel',
      preferred: false,
      language: 'en',
      id: 874
    }, {
      vocabulary_item_id: 573,
      text: 'ITL',
      preferred: true,
      language: 'en',
      id: 885
    }, {
      vocabulary_item_id: 587,
      text: 'SUR',
      preferred: false,
      language: 'en',
      id: 914
    }, {
      vocabulary_item_id: 593,
      text: 'Old Uruguay Peso',
      preferred: true,
      language: 'en',
      id: 925
    }, {
      vocabulary_item_id: 607,
      text: 'Lithuanian Litas',
      preferred: false,
      language: 'en',
      id: 954
    }, {
      vocabulary_item_id: 613,
      text: 'SDG',
      preferred: true,
      language: 'en',
      id: 965
    }, {
      vocabulary_item_id: 627,
      text: 'Won',
      preferred: false,
      language: 'en',
      id: 994
    }, {
      vocabulary_item_id: 633,
      text: 'INR',
      preferred: true,
      language: 'en',
      id: 1005
    }, {
      vocabulary_item_id: 647,
      text: 'Rufiyaa',
      preferred: false,
      language: 'en',
      id: 1034
    }, {
      vocabulary_item_id: 653,
      text: 'BRB',
      preferred: true,
      language: 'en',
      id: 1045
    }, {
      vocabulary_item_id: 667,
      text: 'LAK',
      preferred: false,
      language: 'en',
      id: 1074
    }, {
      vocabulary_item_id: 673,
      text: 'ISK',
      preferred: true,
      language: 'en',
      id: 1085
    }, {
      vocabulary_item_id: 687,
      text: 'Timor Escudo',
      preferred: false,
      language: 'en',
      id: 1114
    }, {
      vocabulary_item_id: 693,
      text: 'AZN',
      preferred: true,
      language: 'en',
      id: 1125
    }, {
      vocabulary_item_id: 707,
      text: 'Sudanese Pound',
      preferred: false,
      language: 'en',
      id: 1154
    }, {
      vocabulary_item_id: 713,
      text: 'BRL',
      preferred: true,
      language: 'en',
      id: 1165
    }, {
      vocabulary_item_id: 727,
      text: 'Liberian Dollar',
      preferred: false,
      language: 'en',
      id: 1194
    }, {
      vocabulary_item_id: 733,
      text: 'SKK',
      preferred: true,
      language: 'en',
      id: 1205
    }, {
      vocabulary_item_id: 747,
      text: 'Euro',
      preferred: false,
      language: 'en',
      id: 1234
    }, {
      vocabulary_item_id: 753,
      text: 'PKR',
      preferred: true,
      language: 'en',
      id: 1245
    }, {
      vocabulary_item_id: 772,
      text: 'GMD',
      preferred: false,
      language: 'en',
      id: 1285
    }, {
      vocabulary_item_id: 778,
      text: 'NLG',
      preferred: true,
      language: 'en',
      id: 1296
    }, {
      vocabulary_item_id: 792,
      text: 'Sudanese Dinar',
      preferred: false,
      language: 'en',
      id: 1325
    }, {
      vocabulary_item_id: 798,
      text: 'DDM',
      preferred: true,
      language: 'en',
      id: 1336
    }, {
      vocabulary_item_id: 812,
      text: 'Mozambique Metical',
      preferred: false,
      language: 'en',
      id: 1365
    }, {
      vocabulary_item_id: 818,
      text: 'CHW',
      preferred: true,
      language: 'en',
      id: 1376
    }, {
      vocabulary_item_id: 832,
      text: 'Chilean Peso',
      preferred: false,
      language: 'en',
      id: 1405
    }, {
      vocabulary_item_id: 838,
      text: 'SBD',
      preferred: true,
      language: 'en',
      id: 1416
    }, {
      vocabulary_item_id: 847,
      text: 'pressure',
      preferred: true,
      language: 'en',
      id: 1432
    }, {
      vocabulary_item_id: 851,
      text: 'density',
      preferred: true,
      language: 'en',
      id: 1436
    }, {
      vocabulary_item_id: 870,
      text: 'Prices unavailable',
      preferred: true,
      language: 'en',
      id: 1455
    }, {
      vocabulary_item_id: 874,
      text: 'Catalog info approved',
      preferred: true,
      language: 'en',
      id: 1459
    }, {
      vocabulary_item_id: 913,
      text: 'Togolese',
      preferred: true,
      language: 'en',
      id: 1509
    }, {
      vocabulary_item_id: 917,
      text: 'Tajikistani',
      preferred: true,
      language: 'en',
      id: 1513
    }, {
      vocabulary_item_id: 921,
      text: 'Swazi',
      preferred: true,
      language: 'en',
      id: 1517
    }, {
      vocabulary_item_id: 925,
      text: 'Sri Lankan',
      preferred: true,
      language: 'en',
      id: 1521
    }, {
      vocabulary_item_id: 929,
      text: 'South Georgia',
      preferred: true,
      language: 'en',
      id: 1525
    }, {
      vocabulary_item_id: 943,
      text: 'Saudi',
      preferred: true,
      language: 'en',
      id: 1544
    }, {
      vocabulary_item_id: 965,
      text: 'Peruvian',
      preferred: true,
      language: 'en',
      id: 1571
    }, {
      vocabulary_item_id: 983,
      text: 'Nepali',
      preferred: false,
      language: 'en',
      id: 1594
    }, {
      vocabulary_item_id: 987,
      text: 'Mozambican',
      preferred: true,
      language: 'en',
      id: 1598
    }, {
      vocabulary_item_id: 991,
      text: 'Mongolian',
      preferred: true,
      language: 'en',
      id: 1602
    }, {
      vocabulary_item_id: 1039,
      text: 'Indian',
      preferred: true,
      language: 'en',
      id: 1660
    }, {
      vocabulary_item_id: 1076,
      text: 'Ecuadoran',
      preferred: true,
      language: 'en',
      id: 1703
    }, {
      vocabulary_item_id: 1235,
      text: 'Galatian',
      preferred: true,
      language: 'en',
      id: 1877
    }, {
      vocabulary_item_id: 1239,
      text: 'Epirote',
      preferred: true,
      language: 'en',
      id: 1881
    }, {
      vocabulary_item_id: 1243,
      text: 'Elian',
      preferred: true,
      language: 'en',
      id: 1885
    }, {
      vocabulary_item_id: 1276,
      text: 'Bactrian',
      preferred: true,
      language: 'en',
      id: 1924
    }, {
      vocabulary_item_id: 1280,
      text: 'Arcadian',
      preferred: true,
      language: 'en',
      id: 1928
    }, {
      vocabulary_item_id: 1284,
      text: 'Aetolian',
      preferred: true,
      language: 'en',
      id: 1932
    }, {
      vocabulary_item_id: 1288,
      text: 'Acarnanian',
      preferred: true,
      language: 'en',
      id: 1936
    }, {
      vocabulary_item_id: 1329,
      text: 'Selangorean',
      preferred: true,
      language: 'en',
      id: 1983
    }, {
      vocabulary_item_id: 1333,
      text: 'Perlisian',
      preferred: true,
      language: 'en',
      id: 1987
    }, {
      vocabulary_item_id: 1337,
      text: 'Negeri Sembilanese',
      preferred: true,
      language: 'en',
      id: 1991
    }, {
      vocabulary_item_id: 1341,
      text: 'Kelantanese',
      preferred: true,
      language: 'en',
      id: 1995
    }, {
      vocabulary_item_id: 1347,
      text: 'Tripuri',
      preferred: true,
      language: 'en',
      id: 2006
    }, {
      vocabulary_item_id: 1357,
      text: 'Meghalayan',
      preferred: true,
      language: 'en',
      id: 2021
    }, {
      vocabulary_item_id: 1361,
      text: 'Laccadivian',
      preferred: true,
      language: 'en',
      id: 2025
    }, {
      vocabulary_item_id: 1371,
      text: 'Damanese',
      preferred: true,
      language: 'en',
      id: 2040
    }, {
      vocabulary_item_id: 1385,
      text: 'Pomeranian',
      preferred: true,
      language: 'en',
      id: 2056
    }, {
      vocabulary_item_id: 1396,
      text: 'Prince Edward Island',
      preferred: true,
      language: 'en',
      id: 2068
    }, {
      vocabulary_item_id: 429,
      text: 'atm',
      preferred: true,
      language: 'en',
      id: 713
    }, {
      vocabulary_item_id: 435,
      text: 'km/h',
      preferred: true,
      language: 'en',
      id: 724
    }, {
      vocabulary_item_id: 441,
      text: 'kilometres',
      preferred: false,
      language: 'en',
      id: 735
    }, {
      vocabulary_item_id: 559,
      text: 'New Zaire',
      preferred: true,
      language: 'en',
      id: 857
    }, {
      vocabulary_item_id: 573,
      text: 'Italian Lira',
      preferred: false,
      language: 'en',
      id: 886
    }, {
      vocabulary_item_id: 579,
      text: 'ILP',
      preferred: true,
      language: 'en',
      id: 897
    }, {
      vocabulary_item_id: 593,
      text: 'UYN',
      preferred: false,
      language: 'en',
      id: 926
    }, {
      vocabulary_item_id: 599,
      text: 'SRD',
      preferred: true,
      language: 'en',
      id: 937
    }, {
      vocabulary_item_id: 613,
      text: 'Sudanese Pound',
      preferred: false,
      language: 'en',
      id: 966
    }, {
      vocabulary_item_id: 619,
      text: 'Guarani',
      preferred: true,
      language: 'en',
      id: 977
    }, {
      vocabulary_item_id: 633,
      text: 'Indian Rupee',
      preferred: false,
      language: 'en',
      id: 1006
    }, {
      vocabulary_item_id: 639,
      text: 'UYI',
      preferred: true,
      language: 'en',
      id: 1017
    }, {
      vocabulary_item_id: 653,
      text: 'Cruzeiro',
      preferred: false,
      language: 'en',
      id: 1046
    }, {
      vocabulary_item_id: 659,
      text: 'Kyat',
      preferred: true,
      language: 'en',
      id: 1057
    }, {
      vocabulary_item_id: 673,
      text: 'Iceland Krona',
      preferred: false,
      language: 'en',
      id: 1086
    }, {
      vocabulary_item_id: 679,
      text: 'CSK',
      preferred: true,
      language: 'en',
      id: 1097
    }, {
      vocabulary_item_id: 693,
      text: 'Azerbaijanian Manat',
      preferred: false,
      language: 'en',
      id: 1126
    }, {
      vocabulary_item_id: 699,
      text: 'ZWR',
      preferred: true,
      language: 'en',
      id: 1137
    }, {
      vocabulary_item_id: 713,
      text: 'Brazilian Real',
      preferred: false,
      language: 'en',
      id: 1166
    }, {
      vocabulary_item_id: 719,
      text: 'ATS',
      preferred: true,
      language: 'en',
      id: 1177
    }, {
      vocabulary_item_id: 733,
      text: 'Slovak Koruna',
      preferred: false,
      language: 'en',
      id: 1206
    }, {
      vocabulary_item_id: 739,
      text: 'CSJ',
      preferred: true,
      language: 'en',
      id: 1217
    }, {
      vocabulary_item_id: 753,
      text: 'Pakistan Rupee',
      preferred: false,
      language: 'en',
      id: 1246
    }, {
      vocabulary_item_id: 759,
      text: 'Dong',
      preferred: true,
      language: 'en',
      id: 1257
    }, {
      vocabulary_item_id: 764,
      text: 'Nuevo Sol',
      preferred: true,
      language: 'en',
      id: 1268
    }, {
      vocabulary_item_id: 778,
      text: 'Netherlands Guilder',
      preferred: false,
      language: 'en',
      id: 1297
    }, {
      vocabulary_item_id: 784,
      text: 'Kwacha',
      preferred: true,
      language: 'en',
      id: 1308
    }, {
      vocabulary_item_id: 798,
      text: 'Mark der DDR',
      preferred: false,
      language: 'en',
      id: 1337
    }, {
      vocabulary_item_id: 804,
      text: 'CNX',
      preferred: true,
      language: 'en',
      id: 1348
    }, {
      vocabulary_item_id: 818,
      text: 'WIR Franc',
      preferred: false,
      language: 'en',
      id: 1377
    }, {
      vocabulary_item_id: 824,
      text: 'TJR',
      preferred: true,
      language: 'en',
      id: 1388
    }, {
      vocabulary_item_id: 838,
      text: 'Solomon Islands Dollar',
      preferred: false,
      language: 'en',
      id: 1417
    }, {
      vocabulary_item_id: 844,
      text: 'CNY',
      preferred: true,
      language: 'en',
      id: 1428
    }, {
      vocabulary_item_id: 882,
      text: 'Imperial',
      preferred: true,
      language: 'en',
      id: 1467
    }, {
      vocabulary_item_id: 889,
      text: 'Wallisian',
      preferred: true,
      language: 'en',
      id: 1476
    }, {
      vocabulary_item_id: 893,
      text: 'British Virgin Island',
      preferred: true,
      language: 'en',
      id: 1480
    }, {
      vocabulary_item_id: 897,
      text: 'Vanuatuan',
      preferred: true,
      language: 'en',
      id: 1484
    }, {
      vocabulary_item_id: 929,
      text: 'South Sandwich Islands',
      preferred: false,
      language: 'en',
      id: 1526
    }, {
      vocabulary_item_id: 933,
      text: 'Slovenian',
      preferred: true,
      language: 'en',
      id: 1530
    }, {
      vocabulary_item_id: 943,
      text: 'Saudi Arabian',
      preferred: false,
      language: 'en',
      id: 1545
    }, {
      vocabulary_item_id: 947,
      text: 'Saint Vincentian',
      preferred: true,
      language: 'en',
      id: 1549
    }, {
      vocabulary_item_id: 969,
      text: 'Palestinian',
      preferred: true,
      language: 'en',
      id: 1576
    }, {
      vocabulary_item_id: 973,
      text: 'Norwegian',
      preferred: true,
      language: 'en',
      id: 1580
    }, {
      vocabulary_item_id: 977,
      text: 'Niuean',
      preferred: true,
      language: 'en',
      id: 1584
    }, {
      vocabulary_item_id: 981,
      text: 'New Zealand',
      preferred: true,
      language: 'en',
      id: 1588
    }, {
      vocabulary_item_id: 995,
      text: 'Mexican',
      preferred: true,
      language: 'en',
      id: 1607
    }, {
      vocabulary_item_id: 999,
      text: 'Martiniquais',
      preferred: true,
      language: 'en',
      id: 1611
    }, {
      vocabulary_item_id: 1117,
      text: 'Belgian',
      preferred: true,
      language: 'en',
      id: 1748
    }, {
      vocabulary_item_id: 1121,
      text: 'Bahraini',
      preferred: true,
      language: 'en',
      id: 1752
    }, {
      vocabulary_item_id: 1247,
      text: 'Dodecanesian',
      preferred: true,
      language: 'en',
      id: 1890
    }, {
      vocabulary_item_id: 1251,
      text: 'Cyrenian',
      preferred: true,
      language: 'en',
      id: 1894
    }, {
      vocabulary_item_id: 1254,
      text: 'Crotonian',
      preferred: true,
      language: 'en',
      id: 1898
    }, {
      vocabulary_item_id: 1258,
      text: 'Consentian',
      preferred: true,
      language: 'en',
      id: 1902
    }, {
      vocabulary_item_id: 1262,
      text: 'Chalcidian',
      preferred: true,
      language: 'en',
      id: 1906
    }, {
      vocabulary_item_id: 1292,
      text: 'North Island',
      preferred: true,
      language: 'en',
      id: 1941
    }, {
      vocabulary_item_id: 1347,
      text: 'Tripuran',
      preferred: false,
      language: 'en',
      id: 2007
    }, {
      vocabulary_item_id: 1365,
      text: 'Kashmiri',
      preferred: true,
      language: 'en',
      id: 2030
    }, {
      vocabulary_item_id: 1371,
      text: 'Diuese',
      preferred: false,
      language: 'en',
      id: 2041
    }, {
      vocabulary_item_id: 1378,
      text: 'Nicobarese',
      preferred: true,
      language: 'en',
      id: 2049
    }, {
      vocabulary_item_id: 1389,
      text: 'Hanoverian',
      preferred: true,
      language: 'en',
      id: 2061
    }, {
      vocabulary_item_id: 1396,
      text: 'P.E.I.',
      preferred: false,
      language: 'en',
      id: 2069
    }, {
      vocabulary_item_id: 1400,
      text: 'Northwest Territorian ',
      preferred: true,
      language: 'en',
      id: 2073
    }, {
      vocabulary_item_id: 1422,
      text: 'Americana',
      preferred: true,
      language: 'en',
      id: 2097
    }, {
      vocabulary_item_id: 1441,
      text: 'pseudonym',
      preferred: true,
      language: 'en',
      id: 2116
    }, {
      vocabulary_item_id: 1632,
      text: 'journal article',
      preferred: true,
      language: 'en',
      id: 2307
    }, {
      vocabulary_item_id: 1636,
      text: 'exhibition checklist',
      preferred: true,
      language: 'en',
      id: 2311
    }, {
      vocabulary_item_id: 1640,
      text: 'diary',
      preferred: true,
      language: 'en',
      id: 2315
    }, {
      vocabulary_item_id: 1644,
      text: 'guide book',
      preferred: true,
      language: 'en',
      id: 2319
    }, {
      vocabulary_item_id: 1648,
      text: 'monograph',
      preferred: true,
      language: 'en',
      id: 2323
    }, {
      vocabulary_item_id: 376,
      text: 'installation',
      preferred: true,
      language: 'en',
      id: 657
    }, {
      vocabulary_item_id: 380,
      text: 'other',
      preferred: true,
      language: 'en',
      id: 661
    }, {
      vocabulary_item_id: 384,
      text: 'painting',
      preferred: true,
      language: 'en',
      id: 665
    }, {
      vocabulary_item_id: 388,
      text: 'watches',
      preferred: true,
      language: 'en',
      id: 669
    }, {
      vocabulary_item_id: 392,
      text: 'design',
      preferred: true,
      language: 'en',
      id: 673
    }, {
      vocabulary_item_id: 396,
      text: 'hanging scroll',
      preferred: true,
      language: 'en',
      id: 677
    }, {
      vocabulary_item_id: 400,
      text: 'print',
      preferred: true,
      language: 'en',
      id: 681
    }, {
      vocabulary_item_id: 404,
      text: 'poster',
      preferred: true,
      language: 'en',
      id: 685
    }, {
      vocabulary_item_id: 408,
      text: 'antiquities',
      preferred: true,
      language: 'en',
      id: 689
    }, {
      vocabulary_item_id: 412,
      text: 'mosaic',
      preferred: true,
      language: 'en',
      id: 693
    }, {
      vocabulary_item_id: 416,
      text: 'bas-relief',
      preferred: true,
      language: 'en',
      id: 697
    }, {
      vocabulary_item_id: 430,
      text: 'Bars',
      preferred: true,
      language: 'en',
      id: 715
    }, {
      vocabulary_item_id: 436,
      text: 'm/s',
      preferred: true,
      language: 'en',
      id: 725
    }, {
      vocabulary_item_id: 442,
      text: 'm',
      preferred: true,
      language: 'en',
      id: 736
    }, {
      vocabulary_item_id: 442,
      text: 'metres',
      preferred: false,
      language: 'en',
      id: 737
    }, {
      vocabulary_item_id: 560,
      text: 'YUN',
      preferred: true,
      language: 'en',
      id: 859
    }, {
      vocabulary_item_id: 568,
      text: 'Zaire',
      preferred: false,
      language: 'en',
      id: 876
    }, {
      vocabulary_item_id: 574,
      text: 'UGS',
      preferred: true,
      language: 'en',
      id: 887
    }, {
      vocabulary_item_id: 574,
      text: 'Uganda Shilling',
      preferred: false,
      language: 'en',
      id: 888
    }, {
      vocabulary_item_id: 580,
      text: 'Ekwele',
      preferred: true,
      language: 'en',
      id: 899
    }, {
      vocabulary_item_id: 588,
      text: 'NIC',
      preferred: false,
      language: 'en',
      id: 916
    }, {
      vocabulary_item_id: 594,
      text: 'MGF',
      preferred: true,
      language: 'en',
      id: 927
    }, {
      vocabulary_item_id: 594,
      text: 'Malagasy Franc',
      preferred: false,
      language: 'en',
      id: 928
    }, {
      vocabulary_item_id: 600,
      text: 'Leone',
      preferred: true,
      language: 'en',
      id: 939
    }, {
      vocabulary_item_id: 608,
      text: 'Kwanza',
      preferred: false,
      language: 'en',
      id: 956
    }, {
      vocabulary_item_id: 614,
      text: 'RSD',
      preferred: true,
      language: 'en',
      id: 967
    }, {
      vocabulary_item_id: 614,
      text: 'Serbian Dinar',
      preferred: false,
      language: 'en',
      id: 968
    }, {
      vocabulary_item_id: 620,
      text: 'ERN',
      preferred: true,
      language: 'en',
      id: 979
    }, {
      vocabulary_item_id: 628,
      text: 'Moroccan Dirham',
      preferred: false,
      language: 'en',
      id: 996
    }, {
      vocabulary_item_id: 634,
      text: 'JOD',
      preferred: true,
      language: 'en',
      id: 1007
    }, {
      vocabulary_item_id: 634,
      text: 'Jordanian Dinar',
      preferred: false,
      language: 'en',
      id: 1008
    }, {
      vocabulary_item_id: 640,
      text: 'Rand',
      preferred: true,
      language: 'en',
      id: 1019
    }, {
      vocabulary_item_id: 648,
      text: 'Ethiopian Birr',
      preferred: false,
      language: 'en',
      id: 1036
    }, {
      vocabulary_item_id: 654,
      text: 'BAD',
      preferred: true,
      language: 'en',
      id: 1047
    }, {
      vocabulary_item_id: 654,
      text: 'Dinar',
      preferred: false,
      language: 'en',
      id: 1048
    }, {
      vocabulary_item_id: 660,
      text: 'MGA',
      preferred: true,
      language: 'en',
      id: 1059
    }, {
      vocabulary_item_id: 668,
      text: 'Colombian Peso',
      preferred: false,
      language: 'en',
      id: 1076
    }, {
      vocabulary_item_id: 674,
      text: 'IDR',
      preferred: true,
      language: 'en',
      id: 1087
    }, {
      vocabulary_item_id: 674,
      text: 'Rupiah',
      preferred: false,
      language: 'en',
      id: 1088
    }, {
      vocabulary_item_id: 680,
      text: 'BBD',
      preferred: true,
      language: 'en',
      id: 1099
    }, {
      vocabulary_item_id: 688,
      text: 'Argentine Peso',
      preferred: false,
      language: 'en',
      id: 1116
    }, {
      vocabulary_item_id: 694,
      text: 'PES',
      preferred: true,
      language: 'en',
      id: 1127
    }, {
      vocabulary_item_id: 694,
      text: 'Sol',
      preferred: false,
      language: 'en',
      id: 1128
    }, {
      vocabulary_item_id: 700,
      text: 'LVR',
      preferred: true,
      language: 'en',
      id: 1139
    }, {
      vocabulary_item_id: 708,
      text: 'Kenyan Shilling',
      preferred: false,
      language: 'en',
      id: 1156
    }, {
      vocabulary_item_id: 714,
      text: 'ECV',
      preferred: true,
      language: 'en',
      id: 1167
    }, {
      vocabulary_item_id: 714,
      text: 'Unidad de Valor Constante (UVC)',
      preferred: false,
      language: 'en',
      id: 1168
    }, {
      vocabulary_item_id: 720,
      text: 'Algerian Dinar',
      preferred: true,
      language: 'en',
      id: 1179
    }, {
      vocabulary_item_id: 728,
      text: 'Uruguayan Peso',
      preferred: false,
      language: 'en',
      id: 1196
    }, {
      vocabulary_item_id: 734,
      text: 'PEH',
      preferred: true,
      language: 'en',
      id: 1207
    }, {
      vocabulary_item_id: 734,
      text: 'Sol',
      preferred: false,
      language: 'en',
      id: 1208
    }, {
      vocabulary_item_id: 740,
      text: 'CHC',
      preferred: true,
      language: 'en',
      id: 1219
    }, {
      vocabulary_item_id: 748,
      text: 'Uzbekistan Sum',
      preferred: false,
      language: 'en',
      id: 1236
    }, {
      vocabulary_item_id: 754,
      text: 'PHP',
      preferred: true,
      language: 'en',
      id: 1247
    }, {
      vocabulary_item_id: 754,
      text: 'Philippine Peso',
      preferred: false,
      language: 'en',
      id: 1248
    }, {
      vocabulary_item_id: 760,
      text: 'LSL',
      preferred: true,
      language: 'en',
      id: 1259
    }, {
      vocabulary_item_id: 765,
      text: 'NOK',
      preferred: true,
      language: 'en',
      id: 1270
    }, {
      vocabulary_item_id: 773,
      text: 'Gibraltar Pound',
      preferred: false,
      language: 'en',
      id: 1287
    }, {
      vocabulary_item_id: 779,
      text: 'YDD',
      preferred: true,
      language: 'en',
      id: 1298
    }, {
      vocabulary_item_id: 779,
      text: 'Yemeni Dinar',
      preferred: false,
      language: 'en',
      id: 1299
    }, {
      vocabulary_item_id: 785,
      text: 'MOP',
      preferred: true,
      language: 'en',
      id: 1310
    }, {
      vocabulary_item_id: 793,
      text: 'Luxembourg Franc',
      preferred: false,
      language: 'en',
      id: 1327
    }, {
      vocabulary_item_id: 799,
      text: 'BUK',
      preferred: true,
      language: 'en',
      id: 1338
    }, {
      vocabulary_item_id: 799,
      text: 'N.A.',
      preferred: false,
      language: 'en',
      id: 1339
    }, {
      vocabulary_item_id: 805,
      text: 'BGK',
      preferred: true,
      language: 'en',
      id: 1350
    }, {
      vocabulary_item_id: 813,
      text: 'Kuwaiti Dinar',
      preferred: false,
      language: 'en',
      id: 1367
    }, {
      vocabulary_item_id: 819,
      text: 'FJD',
      preferred: true,
      language: 'en',
      id: 1378
    }, {
      vocabulary_item_id: 819,
      text: 'Fiji Dollar',
      preferred: false,
      language: 'en',
      id: 1379
    }, {
      vocabulary_item_id: 377,
      text: 'stamps',
      preferred: true,
      language: 'en',
      id: 658
    }, {
      vocabulary_item_id: 381,
      text: 'jewelry',
      preferred: true,
      language: 'en',
      id: 662
    }, {
      vocabulary_item_id: 385,
      text: 'drawing',
      preferred: true,
      language: 'en',
      id: 666
    }, {
      vocabulary_item_id: 389,
      text: 'vase',
      preferred: true,
      language: 'en',
      id: 670
    }, {
      vocabulary_item_id: 393,
      text: 'bust',
      preferred: true,
      language: 'en',
      id: 674
    }, {
      vocabulary_item_id: 397,
      text: 'ceramics',
      preferred: true,
      language: 'en',
      id: 678
    }, {
      vocabulary_item_id: 401,
      text: 'tapestry',
      preferred: true,
      language: 'en',
      id: 682
    }, {
      vocabulary_item_id: 405,
      text: 'fresco',
      preferred: true,
      language: 'en',
      id: 686
    }, {
      vocabulary_item_id: 409,
      text: 'digital art',
      preferred: true,
      language: 'en',
      id: 690
    }, {
      vocabulary_item_id: 413,
      text: 'mixed media',
      preferred: true,
      language: 'en',
      id: 694
    }, {
      vocabulary_item_id: 431,
      text: 'h',
      preferred: true,
      language: 'en',
      id: 716
    }, {
      vocabulary_item_id: 437,
      text: 'tonne',
      preferred: false,
      language: 'en',
      id: 727
    }, {
      vocabulary_item_id: 443,
      text: 'cm',
      preferred: true,
      language: 'en',
      id: 738
    }, {
      vocabulary_item_id: 569,
      text: 'Zambian Kwacha',
      preferred: false,
      language: 'en',
      id: 878
    }, {
      vocabulary_item_id: 575,
      text: 'MTP',
      preferred: true,
      language: 'en',
      id: 889
    }, {
      vocabulary_item_id: 589,
      text: 'Belarussian Ruble',
      preferred: false,
      language: 'en',
      id: 918
    }, {
      vocabulary_item_id: 595,
      text: 'Sucre',
      preferred: true,
      language: 'en',
      id: 929
    }, {
      vocabulary_item_id: 609,
      text: 'Luxembourg Convertible Franc',
      preferred: false,
      language: 'en',
      id: 958
    }, {
      vocabulary_item_id: 615,
      text: 'BRE',
      preferred: true,
      language: 'en',
      id: 969
    }, {
      vocabulary_item_id: 629,
      text: 'Kwanza Reajustado',
      preferred: false,
      language: 'en',
      id: 998
    }, {
      vocabulary_item_id: 635,
      text: 'BOP',
      preferred: true,
      language: 'en',
      id: 1009
    }, {
      vocabulary_item_id: 649,
      text: 'Rhodesian Dollar',
      preferred: false,
      language: 'en',
      id: 1038
    }, {
      vocabulary_item_id: 655,
      text: 'AZM',
      preferred: true,
      language: 'en',
      id: 1049
    }, {
      vocabulary_item_id: 669,
      text: 'Czech Koruna',
      preferred: false,
      language: 'en',
      id: 1078
    }, {
      vocabulary_item_id: 675,
      text: 'Forint',
      preferred: true,
      language: 'en',
      id: 1089
    }, {
      vocabulary_item_id: 689,
      text: 'ROK',
      preferred: false,
      language: 'en',
      id: 1118
    }, {
      vocabulary_item_id: 695,
      text: 'ALL',
      preferred: true,
      language: 'en',
      id: 1129
    }, {
      vocabulary_item_id: 709,
      text: 'Maloti',
      preferred: false,
      language: 'en',
      id: 1158
    }, {
      vocabulary_item_id: 715,
      text: 'Croatian Dinar',
      preferred: true,
      language: 'en',
      id: 1169
    }, {
      vocabulary_item_id: 729,
      text: 'UGW',
      preferred: false,
      language: 'en',
      id: 1198
    }, {
      vocabulary_item_id: 735,
      text: 'CUC',
      preferred: true,
      language: 'en',
      id: 1209
    }, {
      vocabulary_item_id: 749,
      text: 'Vatu',
      preferred: false,
      language: 'en',
      id: 1238
    }, {
      vocabulary_item_id: 755,
      text: 'NPR',
      preferred: true,
      language: 'en',
      id: 1249
    }, {
      vocabulary_item_id: 774,
      text: 'Belize Dollar',
      preferred: false,
      language: 'en',
      id: 1289
    }, {
      vocabulary_item_id: 780,
      text: 'Gold-Franc',
      preferred: true,
      language: 'en',
      id: 1300
    }, {
      vocabulary_item_id: 794,
      text: 'GHC',
      preferred: false,
      language: 'en',
      id: 1329
    }, {
      vocabulary_item_id: 800,
      text: 'BGJ',
      preferred: true,
      language: 'en',
      id: 1340
    }, {
      vocabulary_item_id: 814,
      text: 'Tugrik',
      preferred: false,
      language: 'en',
      id: 1369
    }, {
      vocabulary_item_id: 820,
      text: 'CRC',
      preferred: true,
      language: 'en',
      id: 1380
    }, {
      vocabulary_item_id: 834,
      text: 'Georgian Coupon',
      preferred: false,
      language: 'en',
      id: 1409
    }, {
      vocabulary_item_id: 840,
      text: 'MYR',
      preferred: true,
      language: 'en',
      id: 1420
    }, {
      vocabulary_item_id: 865,
      text: 'Withdrawn',
      preferred: true,
      language: 'en',
      id: 1450
    }, {
      vocabulary_item_id: 901,
      text: 'English',
      preferred: false,
      language: 'en',
      id: 1494
    }, {
      vocabulary_item_id: 935,
      text: 'Sint Maarten',
      preferred: true,
      language: 'en',
      id: 1533
    }, {
      vocabulary_item_id: 1001,
      text: 'Maltese',
      preferred: true,
      language: 'en',
      id: 1614
    }, {
      vocabulary_item_id: 1005,
      text: 'Malawian',
      preferred: true,
      language: 'en',
      id: 1618
    }, {
      vocabulary_item_id: 1009,
      text: 'Luxembourg',
      preferred: true,
      language: 'en',
      id: 1622
    }, {
      vocabulary_item_id: 1019,
      text: 'Kuwaiti',
      preferred: true,
      language: 'en',
      id: 1637
    }, {
      vocabulary_item_id: 1123,
      text: 'Azeri',
      preferred: false,
      language: 'en',
      id: 1755
    }, {
      vocabulary_item_id: 1127,
      text: 'Armenian',
      preferred: true,
      language: 'en',
      id: 1759
    }, {
      vocabulary_item_id: 1131,
      text: 'Antarctic',
      preferred: true,
      language: 'en',
      id: 1763
    }, {
      vocabulary_item_id: 1135,
      text: 'American Samoan',
      preferred: true,
      language: 'en',
      id: 1767
    }, {
      vocabulary_item_id: 1139,
      text: 'Afghan',
      preferred: true,
      language: 'en',
      id: 1771
    }, {
      vocabulary_item_id: 1158,
      text: 'Tarentine',
      preferred: true,
      language: 'en',
      id: 1791
    }, {
      vocabulary_item_id: 1172,
      text: 'Sardian',
      preferred: false,
      language: 'en',
      id: 1810
    }, {
      vocabulary_item_id: 1264,
      text: 'Catalonian',
      preferred: false,
      language: 'en',
      id: 1909
    }, {
      vocabulary_item_id: 1294,
      text: 'Wellingtonian',
      preferred: false,
      language: 'en',
      id: 1944
    }, {
      vocabulary_item_id: 1298,
      text: 'Southland',
      preferred: true,
      language: 'en',
      id: 1948
    }, {
      vocabulary_item_id: 1302,
      text: 'Marlborough',
      preferred: true,
      language: 'en',
      id: 1952
    }, {
      vocabulary_item_id: 1349,
      text: 'Tamilian',
      preferred: false,
      language: 'en',
      id: 2010
    }, {
      vocabulary_item_id: 1353,
      text: 'Pondicherrian',
      preferred: true,
      language: 'en',
      id: 2014
    }, {
      vocabulary_item_id: 1367,
      text: 'Haryanvi',
      preferred: true,
      language: 'en',
      id: 2033
    }, {
      vocabulary_item_id: 1373,
      text: 'Chhattisgarhi',
      preferred: true,
      language: 'en',
      id: 2044
    }, {
      vocabulary_item_id: 1402,
      text: 'New Brunswick',
      preferred: true,
      language: 'en',
      id: 2076
    }, {
      vocabulary_item_id: 2295,
      text: 'Medicine',
      preferred: true,
      language: 'en',
      id: 2983
    }, {
      vocabulary_item_id: 2299,
      text: 'Theology',
      preferred: true,
      language: 'en',
      id: 2987
    }, {
      vocabulary_item_id: 2303,
      text: 'Social Work',
      preferred: true,
      language: 'en',
      id: 2991
    }, {
      vocabulary_item_id: 2307,
      text: 'Science in Leadership ',
      preferred: true,
      language: 'en',
      id: 2995
    }, {
      vocabulary_item_id: 2311,
      text: 'Science in Information Technology',
      preferred: true,
      language: 'en',
      id: 2999
    }, {
      vocabulary_item_id: 2315,
      text: 'Science in Finance',
      preferred: true,
      language: 'en',
      id: 3003
    }, {
      vocabulary_item_id: 2319,
      text: 'Sacred Music ',
      preferred: true,
      language: 'en',
      id: 3007
    }, {
      vocabulary_item_id: 2323,
      text: 'Real Estate Development',
      preferred: true,
      language: 'en',
      id: 3011
    }, {
      vocabulary_item_id: 2327,
      text: 'Public Management',
      preferred: true,
      language: 'en',
      id: 3015
    }, {
      vocabulary_item_id: 2331,
      text: 'Professional Studies ',
      preferred: true,
      language: 'en',
      id: 3019
    }, {
      vocabulary_item_id: 368,
      text: 'ja',
      preferred: true,
      language: 'en',
      id: 643
    }, {
      vocabulary_item_id: 431,
      text: 'hours',
      preferred: false,
      language: 'en',
      id: 717
    }, {
      vocabulary_item_id: 443,
      text: 'centimetres',
      preferred: false,
      language: 'en',
      id: 739
    }, {
      vocabulary_item_id: 446,
      text: 'Whisky',
      preferred: true,
      language: 'en',
      id: 744
    }, {
      vocabulary_item_id: 450,
      text: 'Textiles',
      preferred: true,
      language: 'en',
      id: 748
    }, {
      vocabulary_item_id: 454,
      text: 'Watches',
      preferred: true,
      language: 'en',
      id: 752
    }, {
      vocabulary_item_id: 458,
      text: 'Gems',
      preferred: true,
      language: 'en',
      id: 756
    }, {
      vocabulary_item_id: 462,
      text: 'Taxidermy',
      preferred: true,
      language: 'en',
      id: 760
    }, {
      vocabulary_item_id: 466,
      text: 'Musical Instruments',
      preferred: true,
      language: 'en',
      id: 764
    }, {
      vocabulary_item_id: 470,
      text: 'Automobiles',
      preferred: true,
      language: 'en',
      id: 768
    }, {
      vocabulary_item_id: 474,
      text: 'Wine and Spirits',
      preferred: true,
      language: 'en',
      id: 772
    }, {
      vocabulary_item_id: 478,
      text: 'Wine',
      preferred: true,
      language: 'en',
      id: 776
    }, {
      vocabulary_item_id: 482,
      text: 'Parts and Accessories',
      preferred: true,
      language: 'en',
      id: 780
    }, {
      vocabulary_item_id: 486,
      text: 'Garden Furniture and Statuary',
      preferred: true,
      language: 'en',
      id: 784
    }, {
      vocabulary_item_id: 490,
      text: 'Glass',
      preferred: true,
      language: 'en',
      id: 788
    }, {
      vocabulary_item_id: 494,
      text: 'Mosaics',
      preferred: true,
      language: 'en',
      id: 792
    }, {
      vocabulary_item_id: 498,
      text: 'Sporting and Historical Firearms',
      preferred: true,
      language: 'en',
      id: 796
    }, {
      vocabulary_item_id: 502,
      text: 'Textile Art',
      preferred: true,
      language: 'en',
      id: 800
    }, {
      vocabulary_item_id: 506,
      text: 'Ceramics',
      preferred: true,
      language: 'en',
      id: 804
    }, {
      vocabulary_item_id: 510,
      text: 'Banknotes',
      preferred: true,
      language: 'en',
      id: 808
    }, {
      vocabulary_item_id: 514,
      text: 'Carpets and Rugs',
      preferred: true,
      language: 'en',
      id: 812
    }, {
      vocabulary_item_id: 518,
      text: 'Historical Weapons',
      preferred: true,
      language: 'en',
      id: 816
    }, {
      vocabulary_item_id: 522,
      text: 'Luxury Accessories',
      preferred: true,
      language: 'en',
      id: 820
    }, {
      vocabulary_item_id: 526,
      text: 'Reliefs',
      preferred: true,
      language: 'en',
      id: 824
    }, {
      vocabulary_item_id: 530,
      text: 'Sound Art',
      preferred: true,
      language: 'en',
      id: 828
    }, {
      vocabulary_item_id: 534,
      text: 'Scrolls',
      preferred: true,
      language: 'en',
      id: 832
    }, {
      vocabulary_item_id: 538,
      text: 'Paintings',
      preferred: true,
      language: 'en',
      id: 836
    }, {
      vocabulary_item_id: 542,
      text: 'Mixed Media',
      preferred: true,
      language: 'en',
      id: 840
    }, {
      vocabulary_item_id: 546,
      text: 'Transparencies and Negatives',
      preferred: true,
      language: 'en',
      id: 844
    }, {
      vocabulary_item_id: 550,
      text: 'Arms, Uniforms, and Militaria',
      preferred: true,
      language: 'en',
      id: 848
    }, {
      vocabulary_item_id: 554,
      text: 'Medals',
      preferred: true,
      language: 'en',
      id: 852
    }, {
      vocabulary_item_id: 558,
      text: 'Armor',
      preferred: true,
      language: 'en',
      id: 856
    }, {
      vocabulary_item_id: 561,
      text: 'SRG',
      preferred: true,
      language: 'en',
      id: 861
    }, {
      vocabulary_item_id: 575,
      text: 'Maltese Pound',
      preferred: false,
      language: 'en',
      id: 890
    }, {
      vocabulary_item_id: 581,
      text: '"A" Account (convertible Peseta Account)',
      preferred: true,
      language: 'en',
      id: 901
    }, {
      vocabulary_item_id: 595,
      text: 'XSU',
      preferred: false,
      language: 'en',
      id: 930
    }, {
      vocabulary_item_id: 601,
      text: 'IEP',
      preferred: true,
      language: 'en',
      id: 941
    }, {
      vocabulary_item_id: 615,
      text: 'Cruzeiro',
      preferred: false,
      language: 'en',
      id: 970
    }, {
      vocabulary_item_id: 621,
      text: 'New Taiwan Dollar',
      preferred: true,
      language: 'en',
      id: 981
    }, {
      vocabulary_item_id: 635,
      text: 'Peso boliviano',
      preferred: false,
      language: 'en',
      id: 1010
    }, {
      vocabulary_item_id: 641,
      text: 'TMT',
      preferred: true,
      language: 'en',
      id: 1021
    }, {
      vocabulary_item_id: 655,
      text: 'Azerbaijanian Manat',
      preferred: false,
      language: 'en',
      id: 1050
    }, {
      vocabulary_item_id: 661,
      text: 'GYD',
      preferred: true,
      language: 'en',
      id: 1061
    }, {
      vocabulary_item_id: 675,
      text: 'HUF',
      preferred: false,
      language: 'en',
      id: 1090
    }, {
      vocabulary_item_id: 681,
      text: 'BRR',
      preferred: true,
      language: 'en',
      id: 1101
    }, {
      vocabulary_item_id: 695,
      text: 'Lek',
      preferred: false,
      language: 'en',
      id: 1130
    }, {
      vocabulary_item_id: 701,
      text: 'Financial Rand',
      preferred: true,
      language: 'en',
      id: 1141
    }, {
      vocabulary_item_id: 715,
      text: 'HRD',
      preferred: false,
      language: 'en',
      id: 1170
    }, {
      vocabulary_item_id: 721,
      text: 'CFA Franc BEAC',
      preferred: true,
      language: 'en',
      id: 1181
    }, {
      vocabulary_item_id: 735,
      text: 'Peso Convertible',
      preferred: false,
      language: 'en',
      id: 1210
    }, {
      vocabulary_item_id: 741,
      text: 'ZWL',
      preferred: true,
      language: 'en',
      id: 1221
    }, {
      vocabulary_item_id: 755,
      text: 'Nepalese Rupee',
      preferred: false,
      language: 'en',
      id: 1250
    }, {
      vocabulary_item_id: 685,
      text: 'VEF',
      preferred: false,
      language: 'en',
      id: 1261
    }, {
      vocabulary_item_id: 766,
      text: 'BSD',
      preferred: true,
      language: 'en',
      id: 1272
    }, {
      vocabulary_item_id: 780,
      text: 'XFO',
      preferred: false,
      language: 'en',
      id: 1301
    }, {
      vocabulary_item_id: 786,
      text: 'Comoro Franc',
      preferred: true,
      language: 'en',
      id: 1312
    }, {
      vocabulary_item_id: 800,
      text: 'Lev A/52',
      preferred: false,
      language: 'en',
      id: 1341
    }, {
      vocabulary_item_id: 806,
      text: 'Peso Uruguayo',
      preferred: true,
      language: 'en',
      id: 1352
    }, {
      vocabulary_item_id: 820,
      text: 'Costa Rican Colon',
      preferred: false,
      language: 'en',
      id: 1381
    }, {
      vocabulary_item_id: 826,
      text: 'HRK',
      preferred: true,
      language: 'en',
      id: 1392
    }, {
      vocabulary_item_id: 840,
      text: 'Malaysian Ringgit',
      preferred: false,
      language: 'en',
      id: 1421
    }, {
      vocabulary_item_id: 899,
      text: 'Uruguayan',
      preferred: true,
      language: 'en',
      id: 1488
    }, {
      vocabulary_item_id: 949,
      text: 'Saint-Martinoise',
      preferred: true,
      language: 'en',
      id: 1553
    }, {
      vocabulary_item_id: 953,
      text: 'Barthélemois',
      preferred: true,
      language: 'en',
      id: 1557
    }, {
      vocabulary_item_id: 957,
      text: 'Romanian',
      preferred: true,
      language: 'en',
      id: 1561
    }, {
      vocabulary_item_id: 1009,
      text: 'Luxembourgish',
      preferred: false,
      language: 'en',
      id: 1623
    }, {
      vocabulary_item_id: 1013,
      text: 'Liberian',
      preferred: true,
      language: 'en',
      id: 1627
    }, {
      vocabulary_item_id: 1017,
      text: 'Lao',
      preferred: true,
      language: 'en',
      id: 1631
    }, {
      vocabulary_item_id: 1023,
      text: 'I-Kiribati',
      preferred: true,
      language: 'en',
      id: 1642
    }, {
      vocabulary_item_id: 1045,
      text: 'Haitian',
      preferred: true,
      language: 'en',
      id: 1669
    }, {
      vocabulary_item_id: 1052,
      text: 'Grenadian',
      preferred: true,
      language: 'en',
      id: 1677
    }, {
      vocabulary_item_id: 1086,
      text: 'Cook Island',
      preferred: true,
      language: 'en',
      id: 1713
    }, {
      vocabulary_item_id: 1105,
      text: 'BIOT',
      preferred: true,
      language: 'en',
      id: 1733
    }, {
      vocabulary_item_id: 1143,
      text: 'Umbrian',
      preferred: true,
      language: 'en',
      id: 1776
    }, {
      vocabulary_item_id: 1147,
      text: 'Thynian',
      preferred: true,
      language: 'en',
      id: 1780
    }, {
      vocabulary_item_id: 1151,
      text: 'Thracian',
      preferred: true,
      language: 'en',
      id: 1784
    }, {
      vocabulary_item_id: 1155,
      text: 'Thasian',
      preferred: true,
      language: 'en',
      id: 1788
    }, {
      vocabulary_item_id: 1158,
      text: 'Tarentumian',
      preferred: false,
      language: 'en',
      id: 1792
    }, {
      vocabulary_item_id: 1162,
      text: 'Spartan',
      preferred: true,
      language: 'en',
      id: 1796
    }, {
      vocabulary_item_id: 1166,
      text: 'Sicyonian',
      preferred: true,
      language: 'en',
      id: 1800
    }, {
      vocabulary_item_id: 1170,
      text: 'Scythian',
      preferred: true,
      language: 'en',
      id: 1804
    }, {
      vocabulary_item_id: 1176,
      text: 'Salaminian',
      preferred: true,
      language: 'en',
      id: 1815
    }, {
      vocabulary_item_id: 1180,
      text: 'Rhodian',
      preferred: true,
      language: 'en',
      id: 1819
    }, {
      vocabulary_item_id: 1219,
      text: 'Lucanian',
      preferred: true,
      language: 'en',
      id: 1859
    }, {
      vocabulary_item_id: 1223,
      text: 'Latin',
      preferred: true,
      language: 'en',
      id: 1863
    }, {
      vocabulary_item_id: 1268,
      text: 'Campanian',
      preferred: true,
      language: 'en',
      id: 1914
    }, {
      vocabulary_item_id: 1272,
      text: 'Bosporan',
      preferred: true,
      language: 'en',
      id: 1918
    }, {
      vocabulary_item_id: 1406,
      text: 'Western Australian',
      preferred: true,
      language: 'en',
      id: 2081
    }, {
      vocabulary_item_id: 432,
      text: 'min',
      preferred: true,
      language: 'en',
      id: 718
    }, {
      vocabulary_item_id: 438,
      text: 'kilogram',
      preferred: false,
      language: 'en',
      id: 729
    }, {
      vocabulary_item_id: 444,
      text: 'mm',
      preferred: true,
      language: 'en',
      id: 740
    }, {
      vocabulary_item_id: 447,
      text: 'Boats',
      preferred: true,
      language: 'en',
      id: 745
    }, {
      vocabulary_item_id: 451,
      text: 'Furniture',
      preferred: true,
      language: 'en',
      id: 749
    }, {
      vocabulary_item_id: 455,
      text: 'Motorcyles',
      preferred: true,
      language: 'en',
      id: 753
    }, {
      vocabulary_item_id: 459,
      text: 'Jewelry',
      preferred: true,
      language: 'en',
      id: 757
    }, {
      vocabulary_item_id: 463,
      text: 'Space History',
      preferred: true,
      language: 'en',
      id: 761
    }, {
      vocabulary_item_id: 467,
      text: 'Jewelry and Gems',
      preferred: true,
      language: 'en',
      id: 765
    }, {
      vocabulary_item_id: 471,
      text: 'Scientific Instruments',
      preferred: true,
      language: 'en',
      id: 769
    }, {
      vocabulary_item_id: 475,
      text: 'Equipment and Machines',
      preferred: true,
      language: 'en',
      id: 773
    }, {
      vocabulary_item_id: 479,
      text: 'Fossils',
      preferred: true,
      language: 'en',
      id: 777
    }, {
      vocabulary_item_id: 483,
      text: 'Vintage Electric Instruments',
      preferred: true,
      language: 'en',
      id: 781
    }, {
      vocabulary_item_id: 487,
      text: 'Brass and Woodwinds',
      preferred: true,
      language: 'en',
      id: 785
    }, {
      vocabulary_item_id: 491,
      text: 'Natural History and Fine Minerals',
      preferred: true,
      language: 'en',
      id: 789
    }, {
      vocabulary_item_id: 495,
      text: 'Cinema, Theater, and Television',
      preferred: true,
      language: 'en',
      id: 793
    }, {
      vocabulary_item_id: 499,
      text: 'Sports Memorabilia',
      preferred: true,
      language: 'en',
      id: 797
    }, {
      vocabulary_item_id: 503,
      text: 'Prints',
      preferred: true,
      language: 'en',
      id: 801
    }, {
      vocabulary_item_id: 507,
      text: 'Murals',
      preferred: true,
      language: 'en',
      id: 805
    }, {
      vocabulary_item_id: 511,
      text: 'Costumes and Historical Uniforms',
      preferred: true,
      language: 'en',
      id: 809
    }, {
      vocabulary_item_id: 515,
      text: 'Multimedia',
      preferred: true,
      language: 'en',
      id: 813
    }, {
      vocabulary_item_id: 519,
      text: 'Architectural Elements',
      preferred: true,
      language: 'en',
      id: 817
    }, {
      vocabulary_item_id: 523,
      text: 'Calligraphy',
      preferred: true,
      language: 'en',
      id: 821
    }, {
      vocabulary_item_id: 527,
      text: 'Stamps',
      preferred: true,
      language: 'en',
      id: 825
    }, {
      vocabulary_item_id: 531,
      text: 'Digital Images',
      preferred: true,
      language: 'en',
      id: 829
    }, {
      vocabulary_item_id: 535,
      text: 'Pens',
      preferred: true,
      language: 'en',
      id: 833
    }, {
      vocabulary_item_id: 539,
      text: 'Collages',
      preferred: true,
      language: 'en',
      id: 837
    }, {
      vocabulary_item_id: 543,
      text: 'Transportation Memorabilia',
      preferred: true,
      language: 'en',
      id: 841
    }, {
      vocabulary_item_id: 547,
      text: 'Military Firearms',
      preferred: true,
      language: 'en',
      id: 845
    }, {
      vocabulary_item_id: 551,
      text: 'Sculptures',
      preferred: true,
      language: 'en',
      id: 849
    }, {
      vocabulary_item_id: 555,
      text: 'Couture and Vintage',
      preferred: true,
      language: 'en',
      id: 853
    }, {
      vocabulary_item_id: 576,
      text: 'MLF',
      preferred: true,
      language: 'en',
      id: 891
    }, {
      vocabulary_item_id: 590,
      text: 'XPF',
      preferred: false,
      language: 'en',
      id: 920
    }, {
      vocabulary_item_id: 596,
      text: 'CFA Franc BCEAO',
      preferred: true,
      language: 'en',
      id: 931
    }, {
      vocabulary_item_id: 610,
      text: 'Syli',
      preferred: false,
      language: 'en',
      id: 960
    }, {
      vocabulary_item_id: 616,
      text: 'AYM',
      preferred: true,
      language: 'en',
      id: 971
    }, {
      vocabulary_item_id: 630,
      text: 'New Kwanza',
      preferred: false,
      language: 'en',
      id: 1000
    }, {
      vocabulary_item_id: 636,
      text: 'Kina',
      preferred: true,
      language: 'en',
      id: 1011
    }, {
      vocabulary_item_id: 650,
      text: 'Guinea Escudo',
      preferred: false,
      language: 'en',
      id: 1040
    }, {
      vocabulary_item_id: 656,
      text: 'TRY',
      preferred: true,
      language: 'en',
      id: 1051
    }, {
      vocabulary_item_id: 670,
      text: 'Hong Kong Dollar',
      preferred: false,
      language: 'en',
      id: 1080
    }, {
      vocabulary_item_id: 676,
      text: 'BIF',
      preferred: true,
      language: 'en',
      id: 1091
    }, {
      vocabulary_item_id: 690,
      text: 'Jamaican Dollar',
      preferred: false,
      language: 'en',
      id: 1120
    }, {
      vocabulary_item_id: 696,
      text: 'MZE',
      preferred: true,
      language: 'en',
      id: 1131
    }, {
      vocabulary_item_id: 710,
      text: 'Pound Sterling',
      preferred: false,
      language: 'en',
      id: 1160
    }, {
      vocabulary_item_id: 716,
      text: 'GHP',
      preferred: true,
      language: 'en',
      id: 1171
    }, {
      vocabulary_item_id: 730,
      text: 'Yen',
      preferred: false,
      language: 'en',
      id: 1200
    }, {
      vocabulary_item_id: 736,
      text: 'MZM',
      preferred: true,
      language: 'en',
      id: 1211
    }, {
      vocabulary_item_id: 750,
      text: 'UAH',
      preferred: false,
      language: 'en',
      id: 1240
    }, {
      vocabulary_item_id: 756,
      text: 'OMR',
      preferred: true,
      language: 'en',
      id: 1251
    }, {
      vocabulary_item_id: 761,
      text: 'SSP',
      preferred: true,
      language: 'en',
      id: 1262
    }, {
      vocabulary_item_id: 775,
      text: 'Ngultrum',
      preferred: false,
      language: 'en',
      id: 1291
    }, {
      vocabulary_item_id: 781,
      text: 'Old Dong',
      preferred: true,
      language: 'en',
      id: 1302
    }, {
      vocabulary_item_id: 795,
      text: 'French Franc',
      preferred: false,
      language: 'en',
      id: 1331
    }, {
      vocabulary_item_id: 801,
      text: 'ARY',
      preferred: true,
      language: 'en',
      id: 1342
    }, {
      vocabulary_item_id: 815,
      text: 'KYD',
      preferred: false,
      language: 'en',
      id: 1371
    }, {
      vocabulary_item_id: 821,
      text: 'BYR',
      preferred: true,
      language: 'en',
      id: 1382
    }, {
      vocabulary_item_id: 835,
      text: 'Brunei Dollar',
      preferred: false,
      language: 'en',
      id: 1411
    }, {
      vocabulary_item_id: 841,
      text: 'KPW',
      preferred: true,
      language: 'en',
      id: 1422
    }, {
      vocabulary_item_id: 900,
      text: 'American',
      preferred: true,
      language: 'en',
      id: 1489
    }, {
      vocabulary_item_id: 902,
      text: 'Emirati',
      preferred: false,
      language: 'en',
      id: 1496
    }, {
      vocabulary_item_id: 936,
      text: 'Sint Eustatius',
      preferred: false,
      language: 'en',
      id: 1535
    }, {
      vocabulary_item_id: 950,
      text: 'Saint Lucian',
      preferred: true,
      language: 'en',
      id: 1554
    }, {
      vocabulary_item_id: 954,
      text: 'Saba',
      preferred: true,
      language: 'en',
      id: 1558
    }, {
      vocabulary_item_id: 958,
      text: 'Réunionese',
      preferred: true,
      language: 'en',
      id: 1562
    }, {
      vocabulary_item_id: 1010,
      text: 'Lithuanian',
      preferred: true,
      language: 'en',
      id: 1624
    }, {
      vocabulary_item_id: 1014,
      text: 'Basotho',
      preferred: true,
      language: 'en',
      id: 1628
    }, {
      vocabulary_item_id: 1020,
      text: 'Kosovan',
      preferred: false,
      language: 'en',
      id: 1639
    }, {
      vocabulary_item_id: 1024,
      text: 'Kenyan',
      preferred: true,
      language: 'en',
      id: 1643
    }, {
      vocabulary_item_id: 1046,
      text: 'Guyanese',
      preferred: true,
      language: 'en',
      id: 1670
    }, {
      vocabulary_item_id: 1053,
      text: 'Greenlandic',
      preferred: true,
      language: 'en',
      id: 1678
    }, {
      vocabulary_item_id: 1087,
      text: 'Congolese',
      preferred: true,
      language: 'en',
      id: 1714
    }, {
      vocabulary_item_id: 1102,
      text: 'Burkinabé',
      preferred: true,
      language: 'en',
      id: 1730
    }, {
      vocabulary_item_id: 1106,
      text: 'Brazilian',
      preferred: true,
      language: 'en',
      id: 1734
    }, {
      vocabulary_item_id: 1140,
      text: 'Abkhazian',
      preferred: false,
      language: 'en',
      id: 1773
    }, {
      vocabulary_item_id: 1144,
      text: 'Trojan',
      preferred: true,
      language: 'en',
      id: 1777
    }, {
      vocabulary_item_id: 1148,
      text: 'Thurian',
      preferred: true,
      language: 'en',
      id: 1781
    }, {
      vocabulary_item_id: 1152,
      text: 'Thessalian',
      preferred: true,
      language: 'en',
      id: 1785
    }, {
      vocabulary_item_id: 1156,
      text: 'Tenedian',
      preferred: true,
      language: 'en',
      id: 1789
    }, {
      vocabulary_item_id: 1159,
      text: 'Syracusan',
      preferred: true,
      language: 'en',
      id: 1793
    }, {
      vocabulary_item_id: 1163,
      text: 'Skopelitan',
      preferred: true,
      language: 'en',
      id: 1797
    }, {
      vocabulary_item_id: 1167,
      text: 'Sicilian',
      preferred: true,
      language: 'en',
      id: 1801
    }, {
      vocabulary_item_id: 1173,
      text: 'Sardi',
      preferred: false,
      language: 'en',
      id: 1812
    }, {
      vocabulary_item_id: 1177,
      text: 'Sabine',
      preferred: true,
      language: 'en',
      id: 1816
    }, {
      vocabulary_item_id: 1181,
      text: 'Rhegian',
      preferred: true,
      language: 'en',
      id: 1820
    }, {
      vocabulary_item_id: 1220,
      text: 'Locrian',
      preferred: true,
      language: 'en',
      id: 1860
    }, {
      vocabulary_item_id: 1224,
      text: 'Larissan',
      preferred: true,
      language: 'en',
      id: 1864
    }, {
      vocabulary_item_id: 1265,
      text: 'Carystian',
      preferred: false,
      language: 'en',
      id: 1911
    }, {
      vocabulary_item_id: 1269,
      text: 'Calabrian',
      preferred: true,
      language: 'en',
      id: 1915
    }, {
      vocabulary_item_id: 369,
      text: 'zh',
      preferred: true,
      language: 'en',
      id: 645
    }, {
      vocabulary_item_id: 426,
      text: 'Mohs',
      preferred: true,
      language: 'en',
      id: 708
    }, {
      vocabulary_item_id: 432,
      text: 'minutes',
      preferred: false,
      language: 'en',
      id: 719
    }, {
      vocabulary_item_id: 444,
      text: 'millimetres',
      preferred: false,
      language: 'en',
      id: 741
    }, {
      vocabulary_item_id: 562,
      text: 'SIT',
      preferred: true,
      language: 'en',
      id: 863
    }, {
      vocabulary_item_id: 576,
      text: 'Mali Franc',
      preferred: false,
      language: 'en',
      id: 892
    }, {
      vocabulary_item_id: 582,
      text: 'GNE',
      preferred: true,
      language: 'en',
      id: 903
    }, {
      vocabulary_item_id: 596,
      text: 'XOF',
      preferred: false,
      language: 'en',
      id: 932
    }, {
      vocabulary_item_id: 602,
      text: 'Old Turkish Lira',
      preferred: true,
      language: 'en',
      id: 943
    }, {
      vocabulary_item_id: 616,
      text: 'Azerbaijan Manat',
      preferred: false,
      language: 'en',
      id: 972
    }, {
      vocabulary_item_id: 622,
      text: 'SYP',
      preferred: true,
      language: 'en',
      id: 983
    }, {
      vocabulary_item_id: 636,
      text: 'PGK',
      preferred: false,
      language: 'en',
      id: 1012
    }, {
      vocabulary_item_id: 642,
      text: 'FKP',
      preferred: true,
      language: 'en',
      id: 1023
    }, {
      vocabulary_item_id: 656,
      text: 'Turkish Lira',
      preferred: false,
      language: 'en',
      id: 1052
    }, {
      vocabulary_item_id: 662,
      text: 'CHF',
      preferred: true,
      language: 'en',
      id: 1063
    }, {
      vocabulary_item_id: 676,
      text: 'Burundi Franc',
      preferred: false,
      language: 'en',
      id: 1092
    }, {
      vocabulary_item_id: 682,
      text: 'BWP',
      preferred: true,
      language: 'en',
      id: 1103
    }, {
      vocabulary_item_id: 696,
      text: 'Mozambique Escudo',
      preferred: false,
      language: 'en',
      id: 1132
    }, {
      vocabulary_item_id: 702,
      text: 'BGL',
      preferred: true,
      language: 'en',
      id: 1143
    }, {
      vocabulary_item_id: 716,
      text: 'Ghana Cedi',
      preferred: false,
      language: 'en',
      id: 1172
    }, {
      vocabulary_item_id: 722,
      text: 'ECS',
      preferred: true,
      language: 'en',
      id: 1183
    }, {
      vocabulary_item_id: 736,
      text: 'Mozambique Metical',
      preferred: false,
      language: 'en',
      id: 1212
    }, {
      vocabulary_item_id: 742,
      text: 'YER',
      preferred: true,
      language: 'en',
      id: 1223
    }, {
      vocabulary_item_id: 756,
      text: 'Rial Omani',
      preferred: false,
      language: 'en',
      id: 1252
    }, {
      vocabulary_item_id: 761,
      text: 'South Sudanese Pound',
      preferred: false,
      language: 'en',
      id: 1263
    }, {
      vocabulary_item_id: 767,
      text: 'BOB',
      preferred: true,
      language: 'en',
      id: 1274
    }, {
      vocabulary_item_id: 781,
      text: 'VNC',
      preferred: false,
      language: 'en',
      id: 1303
    }, {
      vocabulary_item_id: 787,
      text: 'DOP',
      preferred: true,
      language: 'en',
      id: 1314
    }, {
      vocabulary_item_id: 801,
      text: 'Peso',
      preferred: false,
      language: 'en',
      id: 1343
    }, {
      vocabulary_item_id: 807,
      text: 'Lilangeni',
      preferred: true,
      language: 'en',
      id: 1354
    }, {
      vocabulary_item_id: 821,
      text: 'Belarussian Ruble',
      preferred: false,
      language: 'en',
      id: 1383
    }, {
      vocabulary_item_id: 827,
      text: 'Inti',
      preferred: true,
      language: 'en',
      id: 1394
    }, {
      vocabulary_item_id: 841,
      text: 'North Korean Won',
      preferred: false,
      language: 'en',
      id: 1423
    }, {
      vocabulary_item_id: 855,
      text: 'volume',
      preferred: true,
      language: 'en',
      id: 1440
    }, {
      vocabulary_item_id: 859,
      text: 'diameter',
      preferred: true,
      language: 'en',
      id: 1444
    }, {
      vocabulary_item_id: 886,
      text: 'Yemeni',
      preferred: true,
      language: 'en',
      id: 1471
    }, {
      vocabulary_item_id: 900,
      text: 'US',
      preferred: false,
      language: 'en',
      id: 1490
    }, {
      vocabulary_item_id: 902,
      text: 'Emirian',
      preferred: false,
      language: 'en',
      id: 1497
    }, {
      vocabulary_item_id: 906,
      text: 'Turks and Caicos Island',
      preferred: true,
      language: 'en',
      id: 1501
    }, {
      vocabulary_item_id: 910,
      text: 'Trinidadian',
      preferred: true,
      language: 'en',
      id: 1505
    }, {
      vocabulary_item_id: 940,
      text: 'Serbian',
      preferred: true,
      language: 'en',
      id: 1540
    }, {
      vocabulary_item_id: 958,
      text: 'Réunionnais',
      preferred: false,
      language: 'en',
      id: 1563
    }, {
      vocabulary_item_id: 962,
      text: 'Polish',
      preferred: true,
      language: 'en',
      id: 1567
    }, {
      vocabulary_item_id: 1018,
      text: 'Kyrgyz',
      preferred: true,
      language: 'en',
      id: 1633
    }, {
      vocabulary_item_id: 1028,
      text: 'Japanese',
      preferred: true,
      language: 'en',
      id: 1648
    }, {
      vocabulary_item_id: 1032,
      text: 'Italian',
      preferred: true,
      language: 'en',
      id: 1652
    }, {
      vocabulary_item_id: 1036,
      text: 'Iraqi',
      preferred: true,
      language: 'en',
      id: 1656
    }, {
      vocabulary_item_id: 1057,
      text: 'German',
      preferred: true,
      language: 'en',
      id: 1683
    }, {
      vocabulary_item_id: 1061,
      text: 'French Southern Territories',
      preferred: true,
      language: 'en',
      id: 1687
    }, {
      vocabulary_item_id: 1065,
      text: 'Finnish',
      preferred: true,
      language: 'en',
      id: 1691
    }, {
      vocabulary_item_id: 1069,
      text: 'European',
      preferred: true,
      language: 'en',
      id: 1695
    }, {
      vocabulary_item_id: 1073,
      text: 'Equatorial Guinean',
      preferred: true,
      language: 'en',
      id: 1699
    }, {
      vocabulary_item_id: 1080,
      text: 'Czech',
      preferred: true,
      language: 'en',
      id: 1707
    }, {
      vocabulary_item_id: 1084,
      text: 'Croatian',
      preferred: true,
      language: 'en',
      id: 1711
    }, {
      vocabulary_item_id: 1091,
      text: 'Christmas Island',
      preferred: true,
      language: 'en',
      id: 1719
    }, {
      vocabulary_item_id: 1095,
      text: 'Central African',
      preferred: true,
      language: 'en',
      id: 1723
    }, {
      vocabulary_item_id: 1099,
      text: 'Cambodian',
      preferred: true,
      language: 'en',
      id: 1727
    }, {
      vocabulary_item_id: 1110,
      text: 'Bosnian',
      preferred: true,
      language: 'en',
      id: 1739
    }, {
      vocabulary_item_id: 1114,
      text: 'Bermudian',
      preferred: true,
      language: 'en',
      id: 1743
    }, {
      vocabulary_item_id: 1171,
      text: 'Sarmatian',
      preferred: true,
      language: 'en',
      id: 1806
    }, {
      vocabulary_item_id: 1181,
      text: 'Rhegine',
      preferred: false,
      language: 'en',
      id: 1821
    }, {
      vocabulary_item_id: 1185,
      text: 'Phrygian',
      preferred: true,
      language: 'en',
      id: 1825
    }, {
      vocabulary_item_id: 1189,
      text: 'Philippian',
      preferred: true,
      language: 'en',
      id: 1829
    }, {
      vocabulary_item_id: 1193,
      text: 'Patmian',
      preferred: true,
      language: 'en',
      id: 1833
    }, {
      vocabulary_item_id: 1197,
      text: 'Orcadian',
      preferred: true,
      language: 'en',
      id: 1837
    }, {
      vocabulary_item_id: 1201,
      text: 'Nisyrian',
      preferred: true,
      language: 'en',
      id: 1841
    }, {
      vocabulary_item_id: 1205,
      text: 'Mycenaean',
      preferred: true,
      language: 'en',
      id: 1845
    }, {
      vocabulary_item_id: 1209,
      text: 'Messenian',
      preferred: true,
      language: 'en',
      id: 1849
    }, {
      vocabulary_item_id: 1213,
      text: 'Megarian',
      preferred: true,
      language: 'en',
      id: 1853
    }, {
      vocabulary_item_id: 1217,
      text: 'Maeonian',
      preferred: true,
      language: 'en',
      id: 1857
    }, {
      vocabulary_item_id: 1228,
      text: 'Kalymnian',
      preferred: true,
      language: 'en',
      id: 1869
    }, {
      vocabulary_item_id: 1232,
      text: 'Germanic',
      preferred: true,
      language: 'en',
      id: 1873
    }, {
      vocabulary_item_id: 1273,
      text: 'Boius',
      preferred: true,
      language: 'en',
      id: 1920
    }, {
      vocabulary_item_id: 1307,
      text: 'Bay of Plenty',
      preferred: true,
      language: 'en',
      id: 1959
    }, {
      vocabulary_item_id: 1311,
      text: 'Sonoran',
      preferred: true,
      language: 'en',
      id: 1963
    }, {
      vocabulary_item_id: 1315,
      text: 'Pueblan',
      preferred: true,
      language: 'en',
      id: 1967
    }, {
      vocabulary_item_id: 1318,
      text: 'Guerreran',
      preferred: true,
      language: 'en',
      id: 1971
    }, {
      vocabulary_item_id: 1322,
      text: 'Chiapan',
      preferred: true,
      language: 'en',
      id: 1975
    }, {
      vocabulary_item_id: 1326,
      text: 'East Malaysian',
      preferred: true,
      language: 'en',
      id: 1979
    }, {
      vocabulary_item_id: 1344,
      text: 'West Bengalese',
      preferred: false,
      language: 'en',
      id: 2002
    }, {
      vocabulary_item_id: 1354,
      text: 'Odissi',
      preferred: false,
      language: 'en',
      id: 2017
    }, {
      vocabulary_item_id: 427,
      text: 'ct',
      preferred: true,
      language: 'en',
      id: 709
    }, {
      vocabulary_item_id: 433,
      text: 's',
      preferred: true,
      language: 'en',
      id: 720
    }, {
      vocabulary_item_id: 439,
      text: 'gram',
      preferred: false,
      language: 'en',
      id: 731
    }, {
      vocabulary_item_id: 445,
      text: 'in',
      preferred: true,
      language: 'en',
      id: 742
    }, {
      vocabulary_item_id: 571,
      text: 'XEU',
      preferred: false,
      language: 'en',
      id: 882
    }, {
      vocabulary_item_id: 577,
      text: 'LVL',
      preferred: true,
      language: 'en',
      id: 893
    }, {
      vocabulary_item_id: 591,
      text: 'New Cruzado',
      preferred: false,
      language: 'en',
      id: 922
    }, {
      vocabulary_item_id: 611,
      text: 'Talonas',
      preferred: false,
      language: 'en',
      id: 962
    }, {
      vocabulary_item_id: 617,
      text: 'SCR',
      preferred: true,
      language: 'en',
      id: 973
    }, {
      vocabulary_item_id: 631,
      text: 'Iranian Rial',
      preferred: false,
      language: 'en',
      id: 1002
    }, {
      vocabulary_item_id: 637,
      text: 'BEL',
      preferred: true,
      language: 'en',
      id: 1013
    }, {
      vocabulary_item_id: 651,
      text: 'GRD',
      preferred: false,
      language: 'en',
      id: 1042
    }, {
      vocabulary_item_id: 657,
      text: 'NZD',
      preferred: true,
      language: 'en',
      id: 1053
    }, {
      vocabulary_item_id: 671,
      text: 'YUM',
      preferred: false,
      language: 'en',
      id: 1082
    }, {
      vocabulary_item_id: 677,
      text: 'ANG',
      preferred: true,
      language: 'en',
      id: 1093
    }, {
      vocabulary_item_id: 691,
      text: 'Lempira',
      preferred: false,
      language: 'en',
      id: 1122
    }, {
      vocabulary_item_id: 697,
      text: 'AUD',
      preferred: true,
      language: 'en',
      id: 1133
    }, {
      vocabulary_item_id: 711,
      text: 'Cuban Peso',
      preferred: false,
      language: 'en',
      id: 1162
    }, {
      vocabulary_item_id: 717,
      text: 'BEF',
      preferred: true,
      language: 'en',
      id: 1173
    }, {
      vocabulary_item_id: 731,
      text: 'New Israeli Sheqel',
      preferred: false,
      language: 'en',
      id: 1202
    }, {
      vocabulary_item_id: 737,
      text: 'BHD',
      preferred: true,
      language: 'en',
      id: 1213
    }, {
      vocabulary_item_id: 751,
      text: 'TOP',
      preferred: false,
      language: 'en',
      id: 1242
    }, {
      vocabulary_item_id: 757,
      text: 'MXN',
      preferred: true,
      language: 'en',
      id: 1253
    }, {
      vocabulary_item_id: 762,
      text: 'RUB',
      preferred: true,
      language: 'en',
      id: 1264
    }, {
      vocabulary_item_id: 776,
      text: 'Mvdol',
      preferred: false,
      language: 'en',
      id: 1293
    }, {
      vocabulary_item_id: 782,
      text: 'RON',
      preferred: true,
      language: 'en',
      id: 1304
    }, {
      vocabulary_item_id: 796,
      text: 'Markka',
      preferred: false,
      language: 'en',
      id: 1333
    }, {
      vocabulary_item_id: 802,
      text: 'CYP',
      preferred: true,
      language: 'en',
      id: 1344
    }, {
      vocabulary_item_id: 816,
      text: 'HTG',
      preferred: false,
      language: 'en',
      id: 1373
    }, {
      vocabulary_item_id: 822,
      text: 'AWG',
      preferred: true,
      language: 'en',
      id: 1384
    }, {
      vocabulary_item_id: 836,
      text: 'Cruzado',
      preferred: false,
      language: 'en',
      id: 1413
    }, {
      vocabulary_item_id: 842,
      text: 'KHR',
      preferred: true,
      language: 'en',
      id: 1424
    }, {
      vocabulary_item_id: 856,
      text: 'length',
      preferred: true,
      language: 'en',
      id: 1441
    }, {
      vocabulary_item_id: 860,
      text: 'width',
      preferred: true,
      language: 'en',
      id: 1445
    }, {
      vocabulary_item_id: 887,
      text: 'Sahrawi',
      preferred: true,
      language: 'en',
      id: 1472
    }, {
      vocabulary_item_id: 903,
      text: 'Ukrainian',
      preferred: true,
      language: 'en',
      id: 1498
    }, {
      vocabulary_item_id: 907,
      text: 'Turkmen',
      preferred: true,
      language: 'en',
      id: 1502
    }, {
      vocabulary_item_id: 937,
      text: 'Singaporean',
      preferred: false,
      language: 'en',
      id: 1537
    }, {
      vocabulary_item_id: 941,
      text: 'Senegalese',
      preferred: true,
      language: 'en',
      id: 1541
    }, {
      vocabulary_item_id: 959,
      text: 'Qatari',
      preferred: true,
      language: 'en',
      id: 1564
    }, {
      vocabulary_item_id: 963,
      text: 'Pitcairn Island',
      preferred: true,
      language: 'en',
      id: 1568
    }, {
      vocabulary_item_id: 1025,
      text: 'Kazakhstani',
      preferred: false,
      language: 'en',
      id: 1645
    }, {
      vocabulary_item_id: 1029,
      text: 'Jan Mayen',
      preferred: true,
      language: 'en',
      id: 1649
    }, {
      vocabulary_item_id: 1033,
      text: 'Israeli',
      preferred: true,
      language: 'en',
      id: 1653
    }, {
      vocabulary_item_id: 1037,
      text: 'Iranian',
      preferred: true,
      language: 'en',
      id: 1657
    }, {
      vocabulary_item_id: 1054,
      text: 'Hellenic',
      preferred: false,
      language: 'en',
      id: 1680
    }, {
      vocabulary_item_id: 1058,
      text: 'Georgian',
      preferred: true,
      language: 'en',
      id: 1684
    }, {
      vocabulary_item_id: 1062,
      text: 'French Polynesian',
      preferred: true,
      language: 'en',
      id: 1688
    }, {
      vocabulary_item_id: 1066,
      text: 'Fijian',
      preferred: true,
      language: 'en',
      id: 1692
    }, {
      vocabulary_item_id: 1070,
      text: 'Ethiopian',
      preferred: true,
      language: 'en',
      id: 1696
    }, {
      vocabulary_item_id: 1077,
      text: 'Dominican',
      preferred: true,
      language: 'en',
      id: 1704
    }, {
      vocabulary_item_id: 1081,
      text: 'Cypriot',
      preferred: true,
      language: 'en',
      id: 1708
    }, {
      vocabulary_item_id: 1088,
      text: 'Comorian',
      preferred: false,
      language: 'en',
      id: 1716
    }, {
      vocabulary_item_id: 1092,
      text: 'Chinese',
      preferred: true,
      language: 'en',
      id: 1720
    }, {
      vocabulary_item_id: 1096,
      text: 'Caymanian',
      preferred: true,
      language: 'en',
      id: 1724
    }, {
      vocabulary_item_id: 1100,
      text: 'Cabo Verdean',
      preferred: true,
      language: 'en',
      id: 1728
    }, {
      vocabulary_item_id: 1111,
      text: 'Bonaire',
      preferred: true,
      language: 'en',
      id: 1740
    }, {
      vocabulary_item_id: 1182,
      text: 'Pylosian',
      preferred: true,
      language: 'en',
      id: 1822
    }, {
      vocabulary_item_id: 1186,
      text: 'Phoenician',
      preferred: true,
      language: 'en',
      id: 1826
    }, {
      vocabulary_item_id: 1190,
      text: 'Persian',
      preferred: true,
      language: 'en',
      id: 1830
    }, {
      vocabulary_item_id: 1194,
      text: 'Parian',
      preferred: true,
      language: 'en',
      id: 1834
    }, {
      vocabulary_item_id: 1198,
      text: 'Oiniadan',
      preferred: true,
      language: 'en',
      id: 1838
    }, {
      vocabulary_item_id: 1202,
      text: 'Naxian',
      preferred: true,
      language: 'en',
      id: 1842
    }, {
      vocabulary_item_id: 1206,
      text: 'Moravian',
      preferred: true,
      language: 'en',
      id: 1846
    }, {
      vocabulary_item_id: 1210,
      text: 'Mesopotamian',
      preferred: true,
      language: 'en',
      id: 1850
    }, {
      vocabulary_item_id: 1214,
      text: 'Median',
      preferred: true,
      language: 'en',
      id: 1854
    }, {
      vocabulary_item_id: 1225,
      text: 'Lakedaemonian',
      preferred: false,
      language: 'en',
      id: 1866
    }, {
      vocabulary_item_id: 1229,
      text: 'Ionian',
      preferred: true,
      language: 'en',
      id: 1870
    }, {
      vocabulary_item_id: 1274,
      text: 'Boeotian',
      preferred: true,
      language: 'en',
      id: 1921
    }, {
      vocabulary_item_id: 1308,
      text: 'Auckland',
      preferred: true,
      language: 'en',
      id: 1960
    }, {
      vocabulary_item_id: 1312,
      text: 'Sinaloan',
      preferred: true,
      language: 'en',
      id: 1964
    }, {
      vocabulary_item_id: 1319,
      text: 'Durangoan',
      preferred: true,
      language: 'en',
      id: 1972
    }, {
      vocabulary_item_id: 1323,
      text: 'Campechan',
      preferred: true,
      language: 'en',
      id: 1976
    }, {
      vocabulary_item_id: 1327,
      text: 'West Malaysian',
      preferred: true,
      language: 'en',
      id: 1980
    }, {
      vocabulary_item_id: 1355,
      text: 'Nagalandese',
      preferred: true,
      language: 'en',
      id: 2018
    }, {
      vocabulary_item_id: 1369,
      text: 'Goanese',
      preferred: false,
      language: 'en',
      id: 2037
    }, {
      vocabulary_item_id: 370,
      text: 'it',
      preferred: true,
      language: 'en',
      id: 647
    }, {
      vocabulary_item_id: 427,
      text: 'carats',
      preferred: false,
      language: 'en',
      id: 710
    }, {
      vocabulary_item_id: 433,
      text: 'seconds',
      preferred: false,
      language: 'en',
      id: 721
    }, {
      vocabulary_item_id: 445,
      text: 'inches',
      preferred: false,
      language: 'en',
      id: 743
    }, {
      vocabulary_item_id: 563,
      text: 'ZWN',
      preferred: true,
      language: 'en',
      id: 865
    }, {
      vocabulary_item_id: 577,
      text: 'Latvian Lats',
      preferred: false,
      language: 'en',
      id: 894
    }, {
      vocabulary_item_id: 583,
      text: 'ESA',
      preferred: true,
      language: 'en',
      id: 905
    }, {
      vocabulary_item_id: 603,
      text: 'ALK',
      preferred: true,
      language: 'en',
      id: 945
    }, {
      vocabulary_item_id: 617,
      text: 'Seychelles Rupee',
      preferred: false,
      language: 'en',
      id: 974
    }, {
      vocabulary_item_id: 623,
      text: 'NAD',
      preferred: true,
      language: 'en',
      id: 985
    }, {
      vocabulary_item_id: 637,
      text: 'Financial Franc',
      preferred: false,
      language: 'en',
      id: 1014
    }, {
      vocabulary_item_id: 657,
      text: 'New Zealand Dollar',
      preferred: false,
      language: 'en',
      id: 1054
    }, {
      vocabulary_item_id: 663,
      text: 'CDF',
      preferred: true,
      language: 'en',
      id: 1065
    }, {
      vocabulary_item_id: 677,
      text: 'Netherlands Antillean Guilder',
      preferred: false,
      language: 'en',
      id: 1094
    }, {
      vocabulary_item_id: 683,
      text: 'BDT',
      preferred: true,
      language: 'en',
      id: 1105
    }, {
      vocabulary_item_id: 697,
      text: 'Australian Dollar',
      preferred: false,
      language: 'en',
      id: 1134
    }, {
      vocabulary_item_id: 703,
      text: 'RWF',
      preferred: true,
      language: 'en',
      id: 1145
    }, {
      vocabulary_item_id: 717,
      text: 'Belgian Franc',
      preferred: false,
      language: 'en',
      id: 1174
    }, {
      vocabulary_item_id: 723,
      text: 'Somoni',
      preferred: true,
      language: 'en',
      id: 1185
    }, {
      vocabulary_item_id: 737,
      text: 'Bahraini Dinar',
      preferred: false,
      language: 'en',
      id: 1214
    }, {
      vocabulary_item_id: 743,
      text: 'GEL',
      preferred: true,
      language: 'en',
      id: 1225
    }, {
      vocabulary_item_id: 757,
      text: 'Mexican Peso',
      preferred: false,
      language: 'en',
      id: 1254
    }, {
      vocabulary_item_id: 762,
      text: 'Russian Ruble',
      preferred: false,
      language: 'en',
      id: 1265
    }, {
      vocabulary_item_id: 768,
      text: 'BMD',
      preferred: true,
      language: 'en',
      id: 1276
    }, {
      vocabulary_item_id: 782,
      text: 'Romanian Leu',
      preferred: false,
      language: 'en',
      id: 1305
    }, {
      vocabulary_item_id: 788,
      text: 'AOA',
      preferred: true,
      language: 'en',
      id: 1316
    }, {
      vocabulary_item_id: 802,
      text: 'Cyprus Pound',
      preferred: false,
      language: 'en',
      id: 1345
    }, {
      vocabulary_item_id: 808,
      text: 'SOS',
      preferred: true,
      language: 'en',
      id: 1356
    }, {
      vocabulary_item_id: 822,
      text: 'Aruban Florin',
      preferred: false,
      language: 'en',
      id: 1385
    }, {
      vocabulary_item_id: 828,
      text: 'GHS',
      preferred: true,
      language: 'en',
      id: 1396
    }, {
      vocabulary_item_id: 842,
      text: 'Riel',
      preferred: false,
      language: 'en',
      id: 1425
    }, {
      vocabulary_item_id: 845,
      text: 'hardness',
      preferred: true,
      language: 'en',
      id: 1430
    }, {
      vocabulary_item_id: 849,
      text: 'speed',
      preferred: true,
      language: 'en',
      id: 1434
    }, {
      vocabulary_item_id: 872,
      text: 'Prices need review',
      preferred: true,
      language: 'en',
      id: 1457
    }, {
      vocabulary_item_id: 876,
      text: 'Created',
      preferred: true,
      language: 'en',
      id: 1461
    }, {
      vocabulary_item_id: 887,
      text: 'Sahrawian',
      preferred: false,
      language: 'en',
      id: 1473
    }, {
      vocabulary_item_id: 901,
      text: 'British',
      preferred: true,
      language: 'en',
      id: 1492
    }, {
      vocabulary_item_id: 911,
      text: 'Tongan',
      preferred: true,
      language: 'en',
      id: 1507
    }, {
      vocabulary_item_id: 915,
      text: 'Thai',
      preferred: true,
      language: 'en',
      id: 1511
    }, {
      vocabulary_item_id: 919,
      text: 'Swiss',
      preferred: true,
      language: 'en',
      id: 1515
    }, {
      vocabulary_item_id: 923,
      text: 'Surinamese',
      preferred: true,
      language: 'en',
      id: 1519
    }, {
      vocabulary_item_id: 927,
      text: 'South Sudanese',
      preferred: true,
      language: 'en',
      id: 1523
    }, {
      vocabulary_item_id: 967,
      text: 'Papuan',
      preferred: true,
      language: 'en',
      id: 1573
    }, {
      vocabulary_item_id: 985,
      text: 'Namibian',
      preferred: true,
      language: 'en',
      id: 1596
    }, {
      vocabulary_item_id: 989,
      text: 'Montserratian',
      preferred: true,
      language: 'en',
      id: 1600
    }, {
      vocabulary_item_id: 1037,
      text: 'Persian',
      preferred: false,
      language: 'en',
      id: 1658
    }, {
      vocabulary_item_id: 1041,
      text: 'Hungarian',
      preferred: true,
      language: 'en',
      id: 1662
    }, {
      vocabulary_item_id: 1074,
      text: 'Salvadoran',
      preferred: true,
      language: 'en',
      id: 1701
    }, {
      vocabulary_item_id: 1115,
      text: 'Beninese',
      preferred: true,
      language: 'en',
      id: 1745
    }, {
      vocabulary_item_id: 1233,
      text: 'Gaulish',
      preferred: true,
      language: 'en',
      id: 1875
    }, {
      vocabulary_item_id: 1237,
      text: 'Etruscan',
      preferred: true,
      language: 'en',
      id: 1879
    }, {
      vocabulary_item_id: 1241,
      text: 'Epidamnian',
      preferred: true,
      language: 'en',
      id: 1883
    }, {
      vocabulary_item_id: 1274,
      text: 'Beotian',
      preferred: false,
      language: 'en',
      id: 1922
    }, {
      vocabulary_item_id: 1278,
      text: 'Arretine',
      preferred: true,
      language: 'en',
      id: 1926
    }, {
      vocabulary_item_id: 1282,
      text: 'Apulian',
      preferred: true,
      language: 'en',
      id: 1930
    }, {
      vocabulary_item_id: 1286,
      text: 'Aeginetan',
      preferred: true,
      language: 'en',
      id: 1934
    }, {
      vocabulary_item_id: 1290,
      text: 'Stewart Island',
      preferred: true,
      language: 'en',
      id: 1938
    }, {
      vocabulary_item_id: 1316,
      text: 'Oaxacan',
      preferred: true,
      language: 'en',
      id: 1969
    }, {
      vocabulary_item_id: 1327,
      text: 'Peninsular',
      preferred: false,
      language: 'en',
      id: 1981
    }, {
      vocabulary_item_id: 1331,
      text: 'Sabahan',
      preferred: true,
      language: 'en',
      id: 1985
    }, {
      vocabulary_item_id: 1335,
      text: 'Penangite',
      preferred: true,
      language: 'en',
      id: 1989
    }, {
      vocabulary_item_id: 1339,
      text: 'Labuanese',
      preferred: true,
      language: 'en',
      id: 1993
    }, {
      vocabulary_item_id: 1343,
      text: 'Johorean',
      preferred: true,
      language: 'en',
      id: 1997
    }, {
      vocabulary_item_id: 1345,
      text: 'Uttarakhandi',
      preferred: true,
      language: 'en',
      id: 2004
    }, {
      vocabulary_item_id: 1355,
      text: 'Naga',
      preferred: false,
      language: 'en',
      id: 2019
    }, {
      vocabulary_item_id: 1359,
      text: 'Maharashtrian',
      preferred: true,
      language: 'en',
      id: 2023
    }, {
      vocabulary_item_id: 1363,
      text: 'Karnatakan',
      preferred: true,
      language: 'en',
      id: 2027
    }, {
      vocabulary_item_id: 1383,
      text: 'Rhenish',
      preferred: true,
      language: 'en',
      id: 2054
    }, {
      vocabulary_item_id: 1394,
      text: 'Saskatchewan',
      preferred: true,
      language: 'en',
      id: 2066
    }, {
      vocabulary_item_id: 1423,
      text: 'Afro-Eurasian',
      preferred: true,
      language: 'en',
      id: 2098
    }, {
      vocabulary_item_id: 643,
      text: 'SDR (Special Drawing Right)',
      preferred: false,
      language: 'en',
      id: 1025
    }, {
      vocabulary_item_id: 371,
      text: 'de',
      preferred: true,
      language: 'en',
      id: 649
    }, {
      vocabulary_item_id: 428,
      text: 'karat',
      preferred: false,
      language: 'en',
      id: 712
    }, {
      vocabulary_item_id: 434,
      text: 'milliseconds',
      preferred: false,
      language: 'en',
      id: 723
    }, {
      vocabulary_item_id: 564,
      text: 'MAF',
      preferred: true,
      language: 'en',
      id: 867
    }, {
      vocabulary_item_id: 578,
      text: 'Russian Ruble',
      preferred: false,
      language: 'en',
      id: 896
    }, {
      vocabulary_item_id: 584,
      text: 'CSD',
      preferred: true,
      language: 'en',
      id: 907
    }, {
      vocabulary_item_id: 598,
      text: 'SVC',
      preferred: false,
      language: 'en',
      id: 936
    }, {
      vocabulary_item_id: 604,
      text: 'ADP',
      preferred: true,
      language: 'en',
      id: 947
    }, {
      vocabulary_item_id: 618,
      text: 'Austral',
      preferred: false,
      language: 'en',
      id: 976
    }, {
      vocabulary_item_id: 624,
      text: 'SGD',
      preferred: true,
      language: 'en',
      id: 987
    }, {
      vocabulary_item_id: 638,
      text: 'Zambian Kwacha',
      preferred: false,
      language: 'en',
      id: 1016
    }, {
      vocabulary_item_id: 644,
      text: 'UGX',
      preferred: true,
      language: 'en',
      id: 1027
    }, {
      vocabulary_item_id: 658,
      text: 'NIO',
      preferred: false,
      language: 'en',
      id: 1056
    }, {
      vocabulary_item_id: 664,
      text: 'CAD',
      preferred: true,
      language: 'en',
      id: 1067
    }, {
      vocabulary_item_id: 678,
      text: 'Luxembourg Financial Franc',
      preferred: false,
      language: 'en',
      id: 1096
    }, {
      vocabulary_item_id: 684,
      text: 'Baht',
      preferred: true,
      language: 'en',
      id: 1107
    }, {
      vocabulary_item_id: 698,
      text: 'UAE Dirham',
      preferred: false,
      language: 'en',
      id: 1136
    }, {
      vocabulary_item_id: 704,
      text: 'PLN',
      preferred: true,
      language: 'en',
      id: 1147
    }, {
      vocabulary_item_id: 718,
      text: 'Convertible Franc',
      preferred: false,
      language: 'en',
      id: 1176
    }, {
      vocabulary_item_id: 724,
      text: 'Dobra',
      preferred: true,
      language: 'en',
      id: 1187
    }, {
      vocabulary_item_id: 738,
      text: 'Old Krona',
      preferred: false,
      language: 'en',
      id: 1216
    }, {
      vocabulary_item_id: 744,
      text: 'ARP',
      preferred: true,
      language: 'en',
      id: 1227
    }, {
      vocabulary_item_id: 758,
      text: 'Tenge',
      preferred: false,
      language: 'en',
      id: 1256
    }, {
      vocabulary_item_id: 763,
      text: 'Cabo Verde Escudo',
      preferred: false,
      language: 'en',
      id: 1267
    }, {
      vocabulary_item_id: 769,
      text: 'BAM',
      preferred: true,
      language: 'en',
      id: 1278
    }, {
      vocabulary_item_id: 783,
      text: 'Mexican Unidad de Inversion (UDI)',
      preferred: false,
      language: 'en',
      id: 1307
    }, {
      vocabulary_item_id: 789,
      text: 'MXP',
      preferred: true,
      language: 'en',
      id: 1318
    }, {
      vocabulary_item_id: 803,
      text: 'XUA',
      preferred: false,
      language: 'en',
      id: 1347
    }, {
      vocabulary_item_id: 809,
      text: 'Balboa',
      preferred: true,
      language: 'en',
      id: 1358
    }, {
      vocabulary_item_id: 823,
      text: 'Armenian Dram',
      preferred: false,
      language: 'en',
      id: 1387
    }, {
      vocabulary_item_id: 829,
      text: 'EGP',
      preferred: true,
      language: 'en',
      id: 1398
    }, {
      vocabulary_item_id: 843,
      text: 'Guinea Franc',
      preferred: false,
      language: 'en',
      id: 1427
    }, {
      vocabulary_item_id: 888,
      text: 'Futunan',
      preferred: true,
      language: 'en',
      id: 1475
    }, {
      vocabulary_item_id: 892,
      text: 'U.S. Virgin Island',
      preferred: true,
      language: 'en',
      id: 1479
    }, {
      vocabulary_item_id: 896,
      text: 'Vatican',
      preferred: true,
      language: 'en',
      id: 1483
    }, {
      vocabulary_item_id: 932,
      text: 'Solomon Island',
      preferred: true,
      language: 'en',
      id: 1529
    }, {
      vocabulary_item_id: 946,
      text: 'Samoan',
      preferred: true,
      language: 'en',
      id: 1548
    }, {
      vocabulary_item_id: 968,
      text: 'Panamanian',
      preferred: true,
      language: 'en',
      id: 1575
    }, {
      vocabulary_item_id: 972,
      text: 'Omani',
      preferred: true,
      language: 'en',
      id: 1579
    }, {
      vocabulary_item_id: 976,
      text: 'Norfolk Island',
      preferred: true,
      language: 'en',
      id: 1583
    }, {
      vocabulary_item_id: 980,
      text: 'Nicaraguan',
      preferred: true,
      language: 'en',
      id: 1587
    }, {
      vocabulary_item_id: 994,
      text: 'Micronesian',
      preferred: true,
      language: 'en',
      id: 1606
    }, {
      vocabulary_item_id: 998,
      text: 'Mauritanian',
      preferred: true,
      language: 'en',
      id: 1610
    }, {
      vocabulary_item_id: 1042,
      text: 'Hong Kongese',
      preferred: true,
      language: 'en',
      id: 1664
    }, {
      vocabulary_item_id: 1116,
      text: 'Belizean',
      preferred: true,
      language: 'en',
      id: 1747
    }, {
      vocabulary_item_id: 1120,
      text: 'Bangladeshi',
      preferred: true,
      language: 'en',
      id: 1751
    }, {
      vocabulary_item_id: 1246,
      text: 'Edonian',
      preferred: true,
      language: 'en',
      id: 1889
    }, {
      vocabulary_item_id: 1250,
      text: 'Dacian',
      preferred: true,
      language: 'en',
      id: 1893
    }, {
      vocabulary_item_id: 1253,
      text: 'Cycladian',
      preferred: true,
      language: 'en',
      id: 1897
    }, {
      vocabulary_item_id: 1257,
      text: 'Corcyrean',
      preferred: true,
      language: 'en',
      id: 1901
    }, {
      vocabulary_item_id: 1261,
      text: 'Chiot',
      preferred: true,
      language: 'en',
      id: 1905
    }, {
      vocabulary_item_id: 1291,
      text: 'Mainland',
      preferred: false,
      language: 'en',
      id: 1940
    }, {
      vocabulary_item_id: 1344,
      text: 'Bengalese',
      preferred: false,
      language: 'en',
      id: 1999
    }, {
      vocabulary_item_id: 1364,
      text: 'Bihari',
      preferred: true,
      language: 'en',
      id: 2029
    }, {
      vocabulary_item_id: 1377,
      text: 'Andhraite',
      preferred: true,
      language: 'en',
      id: 2048
    }, {
      vocabulary_item_id: 1381,
      text: 'Swabian',
      preferred: true,
      language: 'en',
      id: 2052
    }, {
      vocabulary_item_id: 1388,
      text: 'Hessian',
      preferred: true,
      language: 'en',
      id: 2060
    }, {
      vocabulary_item_id: 1392,
      text: 'Brandenburgish',
      preferred: true,
      language: 'en',
      id: 2064
    }, {
      vocabulary_item_id: 1399,
      text: 'Nunavut',
      preferred: true,
      language: 'en',
      id: 2072
    }, {
      vocabulary_item_id: 1421,
      text: 'Asian',
      preferred: true,
      language: 'en',
      id: 2096
    }, {
      vocabulary_item_id: 1440,
      text: 'official name',
      preferred: true,
      language: 'en',
      id: 2115
    }, {
      vocabulary_item_id: 1631,
      text: 'exhibition catalog',
      preferred: true,
      language: 'en',
      id: 2306
    }, {
      vocabulary_item_id: 1635,
      text: 'brochure',
      preferred: true,
      language: 'en',
      id: 2310
    }, {
      vocabulary_item_id: 1639,
      text: 'letter',
      preferred: true,
      language: 'en',
      id: 2314
    }, {
      vocabulary_item_id: 1643,
      text: 'biography',
      preferred: true,
      language: 'en',
      id: 2318
    }, {
      vocabulary_item_id: 1647,
      text: 'catalog raisonné',
      preferred: true,
      language: 'en',
      id: 2322
    }, {
      vocabulary_item_id: 372,
      text: 'fr',
      preferred: true,
      language: 'en',
      id: 651
    }, {
      vocabulary_item_id: 375,
      text: 'statue',
      preferred: true,
      language: 'en',
      id: 656
    }, {
      vocabulary_item_id: 379,
      text: 'sculpture',
      preferred: true,
      language: 'en',
      id: 660
    }, {
      vocabulary_item_id: 383,
      text: 'collage',
      preferred: true,
      language: 'en',
      id: 664
    }, {
      vocabulary_item_id: 387,
      text: 'glass',
      preferred: true,
      language: 'en',
      id: 668
    }, {
      vocabulary_item_id: 391,
      text: 'ethnographic',
      preferred: true,
      language: 'en',
      id: 672
    }, {
      vocabulary_item_id: 395,
      text: 'performance',
      preferred: true,
      language: 'en',
      id: 676
    }, {
      vocabulary_item_id: 399,
      text: 'wine',
      preferred: true,
      language: 'en',
      id: 680
    }, {
      vocabulary_item_id: 403,
      text: 'furniture',
      preferred: true,
      language: 'en',
      id: 684
    }, {
      vocabulary_item_id: 407,
      text: 'mural',
      preferred: true,
      language: 'en',
      id: 688
    }, {
      vocabulary_item_id: 411,
      text: 'photography',
      preferred: true,
      language: 'en',
      id: 692
    }, {
      vocabulary_item_id: 415,
      text: 'coins and currency',
      preferred: true,
      language: 'en',
      id: 696
    }, {
      vocabulary_item_id: 429,
      text: 'atmospheres',
      preferred: false,
      language: 'en',
      id: 714
    }, {
      vocabulary_item_id: 559,
      text: 'ZRN',
      preferred: false,
      language: 'en',
      id: 858
    }, {
      vocabulary_item_id: 565,
      text: 'New Yugoslavian Dinar',
      preferred: true,
      language: 'en',
      id: 869
    }, {
      vocabulary_item_id: 579,
      text: 'Pound',
      preferred: false,
      language: 'en',
      id: 898
    }, {
      vocabulary_item_id: 585,
      text: 'EEK',
      preferred: true,
      language: 'en',
      id: 909
    }, {
      vocabulary_item_id: 599,
      text: 'Surinam Dollar',
      preferred: false,
      language: 'en',
      id: 938
    }, {
      vocabulary_item_id: 605,
      text: 'TTD',
      preferred: true,
      language: 'en',
      id: 949
    }, {
      vocabulary_item_id: 619,
      text: 'PYG',
      preferred: false,
      language: 'en',
      id: 978
    }, {
      vocabulary_item_id: 625,
      text: 'QAR',
      preferred: true,
      language: 'en',
      id: 989
    }, {
      vocabulary_item_id: 639,
      text: 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
      preferred: false,
      language: 'en',
      id: 1018
    }, {
      vocabulary_item_id: 645,
      text: 'TZS',
      preferred: true,
      language: 'en',
      id: 1029
    }, {
      vocabulary_item_id: 659,
      text: 'MMK',
      preferred: false,
      language: 'en',
      id: 1058
    }, {
      vocabulary_item_id: 665,
      text: 'LBP',
      preferred: true,
      language: 'en',
      id: 1069
    }, {
      vocabulary_item_id: 679,
      text: 'Koruna',
      preferred: false,
      language: 'en',
      id: 1098
    }, {
      vocabulary_item_id: 685,
      text: 'Bolivar',
      preferred: true,
      language: 'en',
      id: 1109
    }, {
      vocabulary_item_id: 699,
      text: 'Zimbabwe Dollar',
      preferred: false,
      language: 'en',
      id: 1138
    }, {
      vocabulary_item_id: 705,
      text: 'NGN',
      preferred: true,
      language: 'en',
      id: 1149
    }, {
      vocabulary_item_id: 719,
      text: 'Schilling',
      preferred: false,
      language: 'en',
      id: 1178
    }, {
      vocabulary_item_id: 725,
      text: 'SEK',
      preferred: true,
      language: 'en',
      id: 1189
    }, {
      vocabulary_item_id: 739,
      text: 'Krona A/53',
      preferred: false,
      language: 'en',
      id: 1218
    }, {
      vocabulary_item_id: 745,
      text: 'East Caribbean Dollar',
      preferred: true,
      language: 'en',
      id: 1229
    }, {
      vocabulary_item_id: 759,
      text: 'VND',
      preferred: false,
      language: 'en',
      id: 1258
    }, {
      vocabulary_item_id: 764,
      text: 'PEN',
      preferred: false,
      language: 'en',
      id: 1269
    }, {
      vocabulary_item_id: 770,
      text: 'AFN',
      preferred: true,
      language: 'en',
      id: 1280
    }, {
      vocabulary_item_id: 784,
      text: 'MWK',
      preferred: false,
      language: 'en',
      id: 1309
    }, {
      vocabulary_item_id: 790,
      text: 'MVQ',
      preferred: true,
      language: 'en',
      id: 1320
    }, {
      vocabulary_item_id: 804,
      text: 'Peoples Bank Dollar',
      preferred: false,
      language: 'en',
      id: 1349
    }, {
      vocabulary_item_id: 810,
      text: 'MDL',
      preferred: true,
      language: 'en',
      id: 1360
    }, {
      vocabulary_item_id: 824,
      text: 'Tajik Ruble',
      preferred: false,
      language: 'en',
      id: 1389
    }, {
      vocabulary_item_id: 830,
      text: 'DJF',
      preferred: true,
      language: 'en',
      id: 1400
    }, {
      vocabulary_item_id: 844,
      text: 'Yuan Renminbi',
      preferred: false,
      language: 'en',
      id: 1429
    }, {
      vocabulary_item_id: 863,
      text: 'Sold',
      preferred: true,
      language: 'en',
      id: 1448
    }, {
      vocabulary_item_id: 867,
      text: 'Unsold',
      preferred: true,
      language: 'en',
      id: 1452
    }, {
      vocabulary_item_id: 897,
      text: 'Ni-Vanuatu',
      preferred: false,
      language: 'en',
      id: 1485
    }, {
      vocabulary_item_id: 933,
      text: 'Slovene',
      preferred: false,
      language: 'en',
      id: 1531
    }, {
      vocabulary_item_id: 947,
      text: 'Vincentian',
      preferred: false,
      language: 'en',
      id: 1550
    }, {
      vocabulary_item_id: 981,
      text: 'NZ',
      preferred: false,
      language: 'en',
      id: 1589
    }, {
      vocabulary_item_id: 999,
      text: 'Martinican',
      preferred: false,
      language: 'en',
      id: 1612
    }, {
      vocabulary_item_id: 1003,
      text: 'Maldivian',
      preferred: true,
      language: 'en',
      id: 1616
    }, {
      vocabulary_item_id: 1007,
      text: 'Macedonian',
      preferred: true,
      language: 'en',
      id: 1620
    }, {
      vocabulary_item_id: 1043,
      text: 'Honduran',
      preferred: true,
      language: 'en',
      id: 1666
    }, {
      vocabulary_item_id: 1050,
      text: 'Guamanian',
      preferred: true,
      language: 'en',
      id: 1674
    }, {
      vocabulary_item_id: 1125,
      text: 'Australian',
      preferred: true,
      language: 'en',
      id: 1757
    }, {
      vocabulary_item_id: 1129,
      text: 'Barbudan',
      preferred: true,
      language: 'en',
      id: 1761
    }, {
      vocabulary_item_id: 1133,
      text: 'Angolan',
      preferred: true,
      language: 'en',
      id: 1765
    }, {
      vocabulary_item_id: 1137,
      text: 'Albanian',
      preferred: true,
      language: 'en',
      id: 1769
    }, {
      vocabulary_item_id: 1296,
      text: 'Tasman',
      preferred: true,
      language: 'en',
      id: 1946
    }, {
      vocabulary_item_id: 1300,
      text: 'Northland',
      preferred: true,
      language: 'en',
      id: 1950
    }, {
      vocabulary_item_id: 1351,
      text: 'Rajasthani',
      preferred: true,
      language: 'en',
      id: 2012
    }, {
      vocabulary_item_id: 1365,
      text: 'Jammu',
      preferred: false,
      language: 'en',
      id: 2031
    }, {
      vocabulary_item_id: 1369,
      text: 'Goan ',
      preferred: true,
      language: 'en',
      id: 2035
    }, {
      vocabulary_item_id: 1400,
      text: 'N.W.T.',
      preferred: false,
      language: 'en',
      id: 2074
    }, {
      vocabulary_item_id: 1404,
      text: 'British Columbian ',
      preferred: true,
      language: 'en',
      id: 2078
    }, {
      vocabulary_item_id: 2293,
      text: 'Pharmacy',
      preferred: true,
      language: 'en',
      id: 2981
    }, {
      vocabulary_item_id: 2297,
      text: 'Urban Planning',
      preferred: true,
      language: 'en',
      id: 2985
    }, {
      vocabulary_item_id: 2301,
      text: 'Surgery ',
      preferred: true,
      language: 'en',
      id: 2989
    }, {
      vocabulary_item_id: 2305,
      text: 'Science in Taxation',
      preferred: true,
      language: 'en',
      id: 2993
    }, {
      vocabulary_item_id: 2309,
      text: 'Science in Project Management',
      preferred: true,
      language: 'en',
      id: 2997
    }, {
      vocabulary_item_id: 2313,
      text: 'Science in Information Systems Management',
      preferred: true,
      language: 'en',
      id: 3001
    }, {
      vocabulary_item_id: 2317,
      text: 'Science in Education',
      preferred: true,
      language: 'en',
      id: 3005
    }, {
      vocabulary_item_id: 2321,
      text: 'Research',
      preferred: true,
      language: 'en',
      id: 3009
    }, {
      vocabulary_item_id: 373,
      text: 'en',
      preferred: true,
      language: 'en',
      id: 653
    }, {
      vocabulary_item_id: 424,
      text: 'dwt',
      preferred: true,
      language: 'en',
      id: 705
    }, {
      vocabulary_item_id: 449,
      text: 'North American',
      preferred: true,
      language: 'en',
      id: 747
    }, {
      vocabulary_item_id: 453,
      text: 'Sake',
      preferred: true,
      language: 'en',
      id: 751
    }, {
      vocabulary_item_id: 457,
      text: 'String Instruments',
      preferred: true,
      language: 'en',
      id: 755
    }, {
      vocabulary_item_id: 461,
      text: 'History of Science',
      preferred: true,
      language: 'en',
      id: 759
    }, {
      vocabulary_item_id: 465,
      text: 'Fine Minerals',
      preferred: true,
      language: 'en',
      id: 763
    }, {
      vocabulary_item_id: 469,
      text: 'Aeronautical',
      preferred: true,
      language: 'en',
      id: 767
    }, {
      vocabulary_item_id: 473,
      text: 'Aboriginal',
      preferred: true,
      language: 'en',
      id: 771
    }, {
      vocabulary_item_id: 477,
      text: 'Silver',
      preferred: true,
      language: 'en',
      id: 775
    }, {
      vocabulary_item_id: 481,
      text: 'Transportation',
      preferred: true,
      language: 'en',
      id: 779
    }, {
      vocabulary_item_id: 485,
      text: 'Natural History',
      preferred: true,
      language: 'en',
      id: 783
    }, {
      vocabulary_item_id: 489,
      text: 'Pianos',
      preferred: true,
      language: 'en',
      id: 787
    }, {
      vocabulary_item_id: 493,
      text: 'Photographs',
      preferred: true,
      language: 'en',
      id: 791
    }, {
      vocabulary_item_id: 497,
      text: 'Books and Manuscripts',
      preferred: true,
      language: 'en',
      id: 795
    }, {
      vocabulary_item_id: 501,
      text: 'Comic Books',
      preferred: true,
      language: 'en',
      id: 799
    }, {
      vocabulary_item_id: 505,
      text: 'Collectibles and Memorabilia',
      preferred: true,
      language: 'en',
      id: 803
    }, {
      vocabulary_item_id: 509,
      text: 'Fine Art',
      preferred: true,
      language: 'en',
      id: 807
    }, {
      vocabulary_item_id: 513,
      text: 'Antiquities',
      preferred: true,
      language: 'en',
      id: 811
    }, {
      vocabulary_item_id: 517,
      text: 'Certificates',
      preferred: true,
      language: 'en',
      id: 815
    }, {
      vocabulary_item_id: 521,
      text: 'Toys and Dolls',
      preferred: true,
      language: 'en',
      id: 819
    }, {
      vocabulary_item_id: 525,
      text: 'Works on Paper',
      preferred: true,
      language: 'en',
      id: 823
    }, {
      vocabulary_item_id: 529,
      text: 'Uniforms',
      preferred: true,
      language: 'en',
      id: 827
    }, {
      vocabulary_item_id: 533,
      text: 'Hanging Scrolls',
      preferred: true,
      language: 'en',
      id: 831
    }, {
      vocabulary_item_id: 537,
      text: 'Tiles',
      preferred: true,
      language: 'en',
      id: 835
    }, {
      vocabulary_item_id: 541,
      text: 'Medals and Decorations',
      preferred: true,
      language: 'en',
      id: 839
    }, {
      vocabulary_item_id: 545,
      text: 'Installations',
      preferred: true,
      language: 'en',
      id: 843
    }, {
      vocabulary_item_id: 549,
      text: 'Coins, Currency, and Stamps',
      preferred: true,
      language: 'en',
      id: 847
    }, {
      vocabulary_item_id: 553,
      text: 'Miniatures',
      preferred: true,
      language: 'en',
      id: 851
    }, {
      vocabulary_item_id: 557,
      text: 'Correspondence',
      preferred: true,
      language: 'en',
      id: 855
    }, {
      vocabulary_item_id: 560,
      text: 'Yugoslavian Dinar',
      preferred: false,
      language: 'en',
      id: 860
    }, {
      vocabulary_item_id: 566,
      text: 'Kip Pot Pol',
      preferred: true,
      language: 'en',
      id: 871
    }, {
      vocabulary_item_id: 580,
      text: 'GQE',
      preferred: false,
      language: 'en',
      id: 900
    }, {
      vocabulary_item_id: 586,
      text: 'TMM',
      preferred: true,
      language: 'en',
      id: 911
    }, {
      vocabulary_item_id: 600,
      text: 'SLL',
      preferred: false,
      language: 'en',
      id: 940
    }, {
      vocabulary_item_id: 606,
      text: 'PTE',
      preferred: true,
      language: 'en',
      id: 951
    }, {
      vocabulary_item_id: 620,
      text: 'Nakfa',
      preferred: false,
      language: 'en',
      id: 980
    }, {
      vocabulary_item_id: 626,
      text: 'Denar',
      preferred: true,
      language: 'en',
      id: 991
    }, {
      vocabulary_item_id: 640,
      text: 'ZAR',
      preferred: false,
      language: 'en',
      id: 1020
    }, {
      vocabulary_item_id: 646,
      text: 'Rhodesian Dollar',
      preferred: true,
      language: 'en',
      id: 1031
    }, {
      vocabulary_item_id: 660,
      text: 'Malagasy Ariary',
      preferred: false,
      language: 'en',
      id: 1060
    }, {
      vocabulary_item_id: 666,
      text: 'TND',
      preferred: true,
      language: 'en',
      id: 1071
    }, {
      vocabulary_item_id: 680,
      text: 'Barbados Dollar',
      preferred: false,
      language: 'en',
      id: 1100
    }, {
      vocabulary_item_id: 686,
      text: 'Karbovanet',
      preferred: true,
      language: 'en',
      id: 1111
    }, {
      vocabulary_item_id: 700,
      text: 'Latvian Ruble',
      preferred: false,
      language: 'en',
      id: 1140
    }, {
      vocabulary_item_id: 706,
      text: 'MUR',
      preferred: true,
      language: 'en',
      id: 1151
    }, {
      vocabulary_item_id: 720,
      text: 'DZD',
      preferred: false,
      language: 'en',
      id: 1180
    }, {
      vocabulary_item_id: 726,
      text: 'MRO',
      preferred: true,
      language: 'en',
      id: 1191
    }, {
      vocabulary_item_id: 740,
      text: 'WIR Franc (for electronic)',
      preferred: false,
      language: 'en',
      id: 1220
    }, {
      vocabulary_item_id: 746,
      text: 'Tala',
      preferred: true,
      language: 'en',
      id: 1231
    }, {
      vocabulary_item_id: 760,
      text: 'Loti',
      preferred: false,
      language: 'en',
      id: 1260
    }, {
      vocabulary_item_id: 765,
      text: 'Norwegian Krone',
      preferred: false,
      language: 'en',
      id: 1271
    }, {
      vocabulary_item_id: 771,
      text: 'IQD',
      preferred: true,
      language: 'en',
      id: 1282
    }, {
      vocabulary_item_id: 785,
      text: 'Pataca',
      preferred: false,
      language: 'en',
      id: 1311
    }, {
      vocabulary_item_id: 791,
      text: 'MTL',
      preferred: true,
      language: 'en',
      id: 1322
    }, {
      vocabulary_item_id: 805,
      text: 'Lev A/62',
      preferred: false,
      language: 'en',
      id: 1351
    }, {
      vocabulary_item_id: 811,
      text: 'LYD',
      preferred: true,
      language: 'en',
      id: 1362
    }, {
      vocabulary_item_id: 825,
      text: 'Som',
      preferred: false,
      language: 'en',
      id: 1391
    }, {
      vocabulary_item_id: 831,
      text: 'COU',
      preferred: true,
      language: 'en',
      id: 1402
    }, {
      vocabulary_item_id: 898,
      text: 'Uzbekistani',
      preferred: false,
      language: 'en',
      id: 1487
    }, {
      vocabulary_item_id: 948,
      text: 'Miquelonnais',
      preferred: false,
      language: 'en',
      id: 1552
    }, {
      vocabulary_item_id: 952,
      text: 'Saint Helenian',
      preferred: true,
      language: 'en',
      id: 1556
    }, {
      vocabulary_item_id: 956,
      text: 'Russian',
      preferred: true,
      language: 'en',
      id: 1560
    }, {
      vocabulary_item_id: 982,
      text: 'Netherlandic',
      preferred: false,
      language: 'en',
      id: 1591
    }, {
      vocabulary_item_id: 1012,
      text: 'Libyan',
      preferred: true,
      language: 'en',
      id: 1626
    }, {
      vocabulary_item_id: 1016,
      text: 'Latvian',
      preferred: true,
      language: 'en',
      id: 1630
    }, {
      vocabulary_item_id: 1022,
      text: 'North Korean',
      preferred: true,
      language: 'en',
      id: 1641
    }, {
      vocabulary_item_id: 1044,
      text: 'McDonald Islands',
      preferred: false,
      language: 'en',
      id: 1668
    }, {
      vocabulary_item_id: 1048,
      text: 'Guinean',
      preferred: true,
      language: 'en',
      id: 1672
    }, {
      vocabulary_item_id: 1051,
      text: 'Guadeloupe',
      preferred: true,
      language: 'en',
      id: 1676
    }, {
      vocabulary_item_id: 1085,
      text: 'Costa Rican',
      preferred: true,
      language: 'en',
      id: 1712
    }, {
      vocabulary_item_id: 1104,
      text: 'Bruneian',
      preferred: true,
      language: 'en',
      id: 1732
    }, {
      vocabulary_item_id: 1108,
      text: 'Motswana',
      preferred: true,
      language: 'en',
      id: 1736
    }, {
      vocabulary_item_id: 1142,
      text: 'Xanthian',
      preferred: true,
      language: 'en',
      id: 1775
    }, {
      vocabulary_item_id: 1146,
      text: 'Trichonian',
      preferred: true,
      language: 'en',
      id: 1779
    }, {
      vocabulary_item_id: 1150,
      text: 'Thriasian',
      preferred: true,
      language: 'en',
      id: 1783
    }, {
      vocabulary_item_id: 1154,
      text: 'Theban',
      preferred: true,
      language: 'en',
      id: 1787
    }, {
      vocabulary_item_id: 1161,
      text: 'Suebius',
      preferred: true,
      language: 'en',
      id: 1795
    }, {
      vocabulary_item_id: 1165,
      text: 'Sidonian',
      preferred: true,
      language: 'en',
      id: 1799
    }, {
      vocabulary_item_id: 1169,
      text: 'Serrean',
      preferred: true,
      language: 'en',
      id: 1803
    }, {
      vocabulary_item_id: 1175,
      text: 'Samnite',
      preferred: true,
      language: 'en',
      id: 1814
    }, {
      vocabulary_item_id: 1179,
      text: 'Rhithymnian',
      preferred: true,
      language: 'en',
      id: 1818
    }, {
      vocabulary_item_id: 1218,
      text: 'Lydian',
      preferred: true,
      language: 'en',
      id: 1858
    }, {
      vocabulary_item_id: 1222,
      text: 'Leontinian',
      preferred: true,
      language: 'en',
      id: 1862
    }, {
      vocabulary_item_id: 1267,
      text: 'Cantabrian',
      preferred: true,
      language: 'en',
      id: 1913
    }, {
      vocabulary_item_id: 1271,
      text: 'Bruttian',
      preferred: true,
      language: 'en',
      id: 1917
    }, {
      vocabulary_item_id: 1305,
      text: 'East Coast',
      preferred: true,
      language: 'en',
      id: 1956
    }, {
      vocabulary_item_id: 1405,
      text: 'Albertan',
      preferred: true,
      language: 'en',
      id: 2080
    }, {
      vocabulary_item_id: 1409,
      text: 'South Australian',
      preferred: true,
      language: 'en',
      id: 2084
    }, {
      vocabulary_item_id: 1413,
      text: 'Canberran',
      preferred: true,
      language: 'en',
      id: 2088
    }, {
      vocabulary_item_id: 368,
      text: 'Japanese',
      preferred: false,
      language: 'en',
      id: 644
    }, {
      vocabulary_item_id: 425,
      text: 'grain',
      preferred: true,
      language: 'en',
      id: 707
    }, {
      vocabulary_item_id: 561,
      text: 'Surinam Guilder',
      preferred: false,
      language: 'en',
      id: 862
    }, {
      vocabulary_item_id: 567,
      text: 'ILR',
      preferred: true,
      language: 'en',
      id: 873
    }, {
      vocabulary_item_id: 581,
      text: 'ESB',
      preferred: false,
      language: 'en',
      id: 902
    }, {
      vocabulary_item_id: 587,
      text: 'Rouble',
      preferred: true,
      language: 'en',
      id: 913
    }, {
      vocabulary_item_id: 601,
      text: 'Irish Pound',
      preferred: false,
      language: 'en',
      id: 942
    }, {
      vocabulary_item_id: 607,
      text: 'LTL',
      preferred: true,
      language: 'en',
      id: 953
    }, {
      vocabulary_item_id: 621,
      text: 'TWD',
      preferred: false,
      language: 'en',
      id: 982
    }, {
      vocabulary_item_id: 627,
      text: 'KRW',
      preferred: true,
      language: 'en',
      id: 993
    }, {
      vocabulary_item_id: 641,
      text: 'Turkmenistan New Manat',
      preferred: false,
      language: 'en',
      id: 1022
    }, {
      vocabulary_item_id: 647,
      text: 'MVR',
      preferred: true,
      language: 'en',
      id: 1033
    }, {
      vocabulary_item_id: 661,
      text: 'Guyana Dollar',
      preferred: false,
      language: 'en',
      id: 1062
    }, {
      vocabulary_item_id: 667,
      text: 'Kip',
      preferred: true,
      language: 'en',
      id: 1073
    }, {
      vocabulary_item_id: 681,
      text: 'Cruzeiro Real',
      preferred: false,
      language: 'en',
      id: 1102
    }, {
      vocabulary_item_id: 687,
      text: 'TPE',
      preferred: true,
      language: 'en',
      id: 1113
    }, {
      vocabulary_item_id: 701,
      text: 'ZAL',
      preferred: false,
      language: 'en',
      id: 1142
    }, {
      vocabulary_item_id: 707,
      text: 'SDP',
      preferred: true,
      language: 'en',
      id: 1153
    }, {
      vocabulary_item_id: 721,
      text: 'XAF',
      preferred: false,
      language: 'en',
      id: 1182
    }, {
      vocabulary_item_id: 727,
      text: 'LRD',
      preferred: true,
      language: 'en',
      id: 1193
    }, {
      vocabulary_item_id: 741,
      text: 'Zimbabwe Dollar',
      preferred: false,
      language: 'en',
      id: 1222
    }, {
      vocabulary_item_id: 747,
      text: 'EUR',
      preferred: true,
      language: 'en',
      id: 1233
    }, {
      vocabulary_item_id: 766,
      text: 'Bahamian Dollar',
      preferred: false,
      language: 'en',
      id: 1273
    }, {
      vocabulary_item_id: 772,
      text: 'Dalasi',
      preferred: true,
      language: 'en',
      id: 1284
    }, {
      vocabulary_item_id: 786,
      text: 'KMF',
      preferred: false,
      language: 'en',
      id: 1313
    }, {
      vocabulary_item_id: 792,
      text: 'SDD',
      preferred: true,
      language: 'en',
      id: 1324
    }, {
      vocabulary_item_id: 806,
      text: 'UYU',
      preferred: false,
      language: 'en',
      id: 1353
    }, {
      vocabulary_item_id: 812,
      text: 'MZN',
      preferred: true,
      language: 'en',
      id: 1364
    }, {
      vocabulary_item_id: 826,
      text: 'Kuna',
      preferred: false,
      language: 'en',
      id: 1393
    }, {
      vocabulary_item_id: 832,
      text: 'CLP',
      preferred: true,
      language: 'en',
      id: 1404
    }, {
      vocabulary_item_id: 854,
      text: 'weight',
      preferred: true,
      language: 'en',
      id: 1439
    }, {
      vocabulary_item_id: 858,
      text: 'circumference',
      preferred: true,
      language: 'en',
      id: 1443
    }, {
      vocabulary_item_id: 862,
      text: 'thickness',
      preferred: true,
      language: 'en',
      id: 1447
    }, {
      vocabulary_item_id: 885,
      text: 'Zambian',
      preferred: true,
      language: 'en',
      id: 1470
    }, {
      vocabulary_item_id: 905,
      text: 'Tuvaluan',
      preferred: true,
      language: 'en',
      id: 1500
    }, {
      vocabulary_item_id: 909,
      text: 'Tunisian',
      preferred: true,
      language: 'en',
      id: 1504
    }, {
      vocabulary_item_id: 939,
      text: 'Seychellois',
      preferred: true,
      language: 'en',
      id: 1539
    }, {
      vocabulary_item_id: 961,
      text: 'Portuguese',
      preferred: true,
      language: 'en',
      id: 1566
    }, {
      vocabulary_item_id: 983,
      text: 'Nepalese',
      preferred: true,
      language: 'en',
      id: 1593
    }, {
      vocabulary_item_id: 1017,
      text: 'Laotian',
      preferred: false,
      language: 'en',
      id: 1632
    }, {
      vocabulary_item_id: 1027,
      text: 'Channel Island',
      preferred: true,
      language: 'en',
      id: 1647
    }, {
      vocabulary_item_id: 1031,
      text: 'Ivorian',
      preferred: true,
      language: 'en',
      id: 1651
    }, {
      vocabulary_item_id: 1035,
      text: 'Irish',
      preferred: true,
      language: 'en',
      id: 1655
    }, {
      vocabulary_item_id: 1056,
      text: 'Ghanaian',
      preferred: true,
      language: 'en',
      id: 1682
    }, {
      vocabulary_item_id: 1060,
      text: 'Gabonese',
      preferred: true,
      language: 'en',
      id: 1686
    }, {
      vocabulary_item_id: 1064,
      text: 'French',
      preferred: true,
      language: 'en',
      id: 1690
    }, {
      vocabulary_item_id: 1068,
      text: 'Falkland Island',
      preferred: true,
      language: 'en',
      id: 1694
    }, {
      vocabulary_item_id: 1072,
      text: 'Eritrean',
      preferred: true,
      language: 'en',
      id: 1698
    }, {
      vocabulary_item_id: 1079,
      text: 'Danish',
      preferred: true,
      language: 'en',
      id: 1706
    }, {
      vocabulary_item_id: 1083,
      text: 'Cuban',
      preferred: true,
      language: 'en',
      id: 1710
    }, {
      vocabulary_item_id: 1090,
      text: 'Cocos Island',
      preferred: true,
      language: 'en',
      id: 1718
    }, {
      vocabulary_item_id: 1094,
      text: 'Chadian',
      preferred: true,
      language: 'en',
      id: 1722
    }, {
      vocabulary_item_id: 1098,
      text: 'Cameroonian',
      preferred: true,
      language: 'en',
      id: 1726
    }, {
      vocabulary_item_id: 1109,
      text: 'Herzegovinian',
      preferred: true,
      language: 'en',
      id: 1738
    }, {
      vocabulary_item_id: 1113,
      text: 'Bhutanese',
      preferred: true,
      language: 'en',
      id: 1742
    }, {
      vocabulary_item_id: 1170,
      text: 'Scytha',
      preferred: false,
      language: 'en',
      id: 1805
    }, {
      vocabulary_item_id: 1184,
      text: 'Pisidian',
      preferred: true,
      language: 'en',
      id: 1824
    }, {
      vocabulary_item_id: 1188,
      text: 'Phliasian',
      preferred: true,
      language: 'en',
      id: 1828
    }, {
      vocabulary_item_id: 1192,
      text: 'Peloponnesian',
      preferred: true,
      language: 'en',
      id: 1832
    }, {
      vocabulary_item_id: 1196,
      text: 'Paeonian',
      preferred: true,
      language: 'en',
      id: 1836
    }, {
      vocabulary_item_id: 1200,
      text: 'Oean',
      preferred: true,
      language: 'en',
      id: 1840
    }, {
      vocabulary_item_id: 1204,
      text: 'Mytilenean',
      preferred: true,
      language: 'en',
      id: 1844
    }, {
      vocabulary_item_id: 1208,
      text: 'Milesian',
      preferred: true,
      language: 'en',
      id: 1848
    }, {
      vocabulary_item_id: 1212,
      text: 'Melitian',
      preferred: true,
      language: 'en',
      id: 1852
    }, {
      vocabulary_item_id: 1216,
      text: 'Mantinean',
      preferred: true,
      language: 'en',
      id: 1856
    }, {
      vocabulary_item_id: 1227,
      text: 'Kaulonian',
      preferred: true,
      language: 'en',
      id: 1868
    }, {
      vocabulary_item_id: 1231,
      text: 'Iberian',
      preferred: true,
      language: 'en',
      id: 1872
    }, {
      vocabulary_item_id: 1272,
      text: 'Bosphoran',
      preferred: false,
      language: 'en',
      id: 1919
    }, {
      vocabulary_item_id: 1306,
      text: 'Canterbury',
      preferred: true,
      language: 'en',
      id: 1958
    }, {
      vocabulary_item_id: 1310,
      text: 'Tamaulipan',
      preferred: true,
      language: 'en',
      id: 1962
    }, {
      vocabulary_item_id: 1314,
      text: 'Quintanaroan',
      preferred: true,
      language: 'en',
      id: 1966
    }, {
      vocabulary_item_id: 1317,
      text: 'Jaliscan',
      preferred: true,
      language: 'en',
      id: 1970
    }, {
      vocabulary_item_id: 1321,
      text: 'Chihuahuan',
      preferred: true,
      language: 'en',
      id: 1974
    }, {
      vocabulary_item_id: 1325,
      text: 'Lower Californian',
      preferred: true,
      language: 'en',
      id: 1978
    }, {
      vocabulary_item_id: 369,
      text: 'Chinese',
      preferred: false,
      language: 'en',
      id: 646
    }, {
      vocabulary_item_id: 562,
      text: 'Tolar',
      preferred: false,
      language: 'en',
      id: 864
    }, {
      vocabulary_item_id: 568,
      text: 'ZRZ',
      preferred: true,
      language: 'en',
      id: 875
    }, {
      vocabulary_item_id: 582,
      text: 'Syli',
      preferred: false,
      language: 'en',
      id: 904
    }, {
      vocabulary_item_id: 588,
      text: 'Cordoba',
      preferred: true,
      language: 'en',
      id: 915
    }, {
      vocabulary_item_id: 602,
      text: 'TRL',
      preferred: false,
      language: 'en',
      id: 944
    }, {
      vocabulary_item_id: 608,
      text: 'AOK',
      preferred: true,
      language: 'en',
      id: 955
    }, {
      vocabulary_item_id: 622,
      text: 'Syrian Pound',
      preferred: false,
      language: 'en',
      id: 984
    }, {
      vocabulary_item_id: 628,
      text: 'MAD',
      preferred: true,
      language: 'en',
      id: 995
    }, {
      vocabulary_item_id: 642,
      text: 'Falkland Islands Pound',
      preferred: false,
      language: 'en',
      id: 1024
    }, {
      vocabulary_item_id: 648,
      text: 'ETB',
      preferred: true,
      language: 'en',
      id: 1035
    }, {
      vocabulary_item_id: 662,
      text: 'Swiss Franc',
      preferred: false,
      language: 'en',
      id: 1064
    }, {
      vocabulary_item_id: 668,
      text: 'COP',
      preferred: true,
      language: 'en',
      id: 1075
    }, {
      vocabulary_item_id: 682,
      text: 'Pula',
      preferred: false,
      language: 'en',
      id: 1104
    }, {
      vocabulary_item_id: 688,
      text: 'ARS',
      preferred: true,
      language: 'en',
      id: 1115
    }, {
      vocabulary_item_id: 702,
      text: 'Lev',
      preferred: false,
      language: 'en',
      id: 1144
    }, {
      vocabulary_item_id: 708,
      text: 'KES',
      preferred: true,
      language: 'en',
      id: 1155
    }, {
      vocabulary_item_id: 722,
      text: 'Sucre',
      preferred: false,
      language: 'en',
      id: 1184
    }, {
      vocabulary_item_id: 728,
      text: 'UYP',
      preferred: true,
      language: 'en',
      id: 1195
    }, {
      vocabulary_item_id: 742,
      text: 'Yemeni Rial',
      preferred: false,
      language: 'en',
      id: 1224
    }, {
      vocabulary_item_id: 748,
      text: 'UZS',
      preferred: true,
      language: 'en',
      id: 1235
    }, {
      vocabulary_item_id: 767,
      text: 'Boliviano',
      preferred: false,
      language: 'en',
      id: 1275
    }, {
      vocabulary_item_id: 773,
      text: 'GIP',
      preferred: true,
      language: 'en',
      id: 1286
    }, {
      vocabulary_item_id: 787,
      text: 'Dominican Peso',
      preferred: false,
      language: 'en',
      id: 1315
    }, {
      vocabulary_item_id: 793,
      text: 'LUF',
      preferred: true,
      language: 'en',
      id: 1326
    }, {
      vocabulary_item_id: 807,
      text: 'SZL',
      preferred: false,
      language: 'en',
      id: 1355
    }, {
      vocabulary_item_id: 813,
      text: 'KWD',
      preferred: true,
      language: 'en',
      id: 1366
    }, {
      vocabulary_item_id: 827,
      text: 'PEI',
      preferred: false,
      language: 'en',
      id: 1395
    }, {
      vocabulary_item_id: 833,
      text: 'CHE',
      preferred: true,
      language: 'en',
      id: 1406
    }, {
      vocabulary_item_id: 848,
      text: 'time',
      preferred: true,
      language: 'en',
      id: 1433
    }, {
      vocabulary_item_id: 852,
      text: 'refractive index',
      preferred: true,
      language: 'en',
      id: 1437
    }, {
      vocabulary_item_id: 871,
      text: 'Prices approved',
      preferred: true,
      language: 'en',
      id: 1456
    }, {
      vocabulary_item_id: 875,
      text: 'Catalog info needs review',
      preferred: true,
      language: 'en',
      id: 1460
    }, {
      vocabulary_item_id: 900,
      text: 'United States',
      preferred: false,
      language: 'en',
      id: 1491
    }, {
      vocabulary_item_id: 910,
      text: 'Tobagonian',
      preferred: false,
      language: 'en',
      id: 1506
    }, {
      vocabulary_item_id: 914,
      text: 'Timorese',
      preferred: true,
      language: 'en',
      id: 1510
    }, {
      vocabulary_item_id: 918,
      text: 'Syrian',
      preferred: true,
      language: 'en',
      id: 1514
    }, {
      vocabulary_item_id: 922,
      text: 'Svalbard',
      preferred: true,
      language: 'en',
      id: 1518
    }, {
      vocabulary_item_id: 926,
      text: 'Spanish',
      preferred: true,
      language: 'en',
      id: 1522
    }, {
      vocabulary_item_id: 966,
      text: 'Paraguayan',
      preferred: true,
      language: 'en',
      id: 1572
    }, {
      vocabulary_item_id: 984,
      text: 'Nauruan',
      preferred: true,
      language: 'en',
      id: 1595
    }, {
      vocabulary_item_id: 988,
      text: 'Moroccan',
      preferred: true,
      language: 'en',
      id: 1599
    }, {
      vocabulary_item_id: 992,
      text: 'Monégasque',
      preferred: true,
      language: 'en',
      id: 1603
    }, {
      vocabulary_item_id: 1018,
      text: 'Kyrgyzstani',
      preferred: false,
      language: 'en',
      id: 1634
    }, {
      vocabulary_item_id: 1040,
      text: 'Icelandic',
      preferred: true,
      language: 'en',
      id: 1661
    }, {
      vocabulary_item_id: 1073,
      text: 'Equatoguinean',
      preferred: false,
      language: 'en',
      id: 1700
    }, {
      vocabulary_item_id: 1114,
      text: 'Bermudan',
      preferred: false,
      language: 'en',
      id: 1744
    }, {
      vocabulary_item_id: 1171,
      text: 'Sarmata',
      preferred: false,
      language: 'en',
      id: 1807
    }, {
      vocabulary_item_id: 1232,
      text: 'Germanus',
      preferred: false,
      language: 'en',
      id: 1874
    }, {
      vocabulary_item_id: 1236,
      text: 'Euboean',
      preferred: true,
      language: 'en',
      id: 1878
    }, {
      vocabulary_item_id: 1240,
      text: 'Epidaurian',
      preferred: true,
      language: 'en',
      id: 1882
    }, {
      vocabulary_item_id: 1244,
      text: 'Eleusian',
      preferred: true,
      language: 'en',
      id: 1886
    }, {
      vocabulary_item_id: 1277,
      text: 'Athenian',
      preferred: true,
      language: 'en',
      id: 1925
    }, {
      vocabulary_item_id: 1281,
      text: 'Aquitanian',
      preferred: true,
      language: 'en',
      id: 1929
    }, {
      vocabulary_item_id: 1285,
      text: 'Aethaean',
      preferred: true,
      language: 'en',
      id: 1933
    }, {
      vocabulary_item_id: 1289,
      text: 'Chatham',
      preferred: true,
      language: 'en',
      id: 1937
    }, {
      vocabulary_item_id: 1315,
      text: 'Poblano',
      preferred: false,
      language: 'en',
      id: 1968
    }, {
      vocabulary_item_id: 1330,
      text: 'Sarawakian',
      preferred: true,
      language: 'en',
      id: 1984
    }, {
      vocabulary_item_id: 1334,
      text: 'Perakian',
      preferred: true,
      language: 'en',
      id: 1988
    }, {
      vocabulary_item_id: 1338,
      text: 'Malaccan',
      preferred: true,
      language: 'en',
      id: 1992
    }, {
      vocabulary_item_id: 1342,
      text: 'Kedahan',
      preferred: true,
      language: 'en',
      id: 1996
    }, {
      vocabulary_item_id: 1344,
      text: 'West Bangla',
      preferred: false,
      language: 'en',
      id: 2003
    }, {
      vocabulary_item_id: 1358,
      text: 'Manipuri',
      preferred: true,
      language: 'en',
      id: 2022
    }, {
      vocabulary_item_id: 1362,
      text: 'Keralite',
      preferred: true,
      language: 'en',
      id: 2026
    }, {
      vocabulary_item_id: 1382,
      text: 'Saxon',
      preferred: true,
      language: 'en',
      id: 2053
    }, {
      vocabulary_item_id: 1386,
      text: 'Westphalian',
      preferred: true,
      language: 'en',
      id: 2057
    }, {
      vocabulary_item_id: 1393,
      text: 'Yukon',
      preferred: true,
      language: 'en',
      id: 2065
    }, {
      vocabulary_item_id: 370,
      text: 'Italian',
      preferred: false,
      language: 'en',
      id: 648
    }, {
      vocabulary_item_id: 437,
      text: 't',
      preferred: true,
      language: 'en',
      id: 726
    }, {
      vocabulary_item_id: 563,
      text: 'Zimbabwe Dollar (new)',
      preferred: false,
      language: 'en',
      id: 866
    }, {
      vocabulary_item_id: 569,
      text: 'ZMK',
      preferred: true,
      language: 'en',
      id: 877
    }, {
      vocabulary_item_id: 583,
      text: 'Spanish Peseta',
      preferred: false,
      language: 'en',
      id: 906
    }, {
      vocabulary_item_id: 589,
      text: 'BYB',
      preferred: true,
      language: 'en',
      id: 917
    }, {
      vocabulary_item_id: 603,
      text: 'Old Lek',
      preferred: false,
      language: 'en',
      id: 946
    }, {
      vocabulary_item_id: 609,
      text: 'LUC',
      preferred: true,
      language: 'en',
      id: 957
    }, {
      vocabulary_item_id: 623,
      text: 'Namibia Dollar',
      preferred: false,
      language: 'en',
      id: 986
    }, {
      vocabulary_item_id: 629,
      text: 'AOR',
      preferred: true,
      language: 'en',
      id: 997
    }, {
      vocabulary_item_id: 649,
      text: 'RHD',
      preferred: true,
      language: 'en',
      id: 1037
    }, {
      vocabulary_item_id: 663,
      text: 'Congolese Franc',
      preferred: false,
      language: 'en',
      id: 1066
    }, {
      vocabulary_item_id: 669,
      text: 'CZK',
      preferred: true,
      language: 'en',
      id: 1077
    }, {
      vocabulary_item_id: 683,
      text: 'Taka',
      preferred: false,
      language: 'en',
      id: 1106
    }, {
      vocabulary_item_id: 689,
      text: 'Leu A/52',
      preferred: true,
      language: 'en',
      id: 1117
    }, {
      vocabulary_item_id: 703,
      text: 'Rwanda Franc',
      preferred: false,
      language: 'en',
      id: 1146
    }, {
      vocabulary_item_id: 709,
      text: 'LSM',
      preferred: true,
      language: 'en',
      id: 1157
    }, {
      vocabulary_item_id: 723,
      text: 'TJS',
      preferred: false,
      language: 'en',
      id: 1186
    }, {
      vocabulary_item_id: 729,
      text: 'Old Shilling',
      preferred: true,
      language: 'en',
      id: 1197
    }, {
      vocabulary_item_id: 743,
      text: 'Lari',
      preferred: false,
      language: 'en',
      id: 1226
    }, {
      vocabulary_item_id: 749,
      text: 'VUV',
      preferred: true,
      language: 'en',
      id: 1237
    }, {
      vocabulary_item_id: 768,
      text: 'Bermudian Dollar',
      preferred: false,
      language: 'en',
      id: 1277
    }, {
      vocabulary_item_id: 774,
      text: 'BZD',
      preferred: true,
      language: 'en',
      id: 1288
    }, {
      vocabulary_item_id: 788,
      text: 'Kwanza',
      preferred: false,
      language: 'en',
      id: 1317
    }, {
      vocabulary_item_id: 794,
      text: 'Cedi',
      preferred: true,
      language: 'en',
      id: 1328
    }, {
      vocabulary_item_id: 808,
      text: 'Somali Shilling',
      preferred: false,
      language: 'en',
      id: 1357
    }, {
      vocabulary_item_id: 814,
      text: 'MNT',
      preferred: true,
      language: 'en',
      id: 1368
    }, {
      vocabulary_item_id: 828,
      text: 'Ghana Cedi',
      preferred: false,
      language: 'en',
      id: 1397
    }, {
      vocabulary_item_id: 834,
      text: 'GEK',
      preferred: true,
      language: 'en',
      id: 1408
    }, {
      vocabulary_item_id: 887,
      text: 'Sahraouian',
      preferred: false,
      language: 'en',
      id: 1474
    }, {
      vocabulary_item_id: 891,
      text: 'Welsh',
      preferred: true,
      language: 'en',
      id: 1478
    }, {
      vocabulary_item_id: 895,
      text: 'Venezuelan',
      preferred: true,
      language: 'en',
      id: 1482
    }, {
      vocabulary_item_id: 901,
      text: 'UK',
      preferred: false,
      language: 'en',
      id: 1493
    }, {
      vocabulary_item_id: 931,
      text: 'Somali',
      preferred: true,
      language: 'en',
      id: 1528
    }, {
      vocabulary_item_id: 945,
      text: 'Sammarinese',
      preferred: true,
      language: 'en',
      id: 1547
    }, {
      vocabulary_item_id: 967,
      text: 'Papua New Guinean',
      preferred: false,
      language: 'en',
      id: 1574
    }, {
      vocabulary_item_id: 971,
      text: 'Pakistani',
      preferred: true,
      language: 'en',
      id: 1578
    }, {
      vocabulary_item_id: 975,
      text: 'Northern Irish',
      preferred: true,
      language: 'en',
      id: 1582
    }, {
      vocabulary_item_id: 979,
      text: 'Nigerien',
      preferred: true,
      language: 'en',
      id: 1586
    }, {
      vocabulary_item_id: 993,
      text: 'Moldovan',
      preferred: true,
      language: 'en',
      id: 1605
    }, {
      vocabulary_item_id: 997,
      text: 'Mauritian',
      preferred: true,
      language: 'en',
      id: 1609
    }, {
      vocabulary_item_id: 1041,
      text: 'Magyar',
      preferred: false,
      language: 'en',
      id: 1663
    }, {
      vocabulary_item_id: 1115,
      text: 'Beninois',
      preferred: false,
      language: 'en',
      id: 1746
    }, {
      vocabulary_item_id: 1119,
      text: 'Barbadian',
      preferred: true,
      language: 'en',
      id: 1750
    }, {
      vocabulary_item_id: 1123,
      text: 'Azerbaijani',
      preferred: true,
      language: 'en',
      id: 1754
    }, {
      vocabulary_item_id: 1172,
      text: 'Sardianus',
      preferred: true,
      language: 'en',
      id: 1809
    }, {
      vocabulary_item_id: 1245,
      text: 'Egestan',
      preferred: true,
      language: 'en',
      id: 1888
    }, {
      vocabulary_item_id: 1249,
      text: 'Dalmatian',
      preferred: true,
      language: 'en',
      id: 1892
    }, {
      vocabulary_item_id: 1081,
      text: 'Cypriote',
      preferred: false,
      language: 'en',
      id: 1896
    }, {
      vocabulary_item_id: 1256,
      text: 'Corsican',
      preferred: true,
      language: 'en',
      id: 1900
    }, {
      vocabulary_item_id: 1260,
      text: 'Colchian',
      preferred: true,
      language: 'en',
      id: 1904
    }, {
      vocabulary_item_id: 1264,
      text: 'Catalan',
      preferred: true,
      language: 'en',
      id: 1908
    }, {
      vocabulary_item_id: 1294,
      text: 'Wellington',
      preferred: true,
      language: 'en',
      id: 1943
    }, {
      vocabulary_item_id: 1349,
      text: 'Tamil',
      preferred: true,
      language: 'en',
      id: 2009
    }, {
      vocabulary_item_id: 1363,
      text: 'Canarese',
      preferred: false,
      language: 'en',
      id: 2028
    }, {
      vocabulary_item_id: 1376,
      text: 'Arunachal Pradesh',
      preferred: true,
      language: 'en',
      id: 2047
    }, {
      vocabulary_item_id: 1380,
      text: 'Thuringian',
      preferred: true,
      language: 'en',
      id: 2051
    }, {
      vocabulary_item_id: 1387,
      text: 'Lower Saxon',
      preferred: true,
      language: 'en',
      id: 2059
    }, {
      vocabulary_item_id: 1391,
      text: 'Franconian',
      preferred: true,
      language: 'en',
      id: 2063
    }, {
      vocabulary_item_id: 1398,
      text: 'Nova Scotian',
      preferred: true,
      language: 'en',
      id: 2071
    }, {
      vocabulary_item_id: 1420,
      text: 'Middle Eastern',
      preferred: true,
      language: 'en',
      id: 2095
    }, {
      vocabulary_item_id: 1439,
      text: 'birth name',
      preferred: true,
      language: 'en',
      id: 2114
    }, {
      vocabulary_item_id: 1634,
      text: 'dissertation',
      preferred: true,
      language: 'en',
      id: 2309
    }, {
      vocabulary_item_id: 1638,
      text: 'condition report',
      preferred: true,
      language: 'en',
      id: 2313
    }, {
      vocabulary_item_id: 1642,
      text: 'birth certificate',
      preferred: true,
      language: 'en',
      id: 2317
    }, {
      vocabulary_item_id: 1646,
      text: 'dictionary',
      preferred: true,
      language: 'en',
      id: 2321
    }, {
      vocabulary_item_id: 371,
      text: 'German',
      preferred: false,
      language: 'en',
      id: 650
    }, {
      vocabulary_item_id: 374,
      text: 'textiles',
      preferred: true,
      language: 'en',
      id: 655
    }, {
      vocabulary_item_id: 378,
      text: 'silver',
      preferred: true,
      language: 'en',
      id: 659
    }, {
      vocabulary_item_id: 382,
      text: 'etching',
      preferred: true,
      language: 'en',
      id: 663
    }, {
      vocabulary_item_id: 386,
      text: 'rugs and carpets',
      preferred: true,
      language: 'en',
      id: 667
    }, {
      vocabulary_item_id: 390,
      text: 'decorative art',
      preferred: true,
      language: 'en',
      id: 671
    }, {
      vocabulary_item_id: 394,
      text: 'work on paper',
      preferred: true,
      language: 'en',
      id: 675
    }, {
      vocabulary_item_id: 398,
      text: 'video art',
      preferred: true,
      language: 'en',
      id: 679
    }, {
      vocabulary_item_id: 402,
      text: 'screen printing',
      preferred: true,
      language: 'en',
      id: 683
    }, {
      vocabulary_item_id: 406,
      text: 'book',
      preferred: true,
      language: 'en',
      id: 687
    }, {
      vocabulary_item_id: 410,
      text: 'video and multimedia',
      preferred: true,
      language: 'en',
      id: 691
    }, {
      vocabulary_item_id: 414,
      text: 'manuscript',
      preferred: true,
      language: 'en',
      id: 695
    }, {
      vocabulary_item_id: 438,
      text: 'kg',
      preferred: true,
      language: 'en',
      id: 728
    }, {
      vocabulary_item_id: 564,
      text: 'Mali Franc',
      preferred: false,
      language: 'en',
      id: 868
    }, {
      vocabulary_item_id: 584,
      text: 'Serbian Dinar',
      preferred: false,
      language: 'en',
      id: 908
    }, {
      vocabulary_item_id: 590,
      text: 'CFP Franc',
      preferred: true,
      language: 'en',
      id: 919
    }, {
      vocabulary_item_id: 604,
      text: 'Andorran Peseta',
      preferred: false,
      language: 'en',
      id: 948
    }, {
      vocabulary_item_id: 610,
      text: 'GNS',
      preferred: true,
      language: 'en',
      id: 959
    }, {
      vocabulary_item_id: 624,
      text: 'Singapore Dollar',
      preferred: false,
      language: 'en',
      id: 988
    }, {
      vocabulary_item_id: 630,
      text: 'AON',
      preferred: true,
      language: 'en',
      id: 999
    }, {
      vocabulary_item_id: 644,
      text: 'Uganda Shilling',
      preferred: false,
      language: 'en',
      id: 1028
    }, {
      vocabulary_item_id: 650,
      text: 'GWE',
      preferred: true,
      language: 'en',
      id: 1039
    }, {
      vocabulary_item_id: 664,
      text: 'Canadian Dollar',
      preferred: false,
      language: 'en',
      id: 1068
    }, {
      vocabulary_item_id: 670,
      text: 'HKD',
      preferred: true,
      language: 'en',
      id: 1079
    }, {
      vocabulary_item_id: 684,
      text: 'THB',
      preferred: false,
      language: 'en',
      id: 1108
    }, {
      vocabulary_item_id: 690,
      text: 'JMD',
      preferred: true,
      language: 'en',
      id: 1119
    }, {
      vocabulary_item_id: 704,
      text: 'Zloty',
      preferred: false,
      language: 'en',
      id: 1148
    }, {
      vocabulary_item_id: 710,
      text: 'GBP',
      preferred: true,
      language: 'en',
      id: 1159
    }, {
      vocabulary_item_id: 724,
      text: 'STD',
      preferred: false,
      language: 'en',
      id: 1188
    }, {
      vocabulary_item_id: 730,
      text: 'JPY',
      preferred: true,
      language: 'en',
      id: 1199
    }, {
      vocabulary_item_id: 744,
      text: 'Peso Argentino',
      preferred: false,
      language: 'en',
      id: 1228
    }, {
      vocabulary_item_id: 750,
      text: 'Hryvnia',
      preferred: true,
      language: 'en',
      id: 1239
    }, {
      vocabulary_item_id: 769,
      text: 'Convertible Mark',
      preferred: false,
      language: 'en',
      id: 1279
    }, {
      vocabulary_item_id: 775,
      text: 'BTN',
      preferred: true,
      language: 'en',
      id: 1290
    }, {
      vocabulary_item_id: 789,
      text: 'Mexican Peso',
      preferred: false,
      language: 'en',
      id: 1319
    }, {
      vocabulary_item_id: 795,
      text: 'FRF',
      preferred: true,
      language: 'en',
      id: 1330
    }, {
      vocabulary_item_id: 809,
      text: 'PAB',
      preferred: false,
      language: 'en',
      id: 1359
    }, {
      vocabulary_item_id: 815,
      text: 'Cayman Islands Dollar',
      preferred: true,
      language: 'en',
      id: 1370
    }, {
      vocabulary_item_id: 829,
      text: 'Egyptian Pound',
      preferred: false,
      language: 'en',
      id: 1399
    }, {
      vocabulary_item_id: 835,
      text: 'BND',
      preferred: true,
      language: 'en',
      id: 1410
    }, {
      vocabulary_item_id: 866,
      text: 'Not Yet Available',
      preferred: true,
      language: 'en',
      id: 1451
    }, {
      vocabulary_item_id: 902,
      text: 'Emiri',
      preferred: true,
      language: 'en',
      id: 1495
    }, {
      vocabulary_item_id: 936,
      text: 'Statian',
      preferred: true,
      language: 'en',
      id: 1534
    }, {
      vocabulary_item_id: 1002,
      text: 'Malian, Malinese',
      preferred: true,
      language: 'en',
      id: 1615
    }, {
      vocabulary_item_id: 1006,
      text: 'Malagasy',
      preferred: true,
      language: 'en',
      id: 1619
    }, {
      vocabulary_item_id: 1020,
      text: 'Kosovar',
      preferred: true,
      language: 'en',
      id: 1638
    }, {
      vocabulary_item_id: 1042,
      text: 'Hong Kong',
      preferred: false,
      language: 'en',
      id: 1665
    }, {
      vocabulary_item_id: 1049,
      text: 'Guatemalan',
      preferred: true,
      language: 'en',
      id: 1673
    }, {
      vocabulary_item_id: 1124,
      text: 'Austrian',
      preferred: true,
      language: 'en',
      id: 1756
    }, {
      vocabulary_item_id: 1128,
      text: 'Argentine',
      preferred: true,
      language: 'en',
      id: 1760
    }, {
      vocabulary_item_id: 1132,
      text: 'Anguillan',
      preferred: true,
      language: 'en',
      id: 1764
    }, {
      vocabulary_item_id: 1136,
      text: 'Algerian',
      preferred: true,
      language: 'en',
      id: 1768
    }, {
      vocabulary_item_id: 1140,
      text: 'Abkhaz',
      preferred: true,
      language: 'en',
      id: 1772
    }, {
      vocabulary_item_id: 1173,
      text: 'Sardinian',
      preferred: true,
      language: 'en',
      id: 1811
    }, {
      vocabulary_item_id: 1265,
      text: 'Carthaginian',
      preferred: true,
      language: 'en',
      id: 1910
    }, {
      vocabulary_item_id: 1295,
      text: 'Waikato',
      preferred: true,
      language: 'en',
      id: 1945
    }, {
      vocabulary_item_id: 1299,
      text: 'Otago',
      preferred: true,
      language: 'en',
      id: 1949
    }, {
      vocabulary_item_id: 1303,
      text: 'Manawatu',
      preferred: true,
      language: 'en',
      id: 1953
    }, {
      vocabulary_item_id: 1344,
      text: 'Bangla',
      preferred: false,
      language: 'en',
      id: 2000
    }, {
      vocabulary_item_id: 1350,
      text: 'Sikkimese',
      preferred: true,
      language: 'en',
      id: 2011
    }, {
      vocabulary_item_id: 1354,
      text: 'Odishan',
      preferred: true,
      language: 'en',
      id: 2015
    }, {
      vocabulary_item_id: 1368,
      text: 'Gujarati',
      preferred: true,
      language: 'en',
      id: 2034
    }, {
      vocabulary_item_id: 1374,
      text: 'Chandigarhi',
      preferred: true,
      language: 'en',
      id: 2045
    }, {
      vocabulary_item_id: 1403,
      text: 'Manitoban',
      preferred: true,
      language: 'en',
      id: 2077
    }, {
      vocabulary_item_id: 1418,
      text: 'Eurasian',
      preferred: true,
      language: 'en',
      id: 2093
    }, {
      vocabulary_item_id: 2292,
      text: 'Juris Doctor',
      preferred: true,
      language: 'en',
      id: 2980
    }, {
      vocabulary_item_id: 2296,
      text: 'Veterinary Science',
      preferred: true,
      language: 'en',
      id: 2984
    }, {
      vocabulary_item_id: 2300,
      text: 'Studies',
      preferred: true,
      language: 'en',
      id: 2988
    }, {
      vocabulary_item_id: 2304,
      text: 'Science in Teaching',
      preferred: true,
      language: 'en',
      id: 2992
    }, {
      vocabulary_item_id: 2308,
      text: 'Science in Management',
      preferred: true,
      language: 'en',
      id: 2996
    }, {
      vocabulary_item_id: 2335,
      text: 'Philosophy ',
      preferred: true,
      language: 'en',
      id: 3023
    }, {
      vocabulary_item_id: 372,
      text: 'French',
      preferred: false,
      language: 'en',
      id: 652
    }, {
      vocabulary_item_id: 439,
      text: 'gr',
      preferred: true,
      language: 'en',
      id: 730
    }, {
      vocabulary_item_id: 448,
      text: 'Science and Technology',
      preferred: true,
      language: 'en',
      id: 746
    }, {
      vocabulary_item_id: 452,
      text: 'Fireplace Accessories',
      preferred: true,
      language: 'en',
      id: 750
    }, {
      vocabulary_item_id: 456,
      text: 'Percussion and Drums',
      preferred: true,
      language: 'en',
      id: 754
    }, {
      vocabulary_item_id: 460,
      text: 'Decorative Objects',
      preferred: true,
      language: 'en',
      id: 758
    }, {
      vocabulary_item_id: 464,
      text: 'Indigenous Art and Culture',
      preferred: true,
      language: 'en',
      id: 762
    }, {
      vocabulary_item_id: 468,
      text: 'African, Oceanic, and Pre-Columbian',
      preferred: true,
      language: 'en',
      id: 766
    }, {
      vocabulary_item_id: 472,
      text: 'Cameras',
      preferred: true,
      language: 'en',
      id: 770
    }, {
      vocabulary_item_id: 476,
      text: 'Lighting',
      preferred: true,
      language: 'en',
      id: 774
    }, {
      vocabulary_item_id: 480,
      text: 'Cognac',
      preferred: true,
      language: 'en',
      id: 778
    }, {
      vocabulary_item_id: 484,
      text: 'Computers and Electronics',
      preferred: true,
      language: 'en',
      id: 782
    }, {
      vocabulary_item_id: 488,
      text: 'Design',
      preferred: true,
      language: 'en',
      id: 786
    }, {
      vocabulary_item_id: 492,
      text: 'Video Art',
      preferred: true,
      language: 'en',
      id: 790
    }, {
      vocabulary_item_id: 496,
      text: 'Documents',
      preferred: true,
      language: 'en',
      id: 794
    }, {
      vocabulary_item_id: 500,
      text: 'Music and Sound Recordings',
      preferred: true,
      language: 'en',
      id: 798
    }, {
      vocabulary_item_id: 504,
      text: 'New Media Art',
      preferred: true,
      language: 'en',
      id: 802
    }, {
      vocabulary_item_id: 508,
      text: 'Motion Pictures',
      preferred: true,
      language: 'en',
      id: 806
    }, {
      vocabulary_item_id: 512,
      text: 'Books and Historical Documents',
      preferred: true,
      language: 'en',
      id: 810
    }, {
      vocabulary_item_id: 516,
      text: 'Trading Cards',
      preferred: true,
      language: 'en',
      id: 814
    }, {
      vocabulary_item_id: 520,
      text: 'Performance Art',
      preferred: true,
      language: 'en',
      id: 818
    }, {
      vocabulary_item_id: 524,
      text: 'Furniture and Decorative Arts',
      preferred: true,
      language: 'en',
      id: 822
    }, {
      vocabulary_item_id: 528,
      text: 'Autographs',
      preferred: true,
      language: 'en',
      id: 826
    }, {
      vocabulary_item_id: 532,
      text: 'Advertising',
      preferred: true,
      language: 'en',
      id: 830
    }, {
      vocabulary_item_id: 536,
      text: 'Memorabilia',
      preferred: true,
      language: 'en',
      id: 834
    }, {
      vocabulary_item_id: 540,
      text: 'Coins',
      preferred: true,
      language: 'en',
      id: 838
    }, {
      vocabulary_item_id: 544,
      text: 'Clocks',
      preferred: true,
      language: 'en',
      id: 842
    }, {
      vocabulary_item_id: 548,
      text: 'Periodicals',
      preferred: true,
      language: 'en',
      id: 846
    }, {
      vocabulary_item_id: 552,
      text: 'Projections',
      preferred: true,
      language: 'en',
      id: 850
    }, {
      vocabulary_item_id: 556,
      text: 'Clothes, Costumes, and Accessories',
      preferred: true,
      language: 'en',
      id: 854
    }, {
      vocabulary_item_id: 565,
      text: 'YUD',
      preferred: false,
      language: 'en',
      id: 870
    }, {
      vocabulary_item_id: 571,
      text: 'European Currency Unit (E.C.U)',
      preferred: true,
      language: 'en',
      id: 881
    }, {
      vocabulary_item_id: 585,
      text: 'Kroon',
      preferred: false,
      language: 'en',
      id: 910
    }, {
      vocabulary_item_id: 591,
      text: 'BRN',
      preferred: true,
      language: 'en',
      id: 921
    }, {
      vocabulary_item_id: 605,
      text: 'Trinidad and Tobago Dollar',
      preferred: false,
      language: 'en',
      id: 950
    }, {
      vocabulary_item_id: 611,
      text: 'LTT',
      preferred: true,
      language: 'en',
      id: 961
    }, {
      vocabulary_item_id: 625,
      text: 'Qatari Rial',
      preferred: false,
      language: 'en',
      id: 990
    }, {
      vocabulary_item_id: 631,
      text: 'IRR',
      preferred: true,
      language: 'en',
      id: 1001
    }, {
      vocabulary_item_id: 645,
      text: 'Tanzanian Shilling',
      preferred: false,
      language: 'en',
      id: 1030
    }, {
      vocabulary_item_id: 651,
      text: 'Drachma',
      preferred: true,
      language: 'en',
      id: 1041
    }, {
      vocabulary_item_id: 665,
      text: 'Lebanese Pound',
      preferred: false,
      language: 'en',
      id: 1070
    }, {
      vocabulary_item_id: 671,
      text: 'New Dinar',
      preferred: true,
      language: 'en',
      id: 1081
    }, {
      vocabulary_item_id: 685,
      text: 'VEB',
      preferred: false,
      language: 'en',
      id: 1110
    }, {
      vocabulary_item_id: 691,
      text: 'HNL',
      preferred: true,
      language: 'en',
      id: 1121
    }, {
      vocabulary_item_id: 705,
      text: 'Naira',
      preferred: false,
      language: 'en',
      id: 1150
    }, {
      vocabulary_item_id: 711,
      text: 'CUP',
      preferred: true,
      language: 'en',
      id: 1161
    }, {
      vocabulary_item_id: 725,
      text: 'Swedish Krona',
      preferred: false,
      language: 'en',
      id: 1190
    }, {
      vocabulary_item_id: 731,
      text: 'ILS',
      preferred: true,
      language: 'en',
      id: 1201
    }, {
      vocabulary_item_id: 745,
      text: 'XCD',
      preferred: false,
      language: 'en',
      id: 1230
    }, {
      vocabulary_item_id: 751,
      text: 'Pa’anga',
      preferred: true,
      language: 'en',
      id: 1241
    }, {
      vocabulary_item_id: 770,
      text: 'Afghani',
      preferred: false,
      language: 'en',
      id: 1281
    }, {
      vocabulary_item_id: 776,
      text: 'BOV',
      preferred: true,
      language: 'en',
      id: 1292
    }, {
      vocabulary_item_id: 790,
      text: 'Maldive Rupee',
      preferred: false,
      language: 'en',
      id: 1321
    }, {
      vocabulary_item_id: 796,
      text: 'FIM',
      preferred: true,
      language: 'en',
      id: 1332
    }, {
      vocabulary_item_id: 810,
      text: 'Moldovan Leu',
      preferred: false,
      language: 'en',
      id: 1361
    }, {
      vocabulary_item_id: 816,
      text: 'Gourde',
      preferred: true,
      language: 'en',
      id: 1372
    }, {
      vocabulary_item_id: 830,
      text: 'Djibouti Franc',
      preferred: false,
      language: 'en',
      id: 1401
    }, {
      vocabulary_item_id: 836,
      text: 'BRC',
      preferred: true,
      language: 'en',
      id: 1412
    }, {
      vocabulary_item_id: 937,
      text: 'Singapore',
      preferred: true,
      language: 'en',
      id: 1536
    }, {
      vocabulary_item_id: 951,
      text: 'Kittitian or Nevisian',
      preferred: true,
      language: 'en',
      id: 1555
    }, {
      vocabulary_item_id: 955,
      text: 'Rwandan',
      preferred: true,
      language: 'en',
      id: 1559
    }, {
      vocabulary_item_id: 1011,
      text: 'Liechtenstein',
      preferred: true,
      language: 'en',
      id: 1625
    }, {
      vocabulary_item_id: 1015,
      text: 'Lebanese',
      preferred: true,
      language: 'en',
      id: 1629
    }, {
      vocabulary_item_id: 1021,
      text: 'South Korean',
      preferred: true,
      language: 'en',
      id: 1640
    }, {
      vocabulary_item_id: 1025,
      text: 'Kazakh',
      preferred: true,
      language: 'en',
      id: 1644
    }, {
      vocabulary_item_id: 1047,
      text: 'Bissau-Guinean',
      preferred: true,
      language: 'en',
      id: 1671
    }, {
      vocabulary_item_id: 1050,
      text: 'Guambat',
      preferred: false,
      language: 'en',
      id: 1675
    }, {
      vocabulary_item_id: 1054,
      text: 'Greek',
      preferred: true,
      language: 'en',
      id: 1679
    }, {
      vocabulary_item_id: 1088,
      text: 'Comoran',
      preferred: true,
      language: 'en',
      id: 1715
    }, {
      vocabulary_item_id: 1103,
      text: 'Bulgarian',
      preferred: true,
      language: 'en',
      id: 1731
    }, {
      vocabulary_item_id: 1107,
      text: 'Bouvet Island',
      preferred: true,
      language: 'en',
      id: 1735
    }, {
      vocabulary_item_id: 1141,
      text: 'Zakynthian',
      preferred: true,
      language: 'en',
      id: 1774
    }, {
      vocabulary_item_id: 1145,
      text: 'Troezenian',
      preferred: true,
      language: 'en',
      id: 1778
    }, {
      vocabulary_item_id: 1149,
      text: 'Thuriat',
      preferred: true,
      language: 'en',
      id: 1782
    }, {
      vocabulary_item_id: 1153,
      text: 'Thespian',
      preferred: true,
      language: 'en',
      id: 1786
    }, {
      vocabulary_item_id: 1160,
      text: 'Symian',
      preferred: true,
      language: 'en',
      id: 1794
    }, {
      vocabulary_item_id: 1164,
      text: 'Silesian',
      preferred: true,
      language: 'en',
      id: 1798
    }, {
      vocabulary_item_id: 1168,
      text: 'Sere',
      preferred: true,
      language: 'en',
      id: 1802
    }, {
      vocabulary_item_id: 1174,
      text: 'Samian',
      preferred: true,
      language: 'en',
      id: 1813
    }, {
      vocabulary_item_id: 1178,
      text: 'Roman',
      preferred: true,
      language: 'en',
      id: 1817
    }, {
      vocabulary_item_id: 1221,
      text: 'Lesbian',
      preferred: true,
      language: 'en',
      id: 1861
    }, {
      vocabulary_item_id: 1225,
      text: 'Lakedaimonian ',
      preferred: true,
      language: 'en',
      id: 1865
    }, {
      vocabulary_item_id: 1266,
      text: 'Carian',
      preferred: true,
      language: 'en',
      id: 1912
    }, {
      vocabulary_item_id: 1270,
      text: 'Byzantine',
      preferred: true,
      language: 'en',
      id: 1916
    }, {
      vocabulary_item_id: 1304,
      text: "Hawke's Bay",
      preferred: true,
      language: 'en',
      id: 1955
    }, {
      vocabulary_item_id: 1369,
      text: 'Goenkar',
      preferred: false,
      language: 'en',
      id: 2036
    }, {
      vocabulary_item_id: 1404,
      text: 'B.C.',
      preferred: false,
      language: 'en',
      id: 2079
    }, {
      vocabulary_item_id: 1408,
      text: 'Tasmanian',
      preferred: true,
      language: 'en',
      id: 2083
    }, {
      vocabulary_item_id: 1412,
      text: 'New South Welsh',
      preferred: true,
      language: 'en',
      id: 2087
    }, {
      vocabulary_item_id: 373,
      text: 'English',
      preferred: false,
      language: 'en',
      id: 654
    }, {
      vocabulary_item_id: 424,
      text: 'pennyweight ',
      preferred: false,
      language: 'en',
      id: 706
    }, {
      vocabulary_item_id: 440,
      text: 'mg',
      preferred: true,
      language: 'en',
      id: 732
    }, {
      vocabulary_item_id: 566,
      text: 'LAJ',
      preferred: false,
      language: 'en',
      id: 872
    }, {
      vocabulary_item_id: 572,
      text: 'Old Leu',
      preferred: true,
      language: 'en',
      id: 883
    }, {
      vocabulary_item_id: 586,
      text: 'Turkmenistan Manat',
      preferred: false,
      language: 'en',
      id: 912
    }, {
      vocabulary_item_id: 592,
      text: 'PLZ',
      preferred: true,
      language: 'en',
      id: 923
    }, {
      vocabulary_item_id: 606,
      text: 'Portuguese Escudo',
      preferred: false,
      language: 'en',
      id: 952
    }, {
      vocabulary_item_id: 612,
      text: 'EQE',
      preferred: true,
      language: 'en',
      id: 963
    }, {
      vocabulary_item_id: 626,
      text: 'MKD',
      preferred: false,
      language: 'en',
      id: 992
    }, {
      vocabulary_item_id: 632,
      text: 'GWP',
      preferred: true,
      language: 'en',
      id: 1003
    }, {
      vocabulary_item_id: 646,
      text: 'ZWC',
      preferred: false,
      language: 'en',
      id: 1032
    }, {
      vocabulary_item_id: 652,
      text: 'ESP',
      preferred: true,
      language: 'en',
      id: 1043
    }, {
      vocabulary_item_id: 666,
      text: 'Tunisian Dinar',
      preferred: false,
      language: 'en',
      id: 1072
    }, {
      vocabulary_item_id: 672,
      text: 'LKR',
      preferred: true,
      language: 'en',
      id: 1083
    }, {
      vocabulary_item_id: 686,
      text: 'UAK',
      preferred: false,
      language: 'en',
      id: 1112
    }, {
      vocabulary_item_id: 692,
      text: 'BGN',
      preferred: true,
      language: 'en',
      id: 1123
    }, {
      vocabulary_item_id: 706,
      text: 'Mauritius Rupee',
      preferred: false,
      language: 'en',
      id: 1152
    }, {
      vocabulary_item_id: 712,
      text: 'CLF',
      preferred: true,
      language: 'en',
      id: 1163
    }, {
      vocabulary_item_id: 726,
      text: 'Ouguiya',
      preferred: false,
      language: 'en',
      id: 1192
    }, {
      vocabulary_item_id: 732,
      text: 'GTQ',
      preferred: true,
      language: 'en',
      id: 1203
    }, {
      vocabulary_item_id: 746,
      text: 'WST',
      preferred: false,
      language: 'en',
      id: 1232
    }, {
      vocabulary_item_id: 752,
      text: 'SHP',
      preferred: true,
      language: 'en',
      id: 1243
    }, {
      vocabulary_item_id: 771,
      text: 'Iraqi Dinar',
      preferred: false,
      language: 'en',
      id: 1283
    }, {
      vocabulary_item_id: 777,
      text: 'RINET Funds Code',
      preferred: true,
      language: 'en',
      id: 1294
    }, {
      vocabulary_item_id: 791,
      text: 'Maltese Lira',
      preferred: false,
      language: 'en',
      id: 1323
    }, {
      vocabulary_item_id: 797,
      text: 'DEM',
      preferred: true,
      language: 'en',
      id: 1334
    }, {
      vocabulary_item_id: 811,
      text: 'Libyan Dinar',
      preferred: false,
      language: 'en',
      id: 1363
    }, {
      vocabulary_item_id: 817,
      text: 'DKK',
      preferred: true,
      language: 'en',
      id: 1374
    }, {
      vocabulary_item_id: 831,
      text: 'Unidad de Valor Real',
      preferred: false,
      language: 'en',
      id: 1403
    }, {
      vocabulary_item_id: 837,
      text: 'AFA',
      preferred: true,
      language: 'en',
      id: 1414
    }, {
      vocabulary_item_id: 853,
      text: 'surface area',
      preferred: true,
      language: 'en',
      id: 1438
    }, {
      vocabulary_item_id: 857,
      text: 'depth',
      preferred: true,
      language: 'en',
      id: 1442
    }, {
      vocabulary_item_id: 861,
      text: 'height',
      preferred: true,
      language: 'en',
      id: 1446
    }, {
      vocabulary_item_id: 884,
      text: 'Zimbabwean',
      preferred: true,
      language: 'en',
      id: 1469
    }, {
      vocabulary_item_id: 904,
      text: 'Ugandan',
      preferred: true,
      language: 'en',
      id: 1499
    }, {
      vocabulary_item_id: 908,
      text: 'Turkish',
      preferred: true,
      language: 'en',
      id: 1503
    }, {
      vocabulary_item_id: 938,
      text: 'Sierra Leonean',
      preferred: true,
      language: 'en',
      id: 1538
    }, {
      vocabulary_item_id: 942,
      text: 'Scottish',
      preferred: true,
      language: 'en',
      id: 1542
    }, {
      vocabulary_item_id: 960,
      text: 'Puerto Rican',
      preferred: true,
      language: 'en',
      id: 1565
    }, {
      vocabulary_item_id: 964,
      text: 'Philippine',
      preferred: true,
      language: 'en',
      id: 1569
    }, {
      vocabulary_item_id: 982,
      text: 'New Caledonian',
      preferred: false,
      language: 'en',
      id: 1592
    }, {
      vocabulary_item_id: 1026,
      text: 'Jordanian',
      preferred: true,
      language: 'en',
      id: 1646
    }, {
      vocabulary_item_id: 1030,
      text: 'Jamaican',
      preferred: true,
      language: 'en',
      id: 1650
    }, {
      vocabulary_item_id: 1034,
      text: 'Manx',
      preferred: true,
      language: 'en',
      id: 1654
    }, {
      vocabulary_item_id: 1055,
      text: 'Gibraltar',
      preferred: true,
      language: 'en',
      id: 1681
    }, {
      vocabulary_item_id: 1059,
      text: 'Gambian',
      preferred: true,
      language: 'en',
      id: 1685
    }, {
      vocabulary_item_id: 1063,
      text: 'French Guianese',
      preferred: true,
      language: 'en',
      id: 1689
    }, {
      vocabulary_item_id: 1067,
      text: 'Faroese',
      preferred: true,
      language: 'en',
      id: 1693
    }, {
      vocabulary_item_id: 1071,
      text: 'Estonian',
      preferred: true,
      language: 'en',
      id: 1697
    }, {
      vocabulary_item_id: 1078,
      text: 'Djiboutian',
      preferred: true,
      language: 'en',
      id: 1705
    }, {
      vocabulary_item_id: 1082,
      text: 'Curaçaoan',
      preferred: true,
      language: 'en',
      id: 1709
    }, {
      vocabulary_item_id: 1089,
      text: 'Colombian',
      preferred: true,
      language: 'en',
      id: 1717
    }, {
      vocabulary_item_id: 1093,
      text: 'Chilean',
      preferred: true,
      language: 'en',
      id: 1721
    }, {
      vocabulary_item_id: 1097,
      text: 'Canadian',
      preferred: true,
      language: 'en',
      id: 1725
    }, {
      vocabulary_item_id: 1101,
      text: 'Burundian',
      preferred: true,
      language: 'en',
      id: 1729
    }, {
      vocabulary_item_id: 1108,
      text: 'Botswanan',
      preferred: false,
      language: 'en',
      id: 1737
    }, {
      vocabulary_item_id: 1112,
      text: 'Bolivian',
      preferred: true,
      language: 'en',
      id: 1741
    }, {
      vocabulary_item_id: 1183,
      text: 'Pontian',
      preferred: true,
      language: 'en',
      id: 1823
    }, {
      vocabulary_item_id: 1187,
      text: 'Phocian',
      preferred: true,
      language: 'en',
      id: 1827
    }, {
      vocabulary_item_id: 1191,
      text: 'Pergamian',
      preferred: true,
      language: 'en',
      id: 1831
    }, {
      vocabulary_item_id: 1195,
      text: 'Pamphylian',
      preferred: true,
      language: 'en',
      id: 1835
    }, {
      vocabulary_item_id: 1199,
      text: 'Olympian',
      preferred: true,
      language: 'en',
      id: 1839
    }, {
      vocabulary_item_id: 1203,
      text: 'Naupactian',
      preferred: true,
      language: 'en',
      id: 1843
    }, {
      vocabulary_item_id: 1207,
      text: 'Methymnian',
      preferred: true,
      language: 'en',
      id: 1847
    }, {
      vocabulary_item_id: 1211,
      text: 'Melian',
      preferred: true,
      language: 'en',
      id: 1851
    }, {
      vocabulary_item_id: 1215,
      text: 'Marathonian',
      preferred: true,
      language: 'en',
      id: 1855
    }, {
      vocabulary_item_id: 1226,
      text: 'Knossian',
      preferred: true,
      language: 'en',
      id: 1867
    }, {
      vocabulary_item_id: 1230,
      text: 'Illyrian',
      preferred: true,
      language: 'en',
      id: 1871
    }, {
      vocabulary_item_id: 1305,
      text: 'Gisborne',
      preferred: false,
      language: 'en',
      id: 1957
    }, {
      vocabulary_item_id: 1309,
      text: 'Zacatecan',
      preferred: true,
      language: 'en',
      id: 1961
    }, {
      vocabulary_item_id: 1313,
      text: 'Queretan',
      preferred: true,
      language: 'en',
      id: 1965
    }, {
      vocabulary_item_id: 1320,
      text: 'Coahuilan',
      preferred: true,
      language: 'en',
      id: 1973
    }, {
      vocabulary_item_id: 1324,
      text: 'South Lower Californian',
      preferred: true,
      language: 'en',
      id: 1977
    }, {
      vocabulary_item_id: 1370,
      text: 'Delhian',
      preferred: true,
      language: 'en',
      id: 2038
    }, {
      vocabulary_item_id: 825,
      text: 'KGS',
      preferred: true,
      language: 'en',
      id: 1390
    }, {
      vocabulary_item_id: 839,
      text: 'Saudi Riyal',
      preferred: false,
      language: 'en',
      id: 1419
    }, {
      vocabulary_item_id: 864,
      text: 'Not Published',
      preferred: true,
      language: 'en',
      id: 1449
    }, {
      vocabulary_item_id: 868,
      text: 'Amended',
      preferred: true,
      language: 'en',
      id: 1453
    }, {
      vocabulary_item_id: 898,
      text: 'Uzbek',
      preferred: true,
      language: 'en',
      id: 1486
    }, {
      vocabulary_item_id: 934,
      text: 'Slovak',
      preferred: true,
      language: 'en',
      id: 1532
    }, {
      vocabulary_item_id: 948,
      text: 'Saint-Pierrais ',
      preferred: true,
      language: 'en',
      id: 1551
    }, {
      vocabulary_item_id: 982,
      text: 'Dutch',
      preferred: true,
      language: 'en',
      id: 1590
    }, {
      vocabulary_item_id: 1000,
      text: 'Marshallese',
      preferred: true,
      language: 'en',
      id: 1613
    }, {
      vocabulary_item_id: 1004,
      text: 'Malaysian',
      preferred: true,
      language: 'en',
      id: 1617
    }, {
      vocabulary_item_id: 1008,
      text: 'Macanese',
      preferred: true,
      language: 'en',
      id: 1621
    }, {
      vocabulary_item_id: 1018,
      text: 'Kirghiz',
      preferred: false,
      language: 'en',
      id: 1636
    }, {
      vocabulary_item_id: 1044,
      text: 'Heard Island ',
      preferred: true,
      language: 'en',
      id: 1667
    }, {
      vocabulary_item_id: 1126,
      text: 'Aruban',
      preferred: true,
      language: 'en',
      id: 1758
    }, {
      vocabulary_item_id: 1130,
      text: 'Antiguan',
      preferred: true,
      language: 'en',
      id: 1762
    }, {
      vocabulary_item_id: 1134,
      text: 'Andorran',
      preferred: true,
      language: 'en',
      id: 1766
    }, {
      vocabulary_item_id: 1138,
      text: 'Åland Island',
      preferred: true,
      language: 'en',
      id: 1770
    }, {
      vocabulary_item_id: 1157,
      text: 'Tegean',
      preferred: true,
      language: 'en',
      id: 1790
    }, {
      vocabulary_item_id: 1297,
      text: 'Taranaki',
      preferred: true,
      language: 'en',
      id: 1947
    }, {
      vocabulary_item_id: 1301,
      text: 'Nelsonian',
      preferred: true,
      language: 'en',
      id: 1951
    }, {
      vocabulary_item_id: 1352,
      text: 'Punjabi',
      preferred: true,
      language: 'en',
      id: 2013
    }, {
      vocabulary_item_id: 1366,
      text: 'Himachali',
      preferred: true,
      language: 'en',
      id: 2032
    }, {
      vocabulary_item_id: 1372,
      text: 'Nagar Havelian',
      preferred: false,
      language: 'en',
      id: 2043
    }, {
      vocabulary_item_id: 1401,
      text: 'Newfoundland and Labrador',
      preferred: true,
      language: 'en',
      id: 2075
    }, {
      vocabulary_item_id: 2294,
      text: 'Dental Surgery',
      preferred: true,
      language: 'en',
      id: 2982
    }, {
      vocabulary_item_id: 2298,
      text: 'Theological Studies',
      preferred: true,
      language: 'en',
      id: 2986
    }, {
      vocabulary_item_id: 2302,
      text: 'Social Science',
      preferred: true,
      language: 'en',
      id: 2990
    }, {
      vocabulary_item_id: 2306,
      text: 'Science in Supply Chain Management',
      preferred: true,
      language: 'en',
      id: 2994
    }, {
      vocabulary_item_id: 2310,
      text: 'Science in Nursing ',
      preferred: true,
      language: 'en',
      id: 2998
    }, {
      vocabulary_item_id: 2314,
      text: 'Science in Human Resource Development',
      preferred: true,
      language: 'en',
      id: 3002
    }, {
      vocabulary_item_id: 2318,
      text: 'Science',
      preferred: true,
      language: 'en',
      id: 3006
    }, {
      vocabulary_item_id: 2322,
      text: 'Religious Education',
      preferred: true,
      language: 'en',
      id: 3010
    }, {
      vocabulary_item_id: 2326,
      text: 'Public Policy',
      preferred: true,
      language: 'en',
      id: 3014
    }, {
      vocabulary_item_id: 2330,
      text: 'Public Administration ',
      preferred: true,
      language: 'en',
      id: 3018
    }, {
      vocabulary_item_id: 2334,
      text: 'Physics',
      preferred: true,
      language: 'en',
      id: 3022
    }, {
      vocabulary_item_id: 2448,
      text: 'Master',
      preferred: true,
      language: 'en',
      id: 3136
    }, {
      vocabulary_item_id: 2475,
      text: 'region',
      preferred: true,
      language: 'en',
      id: 3163
    }, {
      vocabulary_item_id: 597,
      text: 'USD',
      preferred: true,
      language: 'en',
      id: 934
    }, {
      vocabulary_item_id: 833,
      text: 'WIR Euro',
      preferred: false,
      language: 'en',
      id: 1407
    }, {
      vocabulary_item_id: 839,
      text: 'SAR',
      preferred: true,
      language: 'en',
      id: 1418
    }, {
      vocabulary_item_id: 883,
      text: 'Metric',
      preferred: true,
      language: 'en',
      id: 1468
    }, {
      vocabulary_item_id: 890,
      text: 'Wallis and Futuna',
      preferred: true,
      language: 'en',
      id: 1477
    }, {
      vocabulary_item_id: 894,
      text: 'Vietnamese',
      preferred: true,
      language: 'en',
      id: 1481
    }, {
      vocabulary_item_id: 930,
      text: 'South African',
      preferred: true,
      language: 'en',
      id: 1527
    }, {
      vocabulary_item_id: 944,
      text: 'São Toméan',
      preferred: true,
      language: 'en',
      id: 1546
    }, {
      vocabulary_item_id: 970,
      text: 'Palauan',
      preferred: true,
      language: 'en',
      id: 1577
    }, {
      vocabulary_item_id: 974,
      text: 'Northern Marianan',
      preferred: true,
      language: 'en',
      id: 1581
    }, {
      vocabulary_item_id: 978,
      text: 'Nigerian',
      preferred: true,
      language: 'en',
      id: 1585
    }, {
      vocabulary_item_id: 992,
      text: 'Monacan',
      preferred: false,
      language: 'en',
      id: 1604
    }, {
      vocabulary_item_id: 996,
      text: 'Mahoran',
      preferred: true,
      language: 'en',
      id: 1608
    }, {
      vocabulary_item_id: 1018,
      text: 'Kirgiz',
      preferred: false,
      language: 'en',
      id: 1635
    }, {
      vocabulary_item_id: 1118,
      text: 'Belarusian',
      preferred: true,
      language: 'en',
      id: 1749
    }, {
      vocabulary_item_id: 1122,
      text: 'Bahamian',
      preferred: true,
      language: 'en',
      id: 1753
    }, {
      vocabulary_item_id: 1171,
      text: 'Sauromata',
      preferred: false,
      language: 'en',
      id: 1808
    }, {
      vocabulary_item_id: 1244,
      text: 'Eleusinian',
      preferred: false,
      language: 'en',
      id: 1887
    }, {
      vocabulary_item_id: 1248,
      text: 'Delian',
      preferred: true,
      language: 'en',
      id: 1891
    }, {
      vocabulary_item_id: 1252,
      text: 'Cyrenaic',
      preferred: true,
      language: 'en',
      id: 1895
    }, {
      vocabulary_item_id: 1255,
      text: 'Cretan',
      preferred: true,
      language: 'en',
      id: 1899
    }, {
      vocabulary_item_id: 1259,
      text: 'Colossian',
      preferred: true,
      language: 'en',
      id: 1903
    }, {
      vocabulary_item_id: 1263,
      text: 'Cephalonian',
      preferred: true,
      language: 'en',
      id: 1907
    }, {
      vocabulary_item_id: 1293,
      text: 'West Coast',
      preferred: true,
      language: 'en',
      id: 1942
    }, {
      vocabulary_item_id: 1348,
      text: 'Telanganite',
      preferred: true,
      language: 'en',
      id: 2008
    }, {
      vocabulary_item_id: 1372,
      text: 'Dadran',
      preferred: true,
      language: 'en',
      id: 2042
    }, {
      vocabulary_item_id: 1375,
      text: 'Assamese',
      preferred: true,
      language: 'en',
      id: 2046
    }, {
      vocabulary_item_id: 1379,
      text: 'Andamanese ',
      preferred: true,
      language: 'en',
      id: 2050
    }, {
      vocabulary_item_id: 1386,
      text: 'North Rhine',
      preferred: false,
      language: 'en',
      id: 2058
    }, {
      vocabulary_item_id: 1390,
      text: 'Hamburgish',
      preferred: true,
      language: 'en',
      id: 2062
    }, {
      vocabulary_item_id: 1397,
      text: 'Ontario',
      preferred: true,
      language: 'en',
      id: 2070
    }, {
      vocabulary_item_id: 1419,
      text: 'Australasian',
      preferred: true,
      language: 'en',
      id: 2094
    }, {
      vocabulary_item_id: 1442,
      text: 'name',
      preferred: true,
      language: 'en',
      id: 2117
    }, {
      vocabulary_item_id: 1633,
      text: 'newspaper article',
      preferred: true,
      language: 'en',
      id: 2308
    }, {
      vocabulary_item_id: 1637,
      text: 'exhibition review',
      preferred: true,
      language: 'en',
      id: 2312
    }, {
      vocabulary_item_id: 1641,
      text: 'inventory',
      preferred: true,
      language: 'en',
      id: 2316
    }, {
      vocabulary_item_id: 1645,
      text: 'encyclopedia',
      preferred: true,
      language: 'en',
      id: 2320
    }, {
      vocabulary_item_id: 2286,
      text: 'Range',
      preferred: true,
      language: 'en',
      id: 2974
    }, {
      vocabulary_item_id: 2337,
      text: 'Music ',
      preferred: true,
      language: 'en',
      id: 3025
    }, {
      vocabulary_item_id: 2341,
      text: 'Management ',
      preferred: true,
      language: 'en',
      id: 3029
    }, {
      vocabulary_item_id: 2345,
      text: 'Landscape Architecture ',
      preferred: true,
      language: 'en',
      id: 3033
    }, {
      vocabulary_item_id: 2349,
      text: 'IT ',
      preferred: true,
      language: 'en',
      id: 3037
    }, {
      vocabulary_item_id: 2353,
      text: 'International Business',
      preferred: true,
      language: 'en',
      id: 3041
    }, {
      vocabulary_item_id: 2357,
      text: 'Health Science ',
      preferred: true,
      language: 'en',
      id: 3045
    }, {
      vocabulary_item_id: 2361,
      text: 'Financial Engineering ',
      preferred: true,
      language: 'en',
      id: 3049
    }, {
      vocabulary_item_id: 2365,
      text: 'Engineering Management',
      preferred: true,
      language: 'en',
      id: 3053
    }, {
      vocabulary_item_id: 2369,
      text: 'Economics ',
      preferred: true,
      language: 'en',
      id: 3057
    }, {
      vocabulary_item_id: 2373,
      text: 'Creative Technologies',
      preferred: true,
      language: 'en',
      id: 3061
    }, {
      vocabulary_item_id: 2377,
      text: 'Chemistry ',
      preferred: true,
      language: 'en',
      id: 3065
    }, {
      vocabulary_item_id: 2381,
      text: 'Business ',
      preferred: true,
      language: 'en',
      id: 3069
    }, {
      vocabulary_item_id: 2385,
      text: 'Applied Science ',
      preferred: true,
      language: 'en',
      id: 3073
    }, {
      vocabulary_item_id: 1303,
      text: 'Wanganui',
      preferred: false,
      language: 'en',
      id: 1954
    }, {
      vocabulary_item_id: 1344,
      text: 'West Bengali ',
      preferred: false,
      language: 'en',
      id: 2001
    }, {
      vocabulary_item_id: 1354,
      text: 'Odia',
      preferred: false,
      language: 'en',
      id: 2016
    }, {
      vocabulary_item_id: 1407,
      text: 'Victorian',
      preferred: true,
      language: 'en',
      id: 2082
    }, {
      vocabulary_item_id: 1411,
      text: 'Northern Territory',
      preferred: true,
      language: 'en',
      id: 2086
    }, {
      vocabulary_item_id: 1415,
      text: 'Oceanian',
      preferred: true,
      language: 'en',
      id: 2090
    }, {
      vocabulary_item_id: 1425,
      text: 'style of',
      preferred: true,
      language: 'en',
      id: 2100
    }, {
      vocabulary_item_id: 1429,
      text: 'follower of',
      preferred: true,
      language: 'en',
      id: 2104
    }, {
      vocabulary_item_id: 1433,
      text: 'atelier of',
      preferred: true,
      language: 'en',
      id: 2108
    }, {
      vocabulary_item_id: 1437,
      text: 'attributed to',
      preferred: true,
      language: 'en',
      id: 2112
    }, {
      vocabulary_item_id: 2277,
      text: 'Assay hallmark',
      preferred: true,
      language: 'en',
      id: 2965
    }, {
      vocabulary_item_id: 2281,
      text: 'Title',
      preferred: true,
      language: 'en',
      id: 2969
    }, {
      vocabulary_item_id: 597,
      text: 'US Dollar',
      preferred: false,
      language: 'en',
      id: 933
    }, {
      vocabulary_item_id: 1410,
      text: 'Queensland',
      preferred: true,
      language: 'en',
      id: 2085
    }, {
      vocabulary_item_id: 1414,
      text: 'South American',
      preferred: true,
      language: 'en',
      id: 2089
    }, {
      vocabulary_item_id: 1428,
      text: 'school of',
      preferred: true,
      language: 'en',
      id: 2103
    }, {
      vocabulary_item_id: 1432,
      text: 'office of',
      preferred: true,
      language: 'en',
      id: 2107
    }, {
      vocabulary_item_id: 1436,
      text: 'assistant to',
      preferred: true,
      language: 'en',
      id: 2111
    }, {
      vocabulary_item_id: 2276,
      text: 'European mark',
      preferred: true,
      language: 'en',
      id: 2964
    }, {
      vocabulary_item_id: 2280,
      text: 'Hallmark',
      preferred: true,
      language: 'en',
      id: 2968
    }, {
      vocabulary_item_id: 2480,
      text: 'online',
      preferred: true,
      language: 'en',
      id: 3168
    }, {
      vocabulary_item_id: 570,
      text: 'UIC-Franc',
      preferred: false,
      language: 'en',
      id: 879
    }, {
      vocabulary_item_id: 1416,
      text: 'Central American',
      preferred: true,
      language: 'en',
      id: 2091
    }, {
      vocabulary_item_id: 1426,
      text: 'copyist of',
      preferred: true,
      language: 'en',
      id: 2101
    }, {
      vocabulary_item_id: 1430,
      text: 'associate of',
      preferred: true,
      language: 'en',
      id: 2105
    }, {
      vocabulary_item_id: 1434,
      text: 'studio of',
      preferred: true,
      language: 'en',
      id: 2109
    }, {
      vocabulary_item_id: 1438,
      text: 'after',
      preferred: true,
      language: 'en',
      id: 2113
    }, {
      vocabulary_item_id: 2274,
      text: 'Countersign',
      preferred: true,
      language: 'en',
      id: 2962
    }, {
      vocabulary_item_id: 2278,
      text: 'Mark',
      preferred: true,
      language: 'en',
      id: 2966
    }, {
      vocabulary_item_id: 2282,
      text: 'Signature',
      preferred: true,
      language: 'en',
      id: 2970
    }, {
      vocabulary_item_id: 2478,
      text: 'online + live',
      preferred: true,
      language: 'en',
      id: 3166
    }, {
      vocabulary_item_id: 570,
      text: 'XFU',
      preferred: true,
      language: 'en',
      id: 880
    }, {
      vocabulary_item_id: 1417,
      text: 'North American',
      preferred: true,
      language: 'en',
      id: 2092
    }, {
      vocabulary_item_id: 1427,
      text: 'circle of',
      preferred: true,
      language: 'en',
      id: 2102
    }, {
      vocabulary_item_id: 1431,
      text: 'pupil of',
      preferred: true,
      language: 'en',
      id: 2106
    }, {
      vocabulary_item_id: 1435,
      text: 'workshop of',
      preferred: true,
      language: 'en',
      id: 2110
    }, {
      vocabulary_item_id: 2275,
      text: 'Signet',
      preferred: true,
      language: 'en',
      id: 2963
    }, {
      vocabulary_item_id: 2279,
      text: 'Monogram',
      preferred: true,
      language: 'en',
      id: 2967
    }, {
      vocabulary_item_id: 2283,
      text: 'Date',
      preferred: true,
      language: 'en',
      id: 2971
    }, {
      vocabulary_item_id: 2479,
      text: 'live',
      preferred: true,
      language: 'en',
      id: 3167
    }, {
      vocabulary_item_id: 643,
      text: 'XDR',
      preferred: true,
      language: 'en',
      id: 1026
    }, {
      vocabulary_item_id: 2285,
      text: 'Single',
      preferred: true,
      language: 'en',
      id: 2973
    }, {
      vocabulary_item_id: 2336,
      text: 'Occupational Therapy',
      preferred: true,
      language: 'en',
      id: 3024
    }, {
      vocabulary_item_id: 2340,
      text: 'Mathematics ',
      preferred: true,
      language: 'en',
      id: 3028
    }, {
      vocabulary_item_id: 2344,
      text: 'Letters ',
      preferred: true,
      language: 'en',
      id: 3032
    }, {
      vocabulary_item_id: 2348,
      text: 'Jurisprudence ',
      preferred: true,
      language: 'en',
      id: 3036
    }, {
      vocabulary_item_id: 2352,
      text: 'International Studies',
      preferred: true,
      language: 'en',
      id: 3040
    }, {
      vocabulary_item_id: 2356,
      text: 'Humanities ',
      preferred: true,
      language: 'en',
      id: 3044
    }, {
      vocabulary_item_id: 2360,
      text: 'Financial Economics',
      preferred: true,
      language: 'en',
      id: 3048
    }, {
      vocabulary_item_id: 2364,
      text: 'European Law ',
      preferred: true,
      language: 'en',
      id: 3052
    }, {
      vocabulary_item_id: 2368,
      text: 'Education ',
      preferred: true,
      language: 'en',
      id: 3056
    }, {
      vocabulary_item_id: 2372,
      text: 'Criminal Justice',
      preferred: true,
      language: 'en',
      id: 3060
    }, {
      vocabulary_item_id: 2376,
      text: 'Commerce ',
      preferred: true,
      language: 'en',
      id: 3064
    }, {
      vocabulary_item_id: 2380,
      text: 'Business Administration',
      preferred: true,
      language: 'en',
      id: 3068
    }, {
      vocabulary_item_id: 2384,
      text: 'Arts',
      preferred: true,
      language: 'en',
      id: 3072
    }, {
      vocabulary_item_id: 2289,
      text: 'Sale price',
      preferred: true,
      language: 'en',
      id: 2977
    }, {
      vocabulary_item_id: 2431,
      text: 'Applied Arts and Science',
      preferred: true,
      language: 'en',
      id: 3119
    }, {
      vocabulary_item_id: 2435,
      text: 'Occupational Studies',
      preferred: true,
      language: 'en',
      id: 3123
    }, {
      vocabulary_item_id: 2439,
      text: 'Engineering Science',
      preferred: true,
      language: 'en',
      id: 3127
    }, {
      vocabulary_item_id: 2443,
      text: 'Forestry',
      preferred: true,
      language: 'en',
      id: 3131
    }, {
      vocabulary_item_id: 2290,
      text: 'Premium',
      preferred: true,
      language: 'en',
      id: 2978
    }, {
      vocabulary_item_id: 2428,
      text: 'Engineering Technology',
      preferred: true,
      language: 'en',
      id: 3116
    }, {
      vocabulary_item_id: 2432,
      text: 'Applied Arts',
      preferred: true,
      language: 'en',
      id: 3120
    }, {
      vocabulary_item_id: 2436,
      text: 'Industrial Technology',
      preferred: true,
      language: 'en',
      id: 3124
    }, {
      vocabulary_item_id: 2440,
      text: 'Baccalaureate Studies',
      preferred: true,
      language: 'en',
      id: 3128
    }, {
      vocabulary_item_id: 2444,
      text: 'Electronics Engineering Technology',
      preferred: true,
      language: 'en',
      id: 3132
    }, {
      vocabulary_item_id: 2284,
      text: 'No estimate',
      preferred: true,
      language: 'en',
      id: 2972
    }, {
      vocabulary_item_id: 2339,
      text: 'Mathematical Finance',
      preferred: true,
      language: 'en',
      id: 3027
    }, {
      vocabulary_item_id: 2343,
      text: 'Liberal Arts ',
      preferred: true,
      language: 'en',
      id: 3031
    }, {
      vocabulary_item_id: 2347,
      text: 'Laws',
      preferred: true,
      language: 'en',
      id: 3035
    }, {
      vocabulary_item_id: 2351,
      text: 'International Economics',
      preferred: true,
      language: 'en',
      id: 3039
    }, {
      vocabulary_item_id: 2355,
      text: 'Industrial and Labor Relations',
      preferred: true,
      language: 'en',
      id: 3043
    }, {
      vocabulary_item_id: 2359,
      text: 'Fine Arts',
      preferred: true,
      language: 'en',
      id: 3047
    }, {
      vocabulary_item_id: 2363,
      text: 'Finance ',
      preferred: true,
      language: 'en',
      id: 3051
    }, {
      vocabulary_item_id: 2367,
      text: 'Enterprise ',
      preferred: true,
      language: 'en',
      id: 3055
    }, {
      vocabulary_item_id: 2371,
      text: 'Design',
      preferred: true,
      language: 'en',
      id: 3059
    }, {
      vocabulary_item_id: 2375,
      text: 'Computational Finance',
      preferred: true,
      language: 'en',
      id: 3063
    }, {
      vocabulary_item_id: 2379,
      text: 'Business Informatics ',
      preferred: true,
      language: 'en',
      id: 3067
    }, {
      vocabulary_item_id: 2383,
      text: 'Arts in Teaching ',
      preferred: true,
      language: 'en',
      id: 3071
    }, {
      vocabulary_item_id: 2287,
      text: 'Estimate on request',
      preferred: true,
      language: 'en',
      id: 2975
    }, {
      vocabulary_item_id: 2338,
      text: 'Medical Science',
      preferred: true,
      language: 'en',
      id: 3026
    }, {
      vocabulary_item_id: 2342,
      text: 'Library and Information Science',
      preferred: true,
      language: 'en',
      id: 3030
    }, {
      vocabulary_item_id: 2346,
      text: 'Studies in Law ',
      preferred: true,
      language: 'en',
      id: 3034
    }, {
      vocabulary_item_id: 2350,
      text: 'Information System Management ',
      preferred: true,
      language: 'en',
      id: 3038
    }, {
      vocabulary_item_id: 2354,
      text: 'International Affairs',
      preferred: true,
      language: 'en',
      id: 3042
    }, {
      vocabulary_item_id: 2358,
      text: 'Health Administration ',
      preferred: true,
      language: 'en',
      id: 3046
    }, {
      vocabulary_item_id: 2362,
      text: 'Financial Mathematics',
      preferred: true,
      language: 'en',
      id: 3050
    }, {
      vocabulary_item_id: 2366,
      text: 'Engineering',
      preferred: true,
      language: 'en',
      id: 3054
    }, {
      vocabulary_item_id: 2370,
      text: 'Divinity',
      preferred: true,
      language: 'en',
      id: 3058
    }, {
      vocabulary_item_id: 2374,
      text: 'Computer Applications',
      preferred: true,
      language: 'en',
      id: 3062
    }, {
      vocabulary_item_id: 2378,
      text: 'City Planning',
      preferred: true,
      language: 'en',
      id: 3066
    }, {
      vocabulary_item_id: 2382,
      text: 'Arts in Liberal Studies ',
      preferred: true,
      language: 'en',
      id: 3070
    }, {
      vocabulary_item_id: 2386,
      text: 'Architecture',
      preferred: true,
      language: 'en',
      id: 3074
    }, {
      vocabulary_item_id: 2291,
      text: 'Hammer',
      preferred: true,
      language: 'en',
      id: 2979
    }, {
      vocabulary_item_id: 2429,
      text: 'Science in Business',
      preferred: true,
      language: 'en',
      id: 3117
    }, {
      vocabulary_item_id: 2433,
      text: 'Political Science or Associate of Public Service',
      preferred: true,
      language: 'en',
      id: 3121
    }, {
      vocabulary_item_id: 2437,
      text: 'Pre-Engineering',
      preferred: true,
      language: 'en',
      id: 3125
    }, {
      vocabulary_item_id: 2441,
      text: 'Arts and Sciences',
      preferred: true,
      language: 'en',
      id: 3129
    }, {
      vocabulary_item_id: 2288,
      text: 'Fixed price',
      preferred: true,
      language: 'en',
      id: 2976
    }, {
      vocabulary_item_id: 2430,
      text: 'Applied Science in Information Technology',
      preferred: true,
      language: 'en',
      id: 3118
    }, {
      vocabulary_item_id: 2434,
      text: 'Physical Therapy',
      preferred: true,
      language: 'en',
      id: 3122
    }, {
      vocabulary_item_id: 2438,
      text: 'Degree in Nursing',
      preferred: true,
      language: 'en',
      id: 3126
    }, {
      vocabulary_item_id: 2442,
      text: 'Applied Business',
      preferred: true,
      language: 'en',
      id: 3130
    }, {
      vocabulary_item_id: 2267,
      text: 'personal website',
      preferred: true,
      language: 'en',
      id: 2955
    }, {
      vocabulary_item_id: 2271,
      text: 'office',
      preferred: true,
      language: 'en',
      id: 2959
    }, {
      vocabulary_item_id: 2389,
      text: 'Urban and Regional Planning',
      preferred: true,
      language: 'en',
      id: 3077
    }, {
      vocabulary_item_id: 2393,
      text: 'Technology ',
      preferred: true,
      language: 'en',
      id: 3081
    }, {
      vocabulary_item_id: 2397,
      text: 'Science in Forestry ',
      preferred: true,
      language: 'en',
      id: 3085
    }, {
      vocabulary_item_id: 2401,
      text: 'Arts in Psychology',
      preferred: true,
      language: 'en',
      id: 3089
    }, {
      vocabulary_item_id: 2405,
      text: 'Liberal Studies',
      preferred: true,
      language: 'en',
      id: 3093
    }, {
      vocabulary_item_id: 2409,
      text: 'Journalism',
      preferred: true,
      language: 'en',
      id: 3097
    }, {
      vocabulary_item_id: 2413,
      text: 'Aviation',
      preferred: true,
      language: 'en',
      id: 3101
    }, {
      vocabulary_item_id: 2417,
      text: 'Intercalated Bachelor of Science',
      preferred: true,
      language: 'en',
      id: 3105
    }, {
      vocabulary_item_id: 2421,
      text: 'Arts in Organizational Management',
      preferred: true,
      language: 'en',
      id: 3109
    }, {
      vocabulary_item_id: 2425,
      text: 'International Business Economics',
      preferred: true,
      language: 'en',
      id: 3113
    }, {
      vocabulary_item_id: 2452,
      text: 'operator',
      preferred: true,
      language: 'en',
      id: 3140
    }, {
      vocabulary_item_id: 2456,
      text: 'designer',
      preferred: true,
      language: 'en',
      id: 3144
    }, {
      vocabulary_item_id: 2268,
      text: 'linkedin',
      preferred: true,
      language: 'en',
      id: 2956
    }, {
      vocabulary_item_id: 2272,
      text: 'mobile',
      preferred: true,
      language: 'en',
      id: 2960
    }, {
      vocabulary_item_id: 2390,
      text: 'Public Affairs and Policy Management',
      preferred: true,
      language: 'en',
      id: 3078
    }, {
      vocabulary_item_id: 2394,
      text: 'Talmudic Law ',
      preferred: true,
      language: 'en',
      id: 3082
    }, {
      vocabulary_item_id: 2398,
      text: 'Science and/with education degree ',
      preferred: true,
      language: 'en',
      id: 3086
    }, {
      vocabulary_item_id: 2402,
      text: 'Music Education',
      preferred: true,
      language: 'en',
      id: 3090
    }, {
      vocabulary_item_id: 2406,
      text: 'Applied Studies ',
      preferred: true,
      language: 'en',
      id: 3094
    }, {
      vocabulary_item_id: 2410,
      text: 'Integrated studies ',
      preferred: true,
      language: 'en',
      id: 3098
    }, {
      vocabulary_item_id: 2414,
      text: 'Kinesiology ',
      preferred: true,
      language: 'en',
      id: 3102
    }, {
      vocabulary_item_id: 2418,
      text: 'Business Information Systems',
      preferred: true,
      language: 'en',
      id: 3106
    }, {
      vocabulary_item_id: 2422,
      text: 'Comptrolling ',
      preferred: true,
      language: 'en',
      id: 3110
    }, {
      vocabulary_item_id: 2426,
      text: 'Administrative Studies',
      preferred: true,
      language: 'en',
      id: 3114
    }, {
      vocabulary_item_id: 2453,
      text: 'customizer',
      preferred: true,
      language: 'en',
      id: 3141
    }, {
      vocabulary_item_id: 2266,
      text: 'official website',
      preferred: true,
      language: 'en',
      id: 2954
    }, {
      vocabulary_item_id: 2270,
      text: 'private',
      preferred: true,
      language: 'en',
      id: 2958
    }, {
      vocabulary_item_id: 2388,
      text: 'Accountancy',
      preferred: true,
      language: 'en',
      id: 3076
    }, {
      vocabulary_item_id: 2392,
      text: 'Tourism Studies',
      preferred: true,
      language: 'en',
      id: 3080
    }, {
      vocabulary_item_id: 2396,
      text: 'Science in Law',
      preferred: true,
      language: 'en',
      id: 3084
    }, {
      vocabulary_item_id: 2400,
      text: 'Mortuary Science',
      preferred: true,
      language: 'en',
      id: 3088
    }, {
      vocabulary_item_id: 2404,
      text: 'Library Science',
      preferred: true,
      language: 'en',
      id: 3092
    }, {
      vocabulary_item_id: 2408,
      text: 'General Studies ',
      preferred: true,
      language: 'en',
      id: 3096
    }, {
      vocabulary_item_id: 2412,
      text: 'Religious Studies',
      preferred: true,
      language: 'en',
      id: 3100
    }, {
      vocabulary_item_id: 2416,
      text: 'Medical Biology',
      preferred: true,
      language: 'en',
      id: 3104
    }, {
      vocabulary_item_id: 2420,
      text: 'Computer Science',
      preferred: true,
      language: 'en',
      id: 3108
    }, {
      vocabulary_item_id: 2424,
      text: 'Management and Organizational Studies ',
      preferred: true,
      language: 'en',
      id: 3112
    }, {
      vocabulary_item_id: 2451,
      text: 'director',
      preferred: true,
      language: 'en',
      id: 3139
    }, {
      vocabulary_item_id: 2455,
      text: 'manufacturer',
      preferred: true,
      language: 'en',
      id: 3143
    }, {
      vocabulary_item_id: 2269,
      text: 'work',
      preferred: true,
      language: 'en',
      id: 2957
    }, {
      vocabulary_item_id: 2273,
      text: 'home',
      preferred: true,
      language: 'en',
      id: 2961
    }, {
      vocabulary_item_id: 2387,
      text: 'Advanced Study ',
      preferred: true,
      language: 'en',
      id: 3075
    }, {
      vocabulary_item_id: 2391,
      text: 'Mathematical Sciences',
      preferred: true,
      language: 'en',
      id: 3079
    }, {
      vocabulary_item_id: 2395,
      text: 'Arts in Social Work',
      preferred: true,
      language: 'en',
      id: 3083
    }, {
      vocabulary_item_id: 2399,
      text: 'Science in Psychology',
      preferred: true,
      language: 'en',
      id: 3087
    }, {
      vocabulary_item_id: 2403,
      text: 'Art in Music',
      preferred: true,
      language: 'en',
      id: 3091
    }, {
      vocabulary_item_id: 2407,
      text: 'Science in Human Biology',
      preferred: true,
      language: 'en',
      id: 3095
    }, {
      vocabulary_item_id: 2411,
      text: 'Film and Television ',
      preferred: true,
      language: 'en',
      id: 3099
    }, {
      vocabulary_item_id: 2415,
      text: 'Science in Public Health',
      preferred: true,
      language: 'en',
      id: 3103
    }, {
      vocabulary_item_id: 2419,
      text: 'Computing ',
      preferred: true,
      language: 'en',
      id: 3107
    }, {
      vocabulary_item_id: 2423,
      text: 'Business Science',
      preferred: true,
      language: 'en',
      id: 3111
    }, {
      vocabulary_item_id: 2427,
      text: 'Management Studies',
      preferred: true,
      language: 'en',
      id: 3115
    }, {
      vocabulary_item_id: 2454,
      text: 'producer',
      preferred: true,
      language: 'en',
      id: 3142
    }, {
      vocabulary_item_id: 2312,
      text: 'Science in Information Systems',
      preferred: true,
      language: 'en',
      id: 3000
    }, {
      vocabulary_item_id: 2316,
      text: 'Science in Engineering ',
      preferred: true,
      language: 'en',
      id: 3004
    }, {
      vocabulary_item_id: 2320,
      text: 'Sacred Theology',
      preferred: true,
      language: 'en',
      id: 3008
    }, {
      vocabulary_item_id: 2324,
      text: 'Rabbinic Studies',
      preferred: true,
      language: 'en',
      id: 3012
    }, {
      vocabulary_item_id: 2328,
      text: 'Public Health',
      preferred: true,
      language: 'en',
      id: 3016
    }, {
      vocabulary_item_id: 2332,
      text: 'Political Science',
      preferred: true,
      language: 'en',
      id: 3020
    }, {
      vocabulary_item_id: 2446,
      text: 'Professional',
      preferred: true,
      language: 'en',
      id: 3134
    }, {
      vocabulary_item_id: 2450,
      text: 'Associate',
      preferred: true,
      language: 'en',
      id: 3138
    }, {
      vocabulary_item_id: 2473,
      text: 'continent',
      preferred: true,
      language: 'en',
      id: 3161
    }, {
      vocabulary_item_id: 2325,
      text: 'Quantitative Finance',
      preferred: true,
      language: 'en',
      id: 3013
    }, {
      vocabulary_item_id: 2329,
      text: 'Public Affairs',
      preferred: true,
      language: 'en',
      id: 3017
    }, {
      vocabulary_item_id: 2333,
      text: 'Physician Assistant Studies',
      preferred: true,
      language: 'en',
      id: 3021
    }, {
      vocabulary_item_id: 2447,
      text: 'Doctor',
      preferred: true,
      language: 'en',
      id: 3135
    }, {
      vocabulary_item_id: 2474,
      text: 'country',
      preferred: true,
      language: 'en',
      id: 3162
    }, {
      vocabulary_item_id: 2445,
      text: 'Specialist',
      preferred: true,
      language: 'en',
      id: 3133
    }, {
      vocabulary_item_id: 2449,
      text: 'Bachelor',
      preferred: true,
      language: 'en',
      id: 3137
    }, {
      vocabulary_item_id: 2476,
      text: 'city',
      preferred: true,
      language: 'en',
      id: 3164
    }]
  };

  var roles = exports.roles = {
    roles: [{ name: 'member', id: 1445 }, { name: 'gallery', id: 1449 }, {
      name: 'publisher',
      id: 1453
    }, { name: 'merger', id: 1457 }, { name: 'parent organization', id: 1461 }, {
      name: 'dean',
      id: 1465
    }, { name: 'board member', id: 1469 }, { name: 'academic institution', id: 1446 }, {
      name: 'treating agent',
      id: 1450
    }, { name: 'editor', id: 1454 }, { name: 'successor', id: 1458 }, {
      name: 'sponsor',
      id: 1462
    }, { name: 'chairman', id: 1466 }, { name: 'partner', id: 1470 }, {
      name: 'honorary chair',
      id: 1444
    }, { name: 'museum', id: 1448 }, { name: 'award sponsor', id: 1452 }, {
      name: 'venue',
      id: 1456
    }, { name: 'subsidiary organization', id: 1460 }, { name: 'chancellor', id: 1464 }, {
      name: 'principal',
      id: 1468
    }, { name: 'founder', id: 1472 }, { name: 'auction house', id: 1443 }, {
      name: 'owner',
      id: 1447
    }, { name: 'examiner', id: 1451 }, { name: 'author', id: 1455 }, {
      name: 'predecessor',
      id: 1459
    }, { name: 'organizer', id: 1463 }, { name: 'rector', id: 1467 }, { name: 'head', id: 1471 }, {
      name: 'relative',
      id: 1476
    }, { name: 'employer', id: 1480 }, { name: 'influencer', id: 1484 }, {
      name: 'institution',
      id: 1488
    }, { name: 'numismatist', id: 1492 }, { name: 'sportsman', id: 1496 }, {
      name: 'critic',
      id: 1500
    }, { name: 'curator', id: 1504 }, { name: 'architectural company', id: 1508 }, {
      name: 'perfumer',
      id: 1512
    }, { name: 'tool manufacturer', id: 1516 }, {
      name: 'laboratory equipment manufacturer',
      id: 1520
    }, { name: 'optics manufacturer', id: 1524 }, {
      name: 'electronics manufacturer',
      id: 1528
    }, { name: 'motorcycle manufacturer', id: 1532 }, {
      name: 'aircraft manufacturer',
      id: 1536
    }, { name: 'porcelain manufacturer', id: 1540 }, {
      name: 'metalworking company',
      id: 1544
    }, { name: 'fashion brand', id: 1548 }, { name: 'wine producer', id: 1552 }, {
      name: 'automanufacturer',
      id: 1556
    }, { name: 'cartographer', id: 1560 }, { name: 'model maker', id: 1564 }, {
      name: 'furniture designer',
      id: 1568
    }, { name: 'fashion designer', id: 1572 }, { name: 'woodworker', id: 1576 }, {
      name: 'metalworker',
      id: 1580
    }, { name: 'engineer', id: 1584 }, { name: 'actor', id: 1588 }, {
      name: 'music artist',
      id: 1592
    }, { name: 'book artist', id: 1596 }, { name: 'installation artist', id: 1600 }, {
      name: 'multimedia artist',
      id: 1604
    }, { name: 'performance artist', id: 1608 }, { name: 'video artist', id: 1612 }, {
      name: 'calligrapher',
      id: 1616
    }, { name: 'muralist', id: 1620 }, { name: 'draftsman', id: 1624 }, {
      name: 'company',
      id: 1628
    }, { name: 'president', id: 1475 }, { name: 'spouse', id: 1479 }, {
      name: 'opponent',
      id: 1483
    }, { name: 'repository', id: 1487 }, { name: 'philatelist', id: 1491 }, {
      name: 'dealer',
      id: 1495
    }, { name: 'historian', id: 1499 }, { name: 'teacher', id: 1503 }, {
      name: 'record label',
      id: 1507
    }, { name: 'pen manufacturer', id: 1511 }, {
      name: 'sporting goods manufacturer',
      id: 1515
    }, { name: 'medical equipment manufacturer', id: 1519 }, {
      name: 'camera manufacturer',
      id: 1523
    }, { name: 'computer manufacturer', id: 1527 }, {
      name: 'spacecraft manufacturer',
      id: 1531
    }, { name: 'bus manufacturer', id: 1535 }, { name: 'vehicle manufacturer', id: 1539 }, {
      name: 'foundry',
      id: 1543
    }, { name: 'photography studio', id: 1547 }, { name: 'shipbuilder', id: 1551 }, {
      name: 'watchmaker',
      id: 1555
    }, { name: 'taxidermist', id: 1559 }, { name: 'weapons maker', id: 1563 }, {
      name: 'glass designer',
      id: 1567
    }, { name: 'industrial designer', id: 1571 }, { name: 'leatherworker', id: 1575 }, {
      name: 'blacksmith',
      id: 1579
    }, { name: 'typographer', id: 1583 }, { name: 'dancer', id: 1587 }, {
      name: 'director',
      id: 1591
    }, { name: 'poet', id: 1595 }, { name: 'graphic artist', id: 1599 }, {
      name: 'mixed-media artist',
      id: 1603
    }, { name: 'new media artist', id: 1607 }, { name: 'printmaker', id: 1611 }, {
      name: 'caricaturist',
      id: 1615
    }, { name: 'comic book artist', id: 1619 }, { name: 'engraver', id: 1623 }, {
      name: 'painter',
      id: 1627
    }, { name: 'CEO', id: 1473 }, { name: 'child', id: 1477 }, { name: 'employee', id: 1481 }, {
      name: 'follower',
      id: 1485
    }, { name: 'translator', id: 1489 }, { name: 'philosopher', id: 1493 }, {
      name: 'antiquarian',
      id: 1497
    }, { name: 'collector', id: 1501 }, { name: 'restorer', id: 1505 }, {
      name: 'filmmaker',
      id: 1509
    }, { name: 'tobacco manufacturer', id: 1513 }, {
      name: 'scientific instrument maker',
      id: 1517
    }, { name: 'construction equipment manufacturer', id: 1521 }, {
      name: 'audio equipment manufacturer',
      id: 1525
    }, { name: 'tractor manufacturer', id: 1529 }, {
      name: 'truck manufacturer',
      id: 1533
    }, { name: 'industrial machines manufacturer', id: 1537 }, {
      name: 'ceramics company',
      id: 1541
    }, { name: 'design company', id: 1545 }, { name: 'workshop', id: 1549 }, {
      name: 'distillery',
      id: 1553
    }, { name: 'toymaker', id: 1557 }, { name: 'minter', id: 1561 }, {
      name: 'decorative artist',
      id: 1565
    }, { name: 'lighting designer', id: 1569 }, { name: 'glassworker', id: 1573 }, {
      name: 'goldsmith',
      id: 1577
    }, { name: 'craftsman', id: 1581 }, { name: 'architect', id: 1585 }, {
      name: 'caster',
      id: 1589
    }, { name: 'composer', id: 1593 }, { name: "artists' collective", id: 1597 }, {
      name: 'mosaicist',
      id: 1601
    }, { name: 'ornamentist', id: 1605 }, { name: 'illustrator', id: 1609 }, {
      name: 'watercolorist',
      id: 1613
    }, { name: 'ceramicist', id: 1617 }, { name: 'carver', id: 1621 }, {
      name: 'photographer',
      id: 1625
    }, { name: 'brand', id: 1629 }, { name: 'leader', id: 1474 }, {
      name: 'parent',
      id: 1478
    }, { name: 'collaborator', id: 1482 }, { name: 'foundation', id: 1486 }, {
      name: 'gallerist',
      id: 1490
    }, { name: 'scientist', id: 1494 }, { name: 'archaeologist', id: 1498 }, {
      name: 'student',
      id: 1502
    }, { name: 'conservator', id: 1506 }, { name: 'game manufacturer', id: 1510 }, {
      name: 'bicycle manufacturers',
      id: 1514
    }, { name: 'electrical tool manufacturer', id: 1518 }, {
      name: 'clockmaker',
      id: 1522
    }, { name: 'home appliances manufacturer', id: 1526 }, {
      name: 'train manufacturer',
      id: 1530
    }, { name: 'helicopter manufacturer', id: 1534 }, {
      name: 'agricultural machinery manufacturer',
      id: 1538
    }, { name: 'textile manufacturer', id: 1542 }, { name: 'glassmaking company', id: 1546 }, {
      name: 'manufacturer',
      id: 1550
    }, { name: 'jewelry maker', id: 1554 }, { name: 'musical instrument maker', id: 1558 }, {
      name: 'furniture maker',
      id: 1562
    }, { name: 'set designer', id: 1566 }, { name: 'interior designer', id: 1570 }, {
      name: 'textile designer',
      id: 1574
    }, { name: 'silversmith', id: 1578 }, { name: 'scribe', id: 1582 }, {
      name: 'choreographer',
      id: 1586
    }, { name: 'producer', id: 1590 }, { name: 'writer', id: 1594 }, {
      name: 'designer',
      id: 1598
    }, { name: 'miniaturist', id: 1602 }, { name: 'conceptual artist', id: 1606 }, {
      name: 'street artist',
      id: 1610
    }, { name: 'collagist', id: 1614 }, { name: 'cartoonist', id: 1618 }, {
      name: 'manuscript artist',
      id: 1622
    }, { name: 'sculptor', id: 1626 }, { name: 'artist', id: 1630 }]
  };
});