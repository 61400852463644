define('cdata/pods/components/sales/edit/menu-context/component', ['exports', 'cdata/mixins/components/menu-context'], function (exports, _menuContext) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_menuContext.default, {
    openNewSaleForm: 'openNewSaleForm',
    openImportSaleForm: 'openImportSaleForm',
    openSpectrumSaleForm: 'openSpectrumSaleForm',
    openRefreshSaleForm: 'openRefreshSaleForm',

    createLot: false,

    confirmSessionRemove: false,
    sessionRemove: false,
    removeSession: 'removeSession',

    actions: {
      add: function add() {
        this.sendAction('openNewSaleForm', this.get('model'));
        this.closeContextMenuAction();
      },
      import: function _import() {
        this.sendAction('openImportSaleForm');
        this.closeContextMenuAction();
      },
      spectrum: function spectrum() {
        this.sendAction('openSpectrumSaleForm', this.get('model'));
        this.closeContextMenuAction();
      },
      refresh: function refresh() {
        this.sendAction('openRefreshSaleForm');
        this.closeContextMenuAction();
      },
      removeSession: function removeSession() {
        this.set('confirmSessionRemove', true);
      },
      cancelSessionRemove: function cancelSessionRemove() {
        this.set('confirmSessionRemove', false);
        this.closeContextMenuAction();
      },
      confirmSessionRemove: function confirmSessionRemove() {
        this.set('confirmSessionRemove', false);
        this.closeContextMenuAction();
        this.sendAction('removeSession', this.get('session'));
      }
    }
  });
});