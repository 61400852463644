define('cdata/models/mark', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    uuid: attr('string'),
    typeVocabularyItemId: attr('string'),
    startYear: attr('number'),
    finishYear: attr('number'),
    dateText: attr('string'),
    shapeText: attr('string'),
    makerText: attr('string'),
    placeText: attr('string'),
    typeText: attr('string'),
    markCategories: hasMany('mark-category'),
    markImages: hasMany('mark-image'),
    markNotes: hasMany('mark-note'),
    markSymbols: hasMany('mark-symbol'),
    markMakers: hasMany('mark-maker'),
    markPlaces: hasMany('mark-place'),
    markShapes: hasMany('mark-shape'),
    markTitles: hasMany('mark-title'),
    markMaterials: hasMany('mark-material'),
    markLetters: hasMany('mark-letter')
  });
});