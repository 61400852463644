define('cdata/pods/components/site/editor/row-actions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['row-actions'],

    hideEdit: false,

    enabledReview: false,

    enabled: true,
    disableTitle: null
  });
});