define('cdata/pods/components/entities/view/predicted-hist/component', ['exports', 'cdata/pods/components/lots/lot-properties-mixin'], function (exports, _lotPropertiesMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var sz = 15;

  exports.default = Ember.Component.extend(_lotPropertiesMixin.default, {
    classNames: ['predicted-hist'],

    hist: {},
    estimates: {},

    histogram: Ember.computed('hist.bins.@each', function () {
      var hist = {};
      for (var i = 0; i < this.get('hist.bins.length'); i++) {
        hist[this.get('hist.edges')[i] + ''] = this.get('hist.bins')[i];
      }

      return hist;
    }),

    est: Ember.computed('hist.edges.@each', function () {
      var edges = this.get('hist.edges');
      var bins = this.get('hist.bins');
      var low = this.get('estimates.low');
      var high = this.get('estimates.high');
      var left = 0;
      var right = 0;
      for (var i = 0; i < bins.length; i++) {
        var l = edges[i];
        var r = edges[i + 1];
        var m = l + (r - l) / 2;

        if (low >= l && low <= r) {
          left = i;
          if (low >= m) {
            left = i + 1;
          }
        }
        if (high >= edges[i] && high <= r) {
          right = i;
          if (high >= m) {
            right = i + 1;
          }
        }
      }

      return [Ember.String.htmlSafe('top: ' + (left * sz - 1) + 'px;'), Ember.String.htmlSafe('top: ' + (right * sz - 1) + 'px;')];
    }),

    list: Ember.computed('hist.edges.@each', function () {
      var edges = this.get('hist.edges');
      var bins = this.get('hist.bins');
      var low = +this.get('estimates.low');
      var high = +this.get('estimates.high');
      var bi = parseInt(low * 0.85);
      var list = [];
      var count = 0;

      var max = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = bins[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _value = _step.value;

          max = _value > max ? _value : max;
          void key;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var k = 300 / max;
      var color = null;
      var value = null;

      for (var i = 0; i < bins.length; i++) {
        var l = edges[i];
        var r = edges[i + 1];
        var m = l + (r - l) / 2;

        if (bi >= m) {
          color = 'bi';
        } else if (low >= m) {
          color = 'below';
        } else if (high >= m) {
          color = 'within';
        } else {
          color = 'above';
        }

        value = bins[i];

        var item = {
          price: count === 0 ? l : null,
          width: value * k,
          count: value,
          style: Ember.String.htmlSafe('width: ' + value * k + 'px;'),
          color: color
        };

        list.push(item);

        count = count === 1 ? 0 : count + 1;
      }

      return list;
    })
  });
});