define('cdata/pods/people/edit-mixin', ['exports', 'cdata/mixins/route-create-modals', 'cdata/pods/people/merge-mixin', 'cdata/mixins/editor/route-location', 'cdata/mixins/editor/saving-cases', 'cdata/mixins/components/jde-departments'], function (exports, _routeCreateModals, _mergeMixin, _routeLocation, _savingCases, _jdeDepartments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_routeLocation.default, _routeCreateModals.default, _mergeMixin.default, _savingCases.default, _jdeDepartments.default, {
    sidConstants: Ember.inject.service(),
    relations: Ember.inject.service(),
    roles: Ember.computed.alias('vocab.originalRoles'),
    subjectSearch: Ember.inject.service(),

    actions: {
      /*
       * <<<<< jde departments >>>>>
       */

      removePersonJdeDepartment: function removePersonJdeDepartment(personJdeDepartment) {
        var validate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        personJdeDepartment.deleteRecord();
        if (validate) {
          this.get('_validateDepartmentsItems').perform('person');
        }
      },
      clearPersonJdeDepartmentSection: function clearPersonJdeDepartmentSection() {
        this._removeAllItems('personJdeDepartments', 'removePersonJdeDepartment');
        this._removeAllItems('personChristiesSpecialistDepartments', 'removePersonChristiesSpecialistDepartment');
      },
      addPersonJdeDepartment: function addPersonJdeDepartment(dep) {
        var addSpecialists = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var model = this.get('currentModel');

        var personJdeDepartment = this.get('store').createRecord('person-jde-department', {
          personId: model.get('id'),
          person: model,
          jdeDepartmentVocabularyItemId: dep.get('id')
        });

        model.get('personJdeDepartments').pushObject(personJdeDepartment);
        if (addSpecialists) {
          this.get('_addSpecialistsByDepartment').perform(dep.get('id'), 'person');
        }
      },


      /*
       * <<<<< christies specialist departments >>>>>
       */

      setPreferredToPersonChristiesSpecialistDepartment: function setPreferredToPersonChristiesSpecialistDepartment(personChristiesSpecialistDepartment) {
        this._changePreferred('personChristiesSpecialistDepartments', personChristiesSpecialistDepartment);
      },
      removePersonChristiesSpecialistDepartment: function removePersonChristiesSpecialistDepartment(dep) {
        var validate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        this._removeItemWithPreferred(dep, 'personChristiesSpecialistDepartments');
        if (validate) {
          this.get('_validateDepartmentsItems').perform('person');
        }
      },
      clearPersonChristiesSpecialistDepartmentSection: function clearPersonChristiesSpecialistDepartmentSection() {
        this._removeAllItems('personChristiesSpecialistDepartments', 'removePersonChristiesSpecialistDepartment');
      },
      addPersonChristiesSpecialistDepartment: function addPersonChristiesSpecialistDepartment(spec) {
        var addDepartments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var model = this.get('currentModel');

        var personChristiesSpecialistDepartment = this.get('store').createRecord('person-christies-specialist-department', {
          personId: model.get('id'),
          person: model,
          preferred: !model.get('personChristiesSpecialistDepartments').filterBy('preferred').get('length'),
          christiesSpecialistDepartmentVocabularyItemId: spec.get('id')
        });

        model.get('personChristiesSpecialistDepartments').pushObject(personChristiesSpecialistDepartment);
        if (addDepartments) {
          this.get('_addDepartmentsBySpecialist').perform(spec.get('id'), 'person');
        }
      },


      /*
       * <<<<< names >>>>>
       */

      setPreferredToPersonName: function setPreferredToPersonName(personName) {
        this._changePreferred('personNames', personName);
      },
      removePersonName: function removePersonName(personName) {
        this._removeItemWithPreferred(personName, 'personNames');
      },
      clearPersonNameSection: function clearPersonNameSection() {
        this._removeAllItems('personNames', 'removePersonName');
      },
      addPersonName: function addPersonName() {
        var model = this.get('currentModel');
        var store = this.get('store');
        var personName = store.createRecord('person-name', {
          personId: model.get('id'),
          person: model,
          preferred: !model.get('personNames').filterBy('preferred').get('length'),
          nameLabelVocabularyItemId: this.get('sidConstants.defaultNameLabelVocabularyItemId')
        });
        model.get('personNames').pushObject(personName);
      },


      /*
       * <<<<< roles >>>>>
       */

      addPersonRole: function addPersonRole(roleId, citationId) {
        var model = this.get('currentModel');
        var existRole = model.get('personRoles').find(function (item) {
          return item.get('roleId') === roleId && item.get('citationLabelVocabularyItemId') === citationId;
        });

        if (existRole) {
          if (existRole.get('isDeleted')) {
            var preferred = !model.get('personRoles').filterBy('preferred').get('length');
            existRole.rollbackAttributes();
            existRole.set('preferred', preferred);
          }

          return;
        }

        var role = this.get('roles').findBy('id', roleId);

        var personRole = this.get('store').createRecord('personRole', {
          personId: model.get('id'),
          person: model,
          role: role,
          roleId: roleId,
          citationLabelVocabularyItemId: citationId,
          preferred: !model.get('personRoles').filterBy('preferred').get('length')
        });

        model.get('personRoles').pushObject(personRole);
      },
      setPreferredToPersonRole: function setPreferredToPersonRole(personRole) {
        this._changePreferred('personRoles', personRole);
      },
      removePersonRole: function removePersonRole(personRole) {
        this._removeItemWithPreferred(personRole, 'personRoles');
      },
      clearPersonRoleSection: function clearPersonRoleSection() {
        this._removeAllItems('personRoles', 'removePersonRole');
      },


      /*
       * <<<<< addresses >>>>>
       */

      addPersonAddress: function addPersonAddress() {
        var model = this.get('currentModel');
        var location = this.get('store').createRecord('location');
        var address = this.get('store').createRecord('person-address', {
          personId: model.get('id'),
          person: model,
          locationId: null,
          location: location
        });

        model.get('personAddresses').pushObject(address);
      },
      removePersonAddress: function removePersonAddress(personAddress) {
        personAddress.deleteRecord();
      },
      clearPersonAddressSection: function clearPersonAddressSection() {
        this._removeAllItems('personAddresses', 'removePersonAddress');
      },


      /*
       * <<<<< educations >>>>>
       */

      addPersonOrganizationEducation: function addPersonOrganizationEducation() {
        var model = this.get('currentModel');
        var store = this.get('store');

        var education = store.createRecord('person-organization-education', {
          personId: model.get('id'),
          person: model
        });

        model.get('personOrganizationEducations').pushObject(education);
      },
      setOrganizationToPersonOrganizationEducation: function setOrganizationToPersonOrganizationEducation(personOrganizationEducation, organizationSubject) {
        var organizationId = organizationSubject.get('id');
        personOrganizationEducation.set('organizationId', organizationId);
        personOrganizationEducation.set('_title', organizationSubject.get('name'));
        this.get('store').findRecord('organization', organizationId).then(function (organization) {
          personOrganizationEducation.set('organization', organization);
        });
      },
      removePersonOrganizationEducation: function removePersonOrganizationEducation(personOrganizationEducation) {
        personOrganizationEducation.deleteRecord();
      },
      clearPersonOrganizationEducationSection: function clearPersonOrganizationEducationSection() {
        this._removeAllItems('personOrganizationEducations', 'removePersonOrganizationEducation');
      },


      /*
       * <<<<< memberships >>>>>
       */

      addPersonOrganizationMembership: function addPersonOrganizationMembership() {
        var model = this.get('currentModel');
        var store = this.get('store');

        var education = store.createRecord('person-organization-membership', {
          personRoleVocabularyItemId: this.get('sidConstants.memberRoleId'),
          personId: model.get('id'),
          person: model
        });

        model.get('personOrganizationMemberships').pushObject(education);
      },
      setOrganizationToPersonOrganizationMembership: function setOrganizationToPersonOrganizationMembership(personOrganizationMembership, organizationSubject) {
        var organizationId = organizationSubject.get('id');
        personOrganizationMembership.set('organizationId', organizationId);
        personOrganizationMembership.set('_title', organizationSubject.get('name'));
        this.get('store').findRecord('organization', organizationId).then(function (organization) {
          personOrganizationMembership.set('organization', organization);
        });
      },
      removePersonOrganizationMembership: function removePersonOrganizationMembership(personOrganizationMembership) {
        personOrganizationMembership.deleteRecord();
      },
      clearPersonOrganizationMembershipSection: function clearPersonOrganizationMembershipSection() {
        this._removeAllItems('personOrganizationMemberships', 'removePersonOrganizationMembership');
      },


      /*
       * <<<<< public collections >>>>>
       */

      addPersonPublicCollection: function addPersonPublicCollection() {
        var model = this.get('currentModel');
        var store = this.get('store');

        var education = store.createRecord('person-public-collection', {
          personId: model.get('id'),
          person: model
        });

        model.get('personPublicCollections').pushObject(education);
      },
      setOrganizationToPersonPublicCollection: function setOrganizationToPersonPublicCollection(personPublicCollection, organizationSubject) {
        var organizationId = organizationSubject.get('id');
        personPublicCollection.set('organizationId', organizationId);
        personPublicCollection.set('_title', organizationSubject.get('name'));
        this.get('store').findRecord('organization', organizationId).then(function (organization) {
          personPublicCollection.set('organization', organization);
        });
      },
      removePersonPublicCollection: function removePersonPublicCollection(personPublicCollection) {
        personPublicCollection.deleteRecord();
      },
      clearPersonPublicCollectionSection: function clearPersonPublicCollectionSection() {
        this._removeAllItems('personPublicCollections', 'removePersonPublicCollection');
      },


      /*
       * <<<<< biographies >>>>>
       */

      addPersonBiography: function addPersonBiography() {
        var model = this.get('currentModel');
        var store = this.get('store');

        var personBiography = store.createRecord('person-biography', {
          personId: model.get('id'),
          person: model,
          preferred: !model.get('personBiographies').filterBy('preferred').get('length')
        });

        model.get('personBiographies').pushObject(personBiography);
      },
      removePersonBiography: function removePersonBiography(personBiography) {
        this._removeItemWithPreferred(personBiography, 'personBiographies');
      },
      setPreferredToPersonBiography: function setPreferredToPersonBiography(personBiography) {
        this._changePreferred('personBiographies', personBiography);
      },
      clearPersonBiographySection: function clearPersonBiographySection() {
        this._removeAllItems('personBiographies', 'removePersonBiography');
      },


      /*
       * <<<<< person relationships >>>>>
       */

      addPersonRelationship: function addPersonRelationship() {
        var model = this.get('currentModel');

        var relationship = this.get('store').createRecord('person-relationship', {
          fromId: model.get('id')
        });

        model.get('personRelationships').pushObject(relationship);
      },
      removePersonRelationship: function removePersonRelationship(relationship) {
        relationship.deleteRecord();
      },
      clearPersonRelationshipSection: function clearPersonRelationshipSection() {
        this._removeAllItems('personRelationships', 'removePersonRelationship');
      },
      loadPersonForRelationship: function loadPersonForRelationship(model, id) {
        this.get('subjectSearch').getByIds(id, 'person').then(function (result) {
          model.set('_name', result[0].get('name'));
          model.set('_description', result[0].get('description'));
          model.set('toId', id);
        });
      },


      /*
       * <<<<< person organizations >>>>>
       */

      addPersonOrganization: function addPersonOrganization() {
        var model = this.get('currentModel');

        var personOrganization = this.get('store').createRecord('person-organization', {
          personId: model.get('id'),
          person: model
        });

        model.get('personOrganizations').pushObject(personOrganization);
      },
      removePersonOrganization: function removePersonOrganization(relationship) {
        relationship.deleteRecord();
      },
      clearPersonOrganizationSection: function clearPersonOrganizationSection() {
        this._removeAllItems('personOrganizations', 'removePersonOrganization');
      },


      /*
       * <<<<< related publications >>>>>
       */

      /**
       * Add publication for Person
       */
      addPersonRelatedPublication: function addPersonRelatedPublication() {
        var model = this.get('currentModel');
        var store = this.get('store');

        var personRelatedPublication = store.createRecord('person-related-publication', {
          personId: model.get('id'),
          person: model,
          preferred: !model.get('personRelatedPublications').filterBy('preferred').get('length')
        });

        model.get('personRelatedPublications').pushObject(personRelatedPublication);
      },
      removePersonRelatedPublication: function removePersonRelatedPublication(personRelatedPublication) {
        this._removeItemWithPreferred(personRelatedPublication, 'personRelatedPublications');
      },
      clearPersonRelatedPublicationSection: function clearPersonRelatedPublicationSection() {
        this._removeAllItems('personRelatedPublications', 'removePersonRelatedPublication');
      },


      /*
       * <<<<< images >>>>>
       */

      setPreferredToPersonImage: function setPreferredToPersonImage(personImage) {
        this._changePreferred('personImages', personImage);
      },
      removePersonImage: function removePersonImage(personVisualRepresentation) {
        this._removeItemWithPreferred(personVisualRepresentation, 'personImages');
      },
      clearPersonImagesSection: function clearPersonImagesSection() {
        this._removeAllItems('personImages', 'removePersonImage');
      },
      addPersonImage: function addPersonImage() {
        var model = this.get('currentModel');

        var image = this.get('store').createRecord('image', {});

        var personImage = this.get('store').createRecord('person-image', {
          person: model,
          personId: model.get('id'),
          preferred: !model.get('personImages').filterBy('preferred').get('length'),
          image: image
        });

        model.get('personImages').pushObject(personImage);
      },


      /*
       * <<<<< activity dates >>>>>
       */

      setPreferredToPersonActivityDate: function setPreferredToPersonActivityDate(personActivityDate) {
        this._changePreferred('personActivityDates', personActivityDate);
      },
      removePersonActivityDate: function removePersonActivityDate(personActivityDate) {
        this._removeItemWithPreferred(personActivityDate, 'personActivityDates');
      },
      clearPersonActivityDateSection: function clearPersonActivityDateSection() {
        this._removeAllItems('personActivityDates', 'removePersonActivityDate');
      },
      addPersonActivityDate: function addPersonActivityDate() {
        var model = this.get('currentModel');
        var personActivityDate = this.get('store').createRecord('person-activity-date', {
          personId: model.get('id'),
          person: model,
          text: '',
          preferred: !model.get('personActivityDates').filterBy('preferred').get('length')
        });
        model.get('personActivityDates').pushObject(personActivityDate);
      },


      /*
       * <<<<< vital dates >>>>>
       */

      setPreferredToPersonVitalDate: function setPreferredToPersonVitalDate(personVitalDate) {
        this._changePreferred('personVitalDates', personVitalDate);
      },
      removePersonVitalDate: function removePersonVitalDate(personVitalDate) {
        this._removeItemWithPreferred(personVitalDate, 'personVitalDates');
      },
      addPersonVitalDate: function addPersonVitalDate() {
        var model = this.get('currentModel');
        var personVitalDate = this.get('store').createRecord('person-vital-date', {
          personId: model.get('id'),
          person: model,
          text: '',
          preferred: !model.get('personVitalDates').filterBy('preferred').get('length')
        });
        model.get('personVitalDates').pushObject(personVitalDate);
      },
      clearPersonVitalDateSection: function clearPersonVitalDateSection() {
        this._removeAllItems('personVitalDates', 'removePersonVitalDate');
      },


      /*
       * <<<<< birth places >>>>>
       */

      setPreferredToPersonBirthPlace: function setPreferredToPersonBirthPlace(personBirthPlace) {
        this._changePreferred('personBirthPlaces', personBirthPlace);
      },
      removePersonBirthPlace: function removePersonBirthPlace(personBirthPlace) {
        this._removeItemWithPreferred(personBirthPlace, 'personBirthPlaces');
      },
      addPersonBirthPlace: function addPersonBirthPlace(placeId, citationId) {
        this.addPersonPlace(placeId, citationId, 'personBirthPlaces', 'person-birth-place');
      },
      clearPersonBirthPlaceSection: function clearPersonBirthPlaceSection() {
        this._removeAllItems('personBirthPlaces', 'removePersonBirthPlace');
      },


      /*
       * <<<<< awards >>>>>
       */

      removePersonAward: function removePersonAward(personAward) {
        personAward.deleteRecord();
      },
      addPersonAward: function addPersonAward() {
        var model = this.get('currentModel');

        var personAward = this.get('store').createRecord('person-award', {
          personId: model.get('id'),
          person: model
        });

        model.get('personAwards').pushObject(personAward);
      },
      loadAwardFor: function loadAwardFor(model, id) {
        model.set('award', null);
        this.get('store').findRecord('award', id).then(function (award) {
          model.setProperties({
            award: award,
            awardId: award.get('id'),
            _name: award.get('name'),
            _eventName: award.get('_eventName'),
            _givingName: award.get('_givingName')
          });
        });
      },
      clearPersonAwardSection: function clearPersonAwardSection() {
        this._removeAllItems('personAwards', 'removePersonAward');
      },


      /*
       * <<<<< death places >>>>>
       */

      setPreferredToPersonDeathPlace: function setPreferredToPersonDeathPlace(personDeathPlace) {
        this._changePreferred('personDeathPlaces', personDeathPlace);
      },
      removePersonDeathPlace: function removePersonDeathPlace(personDeathPlace) {
        this._removeItemWithPreferred(personDeathPlace, 'personDeathPlaces');
      },
      addPersonDeathPlace: function addPersonDeathPlace(placeId, citationId) {
        this.addPersonPlace(placeId, citationId, 'personDeathPlaces', 'person-death-place');
      },
      clearPersonDeathPlaceSection: function clearPersonDeathPlaceSection() {
        this._removeAllItems('personDeathPlaces', 'removePersonDeathPlace');
      },


      /*
       * <<<<< nationalities >>>>
       */

      setPreferredToPersonNationality: function setPreferredToPersonNationality(personNationality) {
        this._changePreferred('personNationalities', personNationality);
      },
      removePersonNationality: function removePersonNationality(personNationality) {
        this._removeItemWithPreferred(personNationality, 'personNationalities');
      },
      clearPersonNationalitySection: function clearPersonNationalitySection() {
        this._removeAllItems('personNationalities', 'removePersonNationality');
      },
      addPersonNationality: function addPersonNationality(nationalityId, citationId) {
        var model = this.get('currentModel');
        var existNationality = model.get('personNationalities').find(function (item) {
          return item.get('nationalityVocabularyItemId') === nationalityId && item.get('citationLabelVocabularyItemId') === citationId;
        });

        if (existNationality) {
          if (existNationality.get('isDeleted')) {
            var preferred = !model.get('personNationalities').filterBy('preferred').get('length');
            existNationality.rollbackAttributes();
            existNationality.set('preferred', preferred);
          }

          return;
        }

        var personNationality = this.get('store').createRecord('person-nationality', {
          personId: model.get('id'),
          person: model,
          preferred: !model.get('personNationalities').filterBy('preferred').get('length'),
          nationalityVocabularyItemId: nationalityId,
          citationLabelVocabularyItemId: citationId
        });

        model.get('personNationalities').pushObject(personNationality);
      },


      /*
       * <<<<< phones >>>>>
       */

      addPersonPhone: function addPersonPhone() {
        var model = this.get('currentModel');

        var personPhone = this.get('store').createRecord('person-phone', {
          person: model,
          personId: model.get('id'),
          preferred: !model.get('personPhones').filterBy('preferred').get('length')
        });

        model.get('personPhones').pushObject(personPhone);
      },
      removePersonPhone: function removePersonPhone(personPhone) {
        this._removeItemWithPreferred(personPhone, 'personPhones');
      },
      setPreferredToPersonPhone: function setPreferredToPersonPhone(personPhone) {
        this._changePreferred('personPhones', personPhone);
      },
      clearPersonPhoneSection: function clearPersonPhoneSection() {
        this._removeAllItems('personPhones', 'removePersonPhone');
      },


      /*
       * <<<<< emails >>>>>
       */

      addPersonEmail: function addPersonEmail() {
        var model = this.get('currentModel');

        var personEmail = this.get('store').createRecord('person-email', {
          person: model,
          personId: model.get('id'),
          preferred: !model.get('personEmails').filterBy('preferred').get('length')
        });

        model.get('personEmails').pushObject(personEmail);
      },
      removePersonEmail: function removePersonEmail(personEmail) {
        this._removeItemWithPreferred(personEmail, 'personEmails');
      },
      setPreferredToPersonEmail: function setPreferredToPersonEmail(personEmail) {
        this._changePreferred('personEmails', personEmail);
      },
      clearPersonEmailSection: function clearPersonEmailSection() {
        this._removeAllItems('personEmails', 'removePersonEmail');
      },


      /*
       * <<<<< websites >>>>>
       */

      addPersonWebsite: function addPersonWebsite() {
        var model = this.get('currentModel');

        var personWebsite = this.get('store').createRecord('person-website', {
          person: model,
          personId: model.get('id'),
          preferred: !model.get('personWebsites').filterBy('preferred').get('length')
        });

        model.get('personWebsites').pushObject(personWebsite);
      },
      removePersonWebsite: function removePersonWebsite(personWebsite) {
        this._removeItemWithPreferred(personWebsite, 'personWebsites');
      },
      setPreferredToPersonWebsite: function setPreferredToPersonWebsite(personWebsite) {
        this._changePreferred('personWebsites', personWebsite);
      },
      clearPersonWebsiteSection: function clearPersonWebsiteSection() {
        this._removeAllItems('personWebsites', 'removePersonWebsite');
      },


      /*
       * <<<<< globals >>>>>
       */

      cancel: function cancel() {
        this.send('reset');
        this._clearEditor();
      },


      /**
       * Reset Person model
       */
      reset: function reset() {
        var model = this.get('currentModel');
        model.rollbackAttributes();

        this.get('relations').rollbackHasMany(model);
        model.get('personBiographies').forEach(this._resetPersonBiography.bind(this));
        model.get('personAddresses').forEach(this._resetPersonAddress.bind(this));
      },


      /**
       * Clean, validate and save Person model with all relationships
       */
      save: function save() {
        var _this = this;

        this._clean();

        var model = this.get('currentModel');

        model.get('personImages').forEach(function (item) {
          _this._saveRelationWithImage(item);
        });

        model.get('personAddresses').forEach(function (item) {
          _this._saveAddress(item);
        });

        // saving simple has many relations
        this._handleSaving(this.get('relations').saveSimpleHasMany(model, ['personNames', 'personRelatedPublications', 'personJdeDepartments', 'personChristiesSpecialistDepartments', 'personBiographies', 'personRoles', 'personNationalities', 'personActivityDates', 'personVitalDates', 'personAwards', 'personOrganizationEducations', 'personOrganizationMemberships', 'personRelationships', 'personEmails', 'personPhones', 'personWebsites', 'personOrganizations', 'personPublicCollections', 'personBirthPlaces', 'personDeathPlaces']));

        if (model.get('hasDirtyAttributes')) {
          this._handleSaving(model.save());
        } else {
          this._handleSaving();
        }
      },
      loadOrganizationFor: function loadOrganizationFor(model, id) {
        model.set('organization', null);
        this.get('store').findRecord('organization', id).then(function (organization) {
          model.setProperties({
            organization: organization,
            _title: organization.get('_title'),
            _place: organization.get('_place'),
            organizationId: organization.get('id')
          });
        });
      }
    },

    _resetPersonBiography: function _resetPersonBiography(personBiography) {
      personBiography.rollbackAttributes();
    },
    _resetPersonAddress: function _resetPersonAddress(personAddress) {
      personAddress.rollbackAttributes();
      this._resetLocation(personAddress);
    },
    _resetLocation: function _resetLocation(model) {
      this.get('relations').resetBelongsTo(model, 'location');
    },


    /**
     * Remove not-filled (empty) data
     */
    _clean: function _clean() {
      this._removeItemsByEmptyField('personNames', 'text', 'removePersonName');
      this._removeItemsByEmptyField('personWebsites', 'url', 'removePersonWebsite');
      this._removeItemsByEmptyField('personBiographies', 'text', 'removePersonBiography');
      this._removeItemsByEmptyField('personImages', 'image.url', 'removePersonImage');
      this._removeItemsByEmptyField('personVitalDates', ['text'], 'removePersonVitalDate');
      this._removeItemsByEmptyField('personActivityDates', ['text'], 'removePersonActivityDate');
      this._removeItemsByEmptyField('personOrganizationEducations', ['organizationId'], 'removePersonOrganizationEducation');
      this._removeItemsByEmptyField('personOrganizationMemberships', ['organizationId'], 'removePersonOrganizationMembership');
      this._removeItemsByEmptyField('personPublicCollections', ['organizationId'], 'removePersonPublicCollection');
      this._removeItemsByEmptyField('personAddresses', 'location.googlePlaceId', 'removePersonAddress');
      this._removeItemsByEmptyField('personJdeDepartments', 'jdeDepartmentVocabularyItemId', 'removePersonJdeDepartment');
      this._removeItemsByEmptyField('personChristiesSpecialistDepartments', 'christiesSpecialistDepartmentVocabularyItemId', 'removePersonChristiesSpecialistDepartment');
    },
    addPersonPlace: function addPersonPlace(placeId, citationId, relationshipKey, modelName) {
      var _this2 = this;

      var model = this.get('currentModel');
      var existPlace = model.get(relationshipKey).find(function (item) {
        return item.get('placeId') === placeId && item.get('citationLabelVocabularyItemId') === citationId;
      });

      if (existPlace) {
        if (existPlace.get('isDeleted')) {
          var preferred = !model.get(relationshipKey).filterBy('preferred').get('length');
          existPlace.rollbackAttributes();
          existPlace.set('preferred', preferred);
        }

        return;
      }

      this.get('store').findRecord('place', placeId).then(function (place) {
        var personPlace = _this2.get('store').createRecord(modelName, {
          personId: model.get('id'),
          person: model,
          preferred: !model.get(relationshipKey).filterBy('preferred').get('length'),
          placeId: placeId,
          citationLabelVocabularyItemId: citationId,
          place: place
        });

        personPlace.buildName();

        model.get(relationshipKey).pushObject(personPlace);
      });
    }
  });
});