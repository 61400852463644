define('cdata/pods/components/site/editor/comment-items/component', ['exports', 'cdata/mixins/components/summary-info-comments'], function (exports, _summaryInfoComments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_summaryInfoComments.default, {
    userActions: Ember.inject.service(),
    classNames: ['section-field custom'],

    entity: null,
    entityId: null,

    inProgress: false,
    deletingId: null,

    comments: Ember.computed.alias('model.comments'),

    disabled: Ember.computed.not('text'),

    userId: Ember.computed.alias('userActions.user.id'),

    actions: {
      startDelete: function startDelete(id) {
        this.set('deletingId', id);
      },
      delete: function _delete(comment) {
        var _this = this;

        this.set('inProgress', true);
        this.sendAction('deleteComment', comment, function () {
          _this.set('inProgress', false);
          _this.send('cancel');
        });
      },
      add: function add() {
        this.sendAction('openCommentForm');
      },
      edit: function edit(comment) {
        this.sendAction('openCommentForm', comment);
      },
      cancel: function cancel() {
        this.set('text', null);
        this.set('isAdding', false);
        this.set('deletingId', null);
      }
    }
  });
});