define('cdata/pods/components/site/list/simple-inline-actions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'p',
    classNames: ['simple-inline-actions-row'],

    edit: 'editItem',
    delete: 'deleteItem',
    save: 'saveNewItem',

    active: false,
    deleting: false,

    name: null,

    passive: Ember.computed('active', 'deleting', function () {
      return !this.get('active') && !this.get('deleting');
    }),

    actions: {
      edit: function edit() {
        this.sendAction('edit');
      },
      save: function save() {
        this.sendAction('save', this.get('name'));
        this.send('cancel');
      },
      add: function add() {
        this.set('active', true);
        Ember.run.schedule('afterRender', function () {
          Ember.$('input.new-item').focus();
        });
      },
      cancel: function cancel() {
        this.set('name', '');
        this.set('active', false);
      },
      startDelete: function startDelete() {
        this.set('deleting', true);
      },
      cancelDelete: function cancelDelete() {
        this.set('deleting', false);
      },
      delete: function _delete() {
        this.sendAction('delete');
        this.send('cancelDelete');
      }
    }
  });
});