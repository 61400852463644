define('cdata/pods/components/lots/list/set-results/component', ['exports', 'cdata/mixins/lists/set-results', 'ember-local-storage'], function (exports, _setResults, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_setResults.default, {
    classNames: ['list-set-results'],

    addToComparableSet: 'addToComparableSet',
    removeFromComparableSet: 'removeFromComparableSet',

    comparableSets: (0, _emberLocalStorage.storageFor)('comparable-sets'),
    comparableSetsActive: Ember.computed.and('comparableSets.settings.enabled', 'comparableSets.settings.setId'),
    comparablesLotsIds: Ember.computed('comparablesLots.@each.id', function () {
      if (!this.get('comparableSetsActive') || !this.get('comparablesLots')) {
        return [];
      }

      return this.get('comparablesLots').mapBy('id');
    }),

    actions: {
      addToComparableSet: function addToComparableSet() {
        this.sendAction.apply(this, ['addToComparableSet'].concat(Array.prototype.slice.call(arguments)));
      },
      removeFromComparableSet: function removeFromComparableSet() {
        this.sendAction.apply(this, ['removeFromComparableSet'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});