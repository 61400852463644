define('cdata/pods/components/organizations/modals/conversion-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.ORGANIZATION_CONVERSION,

    classNames: ['context-modal', _constants.MODALS.ORGANIZATION_CONVERSION, 'edit-form'],

    confirmOrganizationConversion: 'confirmOrganizationConversion',
    closeConversionOrganizationForm: 'closeConversionOrganizationForm',

    title: Ember.computed('model.organization.organizationTitles.@each.{preferred,text}', function () {
      var titles = this.get('model.organization.organizationTitles');
      var title = titles && titles.findBy('preferred');

      return title && title.get('text') || 'Unknown';
    }),

    actions: {
      confirm: function confirm() {
        this.sendAction('confirmOrganizationConversion', this.get('model.organization'));
      },
      cancel: function cancel() {
        this.sendAction('closeConversionOrganizationForm');
      }
    }
  });
});