define('cdata/pods/reports/components/chart-header/declaration', ['exports', 'ember-prop-types', 'cdata/pods/reports/components/chart-breadcrumbs/declaration'], function (exports, _emberPropTypes, _declaration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    breadcrumbs: _emberPropTypes.PropTypes.oneOfType([_declaration.default.breadcrumbs]),
    onCloseClick: _emberPropTypes.PropTypes.func,
    header: _emberPropTypes.PropTypes.string.isRequired
  };
});