define('cdata/pods/components/site/log-in/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    webAuth: Ember.inject.service(),
    classNames: ['ui four column centered grid log-in-form'],

    logIn: 'logIn',

    actions: {
      logIn: function logIn() {
        this.sendAction('logIn', this.get('email'), this.get('password'));
      },
      useAuth0: function useAuth0() {
        this.get('webAuth.auth0').authorize();
      }
    }
  });
});