define('cdata/models/organization-title', ['exports', 'ember-data', 'cdata/mixins/models/user-name'], function (exports, _emberData, _userName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_userName.default, {
    organizationId: attr('string'),
    organization: belongsTo('organization'),
    text: attr('string'),
    preferred: attr('boolean'),
    userId: attr('string'),
    language: attr('string', { defaultValue: 'en' }),
    citationLabelVocabularyItemId: attr('string')
  });
});