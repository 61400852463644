define('cdata/pods/components/lots/list/sort-box/component', ['exports', 'cdata/mixins/lists/component-sort-box'], function (exports, _componentSortBox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentSortBox.default, {
    values: [Ember.Object.create({
      title: 'Number',
      key: 'number'
    }), Ember.Object.create({
      title: 'Creation date',
      key: 'year'
    }), Ember.Object.create({
      title: 'Creator name',
      key: 'creator_name'
    }), Ember.Object.create({
      title: 'Department',
      key: 'christies_specialist_department_name'
    }), Ember.Object.create({
      title: 'Sale date',
      key: 'sale_date'
    }), Ember.Object.create({
      title: 'Estimate',
      key: 'estimate'
    }), Ember.Object.create({
      title: 'Sold for',
      key: 'price_usd'
    }), Ember.Object.create({
      title: 'Modified',
      key: 'updated_at'
    })]
  });
});