define('cdata/pods/components/entities/edit/menu-context/component', ['exports', 'cdata/mixins/components/menu-context', 'ember-local-storage'], function (exports, _menuContext, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_menuContext.default, {
    reviewMode: (0, _emberLocalStorage.storageFor)('review-mode'),

    openNewLotFormFromEntity: 'openNewLotFormFromEntity',
    openNewEntityForm: 'openNewEntityForm',
    createEntity: 'createEntity',

    createLot: false,

    reviewEnabled: Ember.computed.alias('reviewMode.settings.enabled'),
    pricesEnabled: Ember.computed.alias('userActions.predictedPrices.settings.enabled'),
    userActions: Ember.inject.service(),

    sourceTagsEnabled: Ember.computed.alias('userActions.sourceTags.settings.enabledTags'),
    sourceUsersEnabled: Ember.computed.alias('userActions.sourceTags.settings.enabledUsers'),

    actions: {
      togglePredictedPrices: function togglePredictedPrices() {
        this.get('userActions').toggleShowPredictedPrices();
      },
      addLot: function addLot() {
        this.sendAction('openNewLotFormFromEntity', this.get('model'));
        this.closeContextMenuAction();
      },
      addEntity: function addEntity() {
        this.sendAction('createEntity');
        this.closeContextMenuAction();
      },
      enableReviewMode: function enableReviewMode() {
        this.get('reviewMode').set('settings.enabled', true);
      },
      disableReviewMode: function disableReviewMode() {
        this.get('reviewMode').set('settings.enabled', false);
      },
      toggleSourceTags: function toggleSourceTags() {
        this.get('userActions').toggleSourceTags();
      },
      toggleSourceUsers: function toggleSourceUsers() {
        this.get('userActions').toggleSourceUsers();
      }
    }
  });
});