define('cdata/models/person-nationality', ['exports', 'ember-data', 'cdata/mixins/models/user-name'], function (exports, _emberData, _userName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_userName.default, {
    personId: attr('string'),
    person: belongsTo('person'),
    userId: attr('string'),
    nationalityVocabularyItemId: attr('string'),
    citationLabelVocabularyItemId: attr('string'),
    preferred: attr('boolean')
  });
});