define('cdata/mixins/create-modals/place', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var eventKey = _constants.MODALS.PLACE + '.saving';

  exports.default = Ember.Mixin.create({
    sidConstants: Ember.inject.service(),

    actions: {
      openNewPlaceForm: function openNewPlaceForm(placeName) {
        this.get('modal').register(this, _constants.MODALS.PLACE, 'places.modal.create-form').open(Ember.Object.create({
          name: placeName
        }));
      },
      closeNewPlaceForm: function closeNewPlaceForm() {
        this.get('modal').close(_constants.MODALS.PLACE);
      },
      saveNewPlaceForm: function saveNewPlaceForm(form) {
        var _this = this;

        var modal = this.get('modal');
        modal.trigger(eventKey + '.process');

        this.get('store').findRecord('place', form.get('parent.id')).then(function (parent) {
          if (!parent) {
            return null;
          }

          var place = _this.get('store').createRecord('place', {
            parent: parent,
            parentId: parent.get('id'),
            name: form.get('name')
          });

          _this._handleSavingModal(place.save(), false).then(function (place) {
            modal.trigger(eventKey + '.success', {
              item: Ember.Object.create({
                id: place.get('id'),
                name: place.get('name'),
                description: form.get('description'),
                type: place
              })
            });
          }, function (error) {
            modal.trigger(eventKey + '.error', error);
          }).finally(function () {
            modal.trigger(eventKey + '.finally');
            _this.send('closeNewPlaceForm');
          });
        });
      }
    }
  });
});