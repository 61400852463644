define('cdata/pods/components/locations/mixins/google-maps', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    maps: {
      c: null,

      _map: null,
      _autocomplete: null,
      _marker: null,
      _infoWindow: null,

      maps: window.google.maps,
      canvas: null,

      init: function init(component, canvas) {
        this.c = component;
        this._map = null;
        this._autocomplete = null;
        this._marker = null;
        this._infoWindow = null;
        this.canvas = canvas;

        this.addMarkerListener();

        if (this.c.get('googlePlaceId')) {
          this.setMarker();
        }

        this.initAutocomplete();

        return this;
      },
      input: function input() {
        return document.getElementById('pac-input');
      },
      getLatLng: function getLatLng() {
        var defaultLocation = {
          lat: 40.74294,
          lng: -73.983204
        };

        return new this.maps.LatLng({
          lat: parseFloat(this.c.get('latitude') || defaultLocation.lat),
          lng: parseFloat(this.c.get('longitude') || defaultLocation.lng)
        });
      },
      mapOptions: function mapOptions() {
        return {
          center: this.getLatLng(),
          zoom: 17,
          disableDefaultUI: true
        };
      },
      autocomplete: function autocomplete() {
        if (!this._autocomplete) {
          this._autocomplete = new this.maps.places.Autocomplete(this.input());
          this._autocomplete.bindTo('bounds', this.map());
        }

        return this._autocomplete;
      },
      map: function map() {
        if (!this._map) {
          this._map = new this.maps.Map(this.canvas, this.mapOptions());
          if (this._map.controls) {
            this._map.controls[this.maps.ControlPosition.TOP_LEFT].push(this.input());
          }
        }

        return this._map;
      },
      marker: function marker() {
        if (!this._marker) {
          this._marker = new this.maps.Marker({
            map: this.map()
          });
        }

        return this._marker;
      },
      addMarkerListener: function addMarkerListener() {
        var _this = this;

        this.marker().addListener('click', function () {
          _this.infoWindow().open(_this.map(), _this.marker());
        });
      },
      infoWindow: function infoWindow() {
        if (!this._infoWindow) {
          this._infoWindow = new this.maps.InfoWindow();
        }

        return this._infoWindow;
      },


      setMarker: function setMarker() {
        var place = this.place();

        this.marker().setPlace({
          placeId: place.place_id,
          location: place.geometry.location
        });

        this.marker().setVisible(true);

        this.infoWindow().setContent('<div><strong>' + place.name + '</strong><br>' + place.formatted_address);
        this.infoWindow().open(this.map(), this.marker());
      },

      place: function place() {
        return {
          place_id: this.c.get('googlePlaceId'),
          formatted_address: this.c.get('address'),
          geometry: {
            location: this.getLatLng()
          },
          name: this.c.get('name')
        };
      },
      initAutocomplete: function initAutocomplete() {
        var _this2 = this;

        this.autocomplete().addListener('place_changed', function () {
          _this2.infoWindow().close();
          var place = _this2.autocomplete().getPlace();

          if (!place.geometry) {
            return;
          }

          if (place.geometry.viewport) {
            _this2.map().fitBounds(place.geometry.viewport);
          } else {
            _this2.map().setCenter(place.geometry.location);
            _this2.map().setZoom(17);
          }

          var findAddress = function findAddress(address_components, type) {
            return address_components.filter(function (address_component) {
              return address_component.types.indexOf(type) >= 0;
            })[0];
          };

          var country = findAddress(place.address_components, 'country');
          var city = findAddress(place.address_components, 'postal_town') || findAddress(place.address_components, 'locality');
          var region = findAddress(place.address_components, 'administrative_area_level_1');

          if (!city) {
            city = region;
            region = {};
          }

          _this2.c.attrs.onchange({
            name: place.name,
            address: place.formatted_address,
            googlePlaceId: place.place_id,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            city: city && city.long_name,
            country: country.long_name,
            region: region && region.long_name,
            continent: _constants.CONTINENTS[country.short_name],
            url: place.url
          });

          // Set the position of the marker using the place ID and location.
          _this2.setMarker(_this2.map(), _this2.marker(), place, _this2.infoWindow());
        });
      }
    }
  });
});