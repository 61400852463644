define('cdata/pods/components/subjects/search-and-set/component', ['exports', 'cdata/services/subject-search', 'cdata/pods/components/subjects/mixins/search-and-mixin'], function (exports, _subjectSearch, _searchAndMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_searchAndMixin.default, {
    session: Ember.inject.service(),

    classNames: ['field', 'autocomplete-one-item'],
    classNameBindings: ['required'],
    placeholder: 'Start typing name',
    label: null,
    title: null,
    format: null,
    removePerson: false,
    disabled: false,
    item: null,
    removable: false,
    confirmable: false,
    confirmLabel: 'Confirm!',
    focus: false,
    shownOnFocus: false,
    subjectFilter: {},
    createNewLabel: 'Create new with title',

    shownLabelOnFocus: Ember.computed.and('shownOnFocus', 'label', 'focus'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('search', _subjectSearch.default.create({
        subjectName: this.get('subjectName'),
        vocab: this.get('vocab'),
        headers: this.get('session.authHeader'),
        params: this.get('params')
      }));
    },


    actions: {
      setSubject: function setSubject(subject) {
        this._handleSelect(subject);
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.get('search').reset();
      },
      remove: function remove() {
        this.set('item', null);
        this.get('handleRemove')();
      },
      confirm: function confirm() {
        this.get('handleConfirm')(this.get('item'));
      }
    },

    _handleSelect: function _handleSelect(item) {
      this.set('item', item);

      if (this.get('handleSetId')) {
        this.get('handleSetId')(item.get('id'));
      }

      if (this.get('handleSet')) {
        this.get('handleSet')(this.get('model'), item);
      }

      this.$().find('input').blur();
    }
  });
});