define('cdata/pods/components/site/account-top/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['account-top'],
    classNameBindings: ['shown', 'fixed'],

    pageType: Ember.inject.service(),
    session: Ember.inject.service(),

    shown: Ember.computed.bool('pageType.classPageType'),

    fixed: Ember.computed.or('pageType.list', 'pageType.main'),

    logout: 'logout',

    actions: {
      logout: function logout() {
        this.sendAction('logout');
      }
    }
  });
});