define('cdata/pods/auth/callback/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    webAuth: Ember.inject.service(),
    flashMessage: Ember.inject.service(),
    transitionSpot: Ember.inject.service(),

    goToPrevPage: function goToPrevPage() {
      var transitionSpot = this.get('transitionSpot');
      var prevUrl = transitionSpot.get('prevUrl');

      if (prevUrl) {
        transitionSpot.restorePrevUrl();
      } else {
        this.transitionTo('init.loader');
      }
    },
    activate: function activate() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('webAuth').continueAuth().then(function () {
        return _this.goToPrevPage();
      }).catch(function (err) {
        (true && Ember.warn(err));

        _this.get('flashMessage').showError('Auth failed. Try again');
        _this.transitionTo('init.login');
      });
    }
  });
});