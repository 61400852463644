define('cdata/pods/components/site/editor/address-item/map/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui grid'],

    cancel: 'cancel',
    confirmGoogleMap: 'confirmGoogleMap',
    changePlace: 'changePlace',

    actions: {
      cancel: function cancel() {
        this.sendAction.apply(this, ['cancel'].concat(Array.prototype.slice.call(arguments)));
      },
      confirmGoogleMap: function confirmGoogleMap() {
        this.sendAction.apply(this, ['confirmGoogleMap'].concat(Array.prototype.slice.call(arguments)));
      },
      changePlace: function changePlace() {
        this.sendAction.apply(this, ['changePlace'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});