define('cdata/pods/components/dates/edit-filter-month-fields/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['edit-filter-month-field'],
    dates: Ember.Object.create(),
    months: undefined,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var months = this.get('months');

      if (null === months) {
        this.set('dates.startMonth', null);
        this.set('dates.finishMonth', null);
        this.set('dates.startYear', null);
        this.set('dates.finishYear', null);
      }

      if (!months || !Ember.isArray(months) || months.length === 0) {
        return;
      }

      var start = (0, _moment.default)(months[0], 'YYYY-MM');
      this.set('dates.startMonth', start.format('MM'));
      this.set('dates.startYear', start.format('YY'));

      if (months[1]) {
        var finish = (0, _moment.default)(months[1], 'YYYY-MM');
        this.set('dates.finishMonth', finish.format('MM'));
        this.set('dates.finishYear', finish.format('YY'));
      }
    },


    actions: {
      setStartMonth: function setStartMonth(value) {
        this.set('dates.startMonth', value);
        this.sendResult();
      },
      setStartYear: function setStartYear(value) {
        this.set('dates.startYear', value);
        this.sendResult();
      },
      setFinishMonth: function setFinishMonth(value) {
        this.set('dates.finishMonth', value);
        this.sendResult();
      },
      setFinishYear: function setFinishYear(value) {
        this.set('dates.finishYear', value);
        this.sendResult();
      }
    },

    sendResult: function sendResult() {
      var now = (0, _moment.default)();

      var startMonth = this.get('dates.startMonth');
      var startYear = this.get('dates.startYear');
      var finishMonth = this.get('dates.finishMonth');
      var finishYear = this.get('dates.finishYear');

      if (startMonth && startMonth.length === 1) {
        startMonth = '0' + startMonth;
      }

      if (finishMonth && finishMonth.length === 1) {
        finishMonth = '0' + finishMonth;
      }

      if (startYear && startYear.length === 1) {
        startYear = '0' + startYear;
      }

      if (finishYear && finishYear.length === 1) {
        finishYear = '0' + finishYear;
      }

      startYear = startYear ? '20' + startYear : now.format('YYYY');
      finishYear = finishYear ? '20' + finishYear : now.format('YYYY');

      var result = [];

      if (startMonth) {
        result.push(startYear + '-' + startMonth);
      }

      if (finishMonth) {
        result.push(finishYear + '-' + finishMonth);
      }

      this.get('handleChanged')(result);
    }
  });
});