define('cdata/services/comparable', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    userActions: Ember.inject.service(),

    item: null,

    upcomingOptions: Ember.Object.create(),

    parseItemForValueExplorer: function parseItemForValueExplorer(item) {
      return Ember.Object.create({
        imageLarge: item.object.artwork_assets && item.object.artwork_assets[0] && item.object.artwork_assets[0].original,
        imageSmall: item.object.artwork_assets && item.object.artwork_assets[0] && item.object.artwork_assets[0].thumbnail,
        makerYears: function () {
          var birth = item.object.artist_birth_date;
          var death = item.object.artist_death_date;

          if (birth && death) {
            return birth + '-' + death;
          }

          return birth || death;
        }(),
        title: item.object.artwork_title,
        name: item.object.artist_name,
        creation: item.object.artwork_creation,
        medium: item.object.artwork_materials,
        personId: item.person_id,
        organizationId: item.organizationId,
        type: item.person_id ? 'person' : 'organization',
        measurements: item.object.artwork_measurements,
        auctionHouse: item.object.auction_house_name,
        date: item.object.sale_date,
        entityId: item.entity_id,
        oldValue: item.object.price_usd,
        boughtValue: item.object.price_usd,
        boughtYear: item.object.sale_date ? (0, _moment.default)(item.object.sale_date).format('YYYY') : '',
        currency: item.object.original_currency,
        estimateLow: item.object.estimate_low,
        estimateHigh: item.object.estimate_high,
        estimateLowUsd: item.object.estimate_low_usd,
        estimateHighUsd: item.object.estimate_high_usd,
        makerId: item.person_id || item.organization_id,
        price: item.object.price,
        priceUsd: item.object.priceUsd
      });
    },
    parseEntityForValueExplorer: function parseEntityForValueExplorer(entity) {
      var date = entity.get('session.startDate');

      return Ember.Object.create({
        entityId: entity.get('id'),
        imageLarge: entity.get('imageUrl'),
        imageSmall: entity.get('imageUrl'),
        makerYears: null,
        title: entity.get('title'),
        name: entity.get('entityCreators.firstObject._title'),
        oldValue: entity.get('priceSale') ? parseInt(entity.get('priceSale')) : undefined,
        boughtValue: entity.get('priceSale') ? parseInt(entity.get('priceSale')) : undefined,
        boughtYear: date ? (0, _moment.default)(date).format('YYYY') : '',
        date: date,
        price: entity.get('price'),
        estimateLow: entity.get('estimateLow'),
        estimateHigh: entity.get('estimateHigh'),
        estimateLowUsd: entity.get('estimateLowUsd'),
        estimateHighUsd: entity.get('estimateHighUsd')
      });
    },
    queryUpcomingLots: function queryUpcomingLots(entityId, params, success) {
      params = params || {};
      params.entity_id = entityId;

      this.get('ajax').$get('upcoming_lots', params, function (response) {
        success(response.upcoming_lots.map(function (item) {
          return Ember.Object.create(item);
        }));
      });
    },
    initUpcomingOptions: function initUpcomingOptions(id) {
      var key = 'upcomingOptions.' + id;
      var options = this.get(key);

      if (!options) {
        this.set(key, this._createParams());
      }

      return this.get(key);
    },
    getUpcomingOptions: function getUpcomingOptions(id) {
      var forQuery = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var params = this.initUpcomingOptions(id);

      if (forQuery) {
        var result = {};
        var exist = false;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Object.entries(params)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _ref = _step.value;

            var _ref2 = _slicedToArray(_ref, 2);

            var key = _ref2[0];
            var value = _ref2[1];

            if (value) {
              result[key] = value;
              exist = true;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return exist ? result : null;
      }

      return params;
    },
    saveUpcomingOptions: function saveUpcomingOptions(params) {
      this.get('userActions').setUpcomingSettings(params);
    },
    _createParams: function _createParams() {
      return Ember.Object.create(this.get('userActions').getUpcomingSettings());
    }
  });
});