define('cdata/mixins/editor/event-bus', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(Ember.Evented, {
    _eventKeys: [],

    /**
     * Override for saving all event keys to local array
     * @param {String} name
     */
    on: function on(name) {
      this._super.apply(this, arguments);
      this._eventKeys.addObject(name);

      return this;
    },


    /**
     * Override for removing event keys from local array
     * @param {String} name
     */
    off: function off(name) {
      this._super.apply(this, arguments);
      this._eventKeys.removeObject(name);

      return this;
    },


    /**
     * Override for trigger all sub-keys events
     * @param {String} name
     * @param {Object} data
     *
     * @example
     *    modal.on('foo.bar')
     *    modal.on('foo.bar.extent')
     *    modal.trigger('foo.bar')
     *
     *    'foo.bar.extent' also will be fired when will trigger to 'foo.bar'
     *
     * @see services/modal-test.js
     */
    trigger: function trigger(name, data) {
      var _this = this;

      this._super.apply(this, arguments);

      // find and trigger all sub-events
      this._eventKeys.forEach(function (key) {
        if (key.indexOf(name + '.') === 0 && name !== key) {
          _this.trigger(key, data);
        }
      });

      return this;
    }
  });
});