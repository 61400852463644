define('cdata/mixins/components/item-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['show-page-header'],
    classNameBindings: ['large', 'small', 'middle'],

    bgrStyle: Ember.computed('image.url', function () {
      var url = this.get('image.url');
      if (url) {
        return new Ember.String.htmlSafe('background-image: url(' + url + ')');
      }

      return Ember.String.htmlSafe('');
    }),

    large: Ember.computed.equal('size', 'large'),
    small: Ember.computed.equal('size', 'small'),
    middle: Ember.computed.equal('size', 'middle'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var target = Ember.$(window);
      var header = Ember.$('.show-page-header');
      var main = Ember.$('main.main');
      var aside = main.find('aside');
      var asideTitle = Ember.$('aside .title');
      var diff = target.height() - header.height() - 55 + 100;
      if (aside.height() < diff) {
        aside.css('minHeight', diff + 'px');
      }

      var onScroll = function onScroll() {
        if (_this._debounce) {
          Ember.run.cancel(_this._debounce);
        }

        _this._debounce = Ember.run.debounce(_this, function () {
          var scroll = target.scrollTop();

          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          if (scroll < 130) {
            _this.set('size', 'large');
            main.css('marginTop', '0');
            header.css('position', 'relative').css('marginTop', '0');
            asideTitle.removeClass('fixed');
            aside.removeClass('fixed');
          }

          if (scroll >= 130 && scroll < 225) {
            _this.set('size', 'middle');
            main.css('marginTop', header.height() + 'px');
            header.css('position', 'fixed').css('marginTop', '-' + scroll + 'px');
            asideTitle.removeClass('fixed');
            aside.removeClass('fixed');
          }

          if (scroll >= 225) {
            _this.set('size', 'small');
            main.css('marginTop', header.height() + 'px');
            header.css('position', 'fixed').css('marginTop', '-225px');
            asideTitle.addClass('fixed');
            aside.addClass('fixed');
          }
        }, 10);
      };

      target.on('scroll.header-size', onScroll);
      onScroll();
    },
    willDestroyElement: function willDestroyElement() {
      var target = Ember.$(window);
      target.off('scroll.header-size');
    }
  });
});