define('cdata/mixins/lists/set-results-actions', ['exports', 'cdata/mixins/components/cdata-category'], function (exports, _cdataCategory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_cdataCategory.default, {
    classNames: ['inline-actions'],
    classNameBindings: ['editMode:edit-mode', 'edit-mode:editMode'],

    editMode: true,

    saveItem: 'saveItem',
    offEditMode: 'offEditMode',

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.sendAction('offEditMode', this.get('item'));
    },


    actions: {
      setMiCategory: function setMiCategory(subject) {
        this.set('item.miCategoryVocabularyItemId', subject.get('id'));
        this.save();
      },
      setCdataCategoryLocal: function setCdataCategoryLocal(category) {
        this.send('setCdataCategory', category);
        this.save();
      },
      toggleEditMode: function toggleEditMode() {
        this.set('editMode', !this.get('editMode'));
      },
      closeEditMode: function closeEditMode() {
        this.set('editMode', false);
      },
      hide: function hide(item) {
        item.set('shown', false);
        this.save();
      },
      show: function show(item) {
        item.set('shown', true);
        this.save();
      },
      approve: function approve(item) {
        item.set('approved', true);
        this.save();
      },
      unapprove: function unapprove(item) {
        item.set('approved', false);
        this.save();
      }
    },

    save: function save() {
      this.sendAction('saveItem', this.get('item'));
    }
  });
});