define('cdata/models/source-person-editor', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    sourceId: attr('string'),
    source: belongsTo('source'),
    personId: attr('string'),
    person: belongsTo('person'),
    _name: attr('string')
  });
});