define('cdata/models/organization', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    vocab: Ember.inject.service(),
    sidConstants: Ember.inject.service(),

    _place: attr('string'),
    _salesCount: attr('number'),
    _lotsCount: attr('number'),
    disambiguatingComment: attr('string'),
    organizationTitles: hasMany('organization-title'),
    organizationRoles: hasMany('organization-role'),
    organizationExistenceDates: hasMany('organization-existence-date'),
    organizationNationalities: hasMany('organization-nationality'),
    organizationAddresses: hasMany('organization-address'),
    organizationImages: hasMany('organization-image'),
    organizationWebsites: hasMany('organization-website'),
    organizationDescriptions: hasMany('organization-description'),
    organizationJdeDepartments: hasMany('organization-jde-department'),
    organizationChristiesSpecialistDepartments: hasMany('organization-christies-specialist-department'),
    uuid: attr('string'),
    approved: attr('boolean'),

    _title: Ember.computed('organizationTitles.@each.{preferred,text}', function () {
      var titles = this.get('organizationTitles');
      var title = titles && titles.findBy('preferred');

      return title && title.get('text');
    }),

    organizationRole: Ember.computed('organizationRoles.@each.{preferred,roleId}', function () {
      return this.get('organizationRoles').findBy('preferred');
    }),

    organizationNationality: Ember.computed('organizationNationalities.@each.{preferred,nationalityVocabularyItemId}', function () {
      return this.get('organizationNationalities').findBy('preferred');
    }),

    title: Ember.computed('organizationTitles.@each.{preferred,text}', function () {
      var title = this.get('organizationTitles').findBy('preferred');

      return title && title.get('text');
    }),

    role: Ember.computed('organizationRole.roleId', function () {
      var roleId = this.get('organizationRole.roleId');

      return roleId && this.get('vocab.rolesLabels')[roleId];
    }),

    nationality: Ember.computed('organizationNationality.nationalityVocabularyItemId', function () {
      var nationalityId = this.get('organizationNationality.nationalityVocabularyItemId');

      return nationalityId && this.get('vocab.nationalitiesLabels')[nationalityId];
    }),

    dates: Ember.computed('organizationExistenceDates.@each.{preferred,text}', function () {
      var dates = this.get('organizationExistenceDates').findBy('preferred');

      return dates && dates.get('text');
    }),

    longTitle: Ember.computed('title', 'role', 'nationality', 'dates', function () {
      var parts = [];

      if (this.get('title')) {
        parts.push(this.get('title'));
      }

      if (this.get('role')) {
        parts.push(this.get('role'));
      }

      if (this.get('nationality')) {
        parts.push(this.get('nationality'));
      }

      if (this.get('dates')) {
        parts.push(this.get('dates'));
      }

      return parts.join(', ');
    }),

    isAuctionHouse: Ember.computed('organizationRoles.@each.{preferred,roleId}', function () {
      return !!this.get('organizationRoles').findBy('roleId', this.get('sidConstants.auctionHouseId'));
    })
  });
});