define('cdata/pods/components/people/mixins/handle_new_person', ['exports', 'cdata/constants', 'cdata/helpers/person-summary-description'], function (exports, _constants, _personSummaryDescription) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modal: Ember.inject.service(),

    actions: {
      createPerson: function createPerson(title) {
        this.sendAction('openNewPersonForm', title, true);
        this._subscribeNewPerson();
      }
    },

    _subscribeNewPerson: function _subscribeNewPerson() {
      this.get('modal').on(_constants.MODALS.PERSON + '.saving.success', this, this._onPersonCreated).on(_constants.MODALS.PERSON + '.close', this, this._unSubscribeNewPerson);
    },
    _unSubscribeNewPerson: function _unSubscribeNewPerson() {
      this.get('modal').off(_constants.MODALS.PERSON + '.saving.success', this, this._onPersonCreated).off(_constants.MODALS.PERSON + '.close', this, this._unSubscribeNewPerson);
    },
    _onPersonCreated: function _onPersonCreated(result) {
      var person = result.item;

      // success result is person object
      var name = person && person.get('personNames.firstObject');
      name = name && name.get('text');

      // add person-based subject to list
      this.send('setPerson', this.get('model'), Ember.Object.create({
        id: person.get('id'),
        name: name,
        description: _personSummaryDescription.default.compute([person, this.get('vocab.nationalitiesLabels')]),
        type: 'person'
      }));
    }
  });
});