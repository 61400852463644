define('cdata/pods/components/entities/edit/edit-forms-gems-relations/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('JewelryGem'), {
    sidConstants: Ember.inject.service(),
    gems: Ember.computed.filterBy('model.entityJewelryGems', 'isDeleted', false),
    gemIds: Ember.computed.mapBy('gems', 'entityGemId'),
    autoCreating: false,
    subjectFilter: Ember.computed(function () {
      return {
        cdata_category_ids: [this.get('sidConstants').get('gemCategoryId')]
      };
    })
  });
});