define('cdata/pods/init/login/route', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    sidConstants: Ember.inject.service(),
    vocab: Ember.inject.service(),
    flashMessage: Ember.inject.service(),
    userActions: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (this.get('session.hasAuth')) {
        transition.abort();
        this.transitionTo('init.loader');
      }
    },


    actions: {
      logIn: function logIn(email, password) {
        var _this = this;

        this.get('controller').set('inProcess', true);
        Ember.$.ajax({
          type: 'POST',
          url: _constants.API_URL + '/login',
          data: JSON.stringify({ email: email, password: password }),
          contentType: 'application/json',
          success: function success(response) {
            _this.get('session').set('key', response.api_key);
            _this.get('userActions').get('user').set('id', response.user.id + '');
            _this.get('sidConstants').reloadSids();
            _this.transitionTo('init.loader');
          },
          dataType: 'JSON',
          complete: function complete() {
            _this.get('controller').set('inProcess', false);
          }
        }).fail(function () {
          _this.get('flashMessage').showError('Login error. Check email/password or try later.');
        });
      }
    }
  });
});