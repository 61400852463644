define('cdata/pods/components/people/list/set-filters/component', ['exports', 'cdata/mixins/lists/component-filter'], function (exports, _componentFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentFilter.default, {
    fields: ['query', 'approved', 'onlyNameFilled', 'exactMatch', 'activityStart', 'activityFinish', 'vitalStart', 'vitalFinish', 'roleIds', 'nationalityIds', 'awardIds', 'jdeDepartmentIds', 'christiesSpecialistDepartmentIds', 'jdeThreeDigitCodeIds', 'relations'],

    roles: [],
    nationalities: [],
    awards: [],
    jdeDepartments: [],
    christiesSpecialistDepartments: [],
    jdeThreeDigitCodes: [],
    person: null,

    jdeDepartmentIds: Ember.computed.mapBy('jdeDepartments', 'id'),
    christiesSpecialistDepartmentIds: Ember.computed.mapBy('christiesSpecialistDepartments', 'id'),
    jdeThreeDigitCodeIds: Ember.computed.mapBy('jdeThreeDigitCodes', 'id'),
    roleIds: Ember.computed.mapBy('roles', 'id'),
    awardIds: Ember.computed.mapBy('awards', 'id'),
    nationalityIds: Ember.computed.mapBy('nationalities', 'id'),

    initFilter: function initFilter() {
      this._super();
      this.initFilterSubjects('roleIds', 'role', 'roles');
      this.initFilterSubjects('jdeDepartmentIds', 'jdeDepartment', 'jdeDepartments');
      this.initFilterSubjects('christiesSpecialistDepartmentIds', 'christiesSpecialist', 'christiesSpecialistDepartments');
      this.initFilterSubjects('jdeThreeDigitCodeIds', 'threeDigitCode', 'jdeThreeDigitCodes');
      this.initFilterSubjects('nationalityIds', 'nationality', 'nationalities');
      this.initFilterSubjects('awardIds', 'award', 'awards');
      this.initFilterRelations();
    },
    initFilterRelations: function initFilterRelations() {
      this.loadRelationSubjects();
      this.set('model.relations', this.formatRelationsToArray());
    },


    actions: {
      clear: function clear() {
        this.setProperties({
          roles: [],
          nationalities: [],
          awards: [],
          jdeDepartments: [],
          christiesSpecialistDepartments: [],
          jdeThreeDigitCodes: []
        });
        this._super();
      },
      setNotApproved: function setNotApproved() {
        var value = false === this.get('model.approved') ? null : false;
        this.set('model.approved', value);
        this.applyFilter();
      },
      setApproved: function setApproved() {
        var value = true === this.get('model.approved') ? null : true;
        this.set('model.approved', value);
        this.applyFilter();
      },
      addRelation: function addRelation(relation) {
        this.get('relations').pushObject(relation);
        this.set('model.relations', this.formatRelationsToArray());
        this.applyFilter();
      },
      removeRelation: function removeRelation(item) {
        this.get('relations').removeObject(this.get('relations')[parseInt(item.get('id'))]);
      },
      setSearch: function setSearch(text) {
        this.set('model.query', text);
        this.applyFilter();
      },
      setId: function setId(text) {
        this.set('model.id', text);
        this.applyFilter();
      },
      setHasOnlyNameFilled: function setHasOnlyNameFilled() {
        var value = this.get('model.onlyNameFilled') ? null : true;
        this.set('model.onlyNameFilled', value);
        this.applyFilter();
      },
      setExactMatch: function setExactMatch() {
        var value = this.get('model.exactMatch') ? null : true;
        this.set('model.exactMatch', value);
        this.applyFilter();
      },
      setVitalDates: function setVitalDates(start, finish) {
        this.set('model.vitalStart', start);
        this.set('model.vitalFinish', finish);
        this.applyFilter();
      },
      setActivityDates: function setActivityDates(start, finish) {
        this.set('model.activityStart', start);
        this.set('model.activityFinish', finish);
        this.applyFilter();
      }
    }
  });
});