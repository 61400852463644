define('cdata/pods/components/sessions/edit/edit-forms-properties/component', ['exports', 'cdata/pods/components/sessions/edit/mixins/edit-forms', 'moment'], function (exports, _editForms, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), {
    loadLocationFor: 'loadLocationFor',

    dateRangeShow: true,

    actions: {
      setLocation: function setLocation(_, locationSubject) {
        this.sendAction('loadLocationFor', this.get('model'), locationSubject.get('id'));
      },
      setStartDate: function setStartDate(date) {
        date = date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null;
        if (!date || !this.get('model.finishDate') || this.get('model.finishDate') <= date) {
          this.set('model.finishDate', date);
        }
        this.set('model.startDate', date);
      },
      setFinishDate: function setFinishDate(date) {
        var _this = this;

        date = date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null;
        if (date && date < this.get('model.startDate')) {
          this.set('model.finishDate', this.get('model.startDate'));
          Ember.run.later(this, function () {
            _this._blur();
          }, 10);

          return;
        }
        this.set('model.finishDate', date);
      }
    },

    _blur: function _blur() {
      var _this2 = this;

      Ember.run.schedule('afterRender', this, function () {
        _this2.set('dateRangeShow', false);
        Ember.run.later(_this2, function () {
          _this2._show();
        }, 10);
      });
    },
    _show: function _show() {
      var _this3 = this;

      Ember.run.schedule('afterRender', this, function () {
        _this3.set('dateRangeShow', true);
      });
    }
  });
});