define('cdata/pods/components/vocabularies/modals/add-item-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var eventKey = _constants.MODALS.VOCAB_ITEM_ADD + '.saving';

  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.VOCAB_ITEM_ADD,
    classNames: ['context-modal', _constants.MODALS.VOCAB_ITEM_ADD, 'edit-form'],
    inProcess: false,

    title: Ember.computed('model.vocabName', function () {
      return this.get('model.vocabName');
    }),

    actions: {
      save: function save() {
        var model = this.get('model');
        this.sendAction('saveVocabularyNewItemForm', model);
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeVocabularyNewItemForm');
      }
    },

    _initItem: function _initItem() {
      var item = Ember.Object.create();
      this.set('item', item);
    },
    _onProcess: function _onProcess() {
      this.set('inProcess', true);
    },
    _onFinally: function _onFinally() {
      this.set('inProcess', false);
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this._initItem();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('modal').on(eventKey + '.process', this, this._onProcess).on(eventKey + '.finally', this, this._onFinally);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      this.get('modal').off(eventKey + '.process', this, this._onProcess).off(eventKey + '.finally', this, this._onFinally);
    }
  });
});