define('cdata/pods/entities/show/route', ['exports', 'cdata/pods/entities/entity-mixin-show', 'cdata/pods/entities/parser-mixin', 'cdata/pods/entities/upcoming-lots-mixin', 'cdata/pods/entities/review-mixin', 'cdata/mixins/audit-logs/audit-log-route'], function (exports, _entityMixinShow, _parserMixin, _upcomingLotsMixin, _reviewMixin, _auditLogRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_entityMixinShow.default, _upcomingLotsMixin.default, _parserMixin.default, _reviewMixin.default, _auditLogRoute.default, {
    withLot: false,

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.initUpcomingLots(controller, model);
      this.initEntityReview(controller, model);
    }
  });
});