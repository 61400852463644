define('cdata/pods/components/site/list/bulk-operations/subject-with-rule/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    setRule: 'setRule',
    setAssign: 'setAssign',
    assignSubject: null,

    isCreator: Ember.computed.equal('subjectName', 'creator'),

    actions: {
      setRule: function setRule() {
        this.sendAction.apply(this, ['setRule'].concat(Array.prototype.slice.call(arguments)));
      },
      setAssign: function setAssign() {
        this.sendAction.apply(this, ['setAssign'].concat(Array.prototype.slice.call(arguments)));
        if (this.get('isCreator')) {
          this.setCreatorTitles();
        }
      }
    },

    setCreatorTitles: function setCreatorTitles() {
      var creator = this.get('assignSubject');
      if (creator.get('type') === 'person') {
        creator.set('_personName', creator.get('name'));
      }
      if (creator.get('type') === 'organization') {
        creator.set('_organizationTitle', creator.get('name'));
      }
    }
  });
});