define('cdata/pods/components/entities/view/predicted-model/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'main',
    classNames: 'main sale-item',
    error: null,
    inProgress: false,
    options: false,

    reloadPredictedModel: 'reloadPredictedModel',

    actions: {
      reload: function reload() {
        this.sendAction('reloadPredictedModel');
      }
    },

    bi: Ember.computed('model.outcomes.bi', function () {
      return Math.floor(this.get('model.outcomes.bi') * 100, 2);
    }),

    biStyle: Ember.computed('bi', function () {
      return Ember.String.htmlSafe("width: " + (this.get('bi') - 3) + "%");
    }),

    belowLowEstimate: Ember.computed('model.outcomes.below_low', function () {
      return Math.floor(this.get('model.outcomes.below_low') * 100, 2);
    }),

    belowLowEstimateStyle: Ember.computed('belowLowEstimate', function () {
      return Ember.String.htmlSafe("width: " + (this.get('belowLowEstimate') - 3) + "%");
    }),

    withinEstimates: Ember.computed('model.outcomes.within', function () {
      return Math.floor(this.get('model.outcomes.within') * 100, 2);
    }),

    withinEstimatesStyle: Ember.computed('withinEstimates', function () {
      return Ember.String.htmlSafe("width: " + (this.get('withinEstimates') - 3) + "%");
    }),

    aboveHighEstimate: Ember.computed('model.outcomes.above_high', function () {
      return Math.floor(this.get('model.outcomes.above_high') * 100, 2);
    }),

    aboveHighEstimateStyle: Ember.computed('aboveHighEstimate', function () {
      return Ember.String.htmlSafe("width: " + (this.get('aboveHighEstimate') - 3) + "%");
    })
  });
});