define('cdata/pods/components/sessions/view/session-item/summary-info/component', ['exports', 'moment', 'cdata/services/natural-sorter'], function (exports, _moment, _naturalSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'entity-item--info',
    tagName: 'article',
    openSessionForms: 'openSessionForms',
    loadLocationFor: 'loadLocationFor',
    addSessionToSale: 'addSessionToSale',
    createLotAndEntity: 'createLotAndEntity',

    createLotInProcess: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._loadLocation();
    },


    actions: {
      openForms: function openForms() {
        this.sendAction.apply(this, ['openSessionForms'].concat(Array.prototype.slice.call(arguments)));
      },
      addSession: function addSession() {
        this.sendAction('addSessionToSale', this.get('model.sale'));
      },
      addLot: function addLot() {
        this.set('createLotInProcess', true);
        this.sendAction('createLotAndEntity', this.get('model.sale'), this.get('model'));
      }
    },

    _loadLocation: function _loadLocation() {
      var model = this.get('model');
      this.sendAction('loadLocationFor', model, model.get('locationId'));
    },


    preferredTitle: Ember.computed('model.sessionTitles.@each.{preferred,text}', function () {
      var title = this.get('model.sessionTitles').filterBy('preferred').get('firstObject');

      return title && title.get('text');
    }),

    otherTitles: Ember.computed('model.sessionTitles.@each.{preferred,text}', function () {
      var titles = this.get('model.sessionTitles').rejectBy('preferred');

      return titles.mapBy('text');
    }),

    sessionFormattedTime: Ember.computed('model.{startTime,startDate}', function () {
      var time = this.get('model.startTime');
      var date = this.get('model.startDate');

      if (!time || !date) {
        return '';
      }

      var timeParts = time.split('.');

      if (timeParts.length === 1) {
        timeParts.push('00');
      }

      if (!timeParts[1]) {
        timeParts[1] = '00';
      }

      if (timeParts[0].length === 1) {
        timeParts[0] = '0' + timeParts[0];
      }

      time = timeParts.join('.');

      time = time.replace('.', ':') + ':00';
      date = date + ' ' + time;

      if ((0, _moment.default)(date).isValid()) {
        return (0, _moment.default)(date).format('kk:mm');
      }

      return '';
    }),

    sessionsRaw: Ember.computed('model.sale.sessions.@each.startDate', function () {
      return this.get('model.sale.sessions');
    }),

    sessions: Ember.computed.sort('sessionsRaw', (0, _naturalSorter.default)('startDate'))
  });
});