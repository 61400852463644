define('cdata/models/entity-gem-jewelry', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    entityJewelry: belongsTo('entity'),
    entityJewelryId: attr('string'),
    entityGem: belongsTo('entity'),
    entityGemId: attr('string')
  });
});