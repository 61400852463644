define('cdata/pods/components/comparables/custom-estimate/form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['four wide column'],

    runEstimates: 'runEstimates',
    applyEstimatesParams: 'applyEstimatesParams',

    actions: {
      runEstimates: function runEstimates() {
        this.sendAction.apply(this, ['runEstimates'].concat(Array.prototype.slice.call(arguments)));
      },
      applyEstimatesParams: function applyEstimatesParams() {
        this.sendAction.apply(this, ['applyEstimatesParams'].concat(Array.prototype.slice.call(arguments)));
      },
      setWeighted: function setWeighted(event) {
        this.set('params.weighted', event.target.value);
      }
    }
  });
});