define('cdata/pods/components/organizations/list/sort-box/component', ['exports', 'cdata/mixins/lists/component-sort-box'], function (exports, _componentSortBox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentSortBox.default, {
    values: [Ember.Object.create({
      title: 'Name',
      key: 'title'
    }), Ember.Object.create({
      title: 'Existence date',
      key: 'existence_date_start'
    }), Ember.Object.create({
      title: 'Sales count',
      key: 'sales_count'
    }), Ember.Object.create({
      title: 'Lots count',
      key: 'lots_count'
    }), Ember.Object.create({
      title: 'Lots total usd',
      key: 'lots_total_usd'
    }), Ember.Object.create({
      title: 'Modified',
      key: 'updated_at'
    })]
  });
});