define('cdata/pods/components/places/list/set-filters/component', ['exports', 'cdata/mixins/lists/component-filter'], function (exports, _componentFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentFilter.default, {
    fields: ['query', 'parentId'],

    parent: null,

    initFilter: function initFilter() {
      var _this = this;

      this._super();

      var parentId = this.get('model.parentId');
      if (parentId) {
        this.get('subjectSearch').getByIds([parentId], 'place').then(function (items) {
          _this.set('parent', items.get('firstObject'));
        });
      }
    },


    actions: {
      clear: function clear() {
        this.setProperties({
          parent: null
        });
        this._super();
      },
      setParent: function setParent(_, place) {
        this.set('parent', place);
        this.set('model.parentId', place.get('id'));
        this.applyFilter();
      }
    }
  });
});