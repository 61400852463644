define('cdata/storages/predicted-prices', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _object.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      var date = new Date();
      date.setMonth(date.getMonth() + 1);

      var day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      var defaultDate = date.getFullYear() + '-' + month + '-' + day;

      return {
        settings: {
          enabled: false,
          auctionHouseId: 35,
          locationId: 70,
          locationTitle: "Christie's - New York",
          auctionHouseTitle: "Christie's",
          saleTitle: 'Day Sale',
          saleDate: defaultDate,
          lowEstimate: null,
          highEstimate: null,
          inProgress: false,
          currencyId: 597,
          saleNumber: null,
          lotNumber: "1",
          reservePrice: "1"
        }
      };
    }
  });

  exports.default = Storage;
});