define('cdata/pods/components/lots/edit/edit-forms-sale-session/component', ['exports', 'cdata/pods/components/lots/edit/mixins/edit-forms', 'cdata/pods/components/lots/lot-properties-mixin'], function (exports, _editForms, _lotPropertiesMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), _lotPropertiesMixin.default, {
    autoCreating: false,

    loadSaleFor: 'loadSaleFor',
    changeLotSession: 'changeLotSession',

    saleId: null,
    sale: null,
    _saleId: null,
    _sale: null,
    _session: null,

    saleTitle: Ember.computed.alias('_sale.title'),

    sessionsItems: Ember.computed('_sale.id', '_sale.sessions.@each.id', function () {
      var sessions = this.get('_sale.sessions');
      if (!sessions) {
        return [];
      }

      return sessions.map(function (session) {
        return Ember.Object.create({
          id: session.get('id'),
          label: session.get('title'),
          session: session
        });
      });
    }),

    sessionId: Ember.computed('model.sessionId', '_session.id', function () {
      return this.get('_session.id') || this.get('model.sessionId');
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('saleId', this.get('sale.id'));
      this.set('_saleId', this.get('sale.id'));
      this.set('_sale', this.get('sale'));
    },


    saleChanged: Ember.observer('saleId', function () {
      this._super.apply(this, arguments);

      if (this.get('_sale.id') === this.get('_saleId')) {
        return;
      }

      var sessions = this.get('_sale.sessions');
      if (!sessions || sessions.get('length') === 0) {
        return;
      }

      this.set('_saleId', this.get('_sale.id'));

      if (sessions.get('length') === 1) {
        this.send('setSession', Ember.Object.create({
          session: sessions.get('firstObject')
        }));
      }
    }),

    actions: {
      setSale: function setSale(_, saleSubject) {
        this.sendAction('loadSaleFor', this, saleSubject.get('id'), '_sale');
      },
      setSession: function setSession(item) {
        if (!item) {
          this.set('_session', null);

          return;
        }
        this.set('_session', item.get('session'));
      },
      save: function save() {
        this.sendAction('changeLotSession', this.get('model'), this.get('_session'));
        this.sendAction.apply(this, ['save'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});