define('cdata/helpers/format-award', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatAward = formatAward;

  var escape = Ember.Handlebars.Utils.escapeExpression;

  function formatAward(params) {
    var relation = params[0];
    var needHtml = typeof params[1] === 'boolean' ? params[1] : true;

    var award = escape(relation.get('_name'));
    var year = escape(relation.get('givingYear'));
    var giving = escape(relation.get('_givingName'));
    var event = escape(relation.get('_eventName'));

    var result = '';

    if (award) {
      if (needHtml) {
        result += '<span class="strong">' + award + '</span>';
      } else {
        result += award;
      }
    }

    if (year) {
      result += ', ' + year;
    }

    if (giving && event) {
      result += ' (' + event + ', ' + giving + ')';
    } else if (giving || event) {
      result += ' (' + (giving || event) + ')';
    }

    if (needHtml) {
      return result.htmlSafe();
    }

    return result;
  }

  exports.default = Ember.Helper.helper(formatAward);
});