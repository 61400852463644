define('cdata/pods/components/people/view/person-header/component', ['exports', 'cdata/mixins/components/item-header'], function (exports, _itemHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_itemHeader.default, {
    classNames: ['people-person-header'],

    prevNextPeopleEditor: Ember.inject.service(),
    prevNextEditor: Ember.computed.alias('prevNextPeopleEditor'),

    openPersonForms: 'openPersonForms',

    showFieldActions: false,

    name: Ember.computed('model.personNames.@each.{preferred,text}', function () {
      var title = this.get('model.personNames').filterBy('preferred').get('firstObject');

      return title ? title.get('text') : '';
    }),

    image: Ember.computed('model.personImages.@each.{preferred,image}', function () {
      var image = this.get('model.personImages').filterBy('preferred').get('firstObject');

      return image && image.get('image');
    }),

    actions: {
      showImagesForms: function showImagesForms() {
        this.sendAction('openPersonForms', 'edit-forms-image-relations');
      },
      openPersonForms: function openPersonForms() {
        this.sendAction.apply(this, ['openPersonForms'].concat(Array.prototype.slice.call(arguments)));
      }
    },

    objectFilter: Ember.computed('model.id', function () {
      return '(creators*' + this.get('model.id') + ',person)';
    })
  });
});