define('cdata/pods/components/d3-zoomable-sunburst/component', ['exports', 'd3', 'ember-cli-jss', 'cdata/pods/components/d3-zoomable-sunburst/stylesheet', 'cdata/pods/components/d3-zoomable-sunburst/declaration', 'cdata/pods/components/d3-zoomable-sunburst/utils'], function (exports, _d, _emberCliJss, _stylesheet, _declaration, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var width = 600;
  var radius = width / 6;
  var viewBox = '0 0 ' + width + ' ' + width;
  var zoomedViewBox = radius + ' ' + radius + ' ' + (width - 2 * radius) + ' ' + (width - 2 * radius);
  var labelTransform = (0, _utils.makeLabelTransform)(radius);

  exports.default = Ember.Component.extend(_emberCliJss.default, {
    stylesheet: _stylesheet.default,
    jssNames: ['root'],
    jssObservedProps: ['width'],

    propTypes: _declaration.default,

    width: width,

    didRender: function didRender() {
      this.renderSunburst();
    },
    renderSunburst: function renderSunburst() {
      var _this = this;

      var data = this.get('data');
      var onNodeHover = this.get('onNodeHover');
      var onNodeLeave = this.get('onNodeLeave');

      var handleNodeHover = (0, _utils.whenElementInteractable)(function (node, element) {
        var selection = _d.default.select(element);

        selection.append('title').text(_utils.getTitle);
        selection.filter(function (d) {
          return d.children;
        }).classed(_this.classes.clickable, true);

        onNodeHover(node);
      });

      var handleNodeLeave = (0, _utils.whenElementInteractable)(function (node, element) {
        var selection = _d.default.select(element);

        selection.selectAll('*').remove();
        selection.filter(function (d) {
          return d.children;
        }).classed(_this.classes.clickable, false);

        onNodeLeave(node);
      });

      var arc = (0, _utils.makeArc)(radius);
      var onNodeSelect = this.get('onNodeSelect');

      var handleClick = (0, _utils.whenElementInteractable)(function (node) {
        var currentRoot = circle.datum();
        var nextRoot = node === currentRoot ? node.parent || root : node;

        circle.datum(nextRoot);

        root.each(function (d) {
          return d.target = (0, _utils.getTargetСoordinates)(d, nextRoot);
        });

        if ((0, _utils.isNotLastLevel)(nextRoot)) {
          svg.transition().duration(150).attr('viewBox', viewBox);
        }

        var t = container.transition().duration(600).on('end', function () {
          if ((0, _utils.isLastLevel)(nextRoot)) {
            svg.transition().duration(400).attr('viewBox', zoomedViewBox);
          }
        });

        // Transition the data on all arcs, even the ones that aren’t visible,
        // so that if this transition is interrupted, entering arcs will start
        // the next transition from the desired position.
        path.transition(t).tween('data', function (d) {
          var i = _d.default.interpolate(d.current, d.target);

          return function (t) {
            return d.current = i(t);
          };
        }).filter(function (d) {
          return +this.getAttribute('fill-opacity') || (0, _utils.arcVisible)(d.target);
        }).attr('fill-opacity', function (d) {
          return (0, _utils.arcVisible)(d.target) ? 1 : 0;
        }).attrTween('d', function (d) {
          return function () {
            return arc(d.current);
          };
        });

        label.filter(function (d) {
          return +this.getAttribute('fill-opacity') || (0, _utils.labelVisible)(d.target);
        }).transition(t).attr('fill-opacity', function (d) {
          return +(0, _utils.labelVisible)(d.target);
        }).attrTween('transform', function (d) {
          return function () {
            return labelTransform(d.current);
          };
        });

        circle.transition(t).attrTween('fill', function (d) {
          return _d.default.interpolateRgb(currentRoot.color, d.color);
        }).attr('class', function (d) {
          return d.parent ? _this.classes.clickable : null;
        }).select('title').text(_utils.getTitle);

        nextRoot.parent ? insertDescription(nextRoot) : insertRootDescription(nextRoot);

        onNodeSelect(nextRoot);
      });

      var onLeave = this.get('onLeave');

      var root = (0, _utils.makeTree)(data);

      var svg = _d.default.select(this.element).select('svg').attr('viewBox', viewBox);

      svg.selectAll('*').remove();

      var container = svg.append('g').attr('class', this.classes.container).on('mouseleave', onLeave);

      var path = container.append('g').selectAll('path').data(root.descendants().slice(1)).join('path').attr('fill', function (d) {
        return d.color;
      }).attr('fill-opacity', function (d) {
        return (0, _utils.arcVisible)(d.current) ? 1 : 0;
      }).attr('d', function (d) {
        return arc(d.current);
      });

      path.on('mouseenter', handleNodeHover).on('mouseleave', handleNodeLeave).filter(function (d) {
        return d.children;
      }).on('click', handleClick);

      var label = container.append('g').attr('class', this.classes.labelsContainer).selectAll('g').data(root.descendants().slice(1)).join('g').attr('dy', '0.35em').attr('fill-opacity', function (d) {
        return +(0, _utils.labelVisible)(d.current);
      }).attr('transform', function (d) {
        return labelTransform(d.current);
      });

      var labelClass = this.classes.label;


      label.each(function (d) {
        var selection = _d.default.select(this).append('text');

        d.data.setupLabel ? d.data.setupLabel(selection) : (0, _utils.setupLabelText)(selection, labelClass);
      });

      var circle = container.append('circle').on('mouseenter', handleNodeHover).on('mouseleave', handleNodeLeave).datum(root).attr('r', radius - 1).attr('fill', root.color).on('click', handleClick);

      var circleTitle = container.append('g').attr('class', this.classes.circleTitleWrapper);

      var insertRootDescription = function insertRootDescription(node) {
        circleTitle.selectAll('*').remove();

        circleTitle.append('text').text(node.data.name);
      };

      var insertDescription = function insertDescription(node) {
        circleTitle.selectAll('*').remove();

        circleTitle.append('text').text(node.data.name);

        circleTitle.append('text').attr('class', _this.classes.circleSubtitle).text('(click here to drill up)');
      };

      insertRootDescription(root);

      onNodeSelect(root);
    },


    getDefaultProps: function getDefaultProps() {
      return {
        onNodeHover: function onNodeHover() {
          return null;
        },
        onNodeLeave: function onNodeLeave() {
          return null;
        },
        onNodeSelect: function onNodeSelect() {
          return null;
        },
        onLeave: function onLeave() {
          return null;
        }
      };
    }
  });
});