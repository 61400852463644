define('cdata/pods/components/entities/edit/edit-forms-shapes/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Shape'), {
    shapes: Ember.computed.filterBy('model.entityShapes', 'isDeleted', false),
    shapeIds: Ember.computed.mapBy('shapes', 'shapeVocabularyItemId'),
    autoCreating: false
  });
});