define("cdata/pods/components/comparables/collectrium-estimate-chart/collectrium-estimate", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (_data) {

    d3.select("#value-chart").select("svg").remove();

    if (!_data.time) {
      return;
    }

    var old_value = _data.old_value; //10000;
    var currency = _data.currency; //'$';
    var bought_year = _data.bought_year.toString(); //'2013';
    var index = _data.index;
    var time = _data.time;

    var ac_index = time.indexOf(bought_year);
    if (ac_index === -1) {
      return;
    }

    var start_index = ac_index - (index.length - 1 - ac_index);
    if (start_index < 0) {
      start_index = 0;
    } else if (index.length - start_index < 5) {
      start_index = index.length - 5;
    }
    var indexes = index.slice(start_index, index.length);
    time = time.slice(start_index, index.length);

    var format = d3.time.format("%Y");
    var start = format.parse(time[0]);
    var end = format.parse(String(parseInt(time[time.length - 1]) + 1));
    var dataList = d3.time.years(start, end);

    // Set up basic graph setting
    var height = 300,
        width = 400;

    // For value Explorer
    var value_svg = d3.select("#value-chart").append("svg").attr("width", width).attr("height", height);

    var value_margin = { top: 40, right: 10, bottom: 30, left: 20 },
        value_width = width - value_margin.left - value_margin.right,
        value_height = height - value_margin.top - value_margin.bottom;

    var value_x = d3.time.scale().range([0, value_width]),
        value_y = d3.scale.linear().range([value_height, 0]);

    var value_xAxis = d3.svg.axis().scale(value_x).orient("bottom").ticks(d3.time.years).tickFormat(d3.time.format('%Y')).ticks(5);

    var value_focus = value_svg.append("g").attr("class", "focus").attr("transform", "translate(" + value_margin.left + "," + value_margin.top + ")");

    var gradient = value_svg.append("defs").append("linearGradient").attr("id", "gradient").attr("x1", "100%").attr("y1", "0%").attr("x2", "100%").attr("y2", "100%").attr("spreadMethod", "pad");

    gradient.append("svg:stop").attr("offset", "0%").attr("stop-color", "#737373").attr("stop-opacity", 1);

    gradient.append("svg:stop").attr("offset", "100%").attr("stop-color", "#ffffff").attr("stop-opacity", 1);

    var sgradient = value_svg.append("defs").append("linearGradient").attr("id", "sgradient").attr("x1", "100%").attr("y1", "0%").attr("x2", "100%").attr("y2", "100%").attr("spreadMethod", "pad");

    sgradient.append("svg:stop").attr("offset", "0%").attr("stop-color", "#41ab5d").attr("stop-opacity", 1);

    sgradient.append("svg:stop").attr("offset", "100%").attr("stop-color", "#ffffff").attr("stop-opacity", 1);

    var max_v = 0,
        min_v = 0;
    var valueData = [],
        sData = [],
        fData = [],
        isData = [],
        ifData = [];
    for (var i = 0; i < indexes.length; i++) {
      var element = {};

      max_v = Math.max(max_v, indexes[i]);

      if (min_v === 0) {
        min_v = indexes[i];
      } else {
        min_v = Math.min(min_v, indexes[i]);
      }

      element['date'] = dataList[i];
      element['value'] = indexes[i];
      valueData.push(element);
      if (i < ac_index - start_index) {
        fData.push(element);
        ifData.push({ 'date': dataList[i], 'value': 0 });
      } else if (i === ac_index - start_index) {
        sData.push(element);
        isData.push({ 'date': dataList[i], 'value': 0 });
        ifData.push({ 'date': dataList[i], 'value': 0 });
        fData.push(element);
      } else {
        isData.push({ 'date': dataList[i], 'value': 0 });
        sData.push(element);
      }
    }

    value_x.domain(d3.extent(valueData.map(function (d) {
      return d.date;
    })));
    value_y.domain([min_v - 5, max_v]);

    value_focus.append("g").attr("class", "x axis").attr("transform", "translate(0," + value_height + ")").call(value_xAxis);

    var value_area = d3.svg.area().interpolate("monotone").x(function (d) {
      return value_x(d.date);
    }).y0(value_height).y1(function (d) {
      return value_y(d.value);
    });

    value_focus.append("defs").append("clipPath").attr("id", "clip").append("rect").attr("width", value_width).attr("height", value_height);

    value_focus.append("path").datum(sData).attr("class", "area").style("fill", "url(#sgradient)").attr("d", value_area(isData)).transition().duration(750).attr("d", value_area);

    value_focus.append("path").datum(fData).attr("class", "area").style("fill", "url(#gradient)").attr("d", value_area(ifData)).transition().duration(750).attr("d", value_area);

    var line = d3.svg.line().interpolate("monotone").x(function (d) {
      return value_x(d.date);
    }).y(function (d) {
      return value_y(d.value);
    });

    var oline = d3.svg.line().interpolate("monotone").x(function (d) {
      return value_x(d.date);
    }).y(function () {
      return value_y(min_v);
    });

    value_focus.append("path").datum(fData).attr("class", "line").attr("d", oline).transition().duration(750).attr("d", line).style('fill', 'transparent').attr('stroke', '#969696').attr('stroke-width', 2);

    value_focus.append("path").datum(sData).attr("class", "line").attr("d", oline).transition().duration(750).attr("d", line).attr('stroke', '#74c476').style('fill', 'transparent').attr('stroke-width', 2);

    value_focus.append("circle").attr("cx", value_x(dataList[ac_index - start_index])).attr("cy", value_y(indexes[ac_index - start_index])).attr("r", 0).style("fill", "#e5f5e0").transition().duration(750).attr("r", 10);

    value_focus.append("circle").attr("cx", value_x(dataList[ac_index - start_index])).attr("cy", value_y(indexes[ac_index - start_index])).attr("r", 0).style("fill", "#41ab5d").transition().duration(750).attr("r", 5);

    value_focus.append("text").attr("x", function () {
      if (ac_index === index.length - 1) {
        return value_x(dataList[ac_index - start_index]) - 20;
      } else if (ac_index === 0) {
        return value_x(dataList[ac_index - start_index]) + 20;
      } else {
        return value_x(dataList[ac_index - start_index]);
      }
    }).attr("y", function () {
      if (value_y(indexes[ac_index - start_index]) > 200) {
        return value_y(indexes[ac_index - start_index]) - 20;
      } else {
        return value_y(indexes[ac_index - start_index]) + 20;
      }
    }).attr("font-size", "14px").attr("font-weight", "bold").attr('text-anchor', 'middle').text('Acquired');

    var odiff = ((indexes[indexes.length - 1] - indexes[ac_index - start_index]) / indexes[ac_index - start_index] * 100).toFixed(1);
    var diff = void 0;

    if (odiff >= 0) {
      diff = '+' + odiff + '%';
    } else {
      diff = odiff + '%';
    }

    var value_text = d3.select("#value-number");
    var n_value = Math.round(old_value * (1 + odiff / 100) / 100) * 100;

    value_text.transition().duration(750).text(currency + toCommas(n_value));

    value_focus.append("text").attr("x", value_x(dataList[dataList.length - 1])).attr("y", -10).attr("font-size", "16px").attr("font-weight", "bold").attr('text-anchor', 'end').style("fill", "#41ab5d").text(diff);

    function toCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };
});