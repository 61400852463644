define('cdata/pods/components/entities/edit/edit-forms-descriptions/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms', 'cdata/mixins/components/manage-source-actions'], function (exports, _editForms, _manageSourceActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Description'), _manageSourceActions.default, {
    descriptions: Ember.computed.filterBy('model.entityDescriptions', 'isDeleted', false),

    parseEntityText: 'parseEntityText',

    actions: {
      parseEntityText: function parseEntityText(entityDescription) {
        this.sendAction('parseEntityText', entityDescription);
      }
    }
  });
});