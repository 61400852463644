define("cdata/mirage/datasets/sales", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'undefined': {
      "sale": {
        "id": "1"
      }
    },
    '49299': {
      "sessions": [{
        "start_date": "2002-09-25",
        "source_url": "Doyle",
        "session_title_ids": [],
        "session_status_vocabulary_item_id": 876,
        "sale_id": 49299,
        "published_total": null,
        "lots_count": 777,
        "location_id": 146,
        "last_lot_number": "766",
        "id": 49299,
        "first_lot_number": "1",
        "finish_date": "2002-09-25",
        "date_text": null,
        "channel_vocabulary_item_id": 2479,
        "calculated_total": null,
        "auction_house_department": null
      }],
      "session_titles": [],
      "sale_titles": [{
        "text": "Fine Furniture and Decorations",
        "sale_id": 49299,
        "preferred": true,
        "id": 50041,
        "disambiguating_comment": null
      }],
      "sale_images": [],
      "sale_descriptions": [],
      "sale": {
        "url": null,
        "source": null,
        "shown": false,
        "session_ids": [49299],
        "sale_title_ids": [50041],
        "sale_image_ids": [],
        "sale_description_ids": [],
        "price_type_vocabulary_item_id": 2290,
        "organization_id": 49034,
        "number": "0209251",
        "id": 49299,
        "currency_vocabulary_item_id": 597
      }
    },
    '767': {
      "sessions": [{
        "start_date": "2012-10-25",
        "source_url": "christies_api",
        "session_title_ids": [],
        "session_status_vocabulary_item_id": 876,
        "sale_id": 767,
        "published_total": null,
        "lots_count": 276,
        "location_id": 301,
        "last_lot_number": "0",
        "id": 767,
        "first_lot_number": "0",
        "finish_date": "2012-10-25",
        "date_text": null,
        "channel_vocabulary_item_id": 2479,
        "calculated_total": null,
        "auction_house_department": null
      }],
      "session_titles": [],
      "sale_titles": [{
        "text": "Impressionist/Modern",
        "sale_id": 767,
        "preferred": true,
        "id": 767,
        "disambiguating_comment": null
      }],
      "sale_images": [],
      "sale_descriptions": [],
      "sale": {
        "url": null,
        "source": null,
        "shown": false,
        "session_ids": [767],
        "sale_title_ids": [767],
        "sale_image_ids": [],
        "sale_description_ids": [],
        "price_type_vocabulary_item_id": 2290,
        "organization_id": 48783,
        "number": "7873",
        "id": 767,
        "currency_vocabulary_item_id": 710
      }
    }
  };
});