define('cdata/pods/components/sorting-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui label header-block-sort'],
    classNameBindings: ['passive:basic', 'active'],
    field: 'id',
    manager: null,
    passive: Ember.computed.not('active'),
    changeSorting: 'changeSorting',

    initState: Ember.on('init', function () {
      var field = this.get('field');
      this.get('manager').setField(false, field);
      Ember.defineProperty(this, 'ascending', Ember.computed.alias('manager.' + field + '.ascending'));
      Ember.defineProperty(this, 'active', Ember.computed.alias('manager.' + field + '.active'));
    }),

    actions: {
      changeSorting: function changeSorting() {
        var asc = this.get('ascending');
        var active = this.get('active');

        if (!active) {
          asc = false;
          active = true;
        } else if (asc === false) {
          asc = true;
        } else if (asc === true) {
          asc = false;
          active = false;
        }

        this.sendAction('changeSorting', active, this.get('field'), asc);
      }
    }
  });
});