define('cdata/pods/components/entities/edit/edit-forms-awards/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('AwardNote'), {
    awards: Ember.computed.filterBy('model.entityAwards', 'isDeleted', false),
    notes: Ember.computed.filterBy('model.entityAwardNotes', 'isDeleted', false),

    addEntityAward: 'addEntityAward',
    removeEntityAward: 'removeEntityAward',
    clearEntityAwardSection: 'clearEntityAwardSection',

    loadAwardFor: 'loadAwardFor',
    openNewAwardForm: 'openNewAwardForm',

    actions: {
      loadAwardFor: function loadAwardFor() {
        this.sendAction.apply(this, ['loadAwardFor'].concat(Array.prototype.slice.call(arguments)));
      },
      openNewAwardForm: function openNewAwardForm() {
        this.sendAction.apply(this, ['openNewAwardForm'].concat(Array.prototype.slice.call(arguments)));
      },
      addAward: function addAward() {
        this.sendAction('addEntityAward');
      },
      addNote: function addNote() {
        this.sendAction('addEntityAwardNote');
      },
      remove: function remove(model) {
        var name = model.get('constructor.modelName');

        if ('entity-award' === name) {
          this.sendAction('removeEntityAward', model);
        }

        if ('entity-award-note' === name) {
          this.sendAction('removeEntityAwardNote', model);
        }
      }
    }
  });
});