define('cdata/helpers/to-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toString = toString;
  function toString(params) {
    return params[0] === undefined || params[0] === null ? '' : params[0].toString();
  }

  exports.default = Ember.Helper.helper(toString);
});