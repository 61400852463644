define('cdata/pods/components/entities/edit/edit-forms-shape/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), {
    item: Ember.computed.alias('model'),

    clearEntityShapeSection: 'clearEntityShapeSection',
    addEntityShape: 'addEntityShape',

    shapeId: Ember.computed('model.shapeModel', 'model.shapeModel.shapeVocabularyItemId', function () {
      return this.get('model.shapeModel') ? this.get('model.shapeModel.shapeVocabularyItemId') : null;
    }),

    shapeLabel: Ember.computed('shapeId', function () {
      return this.get('shapeId') ? this.get('vocab.shapesLabels')[this.get('shapeId')] : null;
    }),

    excludeIds: Ember.computed('shapeId', function () {
      return [this.get('shapeId')];
    }),

    actions: {
      changeShape: function changeShape(shape1, shape2) {
        var shape = shape1 || shape2;
        this.sendAction('clearEntityShapeSection');
        if (shape && shape.get('id')) {
          this.set('shapeId', shape.get('id'));
          this.sendAction('addEntityShape', shape);
        }
      },
      deleteShape: function deleteShape() {
        this.sendAction('clearEntityShapeSection');
      }
    }
  });
});