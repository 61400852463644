define('cdata/pods/components/lots/edit/edit-forms-departments/component', ['exports', 'cdata/pods/components/lots/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('ChristiesSpecialistDepartments'), {
    allSpec: Ember.computed.reads('vocab.christiesSpecialistsLabels'),
    allCitation: Ember.computed.reads('vocab.citationLabelsLabels'),

    autoCreating: false,
    // Hidden by https://christiestech.atlassian.net/browse/MUS-745
    // citationId: null,
    spec: null,

    actions: {
      // Hidden by https://christiestech.atlassian.net/browse/MUS-745
      // setCitationId(citationId) {
      //   this.set('citationId', citationId);
      // },

      setSpec: function setSpec(_, item) {
        this.set('spec', item);
      },
      addLine: function addLine() {
        var _getProperties = this.getProperties('spec', 'citationId'),
            spec = _getProperties.spec,
            citationId = _getProperties.citationId;

        this.sendAction('addLotChristiesSpecialistDepartments', spec, citationId);
        this.setProperties({ spec: null, citationId: null });
      }
    }
  });
});