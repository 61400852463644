define('cdata/pods/components/entities/view/entity-header/component', ['exports', 'cdata/mixins/components/load-creator-names', 'cdata/services/natural-sorter'], function (exports, _loadCreatorNames, _naturalSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_loadCreatorNames.default, {
    reviewEditor: Ember.inject.service(),

    classNames: 'entities-entity-header',

    openEntityForms: 'openEntityForms',

    title: Ember.computed('model.entityTitles.@each.{preferred,text}', function () {
      var title = this.get('model.entityTitles').filterBy('preferred').get('firstObject');

      return title ? title.get('text') : '';
    }),

    artist: Ember.computed('model.entityCreators.@each.{preferred,personId,organizationId,label}', function () {
      this.loadCreatorNames();
      var creators = this.get('model.entityCreators').filterBy('preferred').sort((0, _naturalSorter.default)('label')).mapBy('label');

      return creators ? creators.join(', ') : '';
    }),

    measurements: Ember.computed('model.entityMeasurements.@each.{preferred,text}', function () {
      var note = this.get('model.entityMeasurements').filterBy('preferred').get('firstObject');

      return note ? note.get('text') : '';
    }),

    medium: Ember.computed('model.entityMaterials.@each.{preferred,text}', function () {
      var medium = this.get('model.entityMaterials').filterBy('preferred').get('firstObject');

      return medium ? medium.get('text') : '';
    }),

    date: Ember.computed('model.entityCreationDates.@each.{preferred,text}', function () {
      var date = this.get('model.entityCreationDates').filterBy('preferred').get('firstObject');

      return date ? date.get('text') : '';
    }),

    image: Ember.computed('model.entityImages.@each.{preferred,image}', function () {
      var image = this.get('model.entityImages').filterBy('preferred').get('firstObject');

      return image && image.get('image.url');
    }),

    bgrStyle: Ember.computed('image', function () {
      var image = this.get('image');
      if (image) {
        return new Ember.String.htmlSafe('background-image: url(' + image + ')');
      }

      return Ember.String.htmlSafe('');
    }),

    actions: {
      showImagesForms: function showImagesForms() {
        this.sendAction('openEntityForms', 'edit-forms-image-relations');
      }
    }
  });
});