define('cdata/pods/components/comparables/custom-estimate/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['custom-estimate'],

    runEstimates: 'runEstimates',

    init: function init() {
      this._super.apply(this, arguments);
      this.sendAction('runEstimates');
    }
  });
});