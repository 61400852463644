define('cdata/mixins/editor/edit-forms-template', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function editFormsTemplate() {
    return {
      classNames: ['ui grid form'],

      save: 'save',
      cancel: 'cancel',

      openVocabularyNewItemForm: 'openVocabularyNewItemForm',

      modal: Ember.inject.service(),

      itemsCount: Ember.computed.alias('items.length'),

      autoCreating: true,

      options: {},

      actions: {
        save: function save() {
          this.sendAction.apply(this, ['save'].concat(Array.prototype.slice.call(arguments)));
        },
        cancel: function cancel() {
          this.sendAction.apply(this, ['cancel'].concat(Array.prototype.slice.call(arguments)));
        },
        openVocabularyNewItemForm: function openVocabularyNewItemForm() {
          this.sendAction.apply(this, ['openVocabularyNewItemForm'].concat(Array.prototype.slice.call(arguments)));
        }
      },

      initEsc: function initEsc() {
        key('escape', 'all', function () {
          if (!this.get('modal.hasActiveModal')) {
            this.send('cancel');
          }
        }.bind(this));
      },
      removeEsc: function removeEsc() {
        key.unbind('escape', 'all');
      },
      didInsertElement: function didInsertElement() {
        this._super.apply(this, arguments);
        this.initEsc();
        this.get('modal').on('closed.all', this, this.initEsc);
        this.createDefaultItem();
      },
      willDestroyElement: function willDestroyElement() {
        this._super.apply(this, arguments);
        this.removeEsc();
        this.get('modal').off('closed.all', this, this.initEsc);
      },
      createDefaultItem: function createDefaultItem() {
        var _this = this;

        if (0 === this.get('itemsCount') && this.get('autoCreating')) {
          Ember.run.schedule('afterRender', function () {
            _this.send('add');
          });
        }
      }
    };
  }

  exports.default = editFormsTemplate;
});