define('cdata/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/datasets/entities.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/datasets/entities.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/datasets/entitiy_list_items.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/datasets/entitiy_list_items.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/datasets/locations.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/datasets/locations.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/datasets/lots.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/datasets/lots.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/datasets/organization_list_items.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/datasets/organization_list_items.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/datasets/organizations.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/datasets/organizations.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/datasets/people.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/datasets/people.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/datasets/person_list_items.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/datasets/person_list_items.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/datasets/sales.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/datasets/sales.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/datasets/sessions.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/datasets/sessions.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/datasets/vocabularies.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/datasets/vocabularies.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/entities-bulk-delete.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/entities-bulk-delete.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/entity-lot.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/entity-lot.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/entity-merge.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/entity-merge.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/entity.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/entity.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/lot.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/lot.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/organization-merge.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/organization-merge.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/person-merge.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/person-merge.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});