define('cdata/services/subject-search', ['exports', 'cdata/services/natural-sorter', 'cdata/constants'], function (exports, _naturalSorter, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Service.extend({
    vocab: Ember.inject.service(),

    query: '',
    unit: '',
    results: [],
    process: false,
    index: null,
    excludeIds: [],
    mode: null,
    subjectName: null,
    params: {},
    canEmpty: false,

    /**
     * Subjects through API request
     */
    subjectMap: {
      vocabularyItem: '/vocabulary_items/search',
      style: '/vocabulary_items/search/styles',
      entity: '/entities/search',
      referenceNumber: '/reference_numbers/search',
      entityCreator: '/entity_creators/search',
      person: '/people/search',
      publisher: '/publishers/search',
      organization: '/organizations/search',
      auctionHouse: '/auction_houses/search',
      award: '/awards/search',
      location: '/locations/search',
      place: '/places/search',
      edition: '/editions/search',
      source: '/sources/search',
      sourceOnline: '/source_online/search',
      session: '/sessions/search',
      sale: '/sales/search',
      markLetter: '/mark_letters/search'
    },

    /**
     * Subjects from vocabulary items
     */
    vocabMapTypes: {
      citationLabel: { vocab: 'citationLabels', type: 'citation_label' },
      threeDigitCode: { vocab: 'threeDigitCodes', type: 'three_digit_code' },
      christiesSpecialist: {
        vocab: 'christiesSpecialists',
        type: 'christies_specialist'
      },
      lotSymbol: { vocab: 'lotSymbols', type: 'lot_symbol' },
      jdeDepartment: { vocab: 'jdeDepartments', type: 'jde_department' },
      markSymbol: { vocab: 'markSymbols', type: 'mark_symbol' },
      material: { vocab: 'materials', type: 'material' },
      currency: { vocab: 'currencies', type: 'currency' },
      sessionStatus: { vocab: 'sessionStatuses', type: 'session_status' },
      academicDegree: { vocab: 'academicDegrees', type: 'academic_degree' },
      academicSpecialization: {
        vocab: 'academicSpecializations',
        type: 'academic_specialization'
      },
      contactType: { vocab: 'contactTypes', type: 'contact_type' },
      nationality: { vocab: 'nationalities', type: 'nationality' },
      shape: { vocab: 'shapes', type: 'shape' },
      color: { vocab: 'colors', type: 'color' },
      language: { vocab: 'languages', type: 'language' },
      languageId: { vocab: 'languageIds', type: 'language' },
      miCategory: { vocab: 'miCategories', type: 'mi_category' },
      cdataCategory: { vocab: 'cdataCategories', type: 'cdata_category' },
      christiesBusinessGroup: {
        vocab: 'christiesBusinessGroups',
        type: 'christies_business_group'
      },
      country: { vocab: 'countries', type: 'country' }
    },

    /**
     * Start search, must be called from component
     * @param {String} query
     * @param {Object} params
     * @param {Boolean} canEmpty
     */
    doSearch: function doSearch(query, params) {
      var canEmpty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (query === this.get('query')) {
        return;
      }

      this.set('results', []);
      this.set('process', true);
      this.set('query', query);
      this.set('params', params);
      this.set('canEmpty', canEmpty);

      Ember.run.debounce(this, this.fetch, 100);
    },


    threeDigitCodes: Ember.computed.alias('vocab.threeDigitCodes'),
    citationLabels: Ember.computed.alias('vocab.citationLabels'),
    christiesSpecialists: Ember.computed.alias('vocab.christiesSpecialists'),
    lotSymbols: Ember.computed.alias('vocab.lotSymbols'),
    jdeDepartments: Ember.computed.alias('vocab.jdeDepartments'),
    materials: Ember.computed.alias('vocab.materials'),
    markSymbols: Ember.computed.alias('vocab.markSymbols'),
    roles: Ember.computed.alias('vocab.originalRoles'),
    countries: Ember.computed.alias('vocab.countries'),
    miCategories: Ember.computed.alias('vocab.miCategories'),
    cdataCategories: Ember.computed.alias('vocab.cdataCategories'),
    christiesBusinessGroups: Ember.computed.alias('vocab.christiesBusinessGroups'),
    nationalities: Ember.computed.alias('vocab.nationalities'),
    shapes: Ember.computed.alias('vocab.shapes'),
    colors: Ember.computed.alias('vocab.colors'),
    academicDegrees: Ember.computed.alias('vocab.academicDegrees'),
    sessionStatuses: Ember.computed.alias('vocab.sessionStatuses'),
    currencies: Ember.computed.alias('vocab.currencies'),
    academicSpecializations: Ember.computed.alias('vocab.academicSpecializations'),
    contactTypes: Ember.computed.alias('vocab.contactTypes'),
    languages: Ember.computed.alias('vocab.languages'),
    languageIds: Ember.computed.alias('vocab.languageIds'),
    active: Ember.computed('results.length', 'process', 'query', function () {
      return this.get('results.length') > 0 || this.get('process') || this.get('query');
    }),

    getByIds: function getByIds(ids, subjectName) {
      var _this = this;

      ids = Ember.isArray(ids) ? ids : [ids];
      subjectName = subjectName ? subjectName : this.get('subjectName');
      var result = void 0;

      if (this.get('vocabMapTypes')[subjectName]) {
        result = [];

        this.get(this.get('vocabMapTypes')[subjectName].vocab).forEach(function (item) {
          if ('*' === ids || ids.indexOf(item.get('id')) !== -1) {
            result.push(_this._makeSubjectFromVocabularyItem(_this.get('vocabMapTypes')[subjectName].type, item));
          }
        });
      } else if (this.get('subjectMap')[subjectName]) {
        result = this._querySubjectsByIds(this.get('subjectMap')[subjectName], ids);
      } else if ('role' === subjectName) {
        result = [];
        this.get('roles').forEach(function (role) {
          if ('*' === ids || ids.indexOf(role.get('id')) !== -1) {
            result.push(_this._makeSubjectRole(role));
          }
        });
      } else if ('vocabulary' === subjectName) {
        result = [];
        this.get('vocab.vocabularies').forEach(function (vocabulary) {
          if ('*' === ids || ids.indexOf(vocabulary.get('id')) !== -1) {
            result.push(_this._makeSubjectVocabulary(vocabulary));
          }
        });
      }

      // result is ready array
      if (Ember.isArray(result)) {
        return new Ember.RSVP.Promise(function (resolve) {
          resolve(result);
        });
      }

      // result is promise
      return result;
    },
    getById: function getById(id, subjectName) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this2.getByIds([id], subjectName).then(function (items) {
          if (Ember.isArray(items) && !Ember.isEmpty(items)) {
            resolve(items[0]);
          }
        });
      });
    },


    /**
     * Call from debounce, work with different sources by subjectName
     */
    fetch: function fetch() {
      var query = this.get('query');

      // out if empty query
      if (!this.get('canEmpty') && Ember.isEmpty(query)) {
        this.set('results', []);

        return;
      }

      var subjectName = this.get('subjectName');

      // search subjects on server
      if (this.get('subjectMap')[subjectName]) {
        this._querySubjects(this.get('subjectMap')[subjectName]).then(this.setResults.bind(this));

        return;
      }

      // make subjects from vocabularies
      var vocabulary = this.get('vocabMapTypes')[subjectName];
      if (vocabulary && vocabulary.vocab) {
        var results = [];
        var q = query.toUpperCase();

        this.get(vocabulary.vocab).some(function (item) {
          if (item.get('label').toUpperCase() === q) {
            results.push(item);
          }
        });

        this.get(vocabulary.vocab).some(function (item) {
          if (item.get('label').toUpperCase().indexOf(q) !== -1) {
            results.push(item);
          }

          if (results.length > 30) {
            return true;
          }
        });

        this.get(vocabulary.vocab).some(function (item) {
          if (item.get('description') && item.get('description').toUpperCase().indexOf(query.toUpperCase()) !== -1) {
            results.push(item);
          }

          if (results.length > 30) {
            return true;
          }
        });

        this.setResults(results.uniqBy('label').map(this._makeSubjectFromVocabularyItem.bind(this, vocabulary.type)));

        return;
      }

      // special case 'roles'
      if (subjectName === 'role') {
        this.setResults(this.get('roles').filter(function (item) {
          // filter by query
          return item.get('name').toUpperCase().indexOf(query.toUpperCase()) !== -1;
        })
        // limit
        .slice(0, 30)
        // convert to search-subject format
        .map(this._makeSubjectRole));
      }

      // special case 'vocabularies'
      if (subjectName === 'vocabulary') {
        if ('*' === query) {
          this.setResults(this.get('vocab.vocabularies').map(this._makeSubjectVocabulary));
        } else {
          this.setResults(this.get('vocab.vocabularies').filter(function (item) {
            // filter by query
            return item.get('name').toUpperCase().indexOf(query.toUpperCase()) !== -1;
          })
          // limit
          .slice(0, 30)
          // convert to search-subject format
          .map(this._makeSubjectVocabulary));
        }
      }
    },


    /**
     * Cancel from search processes, clean query and results
     */
    reset: function reset() {
      this.set('index', null);
      this.markSelectedResult();
      this.set('query', '');
      this.set('results', []);
    },
    setResults: function setResults(results) {
      var excludeIds = this.get('excludeIds');
      if (excludeIds && !Ember.isArray(excludeIds)) {
        excludeIds = [excludeIds];
      }

      excludeIds = excludeIds ? excludeIds.map(function (value) {
        return parseInt(value);
      }) : [];

      if (!Ember.isEmpty(excludeIds)) {
        results = results.filter(function (subject) {
          return excludeIds.indexOf(parseInt(subject.get('id'))) === -1;
        });
      }

      if (['entityCreator', 'vocabularyItem', 'material'].indexOf(this.get('subjectName')) !== -1) {
        // skip
      } else {
        results = results.sort((0, _naturalSorter.default)('name'));
      }

      this.set('results', results);
      this.set('process', false);
    },
    selectUp: function selectUp() {
      var index = this.get('index');

      if (null === index) {
        this.set('index', 0);
        this.markSelectedResult();

        return;
      }

      if (0 === index) {
        return;
      }

      this.set('index', index - 1);
      this.markSelectedResult();
    },
    selectDown: function selectDown() {
      var index = this.get('index');

      if (null === index) {
        this.set('index', 0);
        this.markSelectedResult();

        return;
      }

      if (index >= this.get('results.length') - 1) {
        return;
      }

      this.set('index', index + 1);
      this.markSelectedResult();
    },
    markSelectedResult: function markSelectedResult() {
      var _this3 = this;

      this.get('results').forEach(function (item, key) {
        if (_this3.get('index') === key) {
          item.set('active', true);
        } else {
          item.set('active', false);
        }
      });
    },
    getSelected: function getSelected() {
      var index = this.get('index');
      if (null !== index) {
        return this.get('results')[index];
      }
    },
    setFirst: function setFirst() {
      if (null === this.get('index') && this.get('results.length')) {
        this.set('index', 0);
        this.markSelectedResult();
      }
    },
    initVocabularyLabels: function initVocabularyLabels(items, key) {
      var ids = items.mapBy(key);

      Ember.$.ajax({
        url: _constants.API_URL + '/vocabulary_items/search' + '?ids%5B%5D=' + ids.join('&ids%5B%5D='),
        datType: 'JSON',
        method: 'GET',
        headers: this.get('headers'),
        success: function success(terms) {
          terms.forEach(function (term) {
            var label = term.description ? term.name + ' (' + term.description + ')' : term.name;
            var item = items.findBy(key, term.id + '') || items.findBy(key, term.id);
            item.set('label', label);
          });
        }
      });
    },
    _createObjects: function _createObjects(data) {
      var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      return data.map(function (item) {
        // hook for creators search: id contains in person_id or organization_id
        if ('/entity_creators/search' === name && (item.person_id || item.organization_id)) {
          item.id = item.person_id || item.organization_id;
        }

        return Ember.Object.create(item);
      });
    },
    _querySubjects: function _querySubjects(name) {
      var _this4 = this;

      var params = this.get('params') || {};
      params.query = this.get('query');

      if (name === '/auction_houses/search') {
        params = _extends({}, params, { has_sales: true });
      }

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.$.ajax({
          url: _constants.API_URL + name,
          data: params,
          datType: 'JSON',
          method: 'GET',
          headers: _this4.get('headers'),
          success: function (data) {
            resolve(this._createObjects(data, name));
            this.set('process', false);
          }.bind(_this4)
        });
      });
    },
    _querySubjectsByIds: function _querySubjectsByIds(name, ids) {
      var _this5 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.$.ajax({
          url: _constants.API_URL + name + '?ids%5B%5D=' + ids.join('&ids%5B%5D='),
          datType: 'JSON',
          method: 'GET',
          headers: _this5.get('headers'),
          success: function (data) {
            resolve(this._createObjects(data, name));
            this.set('process', false);
          }.bind(_this5)
        });
      });
    },
    _makeSubjectFromVocabularyItem: function _makeSubjectFromVocabularyItem(type, item) {
      return Ember.Object.create({
        id: item.get('id'),
        name: item.get('label'),
        description: item.get('description'),
        type: type
      });
    },
    _makeSubjectRole: function _makeSubjectRole(item) {
      return Ember.Object.create({
        id: item.get('id'),
        name: item.get('name'),
        description: '',
        type: 'role'
      });
    },
    _makeSubjectVocabulary: function _makeSubjectVocabulary(item) {
      return Ember.Object.create({
        id: item.get('id'),
        name: item.get('name'),
        description: '',
        type: 'vocabulary'
      });
    }
  });
});