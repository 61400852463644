define('cdata/services/session', ['exports', 'js-cookie'], function (exports, _jsCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    key: Ember.computed({
      get: function get() {
        return _jsCookie.default.get('accessKey');
      },
      set: function set(_, value) {
        if (value) {
          _jsCookie.default.set('accessKey', value, { path: '/' });
        } else {
          _jsCookie.default.remove('accessKey');
        }

        return value;
      }
    }),

    authHeader: Ember.computed('key', function () {
      return {
        Authorization: 'Bearer ' + this.get('key'),
        'Content-type': 'application/json'
      };
    }),

    hasAuth: Ember.computed.bool('key'),

    removeKey: function removeKey() {
      this.set('key', null);
    }
  });
});