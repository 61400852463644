define('cdata/pods/components/marks/edit/edit-forms-symbols/component', ['exports', 'cdata/pods/components/marks/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Symbol'), {
    autoCreating: false,

    symbolIds: Ember.computed.mapBy('symbols', 'id'),

    symbols: Ember.computed.filterBy('model.markSymbols', 'isDeleted', false)
  });
});