define('cdata/pods/components/organizations/mixins/handle_new_organization', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modal: Ember.inject.service(),

    actions: {
      createOrganization: function createOrganization(title) {
        this.sendAction('openNewOrganizationForm', title, true);
        this._subscribeNewOrganization();
      }
    },

    _subscribeNewOrganization: function _subscribeNewOrganization() {
      this.get('modal').on(_constants.MODALS.ORGANIZATION + '.saving.success', this, this._onOrganizationCreated).on(_constants.MODALS.ORGANIZATION + '.close', this, this._unSubscribeNewOrganization);
    },
    _unSubscribeNewOrganization: function _unSubscribeNewOrganization() {
      this.get('modal').off(_constants.MODALS.ORGANIZATION + '.saving.success', this, this._onOrganizationCreated).off(_constants.MODALS.ORGANIZATION + '.close', this, this._unSubscribeNewOrganization);
    },
    _onOrganizationCreated: function _onOrganizationCreated(result) {
      var organization = result.item;

      // success result is organization object
      var name = organization && organization.get('organizationTitles.firstObject');
      name = name && name.get('text');

      var role = organization && organization.get('organizationRoles.firstObject');
      role = role && role.get('role.name');

      var place = organization && organization.get('_place');

      var description = [];
      if (role) {
        description.push(role);
      }
      if (place) {
        description.push(place);
      }

      // add organization-based subject to list
      this.send('setOrganization', this.get('model'), Ember.Object.create({
        id: organization.get('id'),
        name: name,
        description: description.join(', ') || '',
        type: 'organization'
      }));
    }
  });
});