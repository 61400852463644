define('cdata/pods/components/vocabularies/list/set-filters/component', ['exports', 'cdata/mixins/lists/component-filter'], function (exports, _componentFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_componentFilter.default, {
    vocab: Ember.inject.service(),

    openFormVocabulary: 'openFormVocabulary',
    createNewVocabulary: 'createNewVocabulary',
    newVocabulary: false,
    newVocabularyName: '',

    createNewVocabularyItem: 'createNewVocabularyItem',
    newTerm: false,
    newTermName: '',

    fields: ['query', 'vocabularyIds'],

    vocabularies: Ember.computed('filterParameters.vocabularyIds.[]', function () {
      var ids = this.get('filterParameters.vocabularyIds');

      return this.get('vocab.vocabularies').filter(function (item) {
        return ids && ids.length ? ids.includes(item.get('id')) : ids === item.get('id');
      });
    }),

    vocabularyIds: Ember.computed.mapBy('vocabularies', 'id'),

    initFilter: function initFilter() {
      this._super();
      this.initFilterSubjects('vocabularyIds', 'vocabulary', 'vocabularies');
    },


    oneVocabulary: Ember.computed('vocabularies.@each', function () {
      var vocabularies = this.get('vocabularies');
      if (vocabularies.length === 1) {
        return vocabularies[0];
      }

      return null;
    }),

    // This method owerrides app/mixins/lists/component-filter.js
    initFilterSubjects: function initFilterSubjects(modelKey, subjectName, key) {
      var _this = this;

      var ids = this.get('model.' + modelKey);
      if (ids && ids.length > 0) {
        this.get('subjectSearch').getByIds(ids, subjectName).then(function () {
          return _this.set('model.' + modelKey, _this.formatToArray(key));
        });
      } else {
        this.set('model.' + modelKey, this.formatToArray(key));
      }
    },


    // This method owerrides app/mixins/lists/component-filter.js
    addToCollection: function addToCollection(subject) {
      var exists = this.get('vocabularies').findBy('id', subject.get('id'));

      if (!exists) {
        var vocabFilterValue = this.formatToArray([].concat(_toConsumableArray(this.get('vocabularies')), [subject]));

        this.syncNameLabel(subject);
        this.set('model.vocabularyIds', vocabFilterValue);
        this.applyFilter();
      }
    },


    actions: {
      clear: function clear() {
        this.setProperties({
          vocabularies: []
        });
        this._super();
      },
      editVocabulary: function editVocabulary() {
        this.sendAction('openFormVocabulary');
      },
      addNewVocabulary: function addNewVocabulary() {
        this.set('newVocabulary', true);
        Ember.run.schedule('afterRender', function () {
          $('.right input.new-item').focus();
        });
      },
      cancelNewVocabulary: function cancelNewVocabulary() {
        this.set('newVocabularyName', '');
        this.set('newVocabulary', false);
      },
      saveNewVocabulary: function saveNewVocabulary() {
        this.set('newVocabulary', false);
        var name = this.get('newVocabularyName');
        if (!Ember.isEmpty(name)) {
          this.sendAction('createNewVocabulary', name);
          this.send('cancelNewVocabulary');
          // it runs before =(( this.initFilterSubjects('vocabularyIds', 'vocabulary', 'vocabularies');
        }
      },
      addNewTerm: function addNewTerm() {
        this.set('newTerm', true);
        Ember.run.schedule('afterRender', function () {
          $('.left input.new-item').focus();
        });
      },
      cancelNewItem: function cancelNewItem() {
        this.set('newTermName', '');
        this.set('newTerm', false);
      },
      saveNewVocabularyItem: function saveNewVocabularyItem() {
        this.set('newTerm', false);
        var name = this.get('newTermName');
        if (!Ember.isEmpty(name)) {
          this.sendAction('createNewVocabularyItem', name);
          this.send('cancelNewItem');
        }
      }
    }
  });
});