define('cdata/pods/components/two-options-switcher/component', ['exports', 'ember-cli-jss', 'cdata/pods/components/two-options-switcher/stylesheet', 'cdata/pods/components/two-options-switcher/declaration'], function (exports, _emberCliJss, _stylesheet, _declaration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCliJss.TaglessJSS, {
    tagName: '',
    stylesheet: _stylesheet.default,
    propTypes: _declaration.default,

    getDefaultProps: function getDefaultProps() {
      return {
        value: true,
        onChange: function onChange() {
          return null;
        }
      };
    }
  });
});