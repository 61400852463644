define('cdata/models/person-organization-membership', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    _title: attr('string'),
    _place: attr('string'),
    personId: attr('string'),
    person: belongsTo('person'),
    organizationId: attr('string'),
    organization: belongsTo('organization'),
    startYear: attr('string'),
    finishYear: attr('string'),
    dateText: attr('string'),
    personRoleVocabularyItemId: attr('string')
  });
});