define('cdata/pods/components/lots/list/set-filter/sales-mixin', ['exports', 'cdata/mixins/lists/component-filter'], function (exports, _componentFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_componentFilter.default, {
    loadSaleForFilterItem: 'loadSaleForFilterItem',
    sales: [],

    loadSalesObserver: Ember.observer('sales.@each.id', function () {
      this.loadSales();
    }),

    loadSales: function loadSales() {
      var _this = this;

      var sales = this.get('sales');
      this.set('_sales', []);
      sales = sales || [];
      sales.forEach(function (subject) {
        var item = Ember.Object.create({
          id: subject.get('id'),
          sessions: []
        });
        _this.get('_sales').pushObject(item);
        _this.sendAction('loadSaleForFilterItem', item);
      });
    },


    _sessions: Ember.computed('_sales.@each.{id,qnt}', '_sales.length', function () {
      var _this2 = this;

      var sessions = [];
      var ids = this.get('model.sessionIds');
      var cleanIds = [];
      this.set('sessions', []);
      var hasSessions = true;
      var hasSales = false;

      if (!this.get('_sales.length')) {
        hasSessions = false;
      }

      this.get('_sales').map(function (item) {
        hasSales = true;

        if (!item.get('sessions.length')) {
          hasSessions = false;
        }

        item.get('sessions').forEach(function (session) {
          hasSessions = true;
          sessions.pushObject(session);
          if (ids && ids.indexOf(session.get('id')) !== -1) {
            if (!_this2.get('sessions').findBy('id', session.get('id'))) {
              _this2.get('sessions').pushObject(session);
              cleanIds.push(session.get('id'));
            }
          }
        });
      });

      if (hasSessions && ids) {
        this.set('model.sessionIds', cleanIds.get('length') > 0 ? cleanIds : null);
      } else if (!hasSales) {
        this.set('model.sessionIds', null);
      }

      return sessions;
    })
  });
});