define('cdata/pods/reports/components/sales-report/with-switcher/component', ['exports', 'ember-cli-jss', 'cdata/pods/reports/components/sales-report/with-switcher/declaration', 'cdata/pods/reports/components/sales-report/with-switcher/stylesheet'], function (exports, _emberCliJss, _declaration, _stylesheet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCliJss.TaglessJSS, {
    tagName: '',
    stylesheet: _stylesheet.default,
    propTypes: _declaration.default
  });
});