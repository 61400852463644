define('cdata/pods/components/site/menu-context/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['menu-context'],

    clickOnContextMenuButton: 'clickOnContextMenuButton',
    opened: false,

    actions: {
      openMenu: function openMenu() {
        this.sendAction('clickOnContextMenuButton');
      }
    }
  });
});