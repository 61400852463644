define('cdata/pods/components/entities/list/set-results/item/item-mixin', ['exports', 'cdata/mixins/lists/set-results-item'], function (exports, _setResultsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var escape = Ember.Handlebars.Utils.escapeExpression;

  exports.default = Ember.Mixin.create(_setResultsItem.default, {
    about: Ember.computed('item.{creationDate,title,measurements,medium}', function () {
      var result = [];
      var date = this.get('item.creationDate');
      var materials = this.get('item.materials');
      var measurements = this.get('item.measurements');

      if (date) {
        result.push('<span>' + escape(date) + '</span>');
      }

      if (materials) {
        result.push('<span>' + escape(materials) + '</span>');
      }

      if (measurements) {
        result.push('<span>' + escape(measurements) + '</span>');
      }

      return result.join('').htmlSafe();
    }),

    creator: Ember.computed('item.{creatorName,creatorVitalDates,creatorRole,creatorNationality}', function () {
      var result = [];
      var name = this.get('item.creatorName');
      var dates = this.get('item.creatorVitalDates');
      var role = this.get('item.creatorRole');
      var nationality = this.get('item.creatorNationality');

      if (name) {
        name = escape(name);
        result.push('<span><strong>' + name + '</strong></span>');
      }

      if (dates || nationality) {
        var info = [];
        if (dates) {
          info.push(dates);
        }
        if (nationality) {
          info.push(nationality);
        }
        if (info.length > 0) {
          result.push('<span>' + escape(info.join(', ')) + '</span>');
        }
      }

      if (role) {
        result.push('<span>' + escape(role) + '</span>');
      }

      return result.join('').htmlSafe();
    }),

    properties: Ember.computed('item.{cdataCategoryVocabularyItemId,christiesBusinessGroupVocabularyItemId,miCategoryVocabularyItemId}', function () {
      var result = [];

      var miId = this.get('item.miCategoryVocabularyItemId');
      var mi = miId && escape(this.get('vocab.miCategoriesLabels')[miId]);

      var groupId = this.get('item.christiesBusinessGroupVocabularyItemId');
      var group = groupId && escape(this.get('vocab.christiesBusinessGroupsLabels')[groupId]);

      var cdataId = this.get('item.cdataCategoryVocabularyItemId');
      var cdata = cdataId && escape(this.get('vocab.cdataCategoriesLabels')[cdataId]);

      if (cdataId) {
        var category = this.get('vocab.cdataCategories').findBy('id', cdataId.toString());
        if (category && category.get('parentId')) {
          cdata = escape(this.get('vocab.cdataCategoriesLabels')[category.get('parentId')]) + '<i class="ui icon angle right"></i>' + cdata;
        }
      }

      if (mi) {
        result.push('<span>' + mi + '</span>');
      }
      if (group) {
        result.push('<span>' + group + '</span>');
      }
      if (cdata) {
        result.push('<span>' + cdata + '</span>');
      }

      return result.join('').htmlSafe();
    }),

    addLot: 'addLot',

    actions: {
      addLot: function addLot() {
        this.sendAction('addLot', this.get('item.entityId'));
      }
    }
  });
});