define('cdata/pods/components/people/edit/edit-forms-collections/item/component', ['exports', 'cdata/pods/components/people/edit/mixins/edit-forms-item', 'cdata/pods/components/organizations/mixins/handle_new_organization'], function (exports, _editFormsItem, _handle_new_organization) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsItem.default, _handle_new_organization.default, {
    modal: Ember.inject.service(),

    openNewOrganizationForm: 'openNewOrganizationForm',
    loadOrganizationFor: 'loadOrganizationFor',

    collectionTitle: Ember.computed('model.{_title,_place}', function () {
      var result = this.get('model._title');
      if (this.get('model._place')) {
        result += ', ' + this.get('model._place');
      }

      return result;
    }),

    actions: {
      setOrganization: function setOrganization(_, organizationSubject) {
        this.sendAction('loadOrganizationFor', this.get('model'), organizationSubject.get('id'));
      },
      openNewOrganizationForm: function openNewOrganizationForm() {
        this.sendAction.apply(this, ['openNewOrganizationForm'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});