define("cdata/mirage/datasets/people", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    '371105': {
      "person_vital_dates": [],
      "person_roles": [],
      "person_nationalities": [],
      "person_names": [{
        "text": "14 Taisha 3",
        "source_page": null,
        "source_id": null,
        "preferred": true,
        "person_id": 371105,
        "name_label_vocabulary_item_id": 1442,
        "language": "en",
        "id": 527408
      }],
      "person_images": [],
      "person_death_places": [],
      "person_birth_places": [],
      "person_biographies": [],
      "person_activity_dates": [],
      "person": {
        "person_vital_date_ids": [],
        "person_role_ids": [],
        "person_nationality_ids": [],
        "person_name_ids": [527408],
        "person_image_ids": [],
        "person_death_place_ids": [],
        "person_birth_place_ids": [],
        "person_biography_ids": [],
        "person_activity_date_ids": [],
        "id": 371105,
        "gender": null,
        "disambiguating_comment": null,
        "_name": "14 Taisha 3",
        "_lots_count": 0
      }
    }
  };
});