define('cdata/pods/components/entities/view/entity-item/tab-section/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['item'],
    classNameBindings: ['active'],
    attributeBindings: ['data-id'],

    entityLot: null,

    activeId: null,

    active: Ember.computed('activeId', 'entityLot.entityId', function () {
      var activeId = this.get('activeId');
      var entityId = this.get('entityLot.entityId');

      if (!activeId || !entityId) {
        return false;
      }

      return activeId.toString() === entityId.toString();
    }),

    title: Ember.computed('entityLot.entity.id', 'entityLot.entity.entityTitles.@each.{preferred,text}', function () {
      var titles = this.get('entityLot.entity.entityTitles');

      var title = '#' + this.get('entityLot.entity.id');

      if (titles && titles.get('length') > 0) {
        var prefTitle = titles.findBy('preferred');
        title = prefTitle ? prefTitle.get('text') : title;
      }

      return title;
    })
  });
});