define("cdata/pods/components/citations/cluster-by-source/utils", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getUniqueValues = exports.getUniqueValues = function getUniqueValues(field, objects) {
    return objects.map(function (item) {
      return item.get(field);
    }).uniq().filter(Boolean).sort(function (a, b) {
      return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    });
  };
});