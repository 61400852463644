define('cdata/pods/components/buttons/inline-form-actions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    classNames: ['four wide column'],
    disabled: true,
    mode: 'add',
    addingMode: Ember.computed.equal('mode', 'add'),
    edit: Ember.computed.not('add'),
    add: 'add',
    cancel: 'cancel',

    actions: {
      add: function add() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.sendAction.apply(this, ['add'].concat(_toConsumableArray(args)));
      },
      cancel: function cancel() {
        for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        this.sendAction.apply(this, ['cancel'].concat(_toConsumableArray(args)));
      }
    }
  });
});