define('cdata/pods/components/buttons/preferred-radio/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['preferred-switcher'],
    classNameBindings: ['active'],
    active: Ember.computed.alias('model.preferred'),
    alternate: false,
    setPreferred: 'setPreferred',
    setAlternate: 'setAlternate',

    actions: {
      doAlt: function doAlt() {
        this.sendAction('setAlternate', this.get('model'));
      },
      doPref: function doPref() {
        this.sendAction('setPreferred', this.get('model'));
      }
    }
  });
});