define('cdata/pods/reports/components/chart-header/stylesheet', ['exports', 'ember-cli-jss'], function (exports, _emberCliJss) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = new _emberCliJss.StyleSheet({
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 35
    },

    h4: {
      marginBottom: 0
    },

    breadcrumbs: {
      marginLeft: 8
    }
  });
});