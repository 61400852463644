define('cdata/pods/components/entities/edit/edit-forms-categories/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms', 'cdata/mixins/components/cdata-category'], function (exports, _editForms, _cdataCategory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), _cdataCategory.default, {
    item: Ember.computed.alias('model'),
    save: 'save',

    actions: {
      setMiCategory: function setMiCategory(miCategory) {
        this.set('model.miCategoryVocabularyItemId', miCategory.get('id'));
        var christiesBusinessGroupVocabularyItemId = miCategory.get('cdataBusinessCategoryVocabularyItemId');
        this.set('model.christiesBusinessGroupVocabularyItemId', christiesBusinessGroupVocabularyItemId ? christiesBusinessGroupVocabularyItemId : null);
      },
      reparseCategory: function reparseCategory() {
        this.set('model.cdataCategoryVocabularyItemId', null);
        this.sendAction('save');
      }
    }
  });
});