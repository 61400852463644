define('cdata/mixins/lists/route-bulk', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      initCheckedItemsIds: function initCheckedItemsIds(target) {
        target.set('checkedItemsIds', this.get('currentModel').filterBy('_checked', true).mapBy('id'));
      },
      bulkCheckItem: function bulkCheckItem(item) {
        this.get('modal').trigger('bulk-checkbox-toggled');

        var checked = item.get('_checked');
        item.set('_checked', !item.get('_checked'));

        if (checked && this.get('controller.bulkAllChecked')) {
          // un-check 'all' because one item not checked
          this.set('controller.bulkAllChecked', false);
        } else {
          // if all checked - set 'all' check
          var checkedQnt = this.get('currentModel').rejectBy('_checked', true).get('length');
          var allChecked = 0 === checkedQnt;
          if (allChecked) {
            this.set('controller.bulkAllChecked', true);
          }
        }
      },
      bulkCheckAll: function bulkCheckAll() {
        var checked = !this.get('controller.bulkAllChecked');
        this.get('currentModel').forEach(function (item) {
          if (item.get('_merged') || item.get('isDeleted')) {
            return;
          }
          item.set('_checked', checked);
        });
        this.set('controller.bulkAllChecked', checked);
      },
      bulkCancel: function bulkCancel() {
        this.get('currentModel').forEach(function (item) {
          item.set('_checked', false);
        });
        this.set('controller.bulkAllChecked', false);
      },
      invalidateCheckAll: function invalidateCheckAll() {
        if (this.get('controller.bulkAllChecked')) {
          var checkedQnt = this.get('currentModel').rejectBy('_checked', true).get('length');
          var allChecked = 0 === checkedQnt;
          if (!allChecked) {
            this.set('controller.bulkAllChecked', false);
          }
        }
      },
      initPrevNextEditorForLots: function initPrevNextEditorForLots() {
        var _this = this;

        var sort = void 0;

        if (this.get('_params') && this.get('_params').sortBy) {
          sort = {
            by: this.get('_params').sortBy,
            asc: this.get('_params').sortAsc ? true : undefined
          };
        }

        var params = {
          filter: this.makeFilterParameters(),
          sort: sort,
          page_size: 500
        };

        this.get('prevNextEditor').set('inProcess', true);

        return this.get('store').query('lot-list-item', params).then(function (items) {
          if (items.get('length') === 0) {
            _this.get('prevNextEditor').set('inProcess', false);

            return;
          }
          _this.get('prevNextEditor').setList(items);
          _this.get('prevNextEditor').set('name', 'lots');

          var item = items.get('firstObject');
          var entityId = item.get('entityListItems.firstObject.entity_id');
          if (entityId) {
            _this.transitionTo('entities.subject', item.get('lotId'), item.get('entityListItems.firstObject.entity_id'));
          } else {
            _this.transitionTo('lots.show', item.get('lotId'));
          }

          _this.get('prevNextEditor').set('inProcess', false);
        });
      },
      initPrevNextEditorForPeople: function initPrevNextEditorForPeople() {
        this._initPrevNextEditorForCreators('person-list-item', 'people', 'people.show', 'personId');
      },
      initPrevNextEditorForOrganizations: function initPrevNextEditorForOrganizations() {
        this._initPrevNextEditorForCreators('organization-list-item', 'organizations', 'organizations.show', 'organizationId');
      }
    },

    _initPrevNextEditorForCreators: function _initPrevNextEditorForCreators(listName, name, routeName, fieldName) {
      var _this2 = this;

      var sort = void 0;

      if (this.get('_params') && this.get('_params').sortBy) {
        sort = {
          by: this.get('_params').sortBy,
          asc: this.get('_params').sortAsc ? true : undefined
        };
      }

      var params = {
        filter: this.makeFilterParameters(),
        sort: sort,
        page_size: 500
      };

      this.get('prevNextEditor').set('inProcess', true);

      return this.get('store').query(listName, params).then(function (items) {
        if (items.get('length') === 0) {
          _this2.get('prevNextEditor').set('inProcess', false);

          return;
        }
        _this2.get('prevNextEditor').setList(items);
        _this2.get('prevNextEditor').set('name', name);

        var item = items.get('firstObject');
        var itemId = item.get(fieldName);
        if (itemId) {
          _this2.transitionTo(routeName, itemId);
        }

        _this2.get('prevNextEditor').set('inProcess', false);
      });
    }
  });
});