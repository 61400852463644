define('cdata/pods/marks/mark-mixin-show', ['exports', 'cdata/mixins/route-edit', 'cdata/mixins/route-create-modals', 'cdata/pods/marks/mark-mixin'], function (exports, _routeEdit, _routeCreateModals, _markMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_routeEdit.default, _routeCreateModals.default, _markMixin.default, {
    editor: Ember.Object.create({}),

    model: function model(params) {
      return this.get('store').find('mark', params.mark_id);
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (model.get('markPlaces.length')) {
        model.get('markPlaces').forEach(function (mp) {
          mp.buildName();
        });
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('editor', this.get('editor'));
    },


    actions: {
      openMarkForms: function openMarkForms(formName, options) {
        this._clearEditor();
        this.set('editor.options', options);
        this.set('editor.formName', 'marks/edit/' + formName);
        Ember.$('body').css('overflow', 'hidden');
      },
      createMark: function createMark() {
        var _this = this;

        var mark = this.get('store').createRecord('mark');
        this._handleSaving(mark.save()).then(function (mark) {
          _this.transitionTo('marks.show', mark.id);
        });
      },
      error: function error(_error) {
        this.get('flashMessage').showError('Mark #' + this.paramsFor('marks.show').mark_id + ' cannot be found or have loading error' + "\n" + _error);
      }
    }
  });
});