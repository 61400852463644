define('cdata/pods/components/lots/modals/assign-form/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'p',

    title: Ember.computed('item.title', 'item.entityListItems.firstObject.title', function () {
      return this.get('item.title') || this.get('item.entityListItems.firstObject.title');
    }),

    creationDate: Ember.computed.alias('item.entityListItems.firstObject.creation_date'),
    creatorName: Ember.computed.alias('item.entityListItems.firstObject.creator_name')
  });
});