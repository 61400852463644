define('cdata/mixins/editor/forms/reference-number-item', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modal: Ember.inject.service(),

    referenceNumberTitle: Ember.computed('model.{referenceNumberId,_title}', function () {
      return this.get('model._title');
    }),

    loadReferenceNumberFor: 'loadReferenceNumberFor',
    openNewReferenceNumberForm: 'openNewReferenceNumberForm',

    actions: {
      setReferenceNumber: function setReferenceNumber(_, referenceNumberSubject) {
        this.sendAction('loadReferenceNumberFor', this.get('model'), referenceNumberSubject.get('id'));
      },
      createReferenceNumber: function createReferenceNumber(name) {
        this.sendAction('openNewReferenceNumberForm', name);
        this._subscribeOnNewReferenceNumber();
      }
    },

    /**
     * New number created
     * @param {Object} result
     * @private
     */
    _onReferenceNumberSuccess: function _onReferenceNumberSuccess(result) {
      var number = result.item;

      // set number-based subject
      this.send('setReferenceNumber', null, Ember.Object.create({
        id: number.get('id'),
        name: number.get('value')
      }));
    },
    _subscribeOnNewReferenceNumber: function _subscribeOnNewReferenceNumber() {
      this.get('modal').on(_constants.MODALS.REFERENCE_NUMBER + '.saving.success', this, this._onReferenceNumberSuccess).on(_constants.MODALS.REFERENCE_NUMBER + '.close', this, this._unSubscribeOnNewReferenceNumber);
    },
    _unSubscribeOnNewReferenceNumber: function _unSubscribeOnNewReferenceNumber() {
      this.get('modal').off(_constants.MODALS.REFERENCE_NUMBER + '.saving.success', this, this._onReferenceNumberSuccess).off(_constants.MODALS.REFERENCE_NUMBER + '.close', this, this._unSubscribeOnNewReferenceNumber);
    }
  });
});