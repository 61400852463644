define('cdata/models/person-list-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    personId: attr('number'),
    name: attr('string'),
    lotsCount: attr('number'),
    biography: attr('string'),
    disambiguatingComment: attr('string'),
    updatedAt: attr('string'),
    approved: attr('boolean'),
    imageUrl: attr('string'),
    nationality: attr('string'),
    vitalDates: attr('string'),
    lotsTotalUsd: attr('number'),
    role: attr('string')
  });
});