define('cdata/pods/components/sales/modals/import-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.SALE_IMPORT,
    classNames: ['context-modal', _constants.MODALS.SALE_IMPORT, 'edit-form'],
    inProcess: Ember.computed.alias('model.inProcess'),

    isEnabled: Ember.computed.or('model.christiesSaleId', 'model.url'),
    isDisabled: Ember.computed.not('isEnabled'),

    closeImportSaleForm: 'closeImportSaleForm',
    saveImportSaleForm: 'saveImportSaleForm',

    actions: {
      save: function save() {
        this.sendAction('saveImportSaleForm', this.get('model'));
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeImportSaleForm');
      }
    }
  });
});