define('cdata/mixins/components/cdata-categories-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    cdataTree: [],
    cdataList: [],

    initCdataCategoriesTree: function initCdataCategoriesTree() {
      var selectedIds = this.get('model.cdataCategoryIds');

      var categories = this.get('vocab.cdataCategoriesSorted').map(function (item) {
        return Ember.Object.create({
          id: item.id,
          label: item.label,
          parentId: item.parentId,
          selected: selectedIds && selectedIds.indexOf(item.id) !== -1
        });
      });

      categories = [Ember.Object.create({
        id: "0",
        label: "not set",
        parentId: null,
        selected: selectedIds && selectedIds.indexOf("0") !== -1
      })].concat(categories);

      this.set('cdataList', categories);

      var tree = [];
      var roots = categories.rejectBy('parentId');

      roots.forEach(function (item) {
        var parent = item;
        parent.set('children', categories.filterBy('parentId', item.get('id')));
        parent.set('expanded', parent.get('children').filterBy('selected').get('length') > 0);
        tree.push(parent);
      });

      this.set('cdataTree', tree);
    },
    applyCdataCategoryIds: function applyCdataCategoryIds() {
      var ids = this.get('cdataList').filterBy('selected').mapBy('id');
      this.set('model.cdataCategoryIds', ids.length > 0 ? ids : undefined);
      this.applyFilter();
    },


    actions: {
      expandCdataRoot: function expandCdataRoot(item) {
        item.set('expanded', !item.get('expanded'));
      },
      selectCdataItem: function selectCdataItem(item) {
        var tree = this.get('cdataTree');
        var parent = tree.findBy('id', item.get('parentId'));
        item.set('selected', !item.get('selected'));

        if (parent.get('selected') && !item.get('selected')) {
          parent.set('selected', false);
        }

        var allChecked = parent.get('children').rejectBy('selected', true).get('length') === 0;
        if (allChecked) {
          parent.set('selected', true);
        }

        this.applyCdataCategoryIds();
      },
      selectCdataRootItem: function selectCdataRootItem(item) {
        var subSelected = item.get('children').filterBy('selected', true);

        if (item.get('selected') && subSelected.length > 0) {
          item.get('children').forEach(function (sub) {
            sub.set('selected', false);
          });
        } else {
          item.set('selected', !item.get('selected'));
          item.get('children').forEach(function (sub) {
            sub.set('selected', item.get('selected'));
          });
        }

        if (!item.get('expanded')) {
          this.send('expandCdataRoot', item);
        }

        this.applyCdataCategoryIds();
      }
    }
  });
});