define('cdata/mixins/components/cdata-category-is', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sidConstants: Ember.inject.service(),

    cdataCategoryIsFineArt: function cdataCategoryIsFineArt(targetId) {
      var fineArtId = this.get('sidConstants.fineArtVocabularyItemId');
      var entityCdataCategory = this.get('vocab.cdataCategories').findBy('id', targetId);

      return entityCdataCategory && (entityCdataCategory.id === fineArtId || entityCdataCategory.get('parentId') === fineArtId);
    }
  });
});