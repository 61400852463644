define('cdata/pods/components/entities/edit/edit-forms-examinations/note/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms-item', 'moment'], function (exports, _editFormsItem, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsItem.default, {
    setExaminerToExamination: 'setExaminerToExamination',

    actions: {
      setDate: function setDate(date) {
        date = date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null;
        this.set('model.date', date);
      },
      setExaminer: function setExaminer(_, examinerSubject) {
        // this.sendAction('setCreatorToEntityCreator', this.get('model'), creatorSubject);
        this.sendAction('setExaminerToExamination', this.get('model'), examinerSubject);
      }
    }
  });
});