define('cdata/pods/components/comparables/search-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['list-search-header comparables'],

    meta: null,

    gotoPage: 'gotoPage',

    resultsCount: Ember.computed('meta.total_count', function () {
      return this.get('meta.total_count') || 0;
    }),

    actions: {
      gotoPage: function gotoPage() {
        this.sendAction.apply(this, ['gotoPage'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});