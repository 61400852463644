define('cdata/helpers/biography-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.biographyText = biographyText;
  function biographyText(params) {
    var parts = [];
    var nationality = params[0];
    var role = params[1];
    var dateText = params[2];

    if (nationality) {
      parts.push(nationality);
    }

    if (role) {
      parts.push(role);
    }

    if (dateText) {
      parts.push(dateText);
    }

    return parts.join(', ');
  }

  exports.default = Ember.Helper.helper(biographyText);
});