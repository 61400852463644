define('cdata/mirage/config', ['exports', 'cdata/mirage/datasets/vocabularies', 'cdata/mirage/datasets/lots', 'cdata/mirage/datasets/entities', 'cdata/mirage/datasets/sessions', 'cdata/mirage/datasets/sales', 'cdata/mirage/datasets/locations', 'cdata/mirage/datasets/organizations', 'cdata/mirage/datasets/people', 'cdata/mirage/datasets/organization_list_items', 'cdata/mirage/datasets/person_list_items', 'cdata/mirage/datasets/entitiy_list_items', 'cdata/constants', 'ember-inflector'], function (exports, _vocabularies, _lots, _entities, _sessions, _sales, _locations, _organizations, _people, _organization_list_items, _person_list_items, _entitiy_list_items, _constants, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    // These comments are here to help you get started. Feel free to delete them.
    /*
     Config (with defaults).
      Note: these only affect routes defined *after* them!
     */
    // this.urlPrefix = 'http://api-v2-development.cdata.collectrium.com';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing
    /*
     Shorthand cheatsheet:
      this.get('/posts');
     this.post('/posts');
     this.get('/posts/:id');
     this.put('/posts/:id'); // or this.patch
     this.del('/posts/:id');
      http://www.ember-cli-mirage.com/docs/v0.3.x/shorthands/
     */
  };

  exports.testConfig = testConfig;


  var lastEntityTitle = null;

  function testConfig() {
    this.urlPrefix = _constants.API_URL;
    this.namespace = '';

    this.get('/people/:id', function (_, req) {
      return _get_id('person', req);
    });

    this.get('/person_list_items', function () {
      return _person_list_items.default;
    });

    this.get('/entity_list_items', function () {
      return _entitiy_list_items.default;
    });

    this.get('/sids', function () {
      return _vocabularies.sids;
    });

    this.get('/roles', function () {
      return _vocabularies.roles;
    });

    this.get('/vocabulary_items', function () {
      return _vocabularies.vocabulary_items;
    });

    this.get('/vocabularies', function () {
      return _vocabularies.vocabularies;
    });

    this.get('/lots/:id', function (_, req) {
      return _lots.default[req.params.id.toString()];
    });

    this.get('/entities/:id', function (_, req) {
      return _get_id('entity', req);
    });

    this.put('/entities/:id', function (_, req) {
      var resp = _get_id('entity', req);
      resp.entity_titles[0].text = lastEntityTitle;
    });

    this.get('/sessions/:id', function (_, req) {
      return _sessions.default[req.params.id.toString()];
    });

    this.get('/sales/:id', function (_, req) {
      return _sales.default[req.params.id.toString()];
    });

    this.get('/locations/:id', function (_, req) {
      return _locations.default[req.params.id.toString()];
    });

    this.get('/organizations/:id', function (_, req) {
      return _get_id('organization', req);
    });

    this.get('/lot_list_items', function () {
      return { lot_list_items: [] };
    });

    this.get('/sale_list_items', function () {
      return { sale_list_items: [] };
    });

    this.get('/user_reviews/:entity/:id', function () {
      return [];
    });

    this.get('/lots/spectrum/:id', function () {
      return {};
    });

    this.get('/organization_list_items', function () {
      return _organization_list_items.default;
    });

    this.post('/entities', function (_, req) {
      return _post('entity', req);
    });

    this.post('/lots', function () {
      var lot = server.create('lot');

      return { lot: lot.attrs };
    });

    this.put('/lots/:id', function (_, req) {
      var response = JSON.parse(req.requestBody);
      response.lot.id = req.params.id;

      return response;
    });

    this.post('/lot_descriptions', function (_, req) {
      var item = server.create('lot-description', JSON.parse(req.requestBody).lot_description);

      return { lot_description: item.attrs };
    });

    this.post('/lot_titles', function (_, req) {
      var item = server.create('lot-title', JSON.parse(req.requestBody).lot_title);

      return { lot_title: item.attrs };
    });

    this.post('/entity_descriptions', function (_, req) {
      var item = server.create('entity-description', JSON.parse(req.requestBody).entity_description);

      return { entity_description: item.attrs };
    });

    this.delete('/entity_descriptions/:id', function () {
      return '';
    });

    this.post('/entity_titles', function (_, req) {
      var item = server.create('entity-title', JSON.parse(req.requestBody).entity_title);
      lastEntityTitle = item.attrs.text;

      return { entity_title: item.attrs };
    });

    this.put('/entity_titles/:id', function (_, req) {
      var title = server.schema.entityTitles.find(req.params.id);
      var entityTitle = JSON.parse(req.requestBody).entity_title;
      if (!title) {
        entityTitle.id = req.params.id;
        title = server.create('entity-title', entityTitle);
      }
      lastEntityTitle = title.text;

      return { entity_title: title.attrs };
    });

    this.delete('/entity_titles/:id', function () {
      return '';
    });

    this.delete('/entities/:id', function (_, req) {
      return _delete('entity', req);
    });

    this.delete('/lots/:id', function (_, req) {
      return _delete('lot', req);
    });

    this.post('/entity_lots', function (_, req) {
      var item = server.create('entity-lot', JSON.parse(req.requestBody).entity_lot);

      return { entity_lot: item.attrs };
    });

    this.del('/entity_lots/:id', function () {
      return '';
    });

    this.get('/organizations/search', function () {
      return [{
        id: 49034,
        name: 'Doyle New York',
        description: '',
        type: 'organization'
      }];
    });

    this.get('/people/search', function () {
      return [{
        id: 371105,
        name: '14 Taisha 3',
        description: '',
        type: 'person'
      }];
    });

    this.post('/people/merge', function (_, req) {
      _post('person_merge', req);

      var request = JSON.parse(req.requestBody)['person_merge'];
      var listItem = _person_list_items.default.person_list_items.findBy('id', parseInt(request.target_person_id));

      return {
        person_list_item: listItem
      };
    });

    this.post('/organizations/merge', function (_, req) {
      _post('organization_merge', req);

      var request = JSON.parse(req.requestBody)['organization_merge'];
      var listItem = _organization_list_items.default.organization_list_items.findBy('id', parseInt(request.target_organization_id));

      return {
        organization_list_item: listItem
      };
    });

    this.post('/entities/merge', function (_, req) {
      _post('entity_merge', req);

      var request = JSON.parse(req.requestBody)['entity_merge'];
      var listItem = _entitiy_list_items.default.entity_list_items.findBy('id', parseInt(request.target_entity_id));

      return {
        entity_list_item: listItem
      };
    });

    this.post('/bulk_delete/entities', function (_, req) {
      return _post('entities_bulk_delete', req);
    });

    this.post('/people/conversion', function (_, req) {
      var organization = server.create('organization');
      var request = JSON.parse(req.requestBody);
      request.person_conversion.organization_id = organization.id;
      req.requestBody = JSON.stringify(request);

      return _post('person_conversion', req);
    });

    this.post('/organizations/conversion', function (_, req) {
      var person = server.create('person');
      var request = JSON.parse(req.requestBody);
      request.organization_conversion.person_id = person.id;
      req.requestBody = JSON.stringify(request);

      return _post('organization_conversion', req);
    });

    this.post('/upcoming_lots', function () {
      return {
        upcoming_lots: [],
        meta: {}
      };
    });
  }

  function _get_id(model, req) {
    var id = req.params.id.toString();
    var models = (0, _emberInflector.pluralize)(model);

    var item = server.schema[models].find(id);

    if (item) {
      var response = {};
      response[model] = item.attrs;

      return response;
    }

    switch (model) {
      case 'entity':
        return _entities.default[id];
      case 'lot':
        return _lots.default[id];
      case 'person':
        return _people.default[id];
      case 'organization':
        return _organizations.default[id];
    }
  }

  function _post(model, req) {
    var models = (0, _emberInflector.pluralize)(model);
    var _model = Ember.String.underscore(model);

    var attrs = JSON.parse(req.requestBody)[_model];

    var item = null;

    var id = req.params && req.params.id;

    if (id) {
      item = server.schema[models].find(req.params.id);
    }

    if (!item) {
      if (id) {
        attrs.id = id;
      }

      item = server.create(_model.replace('_', '-'), attrs);
    }

    var response = {};
    response[_model] = item.attrs;

    return response;
  }

  function _delete(model, req) {
    var data = _get_id(model, req);
    var props = data[model];
    props.is_deleted = true;
    server.create(model, props);
  }
});