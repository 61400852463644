define('cdata/models/mark-list-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    cdataCategories: attr('array'),
    shapes: attr('array'),
    places: attr('array'),
    makers: attr('array'),
    startYear: attr('number'),
    finishYear: attr('number'),
    dateText: attr('string'),
    typeVocabularyItemId: attr('string'),
    type: attr('string'),
    title: attr('string'),
    titles: attr('string'),
    letters: attr('array'),
    notes: attr('array'),
    symbols: attr('array'),
    materials: attr('array'),
    queryString: attr('string'),
    objectCount: attr('string'),
    markId: attr('number'),
    uuid: attr('string'),
    imageUrl: attr('string'),
    imageCaption: attr('string'),

    makersText: Ember.computed('makers.@each.title', function () {
      return this.get('makers').map(function (maker) {
        return maker.title;
      }).join(', ');
    }),

    shapesText: Ember.computed('shapes.@each.title', function () {
      return this.get('shapes').map(function (shape) {
        return shape.term;
      }).join(', ');
    }),

    materialsText: Ember.computed('materials.@each.title', function () {
      return this.get('materials').map(function (material) {
        return material.term;
      }).join(', ');
    }),

    lettersText: Ember.computed('letters.@each', function () {
      return this.get('letters').join(', ');
    }),

    categoryText: Ember.computed('cdataCategories.@each.title', function () {
      return this.get('cdataCategories').map(function (cat) {
        return cat.term;
      }).join(', ');
    }),

    origins: Ember.computed('places.@each.title', function () {
      return this.get('places').map(function (place) {
        return place.name;
      }).join(', ');
    })
  });
});