define('cdata/pods/reports/components/chart-breadcrumbs/component', ['exports', 'ember-cli-jss', 'cdata/pods/reports/components/chart-breadcrumbs/stylesheet', 'cdata/pods/reports/components/chart-breadcrumbs/declaration'], function (exports, _emberCliJss, _stylesheet, _declaration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCliJss.TaglessJSS, {
    tagName: '',
    stylesheet: _stylesheet.default,
    jssNames: ['root'],

    propTypes: _declaration.default
  });
});