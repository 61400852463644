define('cdata/models/place-list-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    placeId: attr('number'),
    name: attr('string'),
    hierarchy: attr('string'),
    updatedAt: attr('string'),
    startYear: attr('string'),
    finishYear: attr('string'),
    parentId: attr('number')
  });
});