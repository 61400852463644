define('cdata/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.hasClass('crossFadeToggle'), this.toValue(true), this.use('crossFade', {
      duration: 750
    }), this.reverse('crossFade', {
      duration: 750
    }));
  };
});