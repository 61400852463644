define('cdata/mixins/lists/component-list-controls', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['list-search-header'],
    queryString: '',

    changeSorting: 'changeSorting',
    setFilterSearch: 'setFilterSearch',

    filter: Ember.computed.alias('filterParameters'),

    enableRewriteFromFilter: true,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var current = this.get('queryString');
      if ((!current || current.length === 0) && this.get('filter.query')) {
        this.set('queryString', this.get('filter.query'));
      } else if (this.get('enableRewriteFromFilter')) {
        this.set('queryString', this.get('filter.query'));
      }

      if (this.get('filter.query') === undefined) {
        this.set('queryString', '');
      }
    },


    actions: {
      changeQuery: function changeQuery(query) {
        query = query && query.length > 0 ? query : null;
        this.set('queryString', query);

        if (this._debounceQuery) {
          Ember.run.cancel(this._debounceQuery);
        }

        this.set('enableRewriteFromFilter', false);
        this._debounceQuery = Ember.run.debounce(this, this._changeQuery, 250);
      },
      changeSorting: function changeSorting() {
        this.sendAction.apply(this, ['changeSorting'].concat(Array.prototype.slice.call(arguments)));
      },
      setFilterSearch: function setFilterSearch() {
        this.sendAction.apply(this, ['setFilterSearch'].concat(Array.prototype.slice.call(arguments)));
      }
    },

    _changeQuery: function _changeQuery() {
      this.sendAction('setFilterSearch', this.get('queryString'));
      this.set('enableRewriteFromFilter', true);
    }
  });
});