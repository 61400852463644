define('cdata/pods/reports/components/chart-layout/stylesheet', ['exports', 'ember-cli-jss'], function (exports, _emberCliJss) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = new _emberCliJss.StyleSheet({
    chart: {
      margin: [15, 0, 20]
    }
  });
});