define('cdata/pods/components/sales/edit/sale-context/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['manage-sale-context'],

    createLotAndEntity: 'createLotAndEntity',

    saleTitle: Ember.computed.alias('sale.title'),

    someSession: Ember.computed('session.id', 'sale.id', 'sale.sessions.@each.id', function () {
      var sale = this.get('sale');
      if (!sale) {
        return null;
      }

      var session = this.get('session');
      if (!session) {
        var sessions = this.get('sale.sessions');
        if (sessions) {
          session = sessions.get('firstObject');
        }
      }

      return session || null;
    }),

    targetSession: Ember.computed('sale.id', 'session.id', function () {
      if (this.get('sale') && this.get('sale.sessions.length') > 1 && this.get('session')) {
        return this.get('session');
      }

      return null;
    }),

    date: Ember.computed('session.startDate', 'someSession.startDate', function () {
      var session = this.get('session') || this.get('someSession');
      if (session) {
        return (0, _moment.default)(session.get('startDate')).format('ll');
      } else {
        return '';
      }
    }),

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.set('createLotInProcess', false);
    },


    auctionHouse: Ember.computed('sale.organization.organizationTitles.{preferred,text}', function () {
      var titles = this.get('sale.organization.organizationTitles');
      var title = titles && titles.findBy('preferred');

      return title && title.get('text');
    }),

    createLotInProcess: false,

    actions: {
      addLot: function addLot() {
        this.set('createLotInProcess', true);
        this.sendAction('createLotAndEntity', this.get('sale'), this.get('session'));
      }
    }
  });
});