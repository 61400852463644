define('cdata/pods/components/entities/view/predicted-model/options/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userActions: Ember.inject.service(),
    predictedPrices: Ember.computed.alias('userActions.predictedPrices'),
    settings: Ember.computed.alias('predictedPrices.settings'),

    reload: 'reload',

    auctionHouseId: null,
    locationId: null,
    currencyId: null,
    auctionHouseTitle: null,
    locationTitle: null,
    saleTitle: null,
    saleDate: null,
    lowEstimate: null,
    highEstimate: null,
    reservePrice: null,
    lotNumber: null,
    saleNumber: null,
    inProgress: false,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('auctionHouseId', this.get('settings.auctionHouseId') || 35);
      this.set('locationId', this.get('settings.locationId') || 70);
      this.set('auctionHouseTitle', this.get('settings.auctionHouseTitle') || "Christie's");
      this.set('locationTitle', this.get('settings.locationTitle') || "Christie's - New York");
      this.set('saleTitle', this.get('settings.saleTitle') || "Day Sale");
      this.set('saleDate', this.get('settings.saleDate'));
      this.set('lowEstimate', this.get('settings.lowEstimate') || null);
      this.set('highEstimate', this.get('settings.highEstimate') || null);
      this.set('currencyId', this.get('settings.currencyId') || 597);
      this.set('lotNumber', this.get('settings.lotNumber') || "1");
      this.set('saleNumber', this.get('settings.saleNumber') || null);
      this.set('reservePrice', this.get('settings.reservePrice') || null);
    },


    locationParams: Ember.computed('auctionHouseId', function () {
      if (this.get('auctionHouseId')) {
        return {
          organization_id: this.get('auctionHouseId')
        };
      } else {
        return {};
      }
    }),

    actions: {
      setAuctionHouse: function setAuctionHouse(_, ah) {
        this.set('auctionHouseId', ah.get('id'));
        this.set('auctionHouseTitle', ah.get('name'));
        if (ah.get('id') === 35) {
          this.set('locationId', 70);
          this.set('locationTitle', "Christie's - New York");
        } else {
          this.set('locationId', null);
          this.set('locationTitle', null);
        }
      },
      setLocation: function setLocation(_, loc) {
        this.set('locationId', loc.get('id'));
        this.set('locationTitle', loc.get('name'));
      },
      setDateSale: function setDateSale(date) {
        this.set('saleDate', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
      },
      apply: function apply() {
        this.get('predictedPrices').set('settings', {
          enabled: true,
          auctionHouseId: this.get('auctionHouseId'),
          auctionHouseTitle: this.get('auctionHouseTitle'),
          locationId: this.get('locationId'),
          locationTitle: this.get('locationTitle'),
          saleTitle: this.get('saleTitle'),
          saleDate: this.get('saleDate'),
          lowEstimate: this.get('lowEstimate'),
          highEstimate: this.get('highEstimate'),
          currencyId: this.get('currencyId'),
          lotNumber: this.get('lotNumber'),
          saleNumber: this.get('saleNumber'),
          reservePrice: this.get('reservePrice')
        });

        this.sendAction('reload');
      }
    }
  });
});