define('cdata/pods/init/login/controller', ['exports', 'cdata/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNewLoginEnabled = _environment.default.EmberENV.FEATURES.isNewLoginEnabled;
  exports.default = Ember.Controller.extend({
    isNewLoginEnabled: isNewLoginEnabled
  });
});