define('cdata/pods/components/b-icon/stylesheet', ['exports', 'ember-cli-jss'], function (exports, _emberCliJss) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = new _emberCliJss.StyleSheet({
    root: {
      fontSize: 0,
      display: 'inline-block'
    },

    icon: function icon(_ref) {
      var color = _ref.color;

      return {
        color: color,
        fill: 'currentColor',
        transition: ['color', '0.1s']
      };
    }
  }, { index: -1 });
});