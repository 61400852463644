define('cdata/mixins/components/manage-source-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    openSourceAddForm: 'openSourceAddForm',
    deleteSourceFromModel: 'deleteSourceFromModel',
    openSourceForm: 'openSourceForm',
    createSourceFor: 'createSourceFor',
    selectSourceFor: 'selectSourceFor',
    openSourceOnlineForm: 'openSourceOnlineForm',

    actions: {
      openSourceAddForm: function openSourceAddForm() {
        this.sendAction.apply(this, ['openSourceAddForm'].concat(Array.prototype.slice.call(arguments)));
      },
      deleteSourceFromModel: function deleteSourceFromModel() {
        this.sendAction.apply(this, ['deleteSourceFromModel'].concat(Array.prototype.slice.call(arguments)));
      },
      openSourceForm: function openSourceForm() {
        this.sendAction.apply(this, ['openSourceForm'].concat(Array.prototype.slice.call(arguments)));
      },
      createSourceFor: function createSourceFor() {
        this.sendAction.apply(this, ['createSourceFor'].concat(Array.prototype.slice.call(arguments)));
      },
      selectSourceFor: function selectSourceFor() {
        this.sendAction.apply(this, ['selectSourceFor'].concat(Array.prototype.slice.call(arguments)));
      },
      openSourceOnlineForm: function openSourceOnlineForm() {
        this.sendAction.apply(this, ['openSourceOnlineForm'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});