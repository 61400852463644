define('cdata/pods/components/lots/view/lot-header/component', ['exports', 'cdata/mixins/components/load-creator-names', 'cdata/services/natural-sorter'], function (exports, _loadCreatorNames, _naturalSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_loadCreatorNames.default, {
    classNames: 'lots-lot-header',

    prevNextEditor: Ember.inject.service(),
    reviewEditor: Ember.inject.service(),

    openLotForms: 'openLotForms',
    openEntityForms: 'openEntityForms',
    navigateToLotInSale: 'navigateToLotInSale',

    entity: null,

    title: Ember.computed('entityTitle', 'lotTitle', function () {
      if (!Ember.isEmpty(this.get('lotTitle'))) {
        return this.get('lotTitle');
      }

      return this.get('entityTitle');
    }),

    lotTitle: Ember.computed('model.lotTitles.@each.{preferred,text}', function () {
      var title = this.get('model.lotTitles').filterBy('preferred').get('firstObject');

      return title ? title.get('text') : '';
    }),

    entityTitles: Ember.computed.filterBy('entity.entityTitles', 'isDeleted', false),
    entityTitlesPreferred: Ember.computed.filterBy('entityTitles', 'preferred'),
    entityTitle: Ember.computed.alias('entityTitlesPreferred.firstObject.text'),

    artist: Ember.computed('entity.id', 'entity.entityCreators.@each.{label,personId,organizationId,preferred}', function () {
      this.loadCreatorNames(this.get('entity'));
      var entity = this.get('entity');
      if (!entity) {
        return null;
      }
      var creators = this.get('entity.entityCreators');
      creators = creators && creators.filterBy('preferred').sort((0, _naturalSorter.default)('label')).mapBy('label');

      return creators ? creators.join(', ') : '';
    }),

    cdataId: Ember.computed.alias('model.id'),

    measurements: Ember.computed('entity.entityMeasurements.@each.{preferred,text}', function () {
      var entity = this.get('entity');
      if (!entity) {
        return null;
      }
      var note = entity.get('entityMeasurements').filterBy('preferred').get('firstObject');

      return note ? note.get('text') : '';
    }),

    medium: Ember.computed('entity.entityMaterials.@each.{preferred,text}', function () {
      var entity = this.get('entity');
      if (!entity) {
        return null;
      }
      var medium = entity.get('entityMaterials').filterBy('preferred').get('firstObject');

      return medium ? medium.get('text') : '';
    }),

    date: Ember.computed('entity.entityCreationDates.@each.{preferred,text}', function () {
      var entity = this.get('entity');
      if (!entity) {
        return null;
      }
      var date = entity.get('entityCreationDates').filterBy('preferred').get('firstObject');

      return date ? date.get('text') : '';
    }),

    image: Ember.computed('entity.id', 'entity.entityImages.@each.{preferred,image}', function () {
      var entity = this.get('entity');
      if (!entity) {
        return null;
      }
      var image = entity.get('entityImages').filterBy('preferred').get('firstObject');

      return image && image.get('image.url');
    }),

    bgrStyle: Ember.computed('image', function () {
      var image = this.get('image');
      if (image) {
        return Ember.String.htmlSafe('background-image: url(' + image + ')');
      }

      return Ember.String.htmlSafe('');
    }),

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      if (this.get('entity') || this.get('model.entityLots.length') === 0) {
        this.set('navigateToLotInProgress', false);
      }
    },


    actions: {
      showImagesForms: function showImagesForms() {
        this.sendAction('openEntityForms', 'edit-forms-image-relations');
      },
      navigateToLot: function navigateToLot(field) {
        var lotId = this.get('model').get(field);

        this.set('navigateToLotInProgress', true);

        this.sendAction('navigateToLotInSale', lotId);
      }
    }
  });
});