define('cdata/pods/components/site/editor/add-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['add-field'],
    classNameBindings: ['active'],
    active: false,

    editFields: {},

    actions: {
      open: function open() {
        this.set('active', true);
      },
      close: function close() {
        this.set('active', false);
      },
      openForms: function openForms(form) {
        this.sendAction('openForms', 'edit-forms-' + form);
      }
    },

    mouseLeave: function mouseLeave() {
      this.send('close');
    }
  });
});