define('cdata/pods/components/b-icon/declaration', ['exports', 'ember-prop-types'], function (exports, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: _emberPropTypes.PropTypes.string.isRequired,
    color: _emberPropTypes.PropTypes.string,
    size: _emberPropTypes.PropTypes.number,
    width: _emberPropTypes.PropTypes.number,
    height: _emberPropTypes.PropTypes.number,
    iconClass: _emberPropTypes.PropTypes.string
  };
});