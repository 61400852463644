define('cdata/utils/index', ['exports', 'ramda'], function (exports, _ramda) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sortByIds = exports.withoutByIds = exports.createEmberObjectsArray = undefined;
  var createEmberObjectsArray = exports.createEmberObjectsArray = (0, _ramda.pipe)(_ramda.clone, (0, _ramda.map)(function (item) {
    return Ember.Object.create(item);
  }));

  var withoutByIds = exports.withoutByIds = function withoutByIds(list, ids) {
    var idKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
    return list.filter(function (item) {
      return !ids.includes(Ember.get(item, idKey));
    });
  };

  var sortByIds = exports.sortByIds = function sortByIds(ids, records) {
    return ids.map(function (id) {
      return records.findBy('id', parseInt(id));
    });
  };
});