define('cdata/models/lot-list-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    lotId: attr('string'),
    entityId: attr('string'),
    number: attr('string'),
    title: attr('string'),
    lotStatusVocabularyItemId: attr('string'),
    miCategoryVocabularyItemId: attr('string'),
    miCategoryState: attr('string'),

    personId: attr('number'),
    creationDate: attr('string'),
    creatorName: attr('string'),
    creatorVitalDates: attr('string'),
    creatorRole: attr('string'),
    creatorNationality: attr('string'),

    saleId: attr('string'),
    saleNumber: attr('string'),

    sessionId: attr('string'),
    sessionTitle: attr('string'),
    sessionStartDate: attr('string'),
    sessionEndDate: attr('string'),
    sessionStatus: attr('string'),
    sessionStatusVocabularyItemId: attr('string'),

    organizationId: attr('string'),
    auctionHouseTitle: attr('string'),
    auctionHousePlace: attr('string'),

    currencyVocabularyItemId: attr('string'),
    estimateLow: attr('string'),
    estimateHigh: attr('string'),
    estimateLowUsd: attr('string'),
    estimateHighUsd: attr('string'),
    priceSale: attr('string'),
    priceSaleUsd: attr('string'),

    updatedAt: attr('string'),
    shown: attr('boolean'),
    imageUrl: attr('string'),

    entityListItems: attr('array'),

    christiesSpecialistDepartmentIds: attr('array'),
    christiesSpecialistDepartmentName: attr('string')
  });
});