define('cdata/pods/components/entities/edit/edit-forms-creations/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Creator'), {
    setCreatorToEntityCreator: 'setCreatorToEntityCreator',
    openNewPersonOrOrganizationForm: 'openNewPersonOrOrganizationForm',

    creations: Ember.computed.filterBy('model.entityCreators', 'isDeleted', false),

    actions: {
      setCreatorToEntityCreator: function setCreatorToEntityCreator() {
        this.sendAction.apply(this, ['setCreatorToEntityCreator'].concat(Array.prototype.slice.call(arguments)));
      },
      openNewPersonOrOrganizationForm: function openNewPersonOrOrganizationForm() {
        this.sendAction.apply(this, ['openNewPersonOrOrganizationForm'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});