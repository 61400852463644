define('cdata/services/store', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Store = _emberData.default.Store;
  exports.default = Store.extend({
    didSaveRecord: function didSaveRecord(model, args) {
      var fake = model.modelName.indexOf('conversion') !== -1;

      if (fake) {
        args.data.id = Math.ceil(Math.random() * 1000000);
      }

      this._super(model, args);
    }
  });
});