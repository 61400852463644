define('cdata/pods/components/marks/edit/edit-forms-dates/component', ['exports', 'cdata/pods/components/marks/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), {
    actions: {
      textHasChanged: function textHasChanged() {
        return null;
      }
    }
  });
});