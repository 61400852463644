define('cdata/pods/components/entities/view/entity-item/summary-info/component', ['exports', 'cdata/mixins/components/load-creator-names', 'cdata/mixins/editor/summary-info-user-review', 'cdata/mixins/components/render-with-citation', 'cdata/mixins/components/summary-info-comments', 'cdata/utils/clustering'], function (exports, _loadCreatorNames, _summaryInfoUserReview, _renderWithCitation, _summaryInfoComments, _clustering) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var escape = Ember.Handlebars.Utils.escapeExpression;

  exports.default = Ember.Component.extend(_loadCreatorNames.default, _summaryInfoComments.default, _summaryInfoUserReview.default, _renderWithCitation.default, {
    classNames: 'entity-item--info',
    tagName: 'article',

    openEntityForms: 'openEntityForms',
    createEntityForLot: 'createEntityForLot',
    createGemForJewelry: 'createGemForJewelry',
    removeEntityFromLot: 'removeEntityFromLot',
    save: 'save',

    confirmDeleteObjectFromLot: false,

    actions: {
      openForms: function openForms() {
        this.sendAction.apply(this, ['openEntityForms'].concat(Array.prototype.slice.call(arguments)));
      },
      addEntity: function addEntity() {
        this.sendAction('createEntityForLot', this.get('lot'));
      },
      addGem: function addGem() {
        this.sendAction('createGemForJewelry', this.get('model'));
      },
      removeEntityFromLot: function removeEntityFromLot() {
        this.set('confirmDeleteObjectFromLot', true);
      },
      removeEntityFromLotCancel: function removeEntityFromLotCancel() {
        this.set('confirmDeleteObjectFromLot', false);
      },
      removeEntityFromLotConfirm: function removeEntityFromLotConfirm() {
        this.sendAction('removeEntityFromLot', this.get('lot'), this.get('model'));
        this.send('removeEntityFromLotCancel');
      },
      setShown: function setShown() {
        this.set('model.shown', true);
        this.sendAction('save');
      },
      setHidden: function setHidden() {
        this.set('model.shown', false);
        this.sendAction('save');
      }
    },

    otherCreators: Ember.computed('model.entityCreators.@each.{preferred,label,creatorQualifierVocabularyItemId,creationVocabularyItemId,extent,citationLabelVocabularyItemId,userId}', 'displaySettings', function () {
      var _this = this;

      this.loadCreatorNames();
      var creators = this.get('model.entityCreators').rejectBy('preferred');

      return creators.map(function (creator) {
        var id = creator.get('creatorId');
        var type = creator.get('type');
        var qualifierId = creator.get('creatorQualifierVocabularyItemId') || '';
        var filter = id + ',' + type + ',' + qualifierId;

        return {
          sort: creator.get('label'),
          name: _this.renderItemLabelWithCitation(creator, function (item) {
            return _this._creatorName(item);
          }),
          filter: '(creators*' + filter + ')',
          route: creator.get('route'),
          id: id
        };
      }).sortBy('name');
    }),

    preferredCreators: Ember.computed('model.entityCreators.@each.{preferred,label,creatorQualifierVocabularyItemId,creationVocabularyItemId,extent,citationLabelVocabularyItemId,userId}', 'displaySettings', function () {
      var _this2 = this;

      this.loadCreatorNames();
      var creators = this.get('model.entityCreators').filterBy('preferred');

      return creators.map(function (creator) {
        var id = creator.get('creatorId');
        var type = creator.get('type');
        var qualifierId = creator.get('creatorQualifierVocabularyItemId') || '';
        var filter = id + ',' + type + ',' + qualifierId;

        return {
          sort: creator.get('label'),
          name: _this2.renderItemLabelWithCitation(creator, function (item) {
            return _this2._creatorName(item);
          }),
          filter: '(creators*' + filter + ')',
          route: creator.get('route'),
          id: id
        };
      }).sortBy('name');
    }),

    entityLots: Ember.computed('lot.entityLots.@each.entityId', function () {
      if (!this.get('lot') || !this.get('lot.entityLots')) {
        return [];
      }

      return this.get('lot.entityLots').sortBy('entityId');
    }),

    _creatorName: function _creatorName(creator) {
      var parts = [];

      var title = creator.get('label');
      if (creator.get('creatorQualifierVocabularyItemId')) {
        title = this.get('vocab.creatorQualifiersLabels')[creator.get('creatorQualifierVocabularyItemId')] + ' ' + title;
      }
      parts.push(title);

      if (creator.get('extent')) {
        parts.push(creator.get('extent'));
      }

      if (creator.get('creationVocabularyItemId')) {
        parts.push(this.get('vocab.creationTermsLabels')[creator.get('creationVocabularyItemId')]);
      }

      return parts.join(', ');
    },
    buildPreferredItems: function buildPreferredItems(key) {
      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'text';

      return this.renderItemLabelWithCitation(this.get('model.' + key).filterBy('preferred').get('firstObject'), field);
    },
    buildOthersItems: function buildOthersItems(key) {
      var _this3 = this;

      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'text';

      return this.get('model.' + key).rejectBy('preferred').sortBy(field).map(function (item) {
        return _this3.renderItemLabelWithCitation(item, field);
      });
    },
    buildAllItems: function buildAllItems(key) {
      var _this4 = this;

      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'text';

      return this.get('model.' + key).sortBy(field).map(function (item) {
        return _this4.renderItemLabelWithCitation(item, field);
      });
    },


    preferredTitle: Ember.computed('model.entityTitles.@each.{preferred,text}', function () {
      var entityTitles = this.get('model.entityTitles').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, entityTitles);
    }),

    otherTitles: Ember.computed('model.entityTitles.@each.{preferred,text}', function () {
      var entityTitles = this.get('model.entityTitles').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, entityTitles);
    }),

    preferredDate: Ember.computed('model.entityCreationDates.@each.{preferred,text}', function () {
      var dates = this.get('model.entityCreationDates').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, dates);
    }),

    otherDates: Ember.computed('model.entityCreationDates.@each.{preferred,text}', function () {
      var dates = this.get('model.entityCreationDates').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, dates);
    }),

    preferredMediumModel: Ember.computed.alias('model.preferredMediumModel'),

    preferredMedium: Ember.computed('model.entityMaterials.@each.{preferred,text}', function () {
      var mediums = this.get('model.entityMaterials').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, mediums);
    }),

    mediumTerm: Ember.computed.alias('model.preferredMediumTerm'),

    otherMediums: Ember.computed('model.entityMaterials.@each.{preferred,text}', function () {
      var mediums = this.get('model.entityMaterials').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, mediums);
    }),

    preferredMeasurements: Ember.computed('model.entityMeasurements.@each.{preferred,text}', function () {
      var measurements = this.get('model.entityMeasurements').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, measurements);
    }),

    otherMeasurements: Ember.computed('model.entityMeasurements.@each.{preferred,text}', function () {
      var measurements = this.get('model.entityMeasurements').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, measurements);
    }),

    preferredLiterature: Ember.computed('model.entityLiterature.@each.{preferred,text}', function () {
      var literature = this.get('model.entityLiterature').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, literature);
    }),

    otherLiterature: Ember.computed('model.entityLiterature.@each.{preferred,text}', function () {
      var literature = this.get('model.entityLiterature').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, literature);
    }),

    preferredDescription: Ember.computed('model.entityDescriptions.@each.{preferred,text}', function () {
      var descriptions = this.get('model.entityDescriptions').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, descriptions);
    }),

    otherDescriptions: Ember.computed('model.entityDescriptions.@each.{preferred,text}', function () {
      var descriptions = this.get('model.entityDescriptions').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, descriptions);
    }),

    preferredCatalogueNote: Ember.computed('model.entityCatalogueNotes.@each.{preferred,text,citationLabelVocabularyItemId,userId}', 'displaySettings', function () {
      return this.buildPreferredItems('entityCatalogueNotes');
    }),

    otherCatalogueNotes: Ember.computed('model.entityCatalogueNotes.@each.{preferred,text,citationLabelVocabularyItemId,userId}', 'displaySettings', function () {
      return this.buildOthersItems('entityCatalogueNotes');
    }),

    preferredConditionalReport: Ember.computed('model.entityConditionalReports.@each.{preferred,text,citationLabelVocabularyItemId,userId}', 'displaySettings', function () {
      return this.buildPreferredItems('entityConditionalReports');
    }),

    otherConditionalReports: Ember.computed('model.entityConditionalReports.@each.{preferred,text,citationLabelVocabularyItemId,userId}', 'displaySettings', function () {
      return this.buildOthersItems('entityConditionalReports');
    }),

    preferredPlace: Ember.computed('model.entityCreationPlaces.@each.{preferred,_name}', function () {
      var places = this.get('model.entityCreationPlaces').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('_name');
      }, places);
    }),

    otherPlaces: Ember.computed('model.entityCreationPlaces.@each.{preferred,_name}', function () {
      var places = this.get('model.entityCreationPlaces').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('_name');
      }, places);
    }),

    creationPlaceTitle: Ember.computed('isGem', function () {
      return this.get('isGem') ? 'Origin' : 'Creation places';
    }),

    preferredInscription: Ember.computed('model.entityInscriptions.@each.{preferred,text}', function () {
      var inscriptions = this.get('model.entityInscriptions').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, inscriptions);
    }),

    otherInscriptions: Ember.computed('model.entityInscriptions.@each.{preferred,text}', function () {
      var inscriptions = this.get('model.entityInscriptions').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, inscriptions);
    }),

    preferredEdition: Ember.computed('model.entityEditions.@each.{preferred,text}', function () {
      var editions = this.get('model.entityEditions').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, editions);
    }),

    otherEditions: Ember.computed('model.entityEditions.@each.{preferred,text}', function () {
      var editions = this.get('model.entityEditions').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, editions);
    }),

    examinations: Ember.computed('model.entityExaminations.@each.text', function () {
      var examinations = this.get('model.entityExaminations').toArray();

      return (0, _clustering.clusterify)(function (item) {
        return item.get('text');
      }, examinations);
    }),

    colors: Ember.computed('model.entityColors.@each.colorVocabularyItemId', function () {
      var _this5 = this;

      return this.get('model.entityColors').map(function (item) {
        return _this5.get('vocab.colorsLabels')[item.get('colorVocabularyItemId')];
      });
    }),

    shapes: Ember.computed('model.entityShapes.@each.shapeVocabularyItemId', function () {
      var _this6 = this;

      return this.get('model.entityShapes').map(function (item) {
        return _this6.get('vocab.shapesLabels')[item.get('shapeVocabularyItemId')];
      });
    }),

    styles: Ember.computed('model.entityStyles.@each.{styleVocabularyItemId,label}', function () {
      return this.get('model.entityStyles').mapBy('label');
    }),

    preferredExhibition: Ember.computed('model.entityExhibitions.@each.{preferred,text}', function () {
      var exhibitions = this.get('model.entityExhibitions').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, exhibitions);
    }),

    otherExhibitions: Ember.computed('model.entityExhibitions.@each.{preferred,text}', function () {
      var exhibitions = this.get('model.entityExhibitions').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, exhibitions);
    }),

    preferredTreatment: Ember.computed('model.entityTreatmentNotes.@each.{preferred,text,citationLabelVocabularyItemId,userId}', 'displaySettings', function () {
      return this.buildPreferredItems('entityTreatmentNotes');
    }),

    otherTreatments: Ember.computed('model.entityTreatmentNotes.@each.{preferred,text,citationLabelVocabularyItemId,userId}', 'displaySettings', function () {
      return this.buildOthersItems('entityTreatmentNotes');
    }),

    preferredProvenance: Ember.computed('model.entityProvenances.@each.{preferred,text}', function () {
      var provenances = this.get('model.entityProvenances').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, provenances);
    }),

    otherProvenances: Ember.computed('model.entityProvenances.@each.{preferred,text}', function () {
      var provenances = this.get('model.entityProvenances').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, provenances);
    }),

    preferredPreLot: Ember.computed('model.entityPreLots.@each.{preferred,text}', function () {
      var prelots = this.get('model.entityPreLots').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, prelots);
    }),

    otherPreLots: Ember.computed('model.entityPreLots.@each.{preferred,text}', function () {
      var prelots = this.get('model.entityPreLots').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, prelots);
    }),

    christiesBusinessGroup: Ember.computed('model.christiesBusinessGroupVocabularyItemId', function () {
      var termId = this.get('model.christiesBusinessGroupVocabularyItemId');
      if (termId) {
        return this.get('vocab.christiesBusinessGroupsLabels')[termId];
      }

      return '';
    }),

    categoryId: Ember.computed.alias('model.cdataCategoryVocabularyItemId'),

    category: Ember.computed('categoryId', function () {
      return this.get('vocab.cdataCategories').findBy('id', this.get('categoryId'));
    }),

    clarityLabel: Ember.computed('model.gemClarityVocabularyItemId', function () {
      return this.get('vocab.clarityLabels')[this.get('model.gemClarityVocabularyItemId')];
    }),

    gemTypeLabel: Ember.computed('mediumTerm.materialVocabularyItemId', function () {
      return this.get('mediumTerm.materialVocabularyItemId') && this.get('vocab.materialsLabels')[this.get('mediumTerm.materialVocabularyItemId')];
    }),

    colorIntensityLabel: Ember.computed('model.gemColorIntensityVocabularyItemId', function () {
      return this.get('model.gemColorIntensityVocabularyItemId') && this.get('vocab.colorIntensityLabels')[this.get('model.gemColorIntensityVocabularyItemId')];
    }),

    enableMedium: Ember.computed.not('isGem'),
    enableStyles: Ember.computed.not('isGem'),

    jewelryTypeLabel: Ember.computed('model.jewelryTypeVocabularyItemId', function () {
      var typeId = this.get('model.jewelryTypeVocabularyItemId');

      if (!typeId) {
        return;
      }

      var type = this.get('vocab.jewelryTypes').find(function (item) {
        return item.get('id') === typeId;
      });
      var parentType = type && type.get('parentId') && this.get('vocab.jewelryTypes').find(function (item) {
        return item.get('id') === type.get('parentId');
      });

      return Ember.String.htmlSafe([parentType && parentType.get('label'), type && type.get('label')].filter(function (item) {
        return item;
      }).map(escape).join(' 🠖 '));
    }),

    isGem: Ember.computed.alias('model.isGem'),
    isJewelry: Ember.computed.alias('model.isJewelry'),
    isDiamond: Ember.computed.alias('model.isDiamond'),
    isColoredDiamond: Ember.computed.alias('model.isColoredDiamond'),
    enableColors: Ember.computed('isDiamond', 'model.gemColourless', function () {
      return !this.get('isDiamond');
    }),
    disableColorsTitle: Ember.computed('isColoredDiamond', 'isDiamond', function () {
      var title = 'Disabled for object type';

      if (this.get('isDiamond') && !this.get('isColoredDiamond')) {
        title = 'Disabled for non colourless diamonds';
      }

      return title;
    }),

    oneColor: Ember.computed.alias('isGem'),
    oneShape: Ember.computed.alias('isGem'),

    colorLabel: Ember.computed('model.colorModel.colorVocabularyItemId', function () {
      return this.get('model.colorModel.colorVocabularyItemId') && this.get('vocab.colorsLabels')[this.get('model.colorModel.colorVocabularyItemId')];
    }),

    shapeLabel: Ember.computed('model.shapeModel.shapeVocabularyItemId', function () {
      return this.get('model.shapeModel.shapeVocabularyItemId') && this.get('vocab.shapesLabels')[this.get('model.shapeModel.shapeVocabularyItemId')];
    }),

    gemSettingLabel: Ember.computed('model.jewelryGemSettingVocabularyItemId', function () {
      return this.get('vocab.gemSettingsLabels')[this.get('model.jewelryGemSettingVocabularyItemId')];
    }),

    genderLabel: Ember.computed('model.jewelryGenderVocabularyItemId', function () {
      return this.get('vocab.gendersLabels')[this.get('model.jewelryGenderVocabularyItemId')];
    }),

    treatmentTypeLabel: Ember.computed('model.gemTreatmentTypeVocabularyItemId', function () {
      return this.get('vocab.treatmentTypesLabels')[this.get('model.gemTreatmentTypeVocabularyItemId')];
    }),

    treatmentDegreeLabel: Ember.computed('model.gemTreatmentDegreeVocabularyItemId', function () {
      return this.get('vocab.treatmentDegreesLabels')[this.get('model.gemTreatmentDegreeVocabularyItemId')];
    }),

    cutTypeLabel: Ember.computed('model.gemCutVocabularyItemId', function () {
      return this.get('vocab.gemstoneCutsLabels')[this.get('model.gemCutVocabularyItemId')];
    }),

    cutGradeLabel: Ember.computed('model.gemCutGradeVocabularyItemId', function () {
      return this.get('vocab.gemstoneCutGradesLabels')[this.get('model.gemCutGradeVocabularyItemId')];
    }),

    enabledInscription: Ember.computed.not('isGem'),
    enabledEditions: Ember.computed('isGem', 'isJewelry', function () {
      return !this.get('isGem') && !this.get('isJewelry');
    }),

    enableTreatmentType: Ember.computed.alias('model.enableTreatmentType'),
    disableTreatment: Ember.computed.not('enableTreatmentType'),

    cdataTopCategory: Ember.computed('categoryId', function () {
      var category = this.get('category');
      var labels = this.get('vocab.cdataCategoriesLabels');
      var parentId = category && category.get('parentId');

      if (parentId) {
        return labels[parentId];
      } else if (category) {
        return category.get('label');
      }
    }),

    cdataSubCategory: Ember.computed('categoryId', function () {
      var category = this.get('category');
      var parentId = category && category.get('parentId');

      return category && parentId && category.get('label');
    }),

    miCategory: Ember.computed('model.miCategoryVocabularyItemId', function () {
      var termId = this.get('model.miCategoryVocabularyItemId');
      if (termId) {
        return this.get('vocab.miCategoriesLabels')[termId];
      }

      return '';
    }),

    preferredAward: Ember.computed('model.entityAwardNotes.@each.{preferred,text}', function () {
      var award = this.get('model.entityAwardNotes').filterBy('preferred').get('firstObject');

      return award && award.get('text');
    }),

    otherAwards: Ember.computed('model.entityAwardNotes.@each.{preferred,text}', function () {
      var awards = this.get('model.entityAwardNotes').rejectBy('preferred');

      return awards.mapBy('text');
    }),

    preferredReferenceNumber: Ember.computed('model.entityReferenceNumberNotes.@each.{preferred,text}', function () {
      var number = this.get('model.entityReferenceNumberNotes').filterBy('preferred').get('firstObject');

      return number && number.get('text');
    }),

    otherReferenceNumbers: Ember.computed('model.entityReferenceNumberNotes.@each.{preferred,text}', function () {
      var numbers = this.get('model.entityReferenceNumberNotes').rejectBy('preferred');

      return numbers.mapBy('text');
    })
  });
});