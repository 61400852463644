define('cdata/pods/components/ui-modal/component', ['exports', 'cdata/pods/components/ui-modal/declaration'], function (exports, _declaration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    propTypes: _declaration.default,

    classNames: ['ui modal'],
    jssNames: ['root'],

    $element: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var onApprove = this.get('onApprove');
      var onDeny = this.get('onDeny');
      var onHidden = this.get('onHidden');
      var $element = Ember.$(this.element).modal({
        onApprove: onApprove,
        onDeny: onDeny,
        onHidden: onHidden,
        closable: false
      });

      this.set('$element', $element);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var isOpen = this.get('isOpen');
      var $element = this.get('$element');

      $element.modal(isOpen ? 'show' : 'hide');
    },


    getDefaultProps: function getDefaultProps() {
      return {
        isOpen: false,
        onApprove: function onApprove() {},
        onDeny: function onDeny() {},
        onHide: function onHide() {}
      };
    }
  });
});