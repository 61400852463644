define('cdata/pods/components/entities/edit/edit-forms-treatment/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), {
    item: Ember.computed.alias('model'),

    checkGemProperties: 'checkGemProperties',

    enableDegree: Ember.computed.and('model.gemTreatmentTypeVocabularyItemId', 'model.isEmerald'),
    disableDegree: Ember.computed.not('enableDegree'),

    actions: {
      setType: function setType(type) {
        this.set('model.gemTreatmentTypeVocabularyItemId', type && type.get('id'));
        this.sendAction('checkGemProperties');
      }
    }
  });
});