define('cdata/pods/components/people/edit/edit-forms-memberships/component', ['exports', 'cdata/pods/components/people/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('OrganizationMembership'), {
    openVocabularyNewItemForm: 'openVocabularyNewItemForm',
    openNewOrganizationForm: 'openNewOrganizationForm',
    loadOrganizationFor: 'loadOrganizationFor',

    actions: {
      openVocabularyNewItemForm: function openVocabularyNewItemForm() {
        this.sendAction.apply(this, ['openVocabularyNewItemForm'].concat(Array.prototype.slice.call(arguments)));
      },
      openNewOrganizationForm: function openNewOrganizationForm() {
        this.sendAction.apply(this, ['openNewOrganizationForm'].concat(Array.prototype.slice.call(arguments)));
      },
      loadOrganizationFor: function loadOrganizationFor() {
        this.sendAction.apply(this, ['loadOrganizationFor'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});