define('cdata/pods/components/lots/modals/new-export/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.EXPORT_LOTS,
    classNames: ['context-modal', _constants.MODALS.EXPORT_LOTS, 'edit-form'],
    inProcess: Ember.computed.alias('model.inProcess'),
    isDisabled: Ember.computed.alias('failed'),

    countFiltered: Ember.computed.alias('model.meta.count_filtered'),

    hasFilter: Ember.computed('model.parameters', 'hasChecked', function () {
      if (this.get('hasChecked')) {
        return false;
      }

      return Object.entries(this.get('model.parameters')).length > 0;
    }),

    hasChecked: Ember.computed('model.checkedItemsIds.length', function () {
      return this.get('model.checkedItemsIds') && this.get('model.checkedItemsIds.length') > 0;
    }),

    failed: Ember.computed('hasChecked', 'hasFilter', function () {
      return !this.get('hasChecked') && !this.get('hasFilter');
    }),

    fieldsItems: [{
      id: 1,
      label: 'Value 1'
    }, {
      id: 2,
      label: 'Value 2'
    }],

    actions: {
      save: function save() {
        var model = this.get('model');
        this.sendAction('saveExportLotsForm', model);
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeExportLotsForm');
      }
    },

    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
    }
  });
});