define('cdata/pods/components/people/edit/edit-forms-relationships/item/component', ['exports', 'cdata/pods/components/people/edit/mixins/edit-forms-item', 'cdata/pods/components/people/mixins/handle_new_person'], function (exports, _editFormsItem, _handle_new_person) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsItem.default, _handle_new_person.default, {
    modal: Ember.inject.service(),

    roleLabels: Ember.computed.alias('vocab.rolesLabels'),

    openVocabularyNewItemForm: 'openVocabularyNewItemForm',
    openNewPersonForm: 'openNewPersonForm',
    loadPersonFor: 'loadPersonForRelationship',

    relationshipTitle: Ember.computed('model.{_name,_description,toId}', function () {
      var result = this.get('model._name');
      if (this.get('model._description')) {
        result += ' (' + this.get('model._description') + ')';
      }

      return result;
    }),

    actions: {
      setPerson: function setPerson(_, organizationSubject) {
        this.sendAction('loadPersonFor', this.get('model'), organizationSubject.get('id'));
      },
      openVocabularyNewItemForm: function openVocabularyNewItemForm() {
        this.sendAction.apply(this, ['openVocabularyNewItemForm'].concat(Array.prototype.slice.call(arguments)));
      },
      openNewPersonForm: function openNewPersonForm() {
        this.sendAction.apply(this, ['openNewPersonForm'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});