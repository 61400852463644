define('cdata/pods/lots/lot-mixin-show', ['exports', 'cdata/mixins/route-init', 'cdata/mixins/route-edit', 'cdata/mixins/route-create-modals', 'cdata/pods/lots/lot-mixin', 'cdata/mixins/editor/add-comment'], function (exports, _routeInit, _routeEdit, _routeCreateModals, _lotMixin, _addComment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_routeInit.default, _routeEdit.default, _routeCreateModals.default, _lotMixin.default, _addComment.default, {
    editor: Ember.Object.create({}),
    ajax: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      var entityParams = this.paramsFor('entities.subject');
      if (!entityParams || !entityParams.entity_id) {
        var entityLot = model.get('entityLots.firstObject');
        if (entityLot) {
          this.transitionTo('entities.subject', model.get('id'), entityLot.get('entityId'));

          return;
        }
      }

      controller.set('save', this.save.bind(this));
      controller.set('cancel', this.cancel.bind(this));
      controller.set('editor', this.get('editor'));
      controller.set('auditLogs', []);
      controller.set('auditLogsOn', false);
      controller.set('auditLogsLoading', false);

      model.get('session').then(function (session) {
        var saleId = session.get('saleId');
        var sale = _this.get('store').peekRecord('sale', saleId);

        if (sale) {
          controller.set('sale', sale);
        } else {
          _this.get('store').findRecord('sale', saleId).then(function (sale) {
            controller.set('sale', sale);
          });
        }

        controller.set('session', session);
      });
    },
    model: function model(params) {
      var lot = this.get('store').peekRecord('lot', params.lot_id);
      if (lot) {
        return lot;
      }

      return this.get('store').find('lot', params.lot_id);
    },


    actions: {
      openLotForms: function openLotForms(formName) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        this._clearEditor();
        this.set('editor.options', options);
        this.set('editor.formName', 'lots/edit/' + formName);
        Ember.$('body').css('overflow', 'hidden');
      },
      doNavigateToLotInSale: function doNavigateToLotInSale(lotId) {
        var _this2 = this;

        this.get('store').findRecord('lot', lotId).then(function (lot) {
          var entityId = lot.get('entityLots.firstObject.entityId');
          if (entityId) {
            _this2.transitionTo('entities.subject', lot.get('id'), entityId);
          } else {
            _this2.transitionTo('lots.show', lot.get('id'));
            Ember.run.later(function () {
              _this2.get('controller').set('entity', null);
              _this2.activate();
            });
          }
        });
      },
      changeLotSession: function changeLotSession(lot, session) {
        if (!session) {
          return;
        }

        lot.setProperties({
          session: session,
          sessionId: session.get('id')
        });

        this.get('controller').set('session', session);
        this.get('controller').set('sale', session.get('sale'));
      },
      removeEntityFromLot: function removeEntityFromLot() {
        this.get('controller').set('entity', null);

        return true;
      },
      error: function error() {
        this.get('flashMessage').showError('Lot #' + this.paramsFor('lots.show').lot_id + ' cannot be found or have loading error');
      }
    },

    _loadSaleFor: function _loadSaleFor(context, id) {
      this.get('store').findRecord('sale', id).then(function (sale) {
        sale.get('organization').then(function () {
          context.set('sale', sale);
          context.set('saleId', sale.get('id'));
        });
      });
    }
  });
});