define('cdata/mixins/route-edit', ['exports', 'cdata/mixins/route-type-page'], function (exports, _routeTypePage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_routeTypePage.default, {
    _pageType: 'show',
    _savingQnt: 0,

    actions: {
      cancel: function cancel() {
        this.send('reset');
      },
      reset: function reset() {},
      save: function save() {},
      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        this.send('reset');
      },
      loadImageFor: function loadImageFor(model, id) {
        this.get('store').findRecord('image', id).then(function (image) {
          model.setProperties({
            image: image,
            imageId: image.get('id')
          });
        });
      },
      deleteItemRecord: function deleteItemRecord(item) {
        item.deleteRecord();
        this._handleSaving(item.save()).then(function () {}, function () {
          item.rollbackAttributes();
        });
      }
    },

    _clearEditor: function _clearEditor() {
      if (this.get('editor.formName')) {
        this.set('editor.formName', null);
        Ember.$('body').css('overflow', '');
      }
    },
    _onAfterSaveAll: function _onAfterSaveAll() {},
    _handleSavingModal: function _handleSavingModal(promises) {
      var isNeedHandleSuccess = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      return this._handleSaving(promises, isNeedHandleSuccess, true);
    },


    /**
     * Handle saving promises
     * @param {Array|Promise|Ember.RSVP.Promise} [promises]
     * @param {Boolean} [isNeedHandleSuccess]
     * @param {Boolean} [isModal]
     * @returns {Ember.RSVP.Promise}
     */
    _handleSaving: function _handleSaving(promises) {
      var _this = this;

      var isNeedHandleSuccess = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var isModal = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      this.set('_hasSavingErrors', false);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        // nothing
        if (Ember.isEmpty(promises)) {
          resolve();

          _this._savePointStart();
          _this._savePointSuccess(isModal);

          return;
        }

        // array or one
        if (Ember.isArray(promises)) {
          promises = Ember.RSVP.all(promises);
        }

        _this._savePointStart();

        promises.then(function (result) {
          if (isNeedHandleSuccess) {
            _this._handleSavingSuccess();
          }

          resolve(result);

          _this._savePointSuccess(isModal);
        }, function (error) {
          _this._savePointError();
          _this._handleSavingError(error);
          reject(error);
        }).catch(function (error) {
          _this._handleSavingError.bind(_this);
          reject(error);
        }).finally(function () {});
      });
    },
    _setInProcess: function _setInProcess(value) {
      this.set('controller.inProcess', value);
    },
    _savePointStart: function _savePointStart() {
      this._setInProcess(true);
      this._savingQnt++;
      if (this._savingFinish) {
        Ember.run.cancel(this._savingFinish);
        this._savingFinish = null;
      }
    },
    _savePointSuccess: function _savePointSuccess(isModal) {
      this._savingQnt--;
      this._savePointLastCheck(true, isModal);
    },
    _savePointError: function _savePointError() {
      this._savingQnt--;
      this._setInProcess(false);
    },
    _savePointLastCheck: function _savePointLastCheck() {
      var _this2 = this;

      var hasNotErrors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var isModal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (this._savingQnt <= 0) {
        this._savingFinish = Ember.run.later(this, function () {
          _this2._savingQnt = 0;

          if (hasNotErrors) {
            if (!isModal) {
              _this2._clearEditor();
            }
            Ember.run.schedule('afterRender', _this2, _this2._onAfterSaveAll);
          }

          _this2._setInProcess(false);

          _this2._savingFinish = null;
        }, 100);
      }
    },
    _handleSavingError: function _handleSavingError(error) {
      this.get('flashMessage').showError(error);
    },
    _handleSavingSuccess: function _handleSavingSuccess(nothing) {
      nothing = nothing || false;
      if (nothing) {
        this.get('flashMessage').showNotice('Nothing changed');
      } else {
        this.get('flashMessage').showSuccess('Successfully saved!');
      }
    },


    /**
     * Set preferred to model or first object
     *
     * @param {Array|String|Ember.Enumerable} collection
     * @param {Object} [model]
     * @param {Boolean} [multiple] true if collection have a more then one preferred item
     * @private
     */
    _changePreferred: function _changePreferred(collection) {
      var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var multiple = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (typeof collection === 'string') {
        collection = this.get('currentModel.' + collection);
      }

      if (collection.get('length') === 0) {
        return;
      }

      if (!multiple) {
        collection.forEach(function (item) {
          item.set('preferred', false);
        });
      }

      if (model) {
        model.set('preferred', true);

        return;
      }

      var actives = collection.filterBy('isDeleted', false);
      var setToFirst = true;

      if (multiple) {
        var preferred = actives.findBy('preferred');
        if (preferred) {
          // not need set preferred if exist
          setToFirst = false;
        }
      }

      if (setToFirst && actives && actives.get('length') > 0) {
        actives.get('firstObject').set('preferred', true);
      }
    },


    /**
     * Set preferred to model or first object
     *
     * @param {Array|String|Ember.Enumerable} collection
     * @param {Object} [model]
     * @private
     */
    _changePreferredToAlternate: function _changePreferredToAlternate(collection, model) {
      if (typeof collection === 'string') {
        collection = this.get('currentModel.' + collection);
      }

      var actives = collection.filterBy('isDeleted', false).reject(function (item) {
        return model === item;
      });

      if (actives.get('length') === 0) {
        return;
      }

      if (model) {
        model.set('preferred', false);
      }

      actives.get('firstObject').set('preferred', true);
    },
    _removeItemWithPreferred: function _removeItemWithPreferred(item, collectionName) {
      var multiple = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var isPreferred = item.get('preferred');

      item.set('preferred', false);
      item.deleteRecord();

      if (isPreferred) {
        this._changePreferred(collectionName, null, multiple);
      }
    },
    _removeAllItems: function _removeAllItems(collectionName, removeActionName) {
      var _this3 = this;

      var model = this.get('currentModel');

      model.get(collectionName).map(function (item) {
        return item;
      }).forEach(function (item) {
        _this3.send(removeActionName, item);
      });
    },


    /**
     * Find and remove items, if {fieldName} is empty
     * @param {String} collectionName
     * @param {String|Array} fieldName for array we use 'and' operator (delete if empty all fields)
     * @param {String} removeActionName
     * @private
     */
    _removeItemsByEmptyField: function _removeItemsByEmptyField(collectionName, fieldName, removeActionName) {
      var _this4 = this;

      var model = this.get('currentModel');
      var itemsForDelete = [];

      model.get(collectionName).forEach(function (item) {
        if (Ember.isArray(fieldName)) {
          // many fields - must be empty all of them for delete item
          var empty = true;
          fieldName.forEach(function (field) {
            if (!Ember.isEmpty(item.get(field))) {
              empty = false;
            }
          });
          if (empty) {
            itemsForDelete.push(item);
          }
        } else {
          // one field - delete item if it empty
          if (Ember.isEmpty(item.get(fieldName))) {
            itemsForDelete.push(item);
          }
        }
      });

      itemsForDelete.forEach(function (item) {
        _this4.send(removeActionName, item);
      });
    }
  });
});