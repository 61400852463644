define('cdata/pods/components/marks/edit/edit-forms-notes/component', ['exports', 'cdata/pods/components/marks/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Note'), {
    autoCreating: false,

    noteIds: Ember.computed.mapBy('notes', 'id'),

    notes: Ember.computed.filterBy('model.markNotes', 'isDeleted', false),

    text: null,

    actions: {
      add: function add() {
        this.sendAction('addMarkNote', this.get('text'));
        this.set('text', null);
      }
    }
  });
});