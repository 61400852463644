define('cdata/pods/components/site/log-in-auth0/stylesheet', ['exports', 'ember-cli-jss'], function (exports, _emberCliJss) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = new _emberCliJss.StyleSheet({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: [1, 0, 0],
      position: 'absolute',
      width: '100%',
      height: '100vh',
      backgroundColor: 'white',
      zIndex: 10
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 322
    },

    logo: {
      position: 'relative',
      overflow: 'hidden',
      marginBottom: 30,
      width: 282
    },

    icon: {
      width: 325
    },

    logoText: {
      position: 'absolute',
      top: 0,
      left: 188,
      lineHeight: '20px',
      fontFamily: 'Atlas Grotesk',
      fontWeight: 500,
      fontSize: 26,
      WebkitFontSmoothing: 'antialiased'
    },

    buttonWrapper: {
      width: '100%',
      textAlign: 'center'
    },

    buttonNote: {
      marginTop: 16
    },

    logInButton: {
      width: '100%',
      marginBottom: 12
    }
  });
});