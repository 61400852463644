define('cdata/pods/reports/components/chart-breadcrumbs/declaration', ['exports', 'ember-prop-types'], function (exports, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    breadcrumbs: _emberPropTypes.PropTypes.arrayOf(_emberPropTypes.PropTypes.shape({
      name: _emberPropTypes.PropTypes.string.isRequired,
      color: _emberPropTypes.PropTypes.string.isRequired
    })).isRequired
  };
});