define('cdata/pods/sales/show/route', ['exports', 'cdata/mixins/route-edit', 'cdata/mixins/route-create-modals', 'cdata/pods/sales/sale-mixin', 'cdata/mixins/editor/add-comment'], function (exports, _routeEdit, _routeCreateModals, _saleMixin, _addComment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeEdit.default, _routeCreateModals.default, _saleMixin.default, _addComment.default, {
    editor: Ember.Object.create({}),
    service: Ember.inject.service('sales/show'),

    model: function model(params) {
      return this.get('service').fetchModel(params.sale_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var sessionParams = this.paramsFor('sessions.subject');
      if (!sessionParams || !sessionParams.session_id) {
        var session = model.get('sessions.firstObject');
        if (session) {
          this.transitionTo('sessions.subject', model.get('id'), session.get('id'));

          return;
        }
      }

      controller.set('save', this.save.bind(this));
      controller.set('cancel', this.cancel.bind(this));
      controller.set('editor', this.get('editor'));
    },
    afterModel: function afterModel(model) {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('store').query('lot-list-item', {
        filter: {
          sale_ids: [model.get('id')]
        },
        page_size: 5
      }).then(function (lots) {
        _this.get('controller').set('lots', lots);
      });
    },


    actions: {
      openSaleForms: function openSaleForms(formName) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        this._clearEditor();
        this.set('editor.options', options);
        this.set('editor.formName', 'sales/edit/' + formName);
        Ember.$('body').css('overflow', 'hidden');
      },
      error: function error() {
        this.get('flashMessage').showError('Sale #' + this.paramsFor('sales.show').sale_id + ' cannot be found or have loading error');
      },
      removeSession: function removeSession(session) {
        var _this2 = this;

        this.get('store').query('lot-list-item', {
          filter: {
            session_ids: [session.get('id')]
          },
          page_size: 1
        }).then(function (lots) {
          if (lots.get('length') > 0) {
            _this2.get('flashMessage').showError('You can not delete a session which contains any lot');
          } else {
            session.deleteRecord();
            _this2._handleSaving(session.save()).then(function () {
              var sale = session.get('sale');
              session = sale.get('sessions').rejectBy('isDeleted').get('firstObject');
              if (session) {
                _this2.transitionTo('sessions.subject', sale.get('id'), session.get('id'));
              } else {
                _this2.transitionTo('sales.show', sale.get('id'));
              }
            });
          }
        });
      }
    }
  });
});