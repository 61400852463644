define('cdata/pods/components/citations/mixins/computed-text', ['exports', 'cdata/helpers/short-url', 'cdata/helpers/format-url'], function (exports, _shortUrl, _formatUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var escape = Ember.Handlebars.Utils.escapeExpression;

  exports.default = Ember.Mixin.create({
    text: Ember.computed('model.{source,sourceId,sourcePage}', 'model.source{url,title,headline,publicationYear,_place}', function () {
      var parts = [];

      var title = this.get('model.source.title');
      var headline = this.get('model.source.headline');
      var url = this.get('model.source.url');

      if (title) {
        title = escape(title.trim());
      }

      if (title && url) {
        title = '<a href="' + escape(url) + '" target="_blank">' + title + '</a>';
      } else if (url) {
        title = '<a href="' + escape(url) + '" target="_blank">' + _formatUrl.default.compute([_shortUrl.default.compute([url])]) + '</a>';
      }

      if (title) {
        parts.push('<span class="strong">' + title + '</span>');
      }

      if (headline) {
        parts.push('<span class="strong">' + escape(headline.trim()) + '</span>');
      }

      var authorsList = '';
      var authors = this.get('model.source.sourcePersonAuthors');
      if (!authors || authors.get('length') === 0) {
        authors = this.get('model.source.sourcePersonEditors');
      }
      if (authors && authors.get('length') > 0) {
        authorsList = authors.mapBy('_name');
        if (authorsList) {
          authorsList = authorsList.join(', ');
          parts.push(escape(authorsList));
        }
      }

      var publishers = this.get('model.source.sourcePersonPublishers');
      if (!publishers || publishers.get('length') === 0) {
        publishers = this.get('model.source.sourceOrganizationPublishers');
      }
      if (publishers && publishers.get('length') > 0) {
        parts.push(escape(publishers.get('firstObject').get('_name')));
      }

      var place = this.get('model.source._place');
      if (place) {
        parts.push(escape(place));
      }

      var year = this.get('model.source.publicationYear');
      if (year) {
        parts.push(escape(year));
      }

      var page = this.get('model.sourcePage');
      if (page) {
        if (/^\d+$/gi.test(page)) {
          page = 'p.&nbsp;' + page;
        } else if (/^\d+-\d+$/gi.test(page)) {
          page = 'pp.&nbsp;' + page;
        } else {
          page = escape(page);
        }
        parts.push(page);
      }

      return parts.join('; ').htmlSafe();
    })
  });
});