define('cdata/helpers/format-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatPrice = formatPrice;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * Format number to price
   * @example (123456, 2) -> 123,456.00
   * @param {number|string} num value for format
   * @param {number} [precision] precision
   * @returns {string}
   */
  function formatPrice(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        num = _ref2[0],
        precision = _ref2[1];

    if (typeof num === 'undefined' || null === num || 'null' === num || '' === num) {
      return '';
    }

    if (typeof num === 'string' && !new RegExp('^[0-9d,.]+$').test(num)) {
      return num;
    }

    precision = precision > 0 ? precision : 0;
    var n = num,
        delimiter = ".",
        fraction = ",",
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(precision)) + "",
        j = i.length;

    j = j > 3 ? j % 3 : 0;

    return s + (j ? i.substr(0, j) + fraction : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + fraction) + (precision ? delimiter + Math.abs(n - i).toFixed(precision).slice(2) : "");
  }

  exports.default = Ember.Helper.helper(formatPrice);
});