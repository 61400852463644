define('cdata/pods/components/people/edit/edit-forms-awards/component', ['exports', 'cdata/pods/components/people/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Award'), {
    loadAwardFor: 'loadAwardFor',
    openNewAwardForm: 'openNewAwardForm',

    actions: {
      loadAwardFor: function loadAwardFor() {
        this.sendAction.apply(this, ['loadAwardFor'].concat(Array.prototype.slice.call(arguments)));
      },
      openNewAwardForm: function openNewAwardForm() {
        this.sendAction.apply(this, ['openNewAwardForm'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});