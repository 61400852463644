define('cdata/mixins/lists/parameters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Mixin.create({
    defaultSort: {
      key: null,
      asc: false
    },

    _permanentFilter: {},

    /**
     * Make GET parameters for API query
     * - pagination
     * - sorting
     * - filter
     *
     * @param params {Object} query params
     * @returns {Object}
     */
    makeQueryParameters: function makeQueryParameters(params) {
      params = params || {};
      var sorting = {};
      var filter = {};

      params.page = params.page >= 1 ? params.page : 1;
      var pagination = {
        page_number: params.page || 1,
        page_size: params.size || 20
      };

      var sortActive = params.sortBy && params.sortBy !== 'null';
      if (sortActive) {
        sorting = {
          sort: {
            by: params.sortBy,
            asc: params.sortAsc
          }
        };
      }

      // 'defaultSort' always activated
      var defaultSort = this.get('defaultSort');
      if (!sortActive && defaultSort.key) {
        sortActive = true;
        sorting = {
          sort: {
            by: defaultSort.key,
            asc: defaultSort.asc
          }
        };
      }

      if (this.get('sortingParametersCallback')) {
        this.get('sortingParametersCallback')(sortActive, sorting.sort && sorting.sort.by, sorting.sort && sorting.sort.asc);
      }

      if (params.filter) {
        var isEmpty = true;
        for (var key in params.filter) {
          if (!params.filter.hasOwnProperty(key)) {
            continue;
          }
          var value = params.filter[key];
          if (undefined !== value && null !== value) {
            isEmpty = false;
          }
        }
        if (!isEmpty) {
          filter = {
            filter: this._decamelizeObject(this.didReadyFilterParameters(params.filter))
          };
        } else {
          filter = null;
        }
      }

      var addFilter = this.get('_permanentFilter');
      if (addFilter) {
        filter = filter || { filter: {} };
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Object.entries(addFilter)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _ref = _step.value;

            var _ref2 = _slicedToArray(_ref, 2);

            var _key = _ref2[0];
            var _value = _ref2[1];

            filter.filter[_key] = _value;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      return Ember.assign(pagination, sorting, filter);
    },
    makeFilterParameters: function makeFilterParameters() {
      var params = this._decamelizeObject(this.get('filterParameters'));
      var additional = this._decamelizeObject(this.get('_permanentFilter'));

      if (additional) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = Object.entries(additional)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var _ref3 = _step2.value;

            var _ref4 = _slicedToArray(_ref3, 2);

            var key = _ref4[0];
            var value = _ref4[1];

            params[key] = value;
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }

      return params;
    },
    didReadyFilterParameters: function didReadyFilterParameters(filter) {
      return filter;
    },
    _decamelizeObject: function _decamelizeObject(obj) {
      if (!obj || (typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) !== 'object') {
        return obj;
      }

      var result = {};

      for (var key in obj) {
        if (!obj.hasOwnProperty(key)) {
          continue;
        }

        var newKey = key.decamelize();
        var value = obj[key];

        if (value === undefined || value === null) {
          continue;
        }

        if (value === 'true') {
          result[newKey] = true;
          continue;
        }

        if (value === 'false') {
          result[newKey] = false;
          continue;
        }

        if (Ember.isArray(value)) {
          result[newKey] = value.map(this._decamelizeObject.bind(this));
          continue;
        }

        if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
          result[newKey] = this._decamelizeObject(value);
          continue;
        }

        result[newKey] = value;
      }

      return result;
    },
    setPermanentFilterParameter: function setPermanentFilterParameter(key, value) {
      var filter = this.get('_permanentFilter');
      filter[key] = value;
      this.set('_permanentFilter', filter);
    },
    clearPermanentFilterParameters: function clearPermanentFilterParameters() {
      this.set('_permanentFilter', {});
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.set('_permanentFilter', {});
      this.send('bulkCancel');
    }
  });
});