define('cdata/pods/entities/index/route', ['exports', 'cdata/mixins/lists/route-list', 'cdata/pods/entities/merge-mixin', 'cdata/mixins/lists/format-filter-creators', 'cdata/constants'], function (exports, _routeList, _mergeMixin, _formatFilterCreators, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeList.default, _mergeMixin.default, _formatFilterCreators.default, {
    model: function model(params) {
      return this.infinity(params, 'entity-list-item');
    },


    assignFormPath: 'entities.modal.assign',
    assignModalId: _constants.MODALS.ENTITY_ASSIGN,

    actions: {
      edit: function edit(item) {
        this.transitionTo('entities.view', item.get('entityId'));
      },


      /**
       * Assign item to many entities
       * @param {Array} items entities list
       * @param {String} assignOption name of assign option (role, nationality, etc)
       * @param {String} assignValue id of assign subject or option value
       * @param {String} rule rule for replace/alternate/preferred
       * @param {Boolean} isFilter send filter parameters instead checked items
       */
      saveAssignEntityForm: function saveAssignEntityForm(items, assignOption, assignValue, rule, isFilter) {
        var props = this._makeAssignProperties(assignOption, assignValue, isFilter, rule);
        var entityIds = items.map(function (item) {
          return parseInt(item.get('entityId'));
        });

        this._saveAssignUpdate('entities', props, entityIds, _constants.MODALS.ENTITY_ASSIGN + '.saving');
      },
      assignRemoveEntities: function assignRemoveEntities(items) {
        this.send('saveAssignEntityForm', items, 'delete');
      },
      saveItem: function saveItem(item) {
        item.save();
      }
    }
  });
});