define('cdata/pods/components/sales/view/sale-item/section-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['section-field'],

    openForms: 'openForms',

    actions: {
      openForms: function openForms() {
        this.sendAction.apply(this, ['openForms'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});