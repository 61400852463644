define('cdata/pods/components/organizations/edit/menu-context/component', ['exports', 'cdata/mixins/components/menu-context'], function (exports, _menuContext) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_menuContext.default, {
    ajax: Ember.inject.service(),
    userActions: Ember.inject.service(),

    sourceTagsEnabled: Ember.computed.alias('userActions.sourceTags.settings.enabledTags'),
    sourceUsersEnabled: Ember.computed.alias('userActions.sourceTags.settings.enabledUsers'),

    openNewOrganizationForm: 'openNewOrganizationForm',
    openConversionOrganizationForm: 'openConversionOrganizationForm',
    openMergeOrganizationToPersonForm: 'openMergeOrganizationToPersonForm',
    openOrganizationForms: 'openOrganizationForms',

    conversion: false,
    merge: false,
    mergePerson: false,

    reload: 'reload',

    actions: {
      merge: function merge() {
        this.sendAction('openOrganizationForms', 'edit-forms-merge');
        this.closeContextMenuAction();
      },
      add: function add() {
        this.sendAction('openNewOrganizationForm');
        this.closeContextMenuAction();
      },
      conversion: function conversion() {
        this.sendAction('openConversionOrganizationForm', this.get('model'));
        this.closeContextMenuAction();
      },
      toggleSourceTags: function toggleSourceTags() {
        this.get('userActions').toggleSourceTags();
      },
      toggleSourceUsers: function toggleSourceUsers() {
        this.get('userActions').toggleSourceUsers();
      },
      miner: function miner() {
        var _this = this;

        this.set('mineInProgress', true);
        this.get('ajax').post('information_miner', { organization_id: this.get('model.id') }, function () {
          setTimeout(function () {
            _this.sendAction('reload');
          }, 1000);
          _this.get('flashMessage').showSuccess('Mining is complete!');
        }, function (error) {
          _this.get('flashMessage').showSuccess(error);
        }, function () {
          _this.set('mineInProgress', false);
          _this.closeContextMenuAction();
        });
      }
    }
  });
});