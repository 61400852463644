define('cdata/pods/sales/index/route', ['exports', 'cdata/mixins/lists/route-list', 'cdata/pods/sales/sale-mixin-assign'], function (exports, _routeList, _saleMixinAssign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeList.default, _saleMixinAssign.default, {
    defaultSort: {
      key: 'start_date',
      asc: null
    },

    model: function model(params) {
      return this.infinity(params, 'sale-list-item');
    }
  });
});