define('cdata/pods/components/marks/edit/edit-forms-makers/component', ['exports', 'cdata/pods/components/marks/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Maker'), {
    setMakerToMarkMaker: 'setMakerToMarkMaker',
    openNewPersonOrOrganizationForm: 'openNewPersonOrOrganizationForm',

    makers: Ember.computed.filterBy('model.markMakers', 'isDeleted', false),

    actions: {
      setMakerToMarkMaker: function setMakerToMarkMaker() {
        this.sendAction.apply(this, ['setMakerToMarkMaker'].concat(Array.prototype.slice.call(arguments)));
      },
      openNewPersonOrOrganizationForm: function openNewPersonOrOrganizationForm() {
        this.sendAction.apply(this, ['openNewPersonOrOrganizationForm'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});