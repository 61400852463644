define('cdata/pods/components/organizations/view/organization-header/prev-next-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'lots-lot-header--prev-next-editor',

    prevNextOrganizationsEditor: Ember.inject.service(),
    prevNextEditor: Ember.computed.alias('prevNextOrganizationsEditor'),

    currentOrganizationId: null,

    inProcess: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.get('prevNextEditor').changeItem(this.get('organization.id'));
      this.set('currentOrganizationId', this.get('organization.id'));
      this.set('inProcess', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('organization.id') !== this.get('currentOrganizationId')) {
        this.get('prevNextEditor').changeItem(this.get('organization.id'));
        this.set('currentOrganizationId', this.get('organization.id'));
        this.set('inProcess', false);
      }
    },


    actions: {
      gotoOtherOrganization: function gotoOtherOrganization() {
        this.set('inProcess', true);
      },
      closePrevNextEditor: function closePrevNextEditor() {
        this.get('prevNextEditor').doClear();
      }
    }
  });
});