define('cdata/pods/components/lots/edit/mixins/edit-forms', ['exports', 'cdata/mixins/editor/edit-forms-template', 'ember-inflector'], function (exports, _editFormsTemplate, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * @param {String} [key]
   * @returns {*}
   * @function
   */
  function editForms(key) {
    var editor = (0, _editFormsTemplate.default)();

    if (key) {
      editor._key = key;

      var itemsKey = (0, _emberInflector.pluralize)(key);
      editor['items'] = Ember.computed.filterBy('model.lot' + itemsKey, 'isDeleted', false);

      editor['addLot' + key] = 'addLot' + key;
      editor['removeLot' + key] = 'removeLot' + key;
      editor['setPreferredToLot' + key] = 'setPreferredToLot' + key;
      editor['clearLot' + key + 'Section'] = 'clearLot' + key + 'Section';

      editor.actions['add'] = function () {
        var key = this.get('_key');
        this.sendAction.apply(this, ['addLot' + key].concat(Array.prototype.slice.call(arguments)));
      };
      editor.actions['remove'] = function () {
        var key = this.get('_key');
        this.sendAction.apply(this, ['removeLot' + key].concat(Array.prototype.slice.call(arguments)));
      };
      editor.actions['setPreferred'] = function () {
        var key = this.get('_key');
        this.sendAction.apply(this, ['setPreferredToLot' + key].concat(Array.prototype.slice.call(arguments)));
      };
      editor.actions['clear'] = function () {
        var key = this.get('_key');
        this.sendAction.apply(this, ['clearLot' + key + 'Section'].concat(Array.prototype.slice.call(arguments)));
      };
    }

    return Ember.Mixin.create(editor);
  }

  exports.default = editForms;
});