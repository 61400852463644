define('cdata/services/vocab', ['exports', 'cdata/services/natural-sorter'], function (exports, _naturalSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    sidConstants: Ember.inject.service(),

    originalRoles: [],
    languageIds: [],
    diamondIds: [],
    diamondColors: [],
    treatmentMaterialIds: [],
    materialMinerals: [],
    clusters: [],

    init: function init() {
      this._super.apply(this, arguments);

      /**
       * Mapping id to label for all vocabularies
       * For example:
       *    roles: [{id: '1', label: 'Artist'}, {..}, {..}]
       *    rolesLabels: {'1': 'Artist', '2': 'Photographer', ...}
       *
       * Add sorting computed properties
       * For example:
       *    roles: [{id: '1', label: 'Artist'}, {..}, {..}]
       *    rolesSorted: -> {sorted by label asc}
       */
      var map = this.get('vocabularyMap');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = map.keys()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var key = _step.value;

          this.set(key + 'Labels', Ember.computed(key + '.length', this._collectLabels.bind(this, key)));
          this.set(key + 'Sorted', Ember.computed(key + '.length', this._sortingItems.bind(this, key)));
          this.set(key + 'SortedAsTree', Ember.computed(key + '.length', this._sortingItemsAsTree.bind(this, key)));
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },


    /**
     * Load all static vocabularies
     */
    doLoad: function doLoad() {
      this.loadVocabularyItems('roles');
      this.loadAllItems();
    },


    /**
     * Vocabularies is loaded
     */
    isLoaded: Ember.computed.and('currencies.length', 'miCategories.length', 'cdataCategories.length', 'roles.length', 'vocabularies.length', 'languages.length', 'languageIds.length',
    // 'diamondIds.length',
    'nationalities.length', 'shapes.length', 'colors.length', 'contactTypes.length', 'academicDegrees.length', 'academicSpecializations.length', 'sourceTypes.length', 'nameLabels.length', 'estimateTypes.length', 'priceTypes.length', 'lotStatuses.length', 'sessionStatuses.length', 'creationTerms.length', 'creatorQualifiers.length', 'measurementTypes.length', 'measurementUnits.length', 'conventionTypes.length', 'inscriptionTypes.length', 'markSymbols.length', 'christiesBusinessGroups.length', 'saleChannels.length', 'clarity.length', 'treatmentTypes.length', 'treatmentDegrees.length', 'gemstoneCuts.length', 'gemstoneCutGrades.length', 'jdeDepartments.length', 'christiesSpecialists.length', 'lotSymbols.length', 'threeDigitCodes.length', 'citationLabels.length', 'colorIntensity.length', 'gemSettings.length', 'genders.length', 'jewelryTypes.length', 'examinationTypes.length', 'placeTypes.length', 'clusters.length'),

    findItemById: function findItemById(id) {
      var exist = this.get('store').peekRecord('vocabulary-item', id);
      if (exist) {
        return new Ember.RSVP.Promise(function (resolve) {
          resolve(exist);
        });
      } else {
        return this.get('store').findRecord('vocabulary-item', id);
      }
    },


    vocabularyMap: new Map([['sources', ''], ['localGenders', ''], ['nationalities', ''], ['shapes', ''], ['colors', ''], ['nameLabels', ''], ['sourceTypes', ''], ['languages', ''], ['languageIds', ''], ['diamondIds', ''], ['diamondColors', ''], ['treatmentMaterialIds', ''], ['materialMinerals', ''], ['contactTypes', ''], ['academicDegrees', ''], ['academicSpecializations', ''], ['miCategories', ''], ['currencies', ''], ['cdataCategories', ''], ['sessionStatuses', ''], ['estimateTypes', ''], ['priceTypes', ''], ['lotStatuses', ''], ['creationTerms', ''], ['creatorQualifiers', ''], ['materials', ''], ['measurementTypes', ''], ['measurementUnits', ''], ['conventionTypes', ''], ['inscriptionTypes', ''], ['markSymbols', ''], ['christiesBusinessGroups', ''], ['saleChannels', ''], ['placeTypes', ''], ['jewelryTypes', ''], ['treatmentTypes', ''], ['treatmentDegrees', ''], ['gemstoneCuts', ''], ['gemstoneCutGrades', ''], ['jdeDepartments', ''], ['christiesSpecialists', ''], ['lotSymbols', ''], ['threeDigitCodes', ''], ['citationLabels', ''], ['colorIntensity', ''], ['genders', ''], ['gemSettings', ''], ['clarity', ''], ['examinationTypes', ''], ['roles', ''], ['clusters', '']]),

    localGenders: [Ember.Object.create({ id: 'male', label: 'Male' }), Ember.Object.create({ id: 'female', label: 'Female' })],

    sources: [Ember.Object.create({ id: 'christies', label: "Christie's" }), Ember.Object.create({ id: 'auction_club', label: 'Auction Club' }), Ember.Object.create({ id: 'artron', label: 'Artron' }), Ember.Object.create({ id: 'admin', label: 'Admin' }), Ember.Object.create({ id: 'sothebys', label: 'Sothebys' }), Ember.Object.create({ id: 'jde', label: 'JDE' }), Ember.Object.create({ id: 'phillips', label: 'Phillips' }), Ember.Object.create({ id: 'bonhams', label: 'Bonhams' })],

    continents: [Ember.Object.create({ id: '1', label: 'Africa' }), Ember.Object.create({ id: '2', label: 'Asia' }), Ember.Object.create({ id: '3', label: 'Australia' }), Ember.Object.create({ id: '4', label: 'Europe' }), Ember.Object.create({ id: '5', label: 'North America' }), Ember.Object.create({ id: '6', label: 'South America' })],

    vocabularies: Ember.computed(function () {
      return this.loadVocabularies();
    }),

    roles: Ember.computed('originalRoles.length', function () {
      return this.get('originalRoles').map(function (item) {
        return Ember.Object.create({
          id: item.get('id'),
          label: item.get('name')
        });
      });
    }),

    _collectLabels: function _collectLabels(key) {
      var labels = {};
      this.get(key).forEach(function (item) {
        labels[item.get('id')] = item.get('label');
      });

      return labels;
    },
    _sortingItems: function _sortingItems(key) {
      return this.get(key).sort((0, _naturalSorter.default)('label'));
    },
    _sortingItemsAsTree: function _sortingItemsAsTree(key) {
      var _this = this;

      var items = this.get(key).sort((0, _naturalSorter.default)('label'));
      var tree = [];

      items.forEach(function (item) {
        if (!item.get('parentId') || _this.get(key).startingParentId === item.get('parentId')) {
          _this._addItemToTree(tree, item, items, 0);
        }
      });

      return tree;
    },
    _addItemToTree: function _addItemToTree(tree, item, items, level) {
      var _this2 = this;

      item.set('level', level);
      tree.push(item);

      items.filter(function (child) {
        return child.get('parentId') === item.get('id');
      }).forEach(function (child) {
        _this2._addItemToTree(tree, child, items, level + 1);
      });
    },
    loadVocabularies: function loadVocabularies() {
      return this.get('store').query('vocabulary', {
        disable_pagination: true
      });
    },
    reloadVocabularies: function reloadVocabularies() {
      this.set('vocabularies', this.loadVocabularies());
    },


    /**
     * load vocabulary items by vocabulary id,
     * and save them to this service as id+label format
     *
     * Example
     *  this.loadVocabularyItems('priceTypes')
     *  ...
     *  this.get('priceTypes') -> [ {id: 1, label: 'Fix price', parentId: null}, { ... } ]
     *
     * @param {String} name
     */
    loadVocabularyItems: function loadVocabularyItems(name) {
      var _this3 = this;

      if ('roles' === name) {
        // 'roles' load separately
        return this.get('store').query('role', {}).then(function (roles) {
          _this3.set('originalRoles', roles);

          return roles;
        });
      }

      var vocabularyId = this.get('sidConstants').getVocabId(name.underscore());
      (true && !(vocabularyId) && Ember.assert('Vocabulary Id by name ' + name + ' not found', vocabularyId));


      return this.getItemsByVocabularyId(vocabularyId).then(function (items) {
        _this3.set(name, items.map(function (item) {
          _this3._makeVocabUnit(name, item, items);
        }));
        if (name === 'languages') {
          _this3.set('languageIds', items.map(function (item) {
            _this3._makeVocabUnit('languageIds', item, items);
          }));
        }
      });
    },
    reloadItemsByVocabularyId: function reloadItemsByVocabularyId(id) {
      var name = this.get('sidConstants').getVocabNameById(id);

      return name ? this.loadVocabularyItems(name) : this.getItemsByVocabularyId(id);
    },


    /**
     * Loading all vocabulary items and building the simple (id+label format) service vocabularies
     * with binding to properties as names (priceTypes, lotStatuses etc)
     * Example:
     *  this.loadAllItems()
     *  ....
     *  this.get('priceTypes') -> [ {id: 1, label: 'Fix price', parentId: null}, { ... } ]
     *  this.get('lotStatuses') -> [ {id: 1, label: 'Sold', parentId: null}, { ... } ]
     */
    loadAllItems: function loadAllItems() {
      var _this4 = this;

      var sids = this.get('sidConstants');
      var map = this.get('vocabularyMap');
      var ids = [];

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = map.keys()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var key = _step2.value;

          if (['roles', 'localGenders', 'sources', 'languageIds', 'diamondIds', 'diamondColors', 'treatmentMaterialIds', 'materialMinerals'].indexOf(key) !== -1) {
            continue;
          }

          var vocabId = sids.getVocabId(key.underscore());
          ids.push(vocabId);
          this.set(key, []);
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      this.get('store').query('vocabulary-item', { filter: { vocabulary_ids: ids } }).then(function (items) {
        items.forEach(function (vocabularyItem) {
          var name = sids.getVocabNameById(vocabularyItem.get('vocabularyId'));
          if (_this4.get(name)) {
            _this4.get(name).pushObject(_this4._makeVocabUnit(name, vocabularyItem, items));
            if (name === 'languages') {
              _this4.get('languageIds').pushObject(_this4._makeVocabUnit('languageIds', vocabularyItem, items));
            }
          }
        });

        _this4._makeDiamondColors();
        _this4._makeTreatmentMaterialIds();
        _this4._addItemsByParent(_this4.get('materialMinerals'), _this4.get('materials'), _this4.get('sidConstants.materialMineralId'));
        _this4.get('materialMinerals').startingParentId = _this4.get('sidConstants.materialMineralId');
      });

      var diamondId = this.get('sidConstants').get('materialDiamondId');
      this.get('store').query('vocabulary-item', { filter: { parent_id: diamondId } }).then(function (items) {
        _this4.set('diamondIds', items.mapBy('id')).push(diamondId);
      });
    },
    _makeDiamondColors: function _makeDiamondColors() {
      var _this5 = this;

      this.get('colors').forEach(function (item) {
        item = _this5.get('store').peekRecord('vocabulary-item', item.get('id'));
        item.get('vocabularyItemMappings').forEach(function (mapItem) {
          mapItem = _this5.get('cdataCategories').findBy('id', mapItem.get('toId'));
          if (mapItem && mapItem.get('id') === _this5.get('sidConstants.cdataCategoryGemId')) {
            _this5.get('diamondColors').push(_this5._makeVocabUnit('diamondColors', item, []));
          }
        });
      });
    },
    _makeTreatmentMaterialIds: function _makeTreatmentMaterialIds() {
      var treatmentIds = this.get('sidConstants.treatmentIds');
      var ids = [];

      this.get('materials').forEach(function (item) {
        if (treatmentIds.indexOf(item.get('id')) !== -1 || treatmentIds.indexOf(item.get('parentId')) !== -1) {
          ids.push(item.get('id'));
        }
      });

      this.set('treatmentMaterialIds', ids);
    },
    _addItemsByParent: function _addItemsByParent(collect, items, parentId) {
      var _this6 = this;

      items.filter(function (item) {
        return item.get('parentId') === parentId;
      }).forEach(function (item) {
        collect.push(item);
        _this6._addItemsByParent(collect, items, item.get('id'));
      });
    },


    /**
     * Make one vocabularyItem to internal vocabulary format
     * @param {String} name vocabulary name
     * @param {Object} item vocabularyItem
     * @param {Array} allItems all vocabularyItems
     * @returns {Object}
     */
    _makeVocabUnit: function _makeVocabUnit(name, item, allItems) {
      var _this7 = this;

      var id = item.get('id');
      var label = item.get('_name');

      if ('languages' === name) {
        id = item.get('_name');
        label = item.get('_secondName') + ' [' + item.get('_name') + ']';
      }

      if ('languageIds' === name) {
        label = item.get('_secondName') + ' [' + item.get('_name') + ']';
      }

      var result = Ember.Object.create({
        id: id,
        label: label,
        parentId: item.get('parentId'),
        description: item.get('_secondName')
      });

      if ('miCategories' === name) {
        item.get('vocabularyItemMappings').forEach(function (mapItem) {
          mapItem = allItems.findBy('id', mapItem.get('toId'));
          if ('christiesBusinessGroups' === _this7.get('sidConstants').getVocabNameById(mapItem.get('vocabularyId'))) {
            result.set('cdataBusinessCategoryVocabularyItemId', mapItem.get('id'));
          }
        });
      }

      return result;
    },
    getItemsByVocabularyId: function getItemsByVocabularyId(vocabularyId) {
      return this.get('store').query('vocabulary-item', {
        filter: {
          vocabulary_ids: [vocabularyId]
        },
        disable_pagination: true
      });
    },
    getItemsByIds: function getItemsByIds(ids, vocabName) {
      var _this8 = this;

      if (ids && Ember.isArray(ids)) {
        return ids.map(function (id) {
          return _this8.get(vocabName).findBy('id', id);
        });
      }

      return [];
    },
    getItemById: function getItemById(id, vocabName) {
      var items = this.getItemsByIds([id], vocabName);

      return items && items.length === 1 && items[0];
    }
  });
});