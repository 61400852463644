define('cdata/pods/components/page/header-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['header-panel'],
    createArtist: 'createArtist',
    createLot: 'createLot',
    createOrganization: 'createOrganization',
    createSale: 'createSale',

    actions: {
      createArtist: function createArtist() {
        this.sendAction.apply(this, ['createArtist'].concat(Array.prototype.slice.call(arguments)));
      },
      createLot: function createLot() {
        this.sendAction.apply(this, ['createLot'].concat(Array.prototype.slice.call(arguments)));
      },
      createOrganization: function createOrganization() {
        this.sendAction.apply(this, ['createOrganization'].concat(Array.prototype.slice.call(arguments)));
      },
      createSale: function createSale() {
        this.sendAction.apply(this, ['createSale'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});