define('cdata/pods/vocabularies/index/route', ['exports', 'cdata/mixins/lists/route-list', 'cdata/pods/vocabularies/edit-mixin'], function (exports, _routeList, _editMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Route.extend(_routeList.default, _editMixin.default, {
    model: function model(params) {
      this.initOneVocabulary(params);

      return this.infinity(params, 'vocabulary-item-list-item');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      if (!controller.get('vocabularies')) {
        controller.set('vocabularies', this.get('store').query('vocabulary', {}));
      }

      controller.set('editor', this.get('editor'));
    },


    actions: {
      openForm: function openForm(item) {
        var _this = this;

        if ((typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object') {
          this._openItemForm(item);
        } else {
          this.get('store').findRecord('vocabulary-item', item).then(function (item) {
            _this._openItemForm(item);
          });
        }
      },
      openFormVocabulary: function openFormVocabulary() {
        var vocabulary = this.get('editor.vocabulary');
        this._clearEditor();
        this.set('editor.item', vocabulary);
        this.set('editor.formName', 'vocabularies/edit/edit-forms-vocabulary');
        $('body').css('overflow', 'hidden');
      },
      loadVocabularyItemsFor: function loadVocabularyItemsFor(target, vocabularyId) {
        target.set('items', this.get('store').query('vocabulary-item', {
          filter: {
            vocabulary_ids: [vocabularyId]
          }
        }));
      },
      loadVocabularyItemParentFor: function loadVocabularyItemParentFor(target) {
        if (!target.get('parentId')) {
          target.set('parent', null);
          target.set('parentId', null);
        }
        this.get('store').findRecord('vocabulary-item', target.get('parentId')).then(function (item) {
          target.set('parent', item);
        });
      },
      loadVocabulariesFor: function loadVocabulariesFor(target) {
        target.set('items', this.get('store').query('vocabulary', {}));
      }
    },

    initOneVocabulary: function initOneVocabulary(params) {
      var filter = this.parseFilterParameters(params.filter);
      if (filter.vocabularyIds && filter.vocabularyIds.length === 1) {
        this.set('editor.vocabulary', this.get('store').findRecord('vocabulary', filter.vocabularyIds[0]));
      } else {
        this.set('editor.vocabulary', null);
      }
    },
    _openItemForm: function _openItemForm(item) {
      if (this.get('controller.inProcess', false)) {
        setTimeout(this._openItemForm.bind(this, item), 200);

        return;
      }

      $('body').css('overflow', 'hidden');
      this.set('editor.item', item);
      this.set('editor.formName', 'vocabularies/edit/edit-forms-item');
    }
  });
});