define('cdata/pods/components/editions/modals/create-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var eventKey = _constants.MODALS.EDITION + '.saving';

  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.EDITION,
    classNames: ['context-modal', _constants.MODALS.EDITION, 'edit-form'],
    inProcess: false,

    actions: {
      save: function save() {
        var model = this.get('model');
        this.sendAction('saveNewEditionForm', model);
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeNewEditionForm');
      }
    },

    _onProcess: function _onProcess() {
      this.set('inProcess', true);
    },
    _onFinally: function _onFinally() {
      this.set('inProcess', false);
    },
    _onSuccess: function _onSuccess() {
      this.send('close');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('modal').on(eventKey + '.process', this, this._onProcess).on(eventKey + '.success', this, this._onSuccess).on(eventKey + '.finally', this, this._onFinally);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      this.get('modal').off(eventKey + '.process', this, this._onProcess).off(eventKey + '.success', this, this._onSuccess).off(eventKey + '.finally', this, this._onFinally);
    }
  });
});