define('cdata/pods/components/site/merge-form/items/item-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    mergeList: Ember.inject.service(),

    classNameBindings: ['selected'],

    inProcess: false,

    selected: Ember.computed('mergeList.target.id', 'item.id', function () {
      return this.get('mergeList.target.id') === this.get('item.id');
    }),

    actions: {
      selectForMerge: function selectForMerge() {
        if (this.get('inProcess')) {
          return;
        }
        this.get('mergeList').setTarget(this.get('item'));
      }
    }
  });
});