define('cdata/mixins/audit-logs/audit-log-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      doLoadAuditLogs: function doLoadAuditLogs(params, callback) {
        this.get('store').query('audit-schema-log', params).then(callback);
      }
    }
  });
});