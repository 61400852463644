define('cdata/services/review-editor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    inProcess: false,

    list: [],

    position: null,

    item: Ember.computed('list.length', 'position', function () {
      if (this.get('position') != null && this.get('list.length') > 0) {
        return this.get('list').objectAt(this.get('position'));
      }
    }),

    review: Ember.computed('reviews.length', 'position', 'item', function () {
      var item = this.get('item');

      return this.get('reviews').findBy('id', parseInt(item));
    }),

    prev: Ember.computed('list.length', 'position', function () {
      if (this.get('position') != null && this.get('list.length') > 0) {
        return this.get('list').objectAt(this.get('position') - 1);
      }
    }),

    next: Ember.computed('list.length', 'position', function () {
      if (this.get('position') != null && this.get('list.length') > 0) {
        return this.get('list').objectAt(this.get('position') + 1);
      }
    }),

    prevEntity: null,
    prevEntityId: Ember.computed('prev', function () {
      if (!this.get('prev')) {
        return null;
      }
      var review = this.get('reviews').findBy('id', parseInt(this.get('prev')));

      return review && review.entity_id;
    }),
    prevEntityType: Ember.computed('prev', function () {
      if (!this.get('prev')) {
        return null;
      }
      var review = this.get('reviews').findBy('id', parseInt(this.get('prev')));

      return review && review.entity;
    }),

    nextEntity: null,
    nextEntityId: Ember.computed('next', function () {
      if (!this.get('next')) {
        return null;
      }
      var review = this.get('reviews').findBy('id', parseInt(this.get('next')));

      return review && review.entity_id;
    }),
    nextEntityType: Ember.computed('next', function () {
      if (!this.get('next')) {
        return null;
      }
      var review = this.get('reviews').findBy('id', parseInt(this.get('next')));

      return review && review.entity;
    }),

    setList: function setList(list, reviews) {
      this.set('list', list);
      this.set('reviews', reviews);
      this.set('position', 0);
    },
    changeItem: function changeItem(entity, entityId) {
      var _this = this;

      if (!this.get('list') || this.get('list.length') === 0 || this.get('position') === null) {
        return;
      }

      var review = this.get('reviews').find(function (item) {
        return item.entity === entity && item.entity_id === parseInt(entityId);
      });

      var index = 0;
      this.get('list').forEach(function (elem) {
        if (elem === review.id + '') {
          _this.set('position', index);
          _this.loadDescriptions();
        }
        index++;
      });
    },
    doClear: function doClear() {
      this.set('list', []);
      this.set('position', null);
    },
    loadDescriptions: function loadDescriptions() {
      var _this2 = this;

      this.set('prevEntity', null);
      if (this.get('prev')) {
        this.get('ajax').$get('user_reviews/description/' + this.get('prev'), {}, function (data) {
          return _this2.set('prevEntity', data);
        });
      }

      this.set('nextEntity', null);
      if (this.get('next')) {
        this.get('ajax').$get('user_reviews/description/' + this.get('next'), {}, function (data) {
          return _this2.set('nextEntity', data);
        });
      }
    }
  });
});