define('cdata/storages/upcoming-lots-settings', ['exports', 'ember-local-storage/local/object', 'moment'], function (exports, _object, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _object.default.extend();

  // Uncomment if you would like to set initialState
  Storage.reopenClass({
    initialState: function initialState() {
      return {
        settings: {
          maker_weight: 5,
          image_weight: 5,
          title_weight: 1,
          sale_title_weight: 1,
          sale_date_from: (0, _moment.default)((0, _moment.default)()).add(1, 'days').format('YYYY-MM-DD'),
          sale_date_to: null,
          measurement_weight: 1,
          creation_year_weight: 1,
          medium_weight: 1,
          price_weight: 1,
          category_weight: 1
        }
      };
    }
  });

  exports.default = Storage;
});