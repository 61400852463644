define('cdata/mixins/create-modals/source', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    relations: Ember.inject.service(),

    actions: {
      /**
       * Modal window for edit source
       * @param {Object} model
       * @param {String} [sourceTitle]
       */
      openSourceForm: function openSourceForm(model, sourceTitle) {
        var source = null;

        if (typeof sourceTitle === 'string') {
          source = this.get('store').createRecord('source', {
            title: sourceTitle
          });
        }

        if (!source) {
          source = model.get('source');
        }

        var form = Ember.Object.create({
          _model: model,
          sourcePage: model.get('sourcePage'),
          source: source
        });

        this.get('modal').register(this, _constants.MODALS.SOURCE, 'source-citation.edit').open(form);
      },


      /**
       * Modal window for create online source
       * @param {Object} model
       * @param {String} [url]
       */
      openSourceOnlineForm: function openSourceOnlineForm(model, url) {
        var source = void 0;
        var check = /^https?:\/\//.test(url);
        if (!url || !check) {
          this.get('flashMessage').showError('Please insert correct URL for create new online source');

          return;
        }

        source = this.get('store').createRecord('source', {
          url: url
        });

        var form = Ember.Object.create({
          _model: model,
          sourcePage: null,
          source: source
        });

        this.get('modal').register(this, _constants.MODALS.SOURCE, 'source-citation.edit').open(form);
      },


      /**
       * Modal window for add other source
       * @param {Object} model
       */
      openSourceAddForm: function openSourceAddForm(model) {
        var form = Ember.Object.create({
          _model: model,
          sourcePage: null,
          source: null
        });

        this.get('modal').register(this, _constants.MODALS.SOURCE, 'source-citation.edit').open(form);
      },
      closeSourceForm: function closeSourceForm() {
        this.get('modal').close(_constants.MODALS.SOURCE);
      },


      /**
       * Save source from form and set it to model
       * @param {Ember.Object|Object} form
       */
      saveSourceForm: function saveSourceForm(form) {
        var _this = this;

        var model = form.get('_model');
        model.set('sourcePage', form.get('sourcePage'));

        var source = form.get('source');
        var modal = this.get('modal');
        var isNew = source.get('isNew');

        modal.trigger(_constants.MODALS.SOURCE + '.saving.process');
        this._saveSource(source).then(function () {
          modal.trigger(_constants.MODALS.SOURCE + '.saving.success');

          _this.get('relations').removeBelongsTo(model, 'source');
          model.set('sourceId', source.get('id'));
          model.set('source', source);

          if (isNew) {
            _this.get('userActions').addSourceAsCreated(source.get('id'));
          }
        }).catch(function () {
          modal.trigger(_constants.MODALS.SOURCE + '.saving.error');
        }).finally(function () {
          modal.trigger(_constants.MODALS.SOURCE + '.saving.finally');
        });
      },
      cancelSourceForm: function cancelSourceForm() {
        this.get('modal').close(_constants.MODALS.SOURCE);
      },
      selectSourceFor: function selectSourceFor(model, sourceSubject) {
        var _this2 = this;

        var lastSourceId = model.get('sourceId');
        this.get('store').findRecord('source', sourceSubject.get('id')).then(function (source) {
          _this2.get('relations').removeBelongsTo(model, 'source');
          model.set('source', source);
          model.set('sourceId', source.get('id'));
          if (source.get('id') !== lastSourceId) {
            model.set('sourcePage', null);
          }
        });
      },
      createSourceFor: function createSourceFor(model, title) {
        var source = this.get('store').createRecord('source', {
          title: title
        });
        this.get('relations').removeBelongsTo(model, 'source');
        model.set('source', source);
      },
      createSourceForForm: function createSourceForForm(form) {
        var source = this.get('store').createRecord('source', {});
        form.set('source', source);
      },
      loadSourceFor: function loadSourceFor(model, id) {
        var _this3 = this;

        this.get('store').findRecord('source', id).then(function (source) {
          _this3.get('relations').removeBelongsTo(model, 'source');
          model.set('source', source);
        });
      },
      deleteSourceFromModel: function deleteSourceFromModel(model) {
        this.get('relations').removeBelongsTo(model, 'source');
        model.setProperties({
          source: this.get('store').createRecord('source', {
            url: null
          }),
          sourcePage: null,
          sourceId: null
        });
      },
      resetSource: function resetSource(source) {
        source = source && source.get('content');
        if (Ember.isEmpty(source)) {
          return;
        }
        this.get('relations').rollbackHasMany(source);
        source.rollbackAttributes();
      },
      addAuthorSubjectToSource: function addAuthorSubjectToSource(source, personSubject) {
        var personId = personSubject.get('id');
        var existPerson = source.get('sourcePersonAuthors').findBy('personId', personId);

        if (existPerson) {
          if (existPerson.get('isDeleted')) {
            existPerson.rollbackAttributes();
          }

          return;
        }

        var store = this.get('store');
        var sourcePersonAuthor = store.createRecord('source-person-author', {
          personId: personId,
          sourceId: source.get('id'),
          source: source,
          _name: personSubject.get('name')
        });

        store.findRecord('person', personId).then(function (person) {
          sourcePersonAuthor.set('person', person);
        });

        source.get('sourcePersonAuthors').pushObject(sourcePersonAuthor);
      },
      removeAuthorSubjectFromSource: function removeAuthorSubjectFromSource(personSubject) {
        personSubject.deleteRecord();
      },
      addEditorSubjectToSource: function addEditorSubjectToSource(source, personSubject) {
        var personId = personSubject.get('id');
        var existPerson = source.get('sourcePersonEditors').findBy('personId', personId);

        if (existPerson) {
          if (existPerson.get('isDeleted')) {
            existPerson.rollbackAttributes();
          }

          return;
        }

        var store = this.get('store');
        var sourcePersonEditor = store.createRecord('source-person-editor', {
          personId: personId,
          sourceId: source.get('id'),
          source: source,
          _name: personSubject.get('name')
        });

        store.findRecord('person', personId).then(function (person) {
          sourcePersonEditor.set('person', person);
        });

        source.get('sourcePersonEditors').pushObject(sourcePersonEditor);
      },
      removeEditorSubjectFromSource: function removeEditorSubjectFromSource(personSubject) {
        personSubject.deleteRecord();
      },
      addPlaceSubjectToSource: function addPlaceSubjectToSource(source, placeSubject, name) {
        var placeId = placeSubject.get('id');
        source.set('_place', name);
        source.set('placeId', placeSubject.get('id'));

        this.get('store').findRecord('place', placeId).then(function (place) {
          source.set('place', place);
        });
      }
    },

    _saveSource: function _saveSource(source) {
      var _this4 = this;

      var promises = [];
      var isNew = source.get('isNew');

      if (source.get('content')) {
        source = source.get('content');
      }

      if (!source) {
        return this._handleSavingModal(promises, false);
      }

      if (isNew || source.get('hasDirtyAttributes')) {
        promises.push(source.save().then(function (source) {
          _this4.get('relations').rollbackHasMany(source);

          return source;
        }));
      }

      if (isNew) {
        return this._handleSavingModal(promises, false);
      }

      promises = promises.concat(this.get('relations').saveSimpleHasMany(source, ['sourcePersonAuthors', 'sourcePersonEditors', 'sourcePersonPublishers', 'sourceOrganizationPublishers']));

      return this._handleSavingModal(promises, false).then(function (source) {
        return source;
      });
    },
    _addPublisherPersonToSource: function _addPublisherPersonToSource(source, personSubject) {
      // check on existing this person
      var personId = personSubject.get('id');
      var existPerson = source.get('sourcePersonPublishers').findBy('personId', personId);

      if (existPerson) {
        if (existPerson.get('isDeleted')) {
          existPerson.rollbackAttributes();
        }

        return;
      }

      // add other person
      var store = this.get('store');
      var sourcePersonPublisher = store.createRecord('source-person-publisher', {
        personId: personId,
        sourceId: source.get('id'),
        source: source,
        _name: personSubject.get('name')
      });

      store.findRecord('person', personId).then(function (person) {
        sourcePersonPublisher.set('person', person);
      });

      source.get('sourcePersonPublishers').pushObject(sourcePersonPublisher);
    },
    _addPublisherOrganizationToSource: function _addPublisherOrganizationToSource(source, organizationSubject) {
      var organizationId = organizationSubject.get('id');
      var existOrganization = source.get('sourceOrganizationPublishers').findBy('organizationId', organizationId);

      // check on existing this organization
      if (existOrganization) {
        if (existOrganization.get('isDeleted')) {
          existOrganization.rollbackAttributes();
        }

        return;
      }

      // add other organization
      var store = this.get('store');
      var sourceOrganizationPublisher = store.createRecord('source-organization-publisher', {
        organizationId: organizationId,
        sourceId: source.get('id'),
        source: source,
        _name: organizationSubject.get('name')
      });

      store.findRecord('organization', organizationId).then(function (organization) {
        sourceOrganizationPublisher.set('organization', organization);
      });

      source.get('sourceOrganizationPublishers').pushObject(sourceOrganizationPublisher);
    },
    _removePublishers: function _removePublishers(source, target) {
      if (!target || target === 'person') {
        source.get('sourcePersonPublishers').forEach(function (item) {
          item.deleteRecord();
        });
      }
      if (!target || target === 'organization') {
        source.get('sourceOrganizationPublishers').forEach(function (item) {
          item.deleteRecord();
        });
      }
    }
  });
});