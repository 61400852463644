define('cdata/mixins/lists/route-sorting', ['exports', 'cdata/mixins/lists/parameters', 'cdata/mixins/lists/sorting-manager'], function (exports, _parameters, _sortingManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_parameters.default, {
    queryParams: {
      sortBy: {
        refreshModel: true
      },
      sortAsc: {
        refreshModel: true
      }
    },

    init: function init() {
      this.set('sortingParametersCallback', this.sortingParametersCallback.bind(this));

      return this._super.apply(this, arguments);
    },


    /**
     * Called after make query parameters for API query
     *
     * @param active {Boolean} state of sorting
     * @param field {String} field for sorting
     * @param asc {Boolean} sorting direction
     */
    sortingParametersCallback: function sortingParametersCallback(active, field, asc) {
      if (!field || field === 'null') {
        this.get('sortingManager').cleanup();

        return;
      }
      this.get('sortingManager').setField(active, field, asc, true);
    },


    /**
     * Manage sorting fields: state and direction
     */
    sortingManager: _sortingManager.default.create(),

    actions: {
      /**
       * Change field state from component
       *
       * @param active {Boolean} state of sorting
       * @param field {String} field for sorting
       * @param asc {Boolean|String} sorting direction
       */
      changeSorting: function changeSorting(active, field, asc) {
        // if activated 'defaultSort', and it is 'asc',
        // the field 'defaultSort.key' should be active always (when other any field unactivated)
        var defaultSort = this.get('defaultSort');
        var reverse = defaultSort && defaultSort.key === field && defaultSort.asc;

        asc = asc === true || asc === 'true' ? true : undefined;

        if (reverse && !active && !asc) {
          active = true;
        }

        var sortBy = this.get('controller.sortBy');
        var sortAsc = this.get('controller.sortAsc');
        var page = this.get('controller.page');

        var item = {
          sortBy: active ? field : undefined,
          sortAsc: asc === true || asc === 'true' ? true : undefined
        };

        item.page = sortAsc === item.sortAsc && sortBy === item.sortBy ? page : undefined;

        this.get('controller').setProperties(item);
      }
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      // Add sorting manager to controller
      controller.set('sortingManager', this.get('sortingManager'));
    }
  });
});