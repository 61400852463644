define('cdata/pods/components/site/editor/head-actions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['sixteen wide column head-actions'],

    items: [],

    add: 'add',
    clear: 'clear',
    cancel: 'cancel',

    title: '',

    // enableAdd: Ember.computed('add', 'items.@each.{isNew,isDeleted}', function() {
    //   let hasNew = this.get('items').filterBy('isNew').rejectBy('isDeleted').get('length');
    //   return !hasNew && this.get('add');
    // }),

    enableAdd: Ember.computed.bool('add'),

    enableClear: Ember.computed.and('clear', 'hasItems'),

    hasItems: Ember.computed.alias('items.length'),

    actions: {
      add: function add() {
        this.sendAction('add');
      },
      clear: function clear() {
        this.sendAction('clear');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });
});