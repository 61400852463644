define('cdata/mixins/components/cdata-category', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    cdataCategories: Ember.computed.alias('vocab.cdataCategoriesSorted'),
    cdataCategoryId: Ember.computed.alias('item.cdataCategoryVocabularyItemId'),

    cdataTopCategories: Ember.computed.filter('cdataCategories', function (item) {
      return Ember.isEmpty(item.get('parentId'));
    }),

    cdataSubCategories: Ember.computed.filter('cdataCategories', function (item) {
      return this.get('topCategoryId') && item.get('parentId') === this.get('topCategoryId');
    }).property('topCategoryId'),

    subCategoryId: Ember.computed('cdataCategoryId', function () {
      var id = this.get('cdataCategoryId');
      if (!id) {
        return null;
      }

      var category = this.get('cdataCategories');
      category = category && category.findBy('id', id.toString());

      if (category && category.get('parentId')) {
        return id.toString();
      }

      return null;
    }),

    topCategoryId: Ember.computed('cdataCategoryId', function () {
      var id = this.get('cdataCategoryId');
      if (!id) {
        return null;
      }

      var category = this.get('cdataCategories');
      category = category && category.findBy('id', id.toString());

      if (category && category.get('parentId')) {
        return category.get('parentId');
      }

      return id;
    }),

    actions: {
      setCdataCategory: function setCdataCategory(category) {
        this.set('item.cdataCategoryVocabularyItemId', category && category.get('id'));
      }
    }
  });
});