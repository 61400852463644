define('cdata/pods/components/marks/edit/edit-forms-places/component', ['exports', 'cdata/pods/components/marks/edit/mixins/edit-forms', 'cdata/pods/components/places/mixins/handle_new_place'], function (exports, _editForms, _handle_new_place) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Place'), _handle_new_place.default, {
    autoCreating: false,

    placesIds: Ember.computed.mapBy('places', 'id'),

    places: Ember.computed.filterBy('model.markPlaces', 'isDeleted', false),

    subjectFilter: { clear: true },

    actions: {
      setPlace: function setPlace(_, subjectPlace) {
        this.send('add', subjectPlace);
      }
    }
  });
});