define('cdata/services/page-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    show: false,
    main: false,
    list: false,

    /**
     * Set current page type
     * @param type
     */
    setType: function setType(type) {
      this.clear();
      this.set(type, true);
    },
    clear: function clear() {
      this.setProperties({
        show: false,
        main: false
      });
    },


    classPageType: Ember.computed('show', 'main', 'list', function () {
      if (this.get('show')) {
        return 'show';
      }
      if (this.get('main')) {
        return 'main';
      }
      if (this.get('list')) {
        return 'list';
      }
    })
  });
});