define("cdata/pods/reports/components/chart-layout/utils", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var nodeToBreadcrumb = function nodeToBreadcrumb(node) {
    return {
      name: node.data.name,
      color: node.color
    };
  };

  var extractBreadrumbs = exports.extractBreadrumbs = function extractBreadrumbs(node) {
    return node.parent ? [].concat(_toConsumableArray(extractBreadrumbs(node.parent)), [nodeToBreadcrumb(node)]) : [];
  };
});