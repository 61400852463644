define('cdata/pods/comparables/list/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    params: {},

    inProgress: false,

    comparables: [],

    queryParams: ['query', 'page'],

    // init() {
    //   this._super(...arguments);
    //   Ember.run.schedule("afterRender", this, () => {
    //     this.initParameters();
    //   });
    // },

    actions: {
      run: function run() {
        this.send('show');
      },
      filter: function filter() {
        this.send.apply(this, ['show'].concat(Array.prototype.slice.call(arguments)));
      }
    },

    initParameters: function initParameters() {
      var _this = this;

      Ember.run.schedule('afterRender', this, function () {
        var parameters = _this.get('query');
        var params = {};
        parameters = parameters && JSON.parse(parameters);
        parameters = parameters || {};

        var exist = false;

        Object.keys(parameters).map(function (key) {
          params[key] = parameters[key];
          exist = true;
        });

        _this.set('params', params);

        if (exist) {
          _this.send('show');
        }
      });
    },


    fmv: Ember.computed('valuation.FMV', function () {
      return this.get('valuation.FMV') === 'not available' ? null : this.get('valuation.FMV');
    }),

    rrv: Ember.computed('valuation.RRV', function () {
      return this.get('valuation.RRV') === 'not available' ? null : this.get('valuation.RRV');
    }),

    mtm: Ember.computed('valuation.MTM', function () {
      return this.get('valuation.MTM') === 'not available' ? null : this.get('valuation.MTM');
    }),

    valuations: Ember.computed('fmv', 'rrv', 'mtm', function () {
      return Ember.Object.create({
        fmv: this.get('fmv') || 'not available',
        rrv: this.get('rrv') || 'not available',
        mtm: this.get('mtm') || 'not available'
      });
    })
  });
});