define('cdata/pods/components/site/list/infinity-loader/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['infinity-loader'],
    classNameBindings: ['size'],

    target: null,
    guid: null,
    delay: 10,

    offset: 600,
    busy: false,

    loadMore: 'loadMore',
    loadPrevious: 'loadPrevious',
    gotoPage: 'gotoPage',

    listSize: Ember.computed.alias('model.length'),

    enabled: Ember.computed('model.meta.{page_number,total_pages}', function () {
      var meta = this.get('model.meta');

      return meta.page_number < meta.total_pages;
    }),

    loadedPagesCount: Ember.computed('listSize', 'model.meta.page_size', function () {
      return Math.ceil(this.get('listSize') / this.get('model.meta.page_size'));
    }),

    enabledPrevious: Ember.computed('model.firstObject._firstAtPage', function () {
      return this.get('model.firstObject._firstAtPage') > 1;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('target', Ember.$(window));
      this.set('guid', Ember.guidFor(this));
      this.get('target').scrollTop(0);

      this.subscribeToScroll();
      this.loadMoreIfNeeded();
      this.loadPreviousIfNeeded();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.cancelSubscribeToScroll();
    },
    subscribeToScroll: function subscribeToScroll() {
      var _this = this;

      this.get('target').on('scroll.' + this.get('guid'), function () {
        if (_this.debouncerMore) {
          Ember.run.cancel(_this.debouncerMore);
        }
        _this.debouncerMore = Ember.run.debounce(_this, _this.loadMoreIfNeeded, _this.get('delay'));

        if (_this.debouncerPrevious) {
          Ember.run.cancel(_this.debouncerPrevious);
        }
        _this.debouncerPrevious = Ember.run.debounce(_this, _this.loadPreviousIfNeeded, _this.get('delay'));

        if (_this.debouncerPage) {
          Ember.run.cancel(_this.debouncerPage);
        }
        _this.debouncerPage = Ember.run.debounce(_this, _this.calculatePage, 1000);
      });
    },
    cancelSubscribeToScroll: function cancelSubscribeToScroll() {
      var target = this.get('target');
      if (target) {
        target.off('scroll.' + this.get('guid'));
      }
    },
    loadMoreIfNeeded: function loadMoreIfNeeded() {
      if (this.canLoadMore()) {
        this.sendAction('loadMore');
      }
    },
    loadPreviousIfNeeded: function loadPreviousIfNeeded() {
      if (this.canLoadPrevious()) {
        var list = Ember.$('.list-wrap-results-list-block');

        if (!list || list.length === 0 || this.get('isDestroyed')) {
          return;
        }

        var height = list.height();
        var pages = this.get('loadedPagesCount');
        var pageHeight = Math.ceil(height / pages);
        this.sendAction('loadPrevious', this.get('target'), pageHeight);
      }
    },
    calculatePage: function calculatePage() {
      var list = Ember.$('.list-wrap-results-list-block');

      if (!list || list.length === 0 || this.get('isDestroyed')) {
        return;
      }

      var scrollPos = this.triggerOffsetPrevious();
      var height = list.height();
      var pages = this.get('loadedPagesCount');
      var pageHeight = Math.ceil(height / pages);
      var currentViewPage = void 0;

      var markers = list.find('.page-delimiter');
      markers.each(function (_, marker) {
        marker = Ember.$(marker);
        var offset = marker.offset().top - scrollPos;
        if (offset >= 0 && offset <= pageHeight) {
          currentViewPage = parseInt(marker.data('page'));
        }
      }.bind(this));

      this.sendAction('gotoPage', currentViewPage);
    },
    canLoadMore: function canLoadMore() {
      if (this.isDestroying || this.isDestroyed || !this.get('enabled')) {
        return false;
      }

      if (this.get('model.length') === 0) {
        return false;
      }

      return this.bottomOffset() > this.triggerOffset();
    },
    canLoadPrevious: function canLoadPrevious() {
      if (this.isDestroying || this.isDestroyed || !this.get('enabledPrevious')) {
        return false;
      }

      if (this.get('model.length') === 0) {
        return false;
      }

      return this.triggerOffsetPrevious() <= 0;
    },
    selfOffset: function selfOffset() {
      return this.$().offset().top;
    },
    bottomOffset: function bottomOffset() {
      return this.get('target').height() + this.get('target').scrollTop();
    },
    topOffset: function topOffset() {
      return this.get('target').scrollTop();
    },
    triggerOffset: function triggerOffset() {
      return this.selfOffset() - this.get('offset');
    },
    triggerOffsetPrevious: function triggerOffsetPrevious() {
      return this.topOffset() - this.get('offset');
    },


    size: Ember.computed('listSize', function () {
      Ember.run.scheduleOnce('afterRender', this, this.loadMoreIfNeeded);

      return 'size-' + this.get('listSize');
    })
  });
});