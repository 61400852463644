define('cdata/pods/sessions/session-mixin', ['exports', 'cdata/mixins/editor/saving-cases'], function (exports, _savingCases) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_savingCases.default, {
    actions: {
      /*
       * <<<<<< titles >>>>>>>
       */

      addSessionTitle: function addSessionTitle() {
        var model = this.get('currentModel');
        var store = this.get('store');

        var sessionTitle = store.createRecord('session-title', {
          entityId: model.get('id'),
          entity: model,
          preferred: !model.get('sessionTitles').filterBy('preferred').get('length')
        });

        model.get('sessionTitles').pushObject(sessionTitle);
      },
      removeSessionTitle: function removeSessionTitle(sessionTitle) {
        this._removeItemWithPreferred(sessionTitle, 'sessionTitles');
      },
      setPreferredToSessionTitle: function setPreferredToSessionTitle(sessionTitle) {
        this._changePreferred('sessionTitles', sessionTitle);
      },
      clearSessionTitleSection: function clearSessionTitleSection() {
        this._removeAllItems('sessionTitles', 'removeSessionTitle');
      },


      /*
       * <<<<<< globals >>>>>>>
       */

      loadLocationFor: function loadLocationFor(model, id) {
        var _this = this;

        if (!id) {
          return;
        }

        this.get('store').findRecord('location', id).then(function (location) {
          _this.get('relations').removeBelongsTo(model, 'location');

          model.setProperties({
            locationId: location.get('id'),
            location: location,
            _locationName: location.get('locationTitles.firstObject.text')
          });
        });
      },
      cancel: function cancel() {
        try {
          var saleController = this.controllerFor('sales.show');
          if (saleController) {
            saleController.cancel();
          }
        } catch (e) {
          // skip
        }

        var model = this.get('currentModel');
        model.rollbackAttributes();

        this.get('relations').resetBelongsTo(model, 'location');
        this.send('loadLocationFor', model, model.get('locationId'));

        this._clearEditor();
      },
      save: function save() {
        try {
          var saleController = this.controllerFor('sales.show');
          saleController.save();
        } catch (e) {
          // skip
        }

        this._clean();

        var model = this.get('currentModel');

        // saving simple has many relations
        this._handleSaving(this.get('relations').saveSimpleHasMany(model, ['sessionTitles']));

        if (model.get('hasDirtyAttributes')) {
          this._handleSaving(model.save());
        } else {
          this._handleSaving();
        }
      }
    },

    _clean: function _clean() {
      this._removeItemsByEmptyField('sessionTitles', 'text', 'removeSessionTitle');
    }
  });
});