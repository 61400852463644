define('cdata/pods/components/citations/edit-modal-form/component', ['exports', 'cdata/mixins/modals/component-mixin', 'cdata/constants'], function (exports, _componentMixin, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.SOURCE,
    classNames: ['context-modal', _constants.MODALS.SOURCE, 'edit-form'],

    userActions: Ember.inject.service(),
    subjectSearch: Ember.inject.service(),

    sourceTypes: Ember.computed.alias('vocab.sourceTypes'),

    sourceTypeLabel: Ember.computed('source.sourceTypeVocabularyItemId', 'sourceTypes.length', function () {
      var item = this.get('sourceTypes').findBy('id', this.get('source.sourceTypeVocabularyItemId'));
      if (item) {
        return item.get('label');
      }

      return '';
    }),

    isCreated: Ember.computed.not('isEmpty'),

    isEmpty: Ember.computed('model.source', function () {
      return this.get('model.source.id') === undefined;
    }),
    source: Ember.computed.alias('model.source'),

    placeSubject: null,
    placeName: Ember.computed('placeSubject', function () {
      var subject = this.get('placeSubject');
      if (!subject) {
        return '';
      }

      var parts = [subject.get('name')];
      var description = subject.get('description');
      if (description) {
        parts.push('(' + description + ')');
      }

      return parts.join(' ');
    }),

    title: Ember.computed('isOnline', 'source.id', function () {
      var type = this.get('isOnline') ? 'online' : 'offline';
      var action = this.get('source.id') ? 'Edit' : 'Create';

      return action + ' ' + type + ' source';
    }),

    isOnline: Ember.computed.bool('source.url'),
    isOffline: Ember.computed.not('isOnline'),

    /**
     * Enable edit for source created by me
     */
    isEditEnabled: Ember.computed('userActions.createdSources.length', 'source.id', function () {
      return Ember.isEmpty(this.get('source.id')) || this.get('userActions').isCreateSource(this.get('source.id'));
    }),
    isEditDisabled: Ember.computed.not('isEditEnabled'),

    authors: Ember.computed.filterBy('source.sourcePersonAuthors', 'isDeleted', false),
    authorsIds: Ember.computed.mapBy('authors', 'personId'),

    editors: Ember.computed.filterBy('source.sourcePersonEditors', 'isDeleted', false),
    editorsIds: Ember.computed.mapBy('editors', 'personId'),

    publisher: Ember.computed('source.{sourcePersonPublishers,sourceOrganizationPublishers}.@each.isDeleted', function () {
      var people = this.get('source.sourcePersonPublishers').rejectBy('isDeleted');
      var organizations = this.get('source.sourceOrganizationPublishers').rejectBy('isDeleted');

      return people.get('firstObject') || organizations.get('firstObject');
    }),

    actions: {
      createSource: function createSource(query) {
        this.sendAction('createSourceFor', this.get('model'), query || '');
      },
      cancel: function cancel() {
        this.sendAction('cancelSourceForm');
      },
      save: function save() {
        this.sendAction('saveSourceForm', this.get('model'));
      },
      selectSource: function selectSource(sourceSubject) {
        this.sendAction('loadSourceFor', this.get('model'), sourceSubject.get('id'));
      },
      close: function close() {
        this.send('cancel');
      },
      createAuthor: function createAuthor(query) {
        this.sendAction('openNewPersonForm', query, true);
        this.set('actionForAddPerson', 'addAuthorSubjectToSource');
      },
      addAuthor: function addAuthor(personSubject) {
        this.sendAction('addAuthorSubjectToSource', this.get('source'), personSubject);
      },
      addPlace: function addPlace(model, placeSubject) {
        this.set('placeSubject', placeSubject);
        this.sendAction('addPlaceSubjectToSource', model, placeSubject, this.get('placeName'));
      },
      removeAuthor: function removeAuthor(personSubject) {
        this.sendAction('removeAuthorSubjectFromSource', personSubject);
      },
      addEditor: function addEditor(personSubject) {
        this.sendAction('addEditorSubjectToSource', this.get('source'), personSubject);
      },
      createEditor: function createEditor(query) {
        this.sendAction('openNewPersonForm', query, true);
        this.set('actionForAddPerson', 'addEditorSubjectToSource');
      },
      removeEditor: function removeEditor(personSubject) {
        this.sendAction('removeEditorSubjectFromSource', personSubject);
      },
      addPublisher: function addPublisher(model, publisherSubject) {
        this.sendAction('addPublisherSubjectToSource', model, publisherSubject);
      },
      createPlace: function createPlace(cityName) {
        this.sendAction('openNewPlaceForm', cityName);
      },
      createOrganization: function createOrganization(title) {
        this.sendAction('openNewOrganizationForm', title, true);
      },
      openVocabularyNewItemForm: function openVocabularyNewItemForm() {
        this.sendAction.apply(this, ['openVocabularyNewItemForm'].concat(Array.prototype.slice.call(arguments)));
      }
    },

    _onSourceSaveProcess: function _onSourceSaveProcess() {
      this.set('inProcess', true);
    },
    _onSourceSaveSuccess: function _onSourceSaveSuccess() {
      this.sendAction('closeSourceForm');
    },
    _onSourceSaveFinally: function _onSourceSaveFinally() {
      this.set('inProcess', false);
    },
    _onPersonSaveSuccess: function _onPersonSaveSuccess(result) {
      var person = result.item;

      // success result is person object
      var name = person && person.get('personNames.firstObject');
      name = name && name.get('text');

      // add person-based subject to list
      this.sendAction(this.get('actionForAddPerson'), this.get('source'), Ember.Object.create({
        id: person.get('id'),
        name: name
      }));
    },
    _onOrganizationSaveSuccess: function _onOrganizationSaveSuccess(result) {
      var organization = result.item;

      // success result is organization object
      var title = organization && organization.get('organizationTitles.firstObject');
      title = title && title.get('text');

      // add organization-based subject to list
      this.send('addPublisher', this.get('source'), Ember.Object.create({
        id: organization.get('id'),
        name: title,
        type: 'organization'
      }));
    },
    _onPlaceSaveSuccess: function _onPlaceSaveSuccess(result) {
      var placeSubject = result.item;
      this.send('addPlace', this.get('source'), placeSubject);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('modal').on(_constants.MODALS.SOURCE + '.saving.process', this, this._onSourceSaveProcess).on(_constants.MODALS.SOURCE + '.saving.success', this, this._onSourceSaveSuccess).on(_constants.MODALS.SOURCE + '.saving.finally', this, this._onSourceSaveFinally).on(_constants.MODALS.PERSON + '.saving.success', this, this._onPersonSaveSuccess).on(_constants.MODALS.ORGANIZATION + '.saving.success', this, this._onOrganizationSaveSuccess).on(_constants.MODALS.PLACE + '.saving.success', this, this._onPlaceSaveSuccess);

      this._loadPlaceSubject();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('modal').off(_constants.MODALS.SOURCE + '.saving.process', this, this._onSourceSaveProcess).off(_constants.MODALS.SOURCE + '.saving.success', this, this._onSourceSaveSuccess).off(_constants.MODALS.SOURCE + '.saving.finally', this, this._onSourceSaveFinally).off(_constants.MODALS.PERSON + '.saving.success', this, this._onPersonSaveSuccess).off(_constants.MODALS.ORGANIZATION + '.saving.success', this, this._onOrganizationSaveSuccess).off(_constants.MODALS.PLACE + '.saving.success', this, this._onPlaceSaveSuccess);
    },
    _loadPlaceSubject: function _loadPlaceSubject() {
      var _this = this;

      var placeId = this.get('source.placeId');
      if (placeId) {
        this.get('subjectSearch').getById(placeId, 'place').then(function (placeSubject) {
          if (placeSubject) {
            _this.set('placeSubject', placeSubject);
          }
        });
      }
    }
  });
});