define('cdata/pods/entities/show/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    userActions: Ember.inject.service(),
    predictedPricesEnabled: Ember.computed.alias('userActions.predictedPrices.settings.enabled')
  });
});