define('cdata/pods/components/entities/view/upcoming-lot-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),

    loadImageFromEntity: 'loadImageFromEntity',

    classNames: ['item with-image'],

    firstImage: Ember.computed.alias('model.images.firstObject'),
    image: Ember.computed('firstImage', function () {
      return this.get('firstImage') ? this.get('firstImage.thumb_url') : null;
    }),

    saleDate: Ember.computed.alias('lot.session_start_date'),

    estimateLow: Ember.computed.alias('lot.estimate_low'),
    estimateHigh: Ember.computed.alias('lot.estimate_high'),
    currency: 'USD',

    artist: Ember.computed.alias('model.makers.firstObject.name'),
    artistDates: Ember.computed.alias('model.makers.firstObject.dates'),

    price: Ember.computed.alias('lot.price'),
    number: Ember.computed.alias('lot.number'),

    lot: Ember.computed.alias('model.lots.firstObject'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('lot.sale_uuid') && this.get('ajax').$get('sales/' + this.get('lot.sale_uuid'), {}, function (response) {
        if (!_this.isDestroyed) {
          _this.setProperties({
            currency: _this.get('vocab.currenciesLabels')[response.sale.currency_vocabulary_item_id],
            saleTitle: response.sale_titles[0] && response.sale_titles[0].text,
            saleNumber: response.sale.number
          });
        }
      }, function () {});

      if (!this.get('image')) {
        this.sendAction('loadImageFromEntity', this.get('model.id'), this);
      }
    }
  });
});