define('cdata/mixins/create-modals/award', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var eventKey = _constants.MODALS.AWARD + '.saving';

  exports.default = Ember.Mixin.create({
    sidConstants: Ember.inject.service(),

    actions: {
      openNewAwardForm: function openNewAwardForm(nameText) {
        this.get('modal').register(this, _constants.MODALS.AWARD, 'awards.new').open(Ember.Object.create({
          nameText: nameText || ''
        }));
      },
      closeNewAwardForm: function closeNewAwardForm() {
        this.get('modal').close(_constants.MODALS.AWARD);
      },
      saveNewAwardForm: function saveNewAwardForm(form) {
        var _this = this;

        var award = this.get('store').createRecord('award', {
          name: form.get('nameText')
        });

        // saving
        var modal = this.get('modal');
        modal.trigger(eventKey + '.process');
        this._handleSavingModal(award.save(), false).then(function (award) {
          _this.get('relations').rollbackHasMany(award);
          modal.trigger(eventKey + '.success', { item: award });
        }).catch(function (error) {
          modal.trigger(eventKey + '.error', { error: error });
        }).finally(function () {
          modal.trigger(eventKey + '.finally');
        });
      }
    }
  });
});