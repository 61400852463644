define('cdata/pods/entities/review-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ajax: Ember.inject.service(),

    initEntityReview: function initEntityReview(controller, model) {
      controller.set('userReviews', null);

      this.get('ajax').$get('user_reviews/entity/' + model.get('id'), {}, function (result) {
        controller.set('userReviews', result);
      });
    },


    actions: {
      requestOnReview: function requestOnReview(field) {
        var _this = this;

        var model = this.get('currentModel');

        var req = {
          field: field,
          entity: 'entity',
          entity_id: model.get('id')
        };

        this.get('controller').set('userReviews', null);

        this.get('ajax').post('user_reviews', req, function () {
          _this.get('flashMessage').showSuccess('Review is opened');
        }, function (error) {
          _this.get('flashMessage').showError(error);
        }, function () {
          _this.initEntityReview(_this.get('controller'), model);
        });
      },
      closeReview: function closeReview(field) {
        var _this2 = this;

        var model = this.get('currentModel');

        var req = {
          field: field,
          entity: 'entity',
          entity_id: model.get('id'),
          reviewed: true
        };

        this.get('controller').set('userReviews', null);

        this.get('ajax').put('user_reviews', req, function () {
          _this2.get('flashMessage').showSuccess('Review is closed');
        }, function (error) {
          _this2.get('flashMessage').showError(error);
        }, function () {
          _this2.initEntityReview(_this2.get('controller'), model);
        });
      },


      actions: {}
    }
  });
});