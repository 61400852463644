define('cdata/pods/components/buttons/block-controls-cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['block-controls-cell small'],
    edit: null,
    pathId: 'id',
    pathId2: null
  });
});