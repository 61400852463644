define('cdata/pods/components/people/list/bulk-operations/component', ['exports', 'cdata/mixins/lists/component-bulk-operations'], function (exports, _componentBulkOperations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentBulkOperations.default, {
    assignOptions: [Ember.Object.create({
      id: 'role',
      label: 'Role'
    }), Ember.Object.create({
      id: 'nationality',
      label: 'Nationality'
    }), Ember.Object.create({
      id: 'gender',
      label: 'Gender'
    })]
  });
});