define('cdata/mixins/editor/edit-forms-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['row edit-block hoverable'],
    remove: 'remove',
    setPreferred: 'setPreferred',
    setAlternate: 'setAlternate',
    openVocabularyNewItemForm: 'openVocabularyNewItemForm',

    actions: {
      remove: function remove() {
        this.sendAction.apply(this, ['remove'].concat(Array.prototype.slice.call(arguments)));
      },
      setPreferred: function setPreferred() {
        this.sendAction.apply(this, ['setPreferred'].concat(Array.prototype.slice.call(arguments)));
      },
      setAlternate: function setAlternate() {
        this.sendAction.apply(this, ['setAlternate'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});