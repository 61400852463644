define('cdata/pods/components/people/modals/assign-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin', 'cdata/mixins/components/assign-form'], function (exports, _constants, _componentMixin, _assignForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var eventKey = _constants.MODALS.PERSON_ASSIGN + '.saving';

  exports.default = Ember.Component.extend(_componentMixin.default, _assignForm.default, {
    modalClassId: _constants.MODALS.PERSON_ASSIGN,
    classNames: ['context-modal', _constants.MODALS.PERSON_ASSIGN, 'edit-form'],

    eventKey: eventKey,

    isGender: Ember.computed.equal('model.option', 'gender'),
    genderId: Ember.computed.alias('assignSubject.id'),

    enabled: Ember.computed('assignSubject.id', 'rule.id', function () {
      var subjectId = this.get('assignSubject.id');
      var rule = this.get('rule');

      if (this.get('subjectName') && subjectId && rule.get('id')) {
        return true;
      }

      if (subjectId && this.get('isGender')) {
        return true;
      }
    }),

    subjectName: Ember.computed('model.option', function () {
      var option = this.get('model.option');

      if (['role', 'nationality'].indexOf(option) !== -1) {
        return option;
      }

      return null;
    }),

    actions: {
      setGender: function setGender(gender) {
        this.set('assignSubject', gender);
      }
    }
  });
});