define('cdata/pods/components/entities/view/entity-header/review-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'lots-lot-header--prev-next-editor',
    reviewEditor: Ember.inject.service(),

    currentItemId: null,
    currentItemName: null,

    inProcess: true,

    init: function init() {
      this._super.apply(this, arguments);

      var modelName = this.get('item').constructor.modelName;

      this.get('reviewEditor').changeItem(modelName, this.get('item.id'));
      this.set('currentItemId', this.get('item.id'));
      this.set('currentItemName', modelName);
      this.set('inProcess', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var modelName = this.get('item').constructor.modelName;

      if (this.get('item.id') !== this.get('currentItemId') || modelName !== this.get('currentItemName')) {
        this.get('reviewEditor').changeItem(modelName, this.get('item.id'));
        this.set('currentItemId', this.get('item.id'));
        this.set('inProcess', false);
      }
    },


    actions: {
      gotoOtherEntity: function gotoOtherEntity() {
        this.set('inProcess', true);
      },
      closeReviewEditor: function closeReviewEditor() {
        this.get('reviewEditor').doClear();
      }
    }
  });
});