define('cdata/pods/organizations/merge-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modal: Ember.inject.service(),
    flashMessage: Ember.inject.service(),
    mergeList: Ember.inject.service(),

    organizationMerging: function organizationMerging(id, ids) {
      ids = Array.from(new Set(ids));
      this.get('mergeList').save(this.get('store'), 'organization', id, ids);
    },


    actions: {

      /**
       * Merge data of current organization TO other organization or FROM other organizations to current
       * @param {String} type to|from
       * @param {Array} organizationSubjects
       */
      mergeOrganizations: function mergeOrganizations(type, organizationSubjects) {
        var _this = this;

        // many organizations into current
        var organizationId = this.get('currentModel.id');
        var subjectIds = organizationSubjects.mapBy('id');

        // current into other
        if (type === 'to') {
          organizationId = organizationSubjects.get('firstObject').get('id');
          subjectIds = [this.get('currentModel.id')];
        }

        var message = type === 'to' ? 'Current organization has been merged to other organization' : 'Selected organizations has been merged to current organization';

        this._subscribeOnOrganizationMerge(message, function () {
          _this.transitionTo('organizations.show', organizationId);
          _this.send('cancel');
        });

        this.organizationMerging(organizationId, subjectIds);
      },
      confirmOrganizationConversion: function confirmOrganizationConversion(organization) {
        var _this2 = this;

        var conversion = this.get('store').createRecord('organization-conversion', {
          organizationId: organization.get('id')
        });

        conversion.save().then(function (conversion) {
          _this2.send('closeConversionOrganizationForm');
          _this2.transitionTo('people.show', conversion.get('personId'));
          _this2.get('flashMessage').showSuccess('Conversion completed');
        }, function (error) {
          _this2.get('flashMessage').showError(error);
        });
      }
    },

    _subscribeOnOrganizationMerge: function _subscribeOnOrganizationMerge() {
      var successMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var successCallback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      this._organizationMergeSuccessMessage = successMessage;
      this._organizationMergeSuccessFunction = successCallback;

      this.get('modal').on('organization.merge.success', this, this._onOrganizationMergeSuccess).on('organization.merge.error', this, this._onOrganizationMergeError);
    },
    _unsubscribeOrganizationMerge: function _unsubscribeOrganizationMerge() {
      this.get('modal').off('organization.merge.success', this, this._onOrganizationMergeSuccess).off('organization.merge.error', this, this._onOrganizationMergeError);
    },
    _onOrganizationMergeSuccess: function _onOrganizationMergeSuccess() {
      this._unsubscribeOrganizationMerge();

      if (null !== this._organizationMergeSuccessMessage) {
        this.get('flashMessage').showSuccess(this._organizationMergeSuccessMessage);
      }

      if (null !== this._organizationMergeSuccessFunction) {
        this._organizationMergeSuccessFunction();
      }
    },
    _onOrganizationMergeError: function _onOrganizationMergeError(error) {
      this._unsubscribeOrganizationMerge();

      this.get('flashMessage').showError(error);
    }
  });
});