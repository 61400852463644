define('cdata/pods/user-reviews/route', ['exports', 'cdata/mixins/route-init'], function (exports, _routeInit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeInit.default, {
    ajax: Ember.inject.service(),
    reviewEditor: Ember.inject.service(),

    model: function model() {
      return {};
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.get('ajax').$get("users", {}, function (users) {
        users = [{ id: null, email: "not set" }].concat(users);

        var list = users.map(function (user) {
          return Ember.Object.create({
            id: user.id + '',
            label: user.email,
            email: user.email,
            name: user.email
          });
        });

        controller.set('users', list);
        controller.show();
      });
    },


    actions: {
      assignReviews: function assignReviews(userId, filterParams) {
        var _this = this;

        var req = {
          filter: filterParams,
          user_id: userId
        };

        this.get('controller').set('loading', true);

        this.get('ajax').post('user_reviews/assign', req, function () {
          _this.get('flashMessage').showSuccess('Reviews are assigned');
          _this.get('controller').show();
        }, function (error) {
          _this.get('flashMessage').showError(error);
        }, function () {
          _this.get('controller').set('loading', false);
        });
      },
      startReviewEditor: function startReviewEditor() {
        var review = this.get('reviewEditor.review');
        if (review.entity === 'entity') {
          this.transitionTo('entities.show', review.entity_id);
        }
        if (review.entity === 'lot') {
          this.transitionTo('lots.show', review.entity_id);
        }
      }
    }
  });
});