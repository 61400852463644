define('cdata/pods/components/citations/cluster-by-source/component', ['exports', 'cdata/pods/components/citations/cluster-by-source/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    userActions: Ember.inject.service(),

    enabledTags: Ember.computed.oneWay('userActions.sourceTags.settings.enabledTags'),
    enabledUsers: Ember.computed.oneWay('userActions.sourceTags.settings.enabledUsers'),

    tags: Ember.computed('cluster.@each.citationName', 'enabledTags', function () {
      var enabledTags = this.get('enabledTags');

      if (enabledTags) {
        var cluster = this.get('cluster');

        return (0, _utils.getUniqueValues)('citationName', cluster);
      }

      return null;
    }),

    users: Ember.computed('cluster.@each.userName', 'enabledUsers', function () {
      var enabledUsers = this.get('enabledUsers');

      if (enabledUsers) {
        var cluster = this.get('cluster');

        return (0, _utils.getUniqueValues)('userName', cluster);
      }

      return null;
    })
  });
});