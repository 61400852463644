define('cdata/mixins/models/build-place-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _name: null,

    buildName: function buildName() {
      var _this = this;

      var place = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var first = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (first && this.get('_name')) {
        return;
      }
      var promise = place ? place.get('parent') : this.get('placeId') ? this.get('place') : null;

      if (promise) {
        promise.then(function (place) {
          _this.set('_name', place.get('name') + (_this.get('_name') ? ', ' + _this.get('_name') : ''));
          if (place.get('parentId')) {
            _this.buildName(place, false);
          }
        });
      } else {
        this.set('_name', this.get('text'));
      }
    }
  });
});