define('cdata/models/mark-maker', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    markId: attr('string'),
    mark: belongsTo('mark'),
    personId: attr('string'),
    person: belongsTo('person'),
    organizationId: attr('string'),
    organization: belongsTo('organization'),

    type: Ember.computed('personId', 'organizationId', function () {
      if (this.get('personId') > 0) {
        return 'person';
      }

      if (this.get('organizationId')) {
        return 'organization';
      }

      return null;
    }),

    label: Ember.computed('_personName', '_organizationTitle', 'type', function () {
      if (this.get('type') === 'person') {
        return this.get('_personName');
      }
      if (this.get('type') === 'organization') {
        return this.get('_organizationTitle');
      }

      return null;
    }),

    filter: Ember.computed('type', 'makerId', function () {
      if (this.get('type') === 'person') {
        return '(personIds*' + this.get('makerId') + ')';
      } else {
        return '(organizationIds*' + this.get('makerId') + ')';
      }
    }),

    isPerson: Ember.computed.equal('type', 'person'),
    isOrganization: Ember.computed.equal('type', 'organization'),

    makerId: Ember.computed('personId', 'organizationId', function () {
      return this.get('personId') || this.get('organizationId');
    }),

    route: Ember.computed('type', function () {
      if (this.get('type') === 'person') {
        return 'people.show';
      }
      if (this.get('type') === 'organization') {
        return 'organizations.show';
      }
    })
  });
});