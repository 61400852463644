define('cdata/pods/components/organizations/list/set-filter/component', ['exports', 'cdata/mixins/lists/component-filter'], function (exports, _componentFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentFilter.default, {
    fields: ['query', 'approved', 'existenceDateStart', 'existenceDateFinish', 'roleIds', 'nationalityIds', 'jdeDepartmentIds', 'christiesSpecialistDepartmentIds', 'jdeThreeDigitCodeIds'],

    roles: [],
    nationalities: [],
    jdeDepartments: [],
    christiesSpecialistDepartments: [],
    jdeThreeDigitCodes: [],

    roleIds: Ember.computed.mapBy('roles', 'id'),
    nationalityIds: Ember.computed.mapBy('nationalities', 'id'),
    jdeDepartmentIds: Ember.computed.mapBy('jdeDepartments', 'id'),
    christiesSpecialistDepartmentIds: Ember.computed.mapBy('christiesSpecialistDepartments', 'id'),
    jdeThreeDigitCodeIds: Ember.computed.mapBy('jdeThreeDigitCodes', 'id'),

    initFilter: function initFilter() {
      this._super();
      this.initFilterVocabs('roleIds', 'roles');
      this.initFilterSubjects('jdeDepartmentIds', 'jdeDepartment', 'jdeDepartments');
      this.initFilterSubjects('christiesSpecialistDepartmentIds', 'christiesSpecialist', 'christiesSpecialistDepartments');
      this.initFilterSubjects('jdeThreeDigitCodeIds', 'threeDigitCode', 'jdeThreeDigitCodes');
      this.initFilterVocabs('nationalityIds', 'nationalities');
    },


    actions: {
      clear: function clear() {
        this.setProperties({
          roles: [],
          nationalities: [],
          awards: [],
          jdeDepartments: [],
          christiesSpecialistDepartments: [],
          jdeThreeDigitCodes: []
        });
        this._super();
      },
      setNotApproved: function setNotApproved() {
        var value = false === this.get('model.approved') ? null : false;
        this.set('model.approved', value);
        this.applyFilter();
      },
      setApproved: function setApproved() {
        var value = true === this.get('model.approved') ? null : true;
        this.set('model.approved', value);
        this.applyFilter();
      },
      setExistenceDates: function setExistenceDates(start, finish) {
        this.set('model.existenceDateStart', start);
        this.set('model.existenceDateFinish', finish);
        this.applyFilter();
      }
    }
  });
});