define('cdata/pods/components/people/list/sort-box/component', ['exports', 'cdata/mixins/lists/component-sort-box'], function (exports, _componentSortBox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentSortBox.default, {
    values: [Ember.Object.create({
      title: 'Name',
      key: 'name'
    }), Ember.Object.create({
      title: 'Modified',
      key: 'updated_at'
    }), Ember.Object.create({
      title: 'Lots count',
      key: 'lots_count'
    }), Ember.Object.create({
      title: 'Lots total usd',
      key: 'lots_total_usd'
    })],

    activeDirection: 'asc',

    activeWithoutDefault: Ember.computed('sorting._field', 'sorting.name.ascending', function () {
      return this.get('sorting._field') && !this.get('sorting.name.ascending');
    }),

    actions: {
      orderCancel: function orderCancel() {
        this._super.apply(this, arguments);
        this.set('activeDirection', 'asc');
      }
    }
  });
});