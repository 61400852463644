define('cdata/pods/components/lots/list/set-filter/mixin', ['exports', 'moment', 'cdata/mixins/lists/component-filter', 'cdata/mixins/components/cdata-categories-filter', 'cdata/utils/auction-houses-options', 'cdata/utils/computed', 'cdata/pods/components/lots/list/set-filter/sales-mixin'], function (exports, _moment, _componentFilter, _cdataCategoriesFilter, _auctionHousesOptions, _computed, _salesMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_componentFilter.default, _cdataCategoriesFilter.default, _salesMixin.default, {
    fields: ['shown', 'source', 'saleSource', 'lotsMissingCreator', 'multiObject', 'multiObjectCount', 'pwcaMakers', 'impModMakers', 'hasMultipleEntities', 'hasOneEntity', 'estimateOnRequest', 'hasNoEntities', 'miCategoryIds', 'cdataCategoryIds', 'creators', 'materialIds', 'materialOr', 'creationDateStart', 'creationDateFinish', 'auctionHouseIds', 'saleDateFrom', 'saleDateTo', 'saleFinishDateFrom', 'saleFinishDateTo', 'sessionStatusIds', 'priceTypeIds', 'lotStatusIds', 'saleIds', 'sessionIds', 'hasUnknownPrice', 'hasUnknownEstimate', 'priceMin', 'priceMax', 'measurementTypeId', 'measurementValueMin', 'measurementValueMax', 'setId', 'allOfSet', 'bySet', 'missingMeasurements', 'missingWidth', 'missingHeight', 'missingCreationDateStart', 'missingCreationDateFinish', 'estimateMin', 'estimateMax', 'missingPrices', 'christiesSpecialistDepartmentIds'],

    materials: [],
    creators: [],
    creatorQualifiers: [],
    miCategories: [],
    people: [],
    organizations: [],
    auctionHouses: [],
    sessionStatuses: [],
    priceTypes: [],
    sales: [],
    lotStatuses: [],
    christiesSpecialistDepartments: [],

    sessions: [],
    _sales: [],

    materialIds: Ember.computed.mapBy('materials', 'id'),
    miCategoriesIds: Ember.computed.mapBy('miCategories', 'id'),
    auctionHouseIds: Ember.computed.mapBy('auctionHouses', 'id'),
    sessionStatusIds: Ember.computed.mapBy('sessionStatuses', 'id'),
    priceTypeIds: Ember.computed.mapBy('priceTypes', 'id'),
    lotStatusIds: Ember.computed.mapBy('lotStatuses', 'id'),
    saleIds: Ember.computed.mapBy('sales', 'id'),
    sessionIds: Ember.computed.mapBy('sessions', 'id'),
    christiesSpecialistDepartmentIds: Ember.computed.mapBy('christiesSpecialistDepartments', 'id'),

    // `Id` it's a number when we parse query params, in other cases `id` it's a string
    auctionHouseStringIds: Ember.computed.map('auctionHouseIds', function (item) {
      return '' + item;
    }),

    auctionHousesOptions: (0, _auctionHousesOptions.getAuctionHousesOptions)(),
    auctionHousesVisible: (0, _computed.computedWithoutByIds)('auctionHousesOptions', 'auctionHouseStringIds'),

    countObjects: null,

    objectsCountList: [Ember.Object.create({
      id: 'hasOneEntity',
      label: 'Has one object'
    }), Ember.Object.create({
      id: 'hasMultipleEntities',
      label: 'Has multiple objects'
    }), Ember.Object.create({
      id: 'hasNoEntities',
      label: 'Has not objects'
    })],

    multiObjectCountList: [Ember.Object.create({
      id: 'unknown',
      label: 'Unknown'
    }), Ember.Object.create({
      id: 'single',
      label: 'Single object'
    }), Ember.Object.create({
      id: 'multi',
      label: 'Multi object'
    })],

    shownList: [Ember.Object.create({
      id: 'true',
      label: 'shown'
    }), Ember.Object.create({
      id: 'false',
      label: 'hidden'
    })],

    initFilter: function initFilter() {
      this._super();

      this.syncDefaultQueryParams();
      this.initCreators();

      this.initFilterSubjects('miCategoryIds', 'miCategory', 'miCategories');
      this.initFilterSubjects('auctionHouseIds', 'organization', 'auctionHouses');
      this.initFilterSubjects('saleIds', 'sale', 'sales');

      this.initFilterVocabs('sessionStatusIds', 'sessionStatuses');
      this.initFilterVocabs('priceTypeIds', 'priceTypes');
      this.initFilterVocabs('lotStatusIds', 'lotStatuses');
      this.initFilterVocabs('materialIds', 'materials');
      this.initFilterSubjects('christiesSpecialistDepartmentIds', 'christiesSpecialist', 'christiesSpecialistDepartments');

      if (this.get('model.allOfSet') === undefined || !this.get('enableComparables')) {
        this.set('model.allOfSet', null);
      }

      if (this.get('model.bySet') === undefined || !this.get('enableComparables')) {
        this.set('model.bySet', null);
      }

      if (this.get('model.materialOr') === undefined) {
        this.set('model.materialOr', null);
      }

      if (this.get('model.hasUnknownPrice') === undefined) {
        this.set('model.hasUnknownPrice', null);
      }

      if (this.get('model.multiObject') === undefined) {
        this.set('model.multiObject', null);
      }

      if (this.get('model.pwcaMakers') === undefined) {
        this.set('model.pwcaMakers', null);
      }

      if (this.get('model.impModMakers') === undefined) {
        this.set('model.impModMakers', null);
      }

      if (this.get('model.hasUnknownEstimate') === undefined) {
        this.set('model.hasUnknownEstimate', null);
      }

      if (this.get('model.estimateOnRequest') === undefined) {
        this.set('model.estimateOnRequest', null);
      }

      if (this.get('model.hasOneEntity')) {
        this.set('countObjects', 'hasOneEntity');
      }

      if (this.get('model.hasMultipleEntities')) {
        this.set('countObjects', 'hasMultipleEntities');
      }

      if (this.get('model.hasNoEntities')) {
        this.set('countObjects', 'hasNoEntities');
      }

      this.initCdataCategoriesTree();
    },


    actions: {
      clear: function clear() {
        this.setProperties({
          miCategories: [],
          creators: [],
          people: [],
          organizations: [],
          auctionHouses: [],
          sessionStatuses: [],
          materials: [],
          priceTypes: [],
          sales: [],
          sessions: [],
          creatorQualifiers: [],
          lotStatuses: [],
          countObjects: null
        });

        this.get('model').setProperties({
          saleMonths: [],
          hasOneEntity: null,
          hasNoEntities: null,
          multiObject: null,
          pwcaMakers: null,
          impModMakers: null,
          hasMultipleEntities: null
        });

        this._super();

        this.initCdataCategoriesTree();
      },
      setSource: function setSource(value) {
        this.set('model.source', value);
        this.applyFilter();
      },
      setSaleSource: function setSaleSource(value) {
        this.set('model.saleSource', value);
        this.applyFilter();
      },
      setMeasurementTypeId: function setMeasurementTypeId(value) {
        if (!value) {
          this.set('model.measurementValueMin', null);
          this.set('model.measurementValueMax', null);
        }
        this.set('model.measurementTypeId', value);
        this.applyFilter();
      },
      setMeasurementValues: function setMeasurementValues(min, max) {
        this.set('model.measurementValueMin', min);
        this.set('model.measurementValueMax', max);
        this.applyFilter();
      },
      setShown: function setShown(value) {
        this.set('model.shown', value);
        this.applyFilter();
      },
      setMaterialOr: function setMaterialOr() {
        var value = this.get('model.materialOr') ? null : true;
        this.set('model.materialOr', value);
        this.applyFilter();
      },
      setAllOffSet: function setAllOffSet() {
        var value = this.get('model.allOfSet') ? null : true;
        this.set('model.allOfSet', value);
        this.set('model.bySet', null);
        this.set('model.setId', this.get('model.allOfSet') || this.get('model.bySet') ? this.get('comparableSets.settings.setId') : null);

        this.applyFilter();
      },
      setBySet: function setBySet() {
        var value = this.get('model.bySet') ? null : true;
        this.set('model.bySet', value);
        this.set('model.allOfSet', null);
        this.set('model.setId', this.get('model.allOfSet') || this.get('model.bySet') ? this.get('comparableSets.settings.setId') : null);
        this.applyFilter();
      },
      setMultiObject: function setMultiObject() {
        var value = this.get('model.multiObject') ? null : true;
        this.set('model.multiObject', value);
        this.applyFilter();
      },
      setPwcaMakers: function setPwcaMakers() {
        var value = this.get('model.pwcaMakers') ? null : true;
        this.set('model.pwcaMakers', value);
        this.applyFilter();
      },
      setImpModMakers: function setImpModMakers() {
        var value = this.get('model.impModMakers') ? null : true;
        this.set('model.impModMakers', value);
        this.applyFilter();
      },
      setMissingPrices: function setMissingPrices() {
        var value = this.get('model.missingPrices') ? null : true;
        this.set('model.missingPrices', value);
        this.applyFilter();
      },
      setMissingMeasurements: function setMissingMeasurements() {
        var value = this.get('model.missingMeasurements') ? null : true;
        this.set('model.missingMeasurements', value);
        this.applyFilter();
      },
      setMissingWidth: function setMissingWidth() {
        var value = this.get('model.missingWidth') ? null : true;
        this.set('model.missingWidth', value);
        this.applyFilter();
      },
      setMissingHeight: function setMissingHeight() {
        var value = this.get('model.missingHeight') ? null : true;
        this.set('model.missingHeight', value);
        this.applyFilter();
      },
      setMissingCreationDateStart: function setMissingCreationDateStart() {
        var value = this.get('model.missingCreationDateStart') ? null : true;
        this.set('model.missingCreationDateStart', value);
        this.applyFilter();
      },
      setMissingCreationDateFinish: function setMissingCreationDateFinish() {
        var value = this.get('model.missingCreationDateFinish') ? null : true;
        this.set('model.missingCreationDateFinish', value);
        this.applyFilter();
      },
      setHasUnknownPrice: function setHasUnknownPrice() {
        var value = this.get('model.hasUnknownPrice') === null ? false : null;
        this.set('model.hasUnknownPrice', value);
        this.applyFilter();
      },
      setHasUnknownEstimate: function setHasUnknownEstimate() {
        var value = this.get('model.hasUnknownEstimate') === null ? false : null;
        this.set('model.hasUnknownEstimate', value);
        this.applyFilter();
      },
      setEstimateOnRequest: function setEstimateOnRequest() {
        var value = this.get('model.estimateOnRequest') === null ? true : null;
        this.set('model.estimateOnRequest', value);
        this.applyFilter();
      },
      setLotsMissingCreator: function setLotsMissingCreator() {
        var value = this.get('model.lotsMissingCreator') ? null : true;
        this.set('model.lotsMissingCreator', value);
        this.applyFilter();
      },
      setCountObjects: function setCountObjects(item) {
        this.set('countObjects', item && item.get('id') ? item.get('id') : null);

        var options = {
          hasOneEntity: null,
          hasNoEntities: null,
          hasMultipleEntities: null
        };

        if (this.get('countObjects')) {
          options[this.get('countObjects')] = true;
        }

        this.get('model').setProperties(options);
        this.applyFilter();
      },
      setMultiObjectCount: function setMultiObjectCount(item) {
        this.set('model.multiObjectCount', item && item.get('id') ? item.get('id') : null);
        this.applyFilter();
      },
      setCreationDates: function setCreationDates(start, finish) {
        this.set('model.creationDateStart', start);
        this.set('model.creationDateFinish', finish);
        this.applyFilter();
      },
      setPrices: function setPrices(start, finish) {
        this.set('model.priceMin', start);
        this.set('model.priceMax', finish);
        this.applyFilter();
      },
      setEstimates: function setEstimates(start, finish) {
        this.set('model.estimateMin', start);
        this.set('model.estimateMax', finish);
        this.applyFilter();
      },
      addCreator: function addCreator(subject) {
        this.addCreatorToCollection(subject);
      },
      removeCreator: function removeCreator(subject) {
        this.removeCreatorFromCollection(subject);
      },
      setDateFrom: function setDateFrom(date) {
        this.set('model.saleDateFrom', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      },
      setDateTo: function setDateTo(date) {
        this.set('model.saleDateTo', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      },
      setFinishDateFrom: function setFinishDateFrom(date) {
        this.set('model.saleFinishDateFrom', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      },
      setFinishDateTo: function setFinishDateTo(date) {
        this.set('model.saleFinishDateTo', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
        this.applyFilter();
      }
    },

    measurementTypesFiltered: Ember.computed('vocab.measurementTypesSorted', function () {
      return this.get('vocab.measurementTypesSorted').filter(function (item) {
        return ['height', 'width', 'depth', 'weight'].indexOf(item.get('label')) !== -1;
      });
    }),

    measurementUnit: Ember.computed('model.measurementTypeId', function () {
      var id = this.get('model.measurementTypeId');
      if (!id) {
        return null;
      }

      var name = this.get('vocab.measurementTypesLabels')[id];

      if (name === 'height' || name === 'width' || name === 'depth') {
        return 'cm';
      }

      if (name === 'weight') {
        return 'kg';
      }
    })
  });
});