define('cdata/models/location', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    _name: attr('string'),
    uuid: attr('string'),
    city: attr('string'),
    region: attr('string'),
    country: attr('string'),
    continent: attr('string'),
    address: attr('string'),
    googlePlaceId: attr('string'),
    geotagUrl: attr('string'),
    latitude: attr('float'),
    longitude: attr('float'),
    locationTitles: hasMany('location-title')
  });
});