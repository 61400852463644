define('cdata/models/vocabulary-item-term', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    vocabularyItemId: attr('string'),
    vocabularyItem: belongsTo('vocabulary-item'),
    language: attr('string', { defaultValue: 'en' }),
    languageVocabularyItemId: attr('number'),
    text: attr('string'),
    preferred: attr('boolean')
  });
});