define('cdata/mixins/modals/component-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modal: Ember.inject.service(),

    initCloseOnEscape: Ember.on('didInsertElement', function () {
      var _this = this;

      key('escape', 'all', function () {
        if (_this.get('modal.sid') === undefined) {
          key.unbind('escape', 'all');

          return false;
        }

        if (_this.get('modal.sid') === _this.get('modalClassId')) {
          Ember.run.later(function () {
            _this.send('cancel');
          });

          return false;
        }
      });
    }),

    removeCloseOnEscape: Ember.on('willDestroyElement', function () {
      if (!this.get('modal.sid')) {
        key.unbind('escape', 'all');
      }
    })
  });
});