define('cdata/utils/auth0', ['exports', 'npm:auth0-js', 'npm:pify'], function (exports, _npmAuth0Js, _npmPify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = function (config) {
    var webAuth = new _npmAuth0Js.default.WebAuth(_extends({}, config, {
      responseType: 'token id_token',
      scope: 'openid profile email',
      leeway: 60
    }));

    var connection = config.connection;

    var authorize = function authorize() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _options$state = options.state,
          state = _options$state === undefined ? {} : _options$state,
          rest = _objectWithoutProperties(options, ['state']);

      return webAuth.authorize(_extends({
        state: JSON.stringify(state),
        connection: connection
      }, rest));
    };
    var readAuthData = function readAuthData() {
      var parseHash = (0, _npmPify.default)(webAuth.parseHash.bind(webAuth));

      return parseHash();
    };

    return Object.freeze({
      authorize: authorize,
      readAuthData: readAuthData
    });
  };
});