define('cdata/services/relations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * Rollback attributes for all items from has many relation (deep)
     * @param {Object} model
     * @param {Array} skipList
     * @returns {rollbackHasMany}
     */
    rollbackHasMany: function rollbackHasMany(model) {
      var _this = this;

      var skipList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      model.eachRelationship(function (name, descriptor) {
        if (!name || !descriptor || descriptor.kind !== 'hasMany') {
          return;
        }

        if (skipList.indexOf(name) !== -1) {
          return;
        }

        var items = model.get(name);

        if (!Ember.isArray(items)) {
          return;
        }

        var itemsForDelete = [];
        var itemsForRollback = [];

        items.forEach(function (item) {
          if (!item) {
            return;
          }
          if (item.get('isNew')) {
            itemsForDelete.pushObject(item);
          } else {
            itemsForRollback.pushObject(item);
          }
        });

        itemsForDelete.forEach(function (item) {
          item.deleteRecord();
        });

        itemsForRollback.forEach(function (item) {
          item.rollbackAttributes();
          _this.rollbackHasMany(item);
        });
      });
    },


    /**
     * Remove relation 'belongs to'
     * @param model
     * @param belongsName
     */
    removeBelongsTo: function removeBelongsTo(model, belongsName) {
      var belongsId = belongsName + 'Id';
      var tmpKey = '__' + belongsName;

      if (model.get(belongsId) && !model.get(tmpKey)) {
        model.set(tmpKey, model.get(belongsName));
      }

      model.set(belongsId, null);
      model.set(belongsName, null);
    },


    /**
     * Clear old saved relation 'belongs to'
     * @param model
     * @param belongsName
     */
    clearBelongsTo: function clearBelongsTo(model, belongsName) {
      var tmpKey = '__' + belongsName;
      model.set(tmpKey, undefined);
    },
    resetBelongsTo: function resetBelongsTo(model, belongsName) {
      var belongsId = belongsName + 'Id';
      var tmpKey = '__' + belongsName;

      model = model.get('content') || model;
      model.rollbackAttributes();

      if (model.get(belongsId) && model.get(tmpKey) && (!model.get(belongsName + '.id') || model.get(belongsName + '.id') !== model.get(belongsId))) {
        model.set(belongsName, model.get(tmpKey));
        model.set(tmpKey, null);
      }

      if (!model.get(belongsId)) {
        model.set(belongsName, null);
      }
    },


    /**
     * Iterate by relation and save all dirty items
     * @param {Object} model
     * @param {Array} relationships
     */
    saveSimpleHasMany: function saveSimpleHasMany(model, relationshipsNames) {
      var _this2 = this;

      var relationships = relationshipsNames.map(function (name) {
        return model.get(name);
      });

      return relationships.map(function (items) {
        var dirtyItems = items.filterBy('hasDirtyAttributes');

        if (Ember.isArray(dirtyItems)) {
          var hasPreferred = Boolean(dirtyItems.findBy('preferred'));

          if (hasPreferred) {
            return _this2.saveWithPreferred(dirtyItems);
          } else {
            return Ember.RSVP.all(dirtyItems.invoke('save'));
          }
        } else {
          return Ember.RSVP.resolve();
        }
      });
    },


    /**
     * Saves `preferred` records in the required order
     * @param {Array} models
     * @returns {Promise}
     */
    saveWithPreferred: function saveWithPreferred(items) {
      var preferredItem = items.findBy('preferred');
      var otherItems = items.rejectBy('preferred');

      // Only one record can be preferred every step of the way. See MUS-716
      return Ember.RSVP.all(otherItems.invoke('save')).then(function () {
        return preferredItem.save();
      });
    },


    /**
     * Iterate by relation and delete all deleted items
     * @param {Object} model
     * @param {Array} relationships
     */
    deleteSimpleHasMany: function deleteSimpleHasMany(model, relationships) {
      var promises = [];
      relationships.forEach(function (simpleRelation) {
        var items = model.get(simpleRelation);
        if (Ember.isArray(items)) {
          model.get(simpleRelation).filterBy('isDeleted').forEach(function (item) {
            promises.push(item.save());
          });
        }
      });

      return promises;
    },
    hasDirtyState: function hasDirtyState(items) {
      if (!Ember.isArray(items)) {
        items = [items];
      }

      var has = items.filterBy('hasDirtyAttributes').get('length') > 0;
      has = has || items.filterBy('isDeleted').get('length') > 0;
      has = has || items.filterBy('isNew').get('length') > 0;

      return has;
    }
  });
});