define('cdata/pods/components/entities/edit/edit-forms-numbers/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('ReferenceNumberNote'), {
    addEntityReferenceNumber: 'addEntityReferenceNumber',
    removeEntityReferenceNumber: 'removeEntityReferenceNumber',
    clearEntityReferenceNumberSection: 'clearEntityReferenceNumberSection',

    notes: Ember.computed.filterBy('model.entityReferenceNumberNotes', 'isDeleted', false),
    numbers: Ember.computed.filterBy('model.entityReferenceNumbers', 'isDeleted', false),

    actions: {
      clear: function clear() {
        this.sendAction('clearEntityReferenceNumberSection');
      },
      addNumber: function addNumber() {
        this.sendAction('addEntityReferenceNumber');
      },
      addNote: function addNote() {
        this.sendAction('addEntityReferenceNumberNote');
      },
      remove: function remove(model) {
        var name = model.get('constructor.modelName');

        if ('entity-reference-number' === name) {
          this.sendAction('removeEntityReferenceNumber', model);
        }

        if ('entity-reference-number-note' === name) {
          this.sendAction('removeEntityReferenceNumberNote', model);
        }
      }
    }
  });
});