define('cdata/pods/components/wrappers/open-row-cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EditRowCell = Ember.Component.extend({
    classNames: ['four', 'wide', 'column', 'open-row-cell'],
    classNameBindings: ['editable'],

    editable: Ember.computed.bool('open'),

    actions: {
      open: function open() {
        this.get('open')();
      }
    }
  });

  EditRowCell.reopenClass({
    positionalParams: ['label', 'open']
  });

  exports.default = EditRowCell;
});