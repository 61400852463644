define('cdata/pods/components/entities/list/set-results/component', ['exports', 'cdata/mixins/lists/set-results'], function (exports, _setResults) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_setResults.default, {
    addToSaleMode: false,

    createNewLotFromAlreadyObject: 'createNewLotFromAlreadyObject',

    actions: {
      addLot: function addLot(entityId) {
        this.sendAction('createNewLotFromAlreadyObject', entityId);
      }
    }
  });
});