define('cdata/pods/components/entities/edit/edit-forms-examinations/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Examination'), {
    examinations: Ember.computed.filterBy('model.entityExaminations', 'isDeleted', false),
    setExaminerToExamination: 'setExaminerToExamination',

    actions: {
      setExaminerToExamination: function setExaminerToExamination() {
        this.sendAction.apply(this, ['setExaminerToExamination'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});