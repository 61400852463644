define('cdata/models/entity-examination', ['exports', 'ember-data', 'cdata/mixins/models/user-name'], function (exports, _emberData, _userName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_userName.default, {
    entityId: attr('string'),
    entity: belongsTo('entity'),
    text: attr('string'),
    typeVocabularyItemId: attr('string'),
    referenceNumber: attr('string'),
    personId: attr('string'),
    person: belongsTo('person'),
    organizationId: attr('string'),
    organization: belongsTo('organization'),
    date: attr('string'),
    citationLabelVocabularyItemId: attr('string'),
    userId: attr('string'),

    examinerLabel: Ember.computed('_personName', 'person.name', '_organizationTitle', 'organization.title', function () {
      return this.get('_personName') || this.get('_organizationTitle') || this.get('person.name') || this.get('organization.title');
    })
  });
});