define('cdata/mixins/components/render-with-citation', ['exports', 'cdata/helpers/short-url'], function (exports, _shortUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var escape = Ember.Handlebars.Utils.escapeExpression;
  exports.default = Ember.Mixin.create({
    userActions: Ember.inject.service(),

    displaySettings: Ember.computed('enabledSourceTags', 'enabledSourceUsers', function () {
      return this.get('enabledSourceTags') + '-' + this.get('enabledSourceUsers');
    }),

    enabledSourceTags: Ember.computed.alias('userActions.sourceTags.settings.enabledTags'),
    enabledSourceUsers: Ember.computed.alias('userActions.sourceTags.settings.enabledUsers'),

    renderItemLabelWithCitation: function renderItemLabelWithCitation(item, attrName) {
      if (!item) {
        return null;
      }

      var label = null;
      if (typeof attrName === 'function') {
        label = attrName(item);
      } else {
        label = item.get(attrName);
      }

      var suffix = this._getSourceUserString(item);

      return suffix ? Ember.String.htmlSafe(escape(label) + suffix) : label;
    },
    renderUrlWithCitation: function renderUrlWithCitation(website) {
      if (!website) {
        return null;
      }

      if (!website.get('url')) {
        return null;
      }

      var url = website.get('url');
      var citation = website.get('citationName') || '';
      url = '<a href="' + escape(url) + '" target="_blank">' + _shortUrl.default.compute([url, 40]) + '</a>';
      var result = citation ? citation + ': ' + url : url;

      if (website.get('userName')) {
        result += ' &larr; ' + '<i>' + website.get('userName') + '</i>';
      }

      return Ember.String.htmlSafe(result);
    },
    _getSourceUserString: function _getSourceUserString(item) {
      var user = this.get('enabledSourceUsers') && item && item.get('userName');
      var citation = this.get('enabledSourceTags') && item && item.get('citationName');

      var meta = [];
      if (citation) {
        meta.push(citation);
      }
      if (user) {
        meta.push('<i>' + user + '</i>');
      }

      return meta.length > 0 ? '<span class="grey"> &larr; ' + meta.join(' &larr; ') + '</span>' : null;
    },
    _getCitationLabel: function _getCitationLabel(item) {
      return item.get('citationName') || '';
    },
    _isChartOfArt: function _isChartOfArt(item) {
      return this._getCitationLabel(item) === 'Chart of Art';
    },
    _isNotChartOfArt: function _isNotChartOfArt(item) {
      return this._getCitationLabel(item) !== 'Chart of Art';
    }
  });
});