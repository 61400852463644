define('cdata/pods/components/entities/edit/edit-forms-descriptions/note/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms-item'], function (exports, _editFormsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsItem.default, {
    parseEntityText: 'parseEntityText',

    actions: {
      parseText: function parseText() {
        var text = this.get('model.text');
        if (text) {
          this.sendAction('parseEntityText', this.get('model'));
        }
      }
    }
  });
});