define('cdata/pods/components/marks/list/set-filters/component', ['exports', 'cdata/mixins/lists/component-filter', 'cdata/mixins/components/cdata-categories-filter'], function (exports, _componentFilter, _cdataCategoriesFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentFilter.default, _cdataCategoriesFilter.default, {
    fields: ['query', 'symbolIds', 'cdataCategoryIds', 'personIds', 'organizationIds', 'shapeIds', 'placeIds', 'typeIds', 'childPlaces', 'yearFrom', 'yearTo', 'letterIds', 'materialIds'],

    symbols: [],
    letters: [],
    people: [],
    organizations: [],
    places: [],
    types: [],
    shapes: [],
    materials: [],

    symbolIds: Ember.computed.mapBy('symbols', 'id'),
    organizationIds: Ember.computed.mapBy('auctionHouses', 'id'),
    personIds: Ember.computed.mapBy('auctionHouses', 'id'),
    placeIds: Ember.computed.mapBy('places', 'id'),
    typeIds: Ember.computed.mapBy('types', 'id'),
    shapeIds: Ember.computed.mapBy('shapes', 'id'),
    materialIds: Ember.computed.mapBy('materials', 'id'),
    letterIds: Ember.computed.mapBy('letters', 'id'),

    initFilter: function initFilter() {
      this._super();

      this.initFilterSubjects('symbolIds', 'symbol', 'symbols');
      this.initFilterSubjects('personIds', 'person', 'people');
      this.initFilterSubjects('organizationIds', 'organization', 'organizations');
      this.initFilterSubjects('placeIds', 'place', 'places');
      this.initFilterSubjects('shapeIds', 'shape', 'shapes');
      this.initFilterSubjects('materialIds', 'material', 'materials');
      this.initFilterSubjects('typeIds', 'inscriptionType', 'types');

      if (this.get('model.letterIds')) {
        this.set('letters', this.get('model.letterIds').map(function (letter) {
          return Ember.Object.create({
            id: letter,
            name: letter,
            description: null
          });
        }));
      }

      this.initCdataCategoriesTree();
    },


    actions: {
      setYears: function setYears(from, to) {
        this.set('model.yearFrom', from);
        this.set('model.yearTo', to);
        this.applyFilter();
      },
      setChildPlaces: function setChildPlaces() {
        var value = this.get('model.childPlaces') ? null : true;
        this.set('model.childPlaces', value);
        this.applyFilter();
      },
      clear: function clear() {
        this.setProperties({
          organizations: [],
          people: [],
          places: [],
          types: [],
          shapes: [],
          materials: [],
          letters: [],
          symbols: []
        });

        this._super();

        this.initCdataCategoriesTree();
      }
    }
  });
});