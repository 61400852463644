define('cdata/helpers/pref', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pref = pref;
  function pref(params) {
    var item = params[0];
    if (typeof item !== 'boolean') {
      item = item && item.get('preferred');
    }

    return item ? 'pref' : 'no-pref';
  }

  exports.default = Ember.Helper.helper(pref);
});