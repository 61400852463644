define('cdata/pods/entities/entity-mixin-show', ['exports', 'cdata/mixins/route-edit', 'cdata/mixins/route-create-modals', 'cdata/mixins/editor/add-comment', 'cdata/pods/entities/entity-mixin'], function (exports, _routeEdit, _routeCreateModals, _addComment, _entityMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_routeEdit.default, _routeCreateModals.default, _entityMixin.default, _addComment.default, {
    editor: Ember.Object.create({}),
    ajax: Ember.inject.service(),
    userActions: Ember.inject.service(),
    predictedPrices: Ember.computed.alias('userActions.predictedPrices'),

    model: function model(params) {
      return this.get('store').find('entity', params.entity_id);
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (model.get('entityCreationPlaces.length')) {
        model.get('entityCreationPlaces').forEach(function (cp) {
          cp.buildName();
        });
      }

      if (model.get('entityStyles.length')) {
        this.get('subjectSearch').initVocabularyLabels(model.get('entityStyles'), 'styleVocabularyItemId');
      }

      try {
        var lotController = this.controllerFor('lots.show');
        if (lotController) {
          lotController.set('entity', model);
        }
      } catch (e) {
        // skip
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('editor', this.get('editor'));
      this._setLot(controller);
      this._loadPredictedModel(controller, this.get('withLot') ? controller.get('lot') : null, model);
    },


    actions: {
      openEntityForms: function openEntityForms(formName) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        this._clearEditor();
        this.set('editor.options', options);
        this.set('editor.formName', 'entities/edit/' + formName);
        Ember.$('body').css('overflow', 'hidden');
      },
      loadSaleFor: function loadSaleFor(context, id) {
        var key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'sale';

        this.get('store').findRecord('sale', id).then(function (sale) {
          sale.get('organization').then(function () {
            context.set(key, sale);
            context.set('saleId', sale.get('id'));
          });
        });
      },
      reloadPredictedModel: function reloadPredictedModel() {
        var entityController = null;
        var lot = null;
        var entity = null;

        if (this.get('withLot')) {
          var lotController = this.controllerFor('lots.show');
          lot = lotController.get('model');
          entityController = this.controllerFor('entities.subject');
          entity = entityController.get('model');
        } else {
          lot = null;
          entityController = this.controllerFor('entities.show');
        }

        entity = entityController.get('model');
        this._loadPredictedModel(entityController, lot, entity);
      },
      error: function error(_error) {
        this.get('flashMessage').showError('Object #' + this.paramsFor('entities.show').entity_id + ' cannot be found or have loading error' + "\n" + _error);
      }
    },

    _setLot: function _setLot(controller) {
      controller.set('lot', this.modelFor('lots.show'));
    },
    _loadPredictedModel: function _loadPredictedModel(controller, lot, entity) {
      controller.set('predictedModel', null);
      controller.set('predictedModelError', null);
      controller.set('predictedModelInProgress', true);

      var url = '';
      var params = { samples_count: 10000 };
      if (lot && lot.get('id')) {
        url = 'lots/spectrum/' + lot.get('id');
      } else {
        url = 'lots/spectrum/entity/' + entity.get('id');
        var settings = this.get('predictedPrices.settings');
        params = {
          auction_house_id: settings.auctionHouseId,
          location_id: settings.locationId,
          currency_vocabulary_item_id: settings.currencyId || 597,
          sale_date: settings.saleDate,
          sale_title: settings.saleTitle,
          estimate_low: settings.lowEstimate ? parseInt(settings.lowEstimate) : null,
          estimate_high: settings.highEstimate ? parseInt(settings.highEstimate) : null,
          reserve_price: settings.reservePrice ? parseInt(settings.reservePrice) : null,
          samples_count: 10000
        };
      }

      this.get('ajax').$get(url, params, function (response) {
        controller.set('predictedModel', response);
      }, function (error) {
        var message = error.responseJSON && error.responseJSON.error || error.responseText;
        if (message && message.includes('%{artist_uuid')) {
          var shortMessage = message.substr(0, message.search('%{artist_uuid'));
          shortMessage = shortMessage.replace('ML-services Client: ', '');
          controller.set('predictedModelError', { message: shortMessage, details: message });
        } else {
          controller.set('predictedModelError', { message: message, details: message });
        }
      }, function () {
        controller.set('predictedModelInProgress', false);
      });
    }
  });
});