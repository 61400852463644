define('cdata/pods/lots/route', ['exports', 'cdata/mixins/route-init', 'cdata/mixins/route-create-modals'], function (exports, _routeInit, _routeCreateModals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeCreateModals.default, _routeInit.default, {
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('breadCrumb', 'Lots');
    }
  });
});