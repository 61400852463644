define('cdata/services/prev-next-organizations-editor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    name: null,

    inProcess: false,

    list: [],

    position: null,

    item: Ember.computed('list.length', 'position', function () {
      if (this.get('position') != null && this.get('list.length') > 0) {
        return this.get('list').objectAt(this.get('position'));
      }
    }),

    prev: Ember.computed('list.length', 'position', function () {
      if (this.get('position') != null && this.get('list.length') > 0) {
        return this.get('list').objectAt(this.get('position') - 1);
      }
    }),

    next: Ember.computed('list.length', 'position', function () {
      if (this.get('position') != null && this.get('list.length') > 0) {
        return this.get('list').objectAt(this.get('position') + 1);
      }
    }),

    prevOrganization: Ember.computed.alias('prev'),
    nextOrganization: Ember.computed.alias('next'),

    prevOrganizationId: Ember.computed.alias('prevOrganization.organizationId'),
    prevRole: Ember.computed.alias('prevOrganization.role'),
    prevImage: Ember.computed.alias('prevOrganization.imageUrl'),
    prevTitle: Ember.computed.alias('prevOrganization.title'),
    prevDates: Ember.computed.alias('prevOrganization.existenceDates'),

    nextOrganizationId: Ember.computed.alias('nextOrganization.organizationId'),
    nextRole: Ember.computed.alias('nextOrganization.role'),
    nextImage: Ember.computed.alias('nextOrganization.imageUrl'),
    nextTitle: Ember.computed.alias('nextOrganization.title'),
    nextDates: Ember.computed.alias('nextOrganization.existenceDates'),

    setList: function setList(list) {
      this.set('list', list);
      this.set('position', 0);
    },
    changeItem: function changeItem(newId) {
      var _this = this;

      if (!this.get('list') || this.get('list.length') === 0 || this.get('position') === null) {
        return;
      }

      var index = 0;
      this.get('list').forEach(function (elem) {
        if (elem.get('id') === newId) {
          _this.set('position', index);
        }
        index++;
      });
    },
    doClear: function doClear() {
      this.set('list', []);
      this.set('position', null);
    }
  });
});