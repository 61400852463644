define('cdata/pods/components/people/edit/edit-forms-addresses/component', ['exports', 'cdata/pods/components/people/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Address'), {
    classNameBindings: ['editMode:edit-mode'],

    parseLocationForm: 'parseLocationForm',
    addressesChanged: 'doPersonAddressesChanged',

    editMode: Ember.computed('items.@each._isEditMode', function () {
      return this.get('items').filterBy('_isEditMode').length > 0;
    }),

    listMode: Ember.computed.not('editMode'),

    actions: {
      parseLocationForm: function parseLocationForm() {
        this.sendAction.apply(this, ['parseLocationForm'].concat(Array.prototype.slice.call(arguments)));
      },
      addressesChanged: function addressesChanged() {
        this.sendAction('doPersonAddressesChanged');
      }
    }
  });
});