define('cdata/pods/components/vocabularies/form-sid/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui grid form edit-block'],
    loadSidFor: 'loadSidFor',
    saveSid: 'saveSid',
    sid: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.sendAction('loadSidFor', this, this.get('model.id'));
    },


    actions: {
      save: function save() {
        this.sendAction('saveSid', this.get('sid'));
      }
    }
  });
});