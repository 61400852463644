define('cdata/pods/components/lots/modals/create-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.LOT,
    classNames: ['context-modal', _constants.MODALS.LOT, 'edit-form'],
    inProcess: Ember.computed.alias('lot.inProcess'),
    isDisabled: Ember.computed.empty('lot.sessionModel'),

    actions: {
      setSession: function setSession(_, sessionSubject) {
        this.set('lot.session', sessionSubject);
        this.sendAction('lotCreatingHelper', this.get('lot'));
      },
      setSale: function setSale(_, saleSubject) {
        this.set('lot.sale', saleSubject);
        this.sendAction('lotCreatingHelper', this.get('lot'));
      },
      setAuctionHouse: function setAuctionHouse(_, auctionHouseSubject) {
        this.set('lot.auctionHouse', auctionHouseSubject);
        this.sendAction('lotCreatingHelper', this.get('lot'));
      },
      save: function save() {
        var lotData = this.get('lot');
        var model = this.get('model');
        model.set('lotData', lotData);
        this.sendAction('saveNewLotForm', model);
      },
      follow: function follow() {
        var lotData = this.get('lot');
        var model = this.get('model');
        model.set('lotData', lotData);
        this.sendAction('saveNewLotForm', model, true);
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeNewLotForm');
      }
    },

    _initLot: function _initLot() {
      var lot = Ember.Object.create({
        inProcess: false
      });
      this.set('lot', lot);
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this._initLot();
    }
  });
});