define('cdata/pods/components/marks/list/sort-box/component', ['exports', 'cdata/mixins/lists/component-sort-box'], function (exports, _componentSortBox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentSortBox.default, {
    values: [Ember.Object.create({
      title: 'Title',
      key: 'title'
    }), Ember.Object.create({
      title: 'Objects count',
      key: 'object_count'
    }), Ember.Object.create({
      title: 'From year',
      key: 'start_year'
    }), Ember.Object.create({
      title: 'Modified',
      key: 'updated_at'
    })]
  });
});