define("cdata/mirage/datasets/entitiy_list_items", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "meta": {
      "total_pages": 311727,
      "page_size": 20,
      "page_number": 1,
      "count_total": 6234536,
      "count_filtered": 6234536
    },
    "entity_list_items": [{
      "updated_at": "2017-05-24T15:40:19.341174Z",
      "title": "Lake in the Sierras",
      "shown": true,
      "mi_category_vocabulary_item_id": null,
      "measurements": "28 x 34in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b7d/b16f/5804/d100/0428/original/57687b7db16f5804d1000428.jpg",
      "id": 19069372,
      "entity_id": 19069372,
      "creator_vital_dates": "1883 - 1947",
      "creator_name": "Payne Edgar Alwin",
      "creator_ids": ["person-186241-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": null,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:22.373392Z",
      "title": "Provincetown",
      "shown": true,
      "mi_category_vocabulary_item_id": 29,
      "measurements": "40 x 20in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b8a/b16f/5805/3700/0182/original/57687b8ab16f580537000182.jpg",
      "id": 19069973,
      "entity_id": 19069973,
      "creator_vital_dates": null,
      "creator_name": "Louise Eleanor Zaring",
      "creator_ids": ["person-230256-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": 88,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:22.602471Z",
      "title": "Chicago Street Scene",
      "shown": true,
      "mi_category_vocabulary_item_id": 29,
      "measurements": "24 x 20in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b7b/b16f/5804/d100/0197/original/57687b7bb16f5804d1000197.jpg",
      "id": 19068779,
      "entity_id": 19068779,
      "creator_vital_dates": "1884",
      "creator_name": "Rachel Hartley",
      "creator_ids": ["person-417853-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": 88,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:27.135335Z",
      "title": "The Surf, Southern California (No.1205)",
      "shown": true,
      "mi_category_vocabulary_item_id": 29,
      "measurements": "24 x 30in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b7c/b16f/5804/d100/03e0/original/57687b7cb16f5804d10003e0.jpg",
      "id": 19069341,
      "entity_id": 19069341,
      "creator_vital_dates": null,
      "creator_name": null,
      "creator_ids": [],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": 88,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:27.142062Z",
      "title": "Clearing Skies",
      "shown": true,
      "mi_category_vocabulary_item_id": null,
      "measurements": "22 x 30in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b7c/b16f/5804/d100/03e5/original/57687b7cb16f5804d10003e5.jpg",
      "id": 19069345,
      "entity_id": 19069345,
      "creator_vital_dates": "1884 - 1945",
      "creator_name": "Colman Roi Clarkson",
      "creator_ids": ["person-114693-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": null,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:10.661394Z",
      "title": "Untitled",
      "shown": true,
      "mi_category_vocabulary_item_id": null,
      "measurements": "61 x 50.2cm)",
      "materials": "24 x 19 3/4in (",
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7a36/b16f/587d/8e00/0355/original/57687a36b16f587d8e000355.jpg",
      "id": 19070199,
      "entity_id": 19070199,
      "creator_vital_dates": "1921",
      "creator_name": "Francoise Gilot",
      "creator_ids": ["person-63756-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": null,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:12.085879Z",
      "title": "Frank Stella (Set #6), 1966; Frank Stella Slieve More 1964, 1965; Frank Stella Black Adder 1965, 1965 (3)",
      "shown": true,
      "mi_category_vocabulary_item_id": null,
      "measurements": "20.9 x 25.4cm)",
      "materials": "third 8 1/4 x 10in (",
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7a36/b16f/587d/8e00/03d3/original/57687a36b16f587d8e0003d3.jpg",
      "id": 19070256,
      "entity_id": 19070256,
      "creator_vital_dates": "1938",
      "creator_name": "Pettibone Richard",
      "creator_ids": ["person-187800-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": null,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:13.110874Z",
      "title": "Country Road at Sunset",
      "shown": true,
      "mi_category_vocabulary_item_id": 29,
      "measurements": "24 x 30in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b7d/b16f/5804/d100/045d/original/57687b7db16f5804d100045d.jpg",
      "id": 19069389,
      "entity_id": 19069389,
      "creator_vital_dates": "1875 - 1955",
      "creator_name": "Maurice August Del Mue",
      "creator_ids": ["person-376345-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": 88,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:21.403214Z",
      "title": "Sandali",
      "shown": true,
      "mi_category_vocabulary_item_id": null,
      "measurements": "9 3/4 x 13 1/4in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b8a/b16f/5805/3700/0117/original/57687b8ab16f580537000117.jpg",
      "id": 19069944,
      "entity_id": 19069944,
      "creator_vital_dates": "1856 - 1925",
      "creator_name": "Sargent John Singer",
      "creator_ids": ["person-200704-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": null,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:26.047769Z",
      "title": "Iris Canyon, near Monterey, California, 1948",
      "shown": true,
      "mi_category_vocabulary_item_id": 29,
      "measurements": "14 x 20in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b7c/b16f/5804/d100/0356/original/57687b7cb16f5804d1000356.jpg",
      "id": 19069127,
      "entity_id": 19069127,
      "creator_vital_dates": "1883 - 1975",
      "creator_name": "Ferdinand Burgdorff",
      "creator_ids": ["person-469356-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": 88,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:27.106246Z",
      "title": "Wild Heliotrope near Laguna Beach",
      "shown": true,
      "mi_category_vocabulary_item_id": 29,
      "measurements": "20 x 30in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b7c/b16f/5804/d100/03db/original/57687b7cb16f5804d10003db.jpg",
      "id": 19069340,
      "entity_id": 19069340,
      "creator_vital_dates": "1863 - 1957",
      "creator_name": "Gamble John Marshall",
      "creator_ids": ["person-134599-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": 88,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:16.017323Z",
      "title": "Cascade, New England",
      "shown": true,
      "mi_category_vocabulary_item_id": null,
      "measurements": "12 x 7 3/8in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b89/b16f/5805/3700/007b/original/57687b89b16f58053700007b.jpg",
      "id": 19069691,
      "entity_id": 19069691,
      "creator_vital_dates": null,
      "creator_name": null,
      "creator_ids": [],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": null,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:19.775951Z",
      "title": "The Vegetable Garden",
      "shown": true,
      "mi_category_vocabulary_item_id": 29,
      "measurements": "15 x 22 1/2in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b7d/b16f/5804/d100/05c6/original/57687b7db16f5804d10005c6.jpg",
      "id": 19069601,
      "entity_id": 19069601,
      "creator_vital_dates": null,
      "creator_name": null,
      "creator_ids": [],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": 88,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:28.296545Z",
      "title": "Still Life with Bell Peppers, Onions, and Eggplants (No.89)",
      "shown": true,
      "mi_category_vocabulary_item_id": 29,
      "measurements": "25 x 30in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b7d/b16f/5804/d100/0552/original/57687b7db16f5804d1000552.jpg",
      "id": 19069551,
      "entity_id": 19069551,
      "creator_vital_dates": "1886 - 1977",
      "creator_name": "Maurice George Logan",
      "creator_ids": ["person-376465-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": 88,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:40:29.506819Z",
      "title": "View from the Station",
      "shown": true,
      "mi_category_vocabulary_item_id": null,
      "measurements": "19 1/2 x 24 1/2in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/7b8a/b16f/5805/3700/01f7/original/57687b8ab16f5805370001f7.jpg",
      "id": 19070025,
      "entity_id": 19070025,
      "creator_vital_dates": null,
      "creator_name": null,
      "creator_ids": [],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": null,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:41:32.116919Z",
      "title": "Sketch for Antelope Group",
      "shown": true,
      "mi_category_vocabulary_item_id": 29,
      "measurements": "12 x 16in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/86e7/b16f/5836/fb00/0306/original/576886e7b16f5836fb000306.jpg",
      "id": 19076950,
      "entity_id": 19076950,
      "creator_vital_dates": null,
      "creator_name": null,
      "creator_ids": [],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": 88,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:41:33.916327Z",
      "title": "Still life of vases and flowers",
      "shown": true,
      "mi_category_vocabulary_item_id": null,
      "measurements": "46.4 x 38.1cm (18 1/4 x 15in)",
      "materials": "oil on canvas",
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/8bc3/b16f/584b/0d00/02a7/original/57688bc3b16f584b0d0002a7.jpg",
      "id": 19077681,
      "entity_id": 19077681,
      "creator_vital_dates": "1900 - 1986",
      "creator_name": "Cecil Higgs",
      "creator_ids": ["person-450659-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": null,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:41:34.098570Z",
      "title": "Parisian street party",
      "shown": true,
      "mi_category_vocabulary_item_id": null,
      "measurements": "54 x 16cm (21 1/4 x 6 5/16in)",
      "materials": "oil on canvas",
      "lots_count": 1,
      "image_url": null,
      "id": 19077697,
      "entity_id": 19077697,
      "creator_vital_dates": "1913 - 2013",
      "creator_name": "Gerard Sekoto",
      "creator_ids": ["person-203721-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": null,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:41:35.858854Z",
      "title": "Sunset Steeple (Coast Highway, Montecito)",
      "shown": true,
      "mi_category_vocabulary_item_id": 29,
      "measurements": "17 3/4 x 19 3/4in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/88af/b16f/583d/0c00/0524/original/576888afb16f583d0c000524.jpg",
      "id": 19077055,
      "entity_id": 19077055,
      "creator_vital_dates": "1890 - 1949",
      "creator_name": "August Gay",
      "creator_ids": ["person-337303-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": 88,
      "cdata_category_vocabulary_item_id": 538
    }, {
      "updated_at": "2017-05-24T15:41:36.907868Z",
      "title": "Purple Foothills - San Gabriel Mountains",
      "shown": true,
      "mi_category_vocabulary_item_id": 29,
      "measurements": "12 x 16in",
      "materials": null,
      "lots_count": 1,
      "image_url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5768/88af/b16f/583d/0c00/05ae/original/576888afb16f583d0c0005ae.jpg",
      "id": 19077151,
      "entity_id": 19077151,
      "creator_vital_dates": "1883 - 1947",
      "creator_name": "Payne Edgar Alwin",
      "creator_ids": ["person-186241-"],
      "creation_date_start": null,
      "creation_date_finish": null,
      "creation_date": null,
      "christies_business_group_vocabulary_item_id": 88,
      "cdata_category_vocabulary_item_id": 538
    }]
  };
});