define('cdata/pods/components/section-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var defaultProp = function defaultProp(prop) {
    var byDefault = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return Ember.computed(prop, function () {
      var value = this.get(prop);

      return Ember.isNone(value) ? byDefault : value;
    });
  };

  exports.default = Ember.Component.extend({
    classNames: ['section-field'],
    classNameBindings: ['empty'],

    openForms: 'openForms',

    others: Ember.computed(function () {
      return [];
    }),
    others1: Ember.computed(function () {
      return [];
    }),
    others2: Ember.computed(function () {
      return [];
    }),

    listOthers: Ember.computed('others.[]', 'others1.[]', 'others2.[]', function () {
      if (this.get('others.length')) {
        return this.get('others');
      }

      if (!this.get('others1.length') && !this.get('others2.length')) {
        return [];
      }

      if (typeof this.get('others2').concat === 'function') {
        return this.get('others2').concat(this.get('others1'));
      }

      return this.get('others2').toArray().concat(this.get('others1').toArray());
    }),

    emptyPreferred: Ember.computed('preferred', function () {
      var preferred = this.get('preferred');

      return Ember.isNone(preferred);
    }),

    emptyOthers: Ember.computed.empty('listOthers'),

    empty: Ember.computed.and('emptyPreferred', 'emptyOthers'),

    actions: {
      openForms: function openForms() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.sendAction.apply(this, ['openForms'].concat(_toConsumableArray(args)));
      },
      doReview: function doReview() {
        for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        this.sendAction.apply(this, ['doReview'].concat(_toConsumableArray(args)));
      }
    },

    enabled: defaultProp('actionsProps.enabled', true),

    disableTitle: defaultProp('actionsProps.disableTitle'),

    enabledReview: defaultProp('actionsProps.enabledReview', false),

    asPref: false
  });
});