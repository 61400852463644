define('cdata/pods/organizations/show/route', ['exports', 'cdata/mixins/route-edit', 'cdata/pods/organizations/edit-mixin', 'cdata/pods/organizations/merge-mixin', 'cdata/mixins/editor/add-comment', 'cdata/mixins/audit-logs/audit-log-route'], function (exports, _routeEdit, _editMixin, _mergeMixin, _addComment, _auditLogRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeEdit.default, _editMixin.default, _mergeMixin.default, _addComment.default, _auditLogRoute.default, {
    editor: Ember.Object.create({}),

    model: function model(params) {
      return this.get('store').find('organization', params.organization_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('editor', this.get('editor'));
      controller.set('sales', []);
      controller.set('lots', []);

      if (model.get('isAuctionHouse')) {
        this.loadOrganizationSales(model.get('id'));
      }

      this.loadOrganizationLots(model.get('id'));
    },


    actions: {
      openOrganizationForms: function openOrganizationForms(formName, options) {
        this._clearEditor();
        this.set('editor.options', options);
        this.set('editor.formName', 'organizations/edit/' + formName);
        Ember.$('body').css('overflow', 'hidden');
      },
      error: function error() {
        this.get('flashMessage').showError('Organization #' + this.paramsFor('organizations.show').organization_id + ' cannot be found or have loading error');
      },
      reload: function reload() {
        this.refresh();
      }
    },

    loadOrganizationSales: function loadOrganizationSales(id) {
      var _this = this;

      this.get('store').query('sale-list-item', {
        filter: {
          auction_house_ids: [id]
        },
        sort: {
          by: 'start_date'
        },
        page_size: 3
      }).then(function (items) {
        _this.get('controller').set('sales', items);
      });
    },
    loadOrganizationLots: function loadOrganizationLots(id) {
      var _this2 = this;

      this.get('store').query('lot-list-item', {
        filter: {
          creators: [{
            type: 'organization',
            id: id
          }]
        },
        sort: {
          by: 'estimate_low_usd'
        },
        page_size: 3
      }).then(function (items) {
        _this2.get('controller').set('lots', items);
      });
    }
  });
});