define('cdata/pods/components/entities/edit/edit-forms-styles/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Style'), {
    styles: Ember.computed.filterBy('model.entityStyles', 'isDeleted', false),
    styleIds: Ember.computed.mapBy('styles', 'styleVocabularyItemId'),
    autoCreating: false
  });
});