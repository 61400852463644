define('cdata/pods/components/site/editor/address-item/form/stylesheet', ['exports', 'ember-cli-jss'], function (exports, _emberCliJss) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = new _emberCliJss.StyleSheet({
    textField: {
      display: 'flex',
      alignItems: 'center',
      height: 35
    }
  });
});