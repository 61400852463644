define('cdata/pods/comparables/item/mixins/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    indicesChartParams: null,

    inProgress: false,

    actions: {
      applyEstimatesParams: function applyEstimatesParams() {
        this.send('loadCustomEstimate', this.get('estimatesParams'));
      },
      runEstimates: function runEstimates() {
        if (!this.get('inProgress')) {
          this.send('loadCustomEstimate', this.get('estimatesParams'));
        }
      }
    },

    setInProgress: function setInProgress(value) {
      var _this = this;

      Ember.run.schedule('afterRender', this, function () {
        _this.set('inProgress', value);
      });
    }
  });
});