define('cdata/mixins/route-init', ['exports', 'cdata/mixins/route-type-page', 'cdata/config/environment'], function (exports, _routeTypePage, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_routeTypePage.default, {
    _pageType: 'main',

    session: Ember.inject.service(),
    transitionSpot: Ember.inject.service(),
    subjectSearch: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var hasAuth = this.get('session.hasAuth');
      var vocabsLoaded = this.get('vocab.isLoaded');
      var transitionSpot = this.get('transitionSpot');

      if (_environment.default.environment === 'test') {
        hasAuth = true;
      }

      if (!hasAuth || !vocabsLoaded) {
        transitionSpot.keepTransition(this, transition);
        transitionSpot.keepPrevUrl(transition.intent.url);
        transition.abort();
      }

      if (!hasAuth) {
        this.transitionTo('init.login');

        return;
      }

      this.get('subjectSearch').set('headers', this.get('session.authHeader'));

      if (!vocabsLoaded) {
        this.transitionTo('init.loader');
      }
    }
  });
});