define('cdata/pods/components/sales/modals/refresh-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.SALE_REFRESH,
    classNames: ['context-modal', _constants.MODALS.SALE_REFRESH, 'edit-form'],
    inProcess: Ember.computed.alias('model.inProcess'),

    isEnabled: Ember.computed.alias('model.christiesSaleId'),
    isDisabled: Ember.computed.not('isEnabled'),

    closeRefreshSaleForm: 'closeRefreshSaleForm',
    saveRefreshSaleForm: 'saveRefreshSaleForm',

    actions: {
      save: function save() {
        this.sendAction('saveRefreshSaleForm', this.get('model'));
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeRefreshSaleForm');
      }
    }
  });
});