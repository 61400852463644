define('cdata/mixins/lists/route-list', ['exports', 'cdata/mixins/lists/route-pagination', 'cdata/mixins/lists/route-sorting', 'cdata/mixins/lists/route-filter', 'cdata/mixins/lists/route-bulk', 'cdata/mixins/route-edit', 'cdata/mixins/route-type-page', 'cdata/mixins/lists/merge-list', 'cdata/constants', 'ember-inflector'], function (exports, _routePagination, _routeSorting, _routeFilter, _routeBulk, _routeEdit, _routeTypePage, _mergeList, _constants, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Mixin.create(_routeTypePage.default, _routeEdit.default, _routePagination.default, _routeSorting.default, _routeFilter.default, _routeBulk.default, _mergeList.default, {
    _pageType: 'list',

    modal: Ember.inject.service(),
    session: Ember.inject.service(),

    assignFormPath: 'dummy.assign.form.path',
    assignModalId: null,

    actions: {
      /**
       * @param items target items for assign
       * @param option field/option for assign
       * @param optionLabel humanize title of option
       * @param isFilter true if need to use filter parameters instead items
       * @param countFiltered count of filtered items
       */
      openAssignForm: function openAssignForm(items, option, optionLabel, isFilter) {
        var countFiltered = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

        var model = Ember.Object.create({
          items: items,
          option: option,
          optionLabel: optionLabel,
          isFilter: isFilter,
          countFiltered: countFiltered
        });
        this.get('modal').register(this, this.get('assignModalId'), this.get('assignFormPath')).open(model);
      },
      closeAssignForm: function closeAssignForm() {
        this.get('modal').close(this.get('assignModalId'));
      },
      remove: function remove() {
        this.get('flashMessage').showError('"Delete" function not ready yet');
      },
      saveItem: function saveItem(item) {
        item.save();
      }
    },

    /**
     * Make properties for bulk updating
     * @param {String} assignOption name of assign option (role, nationality, etc)
     * @param {String|Boolean} assignValue id of assign subject
     * @param {Boolean} isFilter send filter parameters instead checked items
     * @param {String} rule rule for replace/alternate/preferred
     */
    _makeAssignProperties: function _makeAssignProperties(assignOption, assignValue, isFilter, rule) {
      switch (assignOption) {
        case 'unapprove':
          assignOption = 'approved';
          assignValue = false;
          break;

        case 'approve':
          assignOption = 'approved';
          assignValue = true;
          break;

        case 'shown':
          assignOption = 'shown';
          assignValue = true;
          break;

        case 'hidden':
          assignOption = 'shown';
          assignValue = false;
          break;
      }

      var filter = null;
      if (isFilter) {
        var params = {
          filter: this.get('filterParameters')
        };

        filter = this.makeQueryParameters(params).filter;

        if ((typeof filter === 'undefined' ? 'undefined' : _typeof(filter)) !== 'object' || Object.entries(filter).length === 0) {
          filter = null;
        }
      }

      return {
        field: assignOption,
        value: assignValue,
        replace: rule === 'replace-as-preferred',
        preferred: rule === 'replace-as-preferred' || rule === 'add-as-preferred' || rule === 'add-as-preferred-multiple',
        multiple: rule === 'add-as-preferred-multiple',
        filter: filter
      };
    },
    _saveAssignUpdate: function _saveAssignUpdate(names, props, ids, eventKey) {
      var _this = this;

      ids = Array.from(new Set(ids));

      var modal = this.get('modal');
      var endpoint = 'bulk_update/' + names;
      var url = _constants.API_URL + '/' + endpoint;
      var name = (0, _emberInflector.singularize)(names);
      var key = names + '_bulk_update';
      var listKeys = name + '_list_items';
      var listKey = name + '_list_item';

      var params = {};
      params[key] = props;
      params[key][name + '_ids'] = ids;
      params[key].value = /^[1-9]+[0-9]+$/.test(params[key].value) ? parseInt(params[key].value) : params[key].value;

      // special endpoint for deleting
      if (props.field === 'delete') {
        key = names + '_bulk_delete';
        endpoint = 'bulk_delete/' + names;
        url = _constants.API_URL + '/' + endpoint;
        params = {};
        params[key] = {};
        params[key][name + '_ids'] = ids;
      }

      modal.trigger(eventKey + '.process');

      Ember.$.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(params),
        contentType: 'application/json',
        headers: this.get('session.authHeader'),
        dataType: 'JSON',
        success: function success(response) {
          Ember.run(function () {
            // special case for deleting (mark list items as deleting)
            if (props.field === 'delete') {
              ids.forEach(function (id) {
                var record = _this.get('store').peekRecord(listKey, id);
                if (record) {
                  record.set('_deleted', true);
                }
              });

              _this.get('flashMessage').showSuccess('Accepted!' + '\n' + 'Please check changes later');

              return;
            }

            // update list items from server data after assigned new properties
            var items = response && response[listKeys];
            if (items) {
              items.forEach(function (item) {
                var existing = _this.get('store').peekRecord(listKey, item.id);
                if (existing) {
                  var _props = {};
                  var _iteratorNormalCompletion = true;
                  var _didIteratorError = false;
                  var _iteratorError = undefined;

                  try {
                    for (var _iterator = Object.entries(item)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                      var _ref = _step.value;

                      var _ref2 = _slicedToArray(_ref, 2);

                      var _key = _ref2[0];
                      var value = _ref2[1];

                      if (_key !== 'id') {
                        _props[_key.camelize()] = value;
                      }
                    }
                  } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                  } finally {
                    try {
                      if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                      }
                    } finally {
                      if (_didIteratorError) {
                        throw _iteratorError;
                      }
                    }
                  }

                  existing.setProperties(_props);
                }
              });

              _this.get('flashMessage').showSuccess('Successfully updated');
            } else {
              _this.get('flashMessage').showSuccess('Accepted!' + '\n' + 'Please check changes later');
            }

            modal.trigger(eventKey + '.success');
          });
        },
        complete: function complete() {
          modal.trigger(eventKey + '.finally');
        }
      }).fail(function (error) {
        modal.trigger(eventKey + '.error', { error: error });
        _this.get('flashMessage').showError(error);
      });
    }
  });
});