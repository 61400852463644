define('cdata/pods/components/organizations/view/organization-header/component', ['exports', 'cdata/mixins/components/item-header'], function (exports, _itemHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_itemHeader.default, {
    classNames: ['organizations-organization-header'],

    prevNextOrganizationsEditor: Ember.inject.service(),
    prevNextEditor: Ember.computed.alias('prevNextOrganizationsEditor'),

    openOrganizationForms: 'openOrganizationForms',

    title: Ember.computed('model.organizationTitles.@each.{preferred,text}', function () {
      var title = this.get('model.organizationTitles').filterBy('preferred').get('firstObject');

      return title ? title.get('text') : '';
    }),

    date: Ember.computed('model.organizationExistenceDates.@each.{preferred,text}', function () {
      var date = this.get('model.organizationExistenceDates').filterBy('preferred').get('firstObject');

      return date ? date.get('text') : '';
    }),

    role: Ember.computed('model.organizationRoles.@each.{preferred,text}', function () {
      var role = this.get('model.organizationRoles').filterBy('preferred').get('firstObject');

      return role ? role.get('role.name') : '';
    }),

    nationality: Ember.computed('model.organizationNationalities.@each.{preferred,text}', function () {
      var nationality = this.get('model.organizationNationalities').filterBy('preferred').get('firstObject');

      return nationality ? this.get('vocab.nationalitiesLabels')[nationality.get('nationalityVocabularyItemId')] : '';
    }),

    image: Ember.computed('model.organizationImages.@each.{preferred,image}', function () {
      var image = this.get('model.organizationImages').filterBy('preferred').get('firstObject');

      return image && image.get('image');
    }),

    salesFilter: Ember.computed('model.id', function () {
      return '(auctionHouseIds*' + this.get('model.id') + ')';
    }),

    objectFilter: Ember.computed('model.id', function () {
      return '(creators*' + this.get('model.id') + ',organization)';
    }),

    actions: {
      showImagesForms: function showImagesForms() {
        this.sendAction('openOrganizationForms', 'edit-forms-image-relations');
      }
    }
  });
});