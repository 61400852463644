define('cdata/models/source', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    _place: attr('string'),
    sourceTypeVocabularyItemId: attr('string'),
    url: attr('string'),
    title: attr('string'),
    headline: attr('string'),
    publicationYear: attr('string'),
    sourcePersonAuthors: hasMany('source-person-author'),
    sourcePersonEditors: hasMany('source-person-editor'),
    sourcePersonPublishers: hasMany('source-person-publisher'),
    sourceOrganizationPublishers: hasMany('source-organization-publisher'),
    placeId: attr('string'),
    place: belongsTo('place')
  });
});