define('cdata/pods/components/entities/view/entity-item/section-field/component', ['exports', 'cdata/mixins/editor/section-field'], function (exports, _sectionField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_sectionField.default, {
    classNames: ['section-field'],

    emptyPreferred: Ember.computed.equal('preferred', undefined),
    asPref: false,

    expand: false
  });
});