define('cdata/models/person-christies-specialist-department', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    person: belongsTo('person'),
    personId: attr('string'),
    christiesSpecialistDepartmentVocabularyItemId: attr('string'),
    preferred: attr('boolean')
  });
});