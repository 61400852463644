define("cdata/mirage/datasets/sessions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    '49299': {
      "session_titles": [],
      "session": {
        "start_date": "2002-09-25",
        "source_url": "Doyle",
        "session_title_ids": [],
        "session_status_vocabulary_item_id": 876,
        "sale_id": 49299,
        "published_total": null,
        "lots_count": 777,
        "location_id": 146,
        "last_lot_number": "766",
        "id": 49299,
        "first_lot_number": "1",
        "finish_date": "2002-09-25",
        "date_text": null,
        "channel_vocabulary_item_id": 2479,
        "calculated_total": null,
        "auction_house_department": null
      }
    },
    '767': {
      "session_titles": [],
      "session": {
        "start_date": "2012-10-25",
        "source_url": "christies_api",
        "session_title_ids": [],
        "session_status_vocabulary_item_id": 876,
        "sale_id": 767,
        "published_total": null,
        "lots_count": 276,
        "location_id": 301,
        "last_lot_number": "0",
        "id": 767,
        "first_lot_number": "0",
        "finish_date": "2012-10-25",
        "date_text": null,
        "channel_vocabulary_item_id": 2479,
        "calculated_total": null,
        "auction_house_department": null
      }
    }
  };
});