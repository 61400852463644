define("cdata/pods/comparables/item/mixins/default-item", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "entity_id": "16540904",
    "id": "587411959e404e25d00000ff",
    "person_id": 24268,
    "object": {
      "approved": true,
      "artist_birth_date": 1928,
      "artist_death_date": 1987,
      "artist_id": "53ce7d2c73980e0acb012270",
      "artist_name": "Andy Warhol",
      "artwork_art_form": null,
      "artwork_assets": [{
        "medium": "https://s3.amazonaws.com/cdata-images-production/collectrium/stokstad/models/assets/attachments/5874/1198/9e40/4e25/d000/01b5/medium/587411989e404e25d00001b5.jpg",
        "original": "https://s3.amazonaws.com/cdata-images-production/collectrium/stokstad/models/assets/attachments/5874/1198/9e40/4e25/d000/01b5/original/587411989e404e25d00001b5.jpg",
        "thumbnail": "https://s3.amazonaws.com/cdata-images-production/collectrium/stokstad/models/assets/attachments/5874/1198/9e40/4e25/d000/01b5/thumbnail/587411989e404e25d00001b5.jpg"
      }],
      "artwork_creation": "", //"20th Century",
      "artwork_descriptive_notes": "Andy Warhol (1928-1987) Mao signed twice and dated twice 'Andy Warhol 1973' (on the overlap) synthetic polymer and silkscreen ink on linen 26 x 22 in. (66 x 55.8 cm.) Painted in 1973.",
      "artwork_dimensions": {
        "height": {
          "unit": "in",
          "value": 22.0
        },
        "width": {
          "unit": "in",
          "value": 26.0
        }
      },
      "artwork_edition": null,
      "artwork_id": "587411959e404e25d00000ff",
      "artwork_inscriptions": "",
      "artwork_materials": "", //"synthetic polymer and silkscreen ink on linen",
      "artwork_measurements": "", //"26 x 22 in. (66 x 55.8 cm.)",
      "artwork_title": "Mao",
      "artwork_type": null,
      "auction_house_id": "54523561d976675d900000ab",
      "auction_house_name": "Christie's",
      "christies_object_id": "5684075",
      "estimate_high": 4000000.0,
      "estimate_high_usd": 4000000.0,
      "estimate_low": 3000000.0,
      "estimate_low_usd": 3000000.0,
      "id": "587411909e404e25d00000bc",
      "lot_number": "39",
      "original_currency": "USD",
      "price": 6283750.0,
      "price_usd": 6283750.0,
      "sale_date": "2013-05-15",
      "sale_department": null,
      "sale_id": "5874118e9e404e25d0000099",
      "sale_location": "Christie's - New York ,20 Rockefeller Plaza, New York, NY 10020, United States",
      "sale_name": "Post-War & Contemporary Evening Sale",
      "sale_number": "2785",
      "sale_premium": true,
      "source_url": "http://www.christies.com/lotfinder/lot_details.aspx?intObjectID=5684075",
      "status": "Sold"
    },
    "similarity_distance": 0.0
  };
});