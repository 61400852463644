define('cdata/pods/components/site/bring-to-top/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'i',
    classNames: ['bring-to-top ui icon angle top up'],
    classNameBindings: ['active'],

    active: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var target = Ember.$(window);
      var onScroll = function onScroll() {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        _this.set('active', target.scrollTop() > 230);
      };
      target.on('scroll.bring-to-top', onScroll);
      onScroll();
    },
    willDestroyElement: function willDestroyElement() {
      var target = Ember.$(window);
      target.off('scroll.bring-to-top');
    },
    click: function click() {
      Ember.$('body').stop().animate({ 'scrollTop': 0 });
    }
  });
});