define('cdata/pods/components/site/editor/comment-form/component', ['exports', 'cdata/mixins/editor/edit-forms-comment'], function (exports, _editFormsComment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsComment.default, {
    value: null,
    public: null,

    changePublic: 'changePublic',
    changeText: 'changeText',

    actions: {
      changePublic: function changePublic() {
        this.sendAction.apply(this, ['changePublic'].concat(Array.prototype.slice.call(arguments)));
      },
      changeText: function changeText() {
        this.sendAction.apply(this, ['changeText'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});