define('cdata/pods/reports/controller', ['exports', 'moment', 'cdata/mixins/components/cdata-category', 'cdata/utils/auction-houses-options', 'cdata/utils/computed', 'cdata/pods/reports/utils'], function (exports, _moment, _cdataCategory, _auctionHousesOptions, _computed, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasDetails = exports.enabledDetailsFields = undefined;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var enabledDetailsFields = exports.enabledDetailsFields = {
    entity_image: true,
    entity_provenance: true,
    entity_exhibition: true,
    entity_literature: true
  };

  var hasDetails = exports.hasDetails = function hasDetails(_ref) {
    var name = _ref.data.name;
    return Boolean(enabledDetailsFields[name]);
  };

  var SALES_TYPE = 'sales';
  var FIELDS_TYPE = 'fields';
  var FIELDS_BY_USER_TYPE = 'fields_by_user';

  exports.default = Ember.Controller.extend(_cdataCategory.default, {
    ajax: Ember.inject.service(),
    reports: Ember.inject.service(),

    auctionHouses: (0, _auctionHousesOptions.getAuctionHousesOptions)(),
    auctionHouseIds: Ember.computed.mapBy('auctionHouses', 'id'),

    auctionHousesOptions: (0, _auctionHousesOptions.getAuctionHousesOptions)(),
    auctionHousesVisible: (0, _computed.computedWithoutByIds)('auctionHousesOptions', 'auctionHouseIds'),

    organizations: Ember.computed.union('results.fields.organizations', 'results.fields_by_user.organizations'),

    sales: [],
    saleIds: Ember.computed.mapBy('sales', 'id'),

    saleDateFrom: '2016-01-01',
    saleDateTo: null,

    insertedAtFrom: '2019-05-01',
    insertedAtTo: null,

    results: {},

    loading: false,

    activeReport: Ember.computed('activeType', 'results.{sales,fields,fields_by_user}', function () {
      var _this = this;

      var results = this.get('results');
      var type = this.get('activeType');

      if (type === SALES_TYPE) {
        return {
          componentName: 'reports/components/sales-report',
          data: results.sales,
          clearReports: function clearReports() {
            return _this.send('clearSalesReports');
          }
        };
      }

      if (type === FIELDS_TYPE) {
        return {
          componentName: 'reports/components/fields-report',
          data: results.fields,
          clearReports: function clearReports() {
            return _this.send('clearFieldsReports');
          }
        };
      }

      if (type === FIELDS_BY_USER_TYPE) {
        return {
          componentName: 'reports/components/fields-by-user-report',
          data: results.fields_by_user,
          clearReports: function clearReports() {
            return _this.send('clearFieldsActorReports');
          }
        };
      }

      return null;
    }),

    actions: {
      clearSalesReports: function clearSalesReports() {
        this.set('results.sales', null);
        this.set('activeType', null);
      },
      clearFieldsReports: function clearFieldsReports() {
        this.set('results.fields', null);
        this.set('activeType', null);
      },
      clearFieldsActorReports: function clearFieldsActorReports() {
        this.set('results.fields_by_user', null);
        this.set('activeType', null);
      },
      clearFieldsSalesReports: function clearFieldsSalesReports() {
        this.set('results.fields_by_sales', null);
      },
      sales: function sales() {
        var _this2 = this;

        var req_params = this.clear_params({
          auction_house_ids: this.get('auctionHouseIds'),
          sale_date_from: this.get('saleDateFrom'),
          sale_date_to: this.get('saleDateTo'),
          inserted_at_from: this.get('insertedAtFrom'),
          inserted_at_to: this.get('insertedAtTo')
        });

        this.set('loading', true);

        this.get('reports').getSalesReport(req_params).then(function (results) {
          _this2.set('results.sales', results);
          _this2.set('activeType', SALES_TYPE);

          if (Ember.isEmpty(results.source)) {
            _this2.get('flashMessage').showError('no data');
          }
        }).finally(function () {
          return _this2.set('loading', false);
        });
      },
      fields: function fields() {
        var _this3 = this;

        var req_params = this.clear_params({
          sale_ids: this.get('saleIds'),
          organization_ids: this.get('auctionHouseIds'),
          sale_date_from: this.get('saleDateFrom'),
          sale_date_to: this.get('saleDateTo'),
          inserted_at_from: this.get('insertedAtFrom'),
          inserted_at_to: this.get('insertedAtTo')
        });

        this.set('loading', true);

        this.get('reports').getFieldsReport(req_params).then(function (results) {
          _this3.set('results.fields', results);
          _this3.set('activeType', FIELDS_TYPE);

          if (Ember.isEmpty(results.report)) {
            _this3.get('flashMessage').showError('no data');
          }
        }).finally(function () {
          return _this3.set('loading', false);
        });
      },
      fields_by_user: function fields_by_user() {
        var _this4 = this;

        var req_params = this.clear_params({
          sale_ids: this.get('saleIds'),
          organization_ids: this.get('auctionHouseIds'),
          sale_date_from: this.get('saleDateFrom'),
          sale_date_to: this.get('saleDateTo'),
          inserted_at_from: this.get('insertedAtFrom'),
          inserted_at_to: this.get('insertedAtTo')
        });

        this.set('loading', true);

        this.get('reports').getFieldsByUserReport(req_params).then(function (results) {
          _this4.set('results.fields_by_user', results);
          _this4.set('activeType', FIELDS_BY_USER_TYPE);

          if (Ember.isEmpty(results.report)) {
            _this4.get('flashMessage').showError('no data');
          }
        }).finally(function () {
          return _this4.set('loading', false);
        });
      },
      details: function details(organization_id, field, actor) {
        var _this5 = this;

        this.set('detailOrganizationId', organization_id);
        this.set('detailField', field);
        this.set('detailFieldType', field.startsWith('entity') ? 'entity' : 'lot');
        this.set('detailActor', actor);

        var req_params = this.clear_params({
          organization_ids: [organization_id],
          sale_date_from: this.get('saleDateFrom'),
          sale_date_to: this.get('saleDateTo'),
          inserted_at_from: this.get('insertedAtFrom'),
          inserted_at_to: this.get('insertedAtTo'),
          field: field
        });

        if (actor) {
          req_params.actor = actor;
        }

        this.set('loading', true);

        this.get('reports').getDetails(req_params).then(function (result) {
          _this5.set('results.fields_by_sales', result);

          if (Ember.isEmpty(result)) {
            _this5.get('flashMessage').showError('no data');
          }
        }).finally(function () {
          return _this5.set('loading', false);
        });
      },
      addAuctionHouse: function addAuctionHouse(_ref2) {
        var id = _ref2.id,
            label = _ref2.label;

        var auctionHouses = this.get('auctionHouses');
        var isExist = Boolean(auctionHouses.findBy('id', id));

        if (!isExist) {
          var item = Ember.Object.create({ id: id, label: label });

          auctionHouses.pushObject(item);
        }
      },
      removeAuctionHouse: function removeAuctionHouse(item) {
        this.get('auctionHouses').removeObject(item);
      },
      addSale: function addSale(item) {
        item = Ember.Object.create({
          id: item.id,
          title: item.description + ' ' + item.name
        });

        this.get('sales').pushObject(item);
      },
      removeSale: function removeSale(item) {
        this.get('sales').removeObject(item);
      },
      setDateFrom: function setDateFrom(date) {
        this.set('saleDateFrom', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
      },
      setDateTo: function setDateTo(date) {
        this.set('saleDateTo', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
      },
      setInsertedAtFrom: function setInsertedAtFrom(date) {
        this.set('insertedAtFrom', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
      },
      setInsertedAtTo: function setInsertedAtTo(date) {
        this.set('insertedAtTo', date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null);
      },
      getDetailsTitle: function getDetailsTitle() {
        var organizations = this.get('organizations');
        var id = this.get('detailOrganizationId');
        var detailField = this.get('detailField');
        var detailActor = this.get('detailActor');

        return [(0, _utils.getTitleById)(organizations, id), detailField, detailActor].filter(Boolean).join(' | ');
      }
    },

    clear_params: function clear_params(params) {
      var req_params = {};
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(params)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref3 = _step.value;

          var _ref4 = _slicedToArray(_ref3, 2);

          var key = _ref4[0];
          var value = _ref4[1];

          if (!Ember.isEmpty(value)) {
            req_params[key] = value;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return req_params;
    }
  });
});