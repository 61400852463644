define('cdata/pods/components/comparables/search-header/pagination/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['comparables-pagination'],

    gotoPage: 'gotoPage',

    page: Ember.computed.alias('meta.page'),
    lastPage: Ember.computed.alias('meta.page_count'),

    nextNumber: Ember.computed('nextLink', function () {
      if (!this.get('nextLink') || this.get('nextLink') === 'null') {
        return null;
      }

      return this.get('page') + 1;
    }),

    prevNumber: Ember.computed('prevLink', function () {
      if (!this.get('prevLink') || this.get('prevLink') === 'null') {
        return null;
      }

      return this.get('page') - 1;
    }),

    firstNumber: Ember.computed('page', function () {
      if (this.get('page') === 1 || this.get('page') === this.get('prevNumber')) {
        return null;
      }

      return 1;
    }),

    lastNumber: Ember.computed('page', function () {
      if (this.get('page') === this.get('lastPage')) {
        return null;
      }

      return this.get('lastPage');
    }),

    nextLink: Ember.computed.alias('meta.links.next'),
    prevLink: Ember.computed.alias('meta.links.prev'),
    firstLink: Ember.computed.alias('meta.links.first'),
    lastLink: Ember.computed.alias('meta.links.last'),

    actions: {
      gotoPage: function gotoPage() {
        this.sendAction.apply(this, ['gotoPage'].concat(Array.prototype.slice.call(arguments)));
      }
    },

    links: Ember.computed('page', 'lastPage', 'nextNumber', 'pevNumber', 'firstNumber', 'lastNumber', function () {
      var links = [];
      var page = parseInt(this.get('page'));
      var last = this.get('lastPage');

      links.push({
        number: 1,
        active: page === 1,
        link: this._makeLink(1)
      });

      var next = 2;

      while (next < page) {
        if (next < page - 2) {
          next++;
          continue;
        }
        links.push({
          number: next,
          active: page === next,
          link: this._makeLink(next)
        });
        next++;
      }

      if (page !== 1 && page !== last) {
        links.push({
          number: page,
          active: true,
          link: this._makeLink(page)
        });
      }

      next = page + 1;

      while (next < page + 3 && next < last) {
        links.push({
          number: next,
          active: page === next,
          link: this._makeLink(next)
        });
        next++;
      }

      links.push({
        number: last,
        active: last === page,
        link: this._makeLink(last),
        last: links[links.length - 1].number < last - 1
      });

      links[0].first = links[1].number > 2;

      return links;
    }),

    _makeLink: function _makeLink(number) {
      var link = this.get('firstLink') || this.get('lastLink');

      return link.replace(/page=[0-9]/, 'page=' + number);
    }
  });
});