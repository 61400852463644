define('cdata/pods/entities/merge-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    mergeList: Ember.inject.service(),

    entityMerging: function entityMerging(id, ids) {
      this.get('mergeList').save(this.get('store'), 'entity', id, ids);
    }
  });
});