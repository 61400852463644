define('cdata/models/lot-price', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    lotId: attr('string'),
    currencyVocabularyItemId: attr('string'),
    estimateLow: attr('string'),
    estimateHigh: attr('string'),
    priceBuyNow: attr('string'),
    priceOpening: attr('string'),
    pricePremium: attr('string'),
    priceHammer: attr('string'),
    boughtInAt: attr('string'),
    priceSale: attr('string')
  });
});