define("cdata/mirage/datasets/lots", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    '33237174': {
      "lot_titles": [],
      "lot_prices": [{
        "price_sale": "613.88",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 33237174,
        "id": 191360954,
        "estimate_low": "316.43",
        "estimate_high": "474.65",
        "currency_vocabulary_item_id": 664
      }, {
        "price_sale": "3026.34",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 33237174,
        "id": 191360937,
        "estimate_low": "1559.97",
        "estimate_high": "2339.96",
        "currency_vocabulary_item_id": 670
      }, {
        "price_sale": "581.08",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 33237174,
        "id": 191360921,
        "estimate_low": "299.53",
        "estimate_high": "449.29",
        "currency_vocabulary_item_id": 662
      }, {
        "price_sale": "248.81",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 33237174,
        "id": 191360903,
        "estimate_low": "128.25",
        "estimate_high": "192.38",
        "currency_vocabulary_item_id": 710
      }, {
        "price_sale": "396.87",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 33237174,
        "id": 191360879,
        "estimate_low": "204.57",
        "estimate_high": "306.86",
        "currency_vocabulary_item_id": 747
      }, {
        "price_sale": "388.00",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 33237174,
        "id": 191360865,
        "estimate_low": "200.00",
        "estimate_high": "300.00",
        "currency_vocabulary_item_id": 597
      }],
      "lot_descriptions": [],
      "lot": {
        "source": null,
        "shown": true,
        "session_id": 49299,
        "previous_lot_id": 33237018,
        "number": "533",
        "next_lot_id": 33237941,
        "lot_title_ids": [],
        "lot_status_vocabulary_item_id": 863,
        "lot_price_ids": [191360954, 191360937, 191360921, 191360903, 191360879, 191360865],
        "lot_description_ids": [],
        "id": 33237174,
        "estimate_type_vocabulary_item_id": null,
        "entity_lot_ids": [33464437, 33464436, 33464432, 32780063]
      },
      "entity_lots": [{
        "lot_id": 33237174,
        "id": 33464437,
        "entity_id": 33581049
      }, {
        "lot_id": 33237174,
        "id": 33464436,
        "entity_id": 33581048
      }, {
        "lot_id": 33237174,
        "id": 33464432,
        "entity_id": 33581044
      }, {
        "lot_id": 33237174,
        "id": 32780063,
        "entity_id": 32894740
      }]
    },
    '17261095': {
      "lot_titles": [],
      "lot_prices": [{
        "price_sale": "271149.50",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 17261095,
        "id": 99519196,
        "estimate_low": "160206.50",
        "estimate_high": "240309.75",
        "currency_vocabulary_item_id": 664
      }, {
        "price_sale": "2115601.64",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 17261095,
        "id": 99519194,
        "estimate_low": "1249986.20",
        "estimate_high": "1874979.30",
        "currency_vocabulary_item_id": 670
      }, {
        "price_sale": "254716.51",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 17261095,
        "id": 99519192,
        "estimate_low": "150497.20",
        "estimate_high": "225745.80",
        "currency_vocabulary_item_id": 662
      }, {
        "price_sale": "169250.00",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 17261095,
        "id": 99519190,
        "estimate_low": "100000.00",
        "estimate_high": "150000.00",
        "currency_vocabulary_item_id": 710
      }, {
        "price_sale": "210753.82",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 17261095,
        "id": 99519189,
        "estimate_low": "124522.20",
        "estimate_high": "186783.30",
        "currency_vocabulary_item_id": 747
      }, {
        "price_sale": "272970.29",
        "price_opening": null,
        "price_buy_now": null,
        "lot_id": 17261095,
        "id": 99519186,
        "estimate_low": "161282.30",
        "estimate_high": "241923.45",
        "currency_vocabulary_item_id": 597
      }],
      "lot_descriptions": [],
      "lot": {
        "source": null,
        "shown": true,
        "session_id": 767,
        "previous_lot_id": 17260538,
        "number": "215",
        "next_lot_id": 17260539,
        "lot_title_ids": [],
        "lot_status_vocabulary_item_id": 863,
        "lot_price_ids": [99519196, 99519194, 99519192, 99519190, 99519189, 99519186],
        "lot_description_ids": [],
        "id": 17261095,
        "estimate_type_vocabulary_item_id": null,
        "entity_lot_ids": [16990566]
      },
      "entity_lots": [{ "lot_id": 17261095, "id": 16990566, "entity_id": 17048279 }]
    }
  };
});