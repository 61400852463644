define('cdata/pods/components/marks/edit/edit-forms-materials/component', ['exports', 'cdata/pods/components/marks/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Material'), {
    autoCreating: false,

    materialIds: Ember.computed.mapBy('materials', 'id'),

    materials: Ember.computed.filterBy('model.markMaterials', 'isDeleted', false)
  });
});