define('cdata/models/entity', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    sidConstants: Ember.inject.service(),
    vocab: Ember.inject.service(),

    _title: attr('string'),
    entityCreators: hasMany('entity-creator'),
    entityCreationDates: hasMany('entity-creation-date'),
    entityCreationPlaces: hasMany('entity-creation-place'),
    entityMaterials: hasMany('entity-material'),
    entityMeasurements: hasMany('entity-measurement'),
    entityInscriptions: hasMany('entity-inscription'),
    entityDescriptions: hasMany('entity-description'),
    entityEditions: hasMany('entity-edition'),
    entityImages: hasMany('entity-image'),
    entityExhibitions: hasMany('entity-exhibition'),
    entityExaminations: hasMany('entity-examinations'),
    entityProvenances: hasMany('entity-provenance'),
    entityPreLots: hasMany('entity-pre-lot'),
    entityTreatmentNotes: hasMany('entity-treatment-note'),
    entityTitles: hasMany('entity-title'),
    entityColors: hasMany('entity-color'),
    entityShapes: hasMany('entity-shape'),
    entityStyles: hasMany('entity-style'),
    entityMarks: hasMany('entity-mark'),
    entityJewelryGems: hasMany('entity-jewelry-gem', {
      inverse: 'entityJewelry'
    }),
    entityGemJewelries: hasMany('entity-gem-jewelry', { inverse: 'entityGem' }),
    entitySubjectMatters: hasMany('entity-subject-matter'),
    entityLiterature: hasMany('entity-literature'),
    entityCatalogueNotes: hasMany('entity-catalogue-note'),
    entityConditionalReports: hasMany('entity-conditional-report'),
    entityAwards: hasMany('entity-award'),
    entityAwardNotes: hasMany('entity-award-note'),
    entityTypeVocabularyItemId: attr('string'),
    miCategoryVocabularyItemId: attr('string'),
    cdataCategoryVocabularyItemId: attr('string'),
    christiesBusinessGroupVocabularyItemId: attr('string'),
    entityReferenceNumbers: hasMany('entity-reference-number'),
    entityReferenceNumberNotes: hasMany('entity-reference-number-note'),
    entityLots: hasMany('entity-lot'),
    shown: attr('boolean'),
    isCategoryUpdatedInOf: attr('boolean'),
    uuid: attr('string'),
    extension: attr('string'),
    jewelryTypeVocabularyItemId: attr('string'),
    jewelryGemSettingVocabularyItemId: attr('string'),
    jewelryGenderVocabularyItemId: attr('string'),
    gemClarityVocabularyItemId: attr('string'),
    gemTreatmentTypeVocabularyItemId: attr('string'),
    gemTreatmentDegreeVocabularyItemId: attr('string'),
    gemCutVocabularyItemId: attr('string'),
    gemCutGradeVocabularyItemId: attr('string'),
    gemColourless: attr('boolean'),
    gemColorIntensityVocabularyItemId: attr('string'),

    image: Ember.computed('entityImages.@each.{preferred,imageId}', function () {
      var entityImage = this.get('entityImages').findBy('preferred');

      return entityImage && entityImage.get('image');
    }),

    imageUrl: Ember.computed.alias('image.url'),

    title: Ember.computed('entityTitles.@each.{preferred,text}', function () {
      var title = this.get('entityTitles').findBy('preferred');

      return title && title.get('text');
    }),

    entityLot: Ember.computed('entityLots.@each.lotId', function () {
      return this.get('entityLots.firstObject');
    }),

    lot: Ember.computed.alias('entityLot.lot'),

    session: Ember.computed.alias('lot.session'),

    sale: Ember.computed.alias('session.sale'),

    lotPrices: Ember.computed.alias('lot.lotPrices'),

    price: Ember.computed('lot.lotPrices.@each.{currencyVocabularyItemId,estimateLow,estimateHigh,priceSale}', function () {
      return this.get('lot.lotPrices').findBy('currencyVocabularyItemId', this.get('sale.currencyVocabularyItemId'));
    }),

    priceUsd: Ember.computed('lot.lotPrices.@each.{currencyVocabularyItemId,estimateLow,estimateHigh,priceSale}', function () {
      var usdId = this.get('sidConstants.usd');

      return this.get('lot.lotPrices').findBy('currencyVocabularyItemId', usdId);
    }),

    priceSale: Ember.computed.alias('price.priceSale'),

    estimateLow: Ember.computed.alias('price.estimateLow'),
    estimateHigh: Ember.computed.alias('price.estimateHigh'),

    estimateLowUsd: Ember.computed.alias('priceUsd.estimateLow'),
    estimateHighUsd: Ember.computed.alias('priceUsd.estimateHigh'),

    creationDate: Ember.computed('entityCreationDates.@each.{preferred,text}', function () {
      var date = this.get('entityCreationDates').findBy('preferred');

      return date && date.get('text');
    }),

    preferredMediumModel: Ember.computed('entityMaterials.@each.{preferred,text,isDeleted}', function () {
      return this.get('entityMaterials').rejectBy('isDeleted').filterBy('preferred').get('firstObject');
    }),

    preferredMediumText: Ember.computed.alias('preferredMediumModel.text'),

    preferredMediumTerms: Ember.computed('preferredMediumText', 'preferredMediumModel.entityMaterialTerms.{materialVocabularyItemId,isDeleted,citationLabelVocabularyItemId,userId}', function () {
      return this.get('preferredMediumModel') ? this.get('preferredMediumModel.entityMaterialTerms').rejectBy('isDeleted') : [];
    }),

    preferredMediumTerm: Ember.computed('preferredMediumTerms.{materialVocabularyItemId,isDeleted,citationLabelVocabularyItemId,userId}', function () {
      return this.get('preferredMediumTerms.firstObject');
    }),

    isDiamond: Ember.computed('vocab.diamondIds', 'preferredMediumTerm.materialVocabularyItemId', 'isGem', function () {
      return this.get('isGem') && this.get('vocab.diamondIds').indexOf(this.get('preferredMediumTerm.materialVocabularyItemId') + '') !== -1;
    }),

    isGem: Ember.computed('extension', function () {
      return this.get('extension') === 'gem';
    }),

    isJewelry: Ember.computed('extension', function () {
      return this.get('extension') === 'jewelry';
    }),

    isColoredDiamond: Ember.computed('isDiamond', 'gemColourless', function () {
      return this.get('isDiamond') && !this.get('gemColourless');
    }),

    colorModel: Ember.computed('entityColors.@each.{colorVocabularyItemId,isDeleted}', 'gemColourless', function () {
      return this.get('entityColors').rejectBy('isDeleted').get('firstObject');
    }),

    shapeModel: Ember.computed('entityShapes.@each.{shapeVocabularyItemId,isDeleted}', function () {
      return this.get('entityShapes').rejectBy('isDeleted').get('firstObject');
    }),

    enableTreatmentType: Ember.computed('preferredMediumTerm.materialVocabularyItemId', 'isGem', function () {
      return this.get('isGem') && this.get('vocab.treatmentMaterialIds').indexOf(this.get('preferredMediumTerm.materialVocabularyItemId') + '') !== -1;
    }),

    isEmerald: Ember.computed('isGem', 'preferredMediumTerm.materialVocabularyItemId', function () {
      return this.get('isGem') && this.get('preferredMediumTerm.materialVocabularyItemId') + '' === this.get('sidConstants.materialEmeraldId');
    }),

    checkGemProperties: function checkGemProperties() {
      if (!this.get('isDiamond')) {
        this.set('gemColourless', false);
        this.set('gemClarityVocabularyItemId', null);
        this.set('gemColorIntensityVocabularyItemId', null);
      }

      if (this.get('gemColourless')) {
        this.set('gemColorIntensityVocabularyItemId', null);
      }

      if (!this.get('enableTreatmentType')) {
        this.set('gemTreatmentTypeVocabularyItemId', null);
      }

      if (!this.get('gemTreatmentTypeVocabularyItemId')) {
        this.set('gemTreatmentDegreeVocabularyItemId', null);
      }
    }
  });
});