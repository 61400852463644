define('cdata/pods/people/index/route', ['exports', 'cdata/mixins/lists/route-list', 'cdata/constants', 'cdata/pods/people/merge-mixin'], function (exports, _routeList, _constants, _mergeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeList.default, _mergeMixin.default, {
    prevNextPeopleEditor: Ember.inject.service(),
    prevNextEditor: Ember.computed.alias('prevNextPeopleEditor'),

    defaultSort: {
      key: 'name',
      asc: true
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('prevNextEditor', this.get('prevNextPeopleEditor'));
    },
    model: function model(params) {
      return this.infinity(params, 'person-list-item');
    },


    assignFormPath: 'people.modal.assign',
    assignModalId: _constants.MODALS.PERSON_ASSIGN,

    actions: {
      edit: function edit(item) {
        this.transitionTo('people.view', item.get('personId'));
      },


      /**
       * Assign property to people
       * @param {Array} items people list
       * @param {String} assignOption name of assign option (role, nationality, etc)
       * @param {String|Boolean} assignValue id of assign subject
       * @param {String} rule rule for replace/alternate/preferred
       * @param {Boolean} isFilter send filter parameters instead checked items
       */
      saveAssignPersonForm: function saveAssignPersonForm(items, assignOption, assignValue, rule, isFilter) {
        var props = this._makeAssignProperties(assignOption, assignValue, isFilter, rule);
        var personIds = items.map(function (item) {
          return parseInt(item.get('personId'));
        });

        this._saveAssignUpdate('people', props, personIds, _constants.MODALS.PERSON_ASSIGN + '.saving');
      },
      assignRemovePeople: function assignRemovePeople(items) {
        this.send('saveAssignPersonForm', items, 'delete', null, null, false);
      }
    }
  });
});