define('cdata/pods/components/controls/input-textarea/component', ['exports', 'cdata/pods/components/controls/input-mixin'], function (exports, _inputMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputMixin.default, {
    tagName: 'textarea',
    classNames: [],
    required: false,
    attributeBindings: ['rows', 'placeholder', 'required', 'disabled']
  });
});