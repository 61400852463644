define('cdata/services/flash-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend({
    isShown: false,
    hideDelay: 4000,

    _hideDelay: null,

    show: function show(message, type, hideDelay) {
      var _this = this;

      var delay = hideDelay || this.get('hideDelay');

      this.setProperties({
        isShown: true,
        message: message,
        type: type
      });

      if (this._hideDelay) {
        Ember.run.cancel(this._hideDelay);
      }

      this._hideDelay = Ember.run.later(this, function () {
        _this._hideDelay = null;
        _this.hide();
      }, delay);
    },

    showSuccess: function showSuccess(message, delay) {
      this.show(message, 'success', delay);
    },

    showNotice: function showNotice(message, delay) {
      this.show(message, 'notice', delay);
    },

    showError: function showError(message, delay) {
      var text = message;

      if ((typeof message === 'undefined' ? 'undefined' : _typeof(message)) === 'object' && !Ember.isEmpty(message.error)) {
        text = message.error;
      }

      if ((typeof message === 'undefined' ? 'undefined' : _typeof(message)) === 'object') {
        if (message.status && message.status === 404) {
          text = 'Requested API service: Not Realized' + "\n" + '[404 ' + message.responseText + ']';
        } else if (message.status && message.status === 500) {
          text = 'Unknown API error' + "\n" + '[500 ' + message.responseText + ']';
        } else if (Ember.isArray(message.errors) && message.errors[0]) {
          message = message.errors[0];
          text = message.detail;

          if (message.status === '404') {
            text = 'Requested API service: Not Realized' + "\n" + '[404 ' + message.detail + ']';
          }

          if (message.status === '500') {
            text = 'Unknown API error' + "\n" + '[500 ' + message.detail + ']';
          }
        } else if (message && message.responseJSON && message.responseJSON.message) {
          text = message.responseJSON.message;
        }
      }

      this.show(text, 'error', delay);
    },

    hide: function hide() {
      this.set('isShown', false);
    }
  });
});