define('cdata/helpers/creation-dates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.creationDates = creationDates;
  function creationDates(params) {
    var e = check(params && params[0]); // earliest
    var l = check(params && params[1]); // latest

    var ed = dash(e);
    var ld = dash(l);

    var ei = e !== null ? parseInt(e) : null;
    var li = l !== null ? parseInt(l) : null;

    var century = Math.ceil(ei / 100) + (ei % 100 === 0 ? 1 : 0);

    if (ed) {
      e = Math.abs(ei) + ' BC';
    }

    if (ld) {
      l = Math.abs(li) + ' BC';
    }

    if (e === l) {
      return l;
    }

    if (l === null) {
      return 'after ' + e;
    }

    if (e === null) {
      return 'before ' + l;
    }

    if (!ed && !ld) {
      // Earliest: 1980; Latest: 1989 => 1980s
      if (ei % 10 === 0 && li - ei === 9) {
        return ei + 's';
      }

      // Earliest: 1980; Latest: 1983 => early 1980s
      if (ei % 10 === 0 && li - ei === 3) {
        return 'early ' + ei + 's';
      }

      // Earliest: 1984; Latest: 1986 => mid-1980s
      if (ei % 10 === 4 && li - ei === 2) {
        return 'mid-' + (ei - 4) + 's';
      }

      // Earliest: 1984; Latest: 1986 => late 1980s
      if (ei % 10 === 6 && li - ei === 3) {
        return 'late ' + (ei - 6) + 's';
      }

      // Earliest: 1700; Latest: 1799 => 18th century
      if (ei % 100 === 0 && li - ei === 99) {
        return century + 'th century';
      }

      // Earliest: 1700; Latest: 1729 => 18th century
      if (ei % 100 === 0 && li - ei === 29) {
        return 'early ' + century + 'th century';
      }

      // Earliest: 1730; Latest: 1769 => mid-18th century
      if (ei % 100 === 30 && li - ei === 39) {
        return 'mid-' + century + 'th century';
      }

      // Earliest: 1770; Latest: 1799 => late 18th century
      if (ei % 100 === 70 && li - ei === 29) {
        return 'late ' + century + 'th century';
      }

      // Earliest: 1800-5; Latest: 1800+5 => circa 1800
      if (ei % 100 === 95 && li - ei === 10) {
        return 'circa ' + (ei + 5);
      }

      // Earliest: 1700; Latest: 1750 => first half of 18th century
      if (ei % 100 === 0 && li - ei === 50) {
        return 'first half of ' + century + 'th century';
      }

      // Earliest: 1751; Latest: 1799 => second half of 18th century
      if (ei % 100 === 51 && li - ei === 48) {
        return 'second half of ' + century + 'th century';
      }
    } else {
      // Earliest: -1450-50; Latest: -1450+50 => circa XXXX BC
      if (ei % 100 === -50 && li - ei === 100) {
        return 'circa ' + (Math.abs(ei) - 50) + ' BC';
      }
    }

    if (e && l) {
      return e + ' ‒ ' + l;
    }
  }

  exports.default = Ember.Helper.helper(creationDates);


  function check(value) {
    value = value === undefined || value === null || value === '-' || value.toString().length === 0 ? null : parseInt(value);

    return value !== null ? '' + value : null;
  }

  function dash(value) {
    return value !== null ? /^-.*$/.test(value) : false;
  }
});