define('cdata/mixins/create-modals/export-lots', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      openExportLotsForm: function openExportLotsForm() {
        var parameters = this.makeFilterParameters();

        var model = Ember.Object.create({
          parameters: parameters,
          countOfChecked: 0,
          meta: this.get('currentModel.meta')
        });

        this.send('initCheckedItemsIds', model);

        this.get('modal').register(this, _constants.MODALS.EXPORT_LOTS, 'lots.modal.new-export').open(model);
      },
      closeExportLotsForm: function closeExportLotsForm() {
        this.get('modal').close(_constants.MODALS.EXPORT_LOTS);
      },
      saveExportLotsForm: function saveExportLotsForm(model) {
        var _this = this;

        var filter = model.get('parameters') || {};
        if (model.get('checkedItemsIds') && model.get('checkedItemsIds.length') > 0) {
          filter = { lot_ids: model.get('checkedItemsIds') };
        }

        var export_data = {
          name: model.get('name') || 'New export of lots',
          filter: filter
        };

        var export_model = this.get('store').createRecord('export', export_data);
        model.set('inProcess', true);
        this._handleSaving(export_model.save(), false).then(function () {
          _this.get('flashMessage').showSuccess('Export created');
          _this.send('closeExportLotsForm');
        }).finally(function () {
          model.set('inProcess', false);
        });
      }
    }
  });
});