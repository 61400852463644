define("cdata/mirage/datasets/organization_list_items", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "organization_list_items": [{
      "updated_at": "2017-05-05T08:04:51.896321Z",
      "title": "Bottega Veneta",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 24,
      "nationality_id": 1032,
      "nationality": "Italian",
      "id": 24,
      "existence_dates": "1966 ",
      "existence_date_start": 1966,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:04:52.817845Z",
      "title": "Stephan Hertzog",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 25,
      "nationality_id": null,
      "nationality": null,
      "id": 25,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:04:52.919561Z",
      "title": "Buzi-Cerani",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 26,
      "nationality_id": null,
      "nationality": null,
      "id": 26,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:04:53.384841Z",
      "title": "Cal Vista",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 29,
      "nationality_id": null,
      "nationality": null,
      "id": 29,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:04:54.141189Z",
      "title": "Craig Johnston",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 40,
      "nationality_id": null,
      "nationality": null,
      "id": 40,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:04:54.196144Z",
      "title": "Dietrich Lubs",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 41,
      "nationality_id": null,
      "nationality": null,
      "id": 41,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:04:54.347145Z",
      "title": "Enrico Baleri",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 44,
      "nationality_id": null,
      "nationality": null,
      "id": 44,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:04:54.875104Z",
      "title": "Dorset Rex",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 48,
      "nationality_id": null,
      "nationality": null,
      "id": 48,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:04:55.253094Z",
      "title": "Edgar Kaufmann Jr.",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 52,
      "nationality_id": null,
      "nationality": null,
      "id": 52,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:05:02.250248Z",
      "title": "Marc D'Haenens",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 116,
      "nationality_id": null,
      "nationality": null,
      "id": 116,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:05:02.443010Z",
      "title": "Marie Zimmermann",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 119,
      "nationality_id": null,
      "nationality": null,
      "id": 119,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:05:02.534436Z",
      "title": "Masayaki Kurosawa",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 120,
      "nationality_id": null,
      "nationality": null,
      "id": 120,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:05:02.569880Z",
      "title": "Matthew Williamson",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 123,
      "nationality_id": null,
      "nationality": null,
      "id": 123,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:05:02.998045Z",
      "title": "Max Pearson",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 126,
      "nationality_id": null,
      "nationality": null,
      "id": 126,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:05:03.846363Z",
      "title": "Meriden Silver Plate Company",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 129,
      "nationality_id": null,
      "nationality": null,
      "id": 129,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:05:03.886435Z",
      "title": "Mevis & Van Deursen",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 130,
      "nationality_id": null,
      "nationality": null,
      "id": 130,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:05:03.944743Z",
      "title": "Michel Dumas",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 132,
      "nationality_id": null,
      "nationality": null,
      "id": 132,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:04:56.041924Z",
      "title": "Foreign Office Architects",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 60,
      "nationality_id": 901,
      "nationality": "British",
      "id": 60,
      "existence_dates": "1850 ",
      "existence_date_start": 1850,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:04:57.823765Z",
      "title": "Henri Grailhe",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 73,
      "nationality_id": null,
      "nationality": null,
      "id": 73,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }, {
      "updated_at": "2017-05-05T08:04:58.403282Z",
      "title": "Isambard Kingdom Brunel",
      "sales_count": 0,
      "role_id": 1598,
      "role": "designer",
      "organization_id": 79,
      "nationality_id": null,
      "nationality": null,
      "id": 79,
      "existence_dates": " ",
      "existence_date_start": null,
      "existence_date_finish": null,
      "entities_count": 0,
      "disambiguating_comment": null
    }], "meta": { "total_pages": 2718, "page_size": 20, "page_number": 1, "count_total": 54342, "count_filtered": 54342 }
  };
});