define('cdata/mixins/editor/forms/source-online-offline', ['exports', 'cdata/mixins/components/manage-source-actions', 'cdata/helpers/format-source'], function (exports, _manageSourceActions, _formatSource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_manageSourceActions.default, {
    publicationType: 'offline',
    publicationTypes: [{
      id: 'offline',
      label: 'Offline'
    }, {
      id: 'online',
      label: 'Online'
    }],
    isOffline: Ember.computed.equal('publicationType', 'offline'),
    isOnline: Ember.computed.not('isOffline'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('publicationType', this.get('model.source.url') ? 'online' : 'offline');
    },


    sourceTitle: Ember.computed('model.{sourceId,sourcePage}', 'model.source.{url,title}', function () {
      return _formatSource.default.compute([this.get('model.source'), this.get('sourcePage'), false]);
    }),

    actions: {
      setSource: function setSource(_, sourceSubject) {
        this.sendAction('selectSourceFor', this.get('model'), sourceSubject);
      },
      createSource: function createSource(query) {
        this.sendAction('openSourceForm', this.get('model'), query || '');
      },
      createSourceOnline: function createSourceOnline(url) {
        this.sendAction('openSourceOnlineForm', this.get('model'), url);
      }
    }
  });
});