define('cdata/pods/components/entities/modals/create-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.ENTITY,
    classNames: ['context-modal', _constants.MODALS.ENTITY, 'edit-form'],
    inProcess: Ember.computed.alias('model.inProcess'),
    isDisabled: false,

    actions: {
      save: function save() {
        var model = this.get('model');
        this.sendAction('saveNewEntityForm', model);
      },
      follow: function follow() {
        var model = this.get('model');
        this.sendAction('saveNewEntityForm', model, true);
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeNewEntityForm');
      }
    }
  });
});