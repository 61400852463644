define('cdata/pods/components/controls/input-filter-search/component', ['exports', 'cdata/pods/components/controls/input-mixin'], function (exports, _inputMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputMixin.default, {
    tagName: 'input',
    type: 'text',
    classNames: [],
    attributeBindings: ['type', 'placeholder'],
    setFilterSearch: 'setFilterSearch',

    init: function init() {
      this._super.apply(this, arguments);
      this.handleChanged = function (value) {
        this.changeSearchText.call(this, value);
      }.bind(this);
    },
    changeSearchText: function changeSearchText(text) {
      this.set('value', text);
      Ember.run.debounce(this, this.sendActionFilter, 500);
    },
    sendActionFilter: function sendActionFilter() {
      this.sendAction('setFilterSearch', this.get('value'));
    }
  });
});