define('cdata/pods/components/controls/select-tree/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['controls-select-tree'],

    labelPath: 'label',

    items: [],

    query: null,

    itemId: null,

    title: "Selected item",

    selectedItem: Ember.computed('itemId', 'items.length', function () {
      return this.get('items').findBy('id', this.get('itemId')) || this.get('items').findBy('id', parseInt(this.get('itemId')));
    }),

    selectedItemLabel: Ember.computed('selectedItem.id', function () {
      return this.get('selectedItem').get(this.get('labelPath'));
    }),

    filteredItems: Ember.computed('query', 'items.length', function () {
      var _this = this;

      var query = this.get('query');

      if (!query) {
        return this.get('items');
      }

      return this.get('items').filter(function (item) {
        return item.get(_this.get('labelPath')).toUpperCase().indexOf(query.toUpperCase()) !== -1;
      });
    }),

    actions: {
      select: function select(itemId) {
        this.set('itemId', itemId);
        this._sendSelected(this.get('selectedItem'));
      },
      close: function close() {
        this.sendAction('close');
      }
    },

    _sendSelected: function _sendSelected(item) {
      if (this.get('handleSelect')) {
        this.get('handleSelect')(item);
      }

      if (this.get('handleSelectId')) {
        this.get('handleSelectId')(item && item.get('id') || null);
      }

      this.send('close');
    }
  });
});