define('cdata/pods/sales/sale-mixin-assign', ['exports', 'cdata/mixins/editor/saving-cases', 'cdata/constants'], function (exports, _savingCases, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_savingCases.default, {
    assignFormPath: 'lots.modal.assign',
    assignModalId: _constants.MODALS.SALE_ASSIGN,

    actions: {
      /**
       * Assign property to organizations
       * @param {Array} items lots list
       * @param {String} assignOption name of assign option (role, nationality, etc)
       * @param {String|Boolean} assignValue id of assign subject
       * @param {String} rule rule for replace/alternate/preferred
       * @param {Boolean} isFilter send filter parameters instead checked items
       */
      saveAssignSaleForm: function saveAssignSaleForm(items, assignOption, assignValue, rule, isFilter) {
        var props = this._makeAssignProperties(assignOption, assignValue, isFilter, rule);
        var saleIds = items.map(function (item) {
          return parseInt(item.get('saleId'));
        });

        this._saveAssignUpdate('sales', props, saleIds, _constants.MODALS.SALE_ASSIGN + '.saving');
      },
      removeAssignSales: function removeAssignSales(items) {
        this.send('saveAssignSaleForm', items, 'delete');
      }
    }
  });
});