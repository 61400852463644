define('cdata/pods/organizations/index/route', ['exports', 'cdata/mixins/lists/route-list', 'cdata/constants', 'cdata/pods/organizations/merge-mixin'], function (exports, _routeList, _constants, _mergeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeList.default, _mergeMixin.default, {
    prevNextOrganizationsEditor: Ember.inject.service(),
    prevNextEditor: Ember.computed.alias('prevNextOrganizationsEditor'),

    model: function model(params) {
      return this.infinity(params, 'organization-list-item');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('prevNextEditor', this.get('prevNextEditor'));
    },


    assignFormPath: 'organizations.modal.assign',
    assignModalId: _constants.MODALS.ORGANIZATION_ASSIGN,

    actions: {
      /**
       * Assign property to organizations
       * @param {Array} items people list
       * @param {String} assignOption name of assign option (role, nationality, etc)
       * @param {String|Boolean} assignValue id of assign subject
       * @param {String} rule rule for replace/alternate/preferred
       * @param {Boolean} isFilter send filter parameters instead checked items
       */
      saveAssignOrganizationForm: function saveAssignOrganizationForm(items, assignOption, assignValue, rule, isFilter) {
        var props = this._makeAssignProperties(assignOption, assignValue, isFilter, rule);
        var organizationIds = items.map(function (item) {
          return parseInt(item.get('organizationId'));
        });

        this._saveAssignUpdate('organizations', props, organizationIds, _constants.MODALS.ORGANIZATION_ASSIGN + '.saving');
      },
      assignRemoveOrganizations: function assignRemoveOrganizations(items) {
        this.send('saveAssignOrganizationForm', items, 'delete', null, null, false);
      }
    }
  });
});