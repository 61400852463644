define('cdata/pods/init/loader/route', ['exports', 'cdata/mixins/route-type-page'], function (exports, _routeTypePage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeTypePage.default, {
    sidConstants: Ember.inject.service(),
    userActions: Ember.inject.service(),
    ajax: Ember.inject.service(),
    transitionSpot: Ember.inject.service(),

    vocabInitialized: false,
    usersLoaded: false,
    usersLoadingStart: false,

    _waitingHasRun: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (!this._waitingHasRun) {
        this.waiting();
      }
    },
    activate: function activate() {
      this._super.apply(this, arguments);
      if (!this._waitingHasRun) {
        this.waiting();
      }
    },


    /**
     * Waiting for load all static resources
     */
    waiting: function waiting() {
      var _this = this;

      this._waitingHasRun = true;

      // at first, check load sid constants
      if (!this.get('sidConstants.isLoaded')) {
        Ember.run.later(this.waiting.bind(this), 50);

        return;
      }

      // start for load vocabularies
      if (!this.get('vocabInitialized')) {
        this.get('vocab').doLoad();
        this.set('vocabInitialized', true);
      }

      // start for load users
      if (!this.get('usersLoaded')) {
        if (!this.get('usersLoadingStart')) {
          this.set('usersLoadingStart', true);
          this.get('ajax').$get('users', {}, function (users) {
            var list = {};
            users.forEach(function (user) {
              return list[user.id + ''] = user.email.split('@', 1)[0];
            });
            _this.set('userActions.users', list);
            _this.set('usersLoaded', true);
          });
        }
      }

      // check load vocabularies
      if (!this.get('vocab.isLoaded')) {
        // this.get('vocab.vocabularyMap').forEach((value, key, map) => {
        //   console.log(key, this.get('vocab.' + key).get('length'));
        //   void value;
        // });
        Ember.run.later(this.waiting.bind(this), 50);

        return;
      }

      // all needs loaded - goto back route
      this.get('transitionSpot').redirect(function () {
        _this.transitionTo('people');
        _this._waitingHasRun = false;
      });
    }
  });
});