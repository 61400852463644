define('cdata/pods/components/people/modals/conversion-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.PERSON_CONVERSION,

    classNames: ['context-modal', _constants.MODALS.PERSON_CONVERSION, 'edit-form'],

    confirmPersonConversion: 'confirmPersonConversion',
    closeConversionPersonForm: 'closeConversionPersonForm',

    name: Ember.computed('model.person.personNames.@each.{preferred,text}', function () {
      var names = this.get('model.person.personNames');
      var name = names && names.findBy('preferred');

      return name && name.get('text') || 'Unknown';
    }),

    actions: {
      confirm: function confirm() {
        this.sendAction('confirmPersonConversion', this.get('model.person'));
      },
      cancel: function cancel() {
        this.sendAction('closeConversionPersonForm');
      }
    }
  });
});