define('cdata/pods/components/people/edit/edit-forms-departments/component', ['exports', 'cdata/pods/components/people/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('JdeDepartment'), {
    jdeItems: Ember.computed.filterBy('model.personJdeDepartments', 'isDeleted', false),
    specItems: Ember.computed.filterBy('model.personChristiesSpecialistDepartments', 'isDeleted', false),

    departmentIds: Ember.computed.mapBy('jdeItems', 'jdeDepartmentVocabularyItemId'),
    specIds: Ember.computed.mapBy('specItems', 'christiesSpecialistDepartmentVocabularyItemId'),

    autoCreating: false,

    addPersonChristiesSpecialistDepartment: 'addPersonChristiesSpecialistDepartment',
    removePersonChristiesSpecialistDepartment: 'removePersonChristiesSpecialistDepartment',
    setPreferredToPersonChristiesSpecialistDepartment: 'setPreferredToPersonChristiesSpecialistDepartment',

    threeDigitCodes: Ember.computed('model._threeDigitCodes.@each', function () {
      var _this = this;

      var codes = this.get('model._threeDigitCodes') || [];

      return codes.map(function (code) {
        return _this.get('vocab.threeDigitCodesLabels')[code];
      }).join(', ');
    }),

    actions: {
      addSpec: function addSpec() {
        this.sendAction.apply(this, ['addPersonChristiesSpecialistDepartment'].concat(Array.prototype.slice.call(arguments)));
      },
      removeSpec: function removeSpec() {
        this.sendAction.apply(this, ['removePersonChristiesSpecialistDepartment'].concat(Array.prototype.slice.call(arguments)));
      },
      setPreferredSpec: function setPreferredSpec() {
        this.sendAction.apply(this, ['setPreferredToPersonChristiesSpecialistDepartment'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});