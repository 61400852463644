define('cdata/pods/components/entities/view/sales-history/component', ['exports', 'cdata/services/natural-sorter', 'cdata/mixins/components/cdata-category-is'], function (exports, _naturalSorter, _cdataCategoryIs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_cdataCategoryIs.default, {
    tagName: 'aside',
    classNames: ['entity-item--history-lots'],

    loadImageFromEntity: 'loadImageFromEntity',
    openEntityForms: 'openEntityForms',

    entityLots: Ember.computed('model.entityLots.@each.startDate', function () {
      return this.get('model.entityLots');
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var entityLots = this.get('entityLots');

      entityLots.forEach(function (entityLot) {
        entityLot.get('lot').then(function (lot) {
          lot.get('session').then(function (session) {
            entityLot.set('startDate', session.get('startDate'));
          });
        });
      });
    },


    entityLotsSorted: Ember.computed.sort('entityLots', (0, _naturalSorter.default)('startDate')),

    entityLotsSortedBack: Ember.computed('entityLotsSorted', function () {
      return this.get('entityLotsSorted').reverse();
    }),

    isFineArt: Ember.computed('model.cdataCategoryVocabularyItemId', function () {
      return this.cdataCategoryIsFineArt(this.get('model.cdataCategoryVocabularyItemId'));
    }),

    actions: {
      upcomingLotsOptions: function upcomingLotsOptions() {
        this.sendAction('openEntityForms', 'edit-forms-upcoming-lots');
      },
      loadImageFromEntity: function loadImageFromEntity() {
        this.sendAction.apply(this, ['loadImageFromEntity'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});