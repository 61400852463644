define('cdata/pods/components/ui-modal/declaration', ['exports', 'ember-prop-types'], function (exports, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    isOpen: _emberPropTypes.PropTypes.bool,
    onApprove: _emberPropTypes.PropTypes.func,
    onDeny: _emberPropTypes.PropTypes.func,
    onHide: _emberPropTypes.PropTypes.func
  };
});