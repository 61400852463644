define('cdata/mixins/components/route-context-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    userActions: Ember.inject.service(),

    _contextMenuOpened: false,

    actions: {
      clickOnContextMenuButton: function clickOnContextMenuButton() {
        this.set('userActions.contextMenuOpened', !this.get('userActions.contextMenuOpened'));
        this.fade();
      },
      closeContextMenu: function closeContextMenu() {
        this.set('userActions.contextMenuOpened', false);
        this.fade();
      }
    },

    fade: function fade() {
      var _this = this;

      var fade = Ember.$('.fade');
      var menu = Ember.$('.menu-context');
      var dropdown = menu.find('.dropdown-menu');

      if (this.get('userActions.contextMenuOpened')) {
        fade.show();
        menu.addClass('opened');
        dropdown.show();
        fade.on('click.menu-context', function () {
          _this.send('clickOnContextMenuButton');
        });
      } else {
        fade.hide();
        menu.removeClass('opened');
        dropdown.hide();
        fade.off('click.menu-context');
      }
    }
  });
});