define('cdata/pods/components/entities/edit/edit-forms-measurements/value/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['sixteen wide column'],

    remove: true,
    removeMeasurementValue: 'removeMeasurementValue',

    actions: {
      remove: function remove() {
        this.sendAction('removeMeasurementValue', this.get('model'));
      }
    }
  });
});