define('cdata/pods/entities/upcoming-lots-mixin', ['exports', 'cdata/mixins/components/cdata-category-is'], function (exports, _cdataCategoryIs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_cdataCategoryIs.default, {
    comparable: Ember.inject.service(),

    initUpcomingLots: function initUpcomingLots(controller, model) {
      controller.set('upcomingLots', []);

      if (this.cdataCategoryIsFineArt(model.get('cdataCategoryVocabularyItemId'))) {
        var lots = [];
        lots.inProgress = true;
        controller.set('upcomingLots', lots);

        this.get('comparable').queryUpcomingLots(model.id, this.get('comparable').getUpcomingOptions(model.id, true), function (lots) {
          controller.set('upcomingLots', lots);
        });
      }
    },


    actions: {
      applyUpcomingParams: function applyUpcomingParams() {
        var _this = this;

        var model = this.get('currentModel');

        this.get('controller').set('upcomingLots', []);

        var params = this.get('comparable').getUpcomingOptions(model.id, true);

        var lots = [];
        lots.inProgress = true;
        this.get('controller').set('upcomingLots', lots);

        this.get('comparable').saveUpcomingOptions(params);
        this.get('comparable').queryUpcomingLots(model.id, params, function (lots) {
          _this.get('controller').set('upcomingLots', lots);
        });
      },
      loadImageFromEntity: function loadImageFromEntity(entityId, context) {
        var _this2 = this;

        var entity = this.get('store').peekRecord('entity', entityId);

        if (!entity) {
          this.get('store').findRecord('entity', entityId).then(function (entity) {
            _this2._setImage(entity, context);
          });
        } else {
          this._setImage(entity, context);
        }
      }
    },

    _setImage: function _setImage(entity, context) {
      var images = entity.get('entityImages').filterBy('preferred', true);
      var image = images && images.get('firstObject');

      if (image) {
        image.get('image').then(function (image) {
          context.set('image', image.get('url'));
        });
      }
    }
  });
});