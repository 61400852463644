define('cdata/models/vocabulary-item-list-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    name: attr('string'),
    alternateNames: attr('array'),
    level: attr('number'),
    order: attr('number'),
    vocabularyId: attr('string'),
    parentId: attr('string'),
    parentUuid: attr('string'),
    parents: attr('array'),
    parent: belongsTo('vocabulary-item', { inverse: null }),
    uuid: attr('string'),
    vocabularySid: attr('string'),
    vocabularyUuid: attr('string'),
    vocabularyItemMappings: attr('array')
  });
});