define('cdata/pods/components/sales/edit/edit-forms-properties/component', ['exports', 'cdata/pods/components/sales/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), {
    loadOrganizationFor: 'loadOrganizationFor',
    autoCreating: false,

    actions: {
      setOrganization: function setOrganization(_, organizationSubject) {
        this.sendAction('loadOrganizationFor', this.get('model'), organizationSubject.get('id'));
      },
      setShown: function setShown() {
        this.set('model.isCharity', true);
      },
      setHidden: function setHidden() {
        this.set('model.isCharity', false);
      }
    }
  });
});