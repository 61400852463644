define("cdata/mirage/datasets/locations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    '146': {
      "location_titles": [{
        "text": "Doyle - New York",
        "preferred": true,
        "location_id": 146,
        "language": "en",
        "id": 146
      }],
      "location": {
        "region": null,
        "longitude": -73.95400110000003,
        "location_title_ids": [146],
        "latitude": 40.7799048,
        "id": 146,
        "google_place_id": null,
        "geotag_url": null,
        "country": "US",
        "continent": "North America",
        "city": "New York",
        "address": "175 E 87th St, New York, NY 10128, USA",
        "_name": "Doyle - New York"
      }
    },
    '301': {
      "location_titles": [{
        "text": "Christie's - Amsterdam",
        "preferred": true,
        "location_id": 301,
        "language": "en",
        "id": 301
      }],
      "location": {
        "region": null,
        "longitude": 4.872711999999979,
        "location_title_ids": [301],
        "latitude": 52.353646,
        "id": 301,
        "google_place_id": null,
        "geotag_url": null,
        "country": "NL",
        "continent": "Europe",
        "city": "Amsterdam",
        "address": "Cornelis Schuytstraat 57, 1071 JG Amsterdam, Netherlands",
        "_name": "Christie's - Amsterdam"
      }
    }
  };
});