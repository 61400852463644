define('cdata/pods/sales/lots-session/route', ['exports', 'cdata/mixins/lists/route-list', 'cdata/mixins/lists/format-filter-creators', 'cdata/pods/lots/lot-assign-mixin'], function (exports, _routeList, _formatFilterCreators, _lotAssignMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeList.default, _formatFilterCreators.default, _lotAssignMixin.default, {
    prevNextEditor: Ember.inject.service(),

    saleId: null,
    sessionId: null,

    defaultSort: {
      key: 'number',
      asc: true
    },

    model: function model(params) {
      this.setPermanentFilterParameter('session_ids', [params.session_id]);
      this.set('saleId', params.sale_id);
      this.set('sessionId', params.session_id);

      return this.infinity(params, 'lot-list-item');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var saleId = this.get('saleId');
      controller.set('saleId', saleId);
      var sale = this.get('store').peekRecord('sale', saleId);
      if (sale) {
        controller.set('sale', sale);
      } else {
        this.get('store').findRecord('sale', saleId).then(function (sale) {
          controller.set('sale', sale);
        });
      }

      var sessionId = this.get('sessionId');
      controller.set('sessionId', sessionId);
      var session = this.get('store').peekRecord('session', sessionId);
      if (session) {
        controller.set('session', session);
      } else {
        this.get('store').findRecord('session', sessionId).then(function (session) {
          controller.set('session', session);
        });
      }

      controller.set('prevNextEditor', this.get('prevNextEditor'));
    },


    actions: {
      willTransition: function willTransition() {
        this.clearPermanentFilterParameters();
      }
    }
  });
});