define('cdata/pods/components/entities/edit/edit-forms-measurements/item/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms-item'], function (exports, _editFormsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsItem.default, {
    values: Ember.computed.filterBy('model.entityMeasurementValues', 'isDeleted', false),

    addNewValue: 'addNewValue',
    parseMeasurement: 'parseMeasurement',
    removeMeasurementValue: 'removeMeasurementValue',

    actions: {
      addNewValue: function addNewValue() {
        this.sendAction('addNewValue', this.get('model'));
      },
      parseMeasurement: function parseMeasurement() {
        if (this.get('model.text') && this.get('values.length') === 0) {
          this.sendAction('parseMeasurement', this.get('model'));
        }
      },
      removeMeasurementValue: function removeMeasurementValue(value) {
        this.sendAction('removeMeasurementValue', value);
      }
    }
  });
});