define('cdata/pods/components/locations/google-map/component', ['exports', 'cdata/pods/components/locations/mixins/google-maps'], function (exports, _googleMaps) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_googleMaps.default, {
    latitude: Ember.computed.alias('location.latitude'),
    longitude: Ember.computed.alias('location.longitude'),
    googlePlaceId: Ember.computed.alias('location.googlePlaceId'),
    name: Ember.computed.alias('location.name'),
    address: Ember.computed.alias('location.address'),

    insertMap: Ember.on('didInsertElement', function () {
      var maps = this.get('maps').init(this, this.$('.map-canvas')[0]);

      Ember.run.later(this, function () {
        Ember.$(maps.input()).fadeIn().focus();
      }, 500);
    })
  });
});