define('cdata/pods/components/two-options-switcher/declaration', ['exports', 'ember-prop-types'], function (exports, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    value: _emberPropTypes.PropTypes.bool,
    leftLabel: _emberPropTypes.PropTypes.string.isRequired,
    rightLabel: _emberPropTypes.PropTypes.string.isRequired,
    onChange: _emberPropTypes.PropTypes.func
  };
});