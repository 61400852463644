define('cdata/pods/components/places/list/set-results/component', ['exports', 'cdata/mixins/lists/set-results'], function (exports, _setResults) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_setResults.default, {
    openForm: 'openForm',
    deletePlace: 'deletePlace',
    createNewPlace: 'createNewPlace',

    actions: {
      deletePlace: function deletePlace() {
        this.sendAction.apply(this, ['deletePlace'].concat(Array.prototype.slice.call(arguments)));
      },
      openForm: function openForm() {
        this.sendAction.apply(this, ['openForm'].concat(Array.prototype.slice.call(arguments)));
      },
      createNewPlace: function createNewPlace() {
        this.sendAction.apply(this, ['createNewPlace'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});