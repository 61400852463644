define('cdata/pods/components/lots/edit/edit-forms-multi-object/component', ['exports', 'cdata/pods/components/lots/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), {
    autoCreating: false,

    multiCountLabel: Ember.computed('model.multiObjectCount', function () {
      if (this.get('model.multiObjectCount') === null) {
        return 'not set';
      } else {
        return '';
      }
    }),

    actions: {
      setMulti: function setMulti() {
        this.set('model.multiObject', true);
        this.set('model.multiObjectCount', null);
      },
      setSingle: function setSingle() {
        this.set('model.multiObject', false);
        this.set('model.multiObjectCount', 1);
      },
      setMultiObjectCount: function setMultiObjectCount(value) {
        if (value === null || value === '' || value === 0 || value === '0') {
          this.set('model.multiObjectCount', null);

          return;
        }

        this.set('model.multiObjectCount', parseInt(value));

        if (value >= 2) {
          this.set('model.multiObject', true);
        }

        if (value === 1 || value === '1') {
          this.set('model.multiObject', false);
        }
      }
    }
  });
});