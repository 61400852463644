define('cdata/pods/components/marks/view/mark-item/summary-info/component', ['exports', 'cdata/mixins/components/load-creator-names', 'cdata/mixins/components/summary-info-comments'], function (exports, _loadCreatorNames, _summaryInfoComments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_loadCreatorNames.default, _summaryInfoComments.default, {
    classNames: 'entity-item--info',
    tagName: 'article',

    openMarkForms: 'openMarkForms',
    save: 'save',

    actions: {
      openForms: function openForms() {
        this.sendAction.apply(this, ['openMarkForms'].concat(Array.prototype.slice.call(arguments)));
      }
    },

    otherMakers: Ember.computed('model.markMakers.@each.{preferred,label}', function () {
      this.loadCreatorNames();
      var makers = this.get('model.markMakers');

      return makers.map(function (maker) {
        var id = maker.get('makerId');

        return {
          name: maker.get('label'),
          filter: maker.get('filter'),
          route: maker.get('route'),
          id: id
        };
      });
    }),

    preferredTitle: Ember.computed('model.markTitles.@each.{preferred,text}', function () {
      var title = this.get('model.markTitles').filterBy('preferred').get('firstObject');

      return title && title.get('text');
    }),

    otherTitles: Ember.computed('model.markTitles.@each.{preferred,text}', function () {
      var titles = this.get('model.markTitles').rejectBy('preferred');

      return titles.mapBy('text');
    }),

    otherPlaces: Ember.computed('model.markPlaces.@each.placeId', function () {
      var places = this.get('model.markPlaces');
      places.forEach(function (place) {
        return place.buildName();
      });

      return places.mapBy('_name');
    }),

    otherShapes: Ember.computed('model.markShapes.@each.shapeVocabularyItemId', function () {
      var _this = this;

      var materials = this.get('model.markShapes');

      return materials.map(function (place) {
        return _this.get('vocab.shapesLabels')[place.get('shapeVocabularyItemId')];
      });
    }),

    otherMaterials: Ember.computed('model.markMaterials.@each.materialVocabularyItemId', function () {
      var _this2 = this;

      var materials = this.get('model.markMaterials');

      return materials.map(function (place) {
        return _this2.get('vocab.materialsLabels')[place.get('materialVocabularyItemId')];
      });
    }),

    otherLetters: Ember.computed('model.markLetters.@each.letter', function () {
      var letters = this.get('model.markLetters');

      return letters.map(function (letter) {
        return letter.get('letter');
      });
    }),

    otherNotes: Ember.computed('model.markNotes.@each.text', function () {
      var notes = this.get('model.markNotes');

      return notes.map(function (note) {
        return note.get('text');
      });
    }),

    otherSymbols: Ember.computed('model.markSymbols.@each.symbolVocabularyItemId', function () {
      var _this3 = this;

      var symbols = this.get('model.markSymbols');

      return symbols.map(function (symbol) {
        return _this3.get('vocab.markSymbolsLabels')[symbol.get('symbolVocabularyItemId')];
      });
    }),

    otherCategories: Ember.computed('model.markCategories.@each.cdataCategoryVocabularyItemId', function () {
      var _this4 = this;

      return this.get('model.markCategories').map(function (category) {
        var parts = [];
        var cat = _this4.get('vocab').getItemById(category.get('cdataCategoryVocabularyItemId'), 'cdataCategories');
        if (cat.get('parentId')) {
          parts.push(_this4.get('vocab.cdataCategoriesLabels')[cat.get('parentId')]);
        }
        parts.push(_this4.get('vocab.cdataCategoriesLabels')[cat.get('id')]);

        return parts.join(' > ');
      });
    })
  });
});