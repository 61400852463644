define('cdata/pods/components/comparables/search-results/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['list-set-results'],

    gotoItem: 'gotoItem',

    actions: {
      gotoItem: function gotoItem() {
        this.sendAction.apply(this, ['gotoItem'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});