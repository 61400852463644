define('cdata/pods/components/lots/list/bulk-operations/component', ['exports', 'cdata/mixins/lists/component-bulk-operations'], function (exports, _componentBulkOperations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentBulkOperations.default, {
    assignOptions: [Ember.Object.create({
      id: 'creator',
      label: 'Creator'
    }), Ember.Object.create({
      id: 'remove-creator',
      label: 'Remove creator'
    }), Ember.Object.create({
      id: 'lot_status_vocabulary_item_id',
      label: 'Status'
    }), Ember.Object.create({
      id: 'christiesSpecialist',
      label: 'Department'
    }), Ember.Object.create({
      id: 'mi_category_vocabulary_item_id',
      label: 'MI category'
    }), Ember.Object.create({
      id: 'cdata_category_vocabulary_item_id',
      label: 'CData category'
    }), Ember.Object.create({
      id: 'session',
      label: 'Session'
    }), Ember.Object.create({
      id: 'reviews',
      label: 'Create object reviews'
    }), Ember.Object.create({
      id: 'reparse-category',
      label: 'Reparse CDATA Category'
    })]
  });
});