define('cdata/mixins/lists/route-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: {
      filter: {
        refreshModel: true
      }
    },

    filterParameters: {},

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.queryParams.filter) {
        this.set('filterParameters', this._unpackFilter(transition.queryParams.filter));
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      if (this.get('filterParameters')) {
        controller.set('filterParameters', this.get('filterParameters'));
      } else {
        controller.set('filterParameters', null);
      }
    },


    /**
     * @param {Object|String} filter
     * @param {Object} [assignParams]
     * @returns {Object}
     */
    parseFilterParameters: function parseFilterParameters(filter, assignParams) {
      if (filter && typeof filter === 'string') {
        filter = this._unpackFilter(filter);
      }
      if (!filter) {
        filter = {};
      }
      if (assignParams) {
        filter = Ember.assign(filter, assignParams);
      }

      return filter;
    },


    actions: {
      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        this.set('filterParameters', {});
      },


      /**
       * Set filter to controller (i.e. query params)
       * Clear pagination
       *
       * @param filter
       */
      filter: function filter(_filter) {
        Ember.$('body').scrollTop(0);
        _filter = this._packFilter(_filter) || undefined;

        if (!_filter) {
          this.set('filterParameters', {});
        }

        this.get('controller').set('filter', _filter);
        this.get('controller').set('page', undefined);
      },


      /**
       * Change filter parameters
       * @param {Object} filter
       */
      setFilterParameters: function setFilterParameters(filter) {
        var current = this._unpackFilter(this.get('controller.filter'));

        // save special parameter from current filter
        if (current) {
          filter.query = current.query;
        }

        this.send('filter', filter);
      },


      /**
       * Change filter key 'search'
       * @param {String} text
       */
      setFilterSearch: function setFilterSearch(text) {
        var filter = this._unpackFilter(this.get('controller.filter'));
        filter.query = text && text.length ? text : null;
        this.send('filter', filter);
      },


      /**
       * Change filter key 'month'
       * @param {String} month
       */
      setFilterMonth: function setFilterMonth(month) {
        var filter = this._unpackFilter(this.get('controller.filter'));
        filter.month = month && month.length ? month : null;
        this.send('filter', filter);
      },
      loadSaleForFilterItem: function loadSaleForFilterItem(item) {
        this.get('store').findRecord('sale', item.get('id')).then(function (sale) {
          sale.get('sessions').forEach(function (session) {
            item.get('sessions').pushObject(Ember.Object.create({
              id: session.get('id'),
              label: session.get('title'),
              name: session.get('title')
            }));
          });
          item.set('label', sale.get('title'));
          item.set('qnt', item.get('sessions.length'));
        });
      }
    },

    /**
     * @param {Object} filter
     * @returns {string}
     * @private
     */
    _packFilter: function _packFilter(filter) {
      var result = [];
      for (var key in filter) {
        if (!filter.hasOwnProperty(key)) {
          continue;
        }

        // special case for creators filter
        if (key === 'creators' && Ember.isArray(filter[key])) {
          filter[key] = filter[key].map(function (item) {
            return [item.id, item.type, item.creator_qualifier_vocabulary_item_id || null].join(',');
          });
        }

        var value = this._normalizeFilterValue(filter[key]);
        if (null !== value) {
          if (Ember.isArray(value)) {
            value = value.join('!', value);
          }
          result.push(key + '*' + value);
        }
      }
      var str = '';
      if (result.length) {
        str = '(' + result.join(')*(') + ')';
      }

      // clear trash (empty items)
      str = str.replace('(*)*', '').replace('(*)', '');

      return str;
    },


    /**
     * @param {String} filter
     * @returns {Object}
     * @private
     */
    _unpackFilter: function _unpackFilter(filter) {
      var _this = this;

      var result = {};
      filter = filter || '';
      filter = filter.replace(/^\((.*)\)/, '$1');

      filter.split(')*(').forEach(function (item) {
        var parts = item.split('*');
        var key = parts[0];
        var value = parts.slice(1).join('*');
        value = _this._normalizeFilterValue(value);

        if (null !== value) {
          result[key] = value;
        }

        // array of ids
        if (key.substr(-3) === 'Ids') {
          result[key] = value.split('!');
        }

        // month period
        if (key.substr(-6) === 'Months') {
          result[key] = value.split('!');
        }

        // special format for relations filter
        if (key === 'relations') {
          result[key] = value.split('!').map(function (item) {
            item = item.split(',');

            return {
              type: item[0],
              id: item[1],
              roleId: item[2]
            };
          });
        }

        // special format for creators filter
        if (key === 'creators') {
          result[key] = value.split('!').map(function (item) {
            item = item.split(',');

            return {
              id: parseInt(item[0]),
              type: item[1],
              creator_qualifier_vocabulary_item_id: parseInt(item[2]) || null
            };
          });
        }
      });

      return result;
    },


    /**
     * return null if value is null or undefined
     * @param {*} value
     * @returns {null|*}
     * @private
     */
    _normalizeFilterValue: function _normalizeFilterValue(value) {
      if (undefined === value || 'undefined' === value || 'null' === value || null === value) {
        return null;
      }
      if ('false' === value) {
        value = false;
      }
      if ('true' === value) {
        value = true;
      }

      return value;
    }
  });
});