define('cdata/models/vocabulary-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    _name: attr('string'),
    _secondName: attr('string'),
    vocabularyId: attr('string'),
    vocabularyItemMappings: hasMany('vocabulary-item-mapping'),
    vocabularyItemTerms: hasMany('vocabulary-item-term', { async: true }),
    parentId: attr('string'),
    parent: belongsTo('vocabulary-item', { inverse: null }),
    uuid: attr('string')
  });
});