define('cdata/pods/components/subjects/search-and-add/component', ['exports', 'cdata/pods/components/subjects/mixins/search-and-mixin', 'cdata/services/subject-search'], function (exports, _searchAndMixin, _subjectSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_searchAndMixin.default, {
    session: Ember.inject.service(),

    classNames: ['autocomplete-add-item'],
    placeholder: 'Start typing name',
    subjectFilter: { clear: true },
    isFocused: false,

    isEmptyQuery: Ember.computed.not('search.query'),
    isShowDefault: Ember.computed.and('isFocused', 'isEmptyQuery'),

    defaultListItems: Ember.computed.map('defaultItems', function (_ref) {
      var id = _ref.id,
          label = _ref.label;
      return Ember.Object.create({ id: id, name: label });
    }),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('subjectName')) {
        this.set('search', _subjectSearch.default.create({
          subjectName: this.get('subjectName'),
          vocab: this.get('vocab'),
          headers: this.get('session.authHeader')
        }));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.onFocusOutWithContext = this.onFocusOut.bind(this);
      this.element.addEventListener('focusout', this.onFocusOutWithContext);
    },
    willDestroyElement: function willDestroyElement() {
      this.element.removeEventListener('focusout', this.onFocusOutWithContext);
      this._super.apply(this, arguments);
    },
    onFocusOut: function onFocusOut(_ref2) {
      var relatedTarget = _ref2.relatedTarget;

      if (!this.element.contains(relatedTarget)) {
        this.closeDefaultList();
      }
    },
    _handleSelect: function _handleSelect(item) {
      this.sendAction('handleSelect', item);
    },
    handleDefaultSelect: function handleDefaultSelect(item) {
      this.sendAction('handleSelect', item);
      this.closeDefaultList();
    },
    inputFocusIn: function inputFocusIn() {
      this.set('isFocused', true);
    },
    closeDefaultList: function closeDefaultList() {
      this.set('isFocused', false);
    }
  });
});