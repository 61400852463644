define('cdata/pods/reports/components/chart-layout/component', ['exports', 'ember-cli-jss', 'cdata/pods/reports/components/chart-layout/stylesheet', 'cdata/pods/reports/components/chart-layout/utils'], function (exports, _emberCliJss, _stylesheet, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCliJss.TaglessJSS, {
    tagName: '',
    stylesheet: _stylesheet.default,

    breadcrumbs: Ember.computed.or('hovered', 'selected'),

    setHovered: function setHovered(node) {
      this.set('hovered', (0, _utils.extractBreadrumbs)(node));
    },
    setSelected: function setSelected(node) {
      this.set('selected', (0, _utils.extractBreadrumbs)(node));
    },
    clearHovered: function clearHovered() {
      this.set('hovered', null);
    }
  });
});