define('cdata/pods/components/comparables/search-filter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['list-set-filters'],

    filter: 'filter',

    filterParameters: Ember.Object.create(),

    actions: {
      run: function run() {
        this.sendAction('filter');
      },
      setMaker: function setMaker(_, subject) {
        this.set('filterParameters.maker', subject ? subject.get('name') : null);
        this.send('run');
      }
    }
  });
});