define('cdata/pods/auction-houses/index/route', ['exports', 'cdata/mixins/lists/route-pagination', 'cdata/mixins/lists/route-sorting'], function (exports, _routePagination, _routeSorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routePagination.default, _routeSorting.default, {
    model: function model(params) {
      return this.get('store').query('auction-house', this.makeQueryParameters(params));
    }
  });
});