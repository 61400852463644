define('cdata/pods/components/marks/edit/menu-context/component', ['exports', 'cdata/mixins/components/menu-context'], function (exports, _menuContext) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_menuContext.default, {
    createMark: 'createMark',

    actions: {
      addMark: function addMark() {
        this.sendAction('createMark');
        this.closeContextMenuAction();
      }
    }
  });
});