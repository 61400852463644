define('cdata/pods/places/index/route', ['exports', 'cdata/mixins/lists/route-list', 'cdata/pods/places/edit-mixin'], function (exports, _routeList, _editMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeList.default, _editMixin.default, {
    model: function model(params) {
      return this.infinity(params, 'place-list-item');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('editor', this.get('editor'));
    },


    actions: {
      openForm: function openForm(item) {
        this._clearEditor();
        this.set('editor.item', item);
        this.set('editor.formName', 'places/edit/edit-forms-place');
        Ember.$('body').css('overflow', 'hidden');
      }
    }
  });
});