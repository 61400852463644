define('cdata/pods/components/entities/edit/edit-forms-editions/note/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms-item', 'cdata/constants'], function (exports, _editFormsItem, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsItem.default, {
    subjectSearch: Ember.inject.service(),
    modal: Ember.inject.service(),

    openNewEditionForm: 'openNewEditionForm',

    editionSubject: null,
    editionSize: Ember.computed.alias('editionSubject.description'),
    editionName: Ember.computed.alias('editionSubject.name'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._initEditionSubject();
    },


    actions: {
      setEdition: function setEdition(_, editionSubject) {
        this.set('editionSubject', editionSubject);
        this.set('model.editionId', editionSubject.get('id'));
      },
      createEdition: function createEdition(title) {
        this.sendAction('openNewEditionForm', title);
        this._subscribeNewEdition();
      }
    },

    _initEditionSubject: function _initEditionSubject() {
      var _this = this;

      var editionId = this.get('model.editionId');

      if (!editionId) {
        return;
      }

      this.get('subjectSearch').getById(editionId, 'edition').then(function (item) {
        if (item) {
          _this.set('editionSubject', item);
        }
      });
    },
    _subscribeNewEdition: function _subscribeNewEdition() {
      this.get('modal').on(_constants.MODALS.EDITION + '.saving.success', this, this._onEditionCreated).on(_constants.MODALS.EDITION + '.close', this, this._unSubscribeNewEdition);
    },
    _unSubscribeNewEdition: function _unSubscribeNewEdition() {
      this.get('modal').off(_constants.MODALS.EDITION + '.saving.success', this, this._onEditionCreated).off(_constants.MODALS.EDITION + '.close', this, this._unSubscribeNewEdition);
    },
    _onEditionCreated: function _onEditionCreated(result) {
      var edition = result.item;

      // add edition-based subject to list
      this.send('setEdition', this.get('model'), Ember.Object.create({
        id: edition.get('id'),
        name: edition.get('title'),
        description: edition.get('size'),
        type: 'edition'
      }));
    }
  });
});