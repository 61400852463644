define('cdata/services/modal', ['exports', 'cdata/mixins/editor/event-bus'], function (exports, _eventBus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_eventBus.default, {
    modals: [],

    hasActiveModal: Ember.computed.gt('modals.length', 0),

    sid: Ember.computed('modals.length', function () {
      var current = this.get('modals').get('lastObject');

      return current && current.sid;
    }),

    register: function register(route, sid, controller, template) {
      template = template || controller;

      var outlet = this.getNextOutlet();
      var index = this.getNextIndex();

      var modal = {
        index: index,
        outlet: outlet,
        controller: controller,
        template: template,
        sid: sid,
        route: route
      };

      this.get('modals').pushObject(modal);

      return this;
    },
    open: function open(model, sid) {
      var modal = void 0;
      var modals = this.get('modals');

      if (sid) {
        modal = modals.findBy('sid', sid);
      } else {
        modal = modals.get('lastObject');
        sid = modal.sid;
      }

      this.trigger(sid + '.open');
      this._renderOutlet(modal, model);
    },
    _renderOutlet: function _renderOutlet(modal, model) {
      modal.route.render(modal.template, {
        into: 'application',
        outlet: modal.outlet,
        controller: modal.controller,
        model: model
      });

      this._afterRender(modal);
    },
    _afterRender: function _afterRender(modal) {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var selector = '.context-modal.' + modal.sid;
        var $modal = Ember.$(selector);
        var $uiModal = $modal.find('.ui.modal');

        if (!$uiModal || 0 === $uiModal.length) {
          _this.get('modals').removeObject(modal);

          return;
        }

        $modal.fadeIn().show();
        $uiModal.fadeIn().show();
        Ember.$('body').addClass('modal');
        var modals = _this.get('modals');

        for (var idx = 0; idx < modals.get('length') - 1; idx++) {
          Ember.$('.context-modal.' + modals[idx].sid).hide().find('.ui.modal').hide();
        }
      });
    },
    close: function close(sid) {
      var _this2 = this;

      var modal = this.get('modals').findBy('sid', sid);

      if (!modal) {
        return;
      }

      var modals = this.get('modals');
      var notLast = modals.get('length') >= modal.index;

      if (notLast) {
        var next = modals[modal.index + 1];
        if (next) {
          this.close(next.sid);
        }
      }

      modals.removeObject(modal);

      this.trigger(sid + '.close');

      if (modals.get('length') === 0) {
        Ember.run.later(function () {
          _this2.trigger('closed.all');
        });
      }

      modal.route.disconnectOutlet({
        outlet: modal.outlet,
        parentView: 'application'
      });

      Ember.$('.context-modal.' + modal.sid).hide();

      var lastModal = modals.get('lastObject');
      if (lastModal) {
        Ember.$('.context-modal.' + lastModal.sid).fadeIn().find('.ui.modal').fadeIn();
      } else {
        Ember.$('body').removeClass('modal');
      }
    },
    getNextOutlet: function getNextOutlet() {
      return 'modal-' + this.getNextIndex();
    },
    getNextIndex: function getNextIndex() {
      return this.get('modals.length');
    }
  });
});