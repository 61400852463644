define('cdata/serializers/person', ['exports', 'ember-data', 'cdata/serializers/application', 'cdata/mixins/serializers/embedded-on-save'], function (exports, _emberData, _application, _embeddedOnSave) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
  exports.default = _application.default.extend(EmbeddedRecordsMixin, _embeddedOnSave.default, {
    saveNewAsEmbedded: ['personNames', 'personVitalDates', 'personRoles', 'personNationalities', 'personBiographies'],
    attrs: {
      personNames: {
        serialize: 'ids'
      },
      personVitalDates: {
        serialize: 'ids'
      },
      personRoles: {
        serialize: 'ids'
      },
      personNationalities: {
        serialize: 'ids'
      },
      personBiographies: {
        serialize: 'ids'
      }
    }
  });
});