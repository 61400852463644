define('cdata/pods/components/marks/edit/edit-forms-shapes/component', ['exports', 'cdata/pods/components/marks/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Shape'), {
    autoCreating: false,

    shapeIds: Ember.computed.mapBy('shapes', 'id'),

    shapes: Ember.computed.filterBy('model.markShapes', 'isDeleted', false),

    actions: {
      setShape: function setShape(_, subjectShape) {
        this.send('add', subjectShape);
      }
    }
  });
});