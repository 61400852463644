define('cdata/pods/components/marks/edit/edit-forms-letters/component', ['exports', 'cdata/pods/components/marks/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Letter'), {
    autoCreating: false,

    letterIds: Ember.computed.mapBy('letters', 'letter'),

    letters: Ember.computed.filterBy('model.markLetters', 'isDeleted', false),

    actions: {
      addLetter: function addLetter(subjectLetter) {
        if (this.notExist(subjectLetter.get('id'))) {
          this.send('add', subjectLetter.get('id'));
        }
      },
      createLetter: function createLetter(letter) {
        if (this.notExist(letter)) {
          this.send('add', letter);
        }
      }
    },

    notExist: function notExist(letter) {
      return this.get('letterIds').indexOf(letter) === -1;
    }
  });
});