define('cdata/pods/components/sales/view/sale-header/component', ['exports', 'moment', 'cdata/mixins/components/item-header'], function (exports, _moment, _itemHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_itemHeader.default, {
    classNames: 'sales-sale-header',

    openSaleForms: 'openSaleForms',
    gotoObjects: 'gotoObjects',
    createLotAndEntity: 'createLotAndEntity',
    gotoSaleLots: 'gotoSaleLots',

    isOneSession: Ember.computed.equal('model.sessions.length', 1),

    locationAndDate: Ember.computed('location', 'date', function () {
      var info = [];
      if (this.get('location')) {
        info.push(this.get('location'));
      }

      if (this.get('date')) {
        info.push(this.get('date'));
      }

      return info.join(', ');
    }),

    money: Ember.computed('model.{priceTypeVocabularyItemId,currencyVocabularyItemId}', function () {
      var parts = [];
      var priceType = this.get('model.priceTypeVocabularyItemId');
      var currency = this.get('model.currencyVocabularyItemId');

      if (priceType) {
        parts.push(this.get('vocab.priceTypesLabels')[priceType]);
      }

      if (currency) {
        parts.push(this.get('vocab.currenciesLabels')[currency]);
      }

      return parts.join(', ');
    }),

    auctionHouse: Ember.computed('model.organization.id', 'model.organization.organizationTitles.@each.{preferred,text}', function () {
      var organization = this.get('model.organization');
      if (!organization) {
        return;
      }

      var title = organization.get('organizationTitles').findBy('preferred');

      return title && title.get('text');
    }),

    location: Ember.computed.alias('model.sessions.firstObject.location._name'),

    title: Ember.computed.alias('model.title'),

    startDate: Ember.computed.alias('model.sessions.firstObject.startDate'),
    finishDate: Ember.computed.alias('model.sessions.lastObject.finishDate'),

    date: Ember.computed('startDate', 'finishDate', function () {
      return this.get('startDate') ? (0, _moment.default)(this.get('startDate')).format('ll') : '';
    }),

    saleImage: Ember.computed('model.saleImages.@each.{preferred,image}', function () {
      return this.get('model.saleImages').filterBy('preferred').get('firstObject');
    }),

    imageVersion: Ember.computed.alias('saleImage.image.imageImageVersion'),

    image: Ember.computed('saleImage.image.url', 'saleImage.image.imageImageVersionId', function () {
      return this.get('saleImage.image.imageImageVersion.mediumUrl') || this.get('saleImage.image.url');
    }),

    bgrStyle: Ember.computed('image', function () {
      var image = this.get('image');
      if (image) {
        return Ember.String.htmlSafe('background-image: url(' + image + ')');
      }

      return Ember.String.htmlSafe('');
    }),

    createLotInProcess: false,
    loadSaleLotsInProcess: false,

    actions: {
      showImagesForms: function showImagesForms() {
        this.sendAction('openSaleForms', 'edit-forms-image-relations');
      },
      addLot: function addLot() {
        this.set('createLotInProcess', true);
        this.sendAction('createLotAndEntity', this.get('model'));
      },
      showSaleLots: function showSaleLots() {
        this.set('loadSaleLotsInProcess', true);
        this.sendAction('gotoSaleLots', this.get('model'));
      }
    }
  });
});