define('cdata/helpers/line-breaks-to-br', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.lineBreaksToBr = lineBreaksToBr;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var escape = Ember.Handlebars.Utils.escapeExpression;

  function lineBreaksToBr(params) {
    var text = params[0];

    if (!text) {
      return text;
    }

    if ((typeof text === 'undefined' ? 'undefined' : _typeof(text)) === 'object' && typeof text.toString === 'function') {
      text = text.toString();
    }

    if (typeof text.split === 'function') {
      var parts = text.split("\n");
      parts = parts.map(function (part) {
        return escape(part);
      });
      text = parts.join('<br>');
    }

    return Ember.String.htmlSafe(text);
  }

  exports.default = Ember.Helper.helper(lineBreaksToBr);
});