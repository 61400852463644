define('cdata/helpers/short-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shortText = shortText;
  function shortText(params) {
    var text = params[0] + '';
    var size = params[1] || 30;
    var suffix = params[2] || '...';

    if (text && text.length > size * 1.2) {
      var parts = text.split(' ');
      var result = '';

      parts.forEach(function (part) {
        if (result.length < size) {
          result += part + ' ';
        }
      });

      return result + suffix;
    }

    return text;
  }

  exports.default = Ember.Helper.helper(shortText);
});