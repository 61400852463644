define('cdata/pods/components/people/edit/edit-forms-relationships/component', ['exports', 'cdata/pods/components/people/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Relationship'), {
    openVocabularyNewItemForm: 'openVocabularyNewItemForm',
    openNewPersonForm: 'openNewPersonForm',
    loadPersonForRelationship: 'loadPersonForRelationship',

    actions: {
      openVocabularyNewItemForm: function openVocabularyNewItemForm() {
        this.sendAction.apply(this, ['openVocabularyNewItemForm'].concat(Array.prototype.slice.call(arguments)));
      },
      openNewPersonForm: function openNewPersonForm() {
        this.sendAction.apply(this, ['openNewPersonForm'].concat(Array.prototype.slice.call(arguments)));
      },
      loadPersonForRelationship: function loadPersonForRelationship() {
        this.sendAction.apply(this, ['loadPersonForRelationship'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});