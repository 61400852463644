define('cdata/models/mark-place', ['exports', 'ember-data', 'cdata/mixins/models/build-place-name'], function (exports, _emberData, _buildPlaceName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_buildPlaceName.default, {
    markId: attr('string'),
    mark: belongsTo('mark'),
    placeId: attr('string'),
    place: belongsTo('place')
  });
});