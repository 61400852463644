define('cdata/pods/components/locations/add-address-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('agent').init = this._edit.bind(this);
      this.get('agent').create = function () {
        _this.send('openGoogleMap');
      };
      this._initForm();
    },


    labels: Ember.computed.alias('vocab.contactTypesLabels'),

    step: 'closed',
    isClosed: Ember.computed.equal('step', 'closed'),
    isMap: Ember.computed.equal('step', 'map'),
    isMapConfirm: Ember.computed.equal('step', 'map:confirm'),
    isAddress: Ember.computed.equal('step', 'address'),

    isPlace: Ember.computed.bool('model.location.googlePlaceId'),
    isNotPlace: Ember.computed.not('isPlace'),

    isFilled: Ember.computed.and('model.location.{city,address,googlePlaceId}'),
    isEmpty: Ember.computed.not('isFilled'),

    country: Ember.computed('model.location.{country,continent}', function () {
      var parts = [];
      var location = this.get('model.location');
      var country = location.get('country');
      var continent = location.get('continent');

      if (country) {
        parts.push(country);
      } else if (null !== country) {
        parts.push('Unknown');
      }
      if (continent) {
        parts.push(continent);
      } else if (null !== continent) {
        parts.push('Unknown');
      }

      return parts.join(' / ');
    }),

    actions: {
      setContactType: function setContactType(id) {
        this.set('model.contactTypeVocabularyItemId', id);
      },
      add: function add() {
        if (this.get('isEmpty')) {
          return;
        }

        if ('add' === this.get('mode')) {
          this.sendAction('addPersonAddress', this.get('model'));
        } else {
          this.sendAction('applyLocationForm', this.get('address'), this.get('model'));
        }

        this.send('cancel');
      },
      openGoogleMap: function openGoogleMap() {
        this.set('step', 'map');
      },
      confirmGoogleMap: function confirmGoogleMap() {
        this.set('step', 'address');
      },
      hideGoogleMap: function hideGoogleMap() {
        this.set('showMap', false);
        this.set('model.location.googlePlaceId', null);
      },
      changePlace: function changePlace(place) {
        this.get('model.location').setProperties(place);
        this.sendAction('parseLocationForm', this.get('model.location'));
        this.set('step', 'map:confirm');
      },
      cancel: function cancel() {
        this.set('step', 'closed');
        this._initForm();
      }
    },

    _initForm: function _initForm() {
      this.set('model', Ember.Object.create({
        location: Ember.Object.create({
          city: null,
          country: null,
          continent: null,
          region: null,
          url: null,
          address: null,
          latitude: null,
          longitude: null,
          name: null,
          googlePlaceId: null,
          location: null
        })
      }));
      this.set('showMap', false);
      this.set('address', null);
      this.set('mode', 'add');
      this._markAsEdit();
    },
    _edit: function _edit(address) {
      this.send('cancel');
      this.set('step', 'address');
      this.sendAction('initLocationForm', this.get('model'), address);
      this.set('address', address);
      this.set('mode', 'edit');
      this._markAsEdit(address);
    },
    _markAsEdit: function _markAsEdit(model) {
      this.get('items').forEach(function (item) {
        item.set('_edit', false);
      });
      if (model) {
        model.set('_edit', true);
      }
    }
  });
});