define("cdata/mirage/datasets/organizations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    '49034': {
      "organization_titles": [{
        "text": "Doyle New York",
        "source_page": null,
        "source_id": null,
        "preferred": true,
        "organization_id": 49034,
        "language": "en",
        "id": 51911
      }],
      "organization_roles": [{
        "role_id": 1443,
        "preferred": true,
        "organization_id": 49034,
        "id": 49034
      }],
      "organization_nationalities": [],
      "organization_existence_dates": [],
      "organization": {
        "organization_title_ids": [51911],
        "organization_role_ids": [49034],
        "organization_nationality_ids": [],
        "organization_existence_date_ids": [],
        "id": 49034,
        "disambiguating_comment": null
      }
    },
    '48783': {
      "organization_titles": [{
        "text": "Christie's",
        "source_page": null,
        "source_id": null,
        "preferred": true,
        "organization_id": 48783,
        "language": "en",
        "id": 51654
      }],
      "organization_roles": [{ "role_id": 1443, "preferred": true, "organization_id": 48783, "id": 48784 }],
      "organization_nationalities": [],
      "organization_existence_dates": [],
      "organization": {
        "organization_title_ids": [51654],
        "organization_role_ids": [48784],
        "organization_nationality_ids": [],
        "organization_existence_date_ids": [],
        "id": 48783,
        "disambiguating_comment": null
      }
    }
  };
});