define('cdata/pods/components/sales/list/bulk-operations/component', ['exports', 'cdata/mixins/lists/component-bulk-operations'], function (exports, _componentBulkOperations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentBulkOperations.default, {
    assignOptions: [
      // Ember.Object.create({
      //   id: 'cdata_category_vocabulary_item_id',
      //   label: 'CData category'
      // }),
      // Ember.Object.create({
      //   id: 'mi_category_vocabulary_item_id',
      //   label: 'MI category'
      // })
    ]
  });
});