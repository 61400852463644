define('cdata/mixins/create-modals/comparable-sets', ['exports', 'cdata/constants', 'ember-local-storage', 'cdata/mixins/editor/event-bus'], function (exports, _constants, _emberLocalStorage, _eventBus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_eventBus.default, {
    flashMessage: Ember.inject.service(),
    comparableSets: (0, _emberLocalStorage.storageFor)('comparable-sets'),

    comparableSetsFormModel: Ember.Object.create({
      inProcess: true,
      name: "New set"
    }),

    actions: {
      openSelectSetForm: function openSelectSetForm() {
        var model = this.get('comparableSetsFormModel');

        this.get('store').findAll('set').then(function (sets) {
          model.set('inProcess', false);
          model.set('sets', sets.toArray());
        });

        this.get('modal').register(this, _constants.MODALS.COMPARABLE_SETS, 'lots.modal.comparable-sets').open(model);
      },
      setComparableSet: function setComparableSet(set) {
        this.get('comparableSets').set('settings.enabled', true);
        this.get('comparableSets').set('settings.setId', set.get('id'));
        this.send('startWorkWithSet');
      },
      closeSelectSetForm: function closeSelectSetForm() {
        this.get('modal').close(_constants.MODALS.COMPARABLE_SETS);
      },
      createNewSet: function createNewSet(name) {
        var _this = this;

        var model = this.get('comparableSetsFormModel');

        model.set('inProcess', true);
        var set = this.get('store').createRecord('set', { name: name });
        set.save().then(function (set) {
          _this.get('comparableSets').set('settings.enabled', true);
          _this.get('comparableSets').set('settings.setId', set.get('id'));
          _this.get('store').findAll('set').then(function (sets) {
            model.set('inProcess', false);
            model.set('sets', sets.toArray());
            _this.get('flashMessage').showSuccess('Created!');
            _this.send('startWorkWithSet');
          });
        });
      },
      startWorkWithSet: function startWorkWithSet() {
        var _this2 = this;

        var setId = this.get('comparableSets.settings.setId');
        var set = this.get('store').peekRecord('set', setId);
        if (set) {
          this.trigger('comparable-sets.reload', { set: set });
        } else {
          this.get('store').findRecord('set', setId).then(function (set) {
            _this2.trigger('comparable-sets.reload', { set: set });
          });
        }
      }
    }
  });
});