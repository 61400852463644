define('cdata/pods/components/entities/edit/edit-forms-gem-setting/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), {
    item: Ember.computed.alias('model'),

    actions: {}
  });
});