define('cdata/pods/components/people/view/lot-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['item with-image'],

    entity: Ember.computed('model.entityListItems.@each', function () {
      return this.get('model.entityListItems.firstObject');
    })
  });
});