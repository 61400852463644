define('cdata/helpers/format-education', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatEducation = formatEducation;

  var escape = Ember.Handlebars.Utils.escapeExpression;

  function formatEducation(params, hash) {
    var relation = params[0];
    var degrees = hash && hash.degreesLabels;
    var specializations = hash && hash.specializationsLabels;
    var needHtml = typeof params[1] === 'boolean' ? params[1] : true;

    var title = escape(relation.get('_title'));
    var place = escape(relation.get('_place'));
    var date = escape(relation.get('dateText'));
    var degree = degrees && relation.get('academicDegreeVocabularyItemId') ? degrees[relation.get('academicDegreeVocabularyItemId')] : null;
    var specialization = specializations && relation.get('academicSpecializationVocabularyItemId') ? specializations[relation.get('academicSpecializationVocabularyItemId')] : null;

    var result = '';

    if (title) {
      if (needHtml) {
        result += '<span class="strong">' + title + '</span>';
      } else {
        result += title;
      }
    }

    if (place && date) {
      result += '; ' + place + ', ' + date;
    } else if (place || date) {
      result += '; ' + (place || date);
    }

    if (degree && specialization) {
      result += '; ' + degree + ', ' + specialization;
    } else if (degree || specialization) {
      result += '; ' + (degree || specialization);
    }

    if (needHtml) {
      return result.htmlSafe();
    }

    return result;
  }

  exports.default = Ember.Helper.helper(formatEducation);
});