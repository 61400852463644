define('cdata/pods/components/site/list/bulk-operations/forms/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui grid form edit-block'],

    mergeList: Ember.inject.service(),
    prevNextEditor: Ember.inject.service(),

    checkAllVisible: 'checkAllVisible',
    checkAllFiltered: 'checkAllFiltered',
    approve: 'approve',
    unapprove: 'unapprove',
    shown: 'shown',
    hidden: 'hidden',
    selectAssignOption: 'selectAssignOption',
    assignRemove: 'assignRemove',
    cancel: 'cancel',
    addCheckedToMergeList: 'addCheckedToMergeList',
    initPrevNextEditor: 'initPrevNextEditor',

    quantity: 0,
    quantityLabel: 'items',

    merging: false,

    flagMode: 'approve',

    filterAllChecked: false,

    confirmRemove: false,

    hasFilter: false,
    hasCustomFilter: false,

    disableMergeList: Ember.computed('disabled', 'filterAllChecked', 'quantity', function () {
      return this.get('disabled') || this.get('quantity') < 2 || this.get('filterAllChecked');
    }),

    disableRemove: Ember.computed.or('disabled', 'filterAllChecked'),

    quantityForMerge: Ember.computed('quantity', 'disableMergeList', function () {
      return !this.get('disableMergeList') ? this.get('quantity') : '';
    }),

    enableFilterEditor: Ember.computed('quantityLabel', 'hasFilter', 'hasCustomFilter', function () {
      return (this.get('quantityLabel') === 'lots' || this.get('quantityLabel') === 'people' || this.get('quantityLabel') === 'organizations') && (this.get('hasFilter') || this.get('hasCustomFilter'));
    }),

    disableFilterEditorButton: Ember.computed.alias('prevNextEditor.inProcess'),

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      // when quantity changed => cancel from deleting
      var lastQuantity = this.get('_quantity');
      this.set('_quantity', this.get('quantity'));

      if (lastQuantity !== this.get('quantity')) {
        this.set('confirmRemove', false);
      }
    },


    actions: {
      checkAllVisible: function checkAllVisible() {
        this.sendAction('checkAllVisible');
      },
      checkAllFiltered: function checkAllFiltered() {
        this.sendAction('checkAllFiltered');
      },
      approve: function approve() {
        this.sendAction('approve');
      },
      unapprove: function unapprove() {
        this.sendAction('unapprove');
      },
      shown: function shown() {
        this.sendAction('shown');
      },
      hidden: function hidden() {
        this.sendAction('hidden');
      },
      selectAssignOption: function selectAssignOption() {
        this.sendAction.apply(this, ['selectAssignOption'].concat(Array.prototype.slice.call(arguments)));
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      addToMergeList: function addToMergeList() {
        this.sendAction('addCheckedToMergeList', this.get('merging'));
      },
      remove: function remove() {
        this.set('confirmRemove', true);
      },
      confirmRemove: function confirmRemove() {
        this.set('confirmRemove', false);
        this.sendAction('assignRemove');
      },
      cancelRemove: function cancelRemove() {
        this.set('confirmRemove', false);
      },
      initPrevNextEditor: function initPrevNextEditor() {
        this.sendAction('initPrevNextEditor');
      }
    }
  });
});