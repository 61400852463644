define('cdata/pods/reports/styles-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var clickable = {
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8
    }
  };

  var fontAwesome = {
    fontFamily: 'Icons'
  };

  exports.default = {
    label: {
      pointerEvents: 'none'
    },

    iconEnabled: _extends({}, fontAwesome, clickable),

    iconDisabled: _extends({}, fontAwesome, {
      opacity: 0.55
    })
  };
});