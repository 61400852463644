define('cdata/pods/components/places/list/set-results/item/component', ['exports', 'cdata/mixins/lists/set-results-item'], function (exports, _setResultsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_setResultsItem.default, {
    openForm: 'openForm',
    createNewPlace: 'createNewPlace',
    deletePlace: 'deletePlace',

    actions: {
      saveNewPlace: function saveNewPlace(name) {
        this.sendAction('createNewPlace', name, this.get('item.placeId'));
      },
      editPlace: function editPlace() {
        this.sendAction('openForm', this.get('item'));
      },
      deletePlace: function deletePlace() {
        this.sendAction('deletePlace', this.get('item'));
      }
    }
  });
});