define('cdata/helpers/person-summary-description', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.personSummaryDescription = personSummaryDescription;
  function personSummaryDescription(params /*, hash*/) {
    var person = params[0];
    var nationalityLabels = params[1];
    var result = [];

    var date = person.get('personVitalDates').rejectBy('isDeleted').get('firstObject');
    date = date && date.get('text');

    var role = person.get('personRoles').rejectBy('isDeleted').get('firstObject');
    role = role && role.get('role.name');

    var nationality = person.get('personNationalities').rejectBy('isDeleted').get('firstObject');
    nationality = nationality && nationality.get('nationalityVocabularyItemId');
    nationality = nationality && nationalityLabels[nationality];

    if (nationality) {
      result.push(nationality);
    }

    if (role) {
      result.push(role);
    }

    if (date) {
      result.push(date);
    }

    return result.join(', ');
  }

  exports.default = Ember.Helper.helper(personSummaryDescription);
});