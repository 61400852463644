define('cdata/mixins/create-modals/edition', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var eventKey = _constants.MODALS.EDITION + '.saving';

  exports.default = Ember.Mixin.create({
    sidConstants: Ember.inject.service(),

    actions: {
      openNewEditionForm: function openNewEditionForm(title) {
        this.get('modal').register(this, _constants.MODALS.EDITION, 'editions.modal.create').open(Ember.Object.create({
          title: title || ''
        }));
      },
      closeNewEditionForm: function closeNewEditionForm() {
        this.get('modal').close(_constants.MODALS.EDITION);
      },
      saveNewEditionForm: function saveNewEditionForm(form) {
        var _this = this;

        var edition = this.get('store').createRecord('edition', {
          title: form.get('title'),
          size: form.get('size')
        });

        // saving
        var modal = this.get('modal');
        modal.trigger(eventKey + '.process');
        this._handleSavingModal(edition.save(), false).then(function (edition) {
          _this.get('relations').rollbackHasMany(edition);
          modal.trigger(eventKey + '.success', { item: edition });
        }).catch(function (error) {
          modal.trigger(eventKey + '.error', { error: error });
        }).finally(function () {
          modal.trigger(eventKey + '.finally');
        });
      }
    }
  });
});