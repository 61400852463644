define('cdata/mixins/editor/section-field', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNameBindings: ['disabled:disabled', 'hasFieldReview:opened-review'],

    reviewMode: (0, _emberLocalStorage.storageFor)('review-mode'),

    openForms: 'openForms',
    doReview: 'doReview',

    hideEdit: false,

    enabled: true,
    disabled: Ember.computed.not('enabled'),
    disableTitle: 'Disabled for this object type',

    userReviews: null,
    reviewField: null,

    hasFieldReview: Ember.computed('enabledReviewMode', 'userReviews.@each', function () {
      return this.get('enabledReviewMode') && this.hasReview();
    }),

    enabledReview: Ember.computed('userReviews', 'enabledReviewMode', function () {
      return this.get('userReviews') && this.get('userReviews.length') != null && this.get('enabledReviewMode');
    }),

    enabledReviewMode: Ember.computed.alias('reviewMode.settings.enabled'),

    actions: {
      openForms: function openForms() {
        this.sendAction.apply(this, ['openForms'].concat(Array.prototype.slice.call(arguments)));
      },
      doReview: function doReview() {
        this.sendAction.apply(this, ['doReview'].concat(Array.prototype.slice.call(arguments)));
      }
    },

    hasReview: function hasReview() {
      var review = this.getReview(this.get('reviewField'));

      return !!review;
    },
    getReview: function getReview(field) {
      if (this.get('userReviews') === null) {
        return null;
      }

      var review = null;
      this.get('userReviews').forEach(function (item) {
        if (item.field === field) {
          review = item;
        }
      });

      return review;
    }
  });
});