define('cdata/pods/components/subjects/search-and-set/creator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['search-and-set-creator'],

    type: Ember.computed.alias('creator.type'),
    mode: 'person',
    isModePriority: false,
    disabled: false,

    isPerson: Ember.computed('mode', 'modePriority', 'type', function () {
      var type = this.get('isModePriority') ? this.get('mode') : this.get('type');

      return !type || type === 'person';
    }),

    isOrganization: Ember.computed('mode', 'modePriority', 'type', function () {
      var type = this.get('isModePriority') ? this.get('mode') : this.get('type');

      return type === 'organization';
    }),

    createPersonOrOrganization: 'createPersonOrOrganization',

    label: Ember.computed('creator.{label,name}', function () {
      return this.get('creator.name') || this.get('creator.label');
    }),

    actions: {
      changeMode: function changeMode() {
        this.set('isModePriority', true);
        this.set('mode', 'person' === this.get('mode') ? 'organization' : 'person');
      },
      setSubject: function setSubject(_, subject) {
        this.get('handleSet')(_, subject);
      },
      createPersonOrOrganization: function createPersonOrOrganization() {
        this.sendAction.apply(this, ['createPersonOrOrganization'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});