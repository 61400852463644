define('cdata/pods/components/subjects/default-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',

    classNames: ['subject-default-list'],
    classNameBindings: ['active', 'short', 'showType:type'],

    showType: Ember.computed.equal('mode', 'type'),

    results: [],

    active: false,
    short: Ember.computed.equal('mode', 'short'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.element.addEventListener('keydown', this.onKeyDown);
    },
    willDestroyElement: function willDestroyElement() {
      this.element.removeEventListener('keydown', this.onKeyDown);
      this._super.apply(this, arguments);
    },
    onKeyDown: function onKeyDown(event) {
      if (event.key === 'Enter') {
        event.target.click();
      }
    },
    selectItem: function selectItem(item) {
      this.get('handleSelect')(item);
    }
  });
});