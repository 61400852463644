define('cdata/pods/components/controls/input-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Mixin.create({
    enabledSymbols: null,
    maxSymbols: null,
    filterSymbols: null,
    format: null,
    classNameBindings: ['required'],

    input: function input(event) {
      this._handleEvent(event);
    },
    paste: function paste(event) {
      this._handleEvent(event);
    },
    change: function change(event) {
      this._handleEvent(event);
    },
    click: function click() {
      if (typeof this.get('handleClick') === 'function') {
        this.get('handleClick')();
      }
    },
    focusOut: function focusOut() {
      if (typeof this.get('handleFocusOut') === 'function') {
        this.get('handleFocusOut')();
      }
    },
    focusIn: function focusIn() {
      if (typeof this.get('handleFocusIn') === 'function') {
        this.get('handleFocusIn')();
      }
    },
    keyDown: function keyDown(event) {
      if (typeof this.get('handleKeyArrowDown') === 'function') {
        if (event.key === 'ArrowDown' || event.keyCode === 40) {
          this.get('handleKeyArrowDown')();
          event.preventDefault();
        }
      }
      if (typeof this.get('handleKeyArrowUp') === 'function') {
        if (event.key === 'ArrowUp' || event.keyCode === 38) {
          this.get('handleKeyArrowUp')();
          event.preventDefault();
        }
      }
      if (typeof this.get('handleKeyEnter') === 'function') {
        if (event.key === 'Enter' || event.keyCode === 13) {
          this.get('handleKeyEnter')();
          event.preventDefault();
        }
      }
    },


    /**
     * Get new value from element by DOM events
     * @param {Object|String} event
     */
    _handleEvent: function _handleEvent(event) {
      var value = event && (typeof event === 'undefined' ? 'undefined' : _typeof(event)) === 'object' ? event.target.value : event;

      if (typeof value === 'number') {
        value = value.toString();
      }

      if (typeof value !== 'string') {
        value = '';
      }

      value = this._cropByLimit(value);

      var current = this.get('value');

      if (typeof current === 'number') {
        current = current.toString();
      }

      current = typeof current === 'string' ? current : '';

      // fire action about change value
      if (current !== value && this._enabled(value)) {
        value = this._filter(value);
        value = this._format(value);
        this.get('handleChanged')(value);
      }

      Ember.run.schedule('afterRender', this._sync.bind(this));
    },
    _enabled: function _enabled(value) {
      var enabled = true;
      var symbols = this.get('enabledSymbols');
      var max = this.get('maxSymbols');

      // enabled symbols
      if (symbols) {
        if (value && value.length > 0) {
          enabled = new RegExp('^[' + symbols + ']+$').test(value);
        }
      }

      // maximum length
      if (enabled && max) {
        enabled = value.length <= max;
      }

      return enabled;
    },
    _filter: function _filter(value) {
      var filterSymbols = this.get('filterSymbols');

      // enabled symbols
      if (filterSymbols) {
        if (value && value.length > 0) {
          value = value.replace(new RegExp('[^' + filterSymbols + ']+', 'gi'), '');
        }
      }

      return value;
    },
    _format: function _format(value) {
      if (!value || value.length === 0) {
        return value;
      }

      switch (this.get('format')) {
        case 'phone':
          {
            value = value.replace(/^\+?(\d)(\d{3})(\d*)$/gi, '+$1 $2 $3');
            break;
          }

        case 'url':
          {
            if (value.length >= 5) {
              var isPrefix = /^http?s?:?\/?\/?/.test(value);
              if (!isPrefix) {
                value = 'http://' + value;
              }
            }
            break;
          }

        case 'bcYear':
          {
            // only dashes and numeric
            value = value.replace(/[^-\d]+/gi, '');
            if (!value) {
              return '';
            }

            // dash is first and one - normal case
            var normal = /^-?\d+$/.test(value);
            if (normal) {
              return value;
            }

            // more then one dashes - remove all dashes (toggle dash)
            var double = value.match(/-/g).length > 1;
            if (double && value.indexOf('-') === 0) {
              value = value.replace(/[^\d]+/g, '');

              return value;
            }

            // typing one dash to any place - move it to start of
            if (value.indexOf('-', 1) > 0) {
              value = value.replace(/[^\d]+/g, '');
              value = '-' + value;

              return value;
            }

            return value;
          }
      }

      return value;
    },


    /**
     * Check and synchronize DOM value with routed value
     */
    _sync: function _sync() {
      var value = this.get('value');
      var elem = this.element;

      if (!elem) {
        return;
      }
      var current = elem.value;

      if (value !== current) {
        elem.value = value || null;
      }
    },


    /**
     * Get value from component attribute and send it to event handler
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._handleEvent(this.get('value'));
    },


    /**
     * Right crop if limit here
     *
     * @param value
     * @returns {*}
     * @private
     */
    _cropByLimit: function _cropByLimit(value) {
      var limit = parseInt(this.get('limit'));
      if (!limit) {
        return value;
      }

      var size = value.length;
      if (size > limit) {
        value = value.substring(0, limit);
      }

      return value;
    }
  });
});