define('cdata/pods/components/places/mixins/handle_new_place', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modal: Ember.inject.service(),

    openNewPlaceForm: 'openNewPlaceForm',

    actions: {
      createPlace: function createPlace(title) {
        this.sendAction('openNewPlaceForm', title);
        this._subscribeNewPlace();
      }
    },

    _subscribeNewPlace: function _subscribeNewPlace() {
      this.get('modal').on(_constants.MODALS.PLACE + '.saving.success', this, this._onPlaceCreated).on(_constants.MODALS.PLACE + '.close', this, this._unSubscribeNewPlace);
    },
    _unSubscribeNewPlace: function _unSubscribeNewPlace() {
      this.get('modal').off(_constants.MODALS.PLACE + '.saving.success', this, this._onPlaceCreated).off(_constants.MODALS.PLACE + '.close', this, this._unSubscribeNewPlace);
    },
    _onPlaceCreated: function _onPlaceCreated(result) {
      var place = result.item;

      // add place-based subject to list
      this.send('setPlace', this.get('model'), place);
    }
  });
});