define('cdata/mixins/editor/summary-info-user-review', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    reviewMode: (0, _emberLocalStorage.storageFor)('review-mode'),
    requestOnReview: 'requestOnReview',
    closeReview: 'closeReview',
    userReviews: null,

    actions: {
      doReview: function doReview(field) {
        if (this.hasReview(field)) {
          this.sendAction('closeReview', field);
        } else {
          this.sendAction('requestOnReview', field);
        }
      }
    },

    hasReview: function hasReview(field) {
      var review = this.getReview(field);

      return !!review;
    },
    getReview: function getReview(field) {
      if (this.get('userReviews') === null) {
        return null;
      }

      var review = null;
      this.get('userReviews').forEach(function (item) {
        if (item.field === field) {
          review = item;
        }
      });

      return review;
    }
  });
});