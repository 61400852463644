define('cdata/pods/components/site/list/wrap-results/component', ['exports', 'cdata/pods/components/vocabularies/list/set-filters/wrap-actions-mixin'], function (exports, _wrapActionsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_wrapActionsMixin.default, {
    classNames: ['list-wrap-results'],
    tagName: 'main',

    hasResults: Ember.computed.bool('model.length'),

    setFilterParameters: 'setFilterParameters',
    openAssignForm: 'openAssignForm',
    assignRemove: 'assignRemove',
    bulkCheckAll: 'bulkCheckAll',
    bulkCancel: 'bulkCancel',
    gotoPage: 'gotoPage',
    filter: 'filter',
    loadSaleForFilterItem: 'loadSaleForFilterItem',
    openMergeListForm: 'openMergeListForm',
    initPrevNextEditor: 'initPrevNextEditor',

    isBulkEnabled: true,
    inProgress: false,
    hasCustomFilter: false,
    prevNextEditor: null,

    hasFilter: Ember.computed('filterParameters', function () {
      var filter = this.get('filterParameters');

      return filter && Object.entries(filter).length > 0;
    }),

    actions: {
      filter: function filter() {
        this.sendAction.apply(this, ['filter'].concat(Array.prototype.slice.call(arguments)));
      },
      setFilterParameters: function setFilterParameters() {
        this.sendAction.apply(this, ['setFilterParameters'].concat(Array.prototype.slice.call(arguments)));
      },
      gotoPage: function gotoPage() {
        this.sendAction.apply(this, ['gotoPage'].concat(Array.prototype.slice.call(arguments)));
      },
      bulkCancel: function bulkCancel() {
        this.sendAction.apply(this, ['bulkCancel'].concat(Array.prototype.slice.call(arguments)));
      },
      bulkCheckAll: function bulkCheckAll() {
        this.sendAction.apply(this, ['bulkCheckAll'].concat(Array.prototype.slice.call(arguments)));
      },
      openAssignForm: function openAssignForm() {
        this.sendAction.apply(this, ['openAssignForm'].concat(Array.prototype.slice.call(arguments)));
      },
      assignRemove: function assignRemove() {
        this.sendAction.apply(this, ['assignRemove'].concat(Array.prototype.slice.call(arguments)));
      },
      openMergeListForm: function openMergeListForm() {
        this.sendAction.apply(this, ['openMergeListForm'].concat(Array.prototype.slice.call(arguments)));
      },
      saveAssignForm: function saveAssignForm() {
        this.sendAction.apply(this, ['saveAssignForm'].concat(Array.prototype.slice.call(arguments)));
      },
      loadSaleForFilterItem: function loadSaleForFilterItem() {
        this.sendAction.apply(this, ['loadSaleForFilterItem'].concat(Array.prototype.slice.call(arguments)));
      },
      initPrevNextEditor: function initPrevNextEditor() {
        this.sendAction.apply(this, ['initPrevNextEditor'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});