define('cdata/pods/reports/utils', ['exports', 'ramda', 'cdata/pods/reports/controller', 'cdata/pods/components/d3-zoomable-sunburst/utils'], function (exports, _ramda, _controller, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mapNotZeroBy = exports.setupOrganizationLabel = exports.getTitleById = undefined;
  var getTitleById = exports.getTitleById = function getTitleById(collection, id) {
    return collection.find(function (item) {
      return parseInt(item.id) === id;
    }).title;
  };

  var setupOrganizationLabel = exports.setupOrganizationLabel = (0, _ramda.curry)(function (classes, onClick, selection) {
    (0, _utils.setupLabelText)(selection, classes.label);

    var withExternalLink = selection.filter(_controller.hasDetails);

    withExternalLink.filter(function (d) {
      return d.displayValue <= 10000;
    }).append('tspan').text(' \uF35D').attr('class', classes.iconEnabled).on('click', onClick);

    withExternalLink.filter(function (d) {
      return d.displayValue > 10000;
    }).append('tspan').text(' \uF35D').attr('class', classes.iconDisabled).append('title').text('not allow for count greater than 10,000');
  });

  var mapNotZeroBy = exports.mapNotZeroBy = function mapNotZeroBy(field) {
    var getCount = (0, _ramda.prop)(field);

    return function (fn, items) {
      return (0, _ramda.map)(fn, (0, _ramda.filter)(function (item) {
        return getCount(item) > 0;
      }, items));
    };
  };
});