define('cdata/pods/sales/show/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    service: Ember.inject.service('sales/show'),

    lotsCount: Ember.computed('model.sessions.@each.{lotsCount}', function () {
      return this.get('model.sessions').mapBy('lotsCount').reduce(function (pv, cv) {
        return pv + cv;
      }, 0);
    })
  });
});