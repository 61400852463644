define('cdata/pods/reports/route', ['exports', 'cdata/mixins/route-init'], function (exports, _routeInit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeInit.default, {
    model: function model() {
      return {};
    },


    actions: {}
  });
});