define('cdata/pods/components/lots/lot-properties-mixin', ['exports', 'cdata/helpers/format-price'], function (exports, _formatPrice) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    labels: {
      priceHammer: 'Hammer price',
      pricePremium: 'Premium price',
      priceSale: 'Sale price',
      boughtInAt: 'Bought in at',
      priceOpening: 'Opening price',
      priceBuyNow: 'Buy it now price'
    },

    currencyVocabularyItemId: Ember.computed.alias('model.session.sale.currencyVocabularyItemId'),

    estimateFormatted: Ember.computed('price.{estimateLow,estimateHigh}', function () {
      var low = this.get('price.estimateLow');
      var high = this.get('price.estimateHigh');
      if (!low || !high) {
        return '';
      }

      return Ember.String.htmlSafe(_formatPrice.default.compute([low]) + ' &ndash; ' + _formatPrice.default.compute([high]));
    }),

    currency: Ember.computed('currencyVocabularyItemId', function () {
      return this.get('vocab.currenciesLabels')[this.get('currencyVocabularyItemId')];
    }),

    price: Ember.computed('currencyVocabularyItemId', 'model.lotPrices.@each.priceSale', function () {
      var prices = this.get('model.lotPrices').filterBy('currencyVocabularyItemId', this.get('currencyVocabularyItemId'));

      return prices && prices.get('firstObject');
    }),

    prices: Ember.computed('model.lotPrices.[]', function () {
      return this.get('model.lotPrices').rejectBy('currencyVocabularyItemId', this.get('currencyVocabularyItemId'));
    }),

    foundPrice: Ember.computed('price.{priseSale,priceBuyNow,priceOpening}', function () {
      var price = this.get('price');

      return price.get('priseSale') || price.get('priceBuyNow') || price.get('priceOpening');
    }),

    statusLabel: Ember.computed('model.lotStatusVocabularyItemId', function () {
      if (this.get('model.lotStatusVocabularyItemId')) {
        return this.get('vocab.lotStatusesLabels')[this.get('model.lotStatusVocabularyItemId')];
      }

      return '';
    }),

    isSold: Ember.computed('statusLabel', function () {
      return this.get('statusLabel') === 'Sold';
    })
  });
});