define('cdata/transforms/none', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberData.default.Transform;
  exports.default = Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (/^[1-9]+[0-9]+$/.test(deserialized)) {
        deserialized = parseInt(deserialized);
      }

      return deserialized;
    }
  });
});