define('cdata/models/reference-number', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    labelTermId: attr('number'),
    value: attr('string'),
    organization: belongsTo('organization'),
    organizationId: attr('number'),
    person: belongsTo('person'),
    personId: attr('number'),
    source: belongsTo('source'),
    sourceId: attr('number'),
    sourcePage: attr('string'),
    event: belongsTo('event'),
    eventId: attr('number')
  });
});