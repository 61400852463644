define('cdata/pods/reports/components/sales-report/with-switcher/declaration', ['exports', 'cdata/pods/components/two-options-switcher/declaration'], function (exports, _declaration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    value: _declaration.default.value,
    leftLabel: _declaration.default.leftLabel,
    rightLabel: _declaration.default.rightLabel,
    onChange: _declaration.default.onChange
  };
});