define('cdata/pods/components/people/edit/edit-forms-websites/item/component', ['exports', 'cdata/pods/components/people/edit/mixins/edit-forms-item'], function (exports, _editFormsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editFormsItem.default, {
    openVocabularyNewItemForm: 'openVocabularyNewItemForm',

    actions: {
      openVocabularyNewItemForm: function openVocabularyNewItemForm() {
        this.sendAction.apply(this, ['openVocabularyNewItemForm'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});