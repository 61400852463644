define('cdata/pods/application/route', ['exports', 'cdata/mixins/route-type-page'], function (exports, _routeTypePage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeTypePage.default, {
    session: Ember.inject.service(),
    transitionSpot: Ember.inject.service(),

    actions: {
      logout: function logout() {
        this.get('session').removeKey();
        this.get('transitionSpot').clear();
        this.transitionTo('init.login');
      }
    }
  });
});