define('cdata/mixins/lists/set-results-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['row', 'hoverable'],
    classNameBindings: ['checked', 'merged', 'deleted'],
    attributeBindings: ['data-id'],

    bulkCheckItem: 'bulkCheckItem',
    saveItem: 'saveItem',

    imageUrl: Ember.computed.alias('item.imageUrl'),

    merged: Ember.computed.alias('item._merged'),
    checked: Ember.computed.alias('item._checked'),
    deleted: Ember.computed.or('item.isDeleted', 'item._deleted'),
    passive: Ember.computed.or('merged', 'deleted'),

    actions: {
      bulkCheckItem: function bulkCheckItem() {
        this.sendAction.apply(this, ['bulkCheckItem'].concat(Array.prototype.slice.call(arguments)));
      },
      saveItem: function saveItem(item) {
        this.sendAction('saveItem', item);
      },
      gotoEditMode: function gotoEditMode(item) {
        item.set('_edit', true);
      },
      offEditMode: function offEditMode(item) {
        item.set('_edit', false);
      }
    },

    save: function save() {
      this.sendAction('saveItem', this.get('item'));
    }
  });
});