define("cdata/mirage/datasets/entities", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    '32894740': {
      "images": [{
        "url": "https://cdata-images-production.s3.amazonaws.com/collectrium/stokstad/models/assets/attachments/5866/e469/4f66/9e0e/c901/0f5a/original/5866e4694f669e0ec9010f5a.jpg",
        "source": null,
        "original_url": "https://doyle.com/sites/default/files/images/lots/2002-09-25/323400.jpg",
        "id": 12482015,
        "caption": null
      }],
      "entity_titles": [{
        "text": "Set of Venetian Glass Stemware Approximately eighteen pieces.",
        "source_page": null,
        "source_id": null,
        "preferred": true,
        "language": "US-EN",
        "id": 32843951,
        "entity_id": 32894740
      }],
      "entity_provenances": [],
      "entity_measurements": [],
      "entity_measurement_values": [],
      "entity_materials": [],
      "entity_material_terms": [],
      "entity_lots": [{
        "lot_id": 33237174,
        "id": 32780063,
        "entity_id": 32894740
      }],
      "entity_literatures": [],
      "entity_inscriptions": [],
      "entity_images": [{
        "preferred": true,
        "image_id": 12482015,
        "id": 25496291,
        "entity_id": 32894740
      }],
      "entity_exhibitions": [],
      "entity_editions": [],
      "entity_descriptions": [{
        "text": "Set of Venetian Glass Stemware\nApproximately eighteen pieces.",
        "preferred": true,
        "id": 29676041,
        "entity_id": 32894740
      }],
      "entity_creators": [],
      "entity_creation_dates": [],
      "entity": {
        "v1_object_id": "5866e3c64f669e0ec901094d",
        "shown": true,
        "mi_category_vocabulary_item_id": null,
        "id": 32894740,
        "entity_title_ids": [32843951],
        "entity_provenance_ids": [],
        "entity_measurement_ids": [],
        "entity_material_ids": [],
        "entity_lot_ids": [32780063],
        "entity_literature_ids": [],
        "entity_inscription_ids": [],
        "entity_image_ids": [25496291],
        "entity_form_vocabulary_item_id": null,
        "entity_exhibition_ids": [],
        "entity_edition_ids": [],
        "entity_description_ids": [29676041],
        "entity_creator_ids": [],
        "entity_creation_date_ids": [],
        "christies_business_group_vocabulary_item_id": null,
        "cdata_category_vocabulary_item_id": 524
      }
    },
    '33581049': {
      "images": [],
      "entity_titles": [],
      "entity_provenances": [],
      "entity_measurements": [],
      "entity_measurement_values": [],
      "entity_materials": [],
      "entity_material_terms": [],
      "entity_lots": [{
        "lot_id": 33237174,
        "id": 33464437,
        "entity_id": 33581049
      }],
      "entity_literatures": [],
      "entity_inscriptions": [],
      "entity_images": [],
      "entity_exhibitions": [],
      "entity_editions": [],
      "entity_descriptions": [],
      "entity_creators": [],
      "entity_creation_dates": [],
      "entity": {
        "v1_object_id": null,
        "shown": true,
        "mi_category_vocabulary_item_id": null,
        "id": 33581049,
        "entity_title_ids": [],
        "entity_provenance_ids": [],
        "entity_measurement_ids": [],
        "entity_material_ids": [],
        "entity_lot_ids": [33464437],
        "entity_literature_ids": [],
        "entity_inscription_ids": [],
        "entity_image_ids": [],
        "entity_form_vocabulary_item_id": null,
        "entity_exhibition_ids": [],
        "entity_edition_ids": [],
        "entity_description_ids": [],
        "entity_creator_ids": [],
        "entity_creation_date_ids": [],
        "christies_business_group_vocabulary_item_id": null,
        "cdata_category_vocabulary_item_id": null
      }
    },
    '33581044': {
      "images": [],
      "entity_titles": [{
        "text": "Object title test",
        "source_page": null,
        "source_id": null,
        "preferred": true,
        "language": "en",
        "id": 33529597,
        "entity_id": 33581044
      }],
      "entity_provenances": [],
      "entity_measurements": [],
      "entity_measurement_values": [],
      "entity_materials": [],
      "entity_material_terms": [],
      "entity_lots": [{
        "lot_id": 33237174,
        "id": 33464432,
        "entity_id": 33581044
      }],
      "entity_literatures": [],
      "entity_inscriptions": [],
      "entity_images": [],
      "entity_exhibitions": [],
      "entity_editions": [],
      "entity_descriptions": [],
      "entity_creators": [],
      "entity_creation_dates": [],
      "entity": {
        "v1_object_id": null,
        "shown": true,
        "mi_category_vocabulary_item_id": null,
        "id": 33581044,
        "entity_title_ids": [33529597],
        "entity_provenance_ids": [],
        "entity_measurement_ids": [],
        "entity_material_ids": [],
        "entity_lot_ids": [33464432],
        "entity_literature_ids": [],
        "entity_inscription_ids": [],
        "entity_image_ids": [],
        "entity_form_vocabulary_item_id": null,
        "entity_exhibition_ids": [],
        "entity_edition_ids": [],
        "entity_description_ids": [],
        "entity_creator_ids": [],
        "entity_creation_date_ids": [],
        "christies_business_group_vocabulary_item_id": null,
        "cdata_category_vocabulary_item_id": null
      }
    },
    '33581048': {
      "images": [],
      "entity_titles": [{
        "text": "Test object title",
        "source_page": null,
        "source_id": null,
        "preferred": true,
        "language": "en",
        "id": 33529598,
        "entity_id": 33581048
      }],
      "entity_provenances": [],
      "entity_measurements": [],
      "entity_measurement_values": [],
      "entity_materials": [],
      "entity_material_terms": [],
      "entity_lots": [{
        "lot_id": 33237174,
        "id": 33464436,
        "entity_id": 33581048
      }],
      "entity_literatures": [],
      "entity_inscriptions": [],
      "entity_images": [],
      "entity_exhibitions": [],
      "entity_editions": [],
      "entity_descriptions": [],
      "entity_creators": [],
      "entity_creation_dates": [],
      "entity": {
        "v1_object_id": null,
        "shown": true,
        "mi_category_vocabulary_item_id": null,
        "id": 33581048,
        "entity_title_ids": [33529598],
        "entity_provenance_ids": [],
        "entity_measurement_ids": [],
        "entity_material_ids": [],
        "entity_lot_ids": [33464436],
        "entity_literature_ids": [],
        "entity_inscription_ids": [],
        "entity_image_ids": [],
        "entity_form_vocabulary_item_id": null,
        "entity_exhibition_ids": [],
        "entity_edition_ids": [],
        "entity_description_ids": [],
        "entity_creator_ids": [],
        "entity_creation_date_ids": [],
        "christies_business_group_vocabulary_item_id": null,
        "cdata_category_vocabulary_item_id": null
      }
    },
    '17048279': {
      "images": [],
      "entity_titles": [{
        "text": "Entrance",
        "source_page": null,
        "source_id": null,
        "preferred": true,
        "language": "US-EN",
        "id": 17022463,
        "entity_id": 17048279
      }],
      "entity_provenances": [{
        "text": "Acquired directly from the artist by the present owner in 1998.",
        "preferred": true,
        "id": 1161739,
        "entity_id": 17048279
      }],
      "entity_measurements": [{
        "text": "14 x 12in. (35.6 x 30.5cm.)",
        "preferred": true,
        "id": 8141730,
        "entity_measurement_value_ids": [13757974, 13757973],
        "entity_id": 17048279
      }],
      "entity_measurement_values": [{
        "value_normalized": null,
        "value": "12.00",
        "unit_vocabulary_item_id": 445,
        "type_vocabulary_item_id": 861,
        "id": 13757974,
        "entity_measurement_id": 8141730
      }, {
        "value_normalized": null,
        "value": "14.00",
        "unit_vocabulary_item_id": 445,
        "type_vocabulary_item_id": 860,
        "id": 13757973,
        "entity_measurement_id": 8141730
      }],
      "entity_materials": [{
        "text": "oil on canvas",
        "preferred": true,
        "id": 3680344,
        "entity_material_term_ids": [],
        "entity_id": 17048279
      }],
      "entity_material_terms": [],
      "entity_lots": [{ "lot_id": 17261095, "id": 16990566, "entity_id": 17048279 }],
      "entity_literatures": [{ "text": "NULL", "preferred": true, "id": 465630, "entity_id": 17048279 }],
      "entity_inscriptions": [],
      "entity_images": [],
      "entity_exhibitions": [{
        "text": "Kiel, Kunsthalle zu Kiel, Peter Doig Blizzard seventy-seven, 1998. This exhibition later travelled to Nuremberg, Kunsthalle Nuremberg and London, Whitechapel Gallery.",
        "preferred": true,
        "id": 284204,
        "entity_id": 17048279
      }],
      "entity_editions": [],
      "entity_descriptions": [{
        "text": "Dating from 1998, Entrance is a painting on an intimate scale which was included in Peter Doig's seminal exhibition Blizard Seventy-Seven the same year. The exhibition, which was shown in the Kunsthalle, Kiel, the Kunsthalle Nürnberg and the Whitechapel G",
        "preferred": false,
        "id": 15352025,
        "entity_id": 17048279
      }],
      "entity_creators": [],
      "entity_creation_dates": [{
        "text": "Painted in 1998",
        "start_year": 1998,
        "preferred": true,
        "id": 4949112,
        "finish_year": 1998,
        "entity_id": 17048279
      }],
      "entity": {
        "v1_object_id": "5473696fd97667ea7416329c",
        "shown": true,
        "mi_category_vocabulary_item_id": 28,
        "id": 17048279,
        "entity_title_ids": [17022463],
        "entity_provenance_ids": [1161739],
        "entity_measurement_ids": [8141730],
        "entity_material_ids": [3680344],
        "entity_lot_ids": [16990566],
        "entity_literature_ids": [465630],
        "entity_inscription_ids": [],
        "entity_image_ids": [],
        "entity_form_vocabulary_item_id": 384,
        "entity_exhibition_ids": [284204],
        "entity_edition_ids": [],
        "entity_description_ids": [15352025],
        "entity_creator_ids": [],
        "entity_creation_date_ids": [4949112],
        "christies_business_group_vocabulary_item_id": 88,
        "cdata_category_vocabulary_item_id": 538
      }
    }
  };
});