define('cdata/mixins/create-modals/person', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sidConstants: Ember.inject.service(),

    actions: {
      createArtist: function createArtist() {
        this.get('modal').register(this, _constants.MODALS.PERSON, 'people.modal.new').open(Ember.Object.create());
      },
      closeNewPersonForm: function closeNewPersonForm() {
        this.get('modal').close(_constants.MODALS.PERSON);
      },
      openNewPersonForm: function openNewPersonForm(name, disableFollow) {
        disableFollow = disableFollow === true;
        name = name || '';
        var model = Ember.Object.create({
          initialName: name,
          disableFollow: disableFollow
        });
        this.get('modal').register(this, _constants.MODALS.PERSON, 'people.modal.new').open(model);
      },
      openConversionPersonForm: function openConversionPersonForm(person) {
        var model = Ember.Object.create({
          person: person
        });
        this.get('modal').register(this, _constants.MODALS.PERSON_CONVERSION, 'people.modal.conversion').open(model);
      },
      closeConversionPersonForm: function closeConversionPersonForm() {
        this.get('modal').close(_constants.MODALS.PERSON_CONVERSION);
      },
      openNewPersonOrOrganizationForm: function openNewPersonOrOrganizationForm(name) {
        var model = Ember.Object.create({
          initialName: name
        });
        this.get('modal').register(this, _constants.MODALS.PERSON_OR_ORGANIZATION, 'people.modal.person-or-organization').open(model);
      },
      closeNewPersonOrOrganizationForm: function closeNewPersonOrOrganizationForm() {
        this.get('modal').close(_constants.MODALS.PERSON_OR_ORGANIZATION);
      },
      openNewOrganizationFormInsteadChoice: function openNewOrganizationFormInsteadChoice(name) {
        this.send('closeNewPersonOrOrganizationForm');
        this.send('openNewOrganizationForm', name, true);
      },
      openNewPersonFormInsteadChoice: function openNewPersonFormInsteadChoice(name) {
        this.send('closeNewPersonOrOrganizationForm');
        this.send('openNewPersonForm', name, true);
      },
      saveNewPersonForm: function saveNewPersonForm(form, isFollow) {
        var _this = this;

        isFollow = isFollow === true;
        var data = form.get('personData');
        var person = this.get('store').createRecord('person');

        var modal = this.get('modal');
        var key = _constants.MODALS.PERSON + '.saving';
        modal.trigger(key + '.process');

        this._handleSavingModal(person.save(), false).then(function () {
          var promises = [];

          promises.push(_this._saveNewPersonName(person, data));
          promises.push(_this._saveNewPersonNameAlter(person, data));
          promises.push(_this._saveNewPersonVitalDate(person, data));
          promises.push(_this._saveNewPersonRole(person, data));
          promises.push(_this._saveNewPersonNationality(person, data));

          modal.trigger(key + '.success', { item: person });

          if (isFollow) {
            _this._handleSavingModal(promises, false).finally(function () {
              _this.get('controller').transitionToRoute('people.show', person.get('id'));
            });
          }
        }).catch(function (error) {
          modal.trigger(key + '.error', { error: error });
        }).finally(function () {
          modal.trigger(key + '.finally');
        });
      }
    },

    _saveNewPersonName: function _saveNewPersonName(person, data) {
      if (!data.get('name')) {
        return;
      }

      var item = this.get('store').createRecord('person-name', {
        text: data.get('name'),
        person: person,
        preferred: true,
        nameLabelVocabularyItemId: this.get('sidConstants.defaultNameLabelVocabularyItemId'),
        citationLabelVocabularyItemId: data.get('citationLabelVocabularyItemId')
      });

      person.get('personNames').pushObject(item);

      return item.save();
    },
    _saveNewPersonNameAlter: function _saveNewPersonNameAlter(person, data) {
      if (!data.get('nameAlter')) {
        return;
      }

      var item = this.get('store').createRecord('person-name', {
        text: data.get('nameAlter'),
        person: person,
        preferred: false,
        nameLabelVocabularyItemId: this.get('sidConstants.defaultNameLabelVocabularyItemId'),
        citationLabelVocabularyItemId: data.get('citationLabelVocabularyItemId')
      });

      person.get('personNames').pushObject(item);

      return item.save();
    },
    _saveNewPersonVitalDate: function _saveNewPersonVitalDate(person, data) {
      if (!data.get('text')) {
        return;
      }

      var item = this.get('store').createRecord('person-vital-date', {
        text: data.get('text'),
        person: person,
        startYear: data.get('startYear'),
        finishYear: data.get('finishYear'),
        preferred: true,
        citationLabelVocabularyItemId: data.get('citationLabelVocabularyItemId')
      });

      person.get('personVitalDates').pushObject(item);

      return item.save();
    },
    _saveNewPersonRole: function _saveNewPersonRole(person, data) {
      if (!data.get('roleVocabularyItemId')) {
        return;
      }

      var item = this.get('store').createRecord('person-role', {
        roleId: data.get('roleVocabularyItemId'),
        role: this.get('store').peekRecord('role', data.get('roleVocabularyItemId')),
        person: person,
        preferred: true,
        citationLabelVocabularyItemId: data.get('citationLabelVocabularyItemId')
      });

      person.get('personRoles').pushObject(item);

      return item.save();
    },
    _saveNewPersonNationality: function _saveNewPersonNationality(person, data) {
      if (!data.get('nationalityVocabularyItemId')) {
        return;
      }

      var item = this.get('store').createRecord('person-nationality', {
        nationalityVocabularyItemId: data.get('nationalityVocabularyItemId'),
        person: person,
        preferred: true,
        citationLabelVocabularyItemId: data.get('citationLabelVocabularyItemId')
      });

      person.get('personNationalities').pushObject(item);

      return item.save();
    }
  });
});