define('cdata/pods/components/sales/view/sale-item/summary-info/component', ['exports', 'moment', 'cdata/mixins/components/summary-info-comments'], function (exports, _moment, _summaryInfoComments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_summaryInfoComments.default, {
    classNames: 'sale-item--info',
    tagName: 'article',

    openSaleForms: 'openSaleForms',
    loadOrganizationFor: 'loadOrganizationFor',

    actions: {
      openForms: function openForms() {
        this.sendAction.apply(this, ['openSaleForms'].concat(Array.prototype.slice.call(arguments)));
      },
      loadOrganizationFor: function loadOrganizationFor() {
        this.sendAction.apply(this, ['loadOrganizationFor'].concat(Array.prototype.slice.call(arguments)));
      }
    },

    preferredTitle: Ember.computed('model.saleTitles.@each.{preferred,text}', function () {
      var title = this.get('model.saleTitles').filterBy('preferred').get('firstObject');

      return title && title.get('text');
    }),

    otherTitles: Ember.computed('model.saleTitles.@each.{preferred,text}', function () {
      var titles = this.get('model.saleTitles').rejectBy('preferred');

      return titles.mapBy('text');
    }),

    preferredDescription: Ember.computed('model.saleDescriptions.@each.{preferred,text}', function () {
      var description = this.get('model.saleDescriptions').filterBy('preferred').get('firstObject');

      return description && description.get('text');
    }),

    otherDescriptions: Ember.computed('model.saleDescriptions.@each.{preferred,text}', function () {
      var descriptions = this.get('model.saleDescriptions').rejectBy('preferred');

      return descriptions.mapBy('text');
    }),

    firstLot: Ember.computed('model.sessions.@each', function () {
      return this.get('model.sessions.firstObject.firstLotNumber');
    }),

    lastLot: Ember.computed('model.sessions.@each', function () {
      return this.get('model.sessions.lastObject.lastLotNumber');
    }),

    lotsCount: Ember.computed('model.sessions.@each', function () {
      var qnt = 0;
      this.get('model.sessions').forEach(function (item) {
        qnt += item.get('lotsCount');
      });

      return qnt;
    }),

    locations: Ember.computed('model.sessions.@each._locationName', function () {
      this._loadSessionsLocations();

      return this.get('model.sessions').mapBy('_locationName').uniq().join(', ');
    }),

    channels: Ember.computed('model.sessions.@each.channelVocabularyItemId', function () {
      var _this = this;

      var channelTerms = this.get('model.sessions').mapBy('channelVocabularyItemId').uniq();

      return channelTerms.map(function (item) {
        return _this.get('vocab.saleChannelsLabels')[item];
      }).join(', ');
    }),

    organizationTitle: Ember.computed('model.organization.organizationTitles.@each.{preferred,text}', function () {
      var organization = this.get('model.organization');

      if (!organization) {
        return;
      }

      var titles = organization.get('organizationTitles');

      if (!titles) {
        return;
      }

      var title = titles.filterBy('preferred').get('firstObject.text');
      organization.set('_title', title);

      return title;
    }),

    statuses: Ember.computed('model.sessions.@each.sessionStatusVocabularyItemId', function () {
      var _this2 = this;

      var channelTerms = this.get('model.sessions').mapBy('sessionStatusVocabularyItemId').uniq();

      return channelTerms.map(function (item) {
        return _this2.get('vocab.sessionStatusesLabels')[item];
      }).join(', ');
    }),

    date: Ember.computed('model.sessions.@each.{startDate,finishDate}', function () {
      var startDate = this.get('model.sessions.firstObject.startDate');
      var finishDate = this.get('model.sessions.lastObject.finishDate');

      if (startDate === finishDate) {
        return (0, _moment.default)(startDate).format('ll');
      }

      return ((0, _moment.default)(startDate).format('ll') + ' &ndash; ' + (0, _moment.default)(finishDate).format('ll')).htmlSafe();
    }),

    currencySymbol: Ember.computed('vocab.currenciesLabels', 'model.currencyVocabularyItemId', function () {
      var labels = this.get('vocab.currenciesLabels');
      var itemId = this.get('model.currencyVocabularyItemId');

      return labels && labels[itemId];
    }),

    _loadSessionsLocations: function _loadSessionsLocations() {
      this.get('model.sessions').forEach(function (session) {
        session.get('location').then(function (location) {
          session.set('_locationName', location.get('locationTitles.firstObject.text'));
        });
      });
    }
  });
});