define('cdata/mixins/lists/component-sort-box', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['sort-box'],
    classNameBindings: ['active'],

    changeSorting: 'changeSorting',

    values: [],

    active: Ember.computed.bool('sorting._field'),

    activeDirection: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('sorting').on('changed', function (data) {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.set('activeDirection', data && data.direction);
        }
      });
    },


    activeTitle: Ember.computed('sorting._field', function () {
      var field = this.get('sorting._field');
      var title = '';

      this.get('values').forEach(function (option) {
        if (field === option.get('key')) {
          title = option.get('title');
        }
      });

      return title;
    }),

    actions: {
      orderChanged: function orderChanged(key, direction) {
        var field = this.get('sorting.' + key);

        if (undefined === field) {
          field = this.get('sorting').setField(false, key, false, true);
        }

        this.sendAction('changeSorting', true, key, direction === 'asc');
      },
      orderCancel: function orderCancel() {
        this.get('sorting').cleanup();
        this.sendAction('changeSorting', false, undefined);
      }
    }
  });
});