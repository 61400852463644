define('cdata/pods/components/lots/edit/field-flags/component', ['exports', 'cdata/constants', 'cdata/pods/components/lots/edit/mixins/field-form'], function (exports, _constants, _fieldForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fieldForm.default, {
    flags: Ember.computed(function () {
      return _constants.LOT_FLAGS;
    }),

    actions: {
      toggleFlag: function toggleFlag(key) {
        var path = 'model.' + key;
        this.set(path, this.get(path) !== true);
      }
    }
  });
});