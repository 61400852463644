define('cdata/pods/components/people/modals/create-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var eventKey = _constants.MODALS.PERSON + '.saving';

  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.PERSON,
    classNames: ['context-modal', _constants.MODALS.PERSON, 'edit-form'],
    roleLabels: Ember.computed.alias('vocab.rolesLabels'),
    nationalityLabels: Ember.computed.alias('vocab.nationalitiesLabels'),
    inProcess: false,

    isEnabled: Ember.computed.and('person.name'),
    isDisabled: Ember.computed.not('isEnabled'),

    defaultData: {
      name: '',
      nameAlter: '',
      startYear: '',
      finishYear: '',
      text: '',
      roleVocabularyItemId: null,
      nationalityVocabularyItemId: null,
      citationLabelVocabularyItemId: null
    },

    actions: {
      setRole: function setRole(person, roleSubject) {
        this.set('person.roleVocabularyItemId', roleSubject.get('id'));
      },
      setNationality: function setNationality(person, nationalitySubject) {
        this.set('person.nationalityVocabularyItemId', nationalitySubject.get('id'));
      },
      save: function save() {
        var personData = this.get('person');
        var model = this.get('model');
        model.set('personData', personData);
        this.sendAction('saveNewPersonForm', model);
      },
      follow: function follow() {
        var personData = this.get('person');
        var model = this.get('model');
        model.set('personData', personData);
        this.sendAction('saveNewPersonForm', model, true);
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeNewPersonForm');
      }
    },

    _initPerson: function _initPerson() {
      var person = Ember.Object.create(this.get('defaultData'));
      if (this.get('model.initialName')) {
        person.set('name', this.get('model.initialName'));
      }
      this.set('person', person);
    },
    _onProcess: function _onProcess() {
      this.set('inProcess', true);
    },
    _onFinally: function _onFinally() {
      this.set('inProcess', false);
    },
    _onSuccess: function _onSuccess() {
      this.send('close');
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this._initPerson();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('modal').on(eventKey + '.process', this, this._onProcess).on(eventKey + '.success', this, this._onSuccess).on(eventKey + '.finally', this, this._onFinally);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      this.get('modal').off(eventKey + '.process', this, this._onProcess).off(eventKey + '.success', this, this._onSuccess).off(eventKey + '.finally', this, this._onFinally);
    }
  });
});