define('cdata/models/person', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    vocab: Ember.inject.service(),

    _name: attr('string'),
    _description: attr('string'),
    _lotsCount: attr('number'),
    createdAt: attr('string'),
    updatedAt: attr('string'),
    gender: attr('string'),
    disambiguatingComment: attr('string'),
    personAddresses: hasMany('person-address'),
    personNames: hasMany('person-name'),
    personRoles: hasMany('person-role'),
    personNationalities: hasMany('person-nationality'),
    personBiographies: hasMany('person-biography'),
    personVitalDates: hasMany('person-vital-date'),
    personActivityDates: hasMany('person-activity-date'),
    personImages: hasMany('person-image'),
    personBirthPlaces: hasMany('person-birth-place'),
    personDeathPlaces: hasMany('person-death-place'),
    personRelatedPublications: hasMany('person-related-publication'),
    personAwards: hasMany('person-award'),
    personRelationships: hasMany('person-relationship'),
    personPhones: hasMany('person-phone'),
    personWebsites: hasMany('person-website'),
    personEmails: hasMany('person-email'),
    personOrganizations: hasMany('person-organization'),
    personPublicCollections: hasMany('person-public-collection'),
    personOrganizationMemberships: hasMany('person-organization-membership'),
    personOrganizationEducations: hasMany('person-organization-education'),
    personJdeDepartments: hasMany('person-jde-department'),
    personChristiesSpecialistDepartments: hasMany('person-christies-specialist-department'),
    uuid: attr('string'),
    approved: attr('boolean'),

    personRole: Ember.computed('personRoles.@each.{preferred,role_id}', function () {
      return this.get('personRoles').findBy('preferred');
    }),

    personNationality: Ember.computed('personNationalities.@each.{preferred,nationalityVocabularyItemId}', function () {
      return this.get('personNationalities').findBy('preferred');
    }),

    name: Ember.computed('personNames.@each.{preferred,text}', function () {
      var name = this.get('personNames').findBy('preferred');

      return name && name.get('text');
    }),

    role: Ember.computed('personRole.roleId', function () {
      var roleId = this.get('personRole.roleId');

      return roleId && this.get('vocab.rolesLabels')[roleId];
    }),

    nationality: Ember.computed('personNationality.nationalityVocabularyItemId', function () {
      var nationalityId = this.get('personNationality.nationalityVocabularyItemId');

      return nationalityId && this.get('vocab.nationalitiesLabels')[nationalityId];
    }),

    dates: Ember.computed('personVitalDates.@each.{preferred,text}', function () {
      var dates = this.get('personVitalDates').findBy('preferred');

      return dates && dates.get('text');
    }),

    longName: Ember.computed('name', 'role', 'nationality', 'dates', function () {
      var parts = [];

      if (this.get('name')) {
        parts.push(this.get('name'));
      }

      if (this.get('role')) {
        parts.push(this.get('role'));
      }

      if (this.get('nationality')) {
        parts.push(this.get('nationality'));
      }

      if (this.get('dates')) {
        parts.push(this.get('dates'));
      }

      return parts.join(', ');
    })
  });
});