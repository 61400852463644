define('cdata/mixins/create-modals/organization', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sidConstants: Ember.inject.service(),

    actions: {
      createOrganization: function createOrganization() {
        this.get('modal').register(this, _constants.MODALS.ORGANIZATION, 'organizations.modal.new').open(Ember.Object.create());
      },
      closeNewOrganizationForm: function closeNewOrganizationForm() {
        this.get('modal').close(_constants.MODALS.ORGANIZATION);
      },
      openNewOrganizationForm: function openNewOrganizationForm(title, disableFollow) {
        disableFollow = disableFollow === true;
        title = title || '';
        var model = Ember.Object.create({
          initialTitle: title,
          disableFollow: disableFollow
        });
        this.get('modal').register(this, _constants.MODALS.ORGANIZATION, 'organizations.modal.new').open(model);
      },
      saveNewOrganizationForm: function saveNewOrganizationForm(form, isFollow) {
        var _this = this;

        isFollow = isFollow === true;
        var data = form.get('organizationData');
        var organization = this.get('store').createRecord('organization');

        var modal = this.get('modal');
        var key = _constants.MODALS.ORGANIZATION + '.saving';
        modal.trigger(key + '.process');
        this._handleSavingModal(organization.save(), false).then(function (organization) {
          var promises = [];

          promises.push(_this._saveNewOrganizationTitle(organization, data));
          promises.push(_this._saveNewOrganizationTitleAlter(organization, data));
          promises.push(_this._saveNewOrganizationDate(organization, data));
          promises.push(_this._saveNewOrganizationRole(organization, data));
          promises.push(_this._saveNewOrganizationNationality(organization, data));

          modal.trigger(key + '.success', { item: organization });

          if (isFollow) {
            _this._handleSavingModal(promises, false).finally(function () {
              _this.transitionTo('organizations.show', organization.get('id'));
            });
          }
        }).catch(function (error) {
          modal.trigger(key + '.error', { error: error });
        }).finally(function () {
          modal.trigger(key + '.finally');
        });
      },
      openConversionOrganizationForm: function openConversionOrganizationForm(organization) {
        var model = Ember.Object.create({
          organization: organization
        });
        this.get('modal').register(this, _constants.MODALS.ORGANIZATION_CONVERSION, 'organizations.modal.conversion').open(model);
      },
      closeConversionOrganizationForm: function closeConversionOrganizationForm() {
        this.get('modal').close(_constants.MODALS.ORGANIZATION_CONVERSION);
      }
    },

    _saveNewOrganizationTitle: function _saveNewOrganizationTitle(organization, data) {
      if (!data.get('title')) {
        return;
      }

      var item = this.get('store').createRecord('organization-title', {
        text: data.get('title'),
        organization: organization,
        preferred: true,
        citationLabelVocabularyItemId: data.get('citationLabelVocabularyItemId')
      });
      organization.get('organizationTitles').pushObject(item);

      return item.save();
    },
    _saveNewOrganizationTitleAlter: function _saveNewOrganizationTitleAlter(organization, data) {
      if (!data.get('titleAlter')) {
        return;
      }

      var item = this.get('store').createRecord('organization-title', {
        text: data.get('titleAlter'),
        organization: organization,
        preferred: false,
        citationLabelVocabularyItemId: data.get('citationLabelVocabularyItemId')
      });
      organization.get('organizationTitles').pushObject(item);

      return item.save();
    },
    _saveNewOrganizationDate: function _saveNewOrganizationDate(organization, data) {
      if (!data.get('text')) {
        return;
      }

      var item = this.get('store').createRecord('organization-existence-date', {
        text: data.get('text'),
        organization: organization,
        startYear: data.get('startYear'),
        finishYear: data.get('finishYear'),
        preferred: true,
        citationLabelVocabularyItemId: data.get('citationLabelVocabularyItemId')
      });
      organization.get('organizationExistenceDates').pushObject(item);

      return item.save();
    },
    _saveNewOrganizationRole: function _saveNewOrganizationRole(organization, data) {
      if (!data.get('roleVocabularyItemId')) {
        return;
      }

      var item = this.get('store').createRecord('organization-role', {
        roleId: data.get('roleVocabularyItemId'),
        role: this.get('store').peekRecord('role', data.get('roleVocabularyItemId')),
        organization: organization,
        preferred: true,
        citationLabelVocabularyItemId: data.get('citationLabelVocabularyItemId')
      });
      organization.get('organizationRoles').pushObject(item);

      return item.save();
    },
    _saveNewOrganizationNationality: function _saveNewOrganizationNationality(organization, data) {
      if (!data.get('nationalityVocabularyItemId')) {
        return;
      }

      var item = this.get('store').createRecord('organization-nationality', {
        nationalityVocabularyItemId: data.get('nationalityVocabularyItemId'),
        organization: organization,
        preferred: true,
        citationLabelVocabularyItemId: data.get('citationLabelVocabularyItemId')
      });
      organization.get('organizationNationalities').pushObject(item);

      return item.save();
    }
  });
});