define('cdata/pods/components/subjects/mixins/search-and-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    active: Ember.computed('search.active', 'searchOnFocus', 'focus', function () {
      return this.get('search.active') || this.get('searchOnFocus') && this.get('focus');
    }),

    subjectName: null,

    subjectFilter: {},
    searchOnFocus: false,

    openVocabularyNewItemForm: 'openVocabularyNewItemForm',

    actions: {
      search: function search(query) {
        this.set('search.excludeIds', this.get('excludeIds'));
        this.get('search').doSearch(query, this.get('subjectFilter'), this.get('searchOnFocus'));
        this.$().find('.subject-search-results').fadeIn();
      },
      cancel: function cancel() {
        this.set('focus', false);
        if (!this.get('active')) {
          return;
        }
        this.hideResultList();
      },
      up: function up() {
        this.get('search').selectUp();
      },
      down: function down() {
        this.get('search').selectDown();
      },
      enter: function enter() {
        var item = this.get('search').getSelected();
        if (!item) {
          item = this.get('search').setFirst();

          return;
        }
        if (item) {
          this._handleSelect(item);
          this.hideResultList();
        }
      },
      onFocus: function onFocus() {
        this.set('focus', true);

        if (this.get('searchOnFocus')) {
          this.send('search', this.get('query'));
        }
      },
      openVocabularyNewItemForm: function openVocabularyNewItemForm() {
        this.sendAction.apply(this, ['openVocabularyNewItemForm'].concat(Array.prototype.slice.call(arguments)));
      }
    },

    hideResultList: function hideResultList() {
      var _this = this;

      var $elem = this.$().find('.subject-search-results');
      $elem.fadeOut(300, function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        if (_this.get('active')) {
          _this.get('search').reset();
        }
      });
    }
  });
});