define('cdata/pods/organizations/edit-mixin', ['exports', 'cdata/mixins/route-create-modals', 'cdata/mixins/editor/route-location', 'cdata/mixins/editor/saving-cases', 'cdata/mixins/components/jde-departments'], function (exports, _routeCreateModals, _routeLocation, _savingCases, _jdeDepartments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_routeCreateModals.default, _routeLocation.default, _savingCases.default, _jdeDepartments.default, {
    sidConstants: Ember.inject.service(),
    relations: Ember.inject.service(),
    roles: Ember.computed.alias('vocab.originalRoles'),
    subjectSearch: Ember.inject.service(),

    actions: {
      /*
       * <<<<< websites >>>>>
       */

      addOrganizationWebsite: function addOrganizationWebsite() {
        var model = this.get('currentModel');

        var organizationWebsite = this.get('store').createRecord('organization-website', {
          organization: model,
          organizationId: model.get('id'),
          preferred: !model.get('organizationWebsites').filterBy('preferred').get('length')
        });

        model.get('organizationWebsites').pushObject(organizationWebsite);
      },
      removeOrganizationWebsite: function removeOrganizationWebsite(organizationWebsite) {
        this._removeItemWithPreferred(organizationWebsite, 'organizationWebsites');
      },
      setPreferredToOrganizationWebsite: function setPreferredToOrganizationWebsite(organizationWebsite) {
        this._changePreferred('organizationWebsites', organizationWebsite);
      },
      clearOrganizationWebsiteSection: function clearOrganizationWebsiteSection() {
        this._removeAllItems('organizationWebsites', 'removeOrganizationWebsite');
      },


      /*
       * <<<<< jde departments >>>>>
       */

      removeOrganizationJdeDepartment: function removeOrganizationJdeDepartment(organizationJdeDepartment) {
        var validate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        organizationJdeDepartment.deleteRecord();
        if (validate) {
          this.get('_validateDepartmentsItems').perform('organization');
        }
      },
      clearOrganizationJdeDepartmentSection: function clearOrganizationJdeDepartmentSection() {
        this._removeAllItems('organizationJdeDepartments', 'removeOrganizationJdeDepartment');
        this._removeAllItems('organizationChristiesSpecialistDepartments', 'removeOrganizationChristiesSpecialistDepartment');
      },
      addOrganizationJdeDepartment: function addOrganizationJdeDepartment(dep) {
        var addSpecialists = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var model = this.get('currentModel');

        var organizationJdeDepartment = this.get('store').createRecord('organization-jde-department', {
          organizationId: model.get('id'),
          organization: model,
          jdeDepartmentVocabularyItemId: dep.get('id')
        });

        model.get('organizationJdeDepartments').pushObject(organizationJdeDepartment);
        if (addSpecialists) {
          this.get('_addSpecialistsByDepartment').perform(dep.get('id'), 'organization');
        }
      },


      /*
       * <<<<< christies specialist departments >>>>>
       */

      setPreferredToOrganizationChristiesSpecialistDepartment: function setPreferredToOrganizationChristiesSpecialistDepartment(organizationChristiesSpecialistDepartment) {
        this._changePreferred('organizationChristiesSpecialistDepartments', organizationChristiesSpecialistDepartment);
      },
      removeOrganizationChristiesSpecialistDepartment: function removeOrganizationChristiesSpecialistDepartment(organizationName) {
        var validate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        this._removeItemWithPreferred(organizationName, 'organizationChristiesSpecialistDepartments');
        if (validate) {
          this.get('_validateDepartmentsItems').perform('organization');
        }
      },
      clearOrganizationChristiesSpecialistDepartmentSection: function clearOrganizationChristiesSpecialistDepartmentSection() {
        this._removeAllItems('organizationChristiesSpecialistDepartments', 'removeOrganizationChristiesSpecialistDepartment');
      },
      addOrganizationChristiesSpecialistDepartment: function addOrganizationChristiesSpecialistDepartment(spec) {
        var addDepartments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var model = this.get('currentModel');

        var organizationChristiesSpecialistDepartment = this.get('store').createRecord('organization-christies-specialist-department', {
          organizationId: model.get('id'),
          organization: model,
          preferred: !model.get('organizationChristiesSpecialistDepartments').filterBy('preferred').get('length'),
          christiesSpecialistDepartmentVocabularyItemId: spec.get('id')
        });

        model.get('organizationChristiesSpecialistDepartments').pushObject(organizationChristiesSpecialistDepartment);
        if (addDepartments) {
          this.get('_addDepartmentsBySpecialist').perform(spec.get('id'), 'organization');
        }
      },


      /*
       * <<<<< tiles >>>>>
       */

      setPreferredToOrganizationTitle: function setPreferredToOrganizationTitle(organizationTitle) {
        this._changePreferred('organizationTitles', organizationTitle);
      },
      removeOrganizationTitle: function removeOrganizationTitle(organizationTitle) {
        this._removeItemWithPreferred(organizationTitle, 'organizationTitles');
      },
      clearOrganizationTitleSection: function clearOrganizationTitleSection() {
        this._removeAllItems('organizationTitles', 'removeOrganizationTitle');
      },
      addOrganizationTitle: function addOrganizationTitle() {
        var model = this.get('currentModel');
        var store = this.get('store');
        var organizationTitle = store.createRecord('organization-title', {
          organizationId: model.get('id'),
          organization: model,
          preferred: !model.get('organizationTitles').filterBy('preferred').get('length'),
          titleLabelVocabularyItemId: this.get('sidConstants.defaultTitleLabelVocabularyItemId')
        });
        model.get('organizationTitles').pushObject(organizationTitle);
      },


      /*
       * <<<<< descriptions >>>>
       */

      setPreferredToOrganizationDescription: function setPreferredToOrganizationDescription(organizationDescription) {
        this._changePreferred('organizationDescriptions', organizationDescription);
      },
      removeOrganizationDescription: function removeOrganizationDescription(organizationDescription) {
        this._removeItemWithPreferred(organizationDescription, 'organizationDescriptions');
      },
      clearOrganizationDescriptionSection: function clearOrganizationDescriptionSection() {
        this._removeAllItems('organizationDescriptions', 'removeOrganizationDescription');
      },
      addOrganizationDescription: function addOrganizationDescription() {
        var model = this.get('currentModel');
        var store = this.get('store');

        var organizationDescription = store.createRecord('organization-description', {
          organizationId: model.get('id'),
          organization: model,
          preferred: !model.get('organizationDescriptions').filterBy('preferred').get('length')
        });

        model.get('organizationDescriptions').pushObject(organizationDescription);
      },


      /*
       * <<<<< roles >>>>>
       */

      addOrganizationRole: function addOrganizationRole(roleId, citationId) {
        var model = this.get('currentModel');
        var existRole = model.get('organizationRoles').find(function (item) {
          return item.get('roleId') === roleId && item.get('citationLabelVocabularyItemId') === citationId;
        });

        if (existRole) {
          if (existRole.get('isDeleted')) {
            var preferred = !model.get('organizationRoles').filterBy('preferred').get('length');
            existRole.rollbackAttributes();
            existRole.set('preferred', preferred);
          }

          return;
        }

        var role = this.get('roles').findBy('id', roleId);

        var organizationRole = this.get('store').createRecord('organizationRole', {
          organizationId: model.get('id'),
          organization: model,
          role: role,
          roleId: roleId,
          citationLabelVocabularyItemId: citationId,
          preferred: !model.get('organizationRoles').filterBy('preferred').get('length')
        });

        model.get('organizationRoles').pushObject(organizationRole);
      },
      setPreferredToOrganizationRole: function setPreferredToOrganizationRole(organizationRole) {
        this._changePreferred('organizationRoles', organizationRole);
      },
      removeOrganizationRole: function removeOrganizationRole(organizationRole) {
        this._removeItemWithPreferred(organizationRole, 'organizationRoles');
      },
      clearOrganizationRoleSection: function clearOrganizationRoleSection() {
        this._removeAllItems('organizationRoles', 'removeOrganizationRole');
      },


      /*
       * <<<<< images >>>>>
       */

      setPreferredToOrganizationImage: function setPreferredToOrganizationImage(organizationImage) {
        this._changePreferred('organizationImages', organizationImage);
      },
      removeOrganizationImage: function removeOrganizationImage(organizationImage) {
        this._removeItemWithPreferred(organizationImage, 'organizationImages');
      },
      addOrganizationImage: function addOrganizationImage() {
        var model = this.get('currentModel');
        var organizationImage = this.get('store').createRecord('organization-image', {
          organization: model,
          organizationId: model.get('id'),
          preferred: !model.get('organizationImages').filterBy('preferred').get('length')
        });
        model.get('organizationImages').pushObject(organizationImage);
      },
      clearOrganizationImageSection: function clearOrganizationImageSection() {
        this._removeAllItems('organizationImages', 'removeOrganizationImage');
      },


      /*
       * <<<<< existence dates >>>>>
       */

      setPreferredToOrganizationExistenceDate: function setPreferredToOrganizationExistenceDate(organizationExistenceDate) {
        this._changePreferred('organizationExistenceDates', organizationExistenceDate);
      },
      removeOrganizationExistenceDate: function removeOrganizationExistenceDate(organizationExistenceDate) {
        this._removeItemWithPreferred(organizationExistenceDate, 'organizationExistenceDates');
      },
      clearOrganizationExistenceDateSection: function clearOrganizationExistenceDateSection() {
        this._removeAllItems('organizationExistenceDates', 'removeOrganizationExistenceDate');
      },
      addOrganizationExistenceDate: function addOrganizationExistenceDate() {
        var model = this.get('currentModel');
        var organizationExistenceDate = this.get('store').createRecord('organization-existence-date', {
          organizationId: model.get('id'),
          organization: model,
          text: '',
          preferred: !model.get('organizationExistenceDates').filterBy('preferred').get('length')
        });
        model.get('organizationExistenceDates').pushObject(organizationExistenceDate);
      },


      /*
       * <<<<< nationalities >>>>
       */

      setPreferredToOrganizationNationality: function setPreferredToOrganizationNationality(organizationNationality) {
        this._changePreferred('organizationNationalities', organizationNationality);
      },
      removeOrganizationNationality: function removeOrganizationNationality(organizationNationality) {
        this._removeItemWithPreferred(organizationNationality, 'organizationNationalities');
      },
      clearOrganizationNationalitySection: function clearOrganizationNationalitySection() {
        this._removeAllItems('organizationNationalities', 'removeOrganizationNationality');
      },
      addOrganizationNationality: function addOrganizationNationality(termId, citationId) {
        var model = this.get('currentModel');
        var existNationality = model.get('organizationNationalities').find(function (item) {
          return item.get('nationalityVocabularyItemId') === termId && item.get('citationLabelVocabularyItemId') === citationId;
        });

        if (existNationality) {
          if (existNationality.get('isDeleted')) {
            var preferred = !model.get('organizationNationalities').filterBy('preferred').get('length');
            existNationality.rollbackAttributes();
            existNationality.set('preferred', preferred);
          }

          return;
        }

        var organizationNationality = this.get('store').createRecord('organization-nationality', {
          organizationId: model.get('id'),
          organization: model,
          preferred: !model.get('organizationNationalities').filterBy('preferred').get('length'),
          nationalityVocabularyItemId: termId,
          citationLabelVocabularyItemId: citationId
        });

        model.get('organizationNationalities').pushObject(organizationNationality);
      },


      /*
       * <<<<< addresses >>>>>
       */

      addOrganizationAddress: function addOrganizationAddress() {
        var organization = this.get('currentModel');
        var location = this.get('store').createRecord('location');

        var address = this.get('store').createRecord('organization-address', {
          organizationId: organization.get('id'),
          locationId: null,
          location: location
        });

        organization.get('organizationAddresses').unshiftObject(address);
      },
      removeOrganizationAddress: function removeOrganizationAddress(organizationAddress) {
        organizationAddress.deleteRecord();
      },
      clearOrganizationAddressSection: function clearOrganizationAddressSection() {
        this._removeAllItems('organizationAddresses', 'removeOrganizationAddress');
      },


      /*
       * <<<<< globals >>>>>
       */

      cancel: function cancel() {
        this.send('reset');
        this._clearEditor();
      },


      /**
       * Reset Organization model
       */
      reset: function reset() {
        var model = this.get('currentModel');
        model.rollbackAttributes();

        this.get('relations').rollbackHasMany(model);
      },


      /**
       * Clean, validate and save Organization model with all relationships
       */
      save: function save() {
        var _this = this;

        this._clean();

        var model = this.get('currentModel');

        model.get('organizationImages').forEach(function (item) {
          _this._saveRelationWithImage(item);
        });

        model.get('organizationAddresses').forEach(function (item) {
          _this._saveAddress(item);
        });

        // saving simple has many relations
        this._handleSaving(this.get('relations').saveSimpleHasMany(model, ['organizationTitles', 'organizationWebsites', 'organizationJdeDepartments', 'organizationChristiesSpecialistDepartments', 'organizationRoles', 'organizationNationalities', 'organizationDescriptions', 'organizationExistenceDates']));

        if (model.get('hasDirtyAttributes')) {
          this._handleSaving(model.save());
        } else {
          this._handleSaving();
        }
      }
    },

    /**
     * Remove not-filled (empty) data
     */
    _clean: function _clean() {
      this._removeItemsByEmptyField('organizationTitles', 'text', 'removeOrganizationTitle');
      this._removeItemsByEmptyField('organizationWebsites', 'url', 'removeOrganizationWebsite');
      this._removeItemsByEmptyField('organizationExistenceDates', ['text'], 'removeOrganizationExistenceDate');
      this._removeItemsByEmptyField('organizationImages', 'image.url', 'removeOrganizationImage');
      this._removeItemsByEmptyField('organizationDescriptions', 'text', 'removeOrganizationImage');
      this._removeItemsByEmptyField('organizationAddresses', 'location.country', 'removeOrganizationAddress');
      this._removeItemsByEmptyField('organizationJdeDepartments', 'jdeDepartmentVocabularyItemId', 'removePersonJdeDepartment');
      this._removeItemsByEmptyField('organizationChristiesSpecialistDepartments', 'christiesSpecialistDepartmentVocabularyItemId', 'removePersonChristiesSpecialistDepartment');
    }
  });
});