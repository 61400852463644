define('cdata/pods/components/lots/modals/comparable-sets/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin', 'ember-local-storage'], function (exports, _constants, _componentMixin, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.COMPARABLE_SETS,
    classNames: ['context-modal', _constants.MODALS.COMPARABLE_SETS, 'edit-form'],
    inProcess: Ember.computed.alias('model.inProcess'),
    isDisabled: Ember.computed.alias('failed'),

    comparableSets: (0, _emberLocalStorage.storageFor)('comparable-sets'),

    createNewSet: 'createNewSet',
    setComparableSet: 'setComparableSet',

    sets: Ember.computed.alias('model.sets'),
    setId: Ember.computed.alias('comparableSets.settings.setId'),

    actions: {
      createSet: function createSet() {
        this.sendAction('createNewSet', this.get('model.name'));
      },
      selectComparableSet: function selectComparableSet(set) {
        this.sendAction('setComparableSet', set);
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeSelectSetForm');
      }
    }
  });
});