define('cdata/mixins/lists/component-bulk-operations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modal: Ember.inject.service(),

    classNames: ['list-bulk-operations'],
    classNameBindings: ['disabled'],
    mergeList: Ember.inject.service(),

    saveAssignForm: 'saveAssignForm',
    assignRemove: 'assignRemove',
    openMergeListForm: 'openMergeListForm',
    initPrevNextEditor: 'initPrevNextEditor',

    checkedItems: Ember.computed.filterBy('model', '_checked', true),
    quantity: Ember.computed('checkedItems.length', 'filterAllChecked', function () {
      if (this.get('isFilter')) {
        return this.get('model.meta.count_filtered');
      }

      return this.get('checkedItems.length');
    }),

    disabled: Ember.computed.not('enabled'),
    enabled: Ember.computed.or('quantity', 'filterAllChecked'),

    assignOptions: {},

    hasFilter: false,
    filterAllChecked: false,
    hasAnyFilter: Ember.computed.or('hasFilter', 'hasCustomFilter'),
    isFilter: Ember.computed.and('hasAnyFilter', 'filterAllChecked'),

    actions: {
      checkAllVisible: function checkAllVisible() {
        this.clearFilterAllChecked();
        this.sendAction('bulkCheckAll');
      },
      checkAllFiltered: function checkAllFiltered() {
        this.sendAction('bulkCancel');
        this.set('filterAllChecked', !this.get('filterAllChecked'));
      },
      cancel: function cancel() {
        this.clearFilterAllChecked();
        this.sendAction('bulkCancel');
      },
      selectAssignOption: function selectAssignOption(option) {
        this.sendAction('openAssignForm', this.get('checkedItems'), // target items for assign
        option, // option code (field)
        this.get('assignOptions').findBy('id', option).label, // option title
        this.get('isFilter'), // if need assign by filter parameters instead items,
        this.get('isFilter') ? this.get('quantity') : null);
      },
      assignRemove: function assignRemove() {
        this.sendAction('assignRemove', this.get('checkedItems'));
      },
      approve: function approve() {
        this.sendAction('saveAssignForm', this.get('checkedItems'), 'approve', null, null, this.get('isFilter'));
      },
      unapprove: function unapprove() {
        this.sendAction('saveAssignForm', this.get('checkedItems'), 'unapprove', null, null, this.get('isFilter'));
      },
      shown: function shown() {
        this.sendAction('saveAssignForm', this.get('checkedItems'), 'shown', null, null, this.get('isFilter'));
      },
      hidden: function hidden() {
        this.sendAction('saveAssignForm', this.get('checkedItems'), 'hidden', null, null, this.get('isFilter'));
      },
      addCheckedToMergeList: function addCheckedToMergeList(name) {
        this.get('mergeList').addToList(this.get('checkedItems'));
        this.sendAction('bulkCancel');
        this.send('openMergeListForm', name);
      },
      openMergeListForm: function openMergeListForm(name) {
        this.sendAction('openMergeListForm', name);
      },
      initPrevNextEditor: function initPrevNextEditor() {
        this.sendAction.apply(this, ['initPrevNextEditor'].concat(Array.prototype.slice.call(arguments)));
      }
    },

    clearFilterAllChecked: function clearFilterAllChecked() {
      this.set('filterAllChecked', false);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.get('modal').on('bulk-checkbox-toggled', this, this.clearFilterAllChecked);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      this.get('modal').off('bulk-checkbox-toggled', this, this.clearFilterAllChecked);
    }
  });
});