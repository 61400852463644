define('cdata/models/sale', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    organizationId: attr('string'),
    organization: belongsTo('organization'),
    priceTypeVocabularyItemId: attr('string'),
    currencyVocabularyItemId: attr('string'),
    isCharity: attr('boolean'),
    _organizationTitle: attr('string'),
    _locationName: attr('string'),
    _locationAddress: attr('string'),
    calculatedTotal: attr('string'),
    publishedTotal: attr('string'),
    _title: attr('string'),
    number: attr('string'),
    sessions: hasMany('session'),
    saleTitles: hasMany('sale-title'),
    saleImages: hasMany('sale-image'),
    saleDescriptions: hasMany('sale-description'),
    source: attr('string'),
    sourceId: attr('string'),
    url: attr('string'),
    uuid: attr('string'),

    title: Ember.computed('saleTitles.@each.{preferred,text}', 'number', function () {
      var title = void 0;
      if (this.get('number')) {
        title = this.get('number');
      }

      var saleTitle = this.get('saleTitles').findBy('preferred');
      if (saleTitle && saleTitle.get('text')) {
        title = saleTitle.get('text');
      }

      return title;
    }),

    // Christie’s - 25.05.19 - 1173 - PWCA Evening Sale
    largeTitle: Ember.computed('title', 'number', 'organization.organizationTitles.@each', 'sessions.@each', function () {
      var parts = [];

      var orgTitle = this.get('organization.organizationTitles').findBy('preferred');
      orgTitle && orgTitle.get('text') && parts.push(orgTitle.get('text'));

      var sess = this.get('sessions.firstObject');
      sess && sess.get('startDate') && parts.push(sess.get('startDate'));

      this.get('number') && parts.push(this.get('number'));
      this.get('title') && parts.push(this.get('title'));

      return parts.join(' - ');
    })
  });
});