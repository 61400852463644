define('cdata/pods/user-reviews/controller', ['exports', 'cdata/constants', 'ember-local-storage'], function (exports, _constants, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    userActions: Ember.inject.service(),
    reviewEditor: Ember.inject.service(),

    reviewMode: (0, _emberLocalStorage.storageFor)('review-mode'),

    myId: Ember.computed.alias('userActions.user.id'),

    users: [],
    entity: null,
    field: null,
    assignUserId: null,
    requestedUserId: null,
    assignedUserId: null,

    loading: false,

    userReviews: [],
    userReviewsCount: 0,

    entities: [Ember.Object.create({ id: null, label: '- all -' }), Ember.Object.create({ id: 'entity', label: 'Object' }), Ember.Object.create({ id: 'lot', label: 'Lot' })],

    fields: _constants.REVIEW_FIELDS.ENTITY.map(function (field) {
      return Ember.Object.create(field);
    }).concat(_constants.REVIEW_FIELDS.LOT.map(function (field) {
      return Ember.Object.create(field);
    })),

    enableAssign: Ember.computed('userReviews.length', function () {
      return this.get('userReviews.length') > 0;
    }),
    disableAssign: Ember.computed.not('enableAssign'),

    checkedReviews: Ember.computed.filterBy('userReviews', 'checked', true),

    lastCheckedId: null,

    hasCheckedReviews: Ember.computed('checkedReviews', function () {
      return this.get('checkedReviews.length') > 0;
    }),

    actions: {
      show: function show() {
        this.show();
      },
      assign: function assign() {
        var params = this.get('hasCheckedReviews') ? { ids: this.get('checkedReviews').mapBy('id') } : this.getFilterParams();
        this.send('assignReviews', this.get('assignUserId'), params);
      },
      start: function start() {
        var selectedReviews = this.get('hasCheckedReviews') ? this.get('checkedReviews') : this.get('userReviews');

        var entityIds = selectedReviews.map(function (el) {
          return el.entity === 'entity' ? el.entity_id : null;
        }).filter(function (el) {
          return el;
        }).uniq();
        var lotIds = selectedReviews.map(function (el) {
          return el.entity === 'lot' ? el.entity_id : null;
        }).filter(function (el) {
          return el;
        }).uniq();

        var entityReviews = entityIds.map(function (entity_id) {
          return selectedReviews.find(function (review) {
            return review.entity === 'entity' && review.entity_id === entity_id;
          });
        }).filter(function (el) {
          return el;
        });
        var lotReviews = lotIds.map(function (lot_id) {
          return selectedReviews.find(function (review) {
            return review.entity === 'lot' && review.entity_id === lot_id;
          });
        }).filter(function (el) {
          return el;
        });

        var reviews = entityReviews.concat(lotReviews);
        var reviewIds = reviews.mapBy('id');
        reviewIds = reviewIds.map(function (id) {
          return id + '';
        });

        this.get('reviewEditor').setList(reviewIds, reviews);
        this.get('reviewMode').set('settings.enabled', true);
        this.send('startReviewEditor');
      },
      my: function my() {
        this.set('field', null);
        this.set('assignedUserId', this.get('myId'));
        this.set('requestedUserId', null);
        this.show();
      },
      all: function all() {
        this.set('field', null);
        this.set('entity', null);
        this.set('assignedUserId', null);
        this.set('requestedUserId', null);
        this.show();
      },
      checkReview: function checkReview(review) {
        review.set('checked', !review.get('checked'));
        this.set('lastCheckedId', review.get('id'));
      },
      checkReviewShift: function checkReviewShift(review) {
        review.set('checked', !review.get('checked'));
        this.checkAllByShift(review.get('id'), review.get('checked'));
        this.set('lastCheckedId', review.get('id'));
      }
    },

    show: function show() {
      var _this = this;

      this.set('userReviews', []);
      this.set('loading', true);

      this.get('ajax').$get('user_reviews', this.getFilterParams(), function (response) {
        var users = _this.get('users');

        var items = response.reviews.map(function (item) {
          var u = users.findBy('id', item.request_user_id + '');
          item.request_user = u && u.get('email');

          u = users.findBy('id', item.assign_user_id + '');
          item.assign_user = u && u.get('email');

          return Ember.Object.create(item);
        });

        _this.set('userReviews', items);
        _this.set('userReviewsCount', response.count);
      }, function () {}, function () {
        _this.set('loading', false);
      });
    },
    getFilterParams: function getFilterParams() {
      var params = {
        entity: this.get('entity'),
        field: this.get('field'),
        assign_user_id: this.get('assignedUserId'),
        request_user_id: this.get('requestedUserId')
      };

      var req = {};
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(params)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];

          if (!Ember.isEmpty(value)) {
            req[key] = value;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return req;
    },
    checkAllByShift: function checkAllByShift(targetId, checked) {
      var _this2 = this;

      if (!this.get('lastCheckedId')) {
        return;
      }

      var started = false;

      this.get('userReviews').forEach(function (review) {
        if (review.get('id') === targetId || review.get('id') === _this2.get('lastCheckedId')) {
          started = !started;
        }

        if (started) {
          review.set('checked', checked);
        }
      });
    }
  });
});