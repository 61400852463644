define('cdata/pods/components/lots/modals/assign-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin', 'cdata/mixins/components/cdata-category', 'cdata/mixins/components/assign-form'], function (exports, _constants, _componentMixin, _cdataCategory, _assignForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var eventKey = _constants.MODALS.LOT_ASSIGN + '.saving';
  exports.default = Ember.Component.extend(_componentMixin.default, _cdataCategory.default, _assignForm.default, {
    modalClassId: _constants.MODALS.PERSON_ASSIGN,

    classNames: ['context-modal', _constants.MODALS.LOT_ASSIGN, 'edit-form'],

    enabled: Ember.computed.bool('assignSubject'),

    cdata: Ember.computed.equal('model.option', 'cdata_category_vocabulary_item_id'),

    eventKey: eventKey,

    mi: Ember.computed.equal('model.option', 'mi_category_vocabulary_item_id'),

    status: Ember.computed.equal('model.option', 'lot_status_vocabulary_item_id'),

    statusMessage: Ember.computed('status', 'assignSubject.id', function () {
      if (!this.get('status')) {
        return '';
      }

      var message = '';
      var statusLabel = this.get('vocab.lotStatusesLabels')[this.get('assignSubject.id')];

      if (statusLabel === 'Sold') {
        message = 'All "' + statusLabel + '" lots must have a sale price. If at least one lot doesn\'t have a sale price, this action will be reverted';
      }

      if (statusLabel === 'Unsold' || statusLabel === 'Not Yet Available') {
        message = 'All "' + statusLabel + '" lots must not have a sale price. If at least one lot has a sale price, this action will be reverted';
      }

      return message;
    }),

    isCreator: Ember.computed.equal('model.option', 'creator'),

    isReviews: Ember.computed.equal('model.option', 'reviews'),
    reviewFields: _constants.REVIEW_FIELDS.ENTITY.map(function (field) {
      return Ember.Object.create(field);
    }),
    reviewFieldId: null,

    cdataCategoryId: Ember.computed.alias('assignSubject.id'),

    miCategoryId: Ember.computed('assignSubject.id', function () {
      return this.get('assignSubject.id') || null;
    }),

    subjectName: Ember.computed('model.option', function () {
      var option = this.get('model.option');

      if (['session', 'creator', 'christiesSpecialist'].indexOf(option) !== -1) {
        return option;
      }

      return null;
    }),

    actions: {
      setCdataCategory: function setCdataCategory(category) {
        this.set('assignSubject', category);
      },
      setMiCategory: function setMiCategory(category) {
        this.set('assignSubject', category);
      },
      setLotStatus: function setLotStatus(status) {
        this.set('assignSubject', status);
      },
      setReviewField: function setReviewField(field) {
        this.set('assignSubject', field);
      }
    }
  });
});