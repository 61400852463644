define('cdata/initializers/ember-cli-jss', ['exports', 'jss', 'jss-preset-default', 'md5-o-matic'], function (exports, _jss, _jssPresetDefault, _md5OMatic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = undefined;


  var createGenerateClassName = function createGenerateClassName() {
    var count = 0;

    return function (rule, sheet) {
      var meta = sheet.options.meta;

      var isDynamic = meta.includes(' dynamic');
      var hash = (0, _md5OMatic.default)(isDynamic ? meta + '-' + (count += 1) : meta).slice(0, 6);
      var className = rule.key + '-' + hash;

      return isDynamic ? className + '-dynamic' : className;
    };
  };

  var initialize = exports.initialize = function initialize() {
    _jss.default.setup({
      plugins: (0, _jssPresetDefault.default)().plugins,
      createGenerateClassName: createGenerateClassName
    });
  };

  exports.default = {
    initialize: initialize
  };
});