define('cdata/mixins/editor/forms/image-relation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    images: [],

    init: function init() {
      this._super.apply(this, arguments);

      this.get('images').forEach(function (item) {
        item.get('image').then(function (image) {
          item.set('_url', image.get('url'));
        });
      });
    },


    newImages: Ember.computed.filterBy('images', '_url', undefined),
    newImage: Ember.computed.alias('newImages.firstObject'),
    existImages: Ember.computed.filterBy('images', '_url'),

    loadImageFor: 'loadImageFor',

    actions: {
      loadImageFor: function loadImageFor() {
        this.sendAction.apply(this, ['loadImageFor'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});