define('cdata/pods/sessions/subject/route', ['exports', 'cdata/mixins/route-init', 'cdata/mixins/route-edit', 'cdata/mixins/route-create-modals', 'cdata/pods/sessions/session-mixin'], function (exports, _routeInit, _routeEdit, _routeCreateModals, _sessionMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeInit.default, _routeEdit.default, _routeCreateModals.default, _sessionMixin.default, {
    editor: Ember.Object.create({}),

    model: function model(params) {
      return this.get('store').find('session', params.session_id);
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      try {
        var saleController = this.controllerFor('sales.show');
        if (saleController) {
          saleController.set('session', model);
        }
      } catch (e) {
        // skip
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('editor', this.get('editor'));
    },


    actions: {
      openSessionForms: function openSessionForms(formName) {
        this._clearEditor();
        this.set('editor.formName', 'sessions/edit/' + formName);
        Ember.$('body').css('overflow', 'hidden');
      },
      addSessionToSale: function addSessionToSale(sale) {
        var _this = this;

        var session = this.get('store').createRecord('session', {
          sale: sale,
          saleId: sale.get('id')
        });

        this._handleSaving(session.save()).then(function (session) {
          sale.get('sessions').pushObject(session);
          _this.transitionTo('sessions.subject', sale.get('id'), session.get('id'));
        });
      }
    }
  });
});