define('cdata/pods/components/citations/edit-modal-mode/component', ['exports', 'cdata/pods/components/citations/mixins/computed-text'], function (exports, _computedText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_computedText.default, {
    createSourceFor: 'createSourceFor',

    urlMode: Ember.computed('model.source.{url,title}', function () {
      var source = this.get('model.source');

      return !Ember.isEmpty(source.get('url')) || Ember.isEmpty(source.get('url')) && Ember.isEmpty(source.get('title'));
    }),

    textMode: Ember.computed.not('urlMode'),

    actions: {
      openEditSource: function openEditSource() {
        this.sendAction('openSourceForm', this.get('model'));
      },
      openAddSource: function openAddSource() {
        this.sendAction('openSourceAddForm', this.get('model'));
      },
      deleteSource: function deleteSource() {
        this.sendAction('deleteSourceFromModel', this.get('model'));
      },
      changeUrl: function changeUrl(value) {
        var model = this.get('model');
        var source = model.get('source');
        if (!source || !source.get('content')) {
          this.sendAction('createSourceFor', model, null);
        }
        model.set('source.url', value);
      }
    }
  });
});