define('cdata/models/sale-list-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    saleId: attr('number'),
    number: attr('string'),
    priceTypeVocabularyItemId: attr('string'),
    currencyVocabularyItemId: attr('string'),
    title: attr('string'),
    organizationTitle: attr('string'),
    sessionsCount: attr('number'),
    lotsCount: attr('number'),
    sessionStatusVocabularyItemId: attr('string'),
    calculatedTotal: attr('number'),
    calculatedTotalSold: attr('number'),
    publishedTotal: attr('number'),
    calculatedTotalUsd: attr('number'),
    calculatedTotalSoldUsd: attr('number'),
    calculatedTotalNotEqual: attr('boolean'),
    shown: attr('boolean'),
    imageUrl: attr('string'),
    startDate: attr('string'),
    finishDate: attr('string'),
    updatedAt: attr('string'),

    sessionListItems: attr('array')
  });
});