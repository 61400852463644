define('cdata/models/entity-measurement-value', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    entityMeasurementId: attr('number'),
    entityMeasurement: belongsTo('entity-measurement'),
    typeVocabularyItemId: attr('string'),
    unitVocabularyItemId: attr('string'),
    value: attr('string'),
    valueNormalized: attr('string')
  });
});