define('cdata/models/set', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    name: attr('string'),
    count: attr('number'),

    label: Ember.computed('name', function () {
      var name = this.get('name') ? this.get('name') + ' #' + this.get('id') : '#' + this.get('id');

      return name + ' (' + (this.get('count') || 0) + ')';
    })
  });
});