define('cdata/helpers/slider-scale', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sliderScale = sliderScale;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var STEPS = {
    year: {
      '50': [-1000, 1500],
      '20': [1500, 1800],
      '10': [1800, 1900],
      '5': [1900, 1950],
      '1': [1950, 3000]
    },
    modernYear: {
      '50': [1000, 1500],
      '20': [1500, 1800],
      '10': [1800, 1900],
      '5': [1900, 1950],
      '1': [1950, 3000]
    },
    price: {
      '500': [0, 5000],
      '1000': [5000, 10000],
      '10000': [10000, 100000],
      '100000': [100000, 1000000],
      '1000000': [1000000, 10000000],
      '10000000': [10000000, 100000000],
      '100000000': [100000000, 1000000000],
      '1000000000': [1000000000, 10000000000]
    },
    calculated: {
      '10000': [0, 200000],
      '200000': [200000, 2000000],
      '3000000': [2000000, 20000000],
      '40000000': [20000000, 200000000]
    },
    measurement: {
      '1': [0, 10000]
    }
  };

  function calcStep(type, value) {
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = Object.entries(STEPS[type])[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref = _step.value;

        var _ref2 = _slicedToArray(_ref, 2);

        var key = _ref2[0];
        var range = _ref2[1];

        if (value >= range[0] && value <= range[1]) {
          return parseInt(key);
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  }

  function sliderScale(params) {
    var type = params[0];
    var min = params[1];
    var max = params[2];

    var offset = 9;
    var width = 190;
    var data = [];
    var range = [];

    if (type === 'year' || type === 'price' || type === 'calculated') {
      var value = type === 'year' ? -1000 : 0;

      if (value <= min) {
        value = min;
      }

      data.push(value);

      var t = true;
      while (t) {
        var step = calcStep(type, value);
        if (step === undefined) {
          break;
        }
        value = value += step;
        if (value < min) {
          continue;
        }
        if (value >= max) {
          data.push(max);
          break;
        }
        data.push(value);
      }

      if (data[data.length - 1] < max) {
        data.push(max);
      }
    }

    if (type === 'count.small') {
      data = [min, max];
      range = [offset, width + offset];
    }

    if (range.length === 0) {
      var size = data.length;
      if (size <= 0) {
        return null;
      }

      for (var i = 0; i <= size; i++) {
        range.push(width / size * i + offset);
      }
    }

    return [data, range];
  }

  exports.default = Ember.Helper.helper(sliderScale);
});