define('cdata/pods/components/site/editor/address-item/form/component', ['exports', 'ember-cli-jss', 'cdata/pods/components/site/editor/address-item/form/stylesheet'], function (exports, _emberCliJss, _stylesheet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCliJss.default, {
    classNames: ['ui grid'],
    stylesheet: _stylesheet.default,

    openGoogleMap: 'openGoogleMap',
    withEditableTitle: false,

    setLocationTitle: function setLocationTitle(value) {
      var location = this.get('model.location');
      var locationTitle = location.get('locationTitles.firstObject');

      location.set('_name', value);
      locationTitle.set('text', value);
    },


    actions: {
      openGoogleMap: function openGoogleMap() {
        this.sendAction.apply(this, ['openGoogleMap'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});