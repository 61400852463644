define('cdata/pods/components/vocabularies/list/set-filters/wrap-actions-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    openFormVocabulary: 'openFormVocabulary',
    createNewVocabulary: 'createNewVocabulary',
    createNewVocabularyItem: 'createNewVocabularyItem',

    actions: {
      openFormVocabulary: function openFormVocabulary() {
        this.sendAction.apply(this, ['openFormVocabulary'].concat(Array.prototype.slice.call(arguments)));
      },
      createNewVocabulary: function createNewVocabulary() {
        this.sendAction.apply(this, ['createNewVocabulary'].concat(Array.prototype.slice.call(arguments)));
      },
      createNewVocabularyItem: function createNewVocabularyItem() {
        this.sendAction.apply(this, ['createNewVocabularyItem'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});