define('cdata/mixins/lists/route-pagination', ['exports', 'cdata/mixins/lists/parameters'], function (exports, _parameters) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_parameters.default, {
    queryParams: {
      page: {
        replace: true,
        refreshModel: false
      }
    },

    _params: {},
    _busy: true,
    _busyPrevious: true,

    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      this.set('_busy', false);
      this.set('_busyPrevious', false);
    },
    infinity: function infinity() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (name) {
        this.set('_modelName', name);
      }

      if (params) {
        params.filter = this.parseFilterParameters(params.filter);
        this.set('_params', params);
      }

      params = this.get('_params');
      var makeParams = this.makeQueryParameters(params);

      return this.get('store').query(this.get('_modelName'), makeParams).then(function (objects) {
        if (objects.get('length') > 0) {
          objects.forEach(function (item) {
            item.set('_firstAtPage', false);
            item.set('_lastAtPage', false);
          });
          objects.get('firstObject').set('_firstAtPage', makeParams.page_number);
          objects.get('lastObject').set('_lastAtPage', makeParams.page_number);
        }

        return objects;
      });
    },


    actions: {
      /**
       * Change page number
       * @param page {Number}
       */
      gotoPage: function gotoPage(page) {
        var current = this.get('controller.page');
        if (current !== page) {
          this.get('controller').set('page', page);
        }
      },
      loadMore: function loadMore() {
        var _this = this;

        if (this.get('_busy')) {
          return;
        }

        this.set('_busy', true);
        this.set('controller.loadingMore', true);

        var model = this.get('currentModel');

        var params = this.get('_params');
        params.page = parseInt(model.get('lastObject._lastAtPage')) + 1;
        this.set('_params', params);

        this.infinity().then(function (objects) {
          model.pushObjects(objects.get('content'));
          model.set('meta', objects.get('meta'));
          model.set('meta.page_number', params.page);
          _this.set('_busy', false);
          _this.set('controller.loadingMore', false);
          _this.send('invalidateCheckAll');
        });
      },
      loadPrevious: function loadPrevious(scrollTarget, pageHeight) {
        var _this2 = this;

        if (this.get('_busyPrevious')) {
          return;
        }

        this.set('_busyPrevious', true);
        this.set('controller.loadingPrevious', true);

        var model = this.get('currentModel');
        var params = this.get('_params');
        var pageNumber = model.get('meta.page_number');

        var previousPage = parseInt(model.get('firstObject._firstAtPage')) - 1;

        if (previousPage <= 0) {
          return;
        }

        params.page = previousPage;
        this.set('_params', params);

        this.infinity().then(function (objects) {
          model.unshiftObjects(objects.get('content'));
          model.set('meta', objects.get('meta'));
          model.set('meta.page_number', pageNumber);
          _this2.set('_busyPrevious', false);
          _this2.set('controller.loadingPrevious', false);
          _this2.send('invalidateCheckAll');
          Ember.run.schedule('afterRender', function () {
            scrollTarget.scrollTop(pageHeight);
          });
        });
      },
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);
        var body = Ember.$('body');

        if (transition.targetName !== 'people.show') {
          return true;
        }

        if (body.scrollTop() > 0) {
          transition.abort();
          this.set('_busyPrevious', true);
          this.set('_busy', true);

          body.animate({ scrollTop: 0 }, 100, null, function () {
            Ember.run.later(function () {
              transition.retry();
            });
          });

          return false;
        }
      }
    }
  });
});