define('cdata/mixins/lists/format-filter-creators', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didReadyFilterParameters: function didReadyFilterParameters(filter) {
      var creators = {};
      var filled = filter.personIds || filter.organizationIds;

      if (filter.personIds) {
        creators.personIds = filter.personIds;
        delete filter.personIds;
      }

      if (filter.organizationIds) {
        creators.organizationIds = filter.organizationIds;
        delete filter.organizationIds;
      }

      if (filled) {
        filter.creators = creators;
      }

      return filter;
    }
  });
});