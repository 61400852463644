define('cdata/services/user-actions', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Service.extend({
    upcomingLotsSettings: (0, _emberLocalStorage.storageFor)('upcoming-lots-settings'),
    predictedPrices: (0, _emberLocalStorage.storageFor)('predicted-prices'),
    sourceTags: (0, _emberLocalStorage.storageFor)('source-tags'),
    user: (0, _emberLocalStorage.storageFor)('user-data'),
    users: {},

    createdSourceIds: [],

    contextMenuOpened: false,

    displaySettings: Ember.computed('sourceTags.settings.{enabledTags,enabledUsers}', function () {
      var _get = this.get('sourceTags.settings'),
          enabledTags = _get.enabledTags,
          enabledUsers = _get.enabledUsers;

      return enabledTags + '-' + enabledUsers;
    }),

    isCreateSource: function isCreateSource(sourceId) {
      return this.get('createdSourceIds').includes(sourceId);
    },
    addSourceAsCreated: function addSourceAsCreated(sourceId) {
      this.createdSourceIds.push(sourceId);
    },
    getUpcomingSettings: function getUpcomingSettings() {
      return this.get('upcomingLotsSettings.settings');
    },
    setUpcomingSettings: function setUpcomingSettings() {
      var settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if (null === settings) {
        this.set('upcomingLotsSettings').clear();
      } else {
        this.set('upcomingLotsSettings.settings', settings);
      }
    },
    toggleShowPredictedPrices: function toggleShowPredictedPrices() {
      var settings = this.get('predictedPrices.settings');
      var newSettings = {};
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(settings)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];

          newSettings[key] = value;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      newSettings.enabled = !newSettings.enabled;
      this.set('predictedPrices.settings', newSettings);
    },
    toggleSourceTags: function toggleSourceTags() {
      var settings = this.get('sourceTags.settings');
      var newSettings = {};
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = Object.entries(settings)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _ref3 = _step2.value;

          var _ref4 = _slicedToArray(_ref3, 2);

          var key = _ref4[0];
          var value = _ref4[1];

          newSettings[key] = value;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      newSettings.enabledTags = !newSettings.enabledTags;
      this.set('sourceTags.settings', newSettings);
    },
    toggleSourceUsers: function toggleSourceUsers() {
      var settings = this.get('sourceTags.settings');
      var newSettings = {};
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = Object.entries(settings)[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var _ref5 = _step3.value;

          var _ref6 = _slicedToArray(_ref5, 2);

          var key = _ref6[0];
          var value = _ref6[1];

          newSettings[key] = value;
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      newSettings.enabledUsers = !newSettings.enabledUsers;
      this.set('sourceTags.settings', newSettings);
    }
  });
});