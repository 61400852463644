define('cdata/models/entity-list-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    title: attr('string'),
    entityId: attr('string'),

    measurements: attr('string'),
    materials: attr('string'),
    lotsCount: attr('number'),

    personId: attr('number'),
    creationDate: attr('string'),
    creatorName: attr('string'),
    creatorVitalDates: attr('string'),
    creatorRole: attr('string'),
    creatorNationality: attr('string'),

    miCategoryVocabularyItemId: attr('string'),
    cdataCategoryVocabularyItemId: attr('number'),
    christiesBusinessGroupVocabularyItemId: attr('number'),
    shown: attr('boolean'),

    imageUrl: attr('string'),
    updatedAt: attr('string')
  });
});