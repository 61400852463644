define('cdata/pods/components/vocabularies/edit/edit-forms-vocabulary/component', ['exports', 'cdata/mixins/editor/edit-forms-template', 'cdata/services/natural-sorter'], function (exports, _editFormsTemplate, _naturalSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editFormsTemplate.default)(), {
    classNames: ['ui grid form'],

    sidConstants: Ember.inject.service(),

    autoCreating: false,

    items: [],

    sortedItems: Ember.computed.sort('items', (0, _naturalSorter.default)('name')),

    loadVocabulariesFor: 'loadVocabulariesFor',
    addVocabularyMapping: 'addVocabularyMapping',
    removeVocabularyMapping: 'removeVocabularyMapping',
    saveVocabulary: 'saveVocabulary',
    cancelVocabulary: 'cancelVocabulary',
    setVocabularySidCode: 'setVocabularySidCode',
    setItemSidCode: 'setItemSidCode',

    activeMappings: Ember.computed.filterBy('model.vocabularyMappings', 'isDeleted', false),

    excludeMappingIds: Ember.computed('activeMappings.@each.toId', function () {
      return this.get('activeMappings').mapBy('toId');
    }),

    mappings: Ember.computed('model.vocabularyMappings.@each', 'items.length', function () {
      var _this = this;

      this.get('model.vocabularyMappings').forEach(function (mapping) {
        var item = _this.get('items').findBy('id', mapping.get('toId'));
        if (item) {
          mapping.set('name', item.get('name'));
        }
      });

      return this.get('model.vocabularyMappings');
    }),

    sid: Ember.computed('model.id', function () {
      return this.get('sidConstants').getByEntityId(this.get('model.id'), 'vocabulary');
    }),

    sidCode: null,

    actions: {
      save: function save() {
        this.sendAction('saveVocabulary', this.get('model'));
        if (this.get('sidCode')) {
          this.sendAction('setVocabularySidCode', this.get('model'), this.get('sidCode'));
          this.set('sidCode', null);
        }
      },
      cancel: function cancel() {
        this.sendAction('cancelVocabulary', this.get('model'));
        this.set('sidCode', null);
      },
      addMapping: function addMapping(vocabulary) {
        this.sendAction('addVocabularyMapping', this.get('model'), vocabulary);
      },
      removeMapping: function removeMapping(mapping) {
        this.sendAction('removeVocabularyMapping', mapping);
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.sendAction('loadVocabulariesFor', this);
    }
  });
});