define('cdata/mixins/components/menu-context', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['actions-group', 'actions-group-request-group'],

    closeContextMenu: 'closeContextMenu',

    confirmRemove: false,

    remove: false,

    removeAction: 'deleteItemRecord',

    closeContextMenuAction: function closeContextMenuAction() {
      try {
        this.sendAction('closeContextMenu');
      } catch (e) {
        // skip
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.closeContextMenuAction();
    },


    actions: {
      remove: function remove() {
        this.set('confirmRemove', true);
      },
      cancelRemove: function cancelRemove() {
        this.set('confirmRemove', false);
        this.closeContextMenuAction();
      },
      confirmRemove: function confirmRemove() {
        this.set('confirmRemove', false);
        this.closeContextMenuAction();
        this.sendAction('removeAction', this.get('model'));
      }
    }
  });
});