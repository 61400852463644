define('cdata/pods/components/organizations/modals/create-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin'], function (exports, _constants, _componentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var eventKey = _constants.MODALS.ORGANIZATION + '.saving';

  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.ORGANIZATION,
    classNames: ['context-modal', _constants.MODALS.ORGANIZATION, 'edit-form'],
    roleLabels: Ember.computed.alias('vocab.rolesLabels'),
    nationalityLabels: Ember.computed.alias('vocab.nationalitiesLabels'),
    inProcess: false,

    isEnabled: Ember.computed.and('organization.title'),
    isDisabled: Ember.computed.not('isEnabled'),

    defaultData: {
      title: '',
      titleAlter: '',
      startYear: '',
      finishYear: '',
      text: '',
      roleVocabularyItemId: null,
      nationalityVocabularyItemId: null,
      citationLabelVocabularyItemId: null
    },

    actions: {
      setRole: function setRole(organization, roleSubject) {
        this.set('organization.roleVocabularyItemId', roleSubject.get('id'));
      },
      setNationality: function setNationality(organization, nationalitySubject) {
        this.set('organization.nationalityVocabularyItemId', nationalitySubject.get('id'));
      },
      save: function save() {
        var organizationData = this.get('organization');
        var model = this.get('model');
        model.set('organizationData', organizationData);
        this.sendAction('saveNewOrganizationForm', model);
      },
      follow: function follow() {
        var organizationData = this.get('organization');
        var model = this.get('model');
        model.set('organizationData', organizationData);
        this.sendAction('saveNewOrganizationForm', model, true);
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.sendAction('closeNewOrganizationForm');
      }
    },

    _initOrganization: function _initOrganization() {
      var organization = Ember.Object.create(this.get('defaultData'));
      if (this.get('model.initialTitle')) {
        organization.set('title', this.get('model.initialTitle'));
      }
      this.set('organization', organization);
    },
    _onProcess: function _onProcess() {
      this.set('inProcess', true);
    },
    _onFinally: function _onFinally() {
      this.set('inProcess', false);
    },
    _onSuccess: function _onSuccess() {
      this.send('close');
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this._initOrganization();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('modal').on(eventKey + '.process', this, this._onProcess).on(eventKey + '.success', this, this._onSuccess).on(eventKey + '.finally', this, this._onFinally);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      this.get('modal').off(eventKey + '.process', this, this._onProcess).off(eventKey + '.success', this, this._onSuccess).off(eventKey + '.finally', this, this._onFinally);
    }
  });
});