define('cdata/pods/components/comparables/search-results/item/component', ['exports', 'cdata/mixins/lists/set-results-item'], function (exports, _setResultsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_setResultsItem.default, {
    gotoItem: 'gotoItem',

    makerYears: Ember.computed('entity.{artist_birth_date,artist_death_date}', function () {
      var birth = this.get('entity.artist_birth_date');
      var death = this.get('entity.artist_death_date');

      if (birth && death) {
        return birth + '-' + death;
      }

      return birth || death;
    }),

    image: Ember.computed.alias('entity.artwork_assets.firstObject.thumbnail'),

    entity: Ember.computed.alias('item.object'),

    actions: {
      gotoItem: function gotoItem() {
        this.sendAction('gotoItem', this.get('item'));
      }
    }
  });
});