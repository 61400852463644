define('cdata/pods/components/citations/add-publication-form/component', ['exports', 'cdata/pods/components/citations/mixins/computed-text', 'cdata/helpers/short-url', 'cdata/helpers/format-url'], function (exports, _computedText, _shortUrl, _formatUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_computedText.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this._initModel();
      this.get('agent').init = this._edit.bind(this);
    },


    classNames: ['ui stackable four column grid', 'form', 'edit-block'],
    isFilled: Ember.computed.or('model.source.{url,id}'),
    isEmpty: Ember.computed.not('isFilled'),
    isOffline: Ember.computed.equal('publicationType', 'offline'),
    isOnline: Ember.computed.not('isOffline'),
    publicationType: 'offline',

    publicationTypes: [{
      id: 'offline',
      label: 'Offline'
    }, {
      id: 'online',
      label: 'Online'
    }],

    onlineTitle: Ember.computed('model.source.{url,title}', function () {
      var url = this.get('model.source.url');
      var title = this.get('model.source.title');
      if (!url) {
        return title;
      }
      if (!title) {
        return _shortUrl.default.compute([url, 100]);
      }

      return title + ' (' + _formatUrl.default.compute([_shortUrl.default.compute([url, 50])]) + ')';
    }),

    actions: {
      setSource: function setSource(model, sourceSubject) {
        this.sendAction('selectSourceFor', model, sourceSubject);
      },
      createSource: function createSource(query) {
        this.sendAction('openSourceForm', this.get('model'), query || '');
      },
      createSourceOnline: function createSourceOnline(url) {
        this.sendAction('openSourceOnlineForm', this.get('model'), url);
      },
      add: function add() {
        if (this.get('isEmpty')) {
          return;
        }

        if ('add' === this.get('mode')) {
          this.sendAction('addPersonRelatedPublication', this.get('model'));
        } else {
          this.sendAction('applyPersonRelatedPublicationForm', this.get('publication'), this.get('model'));
        }

        this.send('cancel');
      },
      cancel: function cancel() {
        this._initModel();
      }
    },

    _edit: function _edit(publication) {
      var source = publication.get('source');
      this.send('cancel');
      this.sendAction('initPersonRelatedPublicationForm', this.get('model'), publication);
      this.set('publicationType', source.get('url') ? 'online' : 'offline');
      this.set('mode', 'edit');
      this.set('publication', publication);
      this._markAsEdit(publication);
    },
    _initModel: function _initModel() {
      this.set('model', Ember.Object.create({}));
      this.sendAction('createSourceForForm', this.get('model'));
      this.set('mode', 'add');
      this.set('publication', null);
      this._markAsEdit();
    },
    _markAsEdit: function _markAsEdit(item) {
      this.get('items').forEach(function (item) {
        item.set('_edit', false);
      });
      if (item) {
        item.set('_edit', true);
      }
    }
  });
});