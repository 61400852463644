define('cdata/pods/marks/index/route', ['exports', 'cdata/mixins/lists/route-list'], function (exports, _routeList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeList.default, {
    model: function model(params) {
      return this.infinity(params, 'mark-list-item');
    },


    actions: {
      saveItem: function saveItem(item) {
        item.save();
      },
      createMark: function createMark() {
        var _this = this;

        var mark = this.get('store').createRecord('mark');
        this._handleSaving(mark.save()).then(function (mark) {
          _this.transitionTo('marks.show', mark.id);
        });
      }
    }
  });
});