define('cdata/pods/components/vocabularies/list/set-results/item/component', ['exports', 'cdata/mixins/lists/set-results-item'], function (exports, _setResultsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_setResultsItem.default, {
    openForm: 'openForm',
    createNewVocabularyItem: 'createNewVocabularyItem',
    deleteItem: 'deleteItem',

    sidConstants: Ember.inject.service(),

    sid: Ember.computed('model.id', function () {
      return this.get('sidConstants').getByEntityId(this.get('item.id'), 'vocabulary:item');
    }),

    parents: Ember.computed('item.parents.@each', function () {
      var parents = this.get('item.parents').reverse();

      return parents.map(function (item) {
        return item.name;
      }).join(' -> ');
    }),

    alternates: Ember.computed('item.alternateNames.@each', function () {
      var names = this.get('item.alternateNames');

      return names ? names.join(', ') : '';
    }),

    mappings: Ember.computed('item.vocabularyItemMappings.@each', function () {
      var mappings = this.get('item.vocabularyItemMappings');

      return mappings.map(function (item) {
        return item.name;
      }).join(', ');
    }),

    actions: {
      editItem: function editItem() {
        this.sendAction('openForm', this.get('item.id'));
      },
      saveNewItem: function saveNewItem(name) {
        this.sendAction('createNewVocabularyItem', name, this.get('item'));
      },
      deleteItem: function deleteItem() {
        this.sendAction('deleteItem', this.get('item'));
      }
    }
  });
});