define('cdata/pods/components/marks/list/set-results/item/component', ['exports', 'cdata/pods/components/marks/list/set-results/item/item-mixin'], function (exports, _itemMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_itemMixin.default, {
    symbols: Ember.computed('item.symbols', function () {
      return this.get('item.symbols').mapBy('term').join(', ');
    }),

    notes: Ember.computed('item.notes', function () {
      return this.get('item.notes').join(',');
    })
  });
});