define('cdata/pods/components/marks/view/mark-header/component', ['exports', 'cdata/mixins/components/load-creator-names'], function (exports, _loadCreatorNames) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_loadCreatorNames.default, {
    classNames: 'entities-entity-header',

    openMarkForms: 'openMarkForms',

    title: Ember.computed('model.markTitles.@each.{preferred,text}', function () {
      var title = this.get('model.markTitles').filterBy('preferred').get('firstObject');

      return title ? title.get('text') : '';
    }),

    maker: Ember.computed('model.markMakers.@each.{personId,organizationId,label}', function () {
      this.loadCreatorNames();
      var markMakers = this.get('model.markMakers.content') || this.get('model.markMakers');
      var makers = markMakers.mapBy('label');

      return makers ? makers.join(', ') : '';
    }),

    type: Ember.computed('model.typeVocabularyItemId', function () {
      if (this.get('model.typeVocabularyItemId')) {
        return this.get('vocab.inscriptionTypesLabels')[this.get('model.typeVocabularyItemId')];
      }
    }),

    date: Ember.computed('model.{startYear,finishYear,dateText}', function () {
      if (this.get('model.dateText')) {
        return this.get('model.dateText');
      }

      var dates = [this.get('model.startYear'), this.get('model.finishYear')];
      dates = dates.filter(function (value) {
        return value;
      });

      return dates.join(' - ');
    }),

    image: Ember.computed('model.markImages.@each.{preferred,image}', function () {
      var image = this.get('model.markImages').filterBy('preferred').get('firstObject');

      return image && image.get('image.url');
    }),

    bgrStyle: Ember.computed('image', function () {
      var image = this.get('image');
      if (image) {
        return new Ember.String.htmlSafe('background-image: url(' + image + ')');
      }

      return Ember.String.htmlSafe('');
    }),

    actions: {
      showImagesForms: function showImagesForms() {
        this.sendAction('openMarkForms', 'edit-forms-images');
      }
    }
  });
});