define('cdata/pods/components/subjects/selected-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    path: '_name',

    readonly: false,

    enableRemove: Ember.computed.not('readonly'),

    items: Ember.computed('subjects.@each.isDeleted', function () {
      return this.get('subjects').rejectBy('isDeleted', true);
    }),

    actions: {
      remove: function remove(subject) {
        this.get('removeSubject')(subject);
      }
    }
  });
});