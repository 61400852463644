define('cdata/pods/components/people/edit/edit-forms-merge/component', ['exports', 'cdata/pods/components/people/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), {
    inProcess: false,
    inConfirm: false,
    inForm: Ember.computed.not('inConfirm'),

    mergePeople: 'mergePeople',

    peopleForMerge: [],
    peopleForMergeUnique: Ember.computed.uniq('peopleForMerge'),
    peopleForMergeIds: Ember.computed.mapBy('peopleForMergeUnique', 'id'),
    disableActions: Ember.computed.lt('peopleForMerge.length', 1),

    mergeType: 'to',
    mergeTypeLabel: Ember.computed('isCurrentToOther', function () {
      return this.get('isCurrentToOther') ? 'Select one' : 'Select one or more';
    }),

    isCurrentToOther: Ember.computed.equal('mergeType', 'to'),
    isOthersToCurrent: Ember.computed.equal('mergeType', 'from'),

    peopleForMergeNames: Ember.computed('mergeType', 'peopleForMergeUnique.[]', function () {
      return this.get('peopleForMergeUnique').map(function (item) {
        return item.get('name');
      }).join(', ');
    }),

    onePersonTo: Ember.computed('mergeType', 'peopleForMergeUnique.[]', function () {
      return this.get('peopleForMergeUnique').get('firstObject');
    }),

    mergeTypes: [{
      id: 'to',
      label: 'Overtake'
    }, {
      id: 'from',
      label: 'Bring under'
    }],

    actions: {
      addOne: function addOne() {
        this.send('add', arguments.length <= 1 ? undefined : arguments[1]);
      },
      add: function add(personSubject) {
        // overtake: only one
        if (this.mergeType === 'to') {
          this.set('peopleForMerge', []);
        }

        this.get('peopleForMerge').pushObject(personSubject);
      },
      remove: function remove(personSubject) {
        this.get('peopleForMerge').removeObject(personSubject);
      },
      cancelForm: function cancelForm() {
        this.set('peopleForMerge', []);
        this.set('mergeType', 'to');
      },
      confirm: function confirm() {
        this.set('inConfirm', true);
      },
      cancelConfirm: function cancelConfirm() {
        this.set('inConfirm', false);
      },
      setMergeType: function setMergeType(type) {
        this.send('cancelForm');
        this.set('mergeType', type.id);
      },
      save: function save() {
        var _this = this;

        this.set('inProcess', true);
        this.sendAction('mergePeople', this.get('mergeType'), this.get('peopleForMerge'), function (promise) {
          promise.then(function () {
            _this.send('cancelConfirm');
            _this.send('cancelForm');
          }).finally(function () {
            _this.set('inProcess', false);
          });
        });
      }
    }
  });
});