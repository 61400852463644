define('cdata/pods/components/organizations/modals/assign-form/component', ['exports', 'cdata/constants', 'cdata/mixins/modals/component-mixin', 'cdata/mixins/components/assign-form'], function (exports, _constants, _componentMixin, _assignForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var eventKey = _constants.MODALS.ORGANIZATION_ASSIGN + '.saving';

  exports.default = Ember.Component.extend(_componentMixin.default, _assignForm.default, {
    modalClassId: _constants.MODALS.PERSON_ASSIGN,
    classNames: ['context-modal', _constants.MODALS.ORGANIZATION_ASSIGN, 'edit-form'],

    enabled: Ember.computed.bool('assignSubject'),

    eventKey: eventKey,

    subjectName: Ember.computed('model.option', function () {
      var option = this.get('model.option');

      if (['role', 'nationality'].indexOf(option) !== -1) {
        return option;
      }

      return null;
    })
  });
});