define('cdata/pods/components/site/expanded-text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    size: 100,

    text: '',

    expanded: false,

    shortingText: Ember.computed('text', function () {
      var text = this.get('text');
      var size = this.get('size');
      var checkSize = Math.ceil(size * 1.3);

      if (text && text.length > checkSize) {
        var parts = text.split(' ');
        var result = '';

        parts.forEach(function (part) {
          if (result.length < size) {
            result += part + ' ';
          }
        });

        return result;
      }

      return null;
    }),

    short: Ember.computed('shortingText', 'expanded', function () {
      return this.get('shortingText') && !this.get('expanded');
    }),

    actions: {
      showFull: function showFull() {
        this.set('expanded', true);
      },
      showLess: function showLess() {
        this.set('expanded', false);
      }
    }

  });
});