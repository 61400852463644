define('cdata/pods/components/sales/view/session-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['item'],

    title: Ember.computed('model.sessionTitles.@each.{preferred,text}', function () {
      return this.get('model.sessionTitles').filterBy('preferred').get('firstObject.text');
    })
  });
});