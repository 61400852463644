define('cdata/models/place', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    name: attr('string'),
    parentId: attr('number'),
    parent: belongsTo('place', { inverse: 'children' }),
    children: hasMany('place', { inverse: 'parent' }),
    startYear: attr('string'),
    finishYear: attr('string'),
    tgnId: attr('number'),
    currentPlaceId: attr('number'),
    currentPlace: belongsTo('place')
  });
});