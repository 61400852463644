define('cdata/pods/components/sales/view/lot-list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['item with-image'],
    entity: Ember.computed.alias('model.entityListItems.firstObject'),
    showCreator: true,
    showPrices: false
  });
});