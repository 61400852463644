define('cdata/pods/components/buttons/block-controls/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    classNames: ['edit-blocks-top-right-controls'],
    preferred: true,
    alternate: false,
    editable: false,
    removable: true,
    edit: 'edit',
    remove: 'remove',
    setPreferred: 'setPreferred',
    setAlternate: 'setAlternate',
    creatable: false,
    create: 'create',

    actions: {
      remove: function remove() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.sendAction.apply(this, ['remove'].concat(_toConsumableArray(args)));
      },
      edit: function edit() {
        for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        this.sendAction.apply(this, ['edit'].concat(_toConsumableArray(args)));
      },
      create: function create() {
        for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
          args[_key3] = arguments[_key3];
        }

        this.sendAction.apply(this, ['create'].concat(_toConsumableArray(args)));
      },
      setPreferred: function setPreferred() {
        for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
          args[_key4] = arguments[_key4];
        }

        this.sendAction.apply(this, ['setPreferred'].concat(_toConsumableArray(args)));
      },
      setAlternate: function setAlternate() {
        for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
          args[_key5] = arguments[_key5];
        }

        this.sendAction.apply(this, ['setAlternate'].concat(_toConsumableArray(args)));
      }
    }
  });
});