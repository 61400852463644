define('cdata/models/person-address', ['exports', 'ember-data', 'cdata/mixins/models/user-name'], function (exports, _emberData, _userName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_userName.default, {
    person: belongsTo('person'),
    personId: attr('string'),
    locationId: attr('string'),
    location: belongsTo('location'),
    contactTypeVocabularyItemId: attr('string')
  });
});