define('cdata/pods/components/lots/edit/edit-forms-properties/component', ['exports', 'cdata/pods/components/lots/edit/mixins/edit-forms', 'cdata/pods/components/lots/lot-properties-mixin'], function (exports, _editForms, _lotPropertiesMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), _lotPropertiesMixin.default, {
    addPriceToLot: 'addPriceToLot',
    reloadAfterSave: 'reloadAfterSave',
    autoCreating: false,

    statusLabel: Ember.computed('model.lotStatusVocabularyItemId', function () {
      return this.get('vocab.lotStatusesLabels')[this.get('model.lotStatusVocabularyItemId')];
    }),

    requiredPrices: Ember.computed('statusLabel', 'price.{priceHammer,pricePremium,priceSale}', function () {
      var required = {};

      if (this.get('statusLabel') === 'Sold') {
        var hasAnyOfRequiredPrice = this.get('price.priceHammer') || this.get('price.pricePremium') || this.get('price.priceSale');
        required = {
          priceHammer: !hasAnyOfRequiredPrice,
          pricePremium: !hasAnyOfRequiredPrice,
          priceSale: !hasAnyOfRequiredPrice
        };
      }

      return required;
    }),

    disabledPrices: Ember.computed('statusLabel', 'price.{priceHammer,pricePremium,priceSale}', function () {
      var disabled = {};

      if (this.get('statusLabel') === 'Unsold' || this.get('statusLabel') === 'Not Yet Available') {
        disabled = {
          priceHammer: !!this.get('price.priceHammer'),
          pricePremium: !!this.get('price.pricePremium'),
          priceSale: !!this.get('price.priceSale')
        };
      }

      return disabled;
    }),

    actions: {
      setPrice: function setPrice(key, value) {
        var price = this.get('price');
        if (!price) {
          this.sendAction('addPriceToLot', this.get('model'), this.get('currencyVocabularyItemId'));
          price = this.get('price');
        }
        price.set(key, value);
      },
      setEstimateLow: function setEstimateLow(value) {
        var price = this.get('price');
        if (!price) {
          this.sendAction('addPriceToLot', this.get('model'), this.get('currencyVocabularyItemId'));
          price = this.get('price');
        }
        price.set('estimateLow', value);
      },
      setEstimateHigh: function setEstimateHigh(value) {
        var price = this.get('price');
        if (!price) {
          this.sendAction('addPriceToLot', this.get('model'), this.get('currencyVocabularyItemId'));
          price = this.get('price');
        }
        price.set('estimateHigh', value);
      },
      save: function save() {
        this.sendAction('reloadAfterSave');
        this.sendAction.apply(this, ['save'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});