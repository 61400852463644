define('cdata/pods/components/site/editor/image-relation/form/component', ['exports', 'cdata/helpers/short-url'], function (exports, _shortUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var escape = Ember.Handlebars.Utils.escapeExpression;

  exports.default = Ember.Component.extend({
    classNames: ['eight wide column'],

    imageOriginalLink: Ember.computed('model.originalUrl', function () {
      var url = this.get('model') && this.get('model.originalUrl');

      if (!url) {
        return null;
      }

      return ('<a href="' + escape(url) + '" target="_blank">' + _shortUrl.default.compute([url, 50]) + '</a>').htmlSafe();
    })
  });
});