define('cdata/helpers/format-membership', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatMembership = formatMembership;

  var escape = Ember.Handlebars.Utils.escapeExpression;

  function formatMembership(params, hash) {
    var relation = params[0];
    var roles = hash && hash.rolesLabels;
    var needHtml = typeof params[1] === 'boolean' ? params[1] : true;

    var title = escape(relation.get('_title'));
    var place = escape(relation.get('_place'));
    var date = escape(relation.get('dateText'));
    var role = roles && relation.get('personRoleVocabularyItemId') ? roles[relation.get('personRoleVocabularyItemId')] : null;

    var result = '';

    if (title) {
      if (needHtml) {
        result += '<span class="strong">' + title + '</span>';
      } else {
        result += title;
      }
    }

    if (place && date) {
      result += '; ' + place + ', ' + date;
    } else if (place || date) {
      result += '; ' + (place || date);
    }

    if (role) {
      result += '; ' + role;
    }

    if (needHtml) {
      return result.htmlSafe();
    }

    return result;
  }

  exports.default = Ember.Helper.helper(formatMembership);
});