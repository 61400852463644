define('cdata/pods/components/vocabularies/edit/edit-forms-item/component', ['exports', 'cdata/mixins/editor/edit-forms-template'], function (exports, _editFormsTemplate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editFormsTemplate.default)(), {
    classNames: ['ui grid form'],

    sidConstants: Ember.inject.service(),
    ajax: Ember.inject.service(),

    autoCreating: false,

    addTerm: 'addTerm',
    setTermPreferred: 'setTermPreferred',
    removeTerm: 'removeTerm',
    loadVocabularyItemsFor: 'loadVocabularyItemsFor',
    loadVocabularyMappingsFor: 'loadVocabularyMappingsFor',
    loadVocabularyItemParentFor: 'loadVocabularyItemParentFor',
    addMappingSubjectToItem: 'addMappingSubjectToItem',
    removeMappingSubjectFromItem: 'removeMappingSubjectFromItem',
    setItemSidCode: 'setItemSidCode',

    sidCode: null,

    selectMappingOn: false,
    selectParentOn: false,

    items: [],

    terms: Ember.computed.filterBy('model.vocabularyItemTerms', 'isDeleted', false),

    preferredTerm: Ember.computed('terms.@each.preferred', function () {
      return this.get('terms').filterBy('preferred', true).get('firstObject');
    }),

    secondaryTerms: Ember.computed.filterBy('terms', 'preferred', false),

    activeMappings: Ember.computed.filterBy('model.vocabularyItemMappings', 'isDeleted', false),

    mappingGroups: [],

    excludeMappingIds: Ember.computed('activeMappings.@each.toId', function () {
      return this.get('activeMappings').mapBy('toId');
    }),

    sid: Ember.computed('model.id', function () {
      return this.get('sidConstants').getByEntityId(this.get('model.id'), 'vocabulary:item');
    }),

    subjectSearchParams: Ember.computed('model.parentId', function () {
      return { vocabulary_id: this.get('model.vocabularyId') };
    }),

    parentName: Ember.computed.alias('model.parent._name'),

    actions: {
      openSelectParent: function openSelectParent() {
        this.set('selectParentOn', true);
      },
      closeSelectParent: function closeSelectParent() {
        this.set('selectParentOn', false);
      },
      openSelectMappings: function openSelectMappings(items) {
        this.set('mappingItems', items);
        this.set('selectMappingOn', true);
      },
      closeSelectMappings: function closeSelectMappings() {
        this.set('mappingItems', []);
        this.set('selectMappingOn', false);
      },
      addSubjectToItem: function addSubjectToItem(itemTo) {
        this.sendAction('addMappingSubjectToItem', this.get('mappingGroups'), this.get('model'), itemTo);
      },
      removeSubjectFromItem: function removeSubjectFromItem(itemTo) {
        this.sendAction('removeMappingSubjectFromItem', this.get('mappingGroups'), itemTo);
      },
      save: function save() {
        this.sendAction('save', this.get('model'));
        if (this.get('sidCode')) {
          this.sendAction('setItemSidCode', this.get('model'), this.get('sidCode'));
          this.set('sidCode', null);
        }
      },
      cancel: function cancel() {
        this.sendAction('cancel', this.get('model'));
        this.set('sidCode', null);
      },
      remove: function remove(term) {
        this.sendAction('removeTerm', term);
      },
      setPreferred: function setPreferred(term) {
        this.sendAction('setTermPreferred', term);
      },
      addTerm: function addTerm() {
        this.sendAction('addTerm', this.get('model'));
      },
      setParentItem: function setParentItem(itemSubject) {
        if (!itemSubject) {
          this.set('model.parentId', null);
          this.set('model.parent', null);

          return;
        }

        if (!this._checkParent(itemSubject.id)) {
          return;
        }

        this.set('model.parentId', itemSubject.id);
        this.sendAction('loadVocabularyItemParentFor', this.get('model'));
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('ajax').$get('vocabulary_items/search', { vocabulary_id: this.get('model.vocabularyId'), page_size: 5000 }, function (response) {
        if (!_this.isDestroyed) {
          _this.set('items', response.map(function (item) {
            return Ember.Object.create(item);
          }));
        }
      });

      this.sendAction('loadVocabularyMappingsFor', this, this.get('activeMappings'), this.get('model.vocabularyId'));
    },
    _checkParent: function _checkParent(targetId) {
      var ids = [this.get('model.id')];
      var disabledIds = this._findChildren(ids);
      if (disabledIds.indexOf(targetId) !== -1) {
        this.get('flashMessage').showError('Wrong parent!');

        return false;
      }

      return true;
    },
    _findChildren: function _findChildren(ids) {
      ids = ids.map(function (id) {
        return parseInt(id);
      });
      var childrenIds = this.get('items').filter(function (item) {
        return ids.indexOf(item.get('parent_id')) !== -1;
      }).map(function (item) {
        return item.id;
      });

      if (childrenIds.length === 0) {
        return ids.concat(childrenIds);
      } else {
        return ids.concat(childrenIds).concat(this._findChildren(childrenIds));
      }
    }
  });
});