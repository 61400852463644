define('cdata/pods/reports/components/chart-breadcrumbs/stylesheet', ['exports', 'ember-cli-jss'], function (exports, _emberCliJss) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var pointerHeight = 30;
  var borderHeight = pointerHeight / 2;

  exports.default = new _emberCliJss.StyleSheet({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: [8, 0],

      //Extract into typography
      fontSize: 18,
      fontWeight: 400
    },

    pointer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      height: pointerHeight,
      padding: [0, 15, 0, 30],
      marginRight: 15,
      background: 'red',

      '&:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 0,
        height: 0,
        borderLeft: [borderHeight, 'solid', 'white'],
        borderTop: [borderHeight, 'solid', 'transparent'],
        borderBottom: [borderHeight, 'solid', 'transparent']
      }
    },

    pointerBefore: {
      position: 'absolute',
      right: -borderHeight,
      bottom: 0,
      width: 0,
      height: 0,
      borderLeft: [borderHeight, 'solid'],
      borderTop: [borderHeight, 'solid', 'transparent'],
      borderBottom: [borderHeight, 'solid', 'transparent']
    }
  });
});