define('cdata/pods/components/entities/edit/edit-forms-materials/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Material'), {
    materials: Ember.computed.filterBy('model.entityMaterials', 'isDeleted', false),

    addEntityMaterialTerm: 'addEntityMaterialTerm',
    parseEntityMaterial: 'parseEntityMaterial',

    actions: {
      addMaterialTerm: function addMaterialTerm(material, term) {
        this.sendAction('addEntityMaterialTerm', material, term);
      },
      parseEntityMaterial: function parseEntityMaterial() {
        this.sendAction.apply(this, ['parseEntityMaterial'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});