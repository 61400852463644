define('cdata/mixins/create-modals/sale', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sidConstants: Ember.inject.service(),
    ajax: Ember.inject.service(),

    actions: {
      createSale: function createSale() {
        this.get('modal').register(this, _constants.MODALS.SALE, 'sales.modal.new').open(Ember.Object.create());
      },
      closeNewSaleForm: function closeNewSaleForm() {
        this.get('modal').close(_constants.MODALS.SALE);
      },
      openNewSaleForm: function openNewSaleForm(title, disableFollow) {
        disableFollow = disableFollow === true;
        title = title || '';
        var model = Ember.Object.create({
          initialTitle: title,
          disableFollow: disableFollow
        });
        this.get('modal').register(this, _constants.MODALS.SALE, 'sales.modal.new').open(model);
      },
      saveNewSaleForm: function saveNewSaleForm(form, isFollow) {
        var _this = this;

        form.set('inProcess', true);
        var organization = form.get('organization');

        var sale = this.get('store').createRecord('sale', {
          organization: organization,
          organizationId: organization.get('id'),
          number: form.get('number'),
          priceTypeVocabularyItemId: form.get('priceTypeVocabularyItemId'),
          currencyVocabularyItemId: form.get('currencyVocabularyItemId')
        });

        this._handleSavingModal(sale.save(), false).then(function (sale) {
          var promises = [];

          promises.push(_this._saveNewSaleTitle(form, sale));
          promises.push(_this._saveNewSaleSession(sale));

          _this.send('closeNewSaleForm');

          if (isFollow) {
            _this._handleSavingModal(promises, false).finally(function () {
              _this.transitionTo('sessions.subject', sale.get('id'), sale.get('sessions.firstObject.id'));
            });
          }
        }).finally(function () {
          form.set('inProcess', false);
        });
      },
      loadOrganizationFor: function loadOrganizationFor(model, id) {
        model.set('organization', null);
        this.get('store').findRecord('organization', id).then(function (organization) {
          model.set('organization', organization);
          model.set('organizationId', organization.get('id'));
        });
      },
      gotoObjects: function gotoObjects() {
        this.transitionTo('entities');
      },
      gotoSaleLots: function gotoSaleLots(sale) {
        this.transitionTo('sales.lots', sale.get('id'));
      },
      openImportSaleForm: function openImportSaleForm() {
        this.get('modal').register(this, _constants.MODALS.SALE_IMPORT, 'sales.modal.import').open(Ember.Object.create({
          christiesSaleId: null,
          url: null
        }));
      },
      openSpectrumSaleForm: function openSpectrumSaleForm(sale) {
        this.get('modal').register(this, _constants.MODALS.SALE_SPECTRUM, 'sales.modal.spectrum').open(Ember.Object.create({
          saleId: sale.get('id'),
          email: null,
          title: sale.get('largeTitle')
        }));
      },
      openRefreshSaleForm: function openRefreshSaleForm() {
        this.get('modal').register(this, _constants.MODALS.SALE_REFRESH, 'sales.modal.refresh').open(Ember.Object.create({
          christiesSaleId: null
        }));
      },
      closeImportSaleForm: function closeImportSaleForm() {
        this.get('modal').close(_constants.MODALS.SALE_IMPORT);
      },
      closeSpectrumSaleForm: function closeSpectrumSaleForm() {
        this.get('modal').close(_constants.MODALS.SALE_SPECTRUM);
      },
      closeRefreshSaleForm: function closeRefreshSaleForm() {
        this.get('modal').close(_constants.MODALS.SALE_REFRESH);
      },
      saveImportSaleForm: function saveImportSaleForm(form) {
        var _this2 = this;

        form.set('inProcess', true);

        this.get('ajax').post('sales/import', {
          christies_sale_id: form.get('christiesSaleId') || null,
          url: form.get('url') || null
        }, function () {
          _this2.send('closeImportSaleForm');
          _this2.get('flashMessage').showSuccess('Import has started.' + "\n" + 'You can wait for couple minutes, then to find the sale at the sales list');
        }, function (error) {
          _this2.get('flashMessage').showError(error);
        }, function () {
          form.set('inProcess', false);
        });
      },
      saveSpectrumSaleForm: function saveSpectrumSaleForm(form) {
        var _this3 = this;

        form.set('inProcess', true);

        this.get('ajax').$get('lots/spectrum/sale/' + form.get('saleId'), {
          email: form.get('email') || null,
          title: form.get('title') || null
        }, function () {
          _this3.send('closeSpectrumSaleForm');
          _this3.get('flashMessage').showSuccess('Report has started.');
        }, function (error) {
          _this3.get('flashMessage').showError(error);
        }, function () {
          form.set('inProcess', false);
        });
      },
      saveRefreshSaleForm: function saveRefreshSaleForm(form) {
        var _this4 = this;

        form.set('inProcess', true);

        this.get('ajax').post('sales/refresh', {
          christies_sale_id: form.get('christiesSaleId')
        }, function () {
          _this4.send('closeRefreshSaleForm');
          _this4.get('flashMessage').showSuccess('Updating has started.' + "\n" + 'You can wait for couple minutes, then to find the sale at the sales list');
        }, function (error) {
          _this4.get('flashMessage').showError(error);
        }, function () {
          form.set('inProcess', false);
        });
      }
    },

    _saveNewSaleTitle: function _saveNewSaleTitle(form, sale) {
      if (!form.get('title')) {
        return;
      }

      var item = this.get('store').createRecord('sale-title', {
        text: form.get('title'),
        preferred: true
      });

      sale.get('saleTitles').pushObject(item);

      return item.save();
    },
    _saveNewSaleSession: function _saveNewSaleSession(sale) {
      var session = this.get('store').createRecord('session', {
        sale: sale,
        saleId: sale.get('id')
      });

      sale.get('sessions').pushObject(session);

      return session.save();
    }
  });
});