define('cdata/models/organization-list-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    salesCount: attr('number'),
    lotsCount: attr('number'),
    organizationId: attr('number'),
    title: attr('string'),
    nationality: attr('string'),
    role: attr('string'),
    existenceDate: attr('string'),
    disambiguatingComment: attr('string'),
    lotsTotalUsd: attr('number'),
    approved: attr('boolean'),
    updatedAt: attr('string'),
    imageUrl: attr('string')
  });
});