define('cdata/pods/components/dates/edit-filter-fields/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['edit-filter-fields'],
    start: null,
    finish: null,
    bcStart: false,
    bcFinish: false,
    isBc: true,

    _start: Ember.computed('start', function () {
      return this.parseValue(this.get('start'));
    }),

    _finish: Ember.computed('finish', function () {
      return this.parseValue(this.get('finish'));
    }),

    /**
     * Calculate BC flags from year values
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var start = this.get('start');
      var finish = this.get('finish');

      if (start !== undefined && start !== 0 && start !== '' && start !== null) {
        this.set('bcStart', start && parseInt(start) < 0);
      }

      if (finish !== undefined && finish !== 0 && finish !== '' && finish !== null) {
        this.set('bcFinish', finish && parseInt(finish) < 0);
      }
    },


    /**
     * Prepare value for internal using
     * @param {String|Number} value
     * @returns {String}
     */
    parseValue: function parseValue(value) {
      if (0 === value || '0' === value) {
        return '0';
      }

      if (!value || value.length === 0) {
        return '';
      }

      value = parseInt(value);
      value = Math.abs(value);

      return value + '';
    },


    actions: {
      toggleBcStart: function toggleBcStart() {
        this.set('bcStart', !this.get('bcStart'));
        this.sendUpdate(null, null);
      },
      toggleBcFinish: function toggleBcFinish() {
        this.set('bcFinish', !this.get('bcFinish'));
        this.sendUpdate(null, null);
      },
      setStart: function setStart(text) {
        this.sendUpdate(text || '', null);
      },
      setFinish: function setFinish(text) {
        this.sendUpdate(null, text || '');
      }
    },

    /**
     * Send year values to parent component
     * @param {String} start
     * @param {String} finish
     */
    sendUpdate: function sendUpdate(start, finish) {
      if (start === null) {
        start = this.get('_start') || '';
      }
      if (finish === null) {
        finish = this.get('_finish') || '';
      }

      if (start && this.get('bcStart')) {
        start = '-' + start;
      }

      if (finish && this.get('bcFinish')) {
        finish = '-' + finish;
      }

      this.get('handleChanged')(start, finish);
    }
  });
});