define('cdata/mixins/editor/forms/image-relation-image', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    url: '',
    isLoadingUrl: Ember.computed.notEmpty('url'),
    loadImageFor: 'loadImageFor',

    session: Ember.inject.service(),
    userActions: Ember.inject.service(),

    actions: {
      setUrl: function setUrl(url) {
        var _this = this;

        if (this.get('url')) {
          // already
          return;
        }

        if (!this.isValidUrl(url)) {
          this.set('url', '');

          return;
        }

        this.set('url', url);

        Ember.$.ajax({
          type: 'POST',
          url: _constants.API_URL + '/images',
          data: JSON.stringify({ image: { remote_url: url } }),
          contentType: 'application/json',
          headers: this.get('session.authHeader'),
          success: function success(response) {
            _this.set('url', '');
            _this.fillRepresentation(response.image);
          },
          dataType: 'JSON'
        }).fail(this.failUrl.bind(this));
      },
      cancelUpload: function cancelUpload() {},


      /**
       * Upload image file and save id into image relation item
       * @param {Object} file
       */
      upload: function upload(file) {
        var _this2 = this;

        var headers = this.get('session.authHeader');
        delete headers['Content-type'];

        var upload = file.upload(_constants.API_URL + '/images', {
          fileKey: 'image[file]',
          headers: headers
        });

        upload.then(function (response) {
          _this2.fillRepresentation(response.body.image);
        }, function () {
          _this2.get('flashMessage').showError('File upload error');
        });
      }
    },

    user: Ember.computed('model.userId', function () {
      var userId = this.get('model.userId');
      if (userId && this.get('userActions.users')[userId]) {
        return this.get('userActions.users')[userId];
      }
    }),

    fillRepresentation: function fillRepresentation(result) {
      var item = this.get('model');
      if (item.get('url') && item.get('imageId')) {
        // already
        return;
      }

      this.sendAction('loadImageFor', item, result.id);

      Ember.run.later(function () {
        var editor = Ember.$('.in-place-editor');
        editor.animate({ scrollTop: editor.find('div').height() }, 600);
      });
    },
    failUrl: function failUrl(req) {
      var responseError = req.responseJSON && req.responseJSON.errors && req.responseJSON.errors.image && req.responseJSON.errors.image.url;
      this.set('url', '');
      var error = responseError || req.statusText || 'Unknown error';
      this.get('flashMessage').showError('Oops! Please check you url and repeat (' + error + ')');
    },
    isValidUrl: function isValidUrl() {
      return true;
    }
  });
});