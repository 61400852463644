define('cdata/models/auction-house', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    //sales: hasMany('sales', { async: true }),
    //locations: hasMany('locations', { async: true }),
    //terms: hasMany('terms', { async: false }),

    name: attr('string'),
    updatedAt: attr('date'),

    isPremiumPrice: attr('boolean'),
    lotsCount: attr('number'),
    approvedLotsCount: attr('number'),
    unapprovedLotsCount: attr('number'),

    salesCount: attr('number'),
    approvedSalesCount: attr('number'),
    unapprovedSalesCount: attr('number'),

    categoryHouse: attr('string'),

    approved: attr('boolean', { defaultValue: false })
  });
});