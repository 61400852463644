define('cdata/router', ['exports', 'cdata/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('vocabularies', function () {});

    this.route('auction-houses', function () {});

    this.route('init', function () {
      this.route('loader');
      this.route('login');
    });

    this.route('auth', function () {
      this.route('callback', { path: '/:provider/callback' });
    });

    this.route('lots', function () {
      this.route('show', { path: '/:lot_id' }, function () {
        this.route('entities.subject', { resetNamespace: true, path: '/:entity_id' });
      });
    });

    this.route('organizations', function () {
      this.route('show', { path: '/:organization_id' });
    });

    this.route('entities', function () {
      this.route('show', { path: '/:entity_id' });
    });

    this.route('marks', function () {
      this.route('show', { path: '/:mark_id' });
    });

    this.route('sales', function () {
      this.route('show', { path: '/:sale_id' }, function () {
        this.route('sessions.subject', { resetNamespace: true, path: '/:session_id' });
      });
      this.route('lots', { path: '/:sale_id/lots' });
      this.route('lots-session', { path: '/:sale_id/session/:session_id/lots' });
    });

    this.route('people', function () {
      this.route('show', { path: '/:person_id' });
    });

    this.route('places', function () {});

    this.route('stats', function () {});

    this.route('reports', function () {});

    this.route('sessions', function () {
      this.route('show', { path: '/:session_id' });
    });

    this.route('comparables', function () {
      this.route('list');
      this.route('item');
      this.route('entity', { path: '/entity/:id' });
    });
    this.route('sale-rooms');
    this.route('exports');
    this.route('user-reviews');
  });

  exports.default = Router;
});