define('cdata/services/transition-spot', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    // keep transition for rerun after 'init/loader' is happened
    backTransition: null,

    keepTransition: function keepTransition(context, transition) {
      this.set('backTransition', transition);
    },
    restoreTransition: function restoreTransition() {
      this.get('backTransition').retry();
      this.set('backTransition', null);
    },


    prevUrl: Ember.computed({
      get: function get() {
        return localStorage.getItem('prevUrl');
      },
      set: function set(_, value) {
        if (value) {
          localStorage.setItem('prevUrl', value);
        } else {
          localStorage.removeItem('prevUrl');
        }

        return value;
      }
    }),

    keepPrevUrl: function keepPrevUrl(url) {
      this.set('prevUrl', url);
    },
    restorePrevUrl: function restorePrevUrl() {
      var prevUrl = this.get('prevUrl');
      window.location.replace(prevUrl);
    },
    clear: function clear() {
      this.set('prevUrl', null);
      this.set('backTransition', null);
    },


    /**
     * redirect after login or/and initialization
     */
    redirect: function redirect(secondRedirectCallback) {
      var backTransition = this.get('backTransition');

      if (backTransition && backTransition.targetName !== 'index') {
        this.restoreTransition();
      } else {
        secondRedirectCallback();
      }
    }
  });
});