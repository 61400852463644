define('cdata/pods/reports/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    getSalesReport: function getSalesReport(params) {
      return this.get('ajax').postRequest('reports/sales', { filter: params });
    },
    getFieldsReport: function getFieldsReport(params) {
      return this.get('ajax').postRequest('reports/fields', { filter: params });
    },
    getFieldsByUserReport: function getFieldsByUserReport(params) {
      return this.get('ajax').postRequest('reports/fields_by_user', { filter: params });
    },
    getDetails: function getDetails(params) {
      return this.get('ajax').postRequest('reports/fields_by_sales', { filter: params });
    }
  });
});