define('cdata/pods/components/controls/input-checkbox/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'input',
    type: 'checkbox',
    attributeBindings: ['checked', 'option:value', 'type', 'disabled'],
    option: true,

    checked: Ember.computed('_value', 'option', function () {
      return this.get('_value') === this.get('option');
    }),

    click: function click(e) {
      if (e && e.shiftKey === true && this.attrs.handleChangedShift) {
        this.attrs.handleChangedShift(this.get('option'));
      } else {
        this.attrs.handleChanged(this.get('option'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('_value', this.get('value'));
    }
  });
});