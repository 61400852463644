define('cdata/pods/components/marks/edit/edit-forms-categories/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    categoryId: null,

    label: Ember.computed('categoryId', function () {
      var parts = [];
      var cat = this.get('vocab').getItemById(this.get('categoryId'), 'cdataCategories');
      if (cat.get('parentId')) {
        parts.push(this.get('vocab.cdataCategoriesLabels')[cat.get('parentId')]);
      }
      parts.push(this.get('vocab.cdataCategoriesLabels')[cat.get('id')]);

      return parts.join(' > ');
    })
  });
});