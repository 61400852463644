define('cdata/mixins/editor/saving-cases', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * @param {Object} relation model's relationship with source (PersonNameSource, PersonBiographySource etc)
     */
    _saveRelationWithSource: function _saveRelationWithSource(relation) {
      var _this = this;

      var source = relation.get('source');

      return this._saveSource(source).then(function () {
        if (source.get('id')) {
          relation.set('sourceId', source.get('id'));
        }
        if (relation.get('hasDirtyAttributes')) {
          return _this._handleSaving(relation.save());
        }
      });
    },


    /**
     * @param {Object} relation model's relationship with image (PersonImage, OrganizationImage etc)
     */
    _saveRelationWithImage: function _saveRelationWithImage(relation) {
      var promises = [];

      var image = relation.get('image.content') || relation.get('image');
      if (image.get('hasDirtyAttributes')) {
        promises.push(image.save());
      }

      if (relation.get('hasDirtyAttributes')) {
        promises.push(relation.save());
      }

      return this._handleSaving(promises);
    }
  });
});