define('cdata/pods/components/lots/view/lot-header-little/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'lots-lot-header little',

    navigateToLotInSale: 'navigateToLotInSale',

    lotTitle: Ember.computed('model.lotTitles.@each.{preferred,text}', function () {
      var title = this.get('model.lotTitles').filterBy('preferred').get('firstObject');
      if (!title) {
        return this.get('entityTitle');
      }

      return title ? title.get('text') : '';
    }),

    cdataId: Ember.computed.alias('model.id'),

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.set('navigateToLotInProgress', false);
    },


    actions: {
      navigateToLot: function navigateToLot(field) {
        var lotId = this.get('model').get(field);

        this.set('navigateToLotInProgress', true);

        this.sendAction('navigateToLotInSale', lotId);
      }
    }
  });
});