define('cdata/pods/exports/controller', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    apiUrl: _constants.API_URL,
    ajax: Ember.inject.service(),
    actions: {
      download: function download(id) {
        this.get('ajax').download('exports/' + id, 'Export report ' + id + '.csv');
      }
    }
  });
});