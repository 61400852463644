define("cdata/pods/components/d3-zoomable-sunburst/utils/setup-pie-values", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var minPercent = 2;

  var setNodeDisplayValue = function setNodeDisplayValue(node) {
    var size = node.data.size,
        children = node.children;

    var childSumm = children && children.reduce(function (acc, item) {
      return acc += item.displayValue;
    }, 0);

    node.displayValue = size || childSumm || 0;
  };

  var setNodePercentValue = function setNodePercentValue(node) {
    var displayValue = node.displayValue,
        parent = node.parent;

    if (parent) {
      var percentage = 100 * displayValue / parent.displayValue;

      node.percentValue = percentage;
    }
  };

  var setChildrenExpectedPercent = function setChildrenExpectedPercent(_ref) {
    var children = _ref.children;

    if (children) {
      var smallParts = children.filter(function (_ref2) {
        var percentValue = _ref2.percentValue;
        return percentValue < minPercent;
      });
      var donorParts = children.filter(function (_ref3) {
        var percentValue = _ref3.percentValue;
        return percentValue > minPercent;
      });
      var donorsPrecent = donorParts.reduce(function (acc, _ref4) {
        var percentValue = _ref4.percentValue;
        return acc += percentValue;
      }, 0);
      var excessPercent = smallParts.reduce(function (acc, _ref5) {
        var percentValue = _ref5.percentValue;
        return acc += minPercent - percentValue;
      }, 0);
      var donorPartsBuffer = donorParts.length * minPercent;
      var isDonorsSizeEnough = donorsPrecent - excessPercent >= donorPartsBuffer;

      if (smallParts.length && isDonorsSizeEnough) {
        var donorsPrecentBucket = donorsPrecent;
        var excessPercentBucket = excessPercent;

        donorParts.forEach(function (item) {
          var donorValue = item.percentValue * excessPercentBucket / donorsPrecentBucket;

          if (item.percentValue - donorValue < minPercent) {
            donorValue = item.percentValue - minPercent;
          }

          donorsPrecentBucket -= item.percentValue;
          excessPercentBucket -= donorValue;
          item.expectedPercent = item.percentValue - donorValue;
        });

        smallParts.forEach(function (item) {
          return item.expectedPercent = minPercent;
        });
      } else {
        smallParts.forEach(function (item) {
          return item.expectedPercent = item.percentValue;
        });
        donorParts.forEach(function (item) {
          return item.expectedPercent = item.percentValue;
        });
      }
    }
  };

  var setTmpPartSize = function setTmpPartSize(node) {
    var displayValue = node.displayValue,
        expectedPercent = node.expectedPercent,
        parent = node.parent;


    node.tmpValue = parent ? parent.tmpValue / 100 * expectedPercent : displayValue;
  };

  var setNodePartSize = function setNodePartSize(node) {
    var children = node.children;


    if (!children) {
      node.value = node.tmpValue;
    } else {
      node.value = children.reduce(function (acc, item) {
        return acc += item.value;
      }, 0);
    }
  };

  var setupPieValues = exports.setupPieValues = function setupPieValues(root) {
    return root.eachAfter(setNodeDisplayValue).sort(function (a, b) {
      return b.displayValue - a.displayValue;
    }).eachAfter(setNodePercentValue).each(setChildrenExpectedPercent).each(setTmpPartSize).eachAfter(setNodePartSize);
  };
});