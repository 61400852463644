define('cdata/pods/components/d3-zoomable-sunburst/utils/index', ['exports', 'd3', 'ramda', 'cdata/pods/components/d3-zoomable-sunburst/utils/setup-pie-values', 'cdata/pods/components/d3-zoomable-sunburst/utils/setup-colors'], function (exports, _d, _ramda, _setupPieValues, _setupColors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.whenElementInteractable = exports.getTitle = exports.isNotLastLevel = exports.isLastLevel = exports.makeLabelTransform = exports.labelVisible = exports.arcVisible = exports.makeArc = exports.getTargetСoordinates = exports.setupLabelText = exports.makeTree = exports.makeSorce = undefined;
  var makeSorce = exports.makeSorce = function makeSorce(data) {
    var root = _d.default.hierarchy((0, _ramda.clone)(data));

    (0, _setupPieValues.setupPieValues)(root);
    (0, _setupColors.setupColors)(root, _d.default.interpolateSpectral);

    root.each(function (node) {
      node.data.value = node.value;
      node.data.displayValue = node.displayValue;
      node.data.color = node.color;
    });

    return root.data;
  };

  var makeTree = exports.makeTree = function makeTree(data) {
    var source = makeSorce(data);
    var tree = _d.default.hierarchy(source).sort(function (a, b) {
      return b.data.displayValue - a.data.displayValue;
    });

    tree.each(function (node) {
      node.value = node.data.value;
      node.displayValue = node.data.displayValue;
      node.color = node.data.color;
    });

    var root = _d.default.partition().size([2 * Math.PI, tree.height + 1])(tree);

    root.each(function (d) {
      return d.current = d;
    });

    return root;
  };

  var getPercentageValue = function getPercentageValue(value, total) {
    var percentage = 100 * value / total;
    var percentageStr = percentage >= 1 ? Math.floor(percentage) : '< 1';

    return percentageStr ? ' (' + percentageStr + '%)' : '';
  };

  var percentageLabel = function percentageLabel(d) {
    return d.data.name + ' ' + getPercentageValue(d.displayValue, d.parent.displayValue);
  };

  var setupLabelText = exports.setupLabelText = function setupLabelText(selection, labelClass) {
    var labels = selection.append('tspan').attr('class', labelClass).text(percentageLabel);

    labels.filter(function (d) {
      return d.data.name.length > 18;
    }).attr('textLength', 90).attr('lengthAdjust', 'spacingAndGlyphs');

    return labels;
  };

  var zeroOneRange = (0, _ramda.compose)((0, _ramda.max)(0), (0, _ramda.min)(1));

  var getTargetСoordinates = exports.getTargetСoordinates = function getTargetСoordinates(d, p) {
    return {
      x0: zeroOneRange((d.x0 - p.x0) / (p.x1 - p.x0)) * 2 * Math.PI,
      x1: zeroOneRange((d.x1 - p.x0) / (p.x1 - p.x0)) * 2 * Math.PI,
      y0: Math.max(0, d.y0 - p.depth),
      y1: Math.max(0, d.y1 - p.depth)
    };
  };

  var makeArc = exports.makeArc = function makeArc(radius) {
    return _d.default.arc().startAngle(function (d) {
      return d.x0;
    }).endAngle(function (d) {
      return d.x1;
    }).padAngle(function (d) {
      return Math.min((d.x1 - d.x0) / 2, 0.005);
    }).padRadius(radius * 1.5).innerRadius(function (d) {
      return d.y0 * radius;
    }).outerRadius(function (d) {
      return Math.max(d.y0 * radius, d.y1 * radius - 1);
    });
  };

  var arcVisible = exports.arcVisible = function arcVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
  };

  var labelVisible = exports.labelVisible = function labelVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
  };

  var makeLabelTransform = exports.makeLabelTransform = function makeLabelTransform(radius) {
    return function (d) {
      var x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
      var y = (d.y0 + d.y1) / 2 * radius;

      return 'rotate(' + (x - 90) + ') translate(' + y + ',0) rotate(' + (x < 180 ? 0 : 180) + ')';
    };
  };

  var isLastLevel = exports.isLastLevel = (0, _ramda.propEq)('height', 1);

  var isNotLastLevel = exports.isNotLastLevel = (0, _ramda.compose)(_ramda.not, isLastLevel);

  var format = _d.default.format(',d');

  var getTitle = exports.getTitle = function getTitle(node) {
    var breadbumps = node.ancestors().map(function (d) {
      return d.data.name;
    }).reverse().slice(1).join('/');

    return breadbumps + ' ' + format(node.displayValue);
  };

  var isElementInteractable = function isElementInteractable(element) {
    return parseInt(element.getAttribute('fill-opacity') || 1) === 1;
  };

  var whenElementInteractable = exports.whenElementInteractable = function whenElementInteractable(handler) {
    return function (node) {
      if (isElementInteractable(this)) {
        return handler(node, this);
      }
    };
  };
});