define('cdata/pods/components/site/menu-top/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'menu-top',

    pageType: Ember.inject.service(),

    classNameBindings: ['isShowType:show'],

    isShowType: Ember.computed.bool('pageType.show'),

    entitiesOpened: false,

    actions: {
      openOtherEntities: function openOtherEntities() {
        this._removeEntitiesDebounce();
        this.set('entitiesOpened', true);
      },
      closeOtherEntities: function closeOtherEntities() {
        var _this = this;

        this._removeEntitiesDebounce();
        this._debouncer = Ember.run.debounce(this, function () {
          _this.set('entitiesOpened', false);
        }, 100);
      }
    },

    _removeEntitiesDebounce: function _removeEntitiesDebounce() {
      if (this._debouncer) {
        Ember.run.cancel(this._debouncer);
      }
    }
  });
});