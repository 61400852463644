define('cdata/pods/components/people/modals/person-or-organization/component', ['exports', 'cdata/mixins/modals/component-mixin', 'cdata/constants'], function (exports, _componentMixin, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentMixin.default, {
    modalClassId: _constants.MODALS.PERSON_OR_ORGANIZATION,
    classNames: ['context-modal', _constants.MODALS.PERSON_OR_ORGANIZATION, 'edit-form'],

    closeNewPersonOrOrganizationForm: 'closeNewPersonOrOrganizationForm',

    actions: {
      createOrganization: function createOrganization() {
        this.sendAction('openNewOrganizationFormInsteadChoice', this.get('model.initialName'));
      },
      createPerson: function createPerson() {
        this.sendAction('openNewPersonFormInsteadChoice', this.get('model.initialName'));
      },
      close: function close() {
        this.sendAction('closeNewPersonOrOrganizationForm');
      },
      cancel: function cancel() {
        this.sendAction('closeNewPersonOrOrganizationForm');
      }
    }
  });
});