define('cdata/pods/components/site/cdata-slider/component', ['exports', 'cdata/helpers/slider-scale', 'numeral'], function (exports, _sliderScale, _numeral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['cdata-slider'],
    classNameBindings: ['inactive'],

    init: function init() {
      this._super.apply(this, arguments);

      this.setOldAttrs();
    },
    setOldAttrs: function setOldAttrs() {
      this.set('oldAttrs', {
        min: this.get('min'),
        max: this.get('max'),
        left: this.get('left'),
        right: this.get('right')
      });
    },


    inactive: Ember.computed('min', 'max', function () {
      return this.get('min') === this.get('max');
    }),

    type: 'year',

    min: -1000,
    max: 2018,

    defaultLeft: Ember.computed.alias('min'),
    defaultRight: Ember.computed.alias('max'),

    initLeft: null,
    initRight: null,

    curLeft: undefined,
    curRight: undefined,

    brush: null,
    group: null,

    _ready: false,

    isMinLeft: Ember.computed('min', 'curLeft', function () {
      return !this.get('curLeft') || this.get('curLeft') === this.get('min');
    }),

    isMaxRight: Ember.computed('max', 'curRight', function () {
      return !this.get('curRight') || this.get('curRight') === this.get('max');
    }),

    formatLeft: Ember.computed('curLeft', function () {
      var left = this.get('curLeft');
      var type = this.get('type');

      if (left < 0) {
        return Math.abs(left) + ' BC';
      }

      if (type === 'price') {
        return '$' + (0, _numeral.default)(left).format('0,0');
      }
      if (type === 'calculated') {
        return '$' + (0, _numeral.default)(left * 10).format('0,0');
      }

      return left;
    }),

    formatRight: Ember.computed('curRight', function () {
      var right = this.get('curRight');
      var type = this.get('type');

      if (right < 0) {
        return Math.abs(right) + ' BC';
      }

      if (type === 'price') {
        return '$' + (0, _numeral.default)(right).format('0,0');
      }
      if (type === 'calculated') {
        return '$' + (0, _numeral.default)(right * 10).format('0,0');
      }

      return right;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var left = this.get('left') ? parseInt(this.get('left')) : this.get('defaultLeft');
      var right = this.get('right') ? parseInt(this.get('right')) : this.get('defaultRight');

      this.drawBrush(parseInt(this.get('min')), parseInt(this.get('max')), left, right);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var oldAttrs = this.get('oldAttrs');

      var min = this.get('min') && parseInt(this.get('min'));
      var max = this.get('max') && parseInt(this.get('max'));

      if (!oldAttrs) {
        this.setOldAttrs();

        return;
      }

      var left = this.get('left');
      var right = this.get('right');

      left = left || left === 0 ? parseInt(left) : null;
      right = right || right === 0 ? parseInt(right) : null;

      var oldMax = oldAttrs.max && parseInt(oldAttrs.max);
      var oldMin = oldAttrs.min && parseInt(oldAttrs.min);
      var oldLeft = oldAttrs.left && parseInt(oldAttrs.left);
      var oldRight = oldAttrs.right && parseInt(oldAttrs.right);

      var minMaxChanged = min !== oldMin || max !== oldMax;
      var leftRightChanged = left !== oldLeft || right !== oldRight;

      if (minMaxChanged) {
        Ember.run.schedule('afterRender', function () {
          _this.drawBrush(min, max, left, right);
        });
        this.setOldAttrs();

        return;
      }

      if (!leftRightChanged && !left && !right) {
        return;
      }

      min = min !== undefined ? min : this.get('min');
      max = max !== undefined ? max : this.get('max');

      left = left || this.get('defaultLeft');
      right = right || this.get('defaultRight');
      left = left && left >= min ? left : min;
      right = right && left <= max ? right : max;

      Ember.run.schedule('afterRender', function () {
        var brush = _this.get('brush');
        brush.extent([left, right]);
        _this.set('curLeft', left);
        _this.set('curRight', right);
        brush(_this.get('group'));
        _this.fixMarginDisplay();
        _this._ready = true;
      });

      this.setOldAttrs();
    },
    getScale: function getScale(min, max) {
      return _sliderScale.default.compute([this.get('type'), min, max]);
    },
    drawBrush: function drawBrush(min, max, left, right) {
      var _this2 = this;

      this.destroyBrush();

      left = left && left >= min ? left : min;
      right = right && left <= max ? right : max;

      this.set('curLeft', left);
      this.set('curRight', right);

      if (min === max) {
        return;
      }

      var id = this.$().attr('id');
      var svg = d3.select('#' + id + ' svg.slider');
      var scaleData = this.getScale(min, max);
      var scale = d3.scale.linear().domain(scaleData[0]).range(scaleData[1]);

      var brush = d3.svg.brush();

      brush.x(scale);

      brush.extent([left, right]);

      brush.on('brushend', function () {
        _this2.setCurrentPos();
        _this2.sendChanges();
      });

      brush.on('brushstart', function () {});

      brush.on('brush', function () {
        _this2.setCurrentPos();
      });

      var g = svg.append('g');

      brush(g);

      g.attr('transform', 'translate(5, 0)');
      g.selectAll('rect').attr('height', 3).attr('y', 10);
      g.selectAll('.resize rect').attr('height', 18).attr('width', 18).attr('rx', 9).attr('ry', 9).attr('y', 2).attr('x', -9);
      g.selectAll('.resize').style('cursor', 'pointer');
      g.selectAll('.background').style({
        fill: '#ededed',
        visibility: 'visible'
      });
      g.selectAll('.extent').style({ fill: '#ff242c', visibility: 'visible' });
      g.selectAll('.resize rect').style({
        fill: 'white',
        visibility: 'visible',
        stroke: '#d7d7d7',
        'stroke-width': 2
      });

      this.set('brush', brush);
      this.set('group', g);
    },
    destroyBrush: function destroyBrush() {
      var id = this.$().attr('id');
      d3.select('#' + id + ' svg.slider').selectAll('*').remove();
    },
    setCurrentPos: function setCurrentPos() {
      var brush = this.get('brush');
      var leftPos = brush.extent()[0];
      var rightPos = brush.extent()[1];

      if (this.get('min') >= rightPos) {
        rightPos = this.get('min');
      }

      if (this.get('min') >= leftPos) {
        leftPos = this.get('min');
      }

      if (this.get('max') <= rightPos) {
        rightPos = this.get('max');
      }

      if (this.get('max') <= leftPos) {
        leftPos = this.get('max');
      }

      brush.extent([leftPos, rightPos]);

      this.set('curLeft', parseInt(leftPos));
      this.set('curRight', parseInt(rightPos));

      this.fixMarginDisplay();
    },
    sendChanges: function sendChanges() {
      var left = this.get('curLeft') > this.get('min') ? this.get('curLeft') : undefined;
      var right = this.get('curRight') < this.get('max') ? this.get('curRight') : undefined;

      this.sendAction('handleChanged', left, right);
    },
    fixMarginDisplay: function fixMarginDisplay() {
      if (this.get('min') === this.get('curRight')) {
        this.get('group').selectAll('.resize.w').style({ display: '' });
      }

      if (this.get('max') === this.get('curLeft')) {
        this.get('group').selectAll('.resize.e').style({ display: '' });
      }
    }
  });
});