define('cdata/mixins/components/summary-info-comments', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    addNewComment: 'addNewComment',
    deleteComment: 'deleteComment',
    openCommentForm: 'openCommentForm',

    actions: {
      addNewComment: function addNewComment() {
        this.sendAction.apply(this, ['addNewComment'].concat(Array.prototype.slice.call(arguments)));
      },
      deleteComment: function deleteComment() {
        this.sendAction.apply(this, ['deleteComment'].concat(Array.prototype.slice.call(arguments)));
      },
      openCommentForm: function openCommentForm() {
        var comment = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        var options = {};

        if (comment) {
          options.comment = comment;
        }

        if (this.actions.openForms) {
          this.send('openForms', 'edit-forms-comments', options);
        } else {
          this.sendAction('openCommentForm', comment);
        }
      }
    }
  });
});