define('cdata/services/merge-list', ['exports', 'cdata/constants', 'ember-inflector'], function (exports, _constants, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Service.extend({
    modal: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessage: Ember.inject.service(),

    items: [],

    target: null,

    addToList: function addToList(items) {
      var _this = this;

      this.set('items', []);

      items.forEach(function (item) {
        if (!_this.get('items').findBy('id', item.get('id'))) {
          _this.get('items').pushObject(item);
        }
      });
    },
    setTarget: function setTarget(item) {
      this.clearTarget();
      this.set('target', item);
    },
    clearTarget: function clearTarget() {
      this.set('target', null);
    },
    markAsMerged: function markAsMerged() {
      this.get('items').rejectBy('id', this.getTargetId()).forEach(function (item) {
        item.set('_merged', true);
      });
    },
    clear: function clear() {
      this.clearTarget();

      this.set('items', []);
    },
    getTargetId: function getTargetId() {
      return this.get('target.id');
    },
    getMergeIds: function getMergeIds() {
      return this.get('items').rejectBy('id', this.getTargetId()).mapBy('id');
    },
    save: function save(store, name, id, ids) {
      var _this2 = this;

      var targetName = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      var successCallback = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;

      var modal = this.get('modal');

      targetName = targetName || name;

      var names = (0, _emberInflector.pluralize)(name);
      var endpoint = names + '/merge'; // entities/merge
      var key = name + '_merge'; // entity_merge
      var targetField = 'target_' + targetName + '_id'; // target_entity_id
      var sourceField = name + '_ids'; // entity_ids
      var url = _constants.API_URL + '/' + endpoint;
      var listModel = name + '-list-item'; // entity-list-item
      var listKey = name + '_list_item';
      var existing = store.peekRecord(listModel, id);

      // { entity_merge: { target_entity_id: id, entity_ids: ids } }
      var params = {};
      params[key] = {};
      params[key][targetField] = id;
      params[key][sourceField] = ids;

      Ember.$.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(params),
        contentType: 'application/json',
        headers: this.get('session.authHeader'),
        dataType: 'JSON',
        success: function success(response) {
          Ember.run(function () {
            if (existing) {
              var data = response[listKey];
              var props = {};
              var _iteratorNormalCompletion = true;
              var _didIteratorError = false;
              var _iteratorError = undefined;

              try {
                for (var _iterator = Object.entries(data)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                  var _ref = _step.value;

                  var _ref2 = _slicedToArray(_ref, 2);

                  var _key = _ref2[0];
                  var value = _ref2[1];

                  if (_key !== 'id') {
                    props[_key.camelize()] = value;
                  }
                }
              } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                  }
                } finally {
                  if (_didIteratorError) {
                    throw _iteratorError;
                  }
                }
              }

              existing.setProperties(props);
            }

            if (successCallback && typeof successCallback === 'function') {
              successCallback();
            }

            _this2.get('flashMessage').showSuccess('Merging completed');
            modal.trigger(name + '.merge.success');
          });
        },
        complete: function complete() {
          modal.trigger(name + '.merge.finally');
        }
      }).fail(function (error) {
        modal.trigger(name + '.merge.error', error);
        _this2.get('flashMessage').showError(error);
      });
    }
  });
});