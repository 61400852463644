define('cdata/pods/components/places/edit/edit-forms-place/component', ['exports', 'cdata/mixins/editor/edit-forms-template'], function (exports, _editFormsTemplate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editFormsTemplate.default)(), {
    classNames: ['ui grid form'],

    loadPlaceFor: 'loadPlaceFor',
    loadParentPlaceFor: 'loadParentPlaceFor',
    loadCurrentPlaceFor: 'loadCurrentPlaceFor',

    subjectFilter: { clear: true },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.sendAction('loadPlaceFor', this, this.get('model.placeId'));
    },


    actions: {
      setParent: function setParent(_, parent) {
        this.sendAction('loadParentPlaceFor', this.get('place'), parent.get('id'));
      },
      setCurrent: function setCurrent(_, current) {
        this.sendAction('loadCurrentPlaceFor', this.get('place'), current.get('id'));
      },
      save: function save() {
        this.sendAction('save', this.get('place'));
      },
      cancel: function cancel() {
        this.sendAction('cancel', this.get('place'));
      }
    }
  });
});