define('cdata/pods/lots/index/route', ['exports', 'cdata/mixins/lists/route-list', 'cdata/mixins/lists/format-filter-creators', 'cdata/pods/lots/lot-assign-mixin', 'cdata/pods/lots/lot-comparables', 'cdata/mixins/route-create-modals'], function (exports, _routeList, _formatFilterCreators, _lotAssignMixin, _lotComparables, _routeCreateModals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeList.default, _formatFilterCreators.default, _lotAssignMixin.default, _routeCreateModals.default, _lotComparables.default, {
    prevNextEditor: Ember.inject.service(),

    model: function model(params) {
      return this.infinity(params, 'lot-list-item');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('prevNextEditor', this.get('prevNextEditor'));
    }
  });
});