define('cdata/utils/computed', ['exports', 'cdata/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.computedWithoutByIds = undefined;
  var computedWithoutByIds = exports.computedWithoutByIds = function computedWithoutByIds(listKey, idsKey, idKey) {
    return Ember.computed(listKey + '.[]', idsKey + '.[]', function () {
      var list = this.get(listKey);
      var ids = this.get(idsKey);

      return (0, _utils.withoutByIds)(list, ids, idKey);
    });
  };
});