define('cdata/pods/components/people/view/person-item/summary-info/component', ['exports', 'ember-data', 'cdata/helpers/format-source', 'cdata/helpers/format-award', 'cdata/helpers/format-education', 'cdata/helpers/format-membership', 'cdata/mixins/components/render-with-citation', 'cdata/mixins/components/summary-info-comments', 'cdata/utils/clustering'], function (exports, _emberData, _formatSource, _formatAward, _formatEducation, _formatMembership, _renderWithCitation, _summaryInfoComments, _clustering) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var PromiseArray = _emberData.default.PromiseArray;
  exports.default = Ember.Component.extend(_summaryInfoComments.default, _renderWithCitation.default, {
    classNames: 'person-item--info',
    tagName: 'article',

    openPersonForms: 'openPersonForms',
    save: 'save',

    actions: {
      openForms: function openForms() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.sendAction.apply(this, ['openPersonForms'].concat(_toConsumableArray(args)));
      },
      setApproved: function setApproved() {
        this.set('model.approved', true);
        this.sendAction('save');
      },
      setNew: function setNew() {
        this.set('model.approved', false);
        this.sendAction('save');
      }
    },

    preferredName: Ember.computed('model.personNames.@each.{text,preferred}', function () {
      var names = this.get('model.personNames').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, names);
    }),

    otherNames: Ember.computed('model.personNames.@each.{text,preferred}', function () {
      var names = this.get('model.personNames').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, names);
    }),

    relatedPublications: Ember.computed('model.personRelatedPublications.length', 'model.personRelatedPublications.@each.{preferred,sourceId,source,sourcePage}', function () {
      var publications = this.get('model.personRelatedPublications');

      return publications.map(function (item) {
        return _formatSource.default.compute([item.get('source'), item.get('sourcePage')]);
      });
    }),

    awards: Ember.computed('model.personAwards.@each.{_name,awardId,givingYear}', function () {
      var awards = this.get('model.personAwards');

      return awards.map(function (item) {
        return _formatAward.default.compute([item]);
      });
    }),

    educations: Ember.computed('model.personOrganizationEducations.@each.{_title,_place,dateText,academicDegreeVocabularyItemId,academicSpecializationVocabularyItemId}', function () {
      var _this = this;

      var awards = this.get('model.personOrganizationEducations');

      return awards.map(function (item) {
        return _formatEducation.default.compute([item], {
          degreesLabels: _this.get('vocab.academicDegreesLabels'),
          specializationsLabels: _this.get('vocab.academicSpecializationsLabels')
        });
      });
    }),

    memberships: Ember.computed('model.personOrganizationMemberships.@each.{_title,_place,dateText,personRoleVocabularyItemId}', function () {
      var _this2 = this;

      var awards = this.get('model.personOrganizationMemberships');

      return awards.map(function (item) {
        return _formatMembership.default.compute([item], {
          rolesLabels: _this2.get('vocab.rolesLabels')
        });
      });
    }),

    collections: Ember.computed('model.personPublicCollections.@each.{_title,_place}', function () {
      var awards = this.get('model.personPublicCollections');

      return awards.map(function (item) {
        var result = item.get('_title');
        if (item.get('_place')) {
          result += ', ' + item.get('_place');
        }

        return result;
      });
    }),

    personRelationships: Ember.computed('model.personRelationships.@each.{_title,_place,personRoleVocabularyItemId}', function () {
      var _this3 = this;

      var people = this.get('model.personRelationships');

      return people.map(function (item) {
        var result = item.get('_name');
        if (item.get('_description')) {
          result += ' (' + item.get('_description') + ')';
        }
        if (item.get('personRoleVocabularyItemId')) {
          result += ', ' + _this3.get('vocab.rolesLabels')[item.get('personRoleVocabularyItemId')];
        }

        return result;
      });
    }),

    personOrganizations: Ember.computed('model.personOrganizations.@each.{_title,_place,personRoleVocabularyItemId}', function () {
      var _this4 = this;

      var people = this.get('model.personOrganizations');

      return people.map(function (item) {
        var result = item.get('_title');
        if (item.get('_place')) {
          result += '; ' + item.get('_place');
        }
        if (item.get('personRoleVocabularyItemId')) {
          result += '; ' + _this4.get('vocab.rolesLabels')[item.get('personRoleVocabularyItemId')];
        }

        return result;
      });
    }),

    preferredPhone: Ember.computed('model.personPhones.@each.{preferred,number}', function () {
      var phone = this.get('model.personPhones').filterBy('preferred').get('firstObject');

      return phone && phone.get('number');
    }),

    otherPhones: Ember.computed('model.personPhones.@each.{preferred,number}', function () {
      var phones = this.get('model.personPhones').rejectBy('preferred');

      return phones.mapBy('number');
    }),

    preferredEmail: Ember.computed('model.personEmails.@each.{preferred,email}', function () {
      var email = this.get('model.personEmails').filterBy('preferred').get('firstObject');

      return email && email.get('email');
    }),

    otherEmails: Ember.computed('model.personEmails.@each.{preferred,email}', function () {
      var emails = this.get('model.personEmails').rejectBy('preferred');

      return emails.mapBy('email');
    }),

    preferredBirthPlace: Ember.computed('model.personBirthPlaces.@each.{preferred,_name}', function () {
      var personBirthPlaces = this.get('model.personBirthPlaces').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('_name');
      }, personBirthPlaces);
    }),

    otherBirthPlaces: Ember.computed('model.personBirthPlaces.@each.{preferred,_name}', function () {
      var personBirthPlaces = this.get('model.personBirthPlaces').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('_name');
      }, personBirthPlaces);
    }),

    preferredDeathPlace: Ember.computed('model.personDeathPlaces.@each.{preferred,_name}', function () {
      var personDeathPlaces = this.get('model.personDeathPlaces').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('_name');
      }, personDeathPlaces);
    }),

    otherDeathPlaces: Ember.computed('model.personDeathPlaces.@each.{preferred,_name}', function () {
      var personDeathPlaces = this.get('model.personDeathPlaces').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('_name');
      }, personDeathPlaces);
    }),

    preferredBiography: Ember.computed('model.personBiographies.@each.{preferred,text}', function () {
      var personBiographies = this.get('model.personBiographies').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, personBiographies);
    }),

    otherBiographies: Ember.computed('model.personBiographies.@each.{preferred,text}', function () {
      var personBiographies = this.get('model.personBiographies').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, personBiographies);
    }),

    preferredRole: Ember.computed('model.personRoles.@each.{roleId,preferred}', function () {
      var roles = this.get('model.personRoles').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('role.name');
      }, roles);
    }),

    otherRoles: Ember.computed('model.personRoles.@each.{roleId,preferred}', function () {
      var roles = this.get('model.personRoles').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('role.name');
      }, roles);
    }),

    preferredNationality: Ember.computed('model.personNationalities.@each.{preferred,nationalityVocabularyItemId}', 'vocab.nationalitiesLabels', function () {
      var _this5 = this;

      var personNationalities = this.get('model.personNationalities').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return _this5.get('vocab.nationalitiesLabels.' + item.get('nationalityVocabularyItemId'));
      }, personNationalities);
    }),

    otherNationalities: Ember.computed('model.personNationalities.@each.{preferred,nationalityVocabularyItemId}', 'vocab.nationalitiesLabels', function () {
      var _this6 = this;

      var personNationalities = this.get('model.personNationalities').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return _this6.get('vocab.nationalitiesLabels.' + item.get('nationalityVocabularyItemId'));
      }, personNationalities);
    }),

    preferredVitalDates: Ember.computed('model.personVitalDates.@each.{preferred,text}', function () {
      var personVitalDates = this.get('model.personVitalDates').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, personVitalDates);
    }),

    otherVitalDates: Ember.computed('model.personVitalDates.@each.{preferred,text}', function () {
      var personVitalDates = this.get('model.personVitalDates').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, personVitalDates);
    }),

    preferredActivityDates: Ember.computed('model.personActivityDates.@each.{preferred,text}', function () {
      var personActivityDates = this.get('model.personActivityDates').toArray();

      return (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('text');
      }, personActivityDates);
    }),

    otherActivityDates: Ember.computed('model.personActivityDates.@each.{preferred,text}', function () {
      var personActivityDates = this.get('model.personActivityDates').toArray();

      return (0, _clustering.getOthersCluster)(function (item) {
        return item.get('text');
      }, personActivityDates);
    }),

    preferredChristiesSpecialistDepartments: Ember.computed('model.personChristiesSpecialistDepartments.@each.{preferred,christiesSpecialistDepartmentVocabularyItemId}', function () {
      var dep = this.get('model.personChristiesSpecialistDepartments').filterBy('preferred').get('firstObject');

      return dep && this.get('vocab.christiesSpecialistsLabels')[dep.get('christiesSpecialistDepartmentVocabularyItemId')];
    }),

    othersChristiesSpecialistDepartments: Ember.computed('model.personChristiesSpecialistDepartments.@each.{preferred,christiesSpecialistDepartmentVocabularyItemId}', function () {
      var _this7 = this;

      var deps = this.get('model.personChristiesSpecialistDepartments').rejectBy('preferred');

      return deps.map(function (item) {
        return _this7.get('vocab.christiesSpecialistsLabels.' + item.get('christiesSpecialistDepartmentVocabularyItemId'));
      });
    }),

    jdeDepartments: Ember.computed('model.personJdeDepartments.@each.jdeDepartmentVocabularyItemId', function () {
      var _this8 = this;

      var deps = this.get('model.personJdeDepartments');

      return deps.map(function (item) {
        return _this8.get('vocab.jdeDepartmentsLabels.' + item.get('jdeDepartmentVocabularyItemId'));
      });
    }),

    threeDigitCodes: Ember.computed('model._threeDigitCodes.@each', function () {
      var _this9 = this;

      var codes = this.get('model._threeDigitCodes') || [];

      return codes.map(function (code) {
        return _this9.get('vocab.threeDigitCodesLabels')[code];
      }).join(', ');
    }),

    addresses: Ember.computed('model.personAddresses.@each.{location,locationId,contactTypeVocabularyItemId,citationLabelVocabularyItemId,userId}', 'displaySettings', function () {
      var addresses = this.get('model.personAddresses');
      var locationPromises = addresses.getEach('location');
      var promise = Ember.RSVP.all(locationPromises).then(function (locations) {
        return locations.map(function (loc) {
          return loc.get('_name') + ' / ' + loc.get('address');
        });
      });

      return PromiseArray.create({
        promise: promise
      });
    }),

    gender: Ember.computed('model.gender', function () {
      var gender = this.get('vocab.localGenders').findBy('id', this.get('model.gender'));

      return gender && gender.get('label');
    })
  });
});