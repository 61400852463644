define('cdata/pods/reports/components/sales-report/with-switcher/stylesheet', ['exports', 'ember-cli-jss'], function (exports, _emberCliJss) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = new _emberCliJss.StyleSheet({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  });
});