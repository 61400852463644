define('cdata/mixins/editor/forms/address-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    labels: Ember.computed.alias('vocab.contactTypesLabels'),

    classNameBindings: ['model._isEditMode:edit-mode'],

    parseLocationForm: 'parseLocationForm',
    addressesChanged: 'addressesChanged',

    step: 'address',
    isMap: Ember.computed.equal('step', 'map'),
    isMapConfirm: Ember.computed.equal('step', 'map:confirm'),
    isAddress: Ember.computed.equal('step', 'address'),

    isPlace: Ember.computed.bool('model.location.googlePlaceId'),
    isNotPlace: Ember.computed.not('isPlace'),

    isFilled: Ember.computed.and('model.location.{city,address,googlePlaceId}'),
    isEmpty: Ember.computed.not('isFilled'),

    country: Ember.computed('model.location.{country,continent}', function () {
      var parts = [];
      var location = this.get('model.location');
      var country = location.get('country');
      var continent = location.get('continent');

      if (country) {
        parts.push(country);
      } else if (null !== country) {
        parts.push('Unknown');
      }
      if (continent) {
        parts.push(continent);
      } else if (null !== continent) {
        parts.push('Unknown');
      }

      return parts.join(' / ');
    }),

    actions: {
      openGoogleMap: function openGoogleMap() {
        this.set('step', 'map');
        this.set('model._isEditMode', true);
      },
      confirmGoogleMap: function confirmGoogleMap() {
        this.sendAction('parseLocationForm', this.get('model'));
        this.set('model._isEditMode', false);
        this.set('step', 'address');
      },
      hideGoogleMap: function hideGoogleMap() {
        this.set('showMap', false);
        this.set('model.location.googlePlaceId', null);
        this.set('model._isEditMode', false);
      },
      changePlace: function changePlace(place) {
        var location = this.get('model.location');

        location.setProperties(place);

        location.setProperties({
          _name: place.name,
          city: place.city,
          country: place.country,
          continent: place.continent,
          region: place.region,
          geotagUrl: place.url
        });

        this.set('step', 'map:confirm');
        this.set('model._isEditMode', true);
      },
      cancel: function cancel() {
        this.set('step', 'address');
        this.set('model._isEditMode', false);
      }
    }
  });
});