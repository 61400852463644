define('cdata/mixins/lists/sorting-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var sortingManager = Ember.Object.extend({
    /**
     * Current sorting field name
     */
    _field: null,

    active: false,

    /**
     * Set field options
     * @param active {Boolean} state of sorting
     * @param field {String} field for sorting
     * @param asc {Boolean|String} sorting direction
     * @param replace {Boolean} set true for replace options
     */
    setField: function setField(active, field, asc, replace) {
      asc = asc === true || asc === 'true';
      active = active === true;
      replace = replace === true;

      if (replace || Ember.isEmpty(this.get(field))) {
        this.set(field, {
          active: active,
          ascending: asc
        });
      }

      if (active) {
        if (this.get('_field') !== field) {
          // new active field here, need clear old active field state
          this.cleanup();
        }

        // set new current active field
        this.set('_field', field);

        this.trigger('changed', {
          field: field,
          direction: asc ? 'asc' : 'desc'
        });
      }

      return this.get(field);
    },

    cleanup: function cleanup() {
      var activeField = this.get('_field');
      if (activeField) {
        this.set(activeField + '.active', false);
        this.set(activeField + '.ascending', false);
      }

      this.set('_field', null);

      this.trigger('changed', {
        field: null
      });
    }
  });

  sortingManager = sortingManager.extend(Ember.Evented);

  exports.default = sortingManager;
});