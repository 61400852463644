define('cdata/pods/reports/components/sales-report/component', ['exports', 'cdata/pods/reports/utils', 'cdata/pods/reports/components/sales-report/utils'], function (exports, _utils, _utils2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    salesBySource: Ember.computed('data', function () {
      var data = this.get('data');

      if (data) {
        return {
          name: 'All sales',
          children: (0, _utils2.mapSalesCount)(function (s) {
            return {
              name: s.source,
              children: (0, _utils2.mapSalesCount)(function (ah) {
                return {
                  name: (0, _utils.getTitleById)(data.auction_houses, ah.id),
                  size: ah.sales_count
                };
              }, s.auction_houses)
            };
          }, data.source)
        };
      }

      return null;
    }),

    lotsBySource: Ember.computed('data', function () {
      var data = this.get('data');

      if (data) {
        return {
          name: 'All lots',
          children: (0, _utils2.mapLotsCount)(function (s) {
            return {
              name: s.source,
              children: (0, _utils2.mapLotsCount)(function (ah) {
                return {
                  name: (0, _utils.getTitleById)(data.auction_houses, ah.id),
                  size: ah.lots_count
                };
              }, s.auction_houses)
            };
          }, data.source)
        };
      }

      return null;
    }),

    salesByAuctionHouse: Ember.computed('data', function () {
      var data = this.get('data');

      if (data) {
        return {
          name: 'All sales',
          children: (0, _utils2.mapSalesCount)(function (ah) {
            return {
              name: (0, _utils.getTitleById)(data.auction_houses, ah.id),
              children: (0, _utils2.mapSalesCount)(function (s) {
                return {
                  name: s.id,
                  size: s.sales_count
                };
              }, ah.sources)
            };
          }, data.auction_house)
        };
      }

      return null;
    }),

    lotsByAuctionHouse: Ember.computed('data', function () {
      var data = this.get('data');

      if (data) {
        return {
          name: 'All lots',
          children: (0, _utils2.mapLotsCount)(function (ah) {
            return {
              name: (0, _utils.getTitleById)(data.auction_houses, ah.id),
              children: (0, _utils2.mapLotsCount)(function (s) {
                return {
                  name: s.id,
                  size: s.lots_count
                };
              }, ah.sources)
            };
          }, data.auction_house)
        };
      }

      return null;
    }),

    isSalesSource: true,

    isSalesAuctionHouse: true,

    actions: {
      getTitle: function getTitle(id) {
        var houses = this.get('data.auction_houses');

        return (0, _utils.getTitleById)(houses, id);
      }
    }
  });
});