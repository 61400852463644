define('cdata/models/lot-title', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    lotId: attr('string'),
    lot: belongsTo('lot'),
    text: attr('string'),
    language: attr('string', { defaultValue: 'en' }),
    preferred: attr('boolean')
  });
});