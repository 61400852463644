define('cdata/utils/request', ['exports', 'npm:axios'], function (exports, _npmAxios) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  exports.default = function (url, method) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var _options$data = options.data,
        data = _options$data === undefined ? {} : _options$data,
        rest = _objectWithoutProperties(options, ['data']);

    return (0, _npmAxios.default)(_extends({
      url: url,
      method: method,
      data: method === 'GET' ? {} : data,
      params: method === 'GET' ? data : {},
      withCredentials: true
    }, rest)).then(function (response) {
      var errors = response.data.errors;


      if (errors && errors.length) {
        throw errors;
      }

      return response;
    });
  };
});