define('cdata/pods/components/lots/view/lot-item/summary-info/component', ['exports', 'cdata/mixins/components/summary-info-comments', 'cdata/mixins/components/render-with-citation', 'cdata/mixins/editor/summary-info-user-review', 'cdata/utils/clustering', 'cdata/pods/components/lots/lot-properties-mixin'], function (exports, _summaryInfoComments, _renderWithCitation, _summaryInfoUserReview, _clustering, _lotPropertiesMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_lotPropertiesMixin.default, _summaryInfoComments.default, _summaryInfoUserReview.default, _renderWithCitation.default, {
    classNames: 'lot-item--info',
    tagName: 'article',

    requestOnReview: 'requestOnLotReview',
    closeReview: 'closeLotReview',

    openLotForms: 'openLotForms',
    createEntityForLot: 'createEntityForLot',
    save: 'save',

    actions: {
      openForms: function openForms() {
        this.sendAction.apply(this, ['openLotForms'].concat(Array.prototype.slice.call(arguments)));
      },
      addEntity: function addEntity() {
        this.sendAction('createEntityForLot', this.get('model'));
      },
      setShown: function setShown() {
        this.set('model.shown', true);
        this.sendAction('save');
      },
      setHidden: function setHidden() {
        this.set('model.shown', false);
        this.sendAction('save');
      },
      setMulti: function setMulti() {
        this.set('model.multiObject', true);
        this.set('model.multiObjectCount', null);
        this.sendAction('save');
      },
      setSingle: function setSingle() {
        this.set('model.multiObject', false);
        this.set('model.multiObjectCount', 1);
        this.sendAction('save');
      }
    },

    preferredTitle: Ember.computed('model.lotTitles.@each.{preferred,text}', function () {
      var title = this.get('model.lotTitles').filterBy('preferred').get('firstObject');

      return title && title.get('text');
    }),

    otherTitles: Ember.computed('model.lotTitles.@each.{preferred,text}', function () {
      var titles = this.get('model.lotTitles').rejectBy('preferred');

      return titles.mapBy('text');
    }),

    preferredDescription: Ember.computed('model.lotDescriptions.@each.{preferred,text}', function () {
      var description = this.get('model.lotDescriptions').filterBy('preferred').get('firstObject');

      return description && description.get('text');
    }),

    otherDescriptions: Ember.computed('model.lotDescriptions.@each.{preferred,text}', function () {
      var descriptions = this.get('model.lotDescriptions').rejectBy('preferred');

      return descriptions.mapBy('text');
    }),

    preferredDepartment: Ember.computed('model.lotChristiesSpecialistDepartments.@each.{preferred,christiesSpecialistDepartmentVocabularyItemId}', function () {
      var departments = this.get('model.lotChristiesSpecialistDepartments').toArray();
      var labels = this.get('vocab.christiesSpecialistsLabels');

      return (0, _clustering.getPreferredCluster)(function (item) {
        return labels[item.get('christiesSpecialistDepartmentVocabularyItemId')];
      }, departments);
    }),

    isPreferredDeptFromOF: Ember.computed('model.lotChristiesSpecialistDepartments.@each.{preferred,christiesSpecialistDepartmentVocabularyItemId}', function () {
      var departments = this.get('model.lotChristiesSpecialistDepartments').toArray();

      var preferredDept = (0, _clustering.getPreferredCluster)(function (item) {
        return item.get('isOfUpdatedDept');
      }, departments);

      if (preferredDept) {
        return preferredDept.value == "true";
      } else {
        return false;
      }
    }),

    othersDepartments: Ember.computed('model.lotChristiesSpecialistDepartments.@each.{preferred,christiesSpecialistDepartmentVocabularyItemId}', function () {
      var departments = this.get('model.lotChristiesSpecialistDepartments').toArray();
      var labels = this.get('vocab.christiesSpecialistsLabels');

      return (0, _clustering.getOthersCluster)(function (item) {
        return labels[item.get('christiesSpecialistDepartmentVocabularyItemId')];
      }, departments);
    }),

    allLotSymbols: Ember.computed('model.lotSymbols.@each.{symbolVocabularyItemId}', function () {
      var symbols = this.get('model.lotSymbols').toArray();
      var labels = this.get('vocab.lotSymbolsLabels');

      return (0, _clustering.getSymbols)(function (item) {
        return labels[item.get('symbolVocabularyItemId')];
      }, symbols);
    }),

    saleTitle: Ember.computed.alias('sale.title'),

    sessionTitle: Ember.computed('model.session.id', 'sale.sessions.length', 'sale.id', function () {
      var sale = this.get('sale');
      if (!sale) {
        return '';
      }

      var sessions = sale.get('sessions');

      if (!sessions || sessions.get('length') <= 1) {
        return '';
      }

      var session = this.get('model.session');

      if (!session) {
        return '';
      }

      return session.get('title');
    }),

    sources: Ember.computed.alias('model.sources'),

    disableDescriptions: Ember.computed('model.lotDescriptions.length', 'model.entityLots.length', function () {
      return this.get('model.lotDescriptions.length') === 0 && this.get('model.entityLots.length') === 1;
    }),

    disableTitles: Ember.computed('model.lotTitles.length', 'model.entityLots.length', function () {
      return this.get('model.lotTitles.length') === 0 && this.get('model.entityLots.length') === 1;
    }),

    clustersLabels: Ember.computed('model._clusters.@each', function () {
      var _this = this;

      var codes = this.get('model._clusters') || [];

      return codes.map(function (code) {
        return _this.get('vocab.clustersLabels')[code];
      }).uniq();
    })
  });
});