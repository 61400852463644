define('cdata/pods/components/buttons/remove-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['remove-button'],
    label: true,
    disabled: false,

    actions: {
      doAction: function doAction() {
        if (!this.get('disabled')) {
          this.sendAction('remove', this.get('model'));
        }

        return false;
      }
    }
  });
});