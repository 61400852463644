define('cdata/utils/auction-houses-options', ['exports', 'cdata/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.AUCTION_HOUSES_DEFAULT_FILTER = exports.AUCTION_HOUSES_DEFAULT_IDS = exports.getAuctionHousesOptions = undefined;


  // TODO: This list should be loaded from back-end, when it ready.
  // https://christiestech.atlassian.net/browse/MUS-402
  var AUCTION_HOUSES_OPTIONS = [{ label: "Christie's", id: '35' }, { label: "Sotheby's", id: '51' }, { label: 'Bonhams', id: '59' }, { label: 'Phillips', id: '290' }];

  var getAuctionHousesOptions = exports.getAuctionHousesOptions = function getAuctionHousesOptions() {
    return (0, _utils.createEmberObjectsArray)(AUCTION_HOUSES_OPTIONS);
  };

  var AUCTION_HOUSES_DEFAULT_IDS = exports.AUCTION_HOUSES_DEFAULT_IDS = ['35', '51', '59', '290'];

  var AUCTION_HOUSES_DEFAULT_FILTER = exports.AUCTION_HOUSES_DEFAULT_FILTER = '(auctionHouseIds*35!51!59!290)';
});