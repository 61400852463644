define('cdata/pods/components/sales/list/set-results/item/component', ['exports', 'cdata/mixins/lists/set-results-item', 'moment', 'cdata/helpers/iso-currency-to-symbol', 'cdata/helpers/format-price'], function (exports, _setResultsItem, _moment, _isoCurrencyToSymbol, _formatPrice) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var escape = Ember.Handlebars.Utils.escapeExpression;

  exports.default = Ember.Component.extend(_setResultsItem.default, {
    date: Ember.computed('item.{startDate,finishDate}', function () {
      var start = this.get('item.startDate');
      var finish = this.get('item.finishDate');

      if (start === finish) {
        return (0, _moment.default)(start).format('ll');
      }

      var result = [];
      result.push(escape((0, _moment.default)(start).format('ll')));
      result.push(escape((0, _moment.default)(finish).format('ll')));

      return result.join(' &ndash;<br>').htmlSafe();
    }),

    prices: Ember.computed('item.{priceTypeVocabularyItemId,currencyVocabularyItemId,calculatedTotalUsd}', function () {
      var type = this.get('vocab.priceTypesLabels')[this.get('item.priceTypeVocabularyItemId')];
      var currency = this.get('vocab.currenciesLabels')[this.get('item.currencyVocabularyItemId')];
      var total = this.get('item.calculatedTotal');
      var totalUsd = this.get('item.calculatedTotalUsd');

      var result = [];

      if (type) {
        result.push(escape(type));
      }

      if (total && currency !== 'USD') {
        var price = '';
        if (currency) {
          price = _isoCurrencyToSymbol.default.compute([currency]);
        }
        price += _formatPrice.default.compute([total]);
        result.push(price);
      }

      if (totalUsd) {
        result.push('$<strong>' + _formatPrice.default.compute([totalUsd]) + '</strong>');
      }

      return result.join('<br>').htmlSafe();
    }),

    session: Ember.computed.alias('item.sessionListItems.firstObject')
  });
});