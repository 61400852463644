define('cdata/models/sale-description', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    saleId: attr('string'),
    sale: belongsTo('sale'),
    noteId: attr('string'),
    text: attr('string'),
    language: attr('string', { defaultValue: 'en' }),
    preferred: attr('boolean'),
    sourceId: attr('string'),
    source: belongsTo('source'),
    sourcePage: attr('string')
  });
});