define('cdata/pods/people/show/route', ['exports', 'cdata/mixins/route-edit', 'cdata/pods/people/edit-mixin', 'cdata/mixins/editor/add-comment', 'cdata/mixins/audit-logs/audit-log-route'], function (exports, _routeEdit, _editMixin, _addComment, _auditLogRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend(_routeEdit.default, _editMixin.default, _addComment.default, _auditLogRoute.default, {
    editFields: {
      'Activity dates': 'activity-dates',
      'Birth places': 'birth-places',
      'Death places': 'death-places',
      'Related publications': 'related-publications',
      'Awards': 'awards',
      'Education': 'educations',
      'Membership': 'memberships',
      'Public collections': 'collections',
      'Related people': 'relationships',
      'Related organizations': 'organizations',
      'Phones': 'phones',
      'Emails': 'emails',
      'Addresses': 'addresses'
    },

    editor: Ember.Object.create({
      editFields: Ember.Object.create()
    }),

    model: function model(params) {
      return this.get('store').find('person', params.person_id);
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (model.get('personBirthPlaces.length')) {
        model.get('personBirthPlaces').forEach(function (cp) {
          cp.buildName();
        });
      }

      if (model.get('personDeathPlaces.length')) {
        model.get('personDeathPlaces').forEach(function (cp) {
          cp.buildName();
        });
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      this.initEditFields();
      controller.set('editor', this.get('editor'));
      controller.set('lots', []);

      this.loadPersonWorks();
    },


    actions: {
      openPersonForms: function openPersonForms(formName) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        this._clearEditor();
        this.set('editor.options', options);
        this.set('editor.formName', 'people/edit/' + formName);
        Ember.$('body').css('overflow', 'hidden');
      },
      toggleField: function toggleField(key) {
        key = 'editor.shownFields.' + key;
        this.set(key, !this.get(key));
      },
      error: function error() {
        this.get('flashMessage').showError('Person #' + this.paramsFor('people.show').person_id + ' cannot be found or have loading error');
      },
      reload: function reload() {
        this.refresh();
      }
    },

    initEditFields: function initEditFields() {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(this.editFields)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var label = _ref2[0];
          var form = _ref2[1];

          this.set('editor.editFields.' + form, Ember.Object.create({
            id: form,
            label: label,
            empty: true
          }));
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    loadPersonWorks: function loadPersonWorks() {
      var _this = this;

      this.get('store').query('lot-list-item', {
        filter: {
          creators: [{
            id: this.get('currentModel.id'),
            type: 'person'
          }]
        },
        sort: {
          by: 'sale_date'
        },
        page_size: 5
      }).then(function (items) {
        _this.get('controller').set('lots', items);
      });
    }
  });
});