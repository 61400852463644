define('cdata/mixins/editor/forms/award-item', ['exports', 'cdata/helpers/format-award', 'cdata/constants'], function (exports, _formatAward, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modal: Ember.inject.service(),

    awardTitle: Ember.computed('model.{awardId,givingYear,_name}', function () {
      return _formatAward.default.compute([this.get('model'), false]);
    }),

    loadAwardFor: 'loadAwardFor',
    openNewAwardForm: 'openNewAwardForm',

    actions: {
      setAward: function setAward(_, awardSubject) {
        this.sendAction('loadAwardFor', this.get('model'), awardSubject.get('id'));
      },
      createAward: function createAward(name) {
        this.sendAction('openNewAwardForm', name);
        this._subscribeOnNewAward();
      }
    },

    /**
     * New award created
     * @param {Object} result
     * @private
     */
    _onAwardSuccess: function _onAwardSuccess(result) {
      var award = result.item;

      // set award-based subject
      this.send('setAward', null, Ember.Object.create({
        id: award.get('id'),
        name: award.get('name')
      }));
    },
    _subscribeOnNewAward: function _subscribeOnNewAward() {
      this.get('modal').on(_constants.MODALS.AWARD + '.saving.success', this, this._onAwardSuccess).on(_constants.MODALS.AWARD + '.close', this, this._unSubscribeOnNewAward);
    },
    _unSubscribeOnNewAward: function _unSubscribeOnNewAward() {
      this.get('modal').off(_constants.MODALS.AWARD + '.saving.success', this, this._onAwardSuccess).off(_constants.MODALS.AWARD + '.close', this, this._unSubscribeOnNewAward);
    }
  });
});