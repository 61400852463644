define('cdata/services/prev-next-editor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    name: null,

    inProcess: false,

    list: [],

    position: null,

    item: Ember.computed('list.length', 'position', function () {
      if (this.get('position') != null && this.get('list.length') > 0) {
        return this.get('list').objectAt(this.get('position'));
      }
    }),

    prev: Ember.computed('list.length', 'position', function () {
      if (this.get('position') != null && this.get('list.length') > 0) {
        return this.get('list').objectAt(this.get('position') - 1);
      }
    }),

    next: Ember.computed('list.length', 'position', function () {
      if (this.get('position') != null && this.get('list.length') > 0) {
        return this.get('list').objectAt(this.get('position') + 1);
      }
    }),

    prevEntity: Ember.computed.alias('prev.entityListItems.firstObject'),
    nextEntity: Ember.computed.alias('next.entityListItems.firstObject'),

    prevEntityId: Ember.computed.alias('prevEntity.entity_id'),
    prevNumber: Ember.computed.alias('prev.number'),
    prevImage: Ember.computed.alias('prevEntity.image_url'),
    prevCreator: Ember.computed.alias('prevEntity.creator_name'),
    prevTitle: Ember.computed.alias('prevEntity.title'),

    nextEntityId: Ember.computed.alias('nextEntity.entity_id'),
    nextNumber: Ember.computed.alias('next.number'),
    nextImage: Ember.computed.alias('nextEntity.image_url'),
    nextCreator: Ember.computed.alias('nextEntity.creator_name'),
    nextTitle: Ember.computed.alias('nextEntity.title'),

    setList: function setList(list) {
      this.set('list', list);
      this.set('position', 0);
    },
    changeItem: function changeItem(newId) {
      var _this = this;

      if (!this.get('list') || this.get('list.length') === 0 || this.get('position') === null) {
        return;
      }

      var index = 0;
      this.get('list').forEach(function (elem) {
        if (elem.get('id') === newId) {
          _this.set('position', index);
        }
        index++;
      });
    },
    doClear: function doClear() {
      this.set('list', []);
      this.set('position', null);
    }
  });
});