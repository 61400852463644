define('cdata/mixins/editor/edit-forms-comment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('text', this.get('options.comment') ? this.get('options.comment.text') : '');
      this.set('asPublic', this.get('options.comment') ? this.get('options.comment.public') : false);

      Ember.run.schedule('afterRender', this, function () {
        $('[contenteditable]').on('paste', function (e) {
          var text = e.originalEvent.clipboardData.getData('text');
          e.preventDefault();
          document.execCommand('inserttext', false, text);
        });
      });
    },


    addNewComment: 'addNewComment',
    updateComment: 'updateComment',

    title: Ember.computed('options.comment', function () {
      if (this.get('options.comment')) {
        return 'Edit comment';
      } else {
        return 'Add comment';
      }
    }),

    text: '',
    asPublic: false,
    options: {},

    actions: {
      save: function save() {
        this.set('inProcess', true);

        if (this.get('options.comment')) {
          this.set('options.comment.text', this.get('text'));
          this.set('options.comment.public', this.get('asPublic'));
          this.sendAction('updateComment', this.get('model'), this.get('options.comment'), this.finish.bind(this));
        } else {
          this.sendAction('addNewComment', this.get('model'), this.get('text'), this.get('asPublic'), this.finish.bind(this));
        }
      },
      changePublic: function changePublic() {
        this.set('asPublic', !this.get('asPublic'));
      },
      changeText: function changeText(text) {
        this.set('text', text);
      }
    },

    finish: function finish() {
      if (!this.get('isDestroyed')) {
        this.set('inProcess', false);
      }
      this.send('cancel');
    }
  });
});