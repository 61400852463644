define('cdata/pods/components/site/unbreakable-image/component', ['exports', 'cdata/helpers/viewer'], function (exports, _viewer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['unbreakable-image'],
    classNameBindings: ['loaded', 'enableZoom:real'],

    src: '/images/no-image.png',

    isLoaded: false,
    isBroken: false,
    viewerOn: false,

    loaded: false,

    enableZoom: Ember.computed.and('src', 'loaded'),

    viewer: null,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('_src', this.get('src'));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      if (this.get('_src') !== this.get('src')) {
        Ember.run.schedule('afterRender', this, function () {
          _this.set('loaded', false);
        });
      }
      this.set('_src', this.get('src'));
    },


    actions: {
      switchSrcToThePlaceholder: function switchSrcToThePlaceholder() {
        if (!this.get('isDestroyed')) {
          this.set('src', '/images/no-image.png');
        }
      },
      onLoad: function onLoad(img) {
        var className = this.get('className');
        if (!this.get('isDestroyed')) {
          this.set('loaded', true);
          this.set('className', className + ' loaded');
          img = this.$(img);
          var height = img.height();
          var parent = img.parent().height();
          img.css('margin-top', (parent - height) / 2 + 'px');
        }
      },
      openViewer: function openViewer() {
        if (!this.get('enableZoom')) {
          return;
        }
        var img = this.$('img')[0];

        if (null === this.get('viewer')) {
          var options = {
            navbar: false,
            toolbar: false,
            title: false,
            tooltip: false,
            transition: true,
            interval: 0,
            minWidth: 800,
            minHeight: 800,
            zoomRatio: 0.5
          };
          this.set('viewer', new _viewer.default(img, options));
        }

        this.get('viewer').show();
      }
    },

    convertToLarge: function convertToLarge(src) {
      src = src.replace('/medium/', '/large/');
      src = src.replace('/zoom/', '/large/');

      return src;
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('viewer')) {
        this.get('viewer').hide();
      }
    }
  });
});