define('cdata/pods/places/edit-mixin', ['exports', 'cdata/mixins/route-edit'], function (exports, _routeEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_routeEdit.default, {
    editor: Ember.Object.create({}),
    relations: Ember.inject.service(),

    actions: {
      createNewRootPlace: function createNewRootPlace(name) {
        var _this = this;

        var place = this.get('store').createRecord('place', {
          name: name
        });

        this._handleSaving(place.save()).then(function () {
          var placeItem = _this.get('store').createRecord('place_list_item', {
            id: place.get('id'),
            placeId: place.get('id')
          });

          _this.send('openForm', placeItem);
        });
      },
      deletePlace: function deletePlace(place) {
        var _this2 = this;

        place.deleteRecord();

        this._handleSaving(place.save()).then(function () {
          _this2.refresh();
        });
      },
      createNewPlace: function createNewPlace(name, parentId) {
        var _this3 = this;

        this.get('store').findRecord('place', parentId).then(function (parent) {
          var place = _this3.get('store').createRecord('place', {
            parent: parent,
            parentId: parent.get('id'),
            name: name
          });

          _this3._handleSaving(place.save()).then(function () {
            var placeItem = _this3.get('store').createRecord('place_list_item', {
              id: place.get('id'),
              placeId: place.get('id')
            });

            _this3.send('openForm', placeItem);
          });
        });
      },
      loadPlaceFor: function loadPlaceFor(context, placeId) {
        context.set('place', this.get('store').findRecord('place', placeId));
      },
      loadParentPlaceFor: function loadParentPlaceFor(place, parentId) {
        this.get('relations').removeBelongsTo(place, 'parent');

        this.get('store').findRecord('place', parentId).then(function (parent) {
          place.setProperties({
            parent: parent,
            parentId: parent.get('id')
          });
        });
      },
      loadCurrentPlaceFor: function loadCurrentPlaceFor(place, parentId) {
        this.get('relations').removeBelongsTo(place, 'currentPlace');

        this.get('store').findRecord('place', parentId).then(function (current) {
          place.setProperties({
            currentPlace: current,
            currentPlaceId: current.get('id')
          });
        });
      },
      cancel: function cancel(place) {
        this.get('relations').resetBelongsTo(place, 'parent');
        this.get('relations').resetBelongsTo(place, 'current');
        this._clearEditor();
      },
      save: function save(place) {
        place = place.get('content') || place;
        place.save();

        this._clearEditor();
      }
    }
  });
});