define('cdata/services/web-auth', ['exports', 'cdata/constants', 'cdata/utils/auth0', 'cdata/utils/request'], function (exports, _constants, _auth, _request) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SIGN_IN_ENDPOINT = '/auth/auth0';

  var swapToken = function swapToken(data) {
    return (0, _request.default)('' + _constants.API_URL + SIGN_IN_ENDPOINT, 'POST', {
      data: { access_token: data.accessToken, id_token: data.idToken },
      withCredentials: false
    });
  };

  var checkAuthResult = function checkAuthResult(authResult) {
    var accessToken = authResult.accessToken,
        idToken = authResult.idToken;


    if (accessToken && idToken) {
      return { accessToken: accessToken, idToken: idToken };
    }

    throw Error('AD Auth: idToken or accessToken is not received');
  };

  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    userActions: Ember.inject.service(),
    sidConstants: Ember.inject.service(),

    auth0: Ember.computed(function () {
      return (0, _auth.default)(_constants.AUTH0);
    }),

    onAuthSuccess: function onAuthSuccess(_ref) {
      var data = _ref.data;
      var api_key = data.api_key,
          userId = data.user.id;


      this.get('session').set('key', api_key);
      this.get('userActions').get('user').set('id', userId.toString());
      this.get('sidConstants').reloadSids();
    },
    continueAuth: function continueAuth() {
      var _this = this;

      return this.get('auth0').readAuthData().then(checkAuthResult).then(swapToken).then(function (data) {
        return _this.onAuthSuccess(data);
      });
    }
  });
});