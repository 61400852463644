define('cdata/pods/components/entities/edit/edit-forms-upcoming-lots/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms', 'cdata/mixins/components/cdata-category', 'moment'], function (exports, _editForms, _cdataCategory, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)(), _cdataCategory.default, {
    comparable: Ember.inject.service(),

    applyUpcomingParams: 'applyUpcomingParams',

    init: function init() {
      this._super.apply(this, arguments);

      this.set('params', this.get('comparable').initUpcomingOptions(this.get('model.id')));
    },


    actions: {
      save: function save() {
        this.sendAction('applyUpcomingParams');
        this.sendAction('cancel');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      setSaleDateFrom: function setSaleDateFrom(value) {
        var _this = this;

        var date = value ? (0, _moment.default)(value).format('YYYY-MM-DD') : (0, _moment.default)((0, _moment.default)()).add(1, 'days').format('YYYY-MM-DD');
        Ember.run.schedule('afterRender', this, function () {
          _this.set('params.sale_date_from', date);
        });
      },
      setSaleDateTo: function setSaleDateTo(value) {
        var _this2 = this;

        var date = value ? (0, _moment.default)(value).format('YYYY-MM-DD') : null;
        Ember.run.schedule('afterRender', this, function () {
          _this2.set('params.sale_date_to', date);
        });
      },
      setChristiesOnly: function setChristiesOnly() {
        this.set('params.christies_only', !this.get('params.christies_only'));
      }
    }
  });
});