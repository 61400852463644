define('cdata/pods/components/entities/view/lot-item/component', ['exports', 'cdata/pods/components/lots/lot-properties-mixin'], function (exports, _lotPropertiesMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_lotPropertiesMixin.default, {
    classNames: ['item'],

    sale: Ember.computed.alias('model.session.sale'),

    entity: null,

    saleTitle: Ember.computed('organizationTitle', 'sale.title', function () {
      return this.get('sale.title');
    }),

    organizationTitle: Ember.computed('sale.organization.organizationTitles.@each.{preferred,text}', function () {
      var titles = this.get('sale.organization.organizationTitles');
      if (!titles || titles.get('length') === 0) {
        return '';
      }

      var title = titles.findBy('preferred', true);

      return title && title.get('text');
    }),

    locationTitle: Ember.computed('model.session.location.locationTitles.@each.{preferred,text}', function () {
      var titles = this.get('model.session.location.locationTitles');
      if (!titles || titles.get('length') === 0) {
        return '';
      }

      var title = titles.findBy('preferred', true);

      return title && title.get('text');
    })
  });
});