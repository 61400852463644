define('cdata/pods/reports/components/fields-report/component', ['exports', 'ember-cli-jss', 'cdata/pods/reports/utils', 'cdata/pods/reports/controller', 'cdata/pods/reports/components/fields-report/utils', 'cdata/pods/reports/components/fields-report/stylesheet'], function (exports, _emberCliJss, _utils, _controller, _utils2, _stylesheet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCliJss.TaglessJSS, {
    tagName: '',
    stylesheet: _stylesheet.default,

    enabledDetailsFields: _controller.enabledDetailsFields,

    fields: Ember.computed('data', function () {
      var _this = this;

      var data = this.get('data');

      if (data) {
        return {
          name: 'All fields',
          children: (0, _utils2.mapCount)(function (r) {
            return {
              organization_id: r.organization_id,
              name: (0, _utils.getTitleById)(data.organizations, r.organization_id),
              children: (0, _utils2.mapCount)(function (f) {
                return {
                  name: f.field,
                  setupLabel: (0, _utils.setupOrganizationLabel)(_this.classes, function () {
                    for (var _len = arguments.length, attr = Array(_len), _key = 0; _key < _len; _key++) {
                      attr[_key] = arguments[_key];
                    }

                    return _this.handleShowDetails.apply(_this, [r.organization_id].concat(attr));
                  }),
                  children: (0, _utils2.mapCount)(function (o) {
                    return {
                      name: o.operation,
                      size: o.count
                    };
                  }, f.operations)
                };
              }, r.fields)
            };
          }, data.report)
        };
      }

      return null;
    }),

    handleShowDetails: function handleShowDetails(organizationId, node) {
      var field = node.data.name;

      this.showDetails(organizationId, field);
    },


    actions: {
      getTitle: function getTitle(id) {
        var organizations = this.get('data.organizations');

        return (0, _utils.getTitleById)(organizations, id);
      }
    }
  });
});