define('cdata/services/sid-constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    constants: [],
    isLoaded: Ember.computed.bool('sids.length'),

    sids: Ember.computed(function () {
      return this.get('store').query('sid', {});
    }),

    reloadSids: function reloadSids() {
      this.set('sids', this.get('store').query('sid', {}));
    },


    /**
     * Mapping id -> name for all sids with path 'vocabulary'
     * result is: {'1': 'roles', '2': 'lotStatuses') etc
     */
    vocabNames: Ember.computed('sids.length', function () {
      var _this = this;

      var names = {};
      var vocabularies = this.get('sids').filter(function (item) {
        return item.get('path') === 'vocabulary';
      });
      vocabularies.forEach(function (item) {
        names[item.get('entityId')] = _this._fixKey(item.get('sid').replace('vocabulary_', '')).camelize();
      });

      return names;
    }),

    /**
     * get vocabulary name by id
     * @param {Number|String} id
     */
    getVocabNameById: function getVocabNameById(id) {
      return this.get('vocabNames')[id];
    },
    getByEntityId: function getByEntityId(id, prefix) {
      var list = this.get('sids').filterBy('entityId', id);

      if (prefix) {
        list = list.filter(function (item) {
          return item.get('path').indexOf(prefix) === 0;
        });
      }

      return list.get('firstObject');
    },
    getVocabId: function getVocabId(key) {
      var sid = this.get('sids').findBy('sid', 'vocabulary_' + this._fixKeyBack(key));

      return sid && sid.get('entityId');
    },


    memberRoleId: Ember.computed(function () {
      return this.get('sids').findBy('sid', 'role_member').get('entityId');
    }),

    gemCategoryId: Ember.computed(function () {
      return this.get('sids').findBy('sid', 'cdata_category_gems').get('entityId');
    }),

    usd: Ember.computed(function () {
      return this.get('sids').findBy('sid', 'USD').get('entityId');
    }),

    auctionHouseId: Ember.computed(function () {
      return this.get('sids').findBy('sid', 'role_auction_house').get('entityId');
    }),

    defaultNameLabelVocabularyItemId: Ember.computed(function () {
      return this.get('sids').findBy('sid', 'name_name').get('entityId');
    }),

    materialDiamondId: Ember.computed(function () {
      return this.get('sids').findBy('sid', 'material_diamond').get('entityId');
    }),

    materialMineralId: Ember.computed(function () {
      return this.get('sids').findBy('sid', 'material_mineral').get('entityId');
    }),

    cdataCategoryGemId: Ember.computed(function () {
      return this.get('sids').findBy('sid', 'cdata_category_gems').get('entityId');
    }),

    treatmentIds: Ember.computed(function () {
      return [this.get('sids').findBy('sid', 'material_ruby').get('entityId'), this.get('sids').findBy('sid', 'material_emerald').get('entityId'), this.get('sids').findBy('sid', 'material_sapphire').get('entityId')];
    }),

    materialEmeraldId: Ember.computed(function () {
      return this.get('sids').findBy('sid', 'material_emerald').get('entityId');
    }),

    fineArtVocabularyItemId: Ember.computed(function () {
      var item = this.get('sids').findBy('sid', 'cdata_category_fine_art');

      return item && item.get('entityId');
    }),

    _fixKey: function _fixKey(key) {
      return key;
    },
    _fixKeyBack: function _fixKeyBack(key) {
      return key;
    }
  });
});