define('cdata/pods/components/wrappers/edit-row-cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EditRowCell = Ember.Component.extend({
    classNames: ['four', 'wide', 'column', 'edit-row-cell'],
    classNameBindings: ['editable'],
    size: 100,

    editable: Ember.computed.bool('edit'),

    actions: {
      edit: function edit() {
        this.get('edit')();
      }
    }
  });

  EditRowCell.reopenClass({
    positionalParams: ['label', 'value', 'edit']
  });

  exports.default = EditRowCell;
});