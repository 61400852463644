define('cdata/pods/components/b-icon/component', ['exports', 'ember-cli-jss', 'cdata/pods/components/b-icon/stylesheet', 'cdata/pods/components/b-icon/declaration'], function (exports, _emberCliJss, _stylesheet, _declaration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCliJss.default, {
    propTypes: _declaration.default,
    stylesheet: _stylesheet.default,
    jssNames: ['root'],
    jssObservedProps: ['color'],

    getDefaultProps: function getDefaultProps() {
      return {
        color: 'inherit',
        size: 0,
        width: 0,
        height: 0,
        iconClass: null
      };
    }
  });
});