define('cdata/mixins/lists/merge-list', ['exports', 'cdata/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modal: Ember.inject.service(),
    mergeList: Ember.inject.service(),

    actions: {
      openMergeListForm: function openMergeListForm(name) {
        this.get('modal').register(this, _constants.MODALS.MERGING, 'merging').open(Ember.Object.create({
          name: name
        }));
      },
      closeMergeListForm: function closeMergeListForm() {
        this.get('modal').close(_constants.MODALS.MERGING);
        this.get('mergeList').clear();
      },
      saveMergeListForm: function saveMergeListForm(name) {
        var id = this.get('mergeList').getTargetId();
        var ids = this.get('mergeList').getMergeIds();

        switch (name) {
          case 'entities':
            this.entityMerging(id, ids);
            break;

          case 'people':
            this.personMerging(id, ids);
            break;

          case 'organizations':
            this.organizationMerging(id, ids);
            break;
        }
      }
    }
  });
});