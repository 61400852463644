define('cdata/pods/components/entities/edit/edit-forms-gem-type/component', ['exports', 'cdata/pods/components/entities/edit/mixins/edit-forms'], function (exports, _editForms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend((0, _editForms.default)('Material'), {
    materials: Ember.computed('model.entityMaterials.@each.{preferred,isDeleted,text}', function () {
      return this.get('model.entityMaterials').rejectBy('isDeleted').filterBy('preferred');
    }),
    material: Ember.computed.alias('materials.firstObject'),

    terms: Ember.computed('material.entityMaterialTerms.@each.{isDeleted,materialVocabularyItemId}', function () {
      return this.get('material') ? this.get('material.entityMaterialTerms').rejectBy('isDeleted') : [];
    }),
    term: Ember.computed.alias('terms.firstObject'),
    termId: Ember.computed('term.materialVocabularyItemId', function () {
      return this.get('term') && this.get('term.materialVocabularyItemId') && parseInt(this.get('term.materialVocabularyItemId')) + '';
    }),

    label: Ember.computed('termId', function () {
      return this.get('vocab.materialsLabels')[this.get('termId')];
    }),

    addEntityMaterialTerm: 'addEntityMaterialTerm',
    parseEntityMaterial: 'parseEntityMaterial',

    disableDeleteTerm: Ember.computed.not('material'),

    actions: {
      addMaterialTerm: function addMaterialTerm(material, term) {
        this.sendAction('addEntityMaterialTerm', material, term);
      },
      parseEntityMaterial: function parseEntityMaterial() {
        this.sendAction.apply(this, ['parseEntityMaterial'].concat(Array.prototype.slice.call(arguments)));
      },
      deleteTerm: function deleteTerm() {
        this.get('material').deleteRecord();
      },
      setMaterial: function setMaterial(termSubject) {
        if (!termSubject || !termSubject.get('id')) {
          this.send('deleteTerm');

          return;
        }

        if (!this.get('material')) {
          this.sendAction('addEntityMaterial');
        }

        if (this.get('term')) {
          if (this.get('term.materialVocabularyItemId') === termSubject.get('id')) {
            return;
          } else {
            this.get('term').deleteRecord();
          }
        }

        this.sendAction('addEntityMaterialTerm', this.get('material'), termSubject);
        var term = this.get('term');
        term.set('materialVocabularyItemId', termSubject.get('id'));
        this.get('material').set('text', this.get('label'));
      }
    }
  });
});